import moment from 'moment-timezone';
import { useState, useRef, useEffect, useMemo } from 'react';
import { Table, TableActionCell, Pagination } from 'wix-style-react';
import { useHistory } from 'react-router-dom';
import CancelOrder from 'wix-ui-icons-common/CancelOrder';
import DeleteIcon from 'wix-ui-icons-common/Delete';
import DateAndTimeIcon from 'wix-ui-icons-common/DateAndTime';
import {
    deleteEventApi,
    fetchEventOccurrencesApi,
    selectSingleEvent,
    updateEventOccurrenceApi,
} from '../../../features/events-slice';
import { useAppDispatch, useAppSelector } from '../../../hooks';

import type { Event } from '../../../types';
import { capitalize, isEmpty } from 'lodash';
import { buildURL } from '../../../features/utils';
import { useSelector } from 'react-redux';
import { selectSiteData } from '../../../features/account-slice';
import { OccurrenceModel } from './occurrrence-model';

const MAX_PER_PAGE = 5;

export const Occurrences = ({ id }) => {
    const [selectedPage, setSelectedPage] = useState(1);
    const site = useSelector(selectSiteData);

    const containerRef = useRef(null);
    const [container, setContainer] = useState(null);

    const history = useHistory();

    const parentEvent = useAppSelector(selectSingleEvent);

    const occurrences = parentEvent?.occurrences || [];

    useEffect(() => {
        setContainer(containerRef);
    }, []);

    const [selectedOccurrence, setSelectedOccurrence] = useState<Event | null>();

    const events = useMemo(
        () => occurrences.slice((selectedPage - 1) * MAX_PER_PAGE, selectedPage * MAX_PER_PAGE),
        [occurrences, selectedPage]
    );
    const columns = [
        {
            title: 'Occurrences',
            width: '30%',
            render: (row) => {
                const startDate = moment(row.start_date)
                    .tz(row.timezone)
                    .format('ddd DD MMM YYYY HH:mm a');
                const endTime = moment(row.end_date).tz(row.timezone).format('HH:mm a');
                return `${startDate} - ${endTime}`;
            },
        },
        {
            title: 'Status',
            width: '20%',
            render: (row) => {
                return capitalize(row.status);
            },
        },
        {
            title: 'Tickets Sold',
            width: '20%',
            render: (row) => {
                const attendanceCount = row?.stats?.attendance_count || 0;
                return row.unlimited_capacity
                    ? attendanceCount
                    : `${attendanceCount}/${row.event_capacity}`;
            },
        },
        {
            render: (rowData) => {
                return (
                    <TableActionCell
                        primaryAction={primaryViewAction(rowData)}
                        secondaryActions={secondaryActions(rowData)}
                    />
                );
            },
        },
    ];

    const dispatch = useAppDispatch();

    const secondaryActions = (occurrence) =>
        [
            {
                icon: <DateAndTimeIcon />,
                text: 'Reschedule',
                onClick: () => {
                    setSelectedOccurrence(occurrence);
                },
            },
            occurrence.status !== 'cancelled'
                ? {
                      icon: <CancelOrder />,
                      text: 'Cancel',
                      onClick: async () => {
                          await dispatch(
                              updateEventOccurrenceApi({
                                  status: 'cancelled',
                                  start_date: occurrence.start_date,
                                  end_date: occurrence.end_date,
                                  id: occurrence.id,
                                  series_id: occurrence.series_id,
                              })
                          );
                      },
                  }
                : {},
            {
                icon: <DeleteIcon />,
                text: 'Delete',
                onClick: async () => {
                    await dispatch(deleteEventApi(occurrence.id));
                    await dispatch(fetchEventOccurrencesApi(parentEvent));
                },
            },
        ].filter((c) => !isEmpty(c));

    const _handleChange = ({ page, event }) => {
        event.preventDefault();
        setSelectedPage(page);
    };
    const primaryViewAction = (occurrence: Event) => ({
        text: 'View',
        skin: 'standard',
        onClick: () => {
            history.push(buildURL(site, `/events/manage/${occurrence.id}`));
        },
    });

    const renderEmptyState = () => (
        <Table.EmptyState
            title="No events generated yet"
            subtitle={
                <>
                    There are no events generated between{' '}
                    {moment(parentEvent?.dateStart).format('Do MMMM YYYY')} and{' '}
                    {moment(parentEvent?.dateEnd).format('Do MMMM YYYY')}.
                </>
            }
        ></Table.EmptyState>
    );

    return (
        <div ref={containerRef}>
            {parentEvent && (
                <Table data={events} columns={columns}>
                    <Table.Content />
                    {parentEvent && parentEvent.repeat && events.length === 0 && renderEmptyState()}
                    <Pagination
                        currentPage={selectedPage}
                        totalPages={Math.ceil(occurrences.length / MAX_PER_PAGE)}
                        onChange={_handleChange}
                    />
                </Table>
            )}
            {selectedOccurrence && (
                <OccurrenceModel
                    key={selectedOccurrence.id}
                    occurrence={selectedOccurrence}
                    isOpen
                    parentEvent={parentEvent}
                />
            )}
        </div>
    );
};
