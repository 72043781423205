import { FC, useEffect, useRef, useState } from 'react';
import {
    FormField,
    Box,
    Layout,
    Cell,
    Card,
    Text,
    SectionHelper,
    Accordion,
    accordionItemBuilder,
    InputArea,
    Checkbox,
    Heading,
} from 'wix-style-react';
import InfoDocument from 'wix-ui-icons-common/Document';
import { Controller } from 'react-hook-form';
import { MesssageKeysTable } from '../message-keys-table';
import { API_INSTANCE, buildURL } from '../../../../features/utils';
import { useSelector } from 'react-redux';
import { selectConnectedAccounts, selectSiteData } from '../../../../features/account-slice';
import { IntegrationNotConnected } from './integration-not-connected';

const Pages: FC<any> = ({ campaignId, action, control, setValue }) => {
    const [pages, setPages] = useState<any>([]);
    const [instagramId, setInstagramId] = useState<any>([]);
    const [instagram, setInstagram] = useState<any>(null);
    const [enableInstagram, setEnableInstagram] = useState<any>(action?.enable_instagram);

    const site = useSelector(selectSiteData);

    useEffect(async () => {
        const { data } = await API_INSTANCE.get(
            buildURL(site, `/api/v1/dashboard/facebook/campaigns/${campaignId}`)
        );
        const selectedIds = action?.pages?.map(({ id }) => id) || [];
        setInstagramId(data.instagram_business_account_id);
        setInstagram(data.instagram);
        setPages(
            data.pages.map((page: any) =>
                selectedIds.includes(page.id) ? { ...page, checked: true } : page
            )
        );
    }, []);

    return (
        <>
            <Box marginTop={3} marginBottom={3}>
                <Text size="medium" weight="bold" skin="success">
                    To add promotional images that will be displayed randomly on each new post,
                    please navigate to the "Promotion Media" tab on the event edit page and upload
                    the desired images. These images will serve as promotional material for the
                    event and will be automatically randomized with each new post.
                </Text>
            </Box>
            {instagramId && (
                <>
                    <Heading appearance="H5">
                        You have linked your <b>{instagram?.username}</b> Instagram
                    </Heading>

                    <Controller
                        control={control}
                        name="facebook.enable_instagram"
                        render={({ field }) => (
                            <Checkbox
                                id={instagramId}
                                checked={enableInstagram}
                                onChange={(e) => {
                                    setEnableInstagram(e.target.checked);
                                    field.onChange(e.target.checked);
                                }}
                            >
                                Promote your events on Instagram?
                            </Checkbox>
                        )}
                    />
                    <Box height={20} />
                </>
            )}

            <Heading appearance="H5">Select the pages you want to promote</Heading>

            <Box direction="vertical" gap={3} marginTop={2}>
                {pages.length === 0 && (
                    <SectionHelper appearance="warning" fullWidth>
                        No available pages. Select pages during Facebook Integration.
                    </SectionHelper>
                )}
                {pages.map((page: any) => (
                    <Controller
                        control={control}
                        name="facebook.pages"
                        render={({ field }) => (
                            <Checkbox
                                id={page.id}
                                key={page.id}
                                checked={page.checked}
                                disabled={page.disabled}
                                onChange={(e) => {
                                    const newPages = pages.map((p: any) => {
                                        if (page.id !== p.id) return p;
                                        return {
                                            ...page,
                                            checked: e.target.checked,
                                        };
                                    });
                                    setPages(newPages);
                                    setValue(
                                        'facebook.pages',
                                        newPages.filter(({ checked }) => checked)
                                    );
                                }}
                            >
                                {page.name}
                            </Checkbox>
                        )}
                    />
                ))}
            </Box>
            <Box height={20} />
        </>
    );
};

export const FacebookMessage = ({ control, campaign = {}, trigger, id, setValue }) => {
    const messageRef = useRef();
    const { facebook } = campaign || {};
    if (id === undefined) {
        messageRef.current?.setValue(trigger.message);
    }
    const connectedAccounts = useSelector(selectConnectedAccounts);
    if (!connectedAccounts.hasOwnProperty('facebook')) {
        return <IntegrationNotConnected actionName="Meta" />;
    }
    return (
        <>
            <Box marginTop={2} />

            <Pages campaignId={id} action={facebook} control={control} setValue={setValue} />
            <MesssageKeysTable appendTo="window" />

            <FormField label="Message" required>
                <Controller
                    control={control}
                    name="facebook.message"
                    render={({ field }) => (
                        <InputArea
                            defaultValue={facebook?.message}
                            minHeight="120px"
                            maxHeight="300px"
                            maxLength={2200}
                            {...field}
                        />
                    )}
                />
                <Box marginTop={2} width="400">
                    <SectionHelper appearance="standard" fullWidth>
                        Can include hashtags (example: #crazywildebeest) and usernames of users
                        (example: @natgeo). @Mentioned users receive a notification when published.
                        Maximum 2200 characters, 30 hashtags, and 20 @ tags.
                    </SectionHelper>
                </Box>
            </FormField>
        </>
    );
};
