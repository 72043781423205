import {
    Layout,
    Row,
    Cell,
    Box,
    FormField,
    Input,
    Divider,
    Tooltip,
    SectionHelper,
    Text,
} from 'wix-style-react';
import { Controller } from 'react-hook-form';
import VerifyDomainButton from './verify-domain-button';
import { classes } from './customDomain.st.css';
import * as Icons from 'wix-ui-icons-common';
import { Copy } from '../modules/events/components/copy';
import { useState } from 'react';

const CustomDomainComponent = ({ site, control, setValue }) => {
    const [customDomain, setCustomDomain] = useState(site.custom_domain);
    return (
        <Layout gap={2}>
            <Row>
                <Cell span={12}>
                    <Box marginTop={2}>
                        <SectionHelper appearance="standard">
                            <Box gap={1}>
                                <Box>
                                    <Icons.Preview />
                                </Box>
                                <Text weight="bold">
                                    Track visitor behavior and improve engagement by adding your
                                    Google Analytics Tracking ID and Facebook Pixel ID. Set up a
                                    custom domain to enhance brand recognition and trust among your
                                    visitors.
                                </Text>
                            </Box>
                        </SectionHelper>
                    </Box>
                </Cell>
            </Row>
            <Row>
                <Cell span={12}>
                    <Box direction="vertical" gap={4} marginTop={2}>
                        <FormField
                            label="Google Analytics Measurement ID"
                            infoContent="Use this ID to integrate Google Analytics with your site. It allows you to measure and analyze traffic and user behavior to improve marketing strategies."
                        >
                            <Controller
                                control={control}
                                name="google_analytics_measurement_id"
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormField>
                        <FormField
                            label="Facebook Pixel ID"
                            infoContent="Use this ID to track visitor activity on your site. It helps in optimizing your Facebook ads, building targeted audiences, and gaining insights about how users interact with your pages."
                        >
                            <Controller
                                control={control}
                                name="facebook_pixel_id"
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormField>
                        <Divider />
                        <Box marginTop={0} marginBottom={1} direction="vertical">
                            <h3>Custom Domain Setup</h3>
                            <p>
                                Use{' '}
                                <Box inline>
                                    <Copy text={'connect.ticketspot.io'} isTextArea={false} />
                                </Box>{' '}
                                as your CNAME target for your custom domain
                            </p>
                        </Box>
                        <FormField
                            label="Custom Domain"
                            infoContent='Add a CNAME record for this domain in your DNS settings pointing to "connect.ticketspot.io".'
                        >
                            <Controller
                                control={control}
                                name="custom_domain"
                                render={({ field }) => (
                                    <Box gap={1}>
                                        <Box flexGrow={'1'}>
                                            <Input
                                                {...field}
                                                placeholder="events.yourdomain.com"
                                                className={classes.fullWidth}
                                                onChange={(e) => {
                                                    setCustomDomain(e.target.value);
                                                    setValue('custom_domain', e.target.value);
                                                }}
                                                value={customDomain}
                                            />{' '}
                                        </Box>
                                        <Box align="center" verticalAlign="middle">
                                            {site.custom_domain_verified_at ? (
                                                <Tooltip content="The domain has been verified successfully.">
                                                    <Icons.StatusComplete
                                                        className={classes.success}
                                                    />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip content="The domain has not been verified.">
                                                    <Icons.StatusWarning
                                                        className={classes.failed}
                                                    />
                                                </Tooltip>
                                            )}
                                        </Box>
                                    </Box>
                                )}
                            />
                        </FormField>
                        <Box marginTop={1}>
                            <VerifyDomainButton site={site} customDomain={customDomain} />
                        </Box>
                    </Box>
                </Cell>
            </Row>
        </Layout>
    );
};

export default CustomDomainComponent;
