import { useState, useEffect } from 'react';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';
import * as Icons from 'wix-ui-icons-common';

import { fetchSettingsApi, selectSettings } from '../features/settings-slice';
import { useAppSelector, useAppDispatch } from '../hooks';
import { Box, Tooltip } from 'wix-style-react';
import { classes } from './plan-notification.st.css';
import { selectAccount, selectSiteData } from '../features/account-slice';
import { useSelector } from 'react-redux';

const TRIAL_PLAN = 'trial';
const FREE_PLAN = 'trial';
const STARTER_PLAN = 'starter';
const PROFRESSIONAL_PLAN = 'featured';
const GROWTH_PLAN = 'growth';
const BUSINESS_PLAN = 'business';
const ENTERPRISE_PLAN = 'enterprise';

const LockIcon = () => (
    <svg width="33" height="32" viewBox="0 0 33 32" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.1667 11.5556C11.1667 8.11913 13.9525 5.33334 17.3889 5.33334C20.8254 5.33334 23.6111 8.11913 23.6111 11.5556C23.6111 11.8574 23.6111 12.4445 23.6111 12.4445C24.593 12.4445 25.3889 13.2404 25.3889 14.2222V23.1111C25.3889 25.0748 23.797 26.6667 21.8334 26.6667H12.9445C10.9808 26.6667 9.38892 25.0748 9.38892 23.1111V14.2222C9.38892 13.2404 10.1849 12.4445 11.1667 12.4445C11.1667 12.4445 11.1667 11.8574 11.1667 11.5556ZM21.8353 12.4445C21.8353 12.4445 21.8334 11.86 21.8334 11.5556C21.8334 9.10097 19.8435 7.11112 17.3889 7.11112C14.9343 7.11112 12.9445 9.10097 12.9445 11.5556C12.9445 11.86 12.9445 12.4445 12.9445 12.4445H21.8353ZM11.1667 14.2222V23.1111C11.1667 24.093 11.9626 24.8889 12.9445 24.8889H21.8334C22.8152 24.8889 23.6111 24.093 23.6111 23.1111V14.2222H11.1667ZM18.2778 21.3333H16.5V17.7778H18.2778V21.3333Z"
            fill="#4843AF"
        />
    </svg>
);

export const nameMapping = {
    [PROFRESSIONAL_PLAN]: 'professional',
};

const starterPlanEligible = (plan) => {
    return [TRIAL_PLAN, FREE_PLAN, STARTER_PLAN].includes(plan);
};
const growthPlanEligible = (plan) => {
    return [TRIAL_PLAN, GROWTH_PLAN, BUSINESS_PLAN, ENTERPRISE_PLAN].includes(plan);
};
const businessPlanEligible = (plan) => {
    return [TRIAL_PLAN, BUSINESS_PLAN, ENTERPRISE_PLAN].includes(plan);
};
const featuredPlanEligible = (plan) => {
    return [TRIAL_PLAN, GROWTH_PLAN, BUSINESS_PLAN, PROFRESSIONAL_PLAN, ENTERPRISE_PLAN].includes(
        plan
    );
};

const planTypeFuncs = {
    starter: starterPlanEligible,
    growth: growthPlanEligible,
    business: businessPlanEligible,
    featured: featuredPlanEligible,
};

export const PlanNotification = ({ children, text = '', type, iconOnly = false }) => {
    const dispatch = useAppDispatch();
    const [isLoading, setLoading] = useState(true);

    const settings = useAppSelector(selectSettings);
    const account = useSelector(selectAccount);
    const siteData = useSelector(selectSiteData);

    useEffect(async () => {
        await dispatch(fetchSettingsApi());
        setLoading(false);
    }, [dispatch]);
    // if (isLoading) {
    //     return (
    //         <div className={classes.loading}>
    //             <Loader size="small" />
    //         </div>
    //     );
    // }
    if (!settings) return <span />;
    const eligible = planTypeFuncs[type](settings?.plan);
    const withinFreeTrial = settings?.plan === 'trial' && settings?.trialDaysLeft > 0;

    const upgradeLink =
        window.brand === 'wix'
            ? 'https://manage.wix.com/apps/upgrade/event-viewer'
            : `${process.env.DASHBOARD_CLIENT_URL}/?site=${siteData.site}&widget=${siteData.widget}#/events/${siteData.site}/billing`;

    if (
        !iconOnly &&
        (isLoading ||
            eligible ||
            account?.feature_flags?.force_business_plan ||
            account?.trial_days_left > 0 ||
            withinFreeTrial)
    ) {
        return (
            <Box direction="vertical" gap={2} width={'100%'}>
                {withinFreeTrial && (
                    <Box align="left" verticalAlign="middle" color="P10" direction="vertical">
                        <span className={classes.text}>
                            You are a within Free Trial but <b>{capitalize(text)}</b> is part of the{' '}
                            <b>{capitalize(nameMapping[type] || type)}</b> plan.{' '}
                            <a href={upgradeLink}>Upgrade</a> within{' '}
                            <b>{settings?.trialDaysLeft}</b>{' '}
                            {pluralize('day', settings?.trialDaysLeft)} to continue using the
                            feature.
                        </span>
                    </Box>
                )}
                {children}
            </Box>
        );
    }

    if (iconOnly && !eligible) {
        return (
            <Box verticalAlign="middle">
                <Box className={classes.block}>{children}</Box>
                <Tooltip
                    content={`Upgrade Required: "${capitalize(
                        text
                    )}" is available on the ${capitalize(nameMapping[type] || type)} plan`}
                >
                    <Icons.LockLocked className={classes.lock} />
                </Tooltip>
            </Box>
        );
    }
    if (iconOnly && eligible) {
        return <>{children}</>;
    }

    return (
        <Box direction="vertical" width={'100%'}>
            {!eligible && (
                <Box
                    align="left"
                    alignItems="center"
                    verticalAlign="middle"
                    color="P10"
                    gap={1}
                    direction="vertical"
                    marginBottom={2}
                >
                    <Box gap={1} className={classes.text}>
                        {capitalize(nameMapping[type] || type)} Plan{' '}
                        <Box>
                            <LockIcon />
                        </Box>
                    </Box>
                </Box>
            )}
            <div className={classes.block}>{children}</div>
            <Box marginTop={2} gap={1}>
                Want to use this locked Feature?{' '}
                <a
                    href={upgradeLink}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.upgradeButton}
                >
                    Upgrade Now
                </a>
            </Box>
        </Box>
    );
};
