import React, { useCallback, useState } from 'react';
import { Box, Divider, FormField } from 'wix-style-react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { debounce } from 'lodash';
import { selectSettings, updateSettingsApi } from '../features/settings-slice';
import themes from '../modules/settings/design/themes.json';

const url = new URL(process.env.WIDGET_CLIENT_URL);
const WIDGET_ORIGIN = url.origin;

export const createThemePayload = (val) => {
    const { text, colors, id } = val;
    const fontKeys = Object.keys(text);
    const fortsFormatted = fontKeys.reduce((result, fontKey) => {
        const font = text[fontKey];
        const min = Math.min(font.size / 15, 1.3);

        const val = `${font.style.italics ? 'italics' : 'normal'} normal ${
            font.style.bold ? 'bold' : 'normal'
        } ${font.size}px/${Math.max(min, 1.3)}em ${font.family};`;

        return {
            ...result,
            id,
            [fontKey]: { value: `font: ${val}` },
        };
    }, {});
    const colorKeys = Object.keys(colors);
    const colorsFormatted = colorKeys.reduce((result, colorKey) => {
        const color = colors[colorKey];

        return {
            ...result,
            [colorKey]: { value: color },
        };
    }, {});
    return { ...colorsFormatted, ...fortsFormatted, id };
};
export const ThemeDesignUpdater = (props) => {
    return <WidgetDesignUpdater {...props} setKey="theme" setPayload={createThemePayload} />;
};

const getTheme = (theme) => theme || createThemePayload(themes[0]);
export const ComponentColorUpdater = (props) => {
    const settings = useAppSelector(selectSettings);
    const theme = getTheme(settings?.design?.theme);
    const updateKey = props.updateKey;
    const overrides = settings?.design && settings?.design?.overrides;
    const keyValue = overrides && overrides[updateKey] && overrides[updateKey]?.value;

    return (
        <WidgetDesignUpdater
            {...props}
            initialValue={keyValue || theme[props.startWithColor]?.value}
            setKey="overrides"
            setPayload={(val) => ({
                [props.updateKey]: { value: val || theme[props.startWithColor] },
            })}
        />
    );
};
export const ComponentFontUpdater = (props) => {
    const settings = useAppSelector(selectSettings);
    const theme = getTheme(settings?.design?.theme);

    const updateKey = props.updateKey;
    const overrides = settings?.design && settings?.design?.overrides;
    const keyValue = overrides && overrides[updateKey] && overrides[updateKey];
    return (
        <WidgetDesignUpdater
            {...props}
            initialValue={keyValue || theme[props.startWithTheme]}
            setKey="overrides"
            setPayload={(val) => ({
                [props.updateKey]: val || theme[props.startWithColor],
            })}
        />
    );
};

const WidgetDesignUpdater = ({
    children,
    title,
    setKey,
    setPayload,
    initialValue,
    tooltipContent,
    includeDivider = true,
}) => {
    const [loading, setLoading] = useState(false);
    const settings = useAppSelector(selectSettings);

    const [value, setValue] = useState(initialValue);
    const dispatch = useAppDispatch();

    const handleChange = useCallback(
        async (val, doNotSave) => {
            setValue(val);
            try {
                const currentProps = settings.design && settings.design[setKey];
                let newData = {
                    ...settings.design,
                    [setKey]: { ...(currentProps ? currentProps : {}), ...setPayload(val) },
                };
                if (setKey === 'theme') {
                    newData = {
                        ...newData,
                        overrides: {},
                    };
                }

                const data = {
                    type: 'design',
                    resizeWidget: true,
                    ...newData,
                };
                const iframe = document.querySelector('iframe');
                iframe.contentWindow.postMessage(
                    JSON.stringify(data),
                    process.env.WIDGET_CLIENT_URL
                );
                if (!doNotSave) {
                    await saveChanges(newData);
                }
            } catch (e) {
                console.error(e);
                setLoading(false);
            }
        },
        [settings]
    );
    const saveChanges = useCallback(
        debounce(async (newData) => {
            setLoading(true);
            await dispatch(updateSettingsApi({ design: { ...settings.design, ...newData } }));

            setLoading(false);
        }, 800),
        []
    );

    return (
        <Box direction="vertical">
            <FormField label={title} labelPlacement="top" infoContent={tooltipContent}>
                {React.cloneElement(children as any, {
                    onChange: handleChange,
                    onConfirm: handleChange,
                    onSelect: handleChange,
                    disabled: loading,
                    value: value,
                    selectedId: value,
                })}
            </FormField>
            {includeDivider && (
                <Box marginBottom={5} marginTop={5}>
                    <Divider direction="horizontal" />
                </Box>
            )}
        </Box>
    );
};
export const DesignReset = ({ children }) => {
    const [loading, setLoading] = useState(false);

    const dispatch = useAppDispatch();

    const handleChange = useCallback(async (val) => {
        try {
            const newData = {
                theme: createThemePayload(themes[0]),
                overrides: themes[0]?.overrides,
            };
            createThemePayload;
            const data = {
                type: 'design',
                resizeWidget: true,
                ...newData,
            };
            const iframe = document.querySelector('iframe');
            iframe.contentWindow.postMessage(JSON.stringify(data), WIDGET_ORIGIN);
            setLoading(true);
            await dispatch(updateSettingsApi({ design: newData }));
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    }, []);

    return (
        <>
            {React.cloneElement(children as any, {
                onClick: handleChange,
                disabled: loading,
                status: loading ? 'loading' : '',
            })}
        </>
    );
};
