import { useEffect, useCallback, useState } from 'react';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import * as Icons from 'wix-ui-icons-common';

import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    Cell,
    Layout,
    Page,
    Table,
    TableActionCell,
} from 'wix-style-react';

import { useAppDispatch } from '../../hooks';
import {
    deleteTeamMember,
    fetchSite,
    fetchTeamMembers,
    selectSite,
    selectSiteTeam,
} from '../../features/site-slice';
import { useSelector } from 'react-redux';
import { TeamInviteModel } from './team-invite-modal';

export const TeamInvite = () => {
    const { site_id } = useParams<{ site_id: string }>();
    const dispatch = useAppDispatch();
    const [currentMember, setCurrentMember] = useState();
    const history = useHistory();

    const team = useSelector(selectSiteTeam);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchTeamMembers(site_id));
        };
        void fetchData();
    }, [site_id]);

    const site = useSelector(selectSite);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchSite(site_id));
        };
        void fetchData();
    }, [dispatch, site_id]);

    const handleRemoveTeam = useCallback(
        async (id: string) => {
            await dispatch(deleteTeamMember({ siteId: site_id, id }));
        },
        [dispatch, site_id]
    );
    const secondaryActions = (member) => [
        {
            text: 'Change role',
            icon: <Icons.EditSmall />,
            onClick: () => {
                setCurrentMember(member);
            },
        },
        {
            text: 'Remove',
            icon: <Icons.DeleteSmall />,
            onClick: () => handleRemoveTeam(member.id),
        },
    ];
    const columns = [
        { title: 'Email', render: (row) => row.email },
        { title: 'Role', render: (row) => row.roles[0] },
        { title: 'Invited at', render: (row) => moment(row.created_at).format('LLL') },
        {
            title: '',
            render: (rowData) =>
                !rowData.roles.includes('OWNER') && (
                    <TableActionCell size="small" secondaryActions={secondaryActions(rowData)} />
                ),
        },
    ];

    return (
        <Page height="60vh">
            <Page.Header
                title="Team Invite"
                subtitle="Invite your event staff to join the platform and specify their operational roles."
                actionsBar={
                    <Box gap="SP2">
                        <Button skin="inverted" onClick={() => history.push(`/sites`)}>
                            Cancel
                        </Button>
                        <TeamInviteModel siteId={site_id} />
                    </Box>
                }
                breadcrumbs={
                    <Breadcrumbs
                        activeId="2"
                        items={[
                            { id: '1', value: 'Sites' },
                            { id: '2', value: `"${site?.site_display_name}" Team Invite` },
                        ]}
                        onClick={({ id }) => {
                            if (id === '1') {
                                history.push(`/sites`);
                            }
                        }}
                    />
                }
            />
            <Page.Content>
                <Layout>
                    <Cell>
                        <Card>
                            <Card.Divider />
                            <Card.Content>
                                {currentMember && (
                                    <TeamInviteModel
                                        siteId={site_id}
                                        member={currentMember}
                                        onClose={() => setCurrentMember(null)}
                                    />
                                )}

                                <Table data={team} columns={columns}>
                                    <Table.Content />
                                </Table>
                            </Card.Content>
                        </Card>
                    </Cell>
                </Layout>
            </Page.Content>
        </Page>
    );
};
