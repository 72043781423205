import {
    Box,
    Card,
    Dropdown,
    TableActionCell,
    TableToolbar,
    Search,
    Table,
    Page,
    MarketingPageLayout,
    MarketingPageLayoutContent,
    EmptyState,
    Image,
    StatisticsWidget,
} from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import { classes } from './create-campaign.st.css';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { fetchCampaignLogsApi, selectCampaignLogsList } from '../../features/campaign-log-slice';
import type { Campaign, CampaignLog } from '../../types';
import { useAppDispatch } from '../../hooks';
import { WorkflowTable } from './components/workflow-table';
import { useMemo, useEffect } from 'react';
import moment from 'moment';
import { buildURL } from '../../features/utils';
import { selectSiteData } from '../../features/account-slice';
import NoAttendees from '../../assets/no-attendees.gif';

const NoCampaignLog = () => {
    return (
        <EmptyState
            theme="page"
            image={<Image width="120px" height="120px" src={NoAttendees} transparent />}
            title="No Logs"
            subtitle="Once you engagement action (Email, SMS) has been executed it will appear here"
        />
    );
};

export const CampaignLog = () => {
    const { id } = useParams<{ id: string }>();

    const [activeSearch, setActiveSearch] = useState('');
    const site = useSelector(selectSiteData);

    const dispatch = useAppDispatch();
    const history = useHistory();
    const campaignLog = useSelector(selectCampaignLogsList);
    const selectedCampaigns = useMemo(() => {
        return campaignLog
            ?.map((log) => ({ ...log, expandable: true, expanded: false }))
            ?.filter(
                (log: CampaignLog) =>
                    log?.attendee?.customer?.email?.includes(activeSearch) ||
                    log?.attendee?.customer?.first_name?.includes(activeSearch) ||
                    log?.attendee?.customer?.last_name?.includes(activeSearch)
            );
    }, [campaignLog, activeSearch]);

    useEffect(() => {
        dispatch(fetchCampaignLogsApi(id));
    }, [dispatch]);

    const renderRowDetails = (row) => {
        if (row.expandable) {
            return (
                <Box padding="20px">
                    <div
                        className={classes.message}
                        dangerouslySetInnerHTML={{
                            __html: row.message,
                        }}
                    />
                </Box>
            );
        }
    };

    const columns = [
        {
            title: 'Recipient',
            render: (row: Campaign) =>
                `${row?.attendee?.customer?.first_name} ${row?.attendee?.customer?.last_name} <${row?.attendee?.customer?.email}>`,
        },
        // {
        //     title: 'Event',
        //     render: (row: CampaignLog) => row.event?.title,
        // },
        {
            title: 'Sent',
            render: (row: CampaignLog) => moment(row.created_at).format('llll'),
        },
        {
            title: 'Event title',
            render: (row: CampaignLog) => row?.event?.title,
        },
        {
            width: '20%',
            render: (row) => (
                <TableActionCell
                    numOfVisibleSecondaryActions={1}
                    secondaryActions={[
                        {
                            icon: <Icons.User />,
                            onClick: () => {
                                history.push(
                                    buildURL(
                                        site,
                                        `/attendees/manage/${row?.attendee?.customer?.id}`
                                    )
                                );
                            },
                            text: 'View Attendee',
                        },
                    ]}
                    primaryAction={{
                        text: !row.expanded ? 'Message' : 'Hide',
                        skin: 'inverted',
                        onClick: (row.expanded = !row.expanded),
                    }}
                />
            ),
        },
    ];

    const MainToolbar = () => {
        return (
            <TableToolbar>
                <TableToolbar.ItemGroup position="start">
                    <TableToolbar.Item></TableToolbar.Item>
                </TableToolbar.ItemGroup>
                <TableToolbar.ItemGroup position="end">
                    {/* <TableToolbar.Item>
            <TableToolbar.Label>
              Filter by
              <Box width="175">
                <Dropdown
                //   options={filterOptions}
                //   selectedId={filterBy}
                  roundInput
                //   onSelect={({ id }) => setFilterBy(id)}
                  popoverProps={{ appendTo: 'window' }}
                />
              </Box>
            </TableToolbar.Label>
          </TableToolbar.Item> */}
                    <TableToolbar.Item>
                        <Box width="200">
                            <Search
                                placeholder="Search for a contact"
                                value={activeSearch}
                                onChange={(event) => setActiveSearch(event.target.value)}
                            />
                        </Box>
                    </TableToolbar.Item>
                </TableToolbar.ItemGroup>
            </TableToolbar>
        );
    };

    const noCampaigns = campaignLog && campaignLog.length === 0;
    return (
        <>
            <Page>
                <Page.Header
                    title="Engagement Log"
                    showBackButton
                    onBackClicked={() => window.history.back()}
                />
                <Page.Content>
                    <WorkflowTable campaignId={id} />
                    <Box height={20} />
                    <Card>
                        <Card.Header
                            title="Action Log"
                            subtitle="View Executed Actions such as Emails and SMS Details"
                        />
                        <Card.Content>
                            <StatisticsWidget
                                items={[
                                    {
                                        value: selectedCampaigns?.length,
                                        description: 'Actions Executed',
                                    },
                                ]}
                            />
                            {noCampaigns ? (
                                <NoCampaignLog />
                            ) : (
                                <Table
                                    showSelection
                                    rowDetails={(row) => renderRowDetails(row)}
                                    data={selectedCampaigns}
                                    columns={columns}
                                >
                                    <Table.ToolbarContainer>
                                        {() => MainToolbar()}
                                    </Table.ToolbarContainer>
                                    <Table.Content />
                                </Table>
                            )}
                        </Card.Content>
                    </Card>
                </Page.Content>
            </Page>
            ;
        </>
    );
};
