import { WidgetSettingsUpdater } from '../../../components/widget-settings-updater';
import { NumberInput, RadioGroup, ToggleSwitch } from 'wix-style-react';
import { SettingsHeader } from '../../../components/settings-header';
import { useAppSelector } from '../../../hooks';
import { selectSettings } from '../../../features/settings-slice';

export const EventAmount = ({ label }) => {
    const settings = useAppSelector(selectSettings);

    return (
        <>
            <SettingsHeader title={label} />

            <WidgetSettingsUpdater
                updateKey="showAllEvents"
                isPremium
                tooltipText="Enabling this option will automatically display all of your available events."
                title="Show All Available Events"
                shouldFetchEvents
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            {!settings.showAllEvents && (
                <WidgetSettingsUpdater
                    updateKey="maxEvents"
                    isPremium
                    tooltipText="This setting allows you to specify a fixed number of events to display on the page. Once configured, the chosen number of events will be shown, and no more than the specified limit will be displayed at any given time."
                    title="Limit Total Events"
                    shouldFetchEvents
                    transform={(val) => +val}
                >
                    <NumberInput />
                </WidgetSettingsUpdater>
            )}

            <WidgetSettingsUpdater updateKey="displayOrderBy" isPremium title="Event Display Order">
                <RadioGroup>
                    {[
                        { value: 'start_asc', label: 'Start Time Ascending' },
                        { value: 'start_desc', label: 'Start Time Descending' },
                    ].map((val, index) => (
                        <RadioGroup.Radio key={index} value={val.value}>
                            {val.label}
                        </RadioGroup.Radio>
                    ))}
                </RadioGroup>
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                title="Display 'Load More' Button"
                updateKey="enablePagination"
                isPremium
                tooltipText="Display a limited set of events at a time to make it easier for users to browse through numerous listings. This helps when you have a large number of events to showcase, allowing users to load more as needed."
                resizeWidget
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
            {settings.enablePagination && (
                <WidgetSettingsUpdater
                    updateKey="eventPageSize"
                    isPremium
                    tooltipText="Adjust the number of events displayed at once when clicking to load more events"
                    title="Events Per Load"
                    resizeWidget
                >
                    <NumberInput />
                </WidgetSettingsUpdater>
            )}
        </>
    );
};
