import { useState } from 'react';
import { useHistory } from 'react-router';
import { Modal, AnnouncementModalLayout, Text } from 'wix-style-react';
import { selectSingleCampaign, startCampaignsApi } from '../../../features/campaign-slice';
import { useAppSelector, useAppDispatch } from '../../../hooks';
import StartCampaign from '../../../assets/start-campaign.gif';
import { classes } from './start-campaign-modal.st.css';
import { buildURL } from '../../../features/utils';
import { useSelector } from 'react-redux';
import { selectSiteData } from '../../../features/account-slice';

export const StartCampaignModel = ({ completePath = `/events/engage-promote` }) => {
    const campaign = useAppSelector(selectSingleCampaign);
    const dispatch = useAppDispatch();
    const site = useSelector(selectSiteData);
    const [isModalOpened, setModalOpened] = useState(true);
    const history = useHistory();

    if (!campaign) {
        return <></>;
    }
    const closeModal = () => setModalOpened(false);
    return (
        <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
            <AnnouncementModalLayout
                className={classes.announcement}
                illustration={StartCampaign}
                title={`Start your campaign`}
                primaryButtonText="Start Now"
                secondaryButtonText="Skip"
                onCloseButtonClick={() => {
                    history.push(buildURL(site, completePath));
                    closeModal();
                }}
                secondaryButtonOnClick={() => {
                    history.push(buildURL(site, completePath));
                    closeModal();
                }}
                primaryButtonOnClick={() => {
                    dispatch(startCampaignsApi(campaign)).then(() => {
                        history.push(buildURL(site, completePath));
                    });
                }}
            >
                <Text>
                    Increase event attendance and stay connected to your audience. Start your
                    campaign now.
                </Text>
            </AnnouncementModalLayout>
        </Modal>
    );
};
