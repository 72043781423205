import { Box, Card, Stepper, Button, MarketingLayout, Image, Text, Loader } from 'wix-style-react';
import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import GetStartedSmall from 'wix-ui-icons-common/GetStartedSmall';
import AIWriter from 'react-aiwriter';

import type { Event } from '../../types';
import moment from 'moment-timezone';
import { classes } from './create-ai-event.st.css';
import { Engagement } from './components/ai/engagement';
import { EventType } from './components/ai/event-type';
import { EventLocation } from './components/ai/event-location';
import CreateEventWithAIImage from '../../assets/create-event-with-ai.svg';
import SuccessImage from '../../assets/ai-event-success.gif';
import GenerateEventSvg from '../../assets/generate-ai-event.svg';
import { EventTimeDate } from './components/ai/time-date';
import { addTimeToDate } from './create-event';
import { createAIEventApi } from '../../features/events-slice';
import { useAppDispatch } from '../../hooks';
import { buildURL } from '../../features/utils';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSiteData } from '../../features/account-slice';
import { EVENT_TYPES, EVENT_CATEGORIES, EVENT_SUBCATEGORIES } from './type-and-categories';
import { getCurrency } from './currency-utils';
import { PlanNotification } from '../../components/plan-notification';

export const CreateEventWithAI = ({ closeModal }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [newEvent, setEvent] = useState(null);
    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [getStated, setGetStarted] = useState(false);
    const dispatch = useAppDispatch();
    const site = useSelector(selectSiteData);
    const history = useHistory();

    const { handleSubmit, watch, control, setValue, formState, trigger, getValues } = useForm<any>({
        defaultValues: {
            time_start: moment().add(1, 'hour').startOf('hour'),
            time_end: moment().add(3, 'hour').startOf('hour'),
            date_start: moment().add(1, 'day').toDate(),
            date_end: moment().add(1, 'day').toDate(),
            timezone: moment.tz.guess(),
            repeating_until: new Date(),
            event_setup: 'tickets',
            is_online: false,
            repeat_on: 'weekly',
            is_repeating_event: false,
            create_event_promotion: true,
            create_ticket_order_confirmation: true,
        },
    });
    const { errors } = formState;

    const watchEventSetup = watch('event_setup');

    const onSubmit: SubmitHandler<Event> = async (values: any) => {
        setSaving(true);
        const repeatDays = Object.keys(values)
            .filter((v) => v.startsWith('repeat_day'))
            .map((v) => ({ day: v.replace('repeat_day_', ''), value: !!values[v] }));

        let endDate = values.date_end;
        // if (!values.repeat) {
        //     endDate = new Date(values.dateStart.valueOf());
        // }

        addTimeToDate(values.date_start, values.time_start);
        addTimeToDate(
            endDate,
            values.all_day_event ? values.time_start.add(1, 'hour') : values.time_end
        );

        const venue = {
            multi_line_address_display: values?.address?.formatted || values.location,
            longitude: values?.address?.latLng?.lng,
            address: {
                region: values?.address?.state,
                country: values?.address?.countryCode,
                postal_code: values?.address?.postalCode,
                address_1: values?.address?.street,
                city: values?.address?.city,
            },
            latitude: values?.address?.latLng?.lat,
        };
        const gridId = venue?.latitude
            ? `${venue.latitude.toFixed(2)}:${venue.longitude.toFixed(2)}`
            : undefined;
        const data = {
            ...values,
            start_date: values.date_start,
            end_date: endDate,
            repeating_days: repeatDays,
            grid_id: gridId,
            venue,
            type: EVENT_TYPES.find((type) => type.id === values.type),
            category: EVENT_CATEGORIES.find((c) => c.id === values.category),
            subcategory: EVENT_SUBCATEGORIES.find((c) => c.id === values.subcategory),
            currency: getCurrency(),
        };

        const newEvent = await dispatch(createAIEventApi({ ...values, ...data }));
        setEvent(newEvent.payload);
        setTimeout(() => setSaving(false), 2000);
        setSaved(true);
    };

    const baseSteps = [
        {
            text: 'Event Type',
            component: (
                <EventType watch={watch} control={control} setValue={setValue} errors={errors} />
            ),
        },
        {
            text: 'Date/Time',
            component: (
                <EventTimeDate
                    watch={watch}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    getValues={getValues}
                />
            ),
        },
        {
            text: 'Location',
            component: <EventLocation watch={watch} control={control} setValue={setValue} />,
        },
        {
            text: 'Engagement',
            component: <Engagement watch={watch} control={control} setValue={setValue} />,
        },
        // {
        //     text: 'Promotion',
        //     component: <EventPromotion watch={watch} control={control} setValue={setValue} />,
        // },
    ];
    const activeSteps = baseSteps.filter(
        (step) => !(step.excludes || []).includes(watchEventSetup)
    );
    const steps = activeSteps.map((step, index) => {
        let type = '';
        if (activeStep > index) {
            type = 'completed';
        } else if (activeStep < index) {
            type = 'disabled';
        }
        return {
            text: step.text,
            type,
        };
    });

    if (saving) {
        return (
            <Box width="100%" height="100%" align="center">
                <Loader size="medium" text="Sit tight while our A.I. creates your event!" />
            </Box>
        );
    }
    if (saved) {
        return (
            <Box
                width="100%"
                height="100%"
                align="center"
                direction="vertical"
                gap={2}
                marginTop={1}
            >
                <Box marginBottom={3} gap={2}>
                    <Text className={classes.successText}>
                        Thanks to the power of AI, your event has been successfully created.
                    </Text>
                </Box>
                <Box width={'100%'} align="center" marginTop={3} direction="vertical" gap={2}>
                    <img src={SuccessImage} width={300} />
                    <Button
                        className={classes.viewEventButton}
                        size="large"
                        onClick={() => {
                            history.push(buildURL(site, `/events/manage/${newEvent.id}`));
                            closeModal();
                        }}
                    >
                        View Event
                    </Button>
                </Box>
            </Box>
        );
    }
    if (!getStated) {
        return (
            <Box>
                <MarketingLayout
                    description={
                        <AIWriter delay={50}>
                            <Box direction="vertical" gap={1}>
                                <Text>
                                    Ready to take the hassle out of event planning? Simply provide
                                    us with some basic information about the type of event you want
                                    to host, and let AI do the rest!
                                </Text>
                                <Text>
                                    Sit back, relax, and let AI take your event to the next level!
                                </Text>
                            </Box>
                        </AIWriter>
                    }
                    actions={
                        <PlanNotification type="featured" text="AI Quick Start" iconOnly>
                            <Box marginTop={5}>
                                <Button
                                    onClick={() => setGetStarted(true)}
                                    size="large"
                                    prefixIcon={<GetStartedSmall />}
                                >
                                    Get Started
                                </Button>
                            </Box>
                        </PlanNotification>
                    }
                    size="tiny"
                    hiddenBadge
                    alignItems="stretch"
                    image={
                        <Box width="100%" align="right">
                            <Image width="120px" src={CreateEventWithAIImage} transparent />
                        </Box>
                    }
                />
            </Box>
        );
    }

    return (
        <>
            <Box direction="vertical">
                <Box className={classes.wrap}>
                    <Stepper
                        fit="compact"
                        activeStep={activeStep}
                        steps={steps}
                        onClick={(activeStep) => setActiveStep(activeStep)}
                    />
                </Box>
                <Box marginTop={5} />

                <Card.Divider />
                <Card.Content>
                    {activeSteps[activeStep].component}
                    <Box align="center" marginTop={4} gap={3}>
                        {activeStep < steps.length - 1 ? (
                            <Button
                                size="large"
                                onClick={async () => {
                                    const noErrors = await trigger();
                                    // await handleSubmit(onSubmit());
                                    if (noErrors) {
                                        setActiveStep(activeStep + 1);
                                    }
                                }}
                            >
                                Save and Then: {activeSteps[activeStep + 1].text}
                            </Button>
                        ) : (
                            <Button
                                size="large"
                                suffixIcon={<img src={GenerateEventSvg} />}
                                className={classes.viewEventButton}
                                skin="standard"
                                onClick={handleSubmit(onSubmit)}
                            >
                                Generate
                            </Button>
                        )}
                    </Box>
                </Card.Content>
            </Box>
        </>
    );
};

export default CreateEventWithAI;
