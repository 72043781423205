import { SettingsHeader } from '../../../components/settings-header';
import { TextInput } from './text-input';

export const CheckoutSectionLabelsText = ({ label }) => {
    return (
        <>
            <SettingsHeader title={label} />

            <TextInput
                maxLength={200}
                title="Tickets Section Label"
                tooltipText="Label for the section where attendees select their tickets."
                updateKey="checkoutAttendeeTicketsStepText"
            />
            <TextInput
                maxLength={200}
                title="Date/Time Section Label"
                tooltipText="Label for the section where attendees select the dates or times."
                updateKey="checkoutAttendeeDateTimeStepText"
            />
            <TextInput
                maxLength={200}
                title="Times Section Label"
                tooltipText="Label for the section where attendees select the times only."
                updateKey="checkoutAttendeeTimesStepText"
            />
            <TextInput
                maxLength={200}
                title="Dates Section Label"
                tooltipText="Label for the section where attendees select the dates only."
                updateKey="checkoutAttendeeDatesStepText"
            />
            <TextInput
                maxLength={200}
                title="Details Section Label"
                tooltipText="Label for the section where attendees enter their details."
                updateKey="checkoutAttendeeDetailsStepText"
            />
            <TextInput
                maxLength={200}
                title="Waitlist Details Section Label"
                tooltipText="Label for the section where attendees enter their details for the waitlist."
                updateKey="checkoutAttendeeWaitlistDetailsStepText"
            />
            <TextInput
                maxLength={200}
                title="Payment Section Label"
                tooltipText="Label for the section where users enter their payment details."
                updateKey="checkoutAttendeePaymentStepText"
            />
        </>
    );
};
