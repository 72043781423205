import { SettingsHeader } from '../../../components/settings-header';
import { TextInput } from './text-input';

export const CheckoutAttendeeInfoText = ({ label }) => {
    return (
        <>
            <SettingsHeader title={label} />

            <TextInput
                maxLength={300}
                title="Attendee First Name Label"
                tooltipText="Label for the attendee first name input."
                updateKey="checkoutFormFirstName"
            />
            <TextInput
                maxLength={300}
                title="Attendee Last Name Label"
                tooltipText="Label for the attendee last name input."
                updateKey="checkoutFormLastName"
            />
            <TextInput
                maxLength={300}
                title="Attendee Email Label"
                tooltipText="Label for the attendee email input."
                updateKey="checkoutFormEmail"
            />
            <TextInput
                maxLength={300}
                title="Attendee Count Label"
                tooltipText="The text displayed alongside the number of tickets purchased when collecting attendee information for each ticket."
                updateKey="checkoutForAttendeeCountLabel"
            />
        </>
    );
};
