import { useState, useEffect } from 'react';
import { chunk } from 'lodash';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
    Layout,
    Cell,
    Card,
    FormField,
    Input,
    CustomModalLayout,
    Box,
    Button,
    Modal,
    InputStatus,
    Tooltip,
} from 'wix-style-react';
import { classes } from './custom-fields.st.css';
import * as Icons from 'wix-ui-icons-common';

export const CustomFields = ({
    reservedNames,
    customFields = [],
    setParentValue,
    setCustomFields,
    parentControl,
    type,
}) => {
    const [isModalOpened, setModalOpened] = useState(false);
    const [selectedField, setSelectedField] = useState();

    const { handleSubmit, control, setValue, formState, watch, reset, clearErrors } = useForm<any>({
        defaultValues: customFields.reduce((res, curr) => ({ ...res, [curr.id]: curr.value }), {}),
    });

    const { errors } = formState;

    useEffect(() => {
        customFields.forEach((curr) => {
            setParentValue(curr.id, curr.value);
        });
    }, [customFields]);

    const onSubmit: SubmitHandler<any> = async (values) => {
        setCustomFields(customFields.concat(values));
        reset(null);
        closeModal();
    };

    const openModal = () => setModalOpened(true);
    const closeModal = () => {
        setSelectedField(null);
        reset(null);
        setModalOpened(false);
    };

    const deleteCustomField = (field) => {
        setCustomFields(
            customFields.map((f) => (f.id === field.id ? { ...f, is_deleted: true } : f))
        );
    };

    const watchName = watch('name', selectedField?.name, '');
    const inputId = watchName ? watchName?.toLowerCase()?.replace(/\s+/g, '_') : '';

    const renderForm = () => (
        <Layout>
            <Cell span={12}>
                <FormField label="Name" required>
                    <Controller
                        control={control}
                        name="name"
                        rules={{
                            required: 'Name is required',
                            validate: (val) => {
                                return !(
                                    reservedNames.includes(val) || customFields.map((c) => c.name)
                                ).includes(val);
                            },
                        }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                status={
                                    errors.name?.message ? Input.StatusError : ('' as InputStatus)
                                }
                                onChange={(val) => {
                                    const id = val.target.value
                                        ? val.target.value?.toLowerCase()?.replace(/\s+/g, '_')
                                        : '';
                                    setValue('name', val.target.value);
                                    setValue('id', id);
                                    clearErrors('name');
                                }}
                            />
                        )}
                    />

                    <small className={classes.error}>{errors.name?.message}</small>
                    {errors?.name?.type === 'validate' && (
                        <small className={classes.error}>{watchName} has already been taken</small>
                    )}
                </FormField>
            </Cell>
            <Cell span={12}>
                <FormField
                    label="message personalization ID"
                    infoContent={`Use this ID to personalize your emails in your email campaigns. For this field - {{${type}_${inputId}}}`}
                >
                    <Controller
                        control={control}
                        name="id"
                        render={({ field }) => <Input disabled {...field} value={inputId} />}
                    />
                </FormField>
            </Cell>
        </Layout>
    );

    const renderModalContent = () => (
        <CustomModalLayout
            primaryButtonText={`${selectedField ? 'Update' : 'Create'}  `}
            secondaryButtonText="Cancel"
            onCloseButtonClick={closeModal}
            primaryButtonOnClick={handleSubmit(onSubmit)}
            secondaryButtonOnClick={closeModal}
            title={`${selectedField ? 'Update' : 'Create'} custom field`}
            width="600px"
        >
            {renderForm()}
        </CustomModalLayout>
    );
    const customFieldsGroup = chunk(customFields, 3);

    return (
        <Box
            direction="vertical"
            width="90%"
            boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
        >
            <Card>
                <Card.Header
                    title="Custom Fields"
                    subtitle={`Customize your event by creating additional properties to store extra details. Use these properties in the automation workflow or RSVP form to streamline your event planning process.`}
                    suffix={
                        <Button size="small" prefixIcon={<Icons.AddSmall />} onClick={openModal}>
                            Create
                        </Button>
                    }
                />
            </Card>
            <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
                {renderModalContent()}
            </Modal>
            <Layout>
                {customFieldsGroup.map((propGroup) => {
                    return propGroup.map((prop) => (
                        <Cell span={4}>
                            <FormField
                                label={
                                    <>
                                        {prop.name}{' '}
                                        <div className={classes.delete}>
                                            <Tooltip content="Delete">
                                                <Icons.Delete
                                                    onClick={() => deleteCustomField(prop)}
                                                />
                                            </Tooltip>
                                        </div>
                                    </>
                                }
                                labelPlacement="top"
                            >
                                <Controller
                                    control={parentControl}
                                    name={prop.id}
                                    render={({ field }) => {
                                        return <Input {...field} />;
                                    }}
                                ></Controller>
                            </FormField>
                        </Cell>
                    ));
                })}
            </Layout>
        </Box>
    );
};
