import {
    AnyAction,
    createAsyncThunk,
    createSlice,
    PayloadAction,
    ThunkAction,
} from '@reduxjs/toolkit';

import type { RootState } from '../store';
import type { Attendee, ResponseEvent } from '../types';
import { API_INSTANCE, buildURLWithState } from './utils';

export type AttendeeSliceState = {
    isLoading: boolean;
    isInsightsLoading: boolean;
    isAttendeesLoading: boolean;
    isAttendeesStatsLoading: boolean;
    isMoreAttendeesLoading: boolean;
    data: Attendee[];
    attendeeWorkflows: any[];
    attendeeWaitlist: any[];
    attendeeMonthlyTransactions: any[];
    attendeeDailyTransactions: any[];
    attendeeStats: any;
    current?: Attendee;
    order?: any;
    error: Error | null;
};

const initialState: AttendeeSliceState = {
    isLoading: true,
    attendeeStats: {},
    isAttendeesLoading: true,
    isAttendeesStatsLoading: true,
    isMoreAttendeesLoading: false,
    data: [],
    attendeeWorkflows: [],
    attendeeMonthlyTransactions: [],
    error: null,
};

export const fetchCustomersApi = createAsyncThunk(
    'attendees/fetch',
    async (selectedPage, { getState }) => {
        return API_INSTANCE.get<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/customers`, `&offset=${selectedPage}`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const fetchAttendeesByEventApi = createAsyncThunk(
    'attendees/fetch-by-event',
    async ({ eventId, offset = 0, limit = 10 }, { getState }) => {
        return API_INSTANCE.get<ResponseEvent>(
            buildURLWithState(
                getState(),
                `/api/v1/dashboard/attendees/events/${eventId}`,
                `&limit=${limit}&offset=${offset}`
            )
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const searchAttendeesByEventApi = createAsyncThunk(
    'attendees/search',
    async ({ eventId, offset = 0, limit = 10, criteria }, { getState }) => {
        const queryParams = new URLSearchParams(criteria).toString();
        return API_INSTANCE.get<ResponseEvent>(
            buildURLWithState(
                getState(),
                `/api/v1/dashboard/attendees/events/${eventId}/search`,
                `&limit=${limit}&offset=${offset}&${queryParams}`
            )
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const searchAttendeesStatsByEventApi = createAsyncThunk(
    'attendees/stats',
    async ({ eventId, offset = 0, limit = 10, criteria }, { getState }) => {
        const queryParams = new URLSearchParams(criteria).toString();
        return API_INSTANCE.get<ResponseEvent>(
            buildURLWithState(
                getState(),
                `/api/v1/dashboard/attendees/events/${eventId}/stats`,
                `&limit=${limit}&offset=${offset}&${queryParams}`
            )
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const fetchEventWaitlistAttendees = createAsyncThunk(
    'attendees/waitlist',
    async ({ eventId }, { getState }) => {
        return API_INSTANCE.get<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/attendees/events/${eventId}/waitlist`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const fetchAttendeesByEventApiPagination = createAsyncThunk(
    'attendees/fetch-by-event-pagination',
    async ({ eventId, offset = 0, limit = 20 }, { getState }) => {
        return API_INSTANCE.get<ResponseEvent>(
            buildURLWithState(
                getState(),
                `/api/v1/dashboard/attendees/events/${eventId}`,
                `&limit=${limit}&offset=${offset}`
            )
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const fetchWorkflowsByCustomerIdApi = createAsyncThunk(
    'attendees/fetch-workflows-by-attendee',
    async (id, { getState }) => {
        return API_INSTANCE.get<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/customers/${id}/workflows`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const fetchAttendeeMonthlyTransactions = createAsyncThunk(
    'attendees/fetch-attendee-monthly-transactions',
    async (_, { getState }) => {
        return API_INSTANCE.get<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/attendee-transactions/monthly`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const fetchAttendeeDailyTransactions = createAsyncThunk(
    'attendees/fetch-attendee-daily-transactions',
    async (_, { getState }) => {
        return API_INSTANCE.get<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/attendee-transactions/daily`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const fetchCustomerApi = createAsyncThunk(
    'customers/fetch-by-id',
    async (id: string, { getState }) => {
        return API_INSTANCE.get<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/customers/${id}`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const fetchAttendeeApi = createAsyncThunk(
    'attendee/fetch-by-id',
    async (id: string, { getState }) => {
        return API_INSTANCE.get<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/attendees/${id}`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const fetchAttendeeByEmailApi = createAsyncThunk(
    'attendee/fetch-by-email',
    async (_, { getState }) => {
        return API_INSTANCE.get<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/attendees/`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const deleteAttendeeApi = createAsyncThunk(
    'attendee/delete-by-id',
    async (id: string, { getState }) => {
        return API_INSTANCE.delete<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/attendees/${id}`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const createAttendeeApi = createAsyncThunk(
    'attendee/create-attendee',
    async (attendeeData: string, { getState }) => {
        return API_INSTANCE.post<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/attendees/`),
            attendeeData
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const addAttendeeNoteApi = createAsyncThunk(
    'attendee/add-note',
    async (data: any, { getState }) => {
        return API_INSTANCE.post<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/attendees/${data.attendee_id}/notes`),
            data
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const fetchAttendeeNotesApi = createAsyncThunk(
    'attendee/get-attendee-notes',
    async (attendeeId: any, { getState }) => {
        return API_INSTANCE.get<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/attendees/${attendeeId}/notes`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const updateAttendeeApi = createAsyncThunk(
    'attendees/update-by-id',
    async (attendee: any, { getState }) => {
        return API_INSTANCE.put<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/attendees/${attendee.id}`),
            attendee
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const convertToAttendeeApi = createAsyncThunk(
    'attendees/convert-to-attendee',
    async (attendee: any, { getState }) => {
        return API_INSTANCE.patch<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/attendees/${attendee.id}`),
            attendee
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const updateAttendeeTicketApi = createAsyncThunk(
    'attendees/update-ticket-by-id',
    async (attendee: any, { getState }) => {
        return API_INSTANCE.put<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/attendees/${attendee.id}/tickets`),
            attendee
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const cancelAndRefundAttendee = createAsyncThunk(
    'attendees/cancel-refund',
    async (data: any, { getState }) => {
        return API_INSTANCE.put<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/orders/${data.cart_id}/cancel-refund`),
            data
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const updateCustomerApi = createAsyncThunk(
    'customers/update-by-id',
    async (customer: any, { getState }) => {
        return API_INSTANCE.put<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/customers/${customer.id}`),
            customer
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const fetchOrderApi = createAsyncThunk(
    'orders/by-id',
    async (checkoutId: any, { getState }) => {
        return API_INSTANCE.get<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/orders/${checkoutId}`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const attendeesSlice = createSlice({
    name: 'attendees',
    initialState,
    reducers: {
        attendees_attempt: (state: AttendeeSliceState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload || false;
        },
        attendees_success: (state, action: PayloadAction<Attendee[]>) => {
            state.data = action.payload;
            state.isLoading = false;
        },
        attendees_error: (state, action: PayloadAction<Error>) => {
            state.error = action.payload;
            state.isLoading = false;
        },

        submit_attendee_attempt: (state) => {
            state.isLoading = true;
        },
        submit_attendee_success: (state, action: PayloadAction<Attendee>) => {
            state.data = state.data.concat(action.payload);
            state.isLoading = false;
        },
        submit_attendee_error: (state, action: PayloadAction<Error>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        update_attendee_attempt: (state) => {
            state.isLoading = true;
        },
        update_attendee_error: (state, action: PayloadAction<Error>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        single_attendee_success: (state, action: PayloadAction<Attendee>) => {
            state.current = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAttendeesByEventApi.pending, (state, action) => {
            state.data = [];
            state.attendeeStats = {};
            state.isAttendeesLoading = true;
        });
        builder.addCase(fetchAttendeesByEventApi.fulfilled, (state, action) => {
            state.data = action.payload.attendees;
            state.attendeeStats = action.payload.stats;
            state.isAttendeesLoading = false;
        });

        builder.addCase(fetchAttendeesByEventApiPagination.pending, (state, action) => {
            state.isMoreAttendeesLoading = true;
        });
        builder.addCase(fetchAttendeesByEventApiPagination.fulfilled, (state, action) => {
            state.data = [...state.data, ...action.payload];
            state.isMoreAttendeesLoading = false;
        });
        builder.addCase(searchAttendeesByEventApi.pending, (state, action) => {
            state.data = [];
            state.attendeeStats = {};
            state.isAttendeesLoading = true;
        });
        builder.addCase(searchAttendeesByEventApi.fulfilled, (state, action) => {
            state.isAttendeesLoading = false;
            state.data = action.payload;
        });
        builder.addCase(searchAttendeesStatsByEventApi.pending, (state, action) => {
            state.attendeeStats = {};
            state.isAttendeesStatsLoading = true;
        });
        builder.addCase(searchAttendeesStatsByEventApi.fulfilled, (state, action) => {
            state.isAttendeesStatsLoading = false;
            state.attendeeStats = action.payload;
        });
        builder.addCase(fetchEventWaitlistAttendees.fulfilled, (state, action) => {
            state.attendeeWaitlist = action.payload;
        });
        builder.addCase(fetchWorkflowsByCustomerIdApi.fulfilled, (state, action) => {
            state.attendeeWorkflows = action.payload;
        });
        builder.addCase(fetchAttendeeMonthlyTransactions.fulfilled, (state, action) => {
            state.isInsightsLoading = false;
            state.attendeeMonthlyTransactions = action.payload;
        });
        builder.addCase(fetchAttendeeMonthlyTransactions.pending, (state, action) => {
            state.isInsightsLoading = true;
        });
        builder.addCase(fetchAttendeeDailyTransactions.pending, (state, action) => {
            state.isInsightsLoading = true;
        });
        builder.addCase(fetchAttendeeDailyTransactions.fulfilled, (state, action) => {
            state.isInsightsLoading = false;
            state.attendeeDailyTransactions = action.payload;
        });
        builder.addCase(fetchCustomersApi.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchCustomersApi.fulfilled, (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
        });
        builder.addCase(fetchCustomerApi.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchCustomerApi.fulfilled, (state, action) => {
            state.current = {
                ...action.payload,
            };
            state.isLoading = false;
        });
        builder.addCase(fetchAttendeeApi.pending, (state, action) => {
            state.current = null;
            state.isLoading = true;
        });
        builder.addCase(fetchAttendeeApi.fulfilled, (state, action) => {
            state.current = action.payload;
            state.isLoading = false;
        });
        builder.addCase(fetchOrderApi.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchOrderApi.fulfilled, (state, action) => {
            state.order = action.payload;
            state.isLoading = false;
        });
        builder.addCase(deleteAttendeeApi.fulfilled, (state, action) => {
            state.data = state.data.filter((data) => action.meta.arg !== data.id);
        });
        // builder.addCase(createAttendeeApi.fulfilled, (state, action) => {
        //     state.data = [action.payload.attendees].concat(state.data);
        // });
        builder.addCase(fetchAttendeeByEmailApi.fulfilled, (state, action) => {
            state.data = action.payload;
        });
        builder.addCase(updateAttendeeApi.fulfilled, (state, action) => {
            // state.current = { ...state.current, ...action.payload };
        });
        builder.addCase(updateAttendeeTicketApi.fulfilled, (state, action) => {
            const attendeeIndex = state.order.attendees.findIndex(
                (attendee) => attendee.id === action.payload.id
            );
            if (attendeeIndex !== -1) {
                state.order.attendees[attendeeIndex].ticket = action.payload.ticket;
            }
        });
    },
});

export const {
    attendees_attempt,
    attendees_success,
    attendees_error,
    single_attendee_success,
    submit_attendee_success,
} = attendeesSlice.actions;

export const getAttendee =
    (id: string | number): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch, getState) => {
        const state = getState();
        const attendees = state.attendees.data;
        const currentEvent = attendees.find((item) => item.id == id);
        if (!currentEvent) return null;
        dispatch(single_attendee_success(currentEvent));
    };

export const getAttendees =
    (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch, getState) => {
        const state = getState();
        const attendees = state?.attendees?.data;
        dispatch(attendees_success(attendees));
    };

export const submitEvent =
    (data: Attendee): ThunkAction<void, RootState, unknown, AnyAction> =>
    (dispatch) => {
        dispatch(submit_attendee_success(data));
    };

export const selectAttendeesList = (state: RootState) => state?.attendees?.data;
export const selectAttendeeWorkflowList = (state: RootState) => state?.attendees?.attendeeWorkflows;
export const selectAttendee = (state: RootState) => state?.attendees?.current;
export const selectAttendeesIsLoading = (state: RootState) => state?.attendees?.isAttendeesLoading;
export const selectAttendeesStatsIsLoading = (state: RootState) =>
    state?.attendees?.isAttendeesStatsLoading;
export const selectOrder = (state: RootState) => state?.attendees?.order;
export const selectAttendeeMonthlyTransactions = (state: RootState) =>
    state?.attendees?.attendeeMonthlyTransactions;
export const selectAttendeeDailyTransactions = (state: RootState) =>
    state?.attendees?.attendeeDailyTransactions;
export const selectAttendeeInsightsLoading = (state: RootState) =>
    state?.attendees?.isInsightsLoading;
export const selectMoreAttendeesLoading = (state: RootState) =>
    state?.attendees?.isMoreAttendeesLoading;
export const selectAttendeeStats = (state: RootState) => state?.attendees?.attendeeStats;
export const selectAttendeeWaitlist = (state: RootState) => state?.attendees?.attendeeWaitlist;

export default attendeesSlice;
