import moment from 'moment';
import { useEffect, useState } from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { PrimeReactProvider } from 'primereact/api';

import { useParams } from 'react-router-dom';
import 'primeflex/primeflex.css';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { classes } from './pricing.st.css';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import PLAN_FEATURES from './plan-feature-list.json';

import { Box, Loader, Heading } from 'wix-style-react';
import {
    fetchPlans,
    fetchSubscriptionApi,
    selectPlans,
    selectSubscription,
} from '../../features/site-slice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { API_INSTANCE, buildURL, fullBuildURL } from '../../features/utils';
import { selectSiteData } from '../../features/account-slice';
import { useSelector } from 'react-redux';
import { CancelModal } from './canceling-modal';
import { capitalize } from 'lodash';

export const nameMapping = {
    featured: 'professional',
};

const PlanCard = ({
    plan = {},
    plans,
    isYearly,
    selectedQuota,
    handleQuotaChange,
    volumnOptions,
    selectedEnterprisePlan,
    siteId,
    siteData,
    subscription,
}) => {
    return (
        <div className="col-12 lg:col-3" key={plan.name}>
            <div className="p-3 h-full">
                <div
                    className={`shadow-2 p-3 h-full flex flex-column surface-card ${
                        plan.billing_type === 'business' ? 'border-3 border-indigo-600' : ''
                    }`}
                    style={{ borderRadius: '6px' }}
                >
                    {plan.billing_type === 'business' && (
                        <div
                            className="bg-indigo-600 text-0 font-bold text-sm inline-block p-2 mb-3"
                            style={{ borderRadius: 20, margin: '0 auto' }}
                        >
                            MOST POPULAR
                        </div>
                    )}
                    <div className="text-900 font-medium text-xl mb-2">{plan.name}</div>
                    <div className="text-600">{plan.description}</div>
                    <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                    {plan.billing_type !== 'free' ? (
                        <>
                            <div className="flex align-items-center">
                                <span className="font-bold text-2xl text-900">
                                    ${isYearly ? plan.yearly_price : plan.monthly_price}
                                </span>
                                <span className="ml-2 font-medium text-600">per month</span>
                            </div>

                            <div className="flex align-items-center mt-2 ">
                                <span className="font-light font-italic text-600">
                                    billed {isYearly ? 'yearly' : 'monthly'}
                                </span>
                            </div>
                        </>
                    ) : (
                        'Free Forever'
                    )}
                    <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                    <ul className="list-none p-0 m-0 flex-grow-1">
                        {plan.name === 'Enterprise' && (
                            <li className="flex align-items-center mb-3">
                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                Up to
                                <Dropdown
                                    value={selectedQuota}
                                    onChange={(e) => handleQuotaChange(e.value)}
                                    options={volumnOptions}
                                    optionLabel="name"
                                    className="ml-1 mr-1"
                                    checkmark={true}
                                    highlightOnSelect={false}
                                />
                                Tickets Per Month
                            </li>
                        )}
                        {plan?.benefits?.map((benefit, benefitIndex) => (
                            <li className="flex align-items-center mb-3" key={benefitIndex}>
                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                <span className="text-900">{benefit.name}</span>
                            </li>
                        ))}
                    </ul>
                    <hr className="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />

                    <form
                        action={fullBuildURL(siteData, `/api/v1/dashboard/billing/sessions`)}
                        method="POST"
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <input
                            type="hidden"
                            name="priceId"
                            value={
                                plan.name === 'Enterprise'
                                    ? isYearly
                                        ? selectedEnterprisePlan.yearly_price_id
                                        : selectedEnterprisePlan.monthly_price_id
                                    : isYearly
                                    ? plan.yearly_price_id
                                    : plan.monthly_price_id
                            }
                        />
                        <input type="hidden" name="widgetId" value={siteData.widget} />
                        <input type="hidden" name="billingType" value={plan.billing_type} />
                        <input type="hidden" name="siteId" value={siteId} />
                        <input type="hidden" name="isYearly" value={isYearly} />

                        <SubscriptionComponent
                            subscription={subscription}
                            plans={plans}
                            plan={plan.name === 'Enterprise' ? selectedEnterprisePlan : plan}
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};
const getDisplayedPlans = (plans) => {
    const volumePlans = plans.filter((plan) => plan.volume_based);
    const corePlans = plans.filter((plan) => !plan.volume_based);
    return [...corePlans, volumePlans[0]];
};

const PricingPlans = ({ plans, isYearly, subscription, siteId, siteData }) => {
    const volumePlans = plans.filter((plan) => plan.volume_based);
    const volumnOptions = volumePlans.map((plan) => ({
        name: plan.monthly_attendee_quota,
        code: plan.monthly_attendee_quota,
    }));
    const [selectedQuota, setSelectedQuota] = useState(volumnOptions[0]);
    const [selectedEnterprisePlan, setSelectedEnterprisePlan] = useState(volumePlans[0]);

    const handleQuotaChange = (value) => {
        const quota = parseInt(value.code, 10);
        setSelectedQuota(value);
        const updatedPlan = volumePlans.find((plan) => plan.monthly_attendee_quota === quota);
        setSelectedEnterprisePlan(updatedPlan);
    };

    return (
        <>
            {plans
                .filter((plan) => !plan.volume_based)
                .map((plan) => (
                    <PlanCard
                        plan={plan}
                        plans={plans}
                        isYearly={isYearly}
                        selectedQuota={selectedQuota}
                        handleQuotaChange={handleQuotaChange}
                        volumnOptions={volumnOptions}
                        selectedEnterprisePlan={selectedEnterprisePlan}
                        siteId={siteId}
                        siteData={siteData}
                        subscription={subscription}
                    />
                ))}
            <PlanCard
                plan={selectedEnterprisePlan}
                plans={plans}
                isYearly={isYearly}
                selectedQuota={selectedQuota}
                handleQuotaChange={handleQuotaChange}
                volumnOptions={volumnOptions}
                selectedEnterprisePlan={selectedEnterprisePlan}
                siteId={siteId}
                siteData={siteData}
                subscription={subscription}
            />
        </>
    );
};

export const Pricing = () => {
    const { site_id: siteId } = useParams<{ site_id: string }>();
    const [isLoading, setLoading] = useState(true);
    const [isYearly, setIsYearly] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [visible, setVisible] = useState(false);

    const siteData = useSelector(selectSiteData);

    const subscription = useAppSelector(selectSubscription);
    const plans = useAppSelector(selectPlans);

    const dispatch = useAppDispatch();

    useEffect(async () => {
        await dispatch(fetchSubscriptionApi(siteId));
        setLoading(false);
    }, [dispatch, siteId]);

    useEffect(() => {
        async function fetchData() {
            await dispatch(fetchPlans());
            await dispatch(fetchSubscriptionApi(siteId));
            setLoading(false);
        }
        fetchData();
    }, [dispatch, siteId]); // Or [] if effect doesn't need props or state

    if (isLoading) {
        return (
            <Box align="center">
                <Loader />
            </Box>
        );
    }

    const handleCancel = async (e) => {
        e.preventDefault();
        setShowCancelModal(true);
        await API_INSTANCE.delete(buildURL(siteData, `/api/v1/dashboard/billing/shopify/cancel`));
    };
    return (
        <PrimeReactProvider value={{ ripple: true }}>
            <Box direction="vertical">
                {showCancelModal && (
                    <CancelModal onSubmit={() => dispatch(fetchSubscriptionApi(siteId))} />
                )}
                <ConfirmDialog
                    group="declarative"
                    visible={visible}
                    onHide={() => setVisible(false)}
                    message="Are you sure you want to proceed?"
                    header="Successfully Upgraded"
                    icon="pi pi-check"
                />
                <Box>
                    <div
                        className="surface-ground px-4 py-8 md:px-6 lg:px-8"
                        style={{ width: '100%' }}
                    >
                        <div className="text-900 font-bold text-6xl mb-4 text-center">
                            Pricing Plans
                        </div>
                        <div className="text-700 text-xl mb-6 text-center line-height-3">
                            Choose the Perfect Plan to Unlock Your Event Success!
                        </div>

                        <div className={`mb-6 ${classes.summary}`}>
                            {subscription?.billing_type !== 'free' && (
                                <Card
                                    title={`You're currently on the  ${capitalize(
                                        nameMapping[subscription.billing_type] ||
                                            subscription.billing_type
                                    )} plan.`}
                                >
                                    <p className="m-0">
                                        <div className="mb-3">
                                            {subscription?.cancel_at_period_end
                                                ? `Subscription Canceled and Ends `
                                                : `Your plan renews on `}
                                            <b>
                                                {moment(subscription.current_period_end).format(
                                                    'MMMM D, YYYY'
                                                )}
                                            </b>
                                        </div>

                                        {subscription?.site?.type !== 'shopify' &&
                                            subscription?.site?.type !== 'wix' && (
                                                <form
                                                    action={`${process.env.API_SERVICE_URL}/api/v1/dashboard/subscriptions/portal?site=${siteId}`}
                                                    method="POST"
                                                >
                                                    <input
                                                        type="hidden"
                                                        name="widgetId"
                                                        value={siteData.widget}
                                                    />
                                                    <input
                                                        type="hidden"
                                                        name="siteId"
                                                        value={siteId}
                                                    />
                                                    <Button type="submit" outlined>
                                                        Invoices / Cancel
                                                    </Button>
                                                </form>
                                            )}
                                        {subscription?.site?.type === 'shopify' && (
                                            <Button
                                                type="submit"
                                                severity="danger"
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </Button>
                                        )}
                                    </p>
                                </Card>
                            )}
                        </div>
                        <div className="flex align-items-center justify-content-center text-900 mb-1">
                            <span className="font-semibold mr-3">Monthly</span>
                            <InputSwitch
                                checked={isYearly}
                                onChange={(e) => setIsYearly(e.value)}
                            />
                            <span className="ml-3">Yearly</span>
                        </div>
                        <div className="flex align-items-center justify-content-center text-500 mb-6 mt-3">
                            <span className="bg-orange-100 500 text-orange-500 font-semibold px-2 py-1 border-round">
                                🎉 Save 25%{' '}
                            </span>
                        </div>

                        <div className="grid">
                            <PricingPlans
                                plans={plans}
                                isYearly={isYearly}
                                subscription={subscription}
                                siteId={siteId}
                                siteData={siteData}
                            />
                        </div>
                    </div>
                </Box>
                <Box align="center" background={'#F9FAFB'}>
                    <Heading appearance="H2" style={{ textDecoration: 'underline' }}>
                        Compare Plans
                    </Heading>
                </Box>
                <Box>
                    <div
                        className="surface-ground px-4 py-8 md:px-6 lg:px-8"
                        style={{ width: '100%' }}
                    >
                        <table
                            className="w-full white-space-nowrap"
                            style={{
                                borderCollapse: 'collapse',
                                borderSpacing: '0',
                                background: 'white',
                            }}
                        >
                            <thead>
                                <tr>
                                    <td className="surface-100 p-3">
                                        <div className="text-xl text-900 mb-2">Features</div>
                                    </td>
                                    {getDisplayedPlans(plans).map((plan) => (
                                        <td className="surface-100 p-3 text-center">
                                            <div className="text-900 text-xl font-medium mb-3">
                                                {plan?.name}
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(PLAN_FEATURES).map(([category, features]) => (
                                    <>
                                        <div className="text-900 font-bold text-xl mb-5 mt-5 ml-3">
                                            {category}
                                        </div>
                                        {features.map((feature, index) => (
                                            <tr key={index}>
                                                <td className="p-3 text-900 font-medium border-bottom-1 border-200">
                                                    {feature.feature}
                                                </td>
                                                {feature.plans.map((plan, planIndex) => (
                                                    <td
                                                        key={planIndex}
                                                        className="text-center p-medium border-bottom-1 border-200"
                                                    >
                                                        <i
                                                            className={
                                                                plan === 'Y'
                                                                    ? 'text-green-500 pi pi-check'
                                                                    : plan === 'N'
                                                                    ? 'text-500 pi pi-times'
                                                                    : 'text-900'
                                                            }
                                                        >
                                                            {plan !== 'Y' && plan !== 'N'
                                                                ? plan
                                                                : ''}
                                                        </i>
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Box>
            </Box>
        </PrimeReactProvider>
    );
};

function SubscriptionComponent({ subscription, plan, plans }) {
    // Find the index of the current subscription plan in the plans array
    const currentPlanIndex = plans.findIndex((p) => p.billing_type === subscription?.billing_type);
    // Find the index of the target plan in the plans array
    const targetPlanIndex = plans.findIndex((p) => p.billing_type === plan.billing_type);

    // If the target plan is less than or equal to the current plan, don't render anything
    if (targetPlanIndex < currentPlanIndex) {
        return null;
    }

    // If the target plan is different and a higher tier than the current plan
    if (plan.billing_type !== subscription?.billing_type && targetPlanIndex > currentPlanIndex) {
        return (
            <Box direction="vertical" gap={2} align="center">
                <Button
                    type="submit"
                    className="p-3 w-full mt-auto"
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    Upgrade
                </Button>
            </Box>
        );
    }

    // Default to rendering the "Current" button for other cases
    return (
        <Button type="submit" text raised disabled>
            Current
        </Button>
    );
}
