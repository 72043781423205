import moment from 'moment';
import { useEffect } from 'react';
import { Box, Breadcrumbs, Heading, Image, Layout, Page, Text } from 'wix-style-react';
import { useAppDispatch } from '../../hooks';
import { fetchAttendeeByEmailApi, selectAttendeesList } from '../../features/attendees-slice';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const AttendeeTickets = () => {
    const history = useHistory();

    const attendees = useSelector(selectAttendeesList);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchAttendeeByEmailApi());
    }, [dispatch]);

    return (
        <Box direction="vertical" padding="SP10">
            <Box>
                <Breadcrumbs
                    activeId="2"
                    items={[
                        { id: '1', value: 'Sites' },
                        { id: '2', value: `Tickets` },
                    ]}
                    onClick={({ id }) => {
                        if (id === '1') {
                            history.push(`/sites`);
                        }
                    }}
                />
            </Box>
            <Box direction="vertical" marginBottom={10}>
                <Heading appearance="H1">My Tickets</Heading>
                <Heading appearance="H4">Your Ticket History: Past and Present Events</Heading>
            </Box>

            <Page.Content>
                <Layout></Layout>
            </Page.Content>
        </Box>
    );
};
const Ticket = ({ event }) => {
    const { all_day_event, image_url, timezone, start_date } = event;
    const format = 'YYYY-MM-DD HH:mm:ss Z';
    const allDayEventFormat = 'YYYY-MM-DD';

    return (
        <Box height={160}>
            <Box align="center" verticalAlign="middle" width={180}>
                <Image height="180px" borderRadius="8px 0px 0px 8px" src={image_url} />
            </Box>
            <Box
                direction="vertical"
                borderRadius="0px 8px 8px 0px"
                verticalAlign="space-between"
                padding="18px 24px"
                backgroundColor="D80"
                flexGrow={1}
            >
                <Box verticalAlign="space-between" flexGrow={1}>
                    <Box direction="vertical" gap="3px">
                        <Heading size="small">Get running!</Heading>
                        <Text size="small" secondary>
                            {!all_day_event
                                ? moment(start_date, timezone).format(format)
                                : moment(start_date, timezone).format(allDayEventFormat)}
                        </Text>
                    </Box>
                </Box>
                <Box align="space-between">
                    <Box gap="24px">
                        <Box verticalAlign="middle" gap="0px">
                            <Box marginLeft={1}>
                                <Text size="small">Public</Text>
                            </Box>
                        </Box>
                        <Box verticalAlign="middle">
                            <Box marginLeft={1}>
                                <Text size="small">Join for Free</Text>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
