import { times } from 'lodash';
import { Dropdown, RadioGroup, ToggleSwitch } from 'wix-style-react';
import { WidgetSettingsUpdater } from '../../../components/widget-settings-updater';

const TWENTY_HOUR_TIMES = times(24, (n) => {
    const prefix = n < 10 ? '0' : '';
    return { id: n, value: `${prefix}${n}.00` };
});

const CALENDAR_VIEWS_OPTIONS = [
    { value: 'month', label: 'Month' },
    { value: 'week', label: 'Week' },
    { value: 'day', label: 'Day' },
    { value: 'list', label: 'List' },
];
export const CalendarOptions = () => {
    return (
        <div className="config">
            <WidgetSettingsUpdater
                updateKey="calendarView"
                tooltipText="Select how you want the time to appear on your ticket page"
                title="Time format"
                resizeWidget
                hardRefresh
            >
                <RadioGroup>
                    {CALENDAR_VIEWS_OPTIONS.map((val, index) => (
                        <RadioGroup.Radio key={index} value={val.value}>
                            {val.label}
                        </RadioGroup.Radio>
                    ))}
                </RadioGroup>
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="showCalendarToday"
                title="Display Today Button"
                resizeWidget
                hardRefresh
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="showCalendarWeekends"
                title="Show weekends"
                resizeWidget
                hardRefresh
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="showCalendarTime"
                title="Show time"
                resizeWidget
                hardRefresh
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="calendarFirstDay"
                title="First Day"
                tooltipText="The day that each week begins."
                resizeWidget
            >
                <Dropdown
                    options={[
                        { id: 0, value: 'Sunday' },
                        { id: 1, value: 'Monday' },
                        { id: 2, value: 'Tuesday' },
                        { id: 3, value: 'Wednesday' },
                        { id: 4, value: 'Thursday' },
                        { id: 5, value: 'Friday' },
                        { id: 6, value: 'Saturday' },
                    ]}
                />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="calendarNextDayThreshold"
                title="Next Day Threshold"
                tooltipText="When an event’s end time spans into another day, the minimum time it must be in order for it to render as if it were on that day."
            >
                <Dropdown options={TWENTY_HOUR_TIMES} />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="calendarStartTime"
                title="Start time display (24 hours)"
                tooltipText="Determines the first time slot that will be displayed for each day."
                hardRefresh
                resizeWidget
            >
                <Dropdown options={TWENTY_HOUR_TIMES} />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="calendarEndTime"
                title="End time display (24 hours)"
                tooltipText="Determines the end time slot that will be displayed for each day."
                hardRefresh
                resizeWidget
            >
                <Dropdown options={TWENTY_HOUR_TIMES} />
            </WidgetSettingsUpdater>
        </div>
    );
};
