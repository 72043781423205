import { useState, useEffect } from 'react';
import {
    Text,
    Loader,
    Box,
    Modal,
    CustomModalLayout,
    Button,
    InfoIcon,
    FormField,
    Checkbox,
} from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import { useAppDispatch } from '../hooks';
import pluralize from 'pluralize';
import { convertToAttendeeApi } from '../features/attendees-slice';

export const ConvertToAttendee = ({ releaseCount, selectedIds }) => {
    const [loading, setLoading] = useState(false);
    const [released, setReleased] = useState(false);
    const [shown, setShown] = useState(false);
    const [emailSend, setEmailSend] = useState(false);
    const [initial, setInitial] = useState(true);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (loading) {
            const timer = setTimeout(() => {
                setLoading(false);
                setReleased(true);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [loading]);

    return (
        <Box>
            <Modal isOpen={shown} onRequestClose={() => setShown(false)} screen="desktop">
                <CustomModalLayout
                    primaryButtonText={released ? null : 'Notify'}
                    primaryButtonOnClick={
                        released
                            ? null
                            : async () => {
                                  setLoading(true);
                                  setInitial(false);
                                  await Promise.all(
                                      selectedIds.map((id) =>
                                          dispatch(
                                              convertToAttendeeApi({
                                                  id,
                                                  initiate_order_confirmation: emailSend,
                                                  is_waitlist_subscriber: false,
                                              })
                                          )
                                      )
                                  );
                              }
                    }
                    secondaryButtonOnClick={() => {
                        setInitial(true);
                        setReleased(false);
                        setShown(false);
                    }}
                    secondaryButtonText="Close"
                    onCloseButtonClick={() => setShown(false)}
                    title="Convert To Attendee"
                    width="40%"
                    content={
                        <Box align="center" direction="vertical" gap={2}>
                            {initial && (
                                <Box direction="vertical" gap={2}>
                                    <Text>
                                        Do you want to convert {releaseCount}{' '}
                                        {pluralize('user', releaseCount || 0)} from the waitlist to
                                        attendees?
                                    </Text>
                                    <Box>
                                        <FormField infoContent="A ticket confirmation email will be sent to this attendee if you have configured email communication">
                                            <Checkbox
                                                size="medium"
                                                onChange={(e) => setEmailSend(!emailSend)}
                                                checked={emailSend}
                                            >
                                                Send Email Confirmation
                                            </Checkbox>
                                        </FormField>
                                    </Box>
                                </Box>
                            )}
                            {loading && (
                                <Box>
                                    <Loader size="small" />
                                </Box>
                            )}
                            {loading && (
                                <Text>
                                    Converting {releaseCount} {pluralize('user', releaseCount || 0)}{' '}
                                    from the waitlist to attendees, please wait...
                                </Text>
                            )}
                            {released && (
                                <Text>
                                    {releaseCount} attendees have been converted to attendees
                                </Text>
                            )}
                        </Box>
                    }
                />
            </Modal>
            <Button
                skin="inverted"
                size="small"
                prefixIcon={<Icons.UploadExportSmall />}
                onClick={() => setShown(true)}
            >
                Convert to Attendee{' '}
                <InfoIcon content="Convert to an attendee who can attend the event. They will be added to the attendee list, allowing you to manage them and send email communications." />
            </Button>
        </Box>
    );
};
