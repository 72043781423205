import { SettingsHeader } from '../../../components/settings-header';
import { TextInput } from './text-input';

export const CheckoutCartText = ({ label }) => {
    return (
        <>
            <SettingsHeader title={label} />
            <TextInput
                maxLength={100}
                title="Checkout Cart Title"
                tooltipText="Title for the checkout cart that contains all the details of the purchased tickets."
                updateKey="checkoutTicketSummaryText"
            />
            <TextInput
                maxLength={100}
                title="Ticket Total Label"
                tooltipText="Label to show the final payment amount in the checkout."
                updateKey="checkoutTicketTotalText"
            />
            <TextInput
                maxLength={100}
                title="Booking Fees Label"
                tooltipText="Label to show the booking fee amount in the checkout."
                updateKey="checkoutBookingFeeTotalText"
            />
            <TextInput
                maxLength={100}
                title="Sales Tax Label"
                tooltipText="Label for sales tax if it's included in the checkout."
                updateKey="checkoutTicketSalesTaxText"
            />

            <TextInput
                maxLength={100}
                title="RSVP/Checkout Button Text"
                tooltipText="Text for the checkout button."
                updateKey="checkoutButtonText"
            />
            <TextInput
                maxLength={100}
                title="Ticket Availability Message"
                tooltipText="Message indicating how many tickets are available for purchase."
                updateKey="ticketAvailabilityText"
            />
            <TextInput
                title="Pay What You Want Text"
                tooltipText="The label for the pay what you want input field"
                updateKey="payWhatYouWantText"
            />
        </>
    );
};
