import { useEffect, useRef } from 'react';
import { Card, Button, Box } from 'wix-style-react';
import { useParams } from 'react-router';
import {
    selectCampaignIsLoading,
    selectSingleCampaign,
    getCampaignAdvancedEditor,
    updateCampaignAdvancedEditor,
} from '../../features/campaign-slice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { CampaignEmailEditor } from './components/email-editor';
import { MesssageKeysTable } from './components/message-keys-table';

export const AdvancedEmailEditor = () => {
    const { id } = useParams<{ id: string }>();

    const dispatch = useAppDispatch();

    const isLoading = useAppSelector(selectCampaignIsLoading);
    const campaign = useAppSelector(selectSingleCampaign);
    const advancedMessagedRef = useRef();

    useEffect(() => {
        if (id) dispatch(getCampaignAdvancedEditor(id));
    }, [id]);

    if (isLoading) return <span />;

    return (
        <Card>
            <Card.Header
                title="Advanced Email Editor"
                subtitle="Drag and drop to create your email. Include a stock image, upload your own photos and audit your email to ensure it is fully optimized for sending"
                suffix={
                    <>
                        <Button skin="light" onClick={window.close}>
                            Close
                        </Button>
                        <Box marginLeft={1} />
                        <Button
                            onClick={async () => {
                                const { design, html } = await advancedMessagedRef.current();
                                const data = {
                                    id,
                                    advanced_editor_json: design,
                                    advanced_editor_message: html,
                                };
                                window.opener.postMessage({ type: 'button-save' }, '*');

                                await dispatch(updateCampaignAdvancedEditor(data));
                                window.close();
                            }}
                        >
                            Save
                        </Button>
                    </>
                }
            />
            <Card.Divider />
            {/* window.parent.postMessage({ type: 'button-clicked', email }, '*') */}
            <MesssageKeysTable />
            <CampaignEmailEditor
                advancedMessagedRef={advancedMessagedRef}
                editorJson={campaign?.advanced_editor_json}
            />
            <Box margin={3}></Box>
        </Card>
    );
};
