import { Dropdown, ToggleSwitch } from 'wix-style-react';
import { SettingsHeader } from '../../../components/settings-header';
import { WidgetSettingsUpdater } from '../../../components/widget-settings-updater';

const PROMO_CODE_PLACEMENT = [
    { id: 'top', value: 'Top of Checkout' },
    { id: 'bottom', value: 'Next to Checkout Summary' },
];

export const CheckoutPromoCode = ({ label }) => {
    return (
        <>
            <SettingsHeader title={label} />

            <hr className="divider-long" />

            <WidgetSettingsUpdater
                updateKey="showPromoCode"
                tooltipText="Enable this option to show the promo code component, allowing users to view the promo code button and enter promo codes."
                title="Display Promo Code"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="keepPromoCodeOpen"
                title="Auto-Open Promo Code"
                tooltipText="When enabled, the promo code input will be visible by default. Users can enter promo codes without clicking to reveal the field. When disabled, users will need to click to open the promo code input."
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="promoCodePosition"
                title="Promo Code Placement"
                tooltipText="Choose the placement of the promo code component in your checkout. Opt for the top for immediate visibility, or alongside the checkout summary for a more integrated approach."
            >
                <Dropdown options={PROMO_CODE_PLACEMENT} />
            </WidgetSettingsUpdater>
        </>
    );
};
