const createID = (inputString: string) =>
    inputString.replace(/ /g, '_').replace(/&/g, '').toLowerCase();

export const EVENT_TYPES = [
    'Appearance or Signing',
    'Attraction',
    'Camp, Trip, or Retreat',
    'Class, Training, or Workshop',
    'Concert or Performance',
    'Conference',
    'Convention',
    'Dinner or Gala',
    'Festival or Fair',
    'Game or Competition',
    'Meeting or Networking Event',
    'Other',
    'Party or Social Gathering',
    'Race or Endurance Event',
    'Rally',
    'Screening',
    'Seminar or Talk',
    'Tour',
    'Tournament',
    'Tradeshow, Consumer Show, or Expo',
].map((t) => ({ id: createID(t), name: t }));

export const EVENT_CATEGORIES = [
    { id: 'category-music', name: 'Music' },
    { id: 'category-business_professional', name: 'Business & Professional' },
    { id: 'category-food_drink', name: 'Food & Drink' },
    { id: 'category-community_culture', name: 'Community & Culture' },
    { id: 'category-performing_visual_arts', name: 'Performing & Visual Arts' },
    { id: 'category-film_media_entertainment', name: 'Film, Media & Entertainment' },
    { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    { id: 'category-health_wellness', name: 'Health & Wellness' },
    { id: 'category-science_technology', name: 'Science & Technology' },
    { id: 'category-travel_outdoor', name: 'Travel & Outdoor' },
    { id: 'category-charity_causes', name: 'Charity & Causes' },
    { id: 'category-religion_spirituality', name: 'Religion & Spirituality' },
    { id: 'category-family_education', name: 'Family & Education' },
    { id: 'category-seasonal_holiday', name: 'Seasonal & Holiday' },
    { id: 'category-government_politics', name: 'Government & Politics' },
    { id: 'category-fashion_beauty', name: 'Fashion & Beauty' },
    { id: 'category-home_lifestyle', name: 'Home & Lifestyle' },
    { id: 'category-auto_boat_air', name: 'Auto, Boat & Air' },
    { id: 'category-hobbies_special_interest', name: 'Hobbies & Special Interest' },
    { id: 'category-other', name: 'Other' },
    { id: 'category-school_activities', name: 'School Activities' },
];

export const EVENT_SUBCATEGORIES = [
    {
        id: '1001',
        name: 'Startups & Small Business',
        parent_category: { id: 'category-business_professional', name: 'Business & Professional' },
    },
    {
        id: '1002',
        name: 'Finance',
        parent_category: { id: 'category-business_professional', name: 'Business & Professional' },
    },
    {
        id: '1003',
        name: 'Environment & Sustainability',
        parent_category: { id: 'category-business_professional', name: 'Business & Professional' },
    },
    {
        id: '1004',
        name: 'Educators',
        parent_category: { id: 'category-business_professional', name: 'Business & Professional' },
    },
    {
        id: '1005',
        name: 'Real Estate',
        parent_category: { id: 'category-business_professional', name: 'Business & Professional' },
    },
    {
        id: '1006',
        name: 'Non Profit & NGOs',
        parent_category: { id: 'category-business_professional', name: 'Business & Professional' },
    },
    {
        id: '1007',
        name: 'Sales & Marketing',
        parent_category: { id: 'category-business_professional', name: 'Business & Professional' },
    },
    {
        id: '1008',
        name: 'Media',
        parent_category: { id: 'category-business_professional', name: 'Business & Professional' },
    },
    {
        id: '1009',
        name: 'Design',
        parent_category: { id: 'category-business_professional', name: 'Business & Professional' },
    },
    {
        id: 'category-business_professional0',
        name: 'Career',
        parent_category: { id: 'category-business_professional', name: 'Business & Professional' },
    },
    {
        id: 'category-business_professional1',
        name: 'Investment',
        parent_category: { id: 'category-business_professional', name: 'Business & Professional' },
    },
    {
        id: 'category-other9',
        name: 'Other',
        parent_category: { id: 'category-business_professional', name: 'Business & Professional' },
    },
    {
        id: '2001',
        name: 'Medicine',
        parent_category: { id: 'category-science_technology', name: 'Science & Technology' },
    },
    {
        id: '2002',
        name: 'Science',
        parent_category: { id: 'category-science_technology', name: 'Science & Technology' },
    },
    {
        id: '2003',
        name: 'Biotech',
        parent_category: { id: 'category-science_technology', name: 'Science & Technology' },
    },
    {
        id: '2004',
        name: 'High Tech',
        parent_category: { id: 'category-science_technology', name: 'Science & Technology' },
    },
    {
        id: '2005',
        name: 'Mobile',
        parent_category: { id: 'category-science_technology', name: 'Science & Technology' },
    },
    {
        id: '2006',
        name: 'Social Media',
        parent_category: { id: 'category-science_technology', name: 'Science & Technology' },
    },
    {
        id: '2007',
        name: 'Robotics',
        parent_category: { id: 'category-science_technology', name: 'Science & Technology' },
    },
    {
        id: '2999',
        name: 'Other',
        parent_category: { id: 'category-science_technology', name: 'Science & Technology' },
    },
    { id: '3001', name: 'Alternative', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3002', name: 'Blues & Jazz', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3003', name: 'Classical', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3004', name: 'Country', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3005', name: 'Cultural', parent_category: { id: 'category-music', name: 'Music' } },
    {
        id: '3006',
        name: 'EDM / Electronic',
        parent_category: { id: 'category-music', name: 'Music' },
    },
    { id: '3007', name: 'Folk', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3008', name: 'Hip Hop / Rap', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3009', name: 'Indie', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3010', name: 'Latin', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3011', name: 'Metal', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3012', name: 'Opera', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3013', name: 'Pop', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3014', name: 'R&B', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3015', name: 'Reggae', parent_category: { id: 'category-music', name: 'Music' } },
    {
        id: '3016',
        name: 'Religious/Spiritual',
        parent_category: { id: 'category-music', name: 'Music' },
    },
    { id: '3017', name: 'Rock', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3018', name: 'Top 40', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3019', name: 'Acoustic', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3020', name: 'Americana', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3021', name: 'Bluegrass', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3022', name: 'Blues', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3023', name: 'DJ/Dance', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3024', name: 'EDM', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3025', name: 'Electronic', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3026', name: 'Experimental', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3027', name: 'Jazz', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3028', name: 'Psychedelic', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3029', name: 'Punk/Hardcore', parent_category: { id: 'category-music', name: 'Music' } },
    {
        id: '3030',
        name: 'Singer/Songwriter',
        parent_category: { id: 'category-music', name: 'Music' },
    },
    { id: '3031', name: 'World', parent_category: { id: 'category-music', name: 'Music' } },
    { id: '3999', name: 'Other', parent_category: { id: 'category-music', name: 'Music' } },
    {
        id: '4001',
        name: 'TV',
        parent_category: {
            id: 'category-film_media_entertainment',
            name: 'Film, Media & Entertainment',
        },
    },
    {
        id: '4002',
        name: 'Film',
        parent_category: {
            id: 'category-film_media_entertainment',
            name: 'Film, Media & Entertainment',
        },
    },
    {
        id: '4003',
        name: 'Anime',
        parent_category: {
            id: 'category-film_media_entertainment',
            name: 'Film, Media & Entertainment',
        },
    },
    {
        id: '4004',
        name: 'Gaming',
        parent_category: {
            id: 'category-film_media_entertainment',
            name: 'Film, Media & Entertainment',
        },
    },
    {
        id: '4005',
        name: 'Comics',
        parent_category: {
            id: 'category-film_media_entertainment',
            name: 'Film, Media & Entertainment',
        },
    },
    {
        id: '4006',
        name: 'Adult',
        parent_category: {
            id: 'category-film_media_entertainment',
            name: 'Film, Media & Entertainment',
        },
    },
    {
        id: '4007',
        name: 'Comedy',
        parent_category: {
            id: 'category-film_media_entertainment',
            name: 'Film, Media & Entertainment',
        },
    },
    {
        id: '4999',
        name: 'Other',
        parent_category: {
            id: 'category-film_media_entertainment',
            name: 'Film, Media & Entertainment',
        },
    },
    {
        id: '5001',
        name: 'Theatre',
        parent_category: {
            id: 'category-performing_visual_arts',
            name: 'Performing & Visual Arts',
        },
    },
    {
        id: '5002',
        name: 'Musical',
        parent_category: {
            id: 'category-performing_visual_arts',
            name: 'Performing & Visual Arts',
        },
    },
    {
        id: '5003',
        name: 'Ballet',
        parent_category: {
            id: 'category-performing_visual_arts',
            name: 'Performing & Visual Arts',
        },
    },
    {
        id: '5004',
        name: 'Dance',
        parent_category: {
            id: 'category-performing_visual_arts',
            name: 'Performing & Visual Arts',
        },
    },
    {
        id: '5005',
        name: 'Opera',
        parent_category: {
            id: 'category-performing_visual_arts',
            name: 'Performing & Visual Arts',
        },
    },
    {
        id: '5006',
        name: 'Orchestra',
        parent_category: {
            id: 'category-performing_visual_arts',
            name: 'Performing & Visual Arts',
        },
    },
    {
        id: '5007',
        name: 'Craft',
        parent_category: {
            id: 'category-performing_visual_arts',
            name: 'Performing & Visual Arts',
        },
    },
    {
        id: '5008',
        name: 'Fine Art',
        parent_category: {
            id: 'category-performing_visual_arts',
            name: 'Performing & Visual Arts',
        },
    },
    {
        id: '5009',
        name: 'Literary Arts',
        parent_category: {
            id: 'category-performing_visual_arts',
            name: 'Performing & Visual Arts',
        },
    },
    {
        id: '5010',
        name: 'Comedy',
        parent_category: {
            id: 'category-performing_visual_arts',
            name: 'Performing & Visual Arts',
        },
    },
    {
        id: '5011',
        name: 'Sculpture',
        parent_category: {
            id: 'category-performing_visual_arts',
            name: 'Performing & Visual Arts',
        },
    },
    {
        id: '5012',
        name: 'Painting',
        parent_category: {
            id: 'category-performing_visual_arts',
            name: 'Performing & Visual Arts',
        },
    },
    {
        id: '5013',
        name: 'Design',
        parent_category: {
            id: 'category-performing_visual_arts',
            name: 'Performing & Visual Arts',
        },
    },
    {
        id: '5014',
        name: 'Jewelry',
        parent_category: {
            id: 'category-performing_visual_arts',
            name: 'Performing & Visual Arts',
        },
    },
    {
        id: '5999',
        name: 'Other',
        parent_category: {
            id: 'category-performing_visual_arts',
            name: 'Performing & Visual Arts',
        },
    },
    {
        id: '6001',
        name: 'Fashion',
        parent_category: { id: 'category-fashion_beauty', name: 'Fashion & Beauty' },
    },
    {
        id: '6002',
        name: 'Accessories',
        parent_category: { id: 'category-fashion_beauty', name: 'Fashion & Beauty' },
    },
    {
        id: '6003',
        name: 'Bridal',
        parent_category: { id: 'category-fashion_beauty', name: 'Fashion & Beauty' },
    },
    {
        id: '6004',
        name: 'Beauty',
        parent_category: { id: 'category-fashion_beauty', name: 'Fashion & Beauty' },
    },
    {
        id: '6999',
        name: 'Other',
        parent_category: { id: 'category-fashion_beauty', name: 'Fashion & Beauty' },
    },
    {
        id: '7001',
        name: 'Personal health',
        parent_category: { id: 'category-health_wellness', name: 'Health & Wellness' },
    },
    {
        id: '7002',
        name: 'Mental health',
        parent_category: { id: 'category-health_wellness', name: 'Health & Wellness' },
    },
    {
        id: '7003',
        name: 'Medical',
        parent_category: { id: 'category-health_wellness', name: 'Health & Wellness' },
    },
    {
        id: '7004',
        name: 'Spa',
        parent_category: { id: 'category-health_wellness', name: 'Health & Wellness' },
    },
    {
        id: '7005',
        name: 'Yoga',
        parent_category: { id: 'category-health_wellness', name: 'Health & Wellness' },
    },
    {
        id: '7999',
        name: 'Other',
        parent_category: { id: 'category-health_wellness', name: 'Health & Wellness' },
    },
    {
        id: '8001',
        name: 'Running',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8002',
        name: 'Walking',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8003',
        name: 'Cycling',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8004',
        name: 'Mountain Biking',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8005',
        name: 'Obstacles',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8006',
        name: 'Basketball',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8007',
        name: 'Football',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8008',
        name: 'Baseball',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8009',
        name: 'Soccer',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8010',
        name: 'Golf',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8011',
        name: 'Volleyball',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8012',
        name: 'Tennis',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8013',
        name: 'Swimming & Water Sports',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8014',
        name: 'Hockey',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8015',
        name: 'Motorsports',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8016',
        name: 'Fighting & Martial Arts',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8017',
        name: 'Snow Sports',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8018',
        name: 'Rugby',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8019',
        name: 'Yoga',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8020',
        name: 'Exercise',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8021',
        name: 'Softball',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8022',
        name: 'Wrestling',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8023',
        name: 'Lacrosse',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8024',
        name: 'Cheer',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8025',
        name: 'Camps',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8026',
        name: 'Weightlifting',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8027',
        name: 'Track & Field',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '8999',
        name: 'Other',
        parent_category: { id: 'category-sports_fitness', name: 'Sports & Fitness' },
    },
    {
        id: '9001',
        name: 'Hiking',
        parent_category: { id: 'category-travel_outdoor', name: 'Travel & Outdoor' },
    },
    {
        id: '9002',
        name: 'Rafting',
        parent_category: { id: 'category-travel_outdoor', name: 'Travel & Outdoor' },
    },
    {
        id: '9003',
        name: 'Kayaking',
        parent_category: { id: 'category-travel_outdoor', name: 'Travel & Outdoor' },
    },
    {
        id: '9004',
        name: 'Canoeing',
        parent_category: { id: 'category-travel_outdoor', name: 'Travel & Outdoor' },
    },
    {
        id: '9005',
        name: 'Climbing',
        parent_category: { id: 'category-travel_outdoor', name: 'Travel & Outdoor' },
    },
    {
        id: '9006',
        name: 'Travel',
        parent_category: { id: 'category-travel_outdoor', name: 'Travel & Outdoor' },
    },
    {
        id: '9999',
        name: 'Other',
        parent_category: { id: 'category-travel_outdoor', name: 'Travel & Outdoor' },
    },
    {
        id: '10001',
        name: 'Beer',
        parent_category: { id: 'category-food_drink', name: 'Food & Drink' },
    },
    {
        id: '10002',
        name: 'Wine',
        parent_category: { id: 'category-food_drink', name: 'Food & Drink' },
    },
    {
        id: '10003',
        name: 'Food',
        parent_category: { id: 'category-food_drink', name: 'Food & Drink' },
    },
    {
        id: '10004',
        name: 'Spirits',
        parent_category: { id: 'category-food_drink', name: 'Food & Drink' },
    },
    {
        id: 'category-travel_outdoor99',
        name: 'Other',
        parent_category: { id: 'category-food_drink', name: 'Food & Drink' },
    },
    {
        id: 'category-food_drink01',
        name: 'Animal Welfare',
        parent_category: { id: 'category-charity_causes', name: 'Charity & Causes' },
    },
    {
        id: 'category-food_drink02',
        name: 'Environment',
        parent_category: { id: 'category-charity_causes', name: 'Charity & Causes' },
    },
    {
        id: 'category-food_drink03',
        name: 'Healthcare',
        parent_category: { id: 'category-charity_causes', name: 'Charity & Causes' },
    },
    {
        id: 'category-food_drink04',
        name: 'Human Rights',
        parent_category: { id: 'category-charity_causes', name: 'Charity & Causes' },
    },
    {
        id: 'category-food_drink05',
        name: 'International Aid',
        parent_category: { id: 'category-charity_causes', name: 'Charity & Causes' },
    },
    {
        id: 'category-food_drink06',
        name: 'Poverty',
        parent_category: { id: 'category-charity_causes', name: 'Charity & Causes' },
    },
    {
        id: 'category-food_drink07',
        name: 'Disaster Relief',
        parent_category: { id: 'category-charity_causes', name: 'Charity & Causes' },
    },
    {
        id: 'category-food_drink08',
        name: 'Education',
        parent_category: { id: 'category-charity_causes', name: 'Charity & Causes' },
    },
    {
        id: 'category-hobbies_special_interest99',
        name: 'Other',
        parent_category: { id: 'category-charity_causes', name: 'Charity & Causes' },
    },
    {
        id: 'category-school_activities01',
        name: 'Republican Party',
        parent_category: { id: 'category-government_politics', name: 'Government & Politics' },
    },
    {
        id: 'category-school_activities02',
        name: 'Democratic Party',
        parent_category: { id: 'category-government_politics', name: 'Government & Politics' },
    },
    {
        id: 'category-school_activities03',
        name: 'Other Party',
        parent_category: { id: 'category-government_politics', name: 'Government & Politics' },
    },
    {
        id: 'category-school_activities04',
        name: 'Non-partisan',
        parent_category: { id: 'category-government_politics', name: 'Government & Politics' },
    },
    {
        id: 'category-school_activities05',
        name: 'Federal Government',
        parent_category: { id: 'category-government_politics', name: 'Government & Politics' },
    },
    {
        id: 'category-school_activities06',
        name: 'State Government',
        parent_category: { id: 'category-government_politics', name: 'Government & Politics' },
    },
    {
        id: 'category-school_activities07',
        name: 'County/Municipal Government ',
        parent_category: { id: 'category-government_politics', name: 'Government & Politics' },
    },
    {
        id: 'category-school_activities08',
        name: 'Military',
        parent_category: { id: 'category-government_politics', name: 'Government & Politics' },
    },
    {
        id: 'category-school_activities09',
        name: 'International Affairs',
        parent_category: { id: 'category-government_politics', name: 'Government & Politics' },
    },
    {
        id: 'category-school_activities10',
        name: 'National Security',
        parent_category: { id: 'category-government_politics', name: 'Government & Politics' },
    },
    {
        id: '12999',
        name: 'Other',
        parent_category: { id: 'category-government_politics', name: 'Government & Politics' },
    },
    {
        id: '13001',
        name: 'State',
        parent_category: { id: 'category-community_culture', name: 'Community & Culture' },
    },
    {
        id: '13002',
        name: 'County',
        parent_category: { id: 'category-community_culture', name: 'Community & Culture' },
    },
    {
        id: '13003',
        name: 'City/Town',
        parent_category: { id: 'category-community_culture', name: 'Community & Culture' },
    },
    {
        id: '13004',
        name: 'LGBT',
        parent_category: { id: 'category-community_culture', name: 'Community & Culture' },
    },
    {
        id: '13005',
        name: 'Medieval',
        parent_category: { id: 'category-community_culture', name: 'Community & Culture' },
    },
    {
        id: '13006',
        name: 'Renaissance',
        parent_category: { id: 'category-community_culture', name: 'Community & Culture' },
    },
    {
        id: '13007',
        name: 'Heritage',
        parent_category: { id: 'category-community_culture', name: 'Community & Culture' },
    },
    {
        id: '13008',
        name: 'Nationality',
        parent_category: { id: 'category-community_culture', name: 'Community & Culture' },
    },
    {
        id: '13009',
        name: 'Language',
        parent_category: { id: 'category-community_culture', name: 'Community & Culture' },
    },
    {
        id: '13010',
        name: 'Historic',
        parent_category: { id: 'category-community_culture', name: 'Community & Culture' },
    },
    {
        id: '13999',
        name: 'Other',
        parent_category: { id: 'category-community_culture', name: 'Community & Culture' },
    },
    {
        id: '14001',
        name: 'Christianity',
        parent_category: { id: 'category-religion_spirituality', name: 'Religion & Spirituality' },
    },
    {
        id: '14002',
        name: 'Judaism',
        parent_category: { id: 'category-religion_spirituality', name: 'Religion & Spirituality' },
    },
    {
        id: '14003',
        name: 'Islam',
        parent_category: { id: 'category-religion_spirituality', name: 'Religion & Spirituality' },
    },
    {
        id: '14004',
        name: 'Mormonism',
        parent_category: { id: 'category-religion_spirituality', name: 'Religion & Spirituality' },
    },
    {
        id: '14005',
        name: 'Buddhism',
        parent_category: { id: 'category-religion_spirituality', name: 'Religion & Spirituality' },
    },
    {
        id: '14006',
        name: 'Sikhism',
        parent_category: { id: 'category-religion_spirituality', name: 'Religion & Spirituality' },
    },
    {
        id: '14007',
        name: 'Eastern Religion',
        parent_category: { id: 'category-religion_spirituality', name: 'Religion & Spirituality' },
    },
    {
        id: '14008',
        name: 'Mysticism and Occult',
        parent_category: { id: 'category-religion_spirituality', name: 'Religion & Spirituality' },
    },
    {
        id: '14009',
        name: 'New Age',
        parent_category: { id: 'category-religion_spirituality', name: 'Religion & Spirituality' },
    },
    {
        id: '14010',
        name: 'Atheism',
        parent_category: { id: 'category-religion_spirituality', name: 'Religion & Spirituality' },
    },
    {
        id: '14011',
        name: 'Agnosticism',
        parent_category: { id: 'category-religion_spirituality', name: 'Religion & Spirituality' },
    },
    {
        id: '14012',
        name: 'Unaffiliated',
        parent_category: { id: 'category-religion_spirituality', name: 'Religion & Spirituality' },
    },
    {
        id: '14013',
        name: 'Hinduism',
        parent_category: { id: 'category-religion_spirituality', name: 'Religion & Spirituality' },
    },
    {
        id: '14014',
        name: 'Folk Religions',
        parent_category: { id: 'category-religion_spirituality', name: 'Religion & Spirituality' },
    },
    {
        id: '14015',
        name: 'Shintoism',
        parent_category: { id: 'category-religion_spirituality', name: 'Religion & Spirituality' },
    },
    {
        id: '14099',
        name: 'Other',
        parent_category: { id: 'category-religion_spirituality', name: 'Religion & Spirituality' },
    },
    {
        id: '15001',
        name: 'Education',
        parent_category: { id: 'category-family_education', name: 'Family & Education' },
    },
    {
        id: '15002',
        name: 'Alumni',
        parent_category: { id: 'category-family_education', name: 'Family & Education' },
    },
    {
        id: '15003',
        name: 'Parenting',
        parent_category: { id: 'category-family_education', name: 'Family & Education' },
    },
    {
        id: '15004',
        name: 'Baby',
        parent_category: { id: 'category-family_education', name: 'Family & Education' },
    },
    {
        id: '15005',
        name: 'Children & Youth ',
        parent_category: { id: 'category-family_education', name: 'Family & Education' },
    },
    {
        id: '15006',
        name: 'Parents Association',
        parent_category: { id: 'category-family_education', name: 'Family & Education' },
    },
    {
        id: '15007',
        name: 'Reunion',
        parent_category: { id: 'category-family_education', name: 'Family & Education' },
    },
    {
        id: '15008',
        name: 'Senior Citizen',
        parent_category: { id: 'category-family_education', name: 'Family & Education' },
    },
    {
        id: '15999',
        name: 'Other',
        parent_category: { id: 'category-family_education', name: 'Family & Education' },
    },
    {
        id: '16001',
        name: 'St Patricks Day',
        parent_category: { id: 'category-seasonal_holiday', name: 'Seasonal & Holiday' },
    },
    {
        id: '16002',
        name: 'Easter',
        parent_category: { id: 'category-seasonal_holiday', name: 'Seasonal & Holiday' },
    },
    {
        id: '16003',
        name: 'Independence Day',
        parent_category: { id: 'category-seasonal_holiday', name: 'Seasonal & Holiday' },
    },
    {
        id: '16004',
        name: 'Halloween/Haunt',
        parent_category: { id: 'category-seasonal_holiday', name: 'Seasonal & Holiday' },
    },
    {
        id: '16005',
        name: 'Thanksgiving',
        parent_category: { id: 'category-seasonal_holiday', name: 'Seasonal & Holiday' },
    },
    {
        id: '16006',
        name: 'Christmas',
        parent_category: { id: 'category-seasonal_holiday', name: 'Seasonal & Holiday' },
    },
    {
        id: '16007',
        name: 'Channukah',
        parent_category: { id: 'category-seasonal_holiday', name: 'Seasonal & Holiday' },
    },
    {
        id: '16008',
        name: 'Fall events',
        parent_category: { id: 'category-seasonal_holiday', name: 'Seasonal & Holiday' },
    },
    {
        id: '16009',
        name: 'New Years Eve',
        parent_category: { id: 'category-seasonal_holiday', name: 'Seasonal & Holiday' },
    },
    {
        id: '16999',
        name: 'Other',
        parent_category: { id: 'category-seasonal_holiday', name: 'Seasonal & Holiday' },
    },
    {
        id: '17001',
        name: 'Dating',
        parent_category: { id: 'category-home_lifestyle', name: 'Home & Lifestyle' },
    },
    {
        id: '17002',
        name: 'Pets & Animals',
        parent_category: { id: 'category-home_lifestyle', name: 'Home & Lifestyle' },
    },
    {
        id: '17003',
        name: 'Home & Garden',
        parent_category: { id: 'category-home_lifestyle', name: 'Home & Lifestyle' },
    },
    {
        id: '17999',
        name: 'Other',
        parent_category: { id: 'category-home_lifestyle', name: 'Home & Lifestyle' },
    },
    {
        id: '18001',
        name: 'Auto',
        parent_category: { id: 'category-auto_boat_air', name: 'Auto, Boat & Air' },
    },
    {
        id: '18002',
        name: 'Motorcycle/ATV',
        parent_category: { id: 'category-auto_boat_air', name: 'Auto, Boat & Air' },
    },
    {
        id: '18003',
        name: 'Boat',
        parent_category: { id: 'category-auto_boat_air', name: 'Auto, Boat & Air' },
    },
    {
        id: '18004',
        name: 'Air',
        parent_category: { id: 'category-auto_boat_air', name: 'Auto, Boat & Air' },
    },
    {
        id: '18999',
        name: 'Other',
        parent_category: { id: 'category-auto_boat_air', name: 'Auto, Boat & Air' },
    },
    {
        id: '19001',
        name: 'Anime/Comics',
        parent_category: {
            id: 'category-hobbies_special_interest',
            name: 'Hobbies & Special Interest',
        },
    },
    {
        id: '19002',
        name: 'Gaming',
        parent_category: {
            id: 'category-hobbies_special_interest',
            name: 'Hobbies & Special Interest',
        },
    },
    {
        id: '19003',
        name: 'DIY',
        parent_category: {
            id: 'category-hobbies_special_interest',
            name: 'Hobbies & Special Interest',
        },
    },
    {
        id: '19004',
        name: 'Photography',
        parent_category: {
            id: 'category-hobbies_special_interest',
            name: 'Hobbies & Special Interest',
        },
    },
    {
        id: '19005',
        name: 'Knitting',
        parent_category: {
            id: 'category-hobbies_special_interest',
            name: 'Hobbies & Special Interest',
        },
    },
    {
        id: '19006',
        name: 'Books',
        parent_category: {
            id: 'category-hobbies_special_interest',
            name: 'Hobbies & Special Interest',
        },
    },
    {
        id: '19007',
        name: 'Adult',
        parent_category: {
            id: 'category-hobbies_special_interest',
            name: 'Hobbies & Special Interest',
        },
    },
    {
        id: '19008',
        name: 'Drawing & Painting',
        parent_category: {
            id: 'category-hobbies_special_interest',
            name: 'Hobbies & Special Interest',
        },
    },
    {
        id: 'category-other99',
        name: 'Other',
        parent_category: {
            id: 'category-hobbies_special_interest',
            name: 'Hobbies & Special Interest',
        },
    },
    {
        id: '20001',
        name: 'Dinner',
        parent_category: { id: 'category-school_activities', name: 'School Activities' },
    },
    {
        id: '20002',
        name: 'Fund Raiser',
        parent_category: { id: 'category-school_activities', name: 'School Activities' },
    },
    {
        id: '20003',
        name: 'Raffle',
        parent_category: { id: 'category-school_activities', name: 'School Activities' },
    },
    {
        id: '20004',
        name: 'After School Care',
        parent_category: { id: 'category-school_activities', name: 'School Activities' },
    },
    {
        id: '20005',
        name: 'Parking',
        parent_category: { id: 'category-school_activities', name: 'School Activities' },
    },
    {
        id: '20006',
        name: 'Public Speaker',
        parent_category: { id: 'category-school_activities', name: 'School Activities' },
    },
].map((sub) => ({ ...sub, id: `subcategory-${createID(sub.name)}` }));
