import { Box, Button, InfoIcon, PopoverMenu } from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import WixSocialPostsSmall from 'wix-ui-icons-common/WixSocialPostsSmall';
import IntegrationsIcon from 'wix-ui-icons-common/Integrations';
import ActivityIcon from 'wix-ui-icons-common/Statistics';
import ColorDropIcon from 'wix-ui-icons-common/ColorDrop';
import { useHistory } from 'react-router-dom';
import { classes } from './navigation.st.css';
import { PlanInformation } from '../../components/plan-information';
import { useSelector } from 'react-redux';
import { selectSite } from '../../features/site-slice';

function getQueryParameter(name) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name)?.trim();
}
export const Navigation = () => {
    const history = useHistory();

    const site = useSelector(selectSite);
    const source = getQueryParameter('source');
    return (
        <Box width="100%" direction="vertical" height="100%">
            {/* <PlanInformation /> */}
            <Box
                width="100%"
                height="100%"
                backgroundColor="#4843af"
                direction="vertical"
                boxShadow="rgb(0 0 0 / 59%) 0px 5px 15px"
            >
                <Box align="space-between" margin={2} verticalAlign="middle">
                    <Box align="left" inline>
                        {/* <ImportEvents refreshEvents={() => dispatch(fetchEventsApi())} /> */}
                        <Box direction="horizontal" gap={2}>
                            {window.brand !== 'wix' && (
                                <PopoverMenu
                                    triggerElement={
                                        <Button
                                            prefixIcon={<Icons.Refresh />}
                                            className={classes.button}
                                        >
                                            <Box verticalAlign="middle" gap={1}>
                                                Switch
                                                <InfoIcon content="Switch to a different Site or Widget" />
                                            </Box>
                                        </Button>
                                    }
                                >
                                    {(window.brand !== 'wix' || source !== 'shopify') && (
                                        <PopoverMenu.MenuItem
                                            text="Switch Site"
                                            onClick={() => {
                                                document.location.href = '/';
                                            }}
                                        />
                                    )}
                                    {window.brand !== 'wix' && (
                                        <PopoverMenu.MenuItem
                                            text="Switch Widget"
                                            onClick={() => {
                                                history.push(`/sites/${site.id}/widgets`);
                                            }}
                                        />
                                    )}
                                </PopoverMenu>
                            )}
                            {/* {(window.brand !== 'wix' || source !== 'shopify') && (
                                <Button
                                    onClick={() => (document.location.href = '/')}
                                    prefixIcon={<Icons.Refresh />}
                                >
                                    Switch Site
                                </Button>
                            )}
                            {window.brand !== 'wix' && (
                                <Button
                                    onClick={() => history.push(`/sites/${site.id}/widgets`)}
                                    prefixIcon={<Icons.Refresh />}
                                >
                                    Switch Widget{' '}
                                    <InfoIcon className={classes.white} content="If you are using the TicketSpot widget, you can have multiple widgets on your site. It is useful for showing different types of events or having different layouts. You can also style and customize the widget." />
                                </Button>
                            )} */}
                            <Button
                                onClick={() => history.push(`/events/${site.id}/billing`)}
                                prefixIcon={<Icons.Payment />}
                                className={classes.button}
                            >
                                Billing
                            </Button>

                            <Button
                                onClick={() => window.open('https://help.ticketspot.io', '_blank')}
                                prefixIcon={<Icons.HelpCircle />}
                                className={classes.button}
                            >
                                Help Center
                            </Button>
                        </Box>
                    </Box>
                    <div className={classes.navigationRight}>
                        <Button
                            onClick={() => history.push(`/events`)}
                            prefixIcon={<Icons.List />}
                            className={classes.button}
                        >
                            My Events
                        </Button>

                        <Button
                            onClick={() => {
                                history.push(`/events/design`);
                            }}
                            prefixIcon={<ColorDropIcon />}
                            className={classes.button}
                        >
                            <Box gap={1} verticalAlign="middle">
                                Design Widget{' '}
                                <InfoIcon
                                    size="small"
                                    className={classes.info}
                                    content="Customize your event widget with this tool to align its design, layout, and features with your website. Preview changes in real-time for effective event display and seamless integration into your event management workflow."
                                />
                            </Box>
                        </Button>

                        <Button
                            onClick={() => history.push(`/events/transactions`)}
                            prefixIcon={<ActivityIcon />}
                            className={classes.button}
                        >
                            Attendee Insights
                        </Button>
                        <Button
                            onClick={() => history.push(`/events/integrations`)}
                            prefixIcon={<IntegrationsIcon />}
                            className={classes.button}
                        >
                            Integrations
                        </Button>
                        <Button
                            onClick={() => history.push(`/events/engage-promote`)}
                            prefixIcon={<WixSocialPostsSmall />}
                            className={classes.button}
                        >
                            Engage and Promote
                        </Button>
                    </div>
                </Box>
            </Box>
        </Box>
    );
};
