import { useEffect, useState } from 'react';
import { Button } from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import { classes } from './platform-guide-modal.st.css';
import { InstallationModel } from '../sites/widgets';
import { fetchWidgetApi, selectCurrentWidget } from '../../features/site-slice';
import { useAppDispatch } from '../../hooks';
import { useSelector } from 'react-redux';

export const PlatformGuideModal = ({ siteId, widgetId }) => {
    const [isModalOpened, setModalOpened] = useState(false);

    const openModal = () => setModalOpened(true);
    const closeModal = () => setModalOpened(false);
    const dispatch = useAppDispatch();

    const currentWidget = useSelector(selectCurrentWidget);
    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchWidgetApi({ siteId, widgetId }));
        };
        void fetchData();
    }, [siteId, widgetId]);

    return (
        <>
            <Button
                className={classes.learnButton}
                prefixIcon={<Icons.HelpCircle />}
                onClick={
                    window.brand === 'wix'
                        ? () =>
                              window
                                  .open(
                                      'https://help.ticketspot.io/article/integrating-ticket-spot-with-your-wix-website',
                                      '_blank'
                                  )
                                  .focus()
                        : openModal
                }
            >
                How to Install
            </Button>

            {currentWidget && (
                <InstallationModel
                    onClose={closeModal}
                    widget={currentWidget}
                    isModalOpened={isModalOpened}
                    site_id={siteId}
                />
            )}
        </>
    );
};
