import React, { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'await-debounce';

import Premium from 'wix-ui-icons-common/Premium';
import { Box, Divider, FormField, Loader, Tooltip } from 'wix-style-react';
import { selectSiteData } from '../features/account-slice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { has, isEmpty } from 'lodash';
import { selectSettings, updateSettingsApi } from '../features/settings-slice';
import { InfoCircle } from 'wix-ui-icons-common';
import { FREE_PLAN, TRIAL_PLAN } from '../plans-util';
import { classes } from './widget-settings-updater.st.css';
import { getFragmentParams } from '../modules/settings/utils';

const url = new URL(process.env.WIDGET_CLIENT_URL);
const WIDGET_ORIGIN = url.origin;

export const WidgetSettingsUpdater = ({
    children,
    isPremium = false,
    updateKey,
    title,
    tooltipText,
    invertResult,
    resizeWidget,
    shouldFetchEvents,
    hardRefresh,
    transform,
    noDivider,
}) => {
    const [loading, setLoading] = useState(false);
    const site = useAppSelector(selectSiteData);
    const settings = useAppSelector(selectSettings);
    const [value, setValue] = useState(invertResult ? !settings[updateKey] : settings[updateKey]);
    const dispatch = useAppDispatch();
    const widgetRef = useRef();

    const debounceUpdate = useCallback(async (key, val) => {
        if (!(await debounce(`${updateKey}-debounce`, 1000))) return;

        setLoading(true);

        await dispatch(updateSettingsApi({ [key]: transform ? transform(val) : val }));

        setLoading(false);
    });
    const getTargetValue = (target) => (has(target, 'checked') ? target.checked : target.value);
    const getValue = (target) => (has(target, 'id') ? target.id : target);
    const handleChange = useCallback(
        async (val) => {
            const convertedVal = val.target ? getTargetValue(val.target) : getValue(val);
            const convertedValWithInvertion = invertResult ? !convertedVal : convertedVal;
            setValue(convertedVal);
            try {
                const data = {
                    type: 'settings',
                    key: updateKey,
                    value: convertedValWithInvertion,
                    isPremiumKey: isPremium,
                    resizeWidget,
                    shouldFetchEvents,
                    hardRefresh,
                };
                await debounceUpdate(updateKey, convertedValWithInvertion);
                const iframe = document.querySelector('iframe');
                iframe.contentWindow.postMessage(JSON.stringify(data), WIDGET_ORIGIN);
            } catch (e) {
                setLoading(false);
            }
        },
        [site]
    );

    const { item = '' } = getFragmentParams(window.location.hash);

    useEffect(() => {
        if (item === updateKey && widgetRef.current) {
            widgetRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, []);

    const getSuffix = () => {
        const ineligiblePlan = [TRIAL_PLAN, FREE_PLAN].includes(settings.plan);
        if (loading) return <Loader size="small" />;
        if (isPremium) {
            if (isEmpty(tooltipText)) {
                return;
            }
            return (
                <Tooltip appendTo="window" content={tooltipText}>
                    {ineligiblePlan ? <Premium /> : <InfoCircle />}
                </Tooltip>
            );
        } else if (tooltipText) {
            return (
                <Tooltip appendTo="window" content={tooltipText}>
                    <InfoCircle />
                </Tooltip>
            );
        }
    };

    return (
        <div className={item === updateKey ? classes.popElement : null} ref={widgetRef}>
            <FormField label={title} labelPlacement="top" suffix={getSuffix()}>
                {React.cloneElement(children as any, {
                    onChange: handleChange,
                    onSelect: handleChange,
                    disabled: loading,
                    value: value,
                    checked: value,

                    selectedId: value,
                })}
            </FormField>
            {!noDivider && (
                <Box marginBottom={5} marginTop={5}>
                    <Divider />
                </Box>
            )}
        </div>
    );
};
