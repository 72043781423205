import { useState } from 'react';
import { TagList, Cell, Layout, ListItemEditable, Card, Box } from 'wix-style-react';
import { classes } from './tags.st.css';

export const Tags = ({ tags = [], setTags, typeName }) => {
    const [openEdit, setOpenEdit] = useState(false);

    const createId = (name) => name.toLowerCase()?.replace(/\s+/g, '_');
    const addTag = (name = '') => {
        const id = createId(name);
        if (tags.some((t) => t.id === id)) return;
        setTags(
            tags.concat({
                id: createId(name),
                children: name,
                theme: 'success',
            })
        );
        setOpenEdit(false);
    };
    const removeTag = (val) => {
        const newTags = tags.filter((tag) => tag.id !== createId(val));
        setTags(newTags);
    };
    return (
        <Layout>
            <Cell span={12}>
                <div className={classes.tags}>
                    <TagList
                        tags={tags}
                        initiallyExpanded
                        size="large"
                        actionButton={{
                            label: `Add ${typeName}`,
                            onClick: () => setOpenEdit(true),
                        }}
                        onTagRemove={(val) => removeTag(val)}
                    />
                    {openEdit && (
                        <ListItemEditable
                            onApprove={(val) => addTag(val)}
                            onCancel={() => setOpenEdit(false)}
                            cancelButtonTooltipContent="Cancel"
                            approveButtonTooltipContent="Add"
                        />
                    )}
                </div>
            </Cell>
        </Layout>
    );
};
