import { useCallback, useEffect } from 'react';

import { Badge, Box, Button, Cell, EmptyState, Heading, Layout, Page, Text } from 'wix-style-react';

import { useAppDispatch, useAppSelector } from '../../hooks';

import { useSelector } from 'react-redux';
import {
    deleteWixWidgetsApi,
    fetchSiteComponents,
    selectComponents,
} from '../../features/settings-slice';
import { buildURL } from '../../features/utils';

import { selectSiteData, setSiteData } from '../../features/account-slice';
import { classes } from './wix-widgets.st.css';
import { DeleteConfirmation } from '../../components/delete-confirmation';
import { useHistory } from 'react-router-dom';

export const WixWidgets = () => {
    const dispatch = useAppDispatch();
    const widgets = useSelector(selectComponents);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchSiteComponents());
        };
        void fetchData();
    }, [dispatch]);

    const handleDelete = useCallback(
        async (id: string) => {
            await dispatch(deleteWixWidgetsApi(id));
        },
        [dispatch]
    );

    return (
        <Box direction="vertical" padding="SP10">
            <Box direction="horizontal" marginBottom={10} align="space-between">
                <Box direction="vertical">
                    <Heading appearance="H3">Widgets</Heading>
                    <Text>
                        Select the specific widget component you wish to edit. Note that there may
                        be multiple widgets distributed across various pages of your site.
                    </Text>
                </Box>
            </Box>

            {widgets.length === 0 && (
                <EmptyState
                    theme={'page'}
                    align={'center'}
                    title="You don't have any components yet"
                    subtitle="Add the Ticket Spot widget to your page via your Wix editor."
                />
            )}
            <Page.Content>
                <Layout>
                    {widgets.map((widget, i) => {
                        return (
                            <Cell span={4} key={i}>
                                <Widget
                                    handleDelete={handleDelete}
                                    widget={widget}
                                    key={widget.id}
                                />
                            </Cell>
                        );
                    })}
                </Layout>
            </Page.Content>
        </Box>
    );
};
export const Widget = ({ widget, handleDelete }) => {
    const site = useAppSelector(selectSiteData);
    const compId = widget.id.split(':')[1];
    const dispatch = useAppDispatch();
    const history = useHistory();

    const path = buildURL({ ...site, compId }, '');
    return (
        <Box
            marginTop={2}
            marginBottom={2}
            boxShadow="0px -2px 8px #16233717, 0px 1px 1px #16233717"
            paddingBottom={2}
            direction="vertical"
            padding={3}
            height={400}
            gap={3}
        >
            <Box gap={2} align="space-between">
                <Text weight="bold">ID: {compId}</Text>
                <Box gap={2}>
                    <Button
                        priority="primary"
                        onClick={() => {
                            const baseUrl = window.location.origin;
                            const queryString = `${window.location.search}&compId=${compId}&wix=true`;
                            const newPath = '#/events';

                            window.location.href = `${baseUrl}${window.location.pathname}${queryString}${newPath}`;
                        }}
                    >
                        View
                    </Button>
                    <DeleteConfirmation successClick={() => handleDelete(widget.id)} />
                </Box>
            </Box>
            <Box
                width={'100%'}
                height={'100%'}
                boxShadow="0px -1px 2px #16233717, 0px 1px 1px #16233717"
                className={classes.preview}
            >
                <Badge skin="neutralSuccess" size="small">
                    Preview
                </Badge>
                <iframe
                    src={`${process.env.WIDGET_CLIENT_URL}${path}`}
                    frameBorder="0"
                    width={'100%'}
                    scrolling="no"
                />
            </Box>
        </Box>
    );
};
