import {
    FormField,
    Box,
    Input,
    Layout,
    Cell,
    RadioGroup,
    InputStatus,
    RichTextInputArea,
    Tooltip,
    ToggleSwitch,
    Card,
    Heading,
    Dropdown,
} from 'wix-style-react';
import InfoPremium from 'wix-ui-icons-common/Premium';

import { Controller } from 'react-hook-form';
import { classes } from './action.st.css';
import { MesssageKeysTable } from '../message-keys-table';
import { EditorReceive } from '../email-editor';
import { TestEmailButton } from '../test-email-button';
import { useState } from 'react';
import { TEMPLATE_MESSAGES } from './message-templates';
import { PlanNotification } from '../../../../components/plan-notification';

export const MessageAction = ({
    control,
    errors,
    setValue,
    watchEmailEditorType,
    campaign,
    messageRef,
    getValues,
    watch,
}) => {
    const [selectedTemplate, setSelectedTemplate] = useState(1);
    const watchEmailReceiptPDF = watch('email_action_ticket_receipt_pdf');
    const watchEmailTicketPassesPDF = watch('email_action_ticket_passes_pdf');
    return (
        <>
            <Box gap={2} direction="vertical">
                <FormField label={<Heading appearance="H4">Reply-to Email</Heading>} required>
                    <Controller
                        control={control}
                        name="from_email"
                        rules={{
                            required: 'Reply-to Email is required!',
                            max: 140,
                        }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                status={
                                    errors.from_email?.message
                                        ? Input.StatusError
                                        : ('' as InputStatus)
                                }
                            />
                        )}
                    />
                    <small className={classes.error}>{errors.from_email?.message}</small>
                </FormField>
                <FormField label={<Heading appearance="H4">Sender Name</Heading>} required>
                    <Controller
                        control={control}
                        name="sender_name"
                        render={({ field }) => <Input {...field} />}
                    />
                </FormField>
            </Box>
            <Box marginTop={3} marginBottom={3}>
                <Card.Divider />
            </Box>
            <Box marginTop={2}>
                <Layout>
                    <Cell span={12}>
                        <Box gap={2} verticalAlign="middle">
                            <div className={classes.heading}>Email Setting</div>
                            <Controller
                                control={control}
                                name="email_editor_type"
                                render={({ field }) => (
                                    <RadioGroup
                                        value={watchEmailEditorType}
                                        selectionArea="always"
                                        display="horizonal"
                                        spacing="12px"
                                        selectionAreaSkin="outlined"
                                        selectionAreaPadding="12px 18px"
                                        onChange={(e) => setValue('email_editor_type', e)}
                                        className={classes.space}
                                    >
                                        <RadioGroup.Radio value={'basic'} className={classes.space}>
                                            Basic Email Editor
                                        </RadioGroup.Radio>
                                        <RadioGroup.Radio value={'advanced'}>
                                            Advanced Email Editor
                                            <Box inline marginLeft={1} marginBottom="3px">
                                                <Tooltip content="Available on business plan">
                                                    <InfoPremium />
                                                </Tooltip>
                                            </Box>
                                        </RadioGroup.Radio>
                                    </RadioGroup>
                                )}
                            />
                            {getValues && <TestEmailButton watch={watch} campaign={getValues()} />}
                        </Box>
                    </Cell>
                </Layout>
            </Box>
            {watchEmailEditorType === 'basic' && (
                <div className={classes.basicMessage}>
                    <MesssageKeysTable />
                    <Box marginTop={3} marginBottom={3} gap={3} direction="vertical">
                        <FormField
                            label="Choose Your Message Template"
                            infoContent="Explore our range of tailored templates below to craft the perfect message for each ticket delivery method."
                        >
                            <Dropdown
                                placeholder="Select Template"
                                selectedId={selectedTemplate}
                                options={TEMPLATE_MESSAGES}
                                onSelect={(value) => {
                                    setSelectedTemplate(value.id);
                                    const template = TEMPLATE_MESSAGES.find(
                                        (t) => t.id === value.id
                                    );
                                    setValue('subject', template.subject);
                                    setValue('message', template.message);
                                    messageRef.current?.setValue(template.message);
                                    if (template.extra_props) {
                                        Object.keys(template.extra_props).forEach((prop) => {
                                            setValue(prop, template.extra_props[prop]);
                                        });
                                    }
                                }}
                            />
                        </FormField>

                        <FormField label="Subject" required>
                            <Controller
                                control={control}
                                name="subject"
                                rules={{
                                    required: 'Subject is required!',
                                    max: 140,
                                }}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        status={
                                            errors.subject?.message
                                                ? Input.StatusError
                                                : ('' as InputStatus)
                                        }
                                    />
                                )}
                            />
                            <small className={classes.error}>{errors.subject?.message}</small>
                        </FormField>

                        <FormField
                            label={
                                <Box gap={2}>
                                    <span>Message</span>
                                </Box>
                            }
                            required
                        >
                            <Controller
                                control={control}
                                name="message"
                                rules={{
                                    required: 'Message is required!',
                                    max: 140,
                                }}
                                render={({ field }) => (
                                    <RichTextInputArea
                                        {...field}
                                        initialValue={campaign?.message}
                                        minHeight="140px"
                                        maxHeight="400px"
                                        onChange={(htmlText: string) =>
                                            setValue('message', htmlText)
                                        }
                                        status={
                                            errors.message?.message
                                                ? Input.StatusError
                                                : ('' as InputStatus)
                                        }
                                        ref={messageRef}
                                    />
                                )}
                            />
                            <small className={classes.error}>{errors.message?.message}</small>
                        </FormField>
                    </Box>
                </div>
            )}
            {watchEmailEditorType === 'advanced' && (
                <div className={classes.basicMessage}>
                    <MesssageKeysTable />
                    <Box marginTop={3} marginBottom={3} gap={3} direction="vertical">
                        <FormField label="Subject" required>
                            <Controller
                                control={control}
                                name="subject"
                                rules={{
                                    required: 'Subject is required!',
                                    max: 140,
                                }}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        status={
                                            errors.subject?.message
                                                ? Input.StatusError
                                                : ('' as InputStatus)
                                        }
                                    />
                                )}
                            />
                            <small className={classes.error}>{errors.subject?.message}</small>
                        </FormField>

                        <EditorReceive setValue={setValue} campaign={campaign} />
                    </Box>
                </div>
            )}
            <Box marginTop={3} marginBottom={2} gap={2} direction="vertical">
                <Box>
                    <div className={classes.heading}>Set Attachment</div>
                </Box>
                <Box gap={2}>
                    <Box>
                        <PlanNotification type="business" text="ticket passes pdf" isFormField>
                            <FormField
                                label="Attach Ticket Passes PDF"
                                infoContent="Choose this to attach a PDF of the Ticket Passes in the email. Each pass includes a unique QR Code for event entry and verification."
                            >
                                <Controller
                                    control={control}
                                    name="email_action_ticket_passes_pdf"
                                    render={({ field }) => (
                                        <ToggleSwitch
                                            {...field}
                                            size="small"
                                            onChange={(e) => {
                                                setValue(
                                                    'email_action_ticket_passes_pdf',
                                                    e.target.checked
                                                );
                                            }}
                                            checked={watchEmailTicketPassesPDF}
                                        />
                                    )}
                                />
                            </FormField>
                        </PlanNotification>
                    </Box>

                    <PlanNotification type="business" text="ticket receipt pdf" isFormField>
                        <FormField
                            label="Attach Ticket Receipt PDF"
                            infoContent="Select this option to include a PDF attachment of the Ticket Receipt in the email. The receipt provides a detailed breakdown of ticket purchases, including each item and the total billed amount."
                        >
                            <Controller
                                control={control}
                                name="email_action_ticket_receipt_pdf"
                                render={({ field }) => (
                                    <ToggleSwitch
                                        {...field}
                                        size="small"
                                        onChange={(e) => {
                                            setValue(
                                                'email_action_ticket_receipt_pdf',
                                                e.target.checked
                                            );
                                        }}
                                        checked={watchEmailReceiptPDF}
                                    />
                                )}
                            />
                        </FormField>
                    </PlanNotification>
                </Box>
            </Box>
        </>
    );
};
