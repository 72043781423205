import { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, CustomModalLayout, FormField, Loader, Modal, Text } from 'wix-style-react';
import { Copy } from '../modules/events/components/copy';

export const ZapierModal = () => {
    const [shown, setShown] = useState(true);
    const [secretKey, setSecretKey] = useState(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.API_SERVICE_URL}/api/v1/dashboard/api-keys`,
                    { withCredentials: true }
                );
                setSecretKey(response.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };
        void fetchData();
    }, []);
    return (
        <>
            <Modal
                isOpen={shown}
                onRequestClose={() => setShown(false)}
                shouldCloseOnOverlayClick
                screen="desktop"
            >
                <CustomModalLayout
                    primaryButtonText="Close"
                    primaryButtonOnClick={() => setShown(false)}
                    title="Integrate with hundreds of apps using Zapier!"
                    width={'40%'}
                    content={
                        <Box direction="vertical" gap={4}>
                            <Text>
                                To connect Ticket Spot with your Zapier account, simply grab your
                                Zapier API key from the text box below. When setting up a{' '}
                                <a href="https://zapier.com/apps/ticket-spot/integrations">
                                    Ticket Spot Zap
                                </a>{' '}
                                for the first time, just paste the secret key into the designated
                                field. It&apos;s that easy!
                            </Text>
                            <FormField label="Zapier API Key:">
                                {loading ? (
                                    <Loader size="tiny" />
                                ) : (
                                    <Copy text={secretKey} isTextArea={false} />
                                )}
                            </FormField>
                        </Box>
                    }
                />
            </Modal>
        </>
    );
};
