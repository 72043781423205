import { chunk, isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SiShopify } from 'react-icons/si';
import { classes } from './sites.st.css';
import SiteCreationGif from '../../assets/man-creating-site.gif';
import EcwidIcon from '../../assets/ecwid.png';
import WixIcon from '../../assets/wix.png';
import { SiZapier } from 'react-icons/si';

import WordpressIcon from '../../assets/wordpress.png';
import {
    Box,
    Card,
    Cell,
    Layout,
    Page,
    Button,
    TextButton,
    CustomModalLayout,
    Modal,
    Input,
    FormField,
    Badge,
    Text,
    EmptyState,
    Image,
    Loader,
    Heading,
    PopoverMenu,
} from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import { selectSiteData } from '../../features/account-slice';
import { API_INSTANCE } from '../../features/utils';
import { DeleteConfirmation } from '../../components/delete-confirmation';
import { ZapierModal } from '../../components/zapier-button';
import { SiteEditModal } from '../../components/site-edit-modal';

const AddSiteSelector = ({ addSite }) => {
    const [shown, setShown] = useState(false);

    return (
        <>
            <Button prefixIcon={<Icons.SiteAdd />} onClick={() => setShown(true)}>
                Add Your Site
            </Button>
            <Modal
                isOpen={shown}
                onRequestClose={() => setShown(false)}
                shouldCloseOnOverlayClick
                screen="desktop"
            >
                <CustomModalLayout
                    primaryButtonText="Apply"
                    primaryButtonOnClick={() => setShown(false)}
                    secondaryButtonText="Cancel"
                    secondaryButtonOnClick={() => setShown(false)}
                    onCloseButtonClick={() => setShown(false)}
                    width={'40%'}
                    title="Choose Your Ticket Spot Integration"
                    content={
                        <>
                            <Box marginBottom={4}>
                                {' '}
                                <Text>
                                    Connect to a popular platform for effortless, code-free
                                    installation in seconds.
                                </Text>
                            </Box>
                            <Box gap={2}>
                                <Button
                                    as={'a'}
                                    href={`${process.env.API_SERVICE_URL}/shopify/auth`}
                                    prefixIcon={<SiShopify />}
                                    className={classes.shopifyButton}
                                >
                                    Connect Shopify
                                </Button>
                                <Button
                                    as={'a'}
                                    href="https://www.ecwid.com/apps/featured/ticket-spot"
                                    className={classes.ecwidButton}
                                    prefixIcon={<img src={EcwidIcon} alt="ecwid" />}
                                >
                                    Connect Ecwid
                                </Button>
                            </Box>
                            <Box marginTop={4} marginBottom={3}>
                                <Text>
                                    Install on WordPress, Webflow, Square, Wix Studio, or many
                                    others with just two lines of code.
                                </Text>
                            </Box>

                            <Box>
                                <AddSite
                                    addSite={addSite}
                                    siteType="wordpress"
                                    className={classes.wordpressButton}
                                    buttonName="Connect Wordpress"
                                    icon={<img src={WordpressIcon} alt="ecwid" />}
                                />
                                <AddSite
                                    addSite={addSite}
                                    siteType="standalone"
                                    buttonName="Add Custom Site"
                                    icon={<Icons.SiteAdd />}
                                />
                            </Box>
                        </>
                    }
                />
            </Modal>
        </>
    );
};

const AddSite = ({ icon, addSite, siteType, buttonName, className = '' }) => {
    const [isModalOpened, setModalOpened] = useState(false);
    const [name, setName] = useState('');
    const [type, setType] = useState(null);
    const [url, setUrl] = useState('');

    const openModal = (siteType) => {
        setModalOpened(true);
        setType(siteType);
    };
    const closeModal = () => setModalOpened(false);

    const handleClick = useCallback(async () => {
        const { data } = await API_INSTANCE.post(`/api/v1/dashboard/sites`, { name, url, type });
        addSite(data);
        closeModal();
    }, [name, url]);

    return (
        <Box gap={2}>
            <Button onClick={() => openModal(siteType)} className={className} prefixIcon={icon}>
                {buttonName}
            </Button>
            <Modal isOpen={isModalOpened} onRequestClose={closeModal}>
                <CustomModalLayout
                    primaryButtonText="Create"
                    primaryButtonOnClick={handleClick}
                    primaryButtonProps={{
                        disabled: isEmpty(name) && isEmpty(url),
                    }}
                    onCloseButtonClick={() => closeModal()}
                    title={type === 'wordpress' ? 'Add your Wordpress Site' : 'Create a New Site'}
                    width={300}
                >
                    <Box direction="vertical" gap={2}>
                        <FormField label="Site Name">
                            <Input
                                placeholder="My Event Site"
                                onChange={(e) => setName(e.target.value)}
                            />
                        </FormField>
                        <FormField label="Site URL">
                            <Input
                                placeholder="https://example.ticketspot.io"
                                onChange={(e) => setUrl(e.target.value)}
                            />
                        </FormField>
                    </Box>
                </CustomModalLayout>
            </Modal>
        </Box>
    );
};

const StatusBadge = ({ site }) => {
    if (site.invited) {
        return <Badge skin="neutralSuccess">Invited by: {site.owner_email}</Badge>;
    }
    const plans = {
        free: 'Free',
        starter: 'Starter',
        featured: 'Professional',
        growth: 'Growth',
        business: 'Business',
        enterprise: 'Enterprise',
    };

    if (site.plan === 'trial' && site.trial_days_left > 0) {
        return <Badge skin="warningLight">Free Trial - {site.trial_days_left} days left</Badge>;
    }

    if (site.type === 'wix') {
        return <Badge skin="danger">Wix</Badge>;
    }
    return <Badge skin="premium">{plans[site.plan]} Plan</Badge>;
};
export const Sites = () => {
    const [sites, setSites] = useState([]);
    const [loading, setLoading] = useState(true);
    const site = useSelector(selectSiteData);
    const [showZapier, setShowZapier] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const siteUrl = `/api/v1/dashboard/sites`;
            const { data } = await API_INSTANCE.get(siteUrl);
            setSites(data);
            setLoading(false);
        };
        void fetchData();
    }, [site]);

    const handleDelete = useCallback(
        async (id: string) => {
            await API_INSTANCE.delete(`/api/v1/dashboard/sites/${id}`);
            setSites(sites.filter((s) => s.id !== id));
        },
        [sites]
    );

    const handleSiteUpdate = (siteId, updatedSiteInfo) => {
        setSites(
            sites.map((site) => {
                if (site.id === siteId) {
                    return { ...site, ...updatedSiteInfo };
                }
                return site;
            })
        );
    };

    const history = useHistory();
    const COL_SIZE = 3;
    const activeSites = chunk(
        sites.filter((s) => s.active),
        COL_SIZE
    );
    const inactiveSites = chunk(
        sites.filter((s) => !s.active),
        COL_SIZE
    );
    const addSite = (site) => {
        setSites([site, ...sites]);
    };

    return (
        <>
            <Page height="100vh" minWidth={200} maxWidth={1400}>
                <Page.Header
                    title="Welcome to Ticket Spot!"
                    subtitle={
                        <Box direction="vertical">
                            <p>
                                Efficiently create and manage your events by setting up your site
                                and installing a widget onto your website page.
                            </p>
                            <Box marginTop={2} />
                            <Heading appearance="H5">📚 Event Organizer Academy 🎉</Heading>{' '}
                            <p>
                                Explore our Learn page to access informative videos and a
                                comprehensive FAQ section that can help you master everything you
                                need to know.
                            </p>
                            <Box marginTop={2} />
                            <Box width={300}>
                                <Button
                                    className={classes.learnButton}
                                    skin="dark"
                                    as={'a'}
                                    href={`https://learn.ticketspot.io/`}
                                    target="_blank"
                                    suffixIcon={<Icons.HelpCircle />}
                                >
                                    FAQ and Learning
                                </Button>
                            </Box>
                        </Box>
                    }
                    actionsBar={
                        <Box align="space-between" gap={2}>
                            <PopoverMenu
                                minWidth="max-content"
                                triggerElement={
                                    <Button
                                        skin="inverted"
                                        prefixIcon={<Icons.User />}
                                        suffixIcon={<Icons.ChevronDown />}
                                    >
                                        Account
                                    </Button>
                                }
                            >
                                <PopoverMenu.MenuItem
                                    text="Connect Zapier"
                                    prefixIcon={<SiZapier />}
                                    onClick={() => setShowZapier(true)}
                                />

                                <PopoverMenu.Divider />
                                <PopoverMenu.MenuItem
                                    text="Logout"
                                    skin="destructive"
                                    prefixIcon={<Icons.Exit />}
                                    onClick={() => {
                                        window.location.href = `${process.env.API_SERVICE_URL}/auth/logout`;
                                    }}
                                />
                            </PopoverMenu>
                            <AddSiteSelector addSite={addSite} />
                            {/* <Button prefixIcon={<Icons.User />}>Customer View</Button> */}
                        </Box>
                    }
                />

                <Page.Content>
                    {loading && <Loader size="small" />}
                    {showZapier && <ZapierModal />}

                    {!loading && activeSites.length === 0 && inactiveSites.length === 0 && (
                        <>
                            <EmptyState
                                theme="section"
                                image={<Image width="220px" src={SiteCreationGif} transparent />}
                                subtitle="Connect to Shopify, Ecwid, Wix, or WordPress for effortless Ticket Spot installation without any coding. Alternatively, link with Webflow or similar platforms by adding just two lines of code."
                            >
                                <Box direction="vertical" gap={2} align="center">
                                    <Box gap={2}>
                                        <Button
                                            as={'a'}
                                            href={`${process.env.API_SERVICE_URL}/shopify/auth`}
                                            prefixIcon={<SiShopify />}
                                            className={classes.shopifyButton}
                                        >
                                            Connect Shopify
                                        </Button>
                                        <Button
                                            as={'a'}
                                            href="https://www.ecwid.com/apps/featured/ticket-spot"
                                            className={classes.ecwidButton}
                                            prefixIcon={<img src={EcwidIcon} alt="ecwid" />}
                                        >
                                            Connect Ecwid
                                        </Button>
                                    </Box>
                                    <Box>
                                        <AddSite
                                            addSite={addSite}
                                            siteType="wordpress"
                                            className={classes.wordpressButton}
                                            buttonName="Connect Wordpress"
                                            icon={<img src={WordpressIcon} alt="ecwid" />}
                                        />
                                        <AddSite
                                            addSite={addSite}
                                            siteType="standalone"
                                            buttonName="Add Custom Site"
                                            icon={<Icons.SiteAdd />}
                                        />
                                    </Box>
                                </Box>
                            </EmptyState>
                        </>
                    )}

                    {!loading && sites.length > 0 && (
                        <>
                            <Box height={10} />

                            <SiteListComponent
                                header={null}
                                sites={activeSites}
                                handleDelete={handleDelete}
                                handleSiteUpdate={handleSiteUpdate}
                                history={history}
                            />
                            <Box height={40} />
                            {inactiveSites.length > 0 && (
                                <SiteListComponent
                                    header={
                                        <>
                                            Free Trial Ended <Icons.StatusAlert />
                                        </>
                                    }
                                    sites={inactiveSites}
                                    handleDelete={handleDelete}
                                    history={history}
                                />
                            )}
                        </>
                    )}
                </Page.Content>
            </Page>
        </>
    );
};

function SiteListComponent({ sites, handleDelete, handleSiteUpdate, history, header }) {
    const COL_SIZE = 3;

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <>
            <Box>
                <Heading appearance="H3">{header}</Heading>
            </Box>
            {sites.map((siteChunk, i) => (
                <Box marginTop={2} key={i}>
                    <Layout>
                        {siteChunk.map((site, index) => (
                            <Cell key={index} span={12 / COL_SIZE}>
                                <Card showShadow>
                                    <Box height="24px" marginTop={2}>
                                        <StatusBadge site={site} />
                                    </Box>
                                    <Card.Header
                                        className={classes.card}
                                        title={site.site_display_name}
                                        subtitle={site.url || <>&nbsp;</>}
                                        suffix={
                                            site?.roles?.some((r) => ['OWNER'].includes(r)) && (
                                                <Box gap={2}>
                                                    <SiteEditModal
                                                        site={site}
                                                        handleSiteUpdate={handleSiteUpdate}
                                                        includeDesign={false}
                                                    />
                                                    <DeleteConfirmation
                                                        successClick={() => handleDelete(site.id)}
                                                    />
                                                </Box>
                                            )
                                        }
                                    />
                                    <Card.Divider />
                                    <Card.Content>
                                        {/* {site?.type === 'shopify' && (
                                            <Box align="center">
                                                <Button
                                                    priority="secondary"
                                                    size="small"
                                                    as="a"
                                                    href="https://docs.ticketspot.io/channels/shopify"
                                                    target="_blank"
                                                >
                                                    Instructions
                                                </Button>
                                            </Box>
                                        )} */}
                                        {site?.roles?.some((r) =>
                                            [
                                                'EVENT_MANAGER',
                                                'DESIGN_MANAGER',
                                                'ADMIN',
                                                'OWNER',
                                            ].includes(r)
                                        ) && (
                                            <Box marginTop={2} direction="vertical">
                                                <Layout>
                                                    <Cell span={12}>
                                                        <TextButton
                                                            prefixIcon={<Icons.Edit />}
                                                            onClick={() => {
                                                                if (site?.widget?.id) {
                                                                    document.location.href = `/?site=${site.id}&widget=${site.widget.id}#/events`;
                                                                } else {
                                                                    history.push(
                                                                        `/sites/${site.id}/widgets`
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            Manage
                                                        </TextButton>
                                                    </Cell>
                                                </Layout>
                                            </Box>
                                        )}
                                        {site?.roles?.includes('OWNER') && (
                                            <Box marginTop={2} direction="vertical">
                                                <Layout>
                                                    <Cell span={12}>
                                                        <TextButton
                                                            prefixIcon={<Icons.UserAddSmall />}
                                                            onClick={() => {
                                                                history.push(
                                                                    `/sites/${site.id}/team`
                                                                );
                                                            }}
                                                        >
                                                            Invite team
                                                        </TextButton>
                                                    </Cell>
                                                </Layout>
                                            </Box>
                                        )}
                                        {/* {site.type !== 'wix' &&
                                            site?.roles?.some((r) =>
                                                ['BILLING_MANAGER', 'ADMIN', 'OWNER'].includes(r)
                                            ) && (
                                                <Box marginTop={2} direction="vertical">
                                                    <Layout>
                                                        <Cell span={12}>
                                                            <TextButton
                                                                size="medium"
                                                                prefixIcon={<Icons.Premium />}
                                                                onClick={() =>
                                                                    history.push(
                                                                        `/sites/${site.id}/billing`
                                                                    )
                                                                }
                                                            >
                                                                Billing
                                                            </TextButton>
                                                        </Cell>
                                                    </Layout>
                                                </Box>
                                            )} */}

                                        <Box
                                            marginTop={2}
                                            direction="vertical"
                                            align="right"
                                            gap={2}
                                        >
                                            <Card.Divider />
                                            <Text size="tiny" weight="bold" color="#4EB7F4">
                                                Created by{' '}
                                                {site.type && site.type !== 'standalone'
                                                    ? capitalize(site.type)
                                                    : 'Ticket Spot'}
                                            </Text>
                                        </Box>
                                    </Card.Content>
                                </Card>
                            </Cell>
                        ))}
                    </Layout>
                </Box>
            ))}
        </>
    );
}
