import { RadioGroup, ToggleSwitch } from 'wix-style-react';
import { SettingsHeader } from '../../../components/settings-header';
import { WidgetSettingsUpdater } from '../../../components/widget-settings-updater';
import { selectSettings } from '../../../features/settings-slice';
import { useAppSelector } from '../../../hooks';
import { SliderWithInput } from './slider';

const MORE_BUTTON_VALUES = [
    { value: 'urlLink', label: 'Link to Event URL' },
    { value: 'moreText', label: 'Show More Text' },
];

const IMAGE_SIZE_OPTIONS = [
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' },
];

export const EventInformation = ({ label }) => {
    const settings = useAppSelector(selectSettings);

    return (
        <>
            <SettingsHeader title={label} />

            <hr className="divider-long" />

            <WidgetSettingsUpdater
                updateKey="showAllText"
                tooltipText="Show the complete event description."
                title="Display Full Description"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            {!settings.showAllText && (
                <WidgetSettingsUpdater
                    updateKey="characterSize"
                    title="Adjust Description Length"
                    tooltipText="Use the slider to determine how many characters of the description you'd like to display"
                    transform={(val) => +val}
                >
                    <SliderWithInput
                        max={10000}
                        displayMarks={false}
                        initialValue={settings.characterSize}
                    />
                </WidgetSettingsUpdater>
            )}

            {!settings.showAllText && (
                <WidgetSettingsUpdater
                    updateKey="showMoreButton"
                    isPremium
                    tooltipText="Toggle this option to include a 'Show More' button when there is additional text beyond the character limit that users can view."
                    title="Enable 'Show More' Button"
                >
                    <ToggleSwitch />
                </WidgetSettingsUpdater>
            )}

            {!settings.showAllText && settings.showMoreButton && (
                <WidgetSettingsUpdater
                    updateKey="moreButtonTarget"
                    tooltipText="Choose whether you'd like the 'More' button to reveal additional description text or link directly to the event URL when clicked."
                    title="More Button Action"
                >
                    <RadioGroup>
                        {MORE_BUTTON_VALUES.map((val) => (
                            <RadioGroup.Radio value={val.value}>{val.label}</RadioGroup.Radio>
                        ))}
                    </RadioGroup>
                </WidgetSettingsUpdater>
            )}

            <WidgetSettingsUpdater
                updateKey="imageSize"
                resizeWidget
                title="Image Size"
                tooltipText="Choose the size of the event image that appears in your event listing."
            >
                <RadioGroup>
                    {IMAGE_SIZE_OPTIONS.map((val, index) => (
                        <RadioGroup.Radio key={index} value={val.value}>
                            {val.label}
                        </RadioGroup.Radio>
                    ))}
                </RadioGroup>
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="disableImageZoom"
                isPremium
                title="Disable Image Zoom"
                tooltipText="Click to toggle image zooming on or off."
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="forcePlainText"
                isPremium
                title="Use Plain Text"
                tooltipText="Disable HTML formatting in the event description, allowing plain text only."
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="removeUrlLink"
                isPremium
                title="Remove Title URL Link"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="logoCarouselSlider"
                tooltipText="Toggle the image carousel feature to automatically transition through selected images or manually navigate using arrows."
                title="Image Carousel"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
        </>
    );
};
