import { FC, useEffect, useState } from 'react';
import BoldIcon from '../../../assets/text-icons/bold.svg';
import ItalicsIcon from '../../../assets/text-icons/italics.svg';
import UnderlineIcon from '../../../assets/text-icons/underline.svg';
import themes from './themes.json';
import * as Icons from 'wix-ui-icons-common';

import {
    Cell,
    FormField,
    Layout,
    Slider,
    Box,
    Popover,
    Divider,
    Input,
    ToggleButton,
    IconButton,
    DropdownBase,
    TextButton,
} from 'wix-style-react';
import {
    ComponentFontUpdater,
    createThemePayload,
} from '../../../components/widget-design-updater';
import { ColorPicker } from './color-picker';
import { useAppSelector } from '../../../hooks';
import { selectAvailableFonts, selectSettings } from '../../../features/settings-slice';
import { isEmpty } from 'lodash';

export const FontColorPicker: FC<any> = ({
    title,
    colorUpdateKey,
    fontUpdateKey,
    startWithColor,
    startWithTheme,
    fontMaxSize,
}) => {
    return (
        <FormField label={title} labelPlacement="top">
            <Layout>
                <Cell span={2}>
                    <ComponentFontUpdater updateKey={fontUpdateKey}>
                        <FontPicker startWithTheme={startWithTheme} fontMaxSize={fontMaxSize} />
                    </ComponentFontUpdater>
                </Cell>
                <Cell span={7}>
                    <ColorPicker colorUpdateKey={colorUpdateKey} startWithColor={startWithColor} />
                </Cell>
            </Layout>
        </FormField>
    );
};
export const FontModalContent = ({ onChange, font, fontMaxSize }) => {
    const [isLoading, setLoading] = useState(false);
    const [size, setSize] = useState(font.size);
    const [bold, setBold] = useState(font.bold);
    const [underline, setUnderline] = useState(font.underline);
    const [family, setFamily] = useState({ id: font.family });
    const [italics, setItalics] = useState(font.italics);
    const [fontList, setFontList] = useState([]);

    const availableFonts = useAppSelector(selectAvailableFonts);
    useEffect(() => {
        const min = Math.min(size / 15, 1.3);
        const data = {
            size,
            bold,
            family: family?.family,
            italics,
            underline,
            value: `font: ${italics ? 'italic' : 'normal'} normal ${
                bold ? 'bold' : 'normal'
            } ${size}px/${Math.max(min, 1.3)}em ${family?.family}; text-decoration: ${
                underline ? 'underline' : 'none'
            };`,
        };
        if (family?.family) {
            onChange(data);
        }
    }, [bold, family, italics, size, underline]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            setFontList(
                availableFonts.map(({ id, family }) => ({
                    id: id,
                    value: <span style={{ fontFamily: family }}>{family}</span>,
                    family,
                }))
            );
            const foundFont = availableFonts.find((f) => f.family === font.family);
            setFamily(foundFont);
            setLoading(false);
        };
        void fetchData();
    }, []);

    return (
        <Box padding="12px 24px" direction="vertical" maxWidth="300px">
            <FormField label="Font">
                <DropdownBase
                    animate
                    selectedId={family?.id}
                    options={fontList}
                    onSelect={(option) => {
                        setFamily(option);
                    }}
                >
                    {({ toggle, selectedOption }) => (
                        <TextButton
                            onClick={toggle}
                            suffixIcon={<Icons.ChevronDown />}
                            status={isLoading ? 'loading' : null}
                        >
                            {selectedOption?.value}
                        </TextButton>
                    )}
                </DropdownBase>
            </FormField>

            <Box marginTop={4} marginBottom={4}>
                <Divider />
            </Box>
            <FormField label="Font size (px)">
                <Layout>
                    <Cell span={6}>
                        <Slider
                            onChange={(val) => setSize(val)}
                            min={1}
                            max={fontMaxSize || 200}
                            value={size}
                            displayMarks={false}
                        />
                    </Cell>
                    <Cell span={3}>
                        <Box gap="12px">
                            <Input value={size} onChange={(e) => setSize(e.target.value)} />
                        </Box>
                    </Cell>
                </Layout>
            </FormField>

            <Box marginTop={4} marginBottom={4}>
                <Divider />
            </Box>

            <Box gap="6px">
                <ToggleButton
                    labelValue="Bold"
                    size="small"
                    selected={bold}
                    onClick={() => setBold(!bold)}
                >
                    <img src={BoldIcon} />
                </ToggleButton>
                <ToggleButton
                    labelValue="Italics"
                    size="small"
                    selected={italics}
                    onClick={() => setItalics(!italics)}
                >
                    <img src={ItalicsIcon} />
                </ToggleButton>
                <ToggleButton
                    labelValue="Underline"
                    size="small"
                    selected={underline}
                    onClick={() => setUnderline(!underline)}
                >
                    <img src={UnderlineIcon} />
                </ToggleButton>
            </Box>
        </Box>
    );
};

const createFontValues = (value, fontTheme) => {
    if (!isEmpty(value)) return value;

    const {
        size,
        family,
        style: { bold, underline, italics },
    } = fontTheme;

    return {
        bold,
        family,
        italics,
        size,
        underline,
    };
};

const getTheme = (theme) => theme || createThemePayload(themes[0]);

export const FontPicker = ({ onChange, startWithTheme, fontMaxSize, value }) => {
    const settings = useAppSelector(selectSettings);
    const theme = getTheme(settings?.design?.theme);
    const defaultTheme = themes.find((t) => t.id === theme.id);
    const font = createFontValues(value, defaultTheme.text[startWithTheme]);
    const [isModalOpened, setModalOpened] = useState(false);

    return (
        <Popover showArrow shown={isModalOpened} appendTo="window" placement="right">
            <Popover.Element>
                <IconButton onClick={() => setModalOpened(!isModalOpened)}>
                    <Icons.SentenceCase />
                </IconButton>
            </Popover.Element>
            <Popover.Content>
                {isModalOpened && (
                    <FontModalContent font={font} fontMaxSize={fontMaxSize} onChange={onChange} />
                )}
            </Popover.Content>
        </Popover>
    );
};
