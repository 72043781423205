import { useState } from 'react';
import { Box, InfoIcon, TextButton } from 'wix-style-react';

export const AttendeeQRCodeCheckin = ({ attendee }) => {
    const [show, setShow] = useState<boolean>(false);

    return (
        <>
            <TextButton size="tiny" onClick={() => setShow(!show)}>
                <Box gap={1}>Ticket</Box>
            </TextButton>
            {show && (
                <>
                    <div style={{ display: 'flex' }}>
                        <img src={attendee?.checkin_qrcode} width={100} />{' '}
                        <InfoIcon
                            size="small"
                            content={`This QR Code can be personalized using the '{{ attendee_qr_code }}' template. You can incorporate it into your engagement automation to send customized QR Codes to each attendee`}
                        />
                    </div>
                    <a
                        href={`https://ticket-delivery.ticketspot.io/?id=${attendee.id}`}
                        target="_blank"
                    >
                        Ticket Delivery
                    </a>
                </>
            )}
        </>
    );
};
