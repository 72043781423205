import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import { API_INSTANCE, buildURLWithState, createSiteData } from './utils';

export type AccountSliceState = {
    data: {};
    connectedAccounts: {};
    site: {};
};

const urlSearchParams = new URLSearchParams(
    window.location.search || `?${window.location.hash.split('?')[1] || ''}`
);

export const siteData = createSiteData(urlSearchParams);
const initialState: AccountSliceState = {
    data: {},
    connectedAccounts: {},
    site: siteData,
};

export const fetchAccountApi = createAsyncThunk('account/fetch', async (_, { getState }) => {
    return API_INSTANCE.get<ResponseEvent>(
        buildURLWithState(getState(), `/api/v1/dashboard/accounts`)
    ).then((response) => response.data);
});
export const fetchConnectedAccountsApi = createAsyncThunk(
    'connected-accounts/fetch',
    async (_, { getState }) => {
        return API_INSTANCE.get<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/connected-accounts`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const startFreeTrialApi = createAsyncThunk('account/free_trial', async (_, { getState }) => {
    return API_INSTANCE.post<ResponseEvent>(
        buildURLWithState(getState(), `/api/v1/dashboard/accounts/free_trial`)
    )
        .then((response) => response.data)
        .catch((error) => error);
});
export const updateAccountApi = createAsyncThunk('account/update', async (data, { getState }) => {
    return API_INSTANCE.put<any>(buildURLWithState(getState(), `/api/v1/dashboard/accounts`), data)
        .then((response) => response.data)
        .catch((error) => error);
});

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setSiteData: (state, action: PayloadAction) => {
            state.site = action.payload;
        },
        appendSiteData: (state, action: PayloadAction) => {
            state.site = { ...state.site, ...action.payload };
        },
        config_success: (state, action: PayloadAction) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAccountApi.fulfilled, (state, action) => {
            state.data = action.payload;
        });
        builder.addCase(updateAccountApi.fulfilled, (state, action) => {
            state.data = action.payload;
        });
        builder.addCase(fetchAccountApi.rejected, () => {
            throw new Error('account error');
        });

        builder.addCase(fetchConnectedAccountsApi.fulfilled, (state, action) => {
            state.connectedAccounts = action.payload;
        });
        builder.addCase(startFreeTrialApi.fulfilled, (state, action) => {
            state.data = action.payload;
        });
    },
});

export const { setSiteData, appendSiteData } = accountSlice.actions;

export const selectAccount = (state: RootState) => state?.account.data;
export const selectConnectedAccounts = (state: RootState) => state?.account.connectedAccounts;
export const selectSiteData = (state: RootState) => state?.account.site;

export default accountSlice;
