import { Box, Card, Item, Tabs } from 'wix-style-react';

import { useState } from 'react';
import { PromotionList } from '../campaigns/list-promotions';
import { CampaignList } from '../campaigns/list-campaigns';
import { classes } from './style.st.css';
import Send from 'wix-ui-icons-common/Send';
import WixSocialPostsSmall from 'wix-ui-icons-common/WixSocialPosts';

export const EngagementPromotion = () => {
    const [activeId, setActiveId] = useState(1);

    return (
        <div className={classes.container}>
            <Card className={classes.fullWidth}>
                <Card.Header
                    title="Attendee Engagement and Social Media Promotion"
                    subtitle=" Boost Engagement and Attendance: Automate Event Promotion and Communication"
                />
                <Card.Content>
                    <Tabs
                        activeId={activeId}
                        onClick={(value: Item) => setActiveId(value.id as number)}
                        items={[
                            {
                                id: 1,
                                title: (
                                    <Box direction="horizontal" gap={1} align="center">
                                        {<Send />}Engagement
                                    </Box>
                                ),
                            },
                            {
                                id: 2,
                                title: (
                                    <Box direction="horizontal" gap={1} align="center">
                                        {<WixSocialPostsSmall />}Promotion
                                    </Box>
                                ),
                            },
                        ]}
                    />
                    {activeId === 1 && <CampaignList />}
                    {activeId === 2 && <PromotionList />}
                </Card.Content>
            </Card>
        </div>
    );
};
