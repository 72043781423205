import { Box, Card, Cell, Layout } from 'wix-style-react';

import { CustomFields } from '../../components/custom-fields';
import { PlanNotification } from '../../components/plan-notification';
import { Tags } from '../../components/tags';

export const CustomFieldTags = ({
    tags,
    setTags,
    customFields,
    setCustomFields,
    event,
    setValue,
    control,
}) => {
    return (
        <Layout>
            <Cell span={12}>
                <PlanNotification type="business" text="custom event properties">
                    <CustomFields
                        reservedNames={Object.keys(event || {})}
                        customFields={customFields}
                        setCustomFields={setCustomFields}
                        setParentValue={setValue}
                        parentControl={control}
                        type="event"
                    />
                </PlanNotification>
            </Cell>
            <Box marginTop={3} />
            <Cell span={12}>
                <Card>
                    <Card.Header
                        title="Tags"
                        subtitle="Increase your event's visibility by adding relevant keywords/tags. Make it easy for attendees to find your event by enabling the tag filter from the Settings/Design tool."
                    />
                    <Card.Divider />
                    <Card.Content>
                        <Tags tags={tags} setTags={setTags} typeName="tag" />
                    </Card.Content>
                </Card>
            </Cell>
        </Layout>
    );
};
