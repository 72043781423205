import { useCallback, useRef, useState } from 'react';
import moment from 'moment';
import {
    Card,
    Text,
    Box,
    Divider,
    Button,
    InputArea,
    TableListItem,
    Loader,
} from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import { useAppDispatch } from '../../hooks';
import { addAttendeeNoteApi } from '../../features/attendees-slice';
import { classes } from './attendee-view.st.css';
import Avatar from 'react-avatar';

export const AttendeeNotesCard = ({ attendee }) => {
    const [notes, setNotes] = useState(attendee.notes);
    const dispatch = useAppDispatch();
    const notesSectionRef = useRef(null);

    const [newNote, setNewNote] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSaveNote = useCallback(async () => {
        setIsLoading(true);

        const newNoteData = await dispatch(
            addAttendeeNoteApi({ attendee_id: attendee.id, content: newNote })
        );
        setIsLoading(false);
        setNotes([...notes, newNoteData.payload]);
        setNewNote('');
        const notesSectionContainer = notesSectionRef.current;
        if (notesSectionContainer) {
            notesSectionContainer.scrollTo({
                top: notesSectionContainer.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [dispatch, newNote, setIsLoading]);

    return (
        <Card className={classes.card}>
            <Card.Header title="Notes" />
            <Box
                direction="vertical"
                justifyContent="flex-start"
                padding={1}
                gap={2}
                overflowY="auto"
                height={'100%'}
            >
                <div ref={notesSectionRef} style={{ overflowY: 'auto' }}>
                    {notes.map((note, index) => (
                        <TableListItem
                            key={index}
                            showDivider
                            verticalPadding="tiny"
                            onClick={() => {}}
                            options={[
                                {
                                    value: (
                                        <Box gap={2}>
                                            <Avatar
                                                name={note?.account?.email}
                                                size={50}
                                                round="20px"
                                            />
                                            <Box direction="vertical">
                                                <Text weight="normal">{note?.account?.email}</Text>
                                                <Text size="small" secondary>
                                                    {moment(note?.created_at).format(
                                                        'MMMM D, YYYY [at] h:mm A z'
                                                    )}
                                                </Text>
                                                <Box marginTop={2} marginBottom={2}>
                                                    <Divider />
                                                </Box>
                                                <Text size="small">{note?.content}</Text>
                                            </Box>
                                        </Box>
                                    ),
                                },
                            ]}
                        />
                    ))}
                </div>
            </Box>
            <Box direction="vertical" padding={4} justifyContent="flex-end" verticalAlign="bottom">
                <InputArea
                    placeholder="Add a note for this attendee..."
                    rows={2}
                    value={newNote}
                    onChange={(e) => setNewNote(e.currentTarget.value)}
                />
                <Box align="right" marginTop={3}>
                    <Button
                        size="tiny"
                        prefixIcon={<Icons.Add />}
                        onClick={handleSaveNote}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <>
                                <Loader size="tiny" /> Saving...
                            </>
                        ) : (
                            'Save Note'
                        )}
                    </Button>
                </Box>
            </Box>
        </Card>
    );
};
