import { EventText } from './event-text';
import { WidgetText } from './widget-text';
import { CheckoutAttendeeInfoText, CheckoutText } from './checkout-attendee-info-text';
import { DrilldownContainer } from '../../../components/drilldown';
import { CheckoutDateTimeSelection } from './checkout-date-time-selection';
import { CheckoutGeneralText } from './checkout-general-text';
import { CheckoutSectionLabelsText } from './checkout-section-text';
import { CheckoutCartText } from './checkout-cart-text';
import { CheckoutPaymentInfoText } from './checkout-payment-text';
import { CheckoutPromotionalText } from './checkout-promotional-text';

export const Texts = () => {
    return (
        <DrilldownContainer
            title="Text Settings"
            infoText="Customize the text displayed throughout the event and checkout process, including labels, buttons, and messages."
            sections={[
                {
                    label: 'Widget Text',
                    id: 'widget',
                    component: <WidgetText />,
                },
                {
                    label: 'Event Text',
                    id: 'event',
                    component: <EventText />,
                },
                {
                    label: 'Date Time Selection Text',
                    id: 'checkout-datetime-selection',
                    component: <CheckoutDateTimeSelection />,
                },
                {
                    label: 'Checkout General Text',
                    id: 'checkout-generate',
                    component: <CheckoutGeneralText />,
                },
                {
                    label: 'Checkout Attendee Information Text',
                    id: 'checkout-attendee-info',
                    component: <CheckoutAttendeeInfoText />,
                },
                {
                    label: 'Checkout Section Labels Text',
                    id: 'checkout-section-labels',
                    component: <CheckoutSectionLabelsText />,
                },
                {
                    label: 'Checkout Cart Text',
                    id: 'checkout-cart-text',
                    component: <CheckoutCartText />,
                },
                {
                    label: 'Checkout Payment Information',
                    id: 'checkout-payment-info',
                    component: <CheckoutPaymentInfoText />,
                },
                {
                    label: 'Checkout Promotional Information',
                    id: 'checkout-promotional-info',
                    component: <CheckoutPromotionalText />,
                },
            ]}
        />
    );
};
