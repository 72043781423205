import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Link, useHistory, useParams } from 'react-router-dom';
import * as Icons from 'wix-ui-icons-common';
import pluralize from 'pluralize';
import { classes } from './order-view.st.css';

import {
    Box,
    Text,
    Card,
    Layout,
    Cell,
    Button,
    Loader,
    Page,
    Table,
    TableToolbar,
    TableActionCell,
    InfoIcon,
    Popover,
    Heading,
    Badge,
} from 'wix-style-react';

import { fetchOrderApi, selectOrder } from '../../features/attendees-slice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { buildURL } from '../../features/utils';
import { selectSiteData } from '../../features/account-slice';
import { TicketValid } from './ticket-validility';
import TicketValue from './ticket-value';
import { ATTENDEE_STATUS_OPTIONS } from './event-attendee-list';
import { triggerEngagement } from '../../features/campaign-slice';
import TriggerSuccess from './trigger-success-modal';
import { AttendeeTicketEditModal } from './attendee-ticket-edit-modal';
import { CancelAndRefundModal } from './cancel-and-refund-modal';

export const SendTicketConfirmationButton = ({ event, cartId, headerText, buttonText }) => {
    const [executingTrigger, setExecutingTrigger] = useState(false);
    const [shown, setShown] = useState(false);
    const [runningExecutionTrigger, setRunningExecutionTrigger] = useState(false);
    const dispatch = useAppDispatch();

    const handleTrigger = useCallback(async () => {
        setRunningExecutionTrigger(true);
        const payload: any = {
            trigger_type: 'NEW_ORDER',
            event_id: event?.id,
            cart_id: cartId,
        };
        await dispatch(triggerEngagement(payload));
        setExecutingTrigger(true);
        setRunningExecutionTrigger(false);
    }, [cartId, dispatch, event?.id]);

    return (
        <>
            {executingTrigger && <TriggerSuccess />}

            <Popover showArrow shown={shown} appendTo="window" placemenzzt={'top'}>
                <Popover.Element>
                    <Button
                        priority="secondary"
                        size="small"
                        onClick={() => setShown(true)}
                        prefixIcon={<Icons.Send />}
                        disabled={runningExecutionTrigger}
                    >
                        <Box gap={1} verticalAlign="middle">
                            {' '}
                            {runningExecutionTrigger && <Loader size="tiny" />} {buttonText}{' '}
                            <InfoIcon content='Trigger the "Ticket Confirmation" engagement. Make sure you have set up the "Ticket Confirmation" automation to ensure that your attendees receive their tickets and event details.' />
                        </Box>
                    </Button>
                </Popover.Element>
                <Popover.Content>
                    <Box align="center" width={'100%'}>
                        <Heading appearance="H5">{headerText}</Heading>
                    </Box>
                    <Box direction="horizontal" gap={2} margin={3} paddingBottom={2}>
                        <Button size="small" onClick={handleTrigger}>
                            Yes
                        </Button>
                        <Button priority="secondary" size="small" onClick={() => setShown(false)}>
                            No
                        </Button>
                    </Box>
                </Popover.Content>
            </Popover>
        </>
    );
};
export const OrderView = () => {
    const { id } = useParams<{ id: string }>();
    const site = useAppSelector(selectSiteData);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const order = useSelector(selectOrder);
    const dispatch = useAppDispatch();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            await dispatch(fetchOrderApi(id));
            setLoading(false);
        }
        void fetchData();
    }, [dispatch, id]);

    if (loading) {
        return (
            <Box width="100%" height="100px" align="center" verticalAlign="middle">
                <Loader size="small" />
            </Box>
        );
    }

    return (
        <Page>
            <Page.Header
                title={`Order #${order?.friendly_cart_id}`}
                showBackButton
                onBackClicked={() =>
                    history.push(
                        buildURL(
                            site,
                            `/events/manage/${
                                order?.event?.series_parent_id || order?.event?.id
                            }/attendees`
                        )
                    )
                }
            />

            <Page.Content>
                <OrderInformation id={id} order={order} />
            </Page.Content>
        </Page>
    );
};

export const OrderInformation = ({ id, order }) => {
    const site = useAppSelector(selectSiteData);
    const history = useHistory();

    const secondaryActions = (row) => [
        {
            text: 'View',
            icon: <Icons.UserJoin />,
            onClick: () => history.push(buildURL(site, `/events/attendees/manage/${row.id}`)),
        },
    ];

    const columns = [
        {
            title: 'Name',
            render: (row) => `${row?.customer?.first_name} ${row?.customer?.last_name}`,
        },
        {
            title: 'Ticket',
            render: (row) => <AttendeeTicketEditModal attendee={row} />,
        },
        {
            title: 'Price',
            render: (row) => (
                <Box align="left">
                    <TicketValue attendee={row} currency_symbol={order?.currency_symbol} />
                </Box>
            ),
        },
        {
            title: 'Status',
            render: (row) => ATTENDEE_STATUS_OPTIONS.find((opt) => opt.id === row?.status)?.value,
        },
        {
            title: 'Valid',
            render: (row) => <TicketValid isValid={row?.ticket_validity?.isValid} />,
        },
        {
            render: (row, index) => <TableActionCell secondaryActions={secondaryActions(row)} />,
        },
    ];

    const ticketRefunds =
        order?.attendees?.filter(
            (attendee) => typeof attendee.refund_amount === 'number' && attendee.refund_amount > 0
        ) || [];

    const headerText = (
        <>
            Send to {order?.attendees?.length}{' '}
            {pluralize('attendee', order?.attendees?.length || 0)}
        </>
    );
    return (
        <>
            <Layout>
                <Cell span={5}>
                    <Card>
                        <Card.Header
                            title={order?.event?.title}
                            subtitle={
                                <Box marginTop={1} gap={2} direction="vertical">
                                    <Box gap={2}>
                                        <Button
                                            priority="secondary"
                                            size="small"
                                            to={buildURL(
                                                site,
                                                `/events/manage/${order?.event?.id}`
                                            )}
                                            as={Link}
                                        >
                                            Edit Event
                                        </Button>

                                        <SendTicketConfirmationButton
                                            event={order?.event}
                                            headerText={headerText}
                                            cartId={order?.id}
                                            buttonText="Send Order Confirmation"
                                        />
                                    </Box>
                                    {order && !order?.transaction?.return_processed && (
                                        <Box>
                                            <CancelAndRefundModal orderId={id} cartId={order?.id} />
                                        </Box>
                                    )}
                                </Box>
                            }
                        />
                        <Card.Divider />

                        <Box
                            direction="horizontal"
                            gap="1"
                            paddingTop="2"
                            paddingLeft="4"
                            paddingRight="4"
                            paddingBottom="2"
                        >
                            <Text secondary size="medium" weight="bold">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path opacity="0.3" d="M19 6H5V8H19V6Z" fill="black" />
                                    <path
                                        d="M19 4H18V2H16V4H8V2H6V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V10H19V20ZM19 8H5V6H19V8ZM9 14H7V12H9V14ZM13 14H11V12H13V14ZM17 14H15V12H17V14ZM9 18H7V16H9V18ZM13 18H11V16H13V18ZM17 18H15V16H17V18Z"
                                        fill="black"
                                    />
                                </svg>
                            </Text>
                            <Box verticalAlign="middle">
                                <Text weight="bold" size="small">
                                    {moment
                                        .tz(order?.event?.start_date, order?.event?.timezone)
                                        .format('ddd, MMM D, YYYY h:mm A z')}
                                </Text>
                            </Box>
                        </Box>
                    </Card>
                </Cell>
                <Cell span={7}>
                    <Card className={classes.card}>
                        <Card.Header
                            title="Order Details"
                            suffix={
                                order?.source === 'shopify' && (
                                    <Badge skin="neutralStandard">Purchased via Shopify</Badge>
                                )
                            }
                        />
                        <Card.Content>
                            <Layout>
                                <Cell span={6}>
                                    <Box direction="vertical">
                                        <Text secondary size="medium" weight="bold">
                                            Purchased Date
                                        </Text>
                                        <Text weight="bold" size="small">
                                            {moment
                                                .tz(order?.created_at, order?.event?.timezone)
                                                .format('dddd, MMMM Do, YYYY h:mm A z')}
                                        </Text>
                                    </Box>
                                </Cell>
                                <Cell span={6}>
                                    <Box direction="vertical">
                                        <Text secondary size="medium" weight="bold">
                                            Total
                                        </Text>
                                        <Text weight="bold" size="small">
                                            {order?.currency_symbol}
                                            {order?.total}
                                        </Text>
                                    </Box>
                                </Cell>
                                <Cell span={6}>
                                    <Box direction="vertical">
                                        <Text secondary size="medium" weight="bold">
                                            Tickets Purchased
                                        </Text>
                                        <Text weight="bold" size="small">
                                            {order?.attendees?.length}{' '}
                                            {pluralize('ticket', order?.attendees?.length)}
                                        </Text>
                                    </Box>
                                </Cell>
                                <Cell span={6}>
                                    <Box direction="vertical">
                                        <Text secondary size="medium" weight="bold">
                                            Refunds
                                        </Text>
                                        <Text weight="bold" size="small">
                                            {ticketRefunds.length}
                                        </Text>
                                    </Box>
                                </Cell>
                                <Cell span={6}>
                                    {order?.transaction?.card_brand &&
                                        order?.transaction?.card_last4 && (
                                            <Box direction="vertical">
                                                <Text secondary size="medium" weight="bold">
                                                    Payment
                                                </Text>
                                                <Text weight="bold" size="small">
                                                    Paid by {order?.transaction?.card_brand}
                                                </Text>
                                                <Text weight="bold" size="small">
                                                    Last 4 digits: {order?.transaction?.card_last4}
                                                </Text>
                                            </Box>
                                        )}
                                </Cell>
                            </Layout>
                        </Card.Content>
                    </Card>
                </Cell>
                <Cell span={12}>
                    <Table data={order?.attendees} columns={columns}>
                        <TableToolbar>
                            <TableToolbar.ItemGroup position="start">
                                <TableToolbar.Item>
                                    <TableToolbar.Title>
                                        <Box direction="vertical">
                                            <Heading appearance="H4">Attendees</Heading>
                                        </Box>
                                    </TableToolbar.Title>
                                </TableToolbar.Item>
                            </TableToolbar.ItemGroup>
                        </TableToolbar>
                        <Table.Content />
                    </Table>
                </Cell>
            </Layout>
        </>
    );
};
