import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, RadioGroup, Text } from 'wix-style-react';
import { selectSiteData } from '../../features/account-slice';
import { API_INSTANCE, fullBuildURL } from '../../features/utils';
import type { ResponseEvent } from '../../types';
import { classes } from './organization-selections.st.css';

function OrganizationSelectionComponent({ site, organizations, selectedOrganizationId }) {
    const [selectedOrg, setSelectedOrg] = useState(selectedOrganizationId);

    const handleOrgSelect = useCallback(
        async (value) => {
            setSelectedOrg(value);
            await API_INSTANCE.put<ResponseEvent>(fullBuildURL(site, `/api/v1/users`), {
                organizationId: value,
            });
        },
        [selectedOrg, site]
    );
    return (
        <div>
            <RadioGroup
                onChange={(e) => {
                    handleOrgSelect(e);
                }}
                value={selectedOrg}
            >
                {organizations.map((organization: any, key: number) => {
                    return (
                        <RadioGroup.Radio value={organization.id} key={key}>
                            <Box direction="vertical">
                                <Text size="small">
                                    {organization.name || organization.vertical}
                                </Text>
                            </Box>
                        </RadioGroup.Radio>
                    );
                })}
            </RadioGroup>
        </div>
    );
}

// export const updateEventbriteUserssApi = createAsyncThunk('eventbrite/update', async (data, { getState }) => {
//     return API_INSTANCE.put<ResponseEvent>(buildURLWithState(getState(), `/api/v1/users`), data)
//         .then((response) => response.data)
//         .catch((error) => error);
// });

export const OrganizationSelection = ({ integrations }) => {
    const site = useSelector(selectSiteData);

    const [organizations, setOrganizations] = useState();
    useEffect(() => {
        async function fetch() {
            const { data } = await API_INSTANCE.get<ResponseEvent>(
                fullBuildURL(site, `/api/v1/users/organizations`)
            );
            setOrganizations(data);
        }
        void fetch();
    }, [site]);

    if (!integrations['eventbrite']) {
        return <span />;
    }
    return (
        <div className={classes.container} style={{ marginBottom: 10 }}>
            {organizations && (
                <OrganizationSelectionComponent
                    site={site}
                    selectedOrganizationId={integrations['eventbrite'].organizationId}
                    organizations={organizations}
                />
            )}
        </div>
    );
};
