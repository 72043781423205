import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Cell, Layout, Sidebar, SidebarSectionItem } from 'wix-style-react';
import { fetchAccountApi, selectAccount } from '../../features/account-slice';
import { useAppDispatch } from '../../hooks';
import { Sites } from './sites';

export const SiteDashboard = () => {
    const [selectedKey, setSelectedKey] = useState('sites');

    const dispatch = useAppDispatch();
    const handleClick = useCallback((itemKey) => () => {
        setSelectedKey(itemKey);
    });

    useEffect(() => {
        const fetchAccount = async () => {
            await dispatch(fetchAccountApi());
        };
        void fetchAccount();
    }, [dispatch]);

    return (
        <Layout>
            <Cell>
                <SidebarMenu onClick={handleClick} selectedKey={selectedKey} />
            </Cell>
        </Layout>
    );
};

const SidebarItem = ({ selectedKey, itemKey, name, onClick }) => {
    return (
        <Sidebar.Item>
            <SidebarSectionItem selected={selectedKey === itemKey} onClick={onClick(itemKey)}>
                {name}
            </SidebarSectionItem>
        </Sidebar.Item>
    );
};
const SidebarMenu = ({ selectedKey, onClick }) => {
    const account = useSelector(selectAccount);
    return <Sites />;
};
