import { Box, RadioGroup, ToggleSwitch } from 'wix-style-react';
import { SettingsHeader } from '../../../components/settings-header';
import { WidgetSettingsUpdater } from '../../../components/widget-settings-updater';
import { EventFilter } from './event_filters';

export const Filters = () => {
    return (
        <Box direction="vertical" width="100%">
            <SettingsHeader
                title="Filters"
                info="Filter events by type or location to display the most relevant events on your event listing or calendar."
            />

            <WidgetSettingsUpdater
                updateKey="enableFilters"
                isPremium
                shouldFetchEvents
                tooltipText="Configure what events should be displayed to the users"
                title="Enable Filters"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
            <WidgetSettingsUpdater
                updateKey="filterOperator"
                isPremium
                shouldFetchEvents
                title="Operator"
                tooltipText="When AND is selected then all filters have to match for the event to be listed. When OR is selected then any filter matches will list the event"
            >
                <RadioGroup display="horizontal">
                    {[
                        { value: 'or', label: 'OR' },
                        { value: 'and', label: 'AND' },
                    ].map((val, index) => (
                        <RadioGroup.Radio key={index} value={val.value}>
                            {val.label}
                        </RadioGroup.Radio>
                    ))}
                </RadioGroup>
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater updateKey="filterQueries" shouldFetchEvents>
                <EventFilter />
            </WidgetSettingsUpdater>
        </Box>
    );
};
