import { useCallback, useState } from 'react';
import { Box, Button, Heading, InfoIcon, Loader, Popover, Text } from 'wix-style-react';
import { API_INSTANCE, buildURL } from '../../features/utils';
import { selectSiteData } from '../../features/account-slice';
import { useSelector } from 'react-redux';
import { classes } from './shopify-disconnect-button.st.css';

export const ShopifyDisconnectButton = ({ event }) => {
    const [loading, setLoading] = useState(false);
    const [shown, setShown] = useState(false);
    const site = useSelector(selectSiteData);

    const handleClick = useCallback(async () => {
        setLoading(true);
        const shopifyDisconnect = `/api/v1/dashboard/events/${event?.id}/shopify`;
        await API_INSTANCE.delete(buildURL(site, shopifyDisconnect));
        setLoading(false);
    }, [event?.id, site]);

    return (
        <Box>
            <Popover showArrow shown={shown} appendTo="window" placement={'top'}>
                <Popover.Element>
                    <Button
                        priority="secondary"
                        size="small"
                        skin="destructive"
                        onClick={() => setShown(true)}
                    >
                        <Box gap={2} verticalAlign="middle">
                            <Box gap="1" verticalAlign="middle">
                                Unlink{' '}
                                <InfoIcon
                                    content="Keep this product in Shopify while removing the connection to Ticket Spot. This is useful if you prefer using our widget or no longer want Shopify orders processed by Ticket Spot."
                                    className={classes.info}
                                />
                            </Box>
                            {loading && <Loader size="tiny" />}
                        </Box>
                    </Button>
                </Popover.Element>
                <Popover.Content>
                    <Box align="center" width={'100%'} padding={2}>
                        <Heading appearance="H5">
                            Are you sure you want to unlink this product
                        </Heading>
                    </Box>
                    <Box direction="horizontal" gap={2} margin={3} paddingBottom={2} align="center">
                        <Button
                            size="small"
                            onClick={() => {
                                setShown(false);
                                handleClick();
                            }}
                        >
                            Yes
                        </Button>
                        <Button priority="secondary" size="small" onClick={() => setShown(false)}>
                            No
                        </Button>
                    </Box>
                </Popover.Content>
            </Popover>
        </Box>
    );
};
