import { useState, useEffect } from 'react';
import { Text, Loader, Box, Modal, CustomModalLayout, Button } from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import { useAppDispatch } from '../hooks';
import { notifyWaitlistSubscribers } from '../features/events-slice';

export const WaitlistReleaseModal = ({ releaseCount, selectedIds, event }) => {
    const [loading, setLoading] = useState(false);
    const [released, setReleased] = useState(false);
    const [shown, setShown] = useState(false);
    const [initial, setInitial] = useState(true);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (loading) {
            const timer = setTimeout(() => {
                setLoading(false);
                setReleased(true);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [loading]);

    return (
        <Box>
            <Modal isOpen={shown} onRequestClose={() => setShown(false)} screen="desktop">
                <CustomModalLayout
                    primaryButtonText={released ? null : 'Notify'}
                    primaryButtonOnClick={
                        released
                            ? null
                            : async () => {
                                  setLoading(true);
                                  setInitial(false);
                                  await dispatch(
                                      notifyWaitlistSubscribers({
                                          event_id: event.id,
                                          attendee_ids: selectedIds,
                                      })
                                  );
                              }
                    }
                    secondaryButtonOnClick={() => {
                        setInitial(true);
                        setReleased(false);
                        setShown(false);
                    }}
                    secondaryButtonText="Close"
                    onCloseButtonClick={() => setShown(false)}
                    title="Notify Waitlist Subscribers"
                    width="40%"
                    content={
                        <Box align="center" direction="vertical" gap={2}>
                            {initial && (
                                <Text>
                                    Do you want to notify {releaseCount} attendees that tickets are
                                    available for purchase?
                                </Text>
                            )}
                            {loading && (
                                <Box>
                                    <Loader size="small" />
                                </Box>
                            )}
                            {loading && (
                                <Text>
                                    Notifying {releaseCount} attendees that tickets are available
                                    for purchase, please wait...
                                </Text>
                            )}
                            {released && (
                                <Text>
                                    {releaseCount} attendees have been notified that tickets are now
                                    available for purchase.
                                </Text>
                            )}
                        </Box>
                    }
                />
            </Modal>
            <Button
                skin="inverted"
                size="small"
                prefixIcon={<Icons.UploadExportSmall />}
                onClick={() => setShown(true)}
            >
                Notify
            </Button>
        </Box>
    );
};
