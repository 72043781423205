import { ToggleSwitch } from 'wix-style-react';
import { SettingsHeader } from '../../../components/settings-header';
import { WidgetSettingsUpdater } from '../../../components/widget-settings-updater';

export const EventStatus = ({ label }) => {
    return (
        <>
            <SettingsHeader title={label} />

            <WidgetSettingsUpdater
                updateKey="includePrivateEvents"
                shouldFetchEvents
                isPremium
                title="Show Private Events"
                tooltipText="Enable this option to display your private events on your site."
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="showActiveEvents"
                title="Show Live Events"
                tooltipText="Enable this option to display events that are currently Live. Users will see events that are happening now or in the near future."
                shouldFetchEvents
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
            <WidgetSettingsUpdater
                updateKey="showPreviousEvents"
                isPremium
                hardRefresh
                tooltipText="Display your past events on your site."
                title="Show Previous Events"
                shouldFetchEvents
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
            <WidgetSettingsUpdater
                updateKey="showDraftEvents"
                tooltipText="Display your events that are still in draft mode on your site."
                isPremium
                title="Show Draft Events"
                shouldFetchEvents
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
            <WidgetSettingsUpdater
                updateKey="showSoldOutEvents"
                title="Show Sold Out Events"
                tooltipText="Display events that have reached their maximum ticket capacity."
                shouldFetchEvents
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
        </>
    );
};
