import { useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as Icons from 'wix-ui-icons-common';
import SaveSuccessCheck from '../../assets/save-success-check.gif';
import { classes } from './create-campaign.st.css';

import {
    CustomModalLayout,
    Modal,
    Box,
    FormField,
    Loader,
    Input,
    Text,
    IconButton,
    ToggleSwitch,
    InputStatus,
    RichTextInputArea,
    Tooltip,
} from 'wix-style-react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    createNotificationApi,
    getNotificationApi,
    selectSingleNotification,
    updateNotificationApi,
} from '../../features/campaign-slice';
import { MesssageKeysTable } from './components/message-keys-table';

export const NotificationModal = ({ id, campaign_id }) => {
    const [isModalOpened, setModalOpened] = useState(false);
    const [saving, setSaving] = useState(false);
    const [completed, setCompleted] = useState(false);

    const dispatch = useAppDispatch();

    const openModal = () => setModalOpened(true);
    const closeModal = () => setModalOpened(false);

    const notification = useAppSelector(selectSingleNotification);

    const { handleSubmit, watch, control, setValue, formState } = useForm<any>({
        defaultValues: {
            name: notification?.name,
            message: notification?.message,
            subject: notification?.subject,
            to_email: notification?.from_email,
            enabled: notification?.enabled,
        },
    });
    const onSubmit: SubmitHandler<any> = async (values) => {
        let submitFunc = createNotificationApi;
        if (id) submitFunc = updateNotificationApi;
        setCompleted(false);
        setSaving(true);
        dispatch(
            submitFunc({
                id,
                campaign_id,
                name: values.name,
                message: values.message || '',
                subject: values.subject,
                to_email: values.to_email,
                enabled: values.enabled,
            })
        );
        setCompleted(true);
        setSaving(false);
    };
    useEffect(() => {
        if (id) dispatch(getNotificationApi({ campaign_id, id }));
    }, [id]);

    const renderForm = () => (
        <NotificationInput
            notification={notification}
            campaign_id={campaign_id}
            watch={watch}
            control={control}
            setValue={setValue}
            formState={formState}
        />
    );

    const renderModalContent = () => (
        <CustomModalLayout
            primaryButtonText={
                !completed &&
                (saving ? (
                    <Box gap={1}>
                        <Loader size="tiny" /> Processing
                    </Box>
                ) : (
                    'Save'
                ))
            }
            secondaryButtonText="Close"
            onCloseButtonClick={() => {
                setSaving(false);
                setCompleted(false);
                closeModal();
            }}
            primaryButtonOnClick={completed ? undefined : handleSubmit(onSubmit)}
            secondaryButtonOnClick={() => {
                setSaving(false);
                setCompleted(false);
                closeModal();
            }}
            title={`Notification`}
            subtitle="Get instant updates whenever an attendee RSVPs or purchases a ticket, ensuring you're always in the loop."
        >
            {completed ? <SuccessText /> : renderForm()}
        </CustomModalLayout>
    );

    return (
        <>
            <Box>
                <Tooltip
                    content={
                        notification?.enabled
                            ? 'Active: You will be notified of new orders.'
                            : 'Inactive: You will not be notified of new orders.'
                    }
                >
                    <IconButton skin="light" onClick={openModal}>
                        <Icons.Notification color={notification?.enabled ? 'green' : 'red'} />
                    </IconButton>
                </Tooltip>
            </Box>
            <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
                {renderModalContent()}
            </Modal>
        </>
    );
};

const SuccessText = () => {
    return (
        <Box direction="vertical" gap={2} align="center">
            <img src={SaveSuccessCheck} width={100} />
            <Text>You have successfully saved this notification</Text>
        </Box>
    );
};

const NotificationInput = ({ id, notification, watch, control, setValue, formState }) => {
    const { errors } = formState;
    const watchEnabled = watch('enabled', notification?.enabled || false);
    const messageRef = useRef();

    useEffect(() => {
        if (id === undefined) {
            const message =
                "{{attendee_first_name}} {{attendee_last_name}} has successfully registered for the event titled '{{event_title}}' scheduled on {{event_start_date}} at {{event_start_time}}.";
            setValue(
                'subject',
                '{{attendee_email}} just registered for {{event_title}} on {{event_start_date}} at {{event_start_time}}'
            );
            setValue('message', message);
            messageRef.current?.setValue(message);
        }
    }, [id]);

    useEffect(() => {
        if (notification) {
            if (notification?.message) messageRef.current?.setValue(notification?.message);
            Object.keys(notification).forEach((ev: string) => setValue(ev, notification[ev]));
        }
    }, [notification]);

    return (
        <>
            <Box
                direction="vertical"
                verticalAlign="space-between"
                height="100%"
                width="100%"
                flexGrow={1}
                gap={3}
            >
                <FormField label="Active">
                    <Controller
                        name="enabled"
                        control={control}
                        render={({ field }) => {
                            return (
                                <ToggleSwitch
                                    {...field}
                                    onChange={(e) => {
                                        setValue('enabled', e.target.checked);
                                    }}
                                    checked={watchEnabled}
                                />
                            );
                        }}
                    />
                </FormField>
                <FormField
                    label="Recipient Email"
                    required
                    infoContent="Enter the email address where the notification will be sent."
                >
                    <Controller
                        control={control}
                        name="to_email"
                        rules={{
                            required: 'Recipient Email is required!',
                            max: 140,
                        }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                status={
                                    errors.to_email?.message
                                        ? Input.StatusError
                                        : ('' as InputStatus)
                                }
                            />
                        )}
                    />
                    <small className={classes.error}>{errors.from_email?.message}</small>
                </FormField>
                <MesssageKeysTable />

                <FormField label="Subject" required>
                    <Controller
                        control={control}
                        name="subject"
                        rules={{
                            required: 'Subject is required!',
                            max: 140,
                        }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                status={
                                    errors.subject?.message
                                        ? Input.StatusError
                                        : ('' as InputStatus)
                                }
                            />
                        )}
                    />
                    <small className={classes.error}>{errors.subject?.message}</small>
                </FormField>
                <FormField
                    label="Message Content"
                    required
                    infoContent="Craft a personalized message using tokens like {{attendee_first_name}}, {{event_title}}, and more to dynamically insert attendee and event details."
                >
                    <Controller
                        control={control}
                        name="message"
                        rules={{
                            required: 'Message is required!',
                            max: 140,
                        }}
                        render={({ field }) => (
                            <RichTextInputArea
                                {...field}
                                initialValue={notification?.message}
                                minHeight="140px"
                                maxHeight="400px"
                                onChange={(htmlText: string) => setValue('message', htmlText)}
                                status={
                                    errors.message?.message
                                        ? Input.StatusError
                                        : ('' as InputStatus)
                                }
                                ref={messageRef}
                            />
                        )}
                    />
                    <small className={classes.error}>{errors.message?.message}</small>
                </FormField>
            </Box>
        </>
    );
};
