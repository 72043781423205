import { FormField, Box, Col, Row, Dropdown, Heading } from 'wix-style-react';

import { Controller } from 'react-hook-form';
import { classes } from '../create-events.st.css';
import { EVENT_CATEGORIES, EVENT_SUBCATEGORIES, EVENT_TYPES } from '../type-and-categories';

export const EventCategory = ({ control, setValue, watch, errors }) => {
    const watchType = watch('eventType');
    const watchCategory = watch('category');
    const watchSubcategory = watch('subcategory');
    return (
        <Box direction="vertical" gap={2} width={'100%'}>
            <FormField label={<Heading appearance="H4">Type</Heading>} stretchContent={false}>
                <Controller
                    control={control}
                    name="eventType"
                    render={({ field }) => (
                        <Dropdown
                            {...field}
                            placeholder="Select an event type..."
                            popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                            onSelect={(option) => setValue('eventType', option.id)}
                            selectedId={watchType}
                            options={EVENT_TYPES.map((field) => ({
                                id: field.id,
                                value: field.name,
                            }))}
                        />
                    )}
                />
            </FormField>
            <small className={classes.error}>{errors.type?.message}</small>

            <Box direction="vertical" gap={2} width={'100%'}>
                <FormField label={<Heading appearance="H4">Category</Heading>}>
                    <Controller
                        control={control}
                        name="category"
                        render={({ field }) => (
                            <Dropdown
                                {...field}
                                placeholder="Select a category..."
                                popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                                onSelect={(option) => setValue('category', option.id)}
                                selectedId={watchCategory}
                                options={EVENT_CATEGORIES.map((field) => ({
                                    id: field.id,
                                    value: field.name,
                                }))}
                            />
                        )}
                    />
                </FormField>
                <small className={classes.error}>{errors.category?.message}</small>

                <FormField label={<Heading appearance="H4">Subcategory</Heading>}>
                    <Controller
                        control={control}
                        name="subcategory"
                        render={({ field }) => (
                            <Dropdown
                                {...field}
                                popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                                placeholder="Select a subcategory..."
                                onSelect={(option) => setValue('subcategory', option.id)}
                                selectedId={watchSubcategory}
                                options={EVENT_SUBCATEGORIES.filter(
                                    (c) => c.parent_category.id === watchCategory
                                ).map((field) => ({
                                    id: field.id,
                                    value: field.name,
                                }))}
                            />
                        )}
                    />
                </FormField>
            </Box>
        </Box>
    );
};
