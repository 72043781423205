import { useMemo, useRef } from 'react';
import {
    Box,
    Button,
    Card,
    Row,
    Col,
    Cell,
    Dropdown,
    FormField,
    Input,
    Layout,
    NumberInput,
    RadioGroup,
    Text,
    InputStatus,
    Loader,
    Notification,
    InfoIcon,
    Page,
    ToggleSwitch,
} from 'wix-style-react';
import { classes } from './create-campaign.st.css';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useAppSelector } from '../../hooks';
import { useSelector } from 'react-redux';

import {
    selectCampaignPrefixTypeOptions,
    selectCampaignTimeTypeOptions,
    selectTriggersList,
    getCampaignApi,
    selectSingleCampaign,
    createCampaignApi,
    updateCampaignApi,
    startCampaignsApi,
    stopCampaignsApi,
    selectCampaignIsLoading,
} from '../../features/campaign-slice';
import {
    fetchConnectedAccountsApi,
    selectAccount,
    selectSiteData,
} from '../../features/account-slice';
import type { Campaign } from '../../types';
import { useAppDispatch } from './../../hooks';
import {
    eventResponseTransform,
    fetchEventsApi,
    selectEventsList,
} from '../../features/events-slice';
import { StartCampaignModel } from './components/start-campaign-modal';
import { AttendeeSelection } from '../../components/attendee-selection';
import { uniq } from 'lodash';
import { EventSelection } from './event-selection';
import { ActionSelector } from './action-selector';
import { buildURL } from '../../features/utils';

export const CampaignManage = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectCampaignIsLoading);
    const campaign = id ? useAppSelector(selectSingleCampaign) : null;
    const account = useAppSelector(selectAccount);
    const events = useSelector(selectEventsList);

    useEffect(() => {
        if (id) dispatch(getCampaignApi(id));
    }, [id]);
    if (isLoading)
        return (
            <Box width="100%" height="100px" align="center" verticalAlign="middle">
                <Loader size="small" />
            </Box>
        );
    return (
        <CampaignInput account={account} events={events} campaign={campaign} isUpdating={!!id} />
    );
};

const CampaignInput = ({ campaign }) => {
    const [expandOccurrences, setExpandOccurrences] = useState(false);

    const [selectedIds, setSelectedIds] = useState<Array<string>>(
        campaign?.event_subscription_criteria
            ? campaign?.event_subscription_criteria.map((e) => e.event_id)
            : []
    );
    const [saved, setSaved] = useState(false);
    const [saving, setSaving] = useState(false);
    const [showStartModal, setShowStartModal] = useState(false);

    const { id } = useParams<{ id: string }>();
    const messageRef = useRef();
    const dispatch = useAppDispatch();

    const eventsBase = useAppSelector(selectEventsList);

    const events = useMemo(() => {
        if (!expandOccurrences) return eventsBase;

        return eventsBase.reduce((res, curr) => {
            const occurrences = curr?.occurrences?.map((e, index) => ({
                ...eventResponseTransform(e),
                title: (
                    <Box marginLeft={4}>
                        {index + 1}. {e?.title}
                    </Box>
                ),
            }));
            return res.concat(curr).concat(occurrences || []);
        }, []);
    }, [eventsBase, expandOccurrences]);
    const [attendeeSelections, setAttendeeSelections] = useState(
        campaign?.attendee_selections || []
    );
    const [matchAllSelections, setMatchAllSelections] = useState(
        campaign?.attendee_selection_match_all
    );

    const { handleSubmit, watch, control, setValue, formState, getValues } = useForm<Campaign>({
        defaultValues: {
            ...campaign,
            schedule_time_unit: campaign?.schedule_time_unit || 'MINUTES',
            schedule_time_amount: campaign?.schedule_time_amount || 0,
            schedule_time_direction: campaign?.schedule_time_direction || 'BEFORE',
            schedule_type: campaign?.schedule_type || 1,
            trigger_type: campaign?.watchTriggerType || 'NEW_ORDER',
            is_shared: campaign?.is_shared === undefined ? true : campaign?.is_shared,
            subscribe_all_events: campaign?.subscribe_all_events,
            attendee_selection_type: campaign?.attendee_selection_type || 'automatic',
            email_editor_type: campaign?.email_editor_type || 'basic',
            action_selections: campaign?.action_selections || ['email'],
            zoho_mappings: campaign?.zoho_mappings || {},
            hubspot_mappings: campaign?.hubspot_mappings || {},
            hubspot_workflow_selections: campaign?.hubspot_workflow_selections || [],
            hubspot_action: campaign?.hubspot_action || 'create-update-contact',
            zoho_action: campaign?.zoho_action || 'create-update-contact',
            sms: {
                message: campaign?.sms?.message,
            },
            twitter: {
                message: campaign?.sms?.message,
            },
            facebook: {
                message: campaign?.facebook?.message,
                pages: campaign?.facebook?.pages,
                enable_instagram: campaign?.facebook?.enable_instagram,
            },
            linkedin: {
                message: campaign?.linkedin?.message,
            },
            slack: {
                message: campaign?.slack?.message,
            },
            constant_contact_action: campaign?.constant_contact_action || 'create-update-contact',
            constant_contact_mappings: campaign?.constant_contact_mappings || {},
            constant_contact_list_ids: campaign?.constant_contact_list_ids || [],
        },
    });

    const watchScheduleSuffix = watch('schedule_time_unit', campaign?.schedule_time_unit);
    const watchTriggerType = watch('trigger_type');
    const watchScheduleType = watch('schedule_type');
    const watchIsShared = watch('is_shared');
    const watchAttendeeSelectionType = watch(
        'attendee_selection_type',
        campaign?.attendee_selection_type
    );

    const watchScheduleExecutionType = watch(
        'schedule_time_direction',
        campaign?.schedule_time_direction
    );
    const watchSubscribeAllEvents = watch('subscribe_all_events', campaign?.subscribe_all_events);

    const { errors } = formState;
    const triggers = useAppSelector(selectTriggersList);

    useEffect(() => {
        dispatch(fetchConnectedAccountsApi());
        dispatch(fetchEventsApi(watchSubscribeAllEvents ? 'widget-active' : 'parents-only'));
    }, [dispatch, watchSubscribeAllEvents]);

    useEffect(() => {
        if (id === undefined) {
            const trigger = triggers.find((t) => t.value === watchTriggerType);
            setValue('subject', trigger.subject);
            setValue('message', trigger.message);
            messageRef.current?.setValue(trigger.message);
        }
    }, [id, setValue, triggers, watchTriggerType]);
    const actionSelectionType = {
        0: 'email',
        1: 'sms',
        2: 'slack',
        3: 'twitter',
        4: 'facebook',
        5: 'hubspot',
        6: 'zoho',
        7: 'constantContact',
        8: 'webhook',
    };
    const onSubmit: SubmitHandler<Campaign> = async (values) => {
        let submitFunc = createCampaignApi;
        if (id) submitFunc = updateCampaignApi;
        setSaved(false);
        setSaving(true);
        await dispatch(
            submitFunc({
                id,
                name: values.name,
                message: values.message || '',
                subject: values.subject,
                from_email: values.from_email,
                schedule_type: values.schedule_type,
                schedule_time_amount: values.schedule_time_amount,
                schedule_time_unit: values.schedule_time_unit,
                subscribe_all_events: values.subscribe_all_events,
                schedule_time_direction: values.schedule_time_direction,
                is_shared: values.is_shared,
                enabled: values.enabled || false,
                trigger_type: values.trigger_type,
                event_subscription_criteria: selectedIds.map((id) => ({ event_id: id })),
                attendee_selection_type: values.attendee_selection_type,
                attendee_selections: attendeeSelections,
                attendee_selection_match_all: matchAllSelections,
                email_editor_type: values.email_editor_type,
                sender_name: values.sender_name,
                action_selections: uniq(values.action_selections),
                hubspot_mappings: values.hubspot_mappings,
                hubspot_workflow_selections: values.hubspot_workflow_selections,
                hubspot_action: values.hubspot_action,
                zoho_action: values.zoho_action,
                zoho_mappings: values.zoho_mappings,
                sms: values.sms,
                twitter: values.twitter,
                facebook: values.facebook,
                linkedin: values.linkedin,
                slack: values.slack,
                webhook: values.webhook,
                constant_contact_action: values.constant_contact_action,
                constant_contact_mappings: values.constant_contact_mappings,
                constant_contact_list_ids: values.constant_contact_list_ids,
                email_action_ticket_receipt_pdf: values.email_action_ticket_receipt_pdf,
                email_action_ticket_passes_pdf: values.email_action_ticket_passes_pdf,
            })
        );
        setSaved(true);
        setSaving(false);
        setShowStartModal(id === undefined);
    };
    const history = useHistory();

    useEffect(() => {
        if (campaign) {
            if (campaign?.message) messageRef.current?.setValue(campaign?.message);
            Object.keys(campaign).forEach((ev: string) => setValue(ev, campaign[ev]));
        }
    }, [campaign, setValue]);
    const site = useSelector(selectSiteData);

    const campaignTimeTypeOptions = useAppSelector(selectCampaignTimeTypeOptions);
    const prefixTriggerOptions = useAppSelector(selectCampaignPrefixTypeOptions);

    return (
        <>
            <Box direction="vertical" width="100%" height="100%">
                {showStartModal && <StartCampaignModel />}

                {id && campaign && campaign.enabled && (
                    <Notification theme="standard" show={id && campaign && campaign.enabled}>
                        <Notification.TextLabel>Engagement Running...</Notification.TextLabel>
                        <Notification.CloseButton />
                    </Notification>
                )}
                <Notification
                    theme="success"
                    show={saved}
                    autoHideTimeout={5000}
                    onClose={() => setSaved(false)}
                >
                    <Notification.TextLabel>Successfully Saved Engagement</Notification.TextLabel>
                    <Notification.CloseButton />
                </Notification>

                <Page height="100vh">
                    <Page.Header
                        title={id ? 'Update Engagement' : 'Create Engagement'}
                        actionsBar={
                            <>
                                <Button
                                    className={classes.cancel}
                                    onClick={() =>
                                        history.push(buildURL(site, `/events/engage-promote`))
                                    }
                                >
                                    Exit
                                </Button>
                                <Button onClick={handleSubmit(onSubmit)} className={classes.cancel}>
                                    {saving ? <Loader size="tiny" /> : `${id ? 'Save' : 'Create'}`}
                                </Button>

                                {id && (
                                    <Button
                                        onClick={() =>
                                            campaign?.enabled
                                                ? dispatch(stopCampaignsApi(campaign))
                                                : dispatch(startCampaignsApi(campaign))
                                        }
                                        skin="premium"
                                    >
                                        {campaign?.enabled ? 'Stop' : 'Start'}
                                    </Button>
                                )}
                            </>
                        }
                    />
                    <Page.Content>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col span={12} className={classes.box}>
                                    <Box
                                        direction="vertical"
                                        verticalAlign="space-between"
                                        padding="24px 29px 27px"
                                        borderRadius="8px"
                                        backgroundColor="white"
                                        height="100%"
                                        width="100%"
                                        boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                                        flexGrow={1}
                                    >
                                        <Card>
                                            <Card.Content>
                                                <Layout cols={12}>
                                                    <Cell span={8}>
                                                        <FormField label="Name" required>
                                                            <Controller
                                                                control={control}
                                                                name="name"
                                                                rules={{
                                                                    required: 'Name is required!',
                                                                    max: 140,
                                                                }}
                                                                render={({ field }) => (
                                                                    <Input
                                                                        status={
                                                                            errors.name?.message
                                                                                ? Input.StatusError
                                                                                : ('' as InputStatus)
                                                                        }
                                                                        {...field}
                                                                    />
                                                                )}
                                                            ></Controller>
                                                            <small className={classes.error}>
                                                                {errors.name?.message}
                                                            </small>
                                                        </FormField>
                                                    </Cell>
                                                    <Cell span={4}>
                                                        <FormField
                                                            label="Share Across Widgets"
                                                            infoContent="Do you want to share this engagement with all widgets?"
                                                        >
                                                            <Controller
                                                                name="is_shared"
                                                                control={control}
                                                                render={({ field }) => {
                                                                    return (
                                                                        <ToggleSwitch
                                                                            {...field}
                                                                            onChange={(e) => {
                                                                                setValue(
                                                                                    'is_shared',
                                                                                    e.target.checked
                                                                                );
                                                                            }}
                                                                            checked={watchIsShared}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </FormField>
                                                    </Cell>
                                                </Layout>
                                            </Card.Content>
                                        </Card>
                                    </Box>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12}>
                                    <Box
                                        direction="vertical"
                                        verticalAlign="space-between"
                                        padding="24px 29px 27px"
                                        marginTop="10px"
                                        borderRadius="8px"
                                        backgroundColor="white"
                                        height="100%"
                                        width="100%"
                                        boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                                        flexGrow={1}
                                    >
                                        <Card>
                                            <Card.Header
                                                title="Trigger"
                                                subtitle="Choose from a range of triggers to automate actions and engage with your audience effectively"
                                            />
                                            <Card.Divider />
                                            <Card.Content>
                                                <FormField>
                                                    <Controller
                                                        control={control}
                                                        name="trigger_type"
                                                        rules={{
                                                            required: 'Trigger type is required!',
                                                        }}
                                                        render={({ field }) => (
                                                            <RadioGroup
                                                                selectionArea="always"
                                                                selectionAreaPadding="18px"
                                                                spacing="12px"
                                                                onChange={(e) => {
                                                                    setValue('trigger_type', e);
                                                                }}
                                                                value={field.value}
                                                                disabled={!!id}
                                                            >
                                                                {triggers
                                                                    .filter(
                                                                        (t) =>
                                                                            t.scope !== 'waitlist'
                                                                    )
                                                                    .map((trigger) => {
                                                                        return (
                                                                            <RadioGroup.Radio
                                                                                value={
                                                                                    trigger.value
                                                                                }
                                                                            >
                                                                                <Box direction="vertical">
                                                                                    <Text weight="normal">
                                                                                        {
                                                                                            trigger.name
                                                                                        }
                                                                                    </Text>
                                                                                    <Text size="small">
                                                                                        {
                                                                                            trigger.description
                                                                                        }
                                                                                    </Text>
                                                                                </Box>
                                                                            </RadioGroup.Radio>
                                                                        );
                                                                    })}
                                                            </RadioGroup>
                                                        )}
                                                    />
                                                </FormField>
                                            </Card.Content>
                                        </Card>
                                    </Box>
                                </Col>
                                <Col span={12}>
                                    <Box
                                        direction="vertical"
                                        verticalAlign="space-between"
                                        padding="24px 29px 27px"
                                        borderRadius="8px"
                                        backgroundColor="white"
                                        marginTop="10px"
                                        height="100%"
                                        width="100%"
                                        boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                                        flexGrow={1}
                                    >
                                        <Card>
                                            <Card.Header
                                                title="Timing Options"
                                                subtitle="Choose when your engagement triggers"
                                            />
                                            <Card.Divider />
                                            <Card.Content>
                                                <Box marginBottom={2} marginRight={20}>
                                                    <RadioGroup
                                                        value={watchScheduleType}
                                                        selectionArea="always"
                                                        display="horizonal"
                                                        spacing="12px"
                                                        selectionAreaSkin="outlined"
                                                        selectionAreaPadding="12px 18px"
                                                        onChange={(e: any) =>
                                                            setValue('schedule_type', e)
                                                        }
                                                        className={classes.space}
                                                        disabledRadios={
                                                            watchTriggerType === 'PROMOTION'
                                                                ? [2]
                                                                : [3]
                                                        }
                                                    >
                                                        <RadioGroup.Radio
                                                            value={1}
                                                            className={classes.space}
                                                        >
                                                            Immediately
                                                        </RadioGroup.Radio>
                                                        <RadioGroup.Radio value={2}>
                                                            Custom
                                                        </RadioGroup.Radio>
                                                        <RadioGroup.Radio value={3}>
                                                            Schedule
                                                        </RadioGroup.Radio>
                                                    </RadioGroup>
                                                </Box>
                                                {watchScheduleType === 2 && (
                                                    <Layout>
                                                        <Cell span={2}>
                                                            <Controller
                                                                control={control}
                                                                name="schedule_time_amount"
                                                                render={({ field }) => (
                                                                    <NumberInput
                                                                        {...field}
                                                                        min={0}
                                                                        max={365}
                                                                        onSelect={(option) =>
                                                                            setValue(
                                                                                'schedule_time_amount',
                                                                                option.id
                                                                            )
                                                                        }
                                                                        strict
                                                                        placeholder="1"
                                                                    />
                                                                )}
                                                            />
                                                        </Cell>
                                                        <Cell span={3}>
                                                            <Controller
                                                                control={control}
                                                                name="schedule_time_unit"
                                                                render={({ field }) => (
                                                                    <Dropdown
                                                                        {...field}
                                                                        onSelect={(option) =>
                                                                            setValue(
                                                                                'schedule_time_unit',
                                                                                option.id
                                                                            )
                                                                        }
                                                                        placeholder="Days"
                                                                        selectedId={
                                                                            watchScheduleSuffix
                                                                        }
                                                                        options={
                                                                            campaignTimeTypeOptions
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </Cell>
                                                        <Cell span={3}>
                                                            <Controller
                                                                control={control}
                                                                name="schedule_time_direction"
                                                                render={({ field }) => (
                                                                    <Dropdown
                                                                        {...field}
                                                                        onSelect={(option) =>
                                                                            setValue(
                                                                                'schedule_time_direction',
                                                                                option.id
                                                                            )
                                                                        }
                                                                        selectedId={
                                                                            watchScheduleExecutionType
                                                                        }
                                                                        initialSelectedId={
                                                                            field.value
                                                                        }
                                                                        options={
                                                                            prefixTriggerOptions
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                        </Cell>
                                                    </Layout>
                                                )}
                                            </Card.Content>
                                        </Card>
                                    </Box>
                                </Col>
                            </Row>

                            {[
                                'NEW_ORDER',
                                'EVENT_STARTED',
                                'EVENT_ENDED',
                                'EVENT_CHANGED',
                                'PROMOTION',
                                'ANNOUNCEMENT',
                            ].includes(watchTriggerType) && (
                                <EventSelection
                                    events={events}
                                    control={control}
                                    watch={watch}
                                    campaign={campaign}
                                    setValue={setValue}
                                    setSelectedIds={setSelectedIds}
                                    selectedIds={selectedIds}
                                    expandOccurrences={expandOccurrences}
                                    setExpandOccurrences={setExpandOccurrences}
                                />
                            )}
                            <Row>
                                <Col span={12}>
                                    <Box
                                        direction="vertical"
                                        verticalAlign="space-between"
                                        padding="24px 29px 27px"
                                        borderRadius="8px"
                                        backgroundColor="white"
                                        height="100%"
                                        width="100%"
                                        marginTop="50px"
                                        boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                                        flexGrow={1}
                                    >
                                        <Card>
                                            <Card.Header
                                                title="Actions"
                                                subtitle="Specify the actions to be executed after the trigger event occurs"
                                            />
                                            <Card.Divider />
                                            <Card.Content>
                                                <Layout cols="1">
                                                    <Controller
                                                        control={control}
                                                        name="action_selections"
                                                        render={({ field }) => (
                                                            <ActionSelector
                                                                id={id}
                                                                actionSelectionType={
                                                                    actionSelectionType
                                                                }
                                                                triggers={triggers}
                                                                messageRef={messageRef}
                                                                setValue={setValue}
                                                                control={control}
                                                                errors={errors}
                                                                watch={watch}
                                                                field={field}
                                                                campaign={campaign}
                                                                getValues={getValues}
                                                            />
                                                        )}
                                                    />
                                                </Layout>
                                            </Card.Content>
                                        </Card>
                                    </Box>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12}>
                                    <Box
                                        direction="vertical"
                                        verticalAlign="space-between"
                                        padding="24px 29px 27px"
                                        borderRadius="8px"
                                        backgroundColor="white"
                                        marginTop="50px"
                                        height="100%"
                                        width="100%"
                                        boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                                        flexGrow={1}
                                    >
                                        <Card>
                                            <Card.Header
                                                title="Targeted Audience Selection"
                                                subtitle="Specify Conditions to Customize Recipients"
                                            />
                                            <Card.Divider />
                                            <Card.Content>
                                                <Box marginBottom={2} marginRight={20}>
                                                    <Layout>
                                                        <Cell span="12">
                                                            <Controller
                                                                control={control}
                                                                name="attendee_selection_type"
                                                                render={({ field }) => (
                                                                    <RadioGroup
                                                                        value={
                                                                            watchAttendeeSelectionType
                                                                        }
                                                                        selectionArea="always"
                                                                        display="horizonal"
                                                                        spacing="12px"
                                                                        selectionAreaSkin="outlined"
                                                                        selectionAreaPadding="12px 18px"
                                                                        onChange={(e) =>
                                                                            setValue(
                                                                                'attendee_selection_type',
                                                                                e
                                                                            )
                                                                        }
                                                                        className={classes.space}
                                                                    >
                                                                        <RadioGroup.Radio
                                                                            value={'automatic'}
                                                                            className={
                                                                                classes.space
                                                                            }
                                                                        >
                                                                            All Attendees
                                                                            <Box
                                                                                inline
                                                                                marginLeft={1}
                                                                                marginBottom="3px"
                                                                            >
                                                                                <InfoIcon content="Select this option to include all attendees without applying any filters." />
                                                                            </Box>
                                                                        </RadioGroup.Radio>
                                                                        <RadioGroup.Radio
                                                                            value={'manual'}
                                                                        >
                                                                            Specify Conditions
                                                                            <Box
                                                                                inline
                                                                                marginLeft={1}
                                                                                marginBottom="3px"
                                                                            >
                                                                                <InfoIcon content="Select this option to specify custom filters and conditions to determine the target audience." />
                                                                            </Box>
                                                                        </RadioGroup.Radio>
                                                                    </RadioGroup>
                                                                )}
                                                            />
                                                        </Cell>

                                                        <Cell span="12">
                                                            {watchAttendeeSelectionType ===
                                                                'manual' && (
                                                                <AttendeeSelection
                                                                    selections={attendeeSelections}
                                                                    setSelections={
                                                                        setAttendeeSelections
                                                                    }
                                                                    matchAllSelections={
                                                                        matchAllSelections
                                                                    }
                                                                    setMatchAllSelections={
                                                                        setMatchAllSelections
                                                                    }
                                                                />
                                                            )}
                                                        </Cell>
                                                    </Layout>
                                                </Box>
                                            </Card.Content>
                                        </Card>
                                    </Box>
                                </Col>
                            </Row>
                        </form>
                    </Page.Content>
                </Page>
            </Box>
        </>
    );
};
