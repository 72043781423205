import axios from 'axios';

export const API_INSTANCE = axios.create({
    baseURL: process.env.API_SERVICE_URL,
    withCredentials: true,
});

const toQueryString = (params) => {
    if (!params) return '';
    return Object.keys(params)
        .map((key) => key + '=' + params[key])
        .join('&');
};

export const buildDashboardQuery = (urlSearchParams) => {
    return {
        site: urlSearchParams.get('site'),
        widget: urlSearchParams.get('widget'),
        viewMode: 'editor',
    };
};

export const createSiteData = (urlSearchParams) => {
    return window.brand === 'wix'
        ? buildWixQuery(urlSearchParams)
        : buildDashboardQuery(urlSearchParams);
};

export const buildURLWithState = (state, path: string, opts = '') => {
    const url = new URL(window.location.href);

    if (!url.searchParams.has('brand')) {
        url.searchParams.append('brand', window.brand);
    }

    const params = new URLSearchParams(url.search);
    const optsParams = new URLSearchParams(opts);

    for (const [key, value] of optsParams) {
        params.set(key, value);
    }

    if (state?.account?.site?.request_event_token) {
        params.set('request_event_token', state.account.site.request_event_token);
    }

    const finalParams = params.toString();
    return `${path}?${finalParams}`;
};

export const buildURL = (site, path: string, opts = '') => {
    return `${path}?${toQueryString(site)}${opts}`;
};

export const fullBuildURL = (siteData, path: string, opts = '') => {
    return `${process.env.API_SERVICE_URL}${buildURL(siteData, path, opts)}`;
};

export const buildWixQuery = (urlSearchParams) => {
    return {
        instance: urlSearchParams.get('instance'),
        compId: urlSearchParams.get('origCompId') || urlSearchParams.get('compId'),
        viewMode: urlSearchParams.get('viewMode'),
        brand: 'wix',
    };
};
