import { SettingsHeader } from '../../../components/settings-header';
import { TextInput } from './text-input';

export const CheckoutPromotionalText = ({ label }) => {
    return (
        <>
            <SettingsHeader title={label} />

            <TextInput title="Promo Code Label" updateKey="applyDiscountText" />
            <TextInput title="Promo Code Placeholder" updateKey="promoCodePlaceholderText" />
        </>
    );
};
