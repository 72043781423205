import { Box, ToggleSwitch } from 'wix-style-react';
import { SettingsHeader } from '../../../components/settings-header';
import { WidgetSettingsUpdater } from '../../../components/widget-settings-updater';

export const Eventbrite = () => {
    return (
        <Box direction="vertical">
            <SettingsHeader title="Eventbrite" />

            <WidgetSettingsUpdater
                updateKey="showSummary"
                tooltipText="If your event app (such as Eventbrite) has a summary feature then you can enable it"
                title="Show summary"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="useFullDescription"
                isPremium
                tooltipText="Since Eventbrite has a summary and description input you can choose to also display the description"
                title="Show full description"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
        </Box>
    );
};
