import { useState, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CustomModalLayout, Modal } from 'wix-style-react';
import { updateEventOccurrenceApi } from '../../../features/events-slice';
import { useAppDispatch } from '../../../hooks';
import type { Event } from '../../../types';
import { addTimeToDate, convertToDate, convertToUtc, getMomentDate } from '../create-event';
import { RescheduleForm } from './reschedule-form';

export const OccurrenceModel = ({ occurrence, isOpen }) => {
    const [isModalOpened, setModalOpened] = useState(isOpen);

    const closeModal = () => {
        reset();
        setModalOpened(false);
    };

    useEffect(() => {
        setModalOpened(isOpen);
    }, [isOpen]);

    const { handleSubmit, control, setValue, reset, watch } = useForm<Event>({
        defaultValues: {
            timeStart: getMomentDate(occurrence?.start_date, occurrence?.timezone),
            timeEnd: getMomentDate(occurrence?.end_date, occurrence?.timezone),
            dateEnd: convertToDate(occurrence?.end_date, occurrence?.timezone),
            dateStart: convertToDate(occurrence?.start_date, occurrence?.timezone),
            status: occurrence?.status,
        },
    });
    const dispatch = useAppDispatch();

    const onSubmit: SubmitHandler<Event> = async (values) => {
        addTimeToDate(values.dateStart, values.timeStart);
        addTimeToDate(values.dateEnd, values.timeEnd);
        const start_date = convertToUtc(values.dateStart, occurrence.timezone);
        const end_date = convertToUtc(values.dateEnd, occurrence.timezone);
        await dispatch(
            updateEventOccurrenceApi({
                start_date,
                end_date,
                id: occurrence?.id,
                series_id: occurrence?.series_id,
                status: occurrence?.status,
            })
        );

        closeModal();
        reset();
    };

    const renderModalContent = () => (
        <CustomModalLayout
            primaryButtonText="Update"
            secondaryButtonText="Cancel"
            onCloseButtonClick={closeModal}
            primaryButtonOnClick={handleSubmit(onSubmit)}
            secondaryButtonOnClick={closeModal}
            title="Reschedule"
            width="600px"
            height="400px"
        >
            {
                <RescheduleForm
                    key={occurrence?.id}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                />
            }
        </CustomModalLayout>
    );

    return (
        <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
            {renderModalContent()}
        </Modal>
    );
};
