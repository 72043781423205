import { Box, Checkbox, FormField, GoogleAddressInput } from 'wix-style-react';

import { Controller } from 'react-hook-form';
import { GoogleMapsClient } from '../google-maps-client';
import { GoogleMap } from '../google-map';

export const EventLocation = ({ watch, setValue, control }) => {
    const watchLocation = watch('location');
    const watchAddress = watch('address');
    const watchIsOnline = watch('is_online', false);

    return (
        <Box direction="vertical">
            <Box marginBottom={3}>
                <FormField>
                    <Controller
                        control={control}
                        name="is_online"
                        render={({ field }) => (
                            <Checkbox
                                size="medium"
                                {...field}
                                onChange={(e) => setValue('is_online', e.target.checked)}
                                checked={field.value}
                            >
                                Online Event
                            </Checkbox>
                        )}
                    ></Controller>
                </FormField>
            </Box>

            {!watchIsOnline && (
                <>
                    <FormField label="Location">
                        <GoogleAddressInput
                            value={watchLocation}
                            onChange={(e) => setValue('location', e.target.value)}
                            onSet={(e: any) => {
                                setValue('address', e.address);
                                setValue('location', e.address.formatted);
                            }}
                            Client={GoogleMapsClient as any}
                        />
                    </FormField>
                    <div style={{ height: 250, marginTop: 20 }}>
                        <GoogleMap
                            key={watchAddress?.latLng.lat}
                            latitude={watchAddress?.latLng.lat}
                            longitude={watchAddress?.latLng.lng}
                        />
                    </div>
                </>
            )}
        </Box>
    );
};
