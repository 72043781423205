import { ToggleSwitch } from 'wix-style-react';
import { SettingsHeader } from '../../../components/settings-header';
import { WidgetSettingsUpdater } from '../../../components/widget-settings-updater';

export const CheckoutTicketInfo = ({ label }) => {
    return (
        <>
            <SettingsHeader title={label} />

            <hr className="divider-long" />

            <WidgetSettingsUpdater
                updateKey="showTicketAvailability"
                title="Ticket Availability"
                tooltipText="Display the number of available tickets to the user."
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
        </>
    );
};
