import { useAppSelector } from '../../../../hooks';
import { selectSettings } from '../../../../features/settings-slice';
import { SettingsHeader } from '../../../../components/settings-header';
import { FontColorPicker } from '../font-picker';
import { ColorPicker } from '../color-picker';

export const EventDesign = ({ label }) => {
    const settings = useAppSelector(selectSettings);

    return (
        <div>
            <SettingsHeader title={label} />

            {settings.showHeader && (
                <FontColorPicker
                    title="Main Header"
                    fontUpdateKey="_widgetHeaderFont"
                    colorUpdateKey="headlineColor"
                    startWithTheme="font_4"
                    startWithColor="color-5"
                    fontMaxSize={50}
                />
            )}

            <FontColorPicker
                title="Event Header"
                fontUpdateKey="_eventTitleFont"
                colorUpdateKey="eventTitleColor"
                startWithColor="color-5"
                startWithTheme="font_7"
            />

            <FontColorPicker
                title="Event Details"
                startWithTheme="font_8"
                startWithColor="color-5"
                fontUpdateKey="_eventBodyFont"
                colorUpdateKey="_eventBodyColor"
            />

            <ColorPicker
                title="Event Details Link"
                startWithColor="color-5"
                colorUpdateKey="_eventBodyLinkColor"
            />

            <FontColorPicker
                title="Dates"
                startWithColor="color-5"
                fontUpdateKey="_eventDateFont"
                colorUpdateKey="eventDateColor"
                startWithTheme="font_8"
            />

            <FontColorPicker
                title="Venue"
                startWithTheme="font_8"
                startWithColor="color-5"
                fontUpdateKey="_eventVenueFont"
                colorUpdateKey="eventVenueColor"
            />

            <FontColorPicker
                title="On Sale Event Status"
                startWithTheme="font_6"
                startWithColor="color-5"
                fontUpdateKey="_eventAvailableStatusBodyFont"
                colorUpdateKey="_eventAvailableStatusBodyColor"
                fontMaxSize={25}
            />

            <FontColorPicker
                title="Postponed Event Status"
                startWithTheme="font_9"
                startWithColor="color-5"
                fontUpdateKey="_eventUnavailableStatusBodyFont"
                colorUpdateKey="_eventUnavailableStatusBodyColor"
                fontMaxSize={25}
            />

            <FontColorPicker
                title="Next Event"
                startWithColor="color-5"
                startWithTheme="font_8"
                fontUpdateKey="_nextEventFont"
                colorUpdateKey="_nextEventColor"
                startWithStyle={{ bold: true }}
                fontMaxSize={25}
            />

            <FontColorPicker
                title="Zoom Event Text"
                startWithColor="color-5"
                fontUpdateKey="_eventHostFont"
                colorUpdateKey="_eventHostColor"
                startWithTheme="font_8"
            />
        </div>
    );
};
