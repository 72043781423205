import { ToggleSwitch } from 'wix-style-react';
import { SettingsHeader } from '../../../components/settings-header';
import { WidgetSettingsUpdater } from '../../../components/widget-settings-updater';

export const Promotion = ({ label }) => {
    return (
        <>
            <SettingsHeader title={label} />

            <hr className="divider-long" />

            <WidgetSettingsUpdater
                updateKey="embedTicketPurchasing"
                title="Embed Third-Party Checkout"
                isPremium
                tooltipText="Enable the integration of third-party checkout services (e.g., Eventbrite) within the app to streamline ticket purchasing. Clicking RSVP will show and facilitate the checkout process using the third-party service."
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="enableAddToCalendar"
                isPremium
                tooltipText="Enable your users to save the event to their own calendar."
                title="Display 'Add to Calendar'"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
        </>
    );
};
