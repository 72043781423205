import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, Button, CustomModalLayout, Modal } from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import { createEventOccurrenceApi } from '../../../features/events-slice';
import { useAppDispatch } from '../../../hooks';
import type { Event } from '../../../types';
import { addTimeToDate, convertToDate, convertToUtc, getMomentDate } from '../create-event';
import { RescheduleForm } from './reschedule-form';

export const AddMultiDayEventModel = ({ parentEvent }) => {
    const [isModalOpened, setModalOpened] = useState(false);

    const closeModal = () => {
        reset();
        setModalOpened(false);
    };

    const { handleSubmit, control, setValue, reset, watch } = useForm<Event>({
        defaultValues: {
            timeStart: getMomentDate(parentEvent?.dateStart, parentEvent?.timezone),
            timeEnd: getMomentDate(parentEvent?.dateEnd, parentEvent?.timezone),
            dateEnd: convertToDate(parentEvent?.dateEnd, parentEvent?.timezone),
            dateStart: convertToDate(parentEvent?.dateStart, parentEvent?.timezone),
            status: parentEvent?.status,
        },
    });
    const dispatch = useAppDispatch();

    const onSubmit: SubmitHandler<Event> = async (values) => {
        addTimeToDate(values.dateStart, values.timeStart);
        addTimeToDate(values.dateEnd, values.timeEnd);
        const start_date = convertToUtc(values.dateStart, parentEvent.timezone);
        const end_date = convertToUtc(values.dateEnd, parentEvent.timezone);
        await dispatch(
            createEventOccurrenceApi({
                id: parentEvent.id,
                start_date,
                end_date,
            })
        );

        closeModal();
        reset();
    };

    const renderModalContent = () => (
        <CustomModalLayout
            primaryButtonText="Create"
            secondaryButtonText="Cancel"
            onCloseButtonClick={closeModal}
            primaryButtonOnClick={handleSubmit(onSubmit)}
            secondaryButtonOnClick={closeModal}
            title="Add Specific Dates"
            width="600px"
            height="400px"
        >
            {
                <RescheduleForm
                    key={parentEvent?.id}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                />
            }
        </CustomModalLayout>
    );

    return (
        <Box align="right" marginBottom={3}>
            <Button prefixIcon={<Icons.AddSmall />} onClick={() => setModalOpened(true)}>
                Add Specific Date
            </Button>
            <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
                {renderModalContent()}
            </Modal>
        </Box>
    );
};
