import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
    Cell,
    CustomModalLayout,
    Layout,
    Modal,
    Button,
    Box,
    FormField,
    Input,
    InputStatus,
    RadioGroup,
    Text,
} from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import { createTeamMember, updateTeamMember } from '../../features/site-slice';
import { useAppDispatch } from '../../hooks';

export const ROLES = [
    {
        id: 'ADMIN',
        value: 'Admin',
        description:
            'Has access to edit, publish and manage site, including billing, payments, events, automation, promotion and inviting people',
    },
    {
        id: 'BILLING_MANAGER',
        value: 'Billing Manager',
        description: 'Can manage subscriptions, add payment methods and view invoices',
    },
    {
        id: 'EVENT_MANAGER',
        value: 'Event Manager',
        description: 'Has access to manage events including adding automation and promotions',
    },
    {
        id: 'DESIGN_MANAGER',
        value: 'Design Manager',
        description:
            'Has access to designing and configuring the widget which is installed onto your site',
    },
];

export const TeamInviteModel = ({ member, siteId, onClose }) => {
    const [isModalOpened, setModalOpened] = useState(!!member);

    const dispatch = useAppDispatch();

    const openModal = () => {
        setModalOpened(true);
        onClose && onClose();
    };
    const closeModal = () => setModalOpened(false);

    const memberRole = member?.role && member?.role[0];
    const { handleSubmit, control, formState, setValue, watch } = useForm({
        defaultValues: { ...member, role: memberRole || 'ADMIN' },
    });

    const onSubmit: SubmitHandler<any> = async (values) => {
        const submitFunc = member ? updateTeamMember : createTeamMember;
        await dispatch(
            submitFunc({ siteId, data: { ...values, roles: [values.role], id: member?.id } })
        );

        closeModal();
    };

    const { errors } = formState;

    const watchRole = watch('role');
    const renderForm = () => (
        <Layout>
            {!member && (
                <Cell span={12}>
                    <FormField label="Email" required>
                        <Controller
                            control={control}
                            name="email"
                            rules={{
                                required: 'Email is required!',
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: 'Entered value does not match email format',
                                },
                            }}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder="name@example.com"
                                    status={
                                        errors.email?.message
                                            ? Input.StatusError
                                            : ('' as InputStatus)
                                    }
                                />
                            )}
                        />
                    </FormField>
                </Cell>
            )}
            <Cell span={12}>
                <FormField
                    label="Role"
                    infoContent="Update what the team member has access to within the site"
                >
                    <Controller
                        control={control}
                        name="role"
                        render={({ field }) => (
                            <RadioGroup
                                selectionArea="always"
                                selectionAreaPadding="18px"
                                spacing="12px"
                                onChange={(e) => {
                                    setValue('role', e);
                                }}
                                value={watchRole}
                            >
                                {ROLES.map((role, index) => {
                                    return (
                                        <RadioGroup.Radio key={index} value={role.id}>
                                            <Box direction="vertical">
                                                <Text weight="normal">{role.value}</Text>
                                                <Text size="small">{role.description}</Text>
                                            </Box>
                                        </RadioGroup.Radio>
                                    );
                                })}
                            </RadioGroup>

                            // <Dropdown
                            //     {...field}
                            //     border="standard"
                            //     options={ROLES}
                            //     selectedId={watchRole}
                            //     onSelect={(option) => setValue('role', option.id)}
                            // />
                        )}
                    ></Controller>
                </FormField>
            </Cell>
        </Layout>
    );

    const renderModalContent = () => (
        <CustomModalLayout
            primaryButtonText={member ? 'Update' : 'Add'}
            secondaryButtonText="Cancel"
            onCloseButtonClick={closeModal}
            primaryButtonOnClick={handleSubmit(onSubmit)}
            secondaryButtonOnClick={closeModal}
            title={`${member ? 'Update' : 'Add'} team member`}
            width="600px"
        >
            {renderForm()}
        </CustomModalLayout>
    );

    return (
        <>
            {!member && (
                <Box>
                    <Button prefixIcon={<Icons.Add />} onClick={openModal}>
                        Invite People
                    </Button>
                </Box>
            )}
            <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
                {renderModalContent()}
            </Modal>
        </>
    );
};
