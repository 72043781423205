import { FC, useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Cell,
    ColorInput,
    CustomModalLayout,
    FileUpload,
    FormField,
    IconButton,
    ImageViewer,
    InfoIcon,
    Input,
    InputArea,
    Item,
    Layout,
    Loader,
    Modal,
    Row,
    Tabs,
    Divider,
    Text,
    SectionHelper,
    Tooltip,
    ToggleSwitch,
} from 'wix-style-react';
import { classes } from './site-edit-modal.st.css';
import * as Icons from 'wix-ui-icons-common';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { API_INSTANCE, buildURL } from '../features/utils';
import { getPresignedURL, toBase64 } from '../utils';
import { isEmpty } from 'lodash';
import { TextInput } from '../modules/settings/text/text-input';
import { ColorPicker } from '../modules/settings/design/color-picker';
import { publishSettingsApi, selectSettings, updateSettingsApi } from '../features/settings-slice';
import { useAppDispatch, useAppSelector } from '../hooks';
import CustomDomainComponent from './SEOAndCustomDomain';
import { selectSiteData } from '../features/account-slice';
import { PlanNotification } from './plan-notification';

export const SiteEditModal: FC<any> = ({ site, handleSiteUpdate, includeDesign }) => {
    const [isModalOpened, setModalOpened] = useState(false);

    return (
        <Box align="right" marginBottom={3}>
            <IconButton onClick={() => setModalOpened(true)}>
                <Icons.Edit />
            </IconButton>
            <SiteModal
                site={site}
                handleSiteUpdate={handleSiteUpdate}
                isModalOpened={isModalOpened}
                setModalOpened={setModalOpened}
                includeDesign={includeDesign}
            />
        </Box>
    );
};

export const SiteModal = ({
    site,
    handleSiteUpdate,
    isModalOpened,
    setModalOpened,
    includeDesign,
}) => {
    const [loading, setLoading] = useState(false);
    const [resetProcessing, setResetProcessing] = useState(false);

    const { handleSubmit, control, watch, setValue, reset, getValues } = useForm<Event>({
        defaultValues: { ...site, brand_background_color: '#5651bd', brand_text_color: '#fff' },
    });
    const dispatch = useAppDispatch();
    const settings = useAppSelector(selectSettings);
    const siteData = useAppSelector(selectSiteData);

    const watchImageUrl = watch('logo_url');
    const watchLandingImageUrl = watch('landing_image_url');
    const [activeId, setActiveId] = useState(0);

    const [imageUrl, setImageUrl] = useState(watchImageUrl);
    const [landingImageUrl, setLandingImageUrl] = useState(watchLandingImageUrl);

    const handleRemoveImage = useCallback(
        (index) => {
            setValue('image_urls', null);
            setValue('images', null);
            setImageUrl(null);
        },
        [setValue]
    );
    const handleRemoveLandingPageImage = useCallback(
        (index) => {
            setValue('landing_header_image_url', null);
            setValue('landing_header_image', null);
            setLandingImageUrl(null);
        },
        [setValue]
    );

    useEffect(() => {
        async function setData() {
            if (site && site.logo_url) {
                const logo = site.logo_url;
                setImageUrl(logo);
            }
            if (site && site.landing_header_image_url) {
                const logo = site.landing_header_image_url;
                setLandingImageUrl(logo);
            }
        }
        void setData();
    }, [site]);

    const closeModal = () => {
        setModalOpened(false);
    };

    const onSubmit: SubmitHandler<any> = async (values) => {
        setLoading(true);
        const url = values.logo
            ? await getPresignedURL(site, values.logo, `/api/v1/dashboard/sites/presigned-url`)
            : values.logo_url;
        const landing_url = values.landing_header_image
            ? await getPresignedURL(
                  site,
                  values.landing_header_image,
                  `/api/v1/dashboard/sites/presigned-url`
              )
            : values.landing_header_image_url;
        const newSite = { ...values, logo_url: url, landing_header_image_url: landing_url };
        await API_INSTANCE.put(buildURL(siteData, `/api/v1/dashboard/sites/${site.id}`), newSite);
        handleSiteUpdate(newSite.id, newSite);
        await dispatch(publishSettingsApi());
        setLoading(false);

        closeModal();
    };

    const handleReset = async () => {
        setResetProcessing(true);
        const defaultValues = {
            overrides: {
                _eventpage_buyTicketsText: 'BUY TICKETS',
                _eventpage_soldOutTicketsText: 'TICKETS SOLD OUT',
                _eventpage_eventEndedTicketsText: 'EVENT ENDED',
                _eventpage_ticketsUnavailableText: 'TICKETS UNAVAILABLE',
                _eventpage_buttonBackgroundColor: '#5650bf',
                _eventpage_buttonTextColor: '#ffffff',
                _eventpage_pageBackgroundColor: '#ffffff',
                _eventpage_pageTextColor: '#030229',
                _eventpage_aboutHostBackgroundColor: '#ffffff',
                _eventpage_aboutHostTextColor: '#ffffff',
                _landingpage_headerText: 'Explore Our Latest Events',
                _landingpage_subHeaderText:
                    'Explore our upcoming events and secure your tickets now!',
                _landingpage_tagText: 'Mark Your Dates',
                _landingpage_headerTextColor: '#000000',
                _landingpage_subHeaderTextColor: '#000000',
                _landingpage_tagTextColor: '#000000',
                _landingpage_tagBackgroundColor: '#F9E26A',
            },
        };

        const originalOverrides = settings?.design?.overrides || {};
        const overrides = {
            ...originalOverrides,
            ...defaultValues.overrides,
        };
        await dispatch(updateSettingsApi({ design: { ...settings.design, overrides: overrides } }));
        await dispatch(publishSettingsApi());
        setResetProcessing(false);
        reset();
    };

    const tabs = [
        {
            id: 0,
            title: (
                <Box direction="horizontal" gap={1} align="center">
                    <Box gap={1}>
                        <Icons.InfoCircle /> <Text>About</Text>
                    </Box>
                </Box>
            ),
        },
        {
            id: 1,
            title: (
                <Box direction="horizontal" gap={1} align="center">
                    <Box gap={1}>
                        <Icons.Preview /> <Text>SEO and Custom Domain</Text>{' '}
                        {site && site.custom_domain && site.custom_domain_verified_at === null && (
                            <Tooltip content="The domain has not been verified.">
                                <Icons.StatusWarning className={classes.failed} />
                            </Tooltip>
                        )}
                    </Box>
                </Box>
            ),
        },
        {
            id: 4,
            title: (
                <Box direction="horizontal" gap={1} align="center">
                    <Box gap={1}>
                        <Icons.AddChannel /> <Text>User Event Request</Text>
                        <InfoIcon content="Enable User Event Request to allow the public to submit event proposals for your review and approval." />
                    </Box>
                </Box>
            ),
        },
    ].concat(
        includeDesign
            ? [
                  {
                      id: 2,
                      title: (
                          <Box direction="horizontal" gap={1} align="center">
                              <Box gap={1}>
                                  <Icons.Desktop /> <Text>Event Page Design</Text>
                              </Box>
                          </Box>
                      ),
                  },
                  {
                      id: 3,
                      title: (
                          <Box direction="horizontal" gap={1} align="center">
                              <Box gap={1}>
                                  <Icons.SiteSearch /> <Text>Landing Page Design</Text>
                              </Box>
                          </Box>
                      ),
                  },
              ]
            : []
    );

    const renderModalContent = () => (
        <CustomModalLayout
            primaryButtonText={
                <Box gap={2}>{loading && <Loader size="tiny" />} Save and Close</Box>
            }
            secondaryButtonText="Cancel"
            onCloseButtonClick={closeModal}
            primaryButtonOnClick={handleSubmit(onSubmit)}
            secondaryButtonOnClick={closeModal}
            title={`Edit ${site?.site_display_name}`}
            width="50%"
        >
            <Tabs
                activeId={activeId}
                onClick={(value: Item) => setActiveId(value.id as number)}
                items={tabs}
            />
            {activeId === 0 && (
                <AboutSite
                    control={control}
                    setValue={setValue}
                    handleRemoveImage={handleRemoveImage}
                    imageUrl={imageUrl}
                    setImageUrl={setImageUrl}
                />
            )}
            {activeId === 1 && (
                <PlanNotification type="business" text="attach custom domain">
                    <CustomDomainComponent site={site} control={control} setValue={setValue} />
                </PlanNotification>
            )}
            {activeId === 2 && (
                <BrandEventPageSite handleReset={handleReset} resetProcessing={resetProcessing} />
            )}
            {activeId === 3 && (
                <LandingPageSite
                    setValue={setValue}
                    handleRemoveLandingImage={handleRemoveLandingPageImage}
                    handleReset={handleReset}
                    resetProcessing={resetProcessing}
                    landingImageUrl={landingImageUrl}
                    setLandingImageUrl={setLandingImageUrl}
                />
            )}
            {activeId === 4 && <CreateEventRequest control={control} />}
        </CustomModalLayout>
    );
    return (
        <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
            {renderModalContent()}
        </Modal>
    );
};

const AboutSite = ({ control, setValue, handleRemoveImage, imageUrl, setImageUrl }) => {
    return (
        <Layout gap={2}>
            <Row>
                <Cell span={6}>
                    <Box direction="vertical" gap={2} marginTop={2}>
                        <FormField label="Site Name">
                            <Controller
                                control={control}
                                name="site_display_name"
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormField>
                        <FormField
                            label="Brand Background Color"
                            infoContent={
                                'This brand background color will appear on your attendee ticket pass.'
                            }
                        >
                            <Controller
                                control={control}
                                name="brand_background_color"
                                render={({ field }) => (
                                    <ColorInput
                                        popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                                        {...field}
                                        onChange={(val) => field.onChange(val, true)}
                                    />
                                )}
                            />
                        </FormField>
                        <FormField
                            label="Brand Text Color"
                            infoContent={
                                'This brand text color will appear on your attendee ticket pass.'
                            }
                        >
                            <Controller
                                control={control}
                                name="brand_text_color"
                                render={({ field }) => (
                                    <ColorInput
                                        popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                                        {...field}
                                        onChange={(val) => field.onChange(val, true)}
                                    />
                                )}
                            />
                        </FormField>
                        <FormField label="Site URL">
                            <Controller
                                control={control}
                                rules={{
                                    validate: (url: string) => {
                                        if (isEmpty(url)) return true;
                                        try {
                                            new URL(url);
                                        } catch (e) {
                                            return false;
                                        }
                                        return true;
                                    },
                                }}
                                name="url"
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormField>
                        <FormField
                            label="Organization Name"
                            infoContent={
                                "Specify your organization's name to be featured on Wallet Passes for iOS and during Wallet QR Code check-ins."
                            }
                        >
                            <Controller
                                control={control}
                                name="organization_name"
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormField>
                        <FormField
                            label="About"
                            infoContent={
                                'Briefly introduce your brand, mission, and offerings. This description will be visible to event visitors on the event page, '
                            }
                        >
                            <Controller
                                control={control}
                                name="about"
                                render={({ field }) => (
                                    <InputArea
                                        {...field}
                                        placeholder="Highlight your vision and what attendees can expect from your events."
                                        rows={4}
                                        maxLength={600}
                                        hasCounter
                                        resizable
                                    />
                                )}
                            />
                        </FormField>
                    </Box>
                </Cell>
                <Cell span={6}>
                    <Box marginLeft={3}>
                        <FormField
                            label="Site Logo"
                            infoContent={
                                "Upload a logo to enhance your brand presence. It will be displayed in your users' iOS or Android Wallet during QR Code check-ins."
                            }
                        >
                            <FileUpload
                                multiple
                                onChange={async (files) => {
                                    const newImageFile = files[0];
                                    const newImageUrl = await toBase64(newImageFile);

                                    setValue('logo_url', newImageUrl);
                                    setValue('logo', newImageFile);
                                    setImageUrl(newImageUrl);
                                }}
                                accept=".jpeg,.gif,.png,.jpg"
                            >
                                {({ openFileUploadDialog }) => {
                                    return (
                                        <ImageViewer
                                            onAddImage={openFileUploadDialog}
                                            width={230}
                                            height={230}
                                            imageUrl={imageUrl}
                                            onRemoveImage={handleRemoveImage}
                                        />
                                    );
                                }}
                            </FileUpload>
                        </FormField>
                    </Box>
                </Cell>
            </Row>
        </Layout>
    );
};

const BrandEventPageSite = ({ handleReset, resetProcessing }) => {
    return (
        <Box marginTop={3} direction="vertical">
            <Box marginTop={2}>
                <SectionHelper appearance="standard">
                    <Box gap={1}>
                        <Box>
                            <Icons.Desktop />
                        </Box>
                        <Text weight="bold">
                            Customize your dedicated event page to reflect your brand's unique
                            style. In this section, you can easily change colors and text, ensuring
                            that every detail aligns with your branding.{' '}
                        </Text>
                    </Box>
                </SectionHelper>
            </Box>

            <Box marginBottom={4} marginLeft={-2} align="right">
                <Button
                    prefixIcon={<Icons.Revert />}
                    onClick={handleReset}
                    skin="inverted"
                    suffixIcon={
                        <InfoIcon size="small" content="Reset styles back to the default" />
                    }
                >
                    Reset {resetProcessing && <Loader size="tiny" />}
                </Button>
            </Box>
            <Layout gap={2}>
                <Row className={classes.row}>
                    <Cell span={6}>
                        <TextInput
                            placeholder="BUY TICKETS"
                            title="Buy Tickets Button Text"
                            updateKey="_eventpage_buyTicketsText"
                            tooltipContent="Enter the text that will appear on the 'Buy Tickets' button"
                            noDivider
                        />
                    </Cell>
                    <Cell span={6}>
                        <TextInput
                            placeholder="TICKETS SOLD OUT"
                            title="Sold Out Button Text"
                            updateKey="_eventpage_soldOutTicketsText"
                            tooltipContent="Enter the button text that will appear when tickets are sold out"
                            noDivider
                        />
                    </Cell>
                    <Cell span={6}>
                        <TextInput
                            placeholder="EVENTS ENDED"
                            title="Event Ended Button Text"
                            updateKey="_eventpage_eventEndedTicketsText"
                            tooltipContent="Enter the button text that will appear when the event has ended"
                            noDivider
                        />
                    </Cell>
                    <Cell span={6}>
                        <TextInput
                            placeholder="TICKETS UNAVAILABLE"
                            title="Tickets Unavailable Button Text"
                            updateKey="_eventpage_ticketsUnavailableText"
                            tooltipContent="Enter the button text that will appear when there are no tickets or your tickets are not available to be purchased"
                            noDivider
                        />
                    </Cell>
                </Row>
                <Row>
                    <Cell span={12}>
                        <Divider />
                    </Cell>
                </Row>

                <Row className={classes.row}>
                    <Cell span={6}>
                        <ColorPicker
                            includeDivider={false}
                            title="Button Background Color"
                            colorUpdateKey="_eventpage_buttonBackgroundColor"
                            defaultValue="#5650bf"
                            tooltipContent="Select a background color for your button to make it stand out on your event page."
                        />
                    </Cell>
                    <Cell span={6}>
                        <ColorPicker
                            includeDivider={false}
                            title="Button Text Color"
                            colorUpdateKey="_eventpage_buttonTextColor"
                            defaultValue="#ffffff"
                            tooltipContent="Choose a color for your button's text to enhance visibility and match your event's style."
                        />
                    </Cell>
                </Row>
                <Row className={classes.row}>
                    <Cell span={6}>
                        <ColorPicker
                            includeDivider={false}
                            title="Page Background Color"
                            colorUpdateKey="_eventpage_pageBackgroundColor"
                            defaultValue="#ffffff"
                            tooltipContent="Pick a background color for your event page to create the perfect backdrop for your event details."
                        />
                    </Cell>
                    <Cell span={6}>
                        <ColorPicker
                            includeDivider={false}
                            title="Page Text Color"
                            colorUpdateKey="_eventpage_pageTextColor"
                            defaultValue="#030229"
                            tooltipContent="Select the color of the text on your event page to ensure readability and align with your event's aesthetic."
                        />
                    </Cell>
                </Row>
                <Row>
                    <Cell span={6}>
                        <ColorPicker
                            includeDivider={false}
                            title="About Host Background Color"
                            colorUpdateKey="_eventpage_aboutHostBackgroundColor"
                            defaultValue="#ffffff"
                            tooltipContent="Select the background of the text on the About Your Host Section"
                        />
                    </Cell>
                    <Cell span={6}>
                        <ColorPicker
                            includeDivider={false}
                            title="About Host Text Color"
                            colorUpdateKey="_eventpage_aboutHostTextColor"
                            defaultValue="#ffffff"
                            tooltipContent="Select the color of the text on the About Your Host Section"
                        />
                    </Cell>
                </Row>
            </Layout>
        </Box>
    );
};

const CreateEventRequest = ({ control }) => {
    return (
        <PlanNotification type="business" text="create event request">
            <Box marginLeft={6} marginTop={2} marginBottom={4}>
                <Layout gap={2}>
                    <Row>
                        <Cell span={12}>
                            <FormField
                                label="Enable"
                                infoContent="Allow users to create events pending your approval."
                            >
                                <Controller
                                    control={control}
                                    name="enable_public_create_event"
                                    render={({ field }) => (
                                        <ToggleSwitch {...field} checked={field.value} />
                                    )}
                                />
                            </FormField>
                        </Cell>
                    </Row>
                </Layout>
            </Box>
        </PlanNotification>
    );
};

const LandingPageSite = ({
    setValue,
    handleRemoveLandingImage,
    handleReset,
    resetProcessing,
    landingImageUrl,
    setLandingImageUrl,
}) => {
    return (
        <Box marginTop={3} direction="vertical">
            <Box marginTop={2}>
                <SectionHelper appearance="standard">
                    <Box gap={1}>
                        <Box>
                            <Icons.SiteSearch />
                        </Box>
                        <Text weight="bold">
                            Automatically showcase your events with our integrated widget on your
                            custom landing page, where you can customize colors, themes, and text.
                            Design effortlessly, creating a personalized and engaging first
                            impression that aligns seamlessly with your brand.
                        </Text>
                    </Box>
                </SectionHelper>
            </Box>

            <Box marginBottom={4} marginLeft={-2} align="right">
                <Button
                    prefixIcon={<Icons.Revert />}
                    onClick={handleReset}
                    skin="inverted"
                    suffixIcon={
                        <InfoIcon size="small" content="Reset styles back to the default" />
                    }
                >
                    Reset {resetProcessing && <Loader size="tiny" />}
                </Button>
            </Box>
            <Layout gap={2}>
                <Row className={classes.row}>
                    <Cell span={6}>
                        <Box gap={2} direction="vertical">
                            <TextInput
                                placeholder="Explore Our Latest Events"
                                title="Header Text"
                                updateKey="_landingpage_headerText"
                                noDivider
                            />
                            <TextInput
                                placeholder="Explore our upcoming events and secure your tickets now!"
                                title="Sub Header Text"
                                updateKey="_landingpage_subHeaderText"
                                noDivider
                            />
                            <TextInput
                                placeholder="Mark Your Dates"
                                title="Header Tag Text"
                                updateKey="_landingpage_tagText"
                                noDivider
                            />
                        </Box>
                    </Cell>
                    <Cell span={6}>
                        <Box marginLeft={3}>
                            <FormField label="Header Image">
                                <FileUpload
                                    multiple
                                    onChange={async (files) => {
                                        const newImageFile = files[0];
                                        const newImageUrl = await toBase64(newImageFile);

                                        setValue('landing_header_image_url', newImageUrl);
                                        setValue('landing_header_image', newImageFile);
                                        setLandingImageUrl(newImageUrl);
                                    }}
                                    accept=".jpeg,.gif,.png,.jpg"
                                >
                                    {({ openFileUploadDialog }) => {
                                        return (
                                            <ImageViewer
                                                onAddImage={openFileUploadDialog}
                                                width={230}
                                                height={230}
                                                imageUrl={landingImageUrl}
                                                onRemoveImage={handleRemoveLandingImage}
                                            />
                                        );
                                    }}
                                </FileUpload>
                            </FormField>
                        </Box>
                    </Cell>
                </Row>
                <Row>
                    <Cell span={12}>
                        <Divider />
                    </Cell>
                </Row>

                <Row className={classes.row}>
                    <Cell span={6}>
                        <ColorPicker
                            includeDivider={false}
                            title="Header Text Color"
                            colorUpdateKey="_landingpage_headerTextColor"
                            defaultValue="#000000"
                        />
                    </Cell>
                    <Cell span={6}>
                        <ColorPicker
                            includeDivider={false}
                            title="Sub Header Text Color"
                            colorUpdateKey="_landingpage_subHeaderTextColor"
                            defaultValue="#000000"
                        />
                    </Cell>
                </Row>
                <Row className={classes.row}>
                    <Cell span={6}>
                        <ColorPicker
                            includeDivider={false}
                            title="Tag Text Color"
                            colorUpdateKey="_landingpage_tagTextColor"
                            defaultValue="#000000"
                        />
                    </Cell>
                    <Cell span={6}>
                        <ColorPicker
                            includeDivider={false}
                            title="Tag Background Color"
                            colorUpdateKey="_landingpage_tagBackgroundColor"
                            defaultValue="#F9E26A"
                        />
                    </Cell>
                </Row>
            </Layout>
        </Box>
    );
};
