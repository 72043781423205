import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
    Heading,
    Box,
    Card,
    Layout,
    Cell,
    Table,
    FormField,
    Loader,
    Button,
    Badge,
} from 'wix-style-react';

import { fetchAttendeeApi, selectAttendee } from '../../features/attendees-slice';
import { useAppDispatch } from '../../hooks';
import { ATTENDEE_STATUS_OPTIONS } from './event-attendee-list';

export const AttendeePublicView = () => {
    const { id } = useParams<{ id: string }>();

    const dispatch = useAppDispatch();

    const attendee = useSelector(selectAttendee);

    useEffect(() => {
        dispatch(fetchAttendeeApi(id));
    }, [dispatch]);

    if (!attendee) {
        return (
            <Box width="100%" height="100px" align="center" verticalAlign="middle">
                <Loader size="large" />
            </Box>
        );
    }
    return <AttendeeFields attendee={attendee} />;
};

const AttendeeFields = ({ attendee }) => {
    const answers = attendee?.answers || [];

    const orders = attendee?.cart?.orders.map((order) => {
        const ticket = order.ticket;
        return {
            currency: order?.currency,
            ticket_name: `${order?.quantity}x ${ticket?.name}`,
            ticket_total: order?.quantity * ticket?.price,
        };
    });

    const orderColumns = [
        { ticket_name: 'Ticket', render: (row) => row?.ticket_name },
        {
            ticket_name: 'Total',
            render: (row) =>
                `${getSymbolFromCurrency(attendee?.cart?.currency)} ${row.ticket_total}`,
        },
    ];

    const momentTime = moment.tz(attendee?.event?.start_date, attendee?.event?.timezone);
    const date = momentTime.format('ddd MMM Do');
    const time = momentTime.format('hh:mm A');

    return (
        <Box width="50%" align="center" margin="0 auto">
            <Card>
                <Card.Header
                    title={
                        <Box>
                            {attendee?.customer?.first_name} {attendee?.customer?.last_name} (
                            {attendee?.customer?.email}){' '}
                            <Box marginLeft={2}>
                                <Badge skin="neutralSuccess" size="small">
                                    {
                                        ATTENDEE_STATUS_OPTIONS.find(
                                            (opt) => opt.id === attendee?.status
                                        )?.value
                                    }
                                </Badge>
                            </Box>
                        </Box>
                    }
                    subtitle={`${attendee?.event?.title} on ${date} at ${time}`}
                />
                <Card.Content>
                    <Layout gap="18px">
                        {answers.map((answer, index) => (
                            <Cell span={6} key={index}>
                                <FormField label={answer.question}>{answer?.answer}</FormField>
                            </Cell>
                        ))}
                    </Layout>
                </Card.Content>
                <Card.Divider />
                <Card.Content>
                    <Layout>
                        <Cell>
                            <Heading appearance="H6">Ticket Details</Heading>
                        </Cell>
                        <Cell>
                            <Table skin="neutral" data={orders} columns={orderColumns}>
                                <Table.Content titleBarVisible={false} />
                            </Table>
                        </Cell>
                        {attendee?.status !== 'checkedIn' && (
                            <Cell>
                                <Box width={'100%'} align="center">
                                    <Button size="large">Check-In</Button>
                                </Box>
                            </Cell>
                        )}
                    </Layout>
                </Card.Content>
            </Card>
        </Box>
    );
};
