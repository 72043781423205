import { SettingsHeader } from '../../../components/settings-header';
import { selectSettings } from '../../../features/settings-slice';
import { useAppSelector } from '../../../hooks';
import { TextInput } from './text-input';

export const WidgetText = ({ label }) => {
    const settings = useAppSelector(selectSettings);
    return (
        <div>
            <SettingsHeader title={label} />
            <hr className="divider-long" />

            {settings.showHeader && (
                <TextInput
                    placeholder="Upcoming Events"
                    title="Main Header"
                    updateKey="widgetTitle"
                    maxLength={200}
                />
            )}

            <TextInput
                placeholder="No upcoming events"
                title="No Events Message"
                maxLength={200}
                updateKey="noEventsText"
                tooltipText="Visitors will see this message when you have no events."
            />
            <TextInput
                placeholder="Meeting host:"
                title="Host text"
                maxLength={200}
                updateKey="hostText"
                tooltipText="Visitors will see this message when you have no upcoming events."
            />
            <TextInput
                placeholder="Load More Events"
                title="Load More Events Text"
                maxLength={200}
                updateKey="loadMoreButtonText"
            />
        </div>
    );
};
