import {
    AnyAction,
    createAsyncThunk,
    createSlice,
    PayloadAction,
    ThunkAction,
} from '@reduxjs/toolkit';

import type { RootState } from '../store';
import type { Event, ResponseEvent, Ticket } from '../types';
import { API_INSTANCE, buildURLWithState } from './utils';

export type EventSliceState = {
    isLoading: boolean;
    isTicketingLoading: boolean;
    data: Event[];
    tickets: [];
    eventAttendeeStats: any;
    eventAttendeesFilterValues: any;
    promos: [];
    error: Error | null;
    searchText: string;
    currentEvent?: Event;
    occurrences?: any[];
};

const initialState: EventSliceState = {
    isLoading: false,
    isTicketingLoading: false,
    data: [],
    tickets: [],
    promos: [],
    eventAttendeesFilterValues: {},
    error: null,
    searchText: '',
    occurrences: [],
};

export const eventResponseTransform = (event: ResponseEvent) => ({
    address: {
        city: event?.venue?.address?.city,
        country: event?.venue?.address?.country,
        latLng: {
            lat: +event?.venue?.latitude,
            lng: +event?.venue?.longitude,
        },
        postalCode: event?.venue?.address?.postal_code,
        state: event?.venue?.address?.region,
        street: event?.venue?.address?.address_1,
    },
    id: event.id,
    title: event.title,
    description: event.description || '',
    location: event?.venue?.multi_line_address_display,
    dateStart: event.start_date,
    dateEnd: event.end_date,
    allDayEvent: event.all_day_event,
    repeatingDays: event.repeating_days,
    occurrences: event.occurrences,
    dateTBD: event.date_tbd,
    isOnline: event.is_online,
    isPrivate: event.is_private,
    type: event.type,
    currency: event.currency,
    accountId: event.account_id,
    repeat: event.is_repeating_event,
    extraDetails: event.extra_details,
    isSeriesParent: event.is_series_parent,
    seriesId: event.series_id,
    repeatOn: event.repeat_on,
    timezone: event.timezone,
    image_url: event.image_url,
    url: event.url,
    ticket_url: event.ticket_url,
    status: event.status,
    questions: event.questions,
    useCheckoutForm: event.use_checkout_form,
    occurrenceUniqueZoomLink: event.unique_recurring_zoom_link,
    secondaryImageUrls: event.secondary_image_urls,
    promotionalImageUrls: event.promotional_image_urls,
    descriptionThumbnailUrls: event.description_thumbnail_urls,
    venueEmail: event.venue_email,
    venueName: event.venue_name,
    venuePhone: event.venue_phone,
    venueWebsite: event.venue_website,
    additionalProperties: event.additional_properties,
    eventSetup: event.event_setup,
    confirmationMessage: event.confirmation_message,
    tags: event.tags,
    includeEventDataUrl: event.include_event_data_url,
    externalUrlLinkToHostedPage: event.external_url_link_to_hosted_page,
    multiSessionEvent: event.multi_session_event,
    unlimitedCapacity: event.unlimited_capacity,
    eventCapacity: event.event_capacity,
    hasAttendees: event.has_attendees,
    eventType: event.event_type,
    category: event.category,
    subcategory: event.subcategory,
    salesTax: event.sales_tax,
    isMultiDay: event.is_multi_day,
    isTimeBased: event.is_time_based,
    overrideParentTickets: event.override_parent_tickets,
    createdAt: event.created_at,
    shopify: event.shopify,
    paypalEnableCreditCardFields: event.paypal_enable_credit_card_fields,
    eventPageUrl: event.event_page_url,
    eventLandingPageUrl: event.landing_page_url,
    privacyHideEvent: event.privacy_hide_event,
    privacyAccessCodeEnabled: event.privacy_access_code_enabled,
    privacyAccessCode: event.privacy_access_code,
    enableWaitlist: event.enable_waitlist,
    waitlistAutoResponseMessage: event.waitlist_auto_response_message,
    waitlistTicketReleaseMessage: event.waitlist_ticket_release_message,
    waitlistConfirmationMessage: event.waitlist_confirmation_message,
    waitlistEnableCommunication: event.waitlist_enable_communication,
    waitlistTriggerEventCapacity: event.waitlist_trigger_event_capacity,
    waitlistTriggerTicketsSoldOut: event.waitlist_trigger_tickets_sold_out,
    waitlistCallToAction: event.waitlist_call_to_action,
    waitlistDescription: event.waitlist_description,
    requiresApproval: event.requires_approval,
});

export const fetchEventsApi = createAsyncThunk(
    'events/fetch',
    async (includes: string, { getState }) => {
        return API_INSTANCE.get<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/dashboard/events`, `&includes=${includes || ''}`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const updateEventApi = createAsyncThunk('events/update', (event: Event, { getState }) =>
    API_INSTANCE.put(buildURLWithState(getState(), `/api/v1/dashboard/events/${event.id}`), event)
        .then((response) => {
            return response.data;
        })
        .catch((e) => ({ error: e.response.data }))
);

export const updatePreapprovedEventApi = createAsyncThunk(
    'events/preapproved-update',
    (event: Event, { getState }) =>
        API_INSTANCE.put(
            buildURLWithState(
                getState(),
                `/api/v1/dashboard/events/public/${event.id}/preapprove-event`
            ),
            event
        )
            .then((response) => {
                return response.data;
            })
            .catch((e) => ({ error: e.response.data }))
);
export const updateEventOccurrenceApi = createAsyncThunk(
    'events/update-occurrence',
    (event: Event, { getState }) =>
        API_INSTANCE.put(
            buildURLWithState(getState(), `/api/v1/dashboard/events/${event.id}/occurrences`),
            event
        )
            .then((response) => response.data)
            .catch((error) => error)
);
export const fetchEventOccurrencesApi = createAsyncThunk(
    'events/fetch-occurrences',
    (event: Event, { getState }) =>
        API_INSTANCE.get(
            buildURLWithState(getState(), `/api/v1/dashboard/events/${event.id}/occurrences`),
            event
        )
            .then((response) => response.data)
            .catch((error) => error)
);
export const fetchEventLeafOccurrencesApi = createAsyncThunk(
    'events/fetch-leaf-occurrences',
    (event: Event, { getState }) =>
        API_INSTANCE.get(
            buildURLWithState(getState(), `/api/v1/dashboard/events/${event.id}/leaf-occurrences`),
            event
        )
            .then((response) => response.data)
            .catch((error) => error)
);
export const updateEventWaitlist = createAsyncThunk(
    'events/update-waitlist',
    (data: any, { getState }) =>
        API_INSTANCE.put(
            buildURLWithState(getState(), `/api/v1/dashboard/events/${data.event_id}/waitlist`),
            data
        )
            .then((response) => response.data)
            .catch((error) => error)
);
export const notifyWaitlistSubscribers = createAsyncThunk(
    'events/update-waitlist',
    (data: any, { getState }) =>
        API_INSTANCE.put(
            buildURLWithState(
                getState(),
                `/api/v1/dashboard/events/${data.event_id}/waitlist/notify`
            ),
            data
        )
            .then((response) => response.data)
            .catch((error) => error)
);

export const createEventOccurrenceApi = createAsyncThunk(
    'events/create-occurrence',
    (event: Event, { getState }) =>
        API_INSTANCE.post(
            buildURLWithState(getState(), `/api/v1/dashboard/events/${event.id}/occurrences`),
            event
        )
            .then((response) => response.data)
            .catch((error) => error)
);
export const createDateWithTimesEventOccurrenceApi = createAsyncThunk(
    'events/create-occurrence',
    (event: Event, { getState }) =>
        API_INSTANCE.post(
            buildURLWithState(
                getState(),
                `/api/v1/dashboard/events/${event.id}/time-based-occurrences`
            ),
            event
        )
            .then((response) => response.data)
            .catch((error) => error)
);
export const updateDateWithTimesEventOccurrenceApi = createAsyncThunk(
    'events/update-time-based-occurrence',
    (event: Event, { getState }) =>
        API_INSTANCE.put(
            buildURLWithState(
                getState(),
                `/api/v1/dashboard/events/${event.id}/time-based-occurrences`
            ),
            event
        )
            .then((response) => response.data)
            .catch((error) => error)
);

export const deleteDateWithTimesEventOccurrenceApi = createAsyncThunk(
    'events/delete-time-based-occurrence',
    (event: Event, { getState }) =>
        API_INSTANCE.delete(
            buildURLWithState(
                getState(),
                `/api/v1/dashboard/events/${event.id}/time-based-occurrences`
            ),
            event
        )
            .then((response) => response.data)
            .catch((error) => error)
);

export const getEventTicketsApi = createAsyncThunk(
    'events/tickets',
    (event_id: string, { getState }) =>
        API_INSTANCE.get(
            buildURLWithState(getState(), `/api/v1/dashboard/tickets/events/${event_id}`)
        ).then((response) => response.data)
);
export const getEventTicketsWithoutMetricsApi = createAsyncThunk(
    'events/tickets-without-metrics',
    (event_id: string, { getState }) =>
        API_INSTANCE.get(
            buildURLWithState(
                getState(),
                `/api/v1/dashboard/tickets/events/${event_id}`,
                `&ignore_real_time_metric=true`
            )
        ).then((response) => response.data)
);

export const createEventTicketsApi = createAsyncThunk(
    'events/create-ticket',
    (ticket: Ticket, { getState }) =>
        API_INSTANCE.post(
            buildURLWithState(getState(), `/api/v1/dashboard/tickets/events/${ticket.event_id}`),
            ticket
        )
            .then((response) => response.data)
            .catch((error) => error)
);
export const deleteEventApi = createAsyncThunk(
    'events/delete-event',
    (eventId: any, { getState }) =>
        API_INSTANCE.delete(buildURLWithState(getState(), `/api/v1/dashboard/events/${eventId}`))
            .then((response) => response.data)
            .catch((error) => error)
);
export const updateEventTicketsApi = createAsyncThunk(
    'events/update-ticket',
    (ticket: Ticket, { getState }) =>
        API_INSTANCE.put(
            buildURLWithState(getState(), `/api/v1/dashboard/tickets/${ticket.id}`),
            ticket
        )
            .then((response) => response.data)
            .catch((error) => error)
);
export const updateEventTicketsOrderApi = createAsyncThunk(
    'events/update-ticket-order',
    (ticket: Ticket, { getState }) =>
        API_INSTANCE.put(
            buildURLWithState(getState(), `/api/v1/dashboard/tickets/bulk/order`),
            ticket
        )
            .then((response) => response.data)
            .catch((error) => error)
);

export const deleteEventTicketsApi = createAsyncThunk(
    'events/delete-ticket',
    (id: string, { getState }) =>
        API_INSTANCE.delete(buildURLWithState(getState(), `/api/v1/dashboard/tickets/${id}`))
            .then((response) => response.data)
            .catch((error) => error)
);

export const getEventApi = createAsyncThunk('events/get', (eventId: string, { getState }) =>
    API_INSTANCE.get(buildURLWithState(getState(), `/api/v1/dashboard/events/${eventId}`))
        .then((response) => response.data)
        .catch((error) => error)
);
export const copyEventApi = createAsyncThunk('events/copy', (data: any, { getState }) =>
    API_INSTANCE.post(
        buildURLWithState(getState(), `/api/v1/dashboard/events/${data.id}/copy`),
        data.checkboxSelections
    )
        .then((response) => response.data)
        .catch((error) => error)
);

export const createEventApi = createAsyncThunk('events/create', (event: Event, { getState }) =>
    API_INSTANCE.post(buildURLWithState(getState(), `/api/v1/dashboard/events`), event).then(
        (response) => response.data
    )
);

export const createPreapprovedEventAPI = createAsyncThunk(
    'events/preapproved-create',
    (event: Event, { getState }) =>
        API_INSTANCE.post(
            buildURLWithState(getState(), `/api/v1/dashboard/events/public/preapprove-event`),
            event
        ).then((response) => response.data)
);

export const createAIEventApi = createAsyncThunk('aievents/create', (event: Event, { getState }) =>
    API_INSTANCE.post(
        buildURLWithState(getState(), `/api/v1/dashboard//openai/events`),
        event
    ).then((response) => response.data)
);

export const generateAIText = createAsyncThunk('ai/generate', (data: any, { getState }) =>
    API_INSTANCE.post(
        buildURLWithState(getState(), `/api/v1/dashboard/openai/generate`),
        data
    ).then((response) => response.data)
);

export const fetchEventPromos = createAsyncThunk('promos/fetch', async (eventId, { getState }) => {
    return API_INSTANCE.get<ResponseEvent>(
        buildURLWithState(getState(), `/api/v1/dashboard/event-promos/events/${eventId}`)
    )
        .then((response) => response.data)
        .catch((error) => error);
});
export const createEventPromos = createAsyncThunk('promos/create', async (promo, { getState }) => {
    return API_INSTANCE.post<ResponseEvent>(
        buildURLWithState(getState(), `/api/v1/dashboard/event-promos/events/${promo.event_id}`),
        promo
    )
        .then((response) => response.data)
        .catch((error) => error);
});

export const updateEventPromos = createAsyncThunk(
    'promos/update',
    async (promo: string, { getState }) => {
        return API_INSTANCE.put<ResponseEvent>(
            buildURLWithState(
                getState(),
                `/api/v1/dashboard/event-promos/${promo.id}/events/${promo.event_id}`
            ),
            promo
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const deleteEventPromoApi = createAsyncThunk(
    'promos/delete',
    async (promo: string, { getState }) => {
        return API_INSTANCE.delete<ResponseEvent>(
            buildURLWithState(
                getState(),
                `/api/v1/dashboard/event-promos/${promo.id}/events/${promo.event_id}`
            ),
            promo
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const getEventAttendeeStatsApi = createAsyncThunk(
    'event-attendee/stats',
    async (event: any, { getState }) => {
        return API_INSTANCE.get<ResponseEvent>(
            buildURLWithState(
                getState(),
                `/api/v1/dashboard/attendee-event-rollup/events/${event.id}`
            )
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const fetchEventAttendeesFilterValues = createAsyncThunk(
    'event-attendees/filter-values',
    async ({ eventId, criteria }, { getState }) => {
        const queryParams = new URLSearchParams(criteria).toString();

        return API_INSTANCE.get<ResponseEvent>(
            buildURLWithState(
                getState(),
                `/api/v1/dashboard/events/${eventId}/filter-values`,
                `&${queryParams}`
            )
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        events_attempt: (state: EventSliceState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload || false;
        },
        events_success: (state, action: PayloadAction<Event[]>) => {
            state.data = action.payload;
            state.isLoading = false;
        },
        events_error: (state, action: PayloadAction<Error>) => {
            state.error = action.payload;
            state.isLoading = false;
        },

        submit_event_attempt: (state) => {
            state.isLoading = true;
        },
        submit_event_success: (state, action: PayloadAction<Event>) => {
            state.data = state.data.concat(action.payload);
            state.isLoading = false;
            state.currentEvent = undefined;
        },
        submit_event_error: (state, action: PayloadAction<Error>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        update_event_attempt: (state) => {
            state.isLoading = true;
        },
        update_event_success: (state, action: PayloadAction<Event>) => {
            state.data = state.data.map((item) =>
                item.id === action.payload.id ? action.payload : item
            );
            state.isLoading = false;
            state.currentEvent = undefined;
        },
        update_event_error: (state, action: PayloadAction<Error>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        single_event_success: (state, action: PayloadAction<Event>) => {
            state.currentEvent = action.payload;
        },
        handle_change_search: (state, action: PayloadAction<string>) => {
            state.searchText = action.payload;
            if (window && window.history.pushState) {
                const newurl =
                    window.location.protocol +
                    '//' +
                    window.location.host +
                    window.location.pathname +
                    '?search=' +
                    action.payload;
                window.history.pushState({ path: newurl }, '', newurl);
            }
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getEventApi.pending, (state, action) => {
            state.isLoading = true;
            state.currentEvent = null;
        });
        builder.addCase(getEventApi.fulfilled, (state, action) => {
            state.isLoading = false;
            state.currentEvent = eventResponseTransform(action.payload);
        });
        builder.addCase(copyEventApi.fulfilled, (state, action) => {
            state.isLoading = false;
            state.currentEvent = eventResponseTransform(action.payload);
        });

        builder.addCase(updateEventApi.fulfilled, (state, action) => {
            state.currentEvent = eventResponseTransform(action.payload);
        });
        builder.addCase(updatePreapprovedEventApi.fulfilled, (state, action) => {
            state.currentEvent = eventResponseTransform(action.payload);
        });

        builder.addCase(fetchEventPromos.fulfilled, (state, action) => {
            state.promos = action.payload;
        });
        builder.addCase(createEventPromos.fulfilled, (state, action) => {
            state.promos = state.promos.concat(action.payload);
        });
        builder.addCase(deleteEventPromoApi.fulfilled, (state, action) => {
            state.promos = state.promos.filter((data) => action.meta.arg.id !== data.id);
        });
        builder.addCase(updateEventPromos.fulfilled, (state, action) => {
            state.promos = state.promos.map((promo) =>
                action.meta.arg.id === promo.id ? { ...promo, ...action.meta.arg } : promo
            );
        });
        builder.addCase(updateEventOccurrenceApi.fulfilled, (state, action) => {
            const occurrences = state.currentEvent.occurrences;
            const updateOccurrences = occurrences.map((occurrence: any) =>
                action.meta.arg.id === occurrence.id ? action.payload : occurrence
            );
            state.currentEvent = { ...state.currentEvent, occurrences: updateOccurrences };
        });
        builder.addCase(fetchEventOccurrencesApi.fulfilled, (state, action) => {
            state.currentEvent = { ...state.currentEvent, occurrences: action.payload };
        });
        builder.addCase(fetchEventLeafOccurrencesApi.fulfilled, (state, action) => {
            state.occurrences = action.payload;
        });

        builder.addCase(createEventOccurrenceApi.fulfilled, (state, action) => {
            const occurrences = state.currentEvent.occurrences || [];
            state.currentEvent = {
                ...state.currentEvent,
                occurrences: occurrences.concat(action.payload),
            };
        });

        builder.addCase(createEventApi.fulfilled, (state, action) => {
            state.data = state.data.concat(action.payload);
            state.currentEvent = action.payload;
        });
        builder.addCase(createPreapprovedEventAPI.fulfilled, (state, action) => {
            state.currentEvent = action.payload;
        });
        builder.addCase(createEventApi.rejected, (state, action) => {
            state.data = { error: action.error };
        });

        builder.addCase(fetchEventsApi.pending, (state) => {
            state.data = [];
            state.isLoading = true;
        });

        builder.addCase(fetchEventsApi.fulfilled, (state, action) => {
            state.data = action.payload.map((event: ResponseEvent) => {
                return eventResponseTransform(event);
            });
            state.isLoading = false;
        });

        builder.addCase(getEventTicketsApi.pending, (state, action) => {
            state.isTicketingLoading = true;
        });
        builder.addCase(getEventTicketsApi.fulfilled, (state, action) => {
            state.tickets = action.payload;
            state.isTicketingLoading = false;
        });
        builder.addCase(getEventTicketsWithoutMetricsApi.pending, (state, action) => {
            state.isTicketingLoading = true;
        });
        builder.addCase(getEventTicketsWithoutMetricsApi.fulfilled, (state, action) => {
            state.tickets = action.payload;
            state.isTicketingLoading = false;
        });
        builder.addCase(getEventTicketsApi.rejected, (state, action) => {
            state.tickets = [];
        });

        builder.addCase(createEventTicketsApi.fulfilled, (state, action) => {
            state.tickets = state.tickets.concat(action.payload);
            state.isLoading = false;
        });

        builder.addCase(getEventAttendeeStatsApi.fulfilled, (state, action) => {
            state.eventAttendeeStats = action.payload;
        });
        builder.addCase(fetchEventAttendeesFilterValues.pending, (state, action) => {
            state.eventAttendeesFilterValues = {};
        });
        builder.addCase(fetchEventAttendeesFilterValues.fulfilled, (state, action) => {
            state.eventAttendeesFilterValues = action.payload;
        });

        builder.addCase(updateEventTicketsApi.fulfilled, (state, action) => {
            state.tickets = state.tickets.map((ticket) =>
                action.payload.id === ticket.id ? action.payload : ticket
            );
        });
        builder.addCase(updateEventTicketsOrderApi.pending, (state, action) => {
            const newOrder = action.meta.arg;
            state.tickets = newOrder.map((ticket) => {
                const ticketData = state.tickets.find(({ id }) => id === ticket.id);
                return { ...ticketData, order: ticket.order };
            });
        });

        builder.addCase(deleteEventTicketsApi.fulfilled, (state, action) => {
            state.tickets = state.tickets.filter((ticket) => action?.meta?.arg !== ticket.id);
        });
    },
});

export const {
    events_attempt,
    events_success,
    events_error,
    handle_change_search,
    single_event_success,
    submit_event_success,
    update_event_success,
} = eventsSlice.actions;

export const getSingleEvent =
    (id: string | number): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch, getState) => {
        const state = getState();
        const events = state.events.data;
        const currentEvent = events.find((item) => item.id == id);
        if (!currentEvent) return null;
        dispatch(single_event_success(currentEvent));
    };

export const getEvents =
    (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch, getState) => {
        const state = getState();
        const events = state.events.data;
        dispatch(events_success(events));
    };

export const submitEvent =
    (data: Event): ThunkAction<void, RootState, unknown, AnyAction> =>
    (dispatch) => {
        dispatch(submit_event_success(data));
    };

export const updateEvent =
    (data: Event): ThunkAction<void, RootState, unknown, AnyAction> =>
    (dispatch) => {
        dispatch(update_event_success(data));
    };

export const selectEventsList = (state: RootState) => state.events.data;
export const selectPromoList = (state: RootState) => state.events.promos;
export const selectTicketsList = (state: RootState) => state.events.tickets;
export const selectSingleEvent = (state: RootState) => state?.events?.currentEvent;
export const selectSearchText = (state: RootState) => state?.events?.searchText;
export const selectEventsIsLoading = (state: RootState) => state?.events?.isLoading;
export const selectTicketsIsLoading = (state: RootState) => state?.events?.isTicketingLoading;
export const selectEventAttendeeStats = (state: RootState) => state?.events?.eventAttendeeStats;
export const selectEventOccurrences = (state: RootState) => state?.events?.occurrences;

export default eventsSlice;
