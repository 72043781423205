import { Box, ToggleSwitch } from 'wix-style-react';
import { SettingsHeader } from '../../../components/settings-header';
import { WidgetSettingsUpdater } from '../../../components/widget-settings-updater';

export const UserSearchFilters = () => {
    return (
        <Box direction="vertical">
            <SettingsHeader
                title="User Search Filters"
                info="Enable users to quickly find specific events with efficient search filters."
            />

            <hr className="divider-long" />

            <WidgetSettingsUpdater updateKey="showUserSearch" isPremium title="Show Event Search">
                <ToggleSwitch />
            </WidgetSettingsUpdater>
            <WidgetSettingsUpdater
                updateKey="showVenueSearchFilter"
                isPremium
                title="Show Venue Filter"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
            <WidgetSettingsUpdater
                updateKey="showVenueCitySearchFilter"
                isPremium
                title="Show City Filter"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
            <WidgetSettingsUpdater
                updateKey="showVenueRegionSearchFilter"
                isPremium
                title="Show Region Filter"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
            <WidgetSettingsUpdater
                updateKey="showCountrySearchFilter"
                isPremium
                title="Show Country Filter"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="showLanguageSearchFilter"
                isPremium
                title="Show Language Filter"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
            <WidgetSettingsUpdater
                updateKey="showMonthSearchFilter"
                isPremium
                title="Show Month Filter"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
            <WidgetSettingsUpdater
                updateKey="showWeekSearchFilter"
                isPremium
                title="Show Week Filter"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
            <WidgetSettingsUpdater
                updateKey="showCategorySearchFilter"
                isPremium
                title="Show Category Filter"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
        </Box>
    );
};
