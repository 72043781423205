import GoogleMapReact from 'google-map-react';

import Marker from '../../../assets/marker.png';

export function GoogleMap({ latitude, longitude }) {
    const center = { lat: latitude || 32.7486608, lng: longitude || -117.2561483 };
    return (
        <GoogleMapReact
            bootstrapURLKeys={{
                key: 'AIzaSyDNGGckSzq7aDmMCQaddC1R40VsBMLCFtA',
            }}
            defaultCenter={center}
            defaultZoom={11}
        >
            <img src={Marker} alt="event location" width="30" />
        </GoogleMapReact>
    );
}
