import { SettingsHeader } from '../../../components/settings-header';
import { TextInput } from './text-input';

export const CheckoutPaymentInfoText = ({ label }) => {
    return (
        <>
            <SettingsHeader title={label} />

            <TextInput title="Card Number Text" updateKey="cardNumberText" />
            <TextInput title="Card Expiry Date Text" updateKey="cardExpiryText" />
            <TextInput title="Card Security Code Text" updateKey="cardSecurityCodeText" />
        </>
    );
};
