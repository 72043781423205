import { useState, useEffect, useCallback } from 'react';
import screen from 'screen-size';
import * as Icons from 'wix-ui-icons-common';

import {
    Button,
    SectionHelper,
    InfoIcon,
    Box,
    Layout,
    Cell,
    Heading,
    FormField,
    Dropdown,
    ToggleSwitch,
    Divider,
} from 'wix-style-react';
import { API_INSTANCE, buildURL, fullBuildURL } from '../../../features/utils';
import { useSelector } from 'react-redux';
import SaveSuccessCheck from '../../../assets/save-success-check.gif';
import { selectSiteData } from '../../../features/account-slice';
import { selectSite } from '../../../features/site-slice';
import { fetchSite } from '../../../features/site-slice';
import { useAppDispatch } from '../../../hooks';
import { BsPaypal } from 'react-icons/bs';
import { SiSquare, SiStripe } from 'react-icons/si';
import { classes } from './connect-payment.st.css';
import { capitalize } from 'lodash';
import { Controller } from 'react-hook-form';

const SquareInfo = ({ account, deletePaymentConnector }) => {
    const [locations, setLocations] = useState<any>([]);
    const [locationLoading, setLocationLoading] = useState<any>(false);
    const [selectedLocation, setSelectedLocation] = useState<any>(account.location_id);
    const [savingLocation, setSavingLocation] = useState<any>(false);
    const [showSuccess, setShowSuccess] = useState<any>(false);
    const [locationsError, setLocationsError] = useState<any>(false);

    const site = useSelector(selectSiteData);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const squarePaymentLocationsUrl = `/api/v1/dashboard/payments/square/locations`;
                setLocationLoading(true);
                const { data } = await API_INSTANCE.get(buildURL(site, squarePaymentLocationsUrl));
                setSelectedLocation(account.location_id);
                setLocations(data);
                setLocationLoading(false);
            } catch (e) {
                setLocationsError(true);
                setLocationLoading(false);
            }
        };
        void fetchData();
    }, [site]);

    const handleDropdown = useCallback(
        async (locationId) => {
            setSelectedLocation(locationId);
            setSavingLocation(true);
            const squarePaymentLocationsUrl = `/api/v1/dashboard/payments/square/locations`;
            await API_INSTANCE.put(buildURL(site, squarePaymentLocationsUrl), {
                location_id: locationId,
            });
            setSavingLocation(false);
            setShowSuccess(true);
            setTimeout(() => {
                setShowSuccess(false);
            }, 4000);
        },
        [selectedLocation, site]
    );

    const options = locations.map(({ id, name }) => ({ id, value: name }));

    return (
        <Box direction="vertical" gap={2}>
            <SectionHelper appearance="success">
                Square account connected with merchant ID {account?.merchant_id} and country{' '}
                {account?.country} Square Fees
                <InfoIcon
                    size="small"
                    content={
                        <>
                            All Square fees are deducted before funds are transferred into your
                            linked bank account.
                            <p>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="//squareup.com/help/article/5068"
                                >
                                    Learn more about Square fees
                                </a>
                            </p>
                        </>
                    }
                />
            </SectionHelper>
            <Box marginTop={2} marginBottom={4}>
                <Button onClick={deletePaymentConnector}>Disconnect</Button>
            </Box>

            <Box gap={2}>
                <FormField label="Select Location">
                    <Dropdown
                        status={
                            locationsError
                                ? 'error'
                                : locationLoading || savingLocation
                                ? `loading`
                                : ''
                        }
                        border="standard"
                        placeholder={locationsError ? 'Unable to Load Square Locations' : ''}
                        options={options}
                        selectedId={selectedLocation}
                        onSelect={(option) => handleDropdown(option.id)}
                    />
                </FormField>
                {showSuccess && (
                    <Box align="center" verticalAlign="middle">
                        <img src={SaveSuccessCheck} width={40} />
                    </Box>
                )}
            </Box>
        </Box>
    );
};
const PayPalInfo = ({ account, deletePaymentConnector, control, setValue }) => {
    return (
        <Box direction="vertical" gap={2}>
            <SectionHelper appearance="success">
                <b>PayPal</b> account connected with email {account?.email}
                <p>
                    To process payments with your live PayPal account and become eligible, please
                    complete the{' '}
                    <a
                        href="https://www.paypal.com/bizsignup/entry/product/ppcp"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <b>Account Setup</b>
                    </a>
                    .
                </p>
                <InfoIcon
                    size="small"
                    content={
                        <>
                            All Paypal fees are deducted before funds are transferred into your
                            linked bank account.
                            <p>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.paypal.com/us/webapps/mpp/merchant-fees"
                                >
                                    Learn more about PayPal fees
                                </a>
                            </p>
                        </>
                    }
                />
            </SectionHelper>

            <Box direction="vertical" align="left">
                <FormField label="Display Credit Card Fields" labelPlacement="top">
                    <Controller
                        control={control}
                        name="paypalEnableCreditCardFields"
                        render={({ field }) => (
                            <ToggleSwitch
                                checked={field.value}
                                onChange={(e) =>
                                    setValue('paypalEnableCreditCardFields', e.target.checked)
                                }
                            />
                        )}
                    ></Controller>
                </FormField>
            </Box>
            <Divider />
            <Box marginTop={2} align="center">
                <Button prefixIcon={<Icons.Unlink />} onClick={deletePaymentConnector}>
                    Disconnect
                </Button>
            </Box>
        </Box>
    );
};
const StripeInfo = ({ account, setOpenPayment, deletePaymentConnector }) => {
    const site = useSelector(selectSiteData);

    const { requirements } = account.connected_account;

    const verificationDues =
        requirements?.currently_due.length !== 0 || requirements?.pending_verification.length !== 0;

    if (verificationDues) {
        return (
            <>
                <SectionHelper appearance="danger">
                    Stripe is missing some details in order to finish setting up your account
                </SectionHelper>
                <Box marginTop={2} gap={2}>
                    <Button
                        onClick={() => {
                            setOpenPayment(true);
                            const url = `/auth/stripe`;
                            launchPopup(fullBuildURL(site, url));
                        }}
                    >
                        Update Stripe Details
                    </Button>
                    <Button onClick={deletePaymentConnector}>Disconnect</Button>
                </Box>
            </>
        );
    }
    return (
        <SectionHelper appearance="success">
            Stripe account connected with email {account?.email} Stripe Fees
            <InfoIcon
                size="small"
                content={
                    <>
                        All Stripe fees are deducted before funds are transferred into your linked
                        bank account.
                        <p>
                            <a target="_blank" href="https://stripe.com/pricing">
                                Learn more about Stripe fees
                            </a>
                        </p>
                    </>
                }
            />
        </SectionHelper>
    );
};

export const Accounts = ({
    account,
    setOpenPayment,
    deletePaymentConnector,
    control,
    setValue,
}) => {
    return (
        <>
            {account.type === 'square' && (
                <SquareInfo account={account} deletePaymentConnector={deletePaymentConnector} />
            )}
            {account.type === 'stripe' && (
                <StripeInfo
                    account={account}
                    setOpenPayment={setOpenPayment}
                    deletePaymentConnector={deletePaymentConnector}
                />
            )}
            {account.type === 'paypal' && (
                <PayPalInfo
                    account={account}
                    setOpenPayment={setOpenPayment}
                    deletePaymentConnector={deletePaymentConnector}
                    control={control}
                    setValue={setValue}
                />
            )}
        </>
    );
};
const launchPopup = (url, cb) => {
    let params = `width=${screen().x * 0.5}`;
    params += `, height=${screen().y * 0.8}`;
    params += ', top=0, left=0';
    params += ', fullscreen=yes';
    params += ', scrollbars=1';

    const newWin = window.open(url, 'Connect Square', params);
    if (window.focus) {
        newWin.focus();
    }

    return false;
};
export const ConnectPaymentAccount = ({ setPaymentDetails, paymentDetails, control, setValue }) => {
    const [openPayment, setOpenPayment] = useState(false);
    const site = useSelector(selectSiteData);
    const dispatch = useAppDispatch();
    const [startPoll, setStartPoll] = useState(false);

    const currentSite = useSelector(selectSite);

    useEffect(() => {
        const fetchData = async () => {
            if (site?.site) {
                await dispatch(fetchSite(site.site));
            }
        };
        void fetchData();
    }, [dispatch, site.site]);

    const deletePaymentConnector = useCallback(async () => {
        setStartPoll(false);
        const url = `/api/v1/dashboard/payments/${paymentDetails.type}/${paymentDetails.id}`;
        await API_INSTANCE.delete(buildURL(site, url));
        setPaymentDetails(null);
    }, [paymentDetails, paymentDetails, setPaymentDetails, site]);

    const fetchPaymenthData = async () => {
        if (!paymentDetails) {
            const url = `/api/v1/dashboard/payments`;
            const { data } = await API_INSTANCE.get(buildURL(site, url));
            setPaymentDetails(data);
        } else {
            setStartPoll(false);
        }
    };

    useEffect(() => {
        const startPolling = async () => {
            await fetchPaymenthData();

            const pollInterval = setInterval(async () => {
                if (!startPoll) return;
                await fetchPaymenthData();
            }, 2000);

            return pollInterval;
        };

        const pollInterval = startPolling();

        return () => {
            clearInterval(pollInterval);
        };
    }, [paymentDetails, openPayment, startPoll]);

    return (
        <Box direction="vertical">
            <Box direction="vertical">
                <Heading appearance="H4">Setup Payment</Heading>
                <Heading appearance="H5">
                    Add your payment account so you can receive payouts for your orders
                </Heading>
            </Box>
            <Box marginTop={3} marginBottom={5} direction="vertical">
                {(currentSite?.type === 'shopify' || currentSite?.type === 'ecwid') && (
                    <Box marginBottom={2}>
                        <SectionHelper appearance="success">
                            Publish events as products to your {capitalize(currentSite?.type)}{' '}
                            store, enabling users to purchase tickets through the{' '}
                            {capitalize(currentSite?.type)} checkout and connecting payments to your{' '}
                            {capitalize(currentSite?.type)} account. Alternatively, you can connect
                            Stripe, Square or PayPal to use the Ticket Spot widget for ticket sales.
                        </SectionHelper>
                    </Box>
                )}
                {paymentDetails ? (
                    <Box direction="vertical">
                        <Accounts
                            account={paymentDetails}
                            setOpenPayment={setOpenPayment}
                            deletePaymentConnector={deletePaymentConnector}
                            control={control}
                            setValue={setValue}
                        />
                        <Box marginTop={2} />
                        {paymentDetails?.connected_account?.charges_enabled &&
                            paymentDetails?.connected_account?.payouts_enabled && (
                                <Button onClick={deletePaymentConnector}>Disconnect</Button>
                            )}
                    </Box>
                ) : (
                    <Box direction="vertical" gap={2}>
                        <Button
                            className={classes.square}
                            prefixIcon={<SiSquare />}
                            onClick={() => {
                                setOpenPayment(true);
                                setStartPoll(true);
                                const url = `/auth/square`;
                                launchPopup(fullBuildURL(site, url), () => {
                                    setOpenPayment(false);
                                });
                            }}
                        >
                            Connect Square
                        </Button>

                        <Button
                            className={classes.stripe}
                            prefixIcon={<SiStripe />}
                            onClick={() => {
                                setOpenPayment(true);
                                setStartPoll(true);
                                const url = `/auth/stripe`;
                                launchPopup(fullBuildURL(site, url), () => {
                                    setOpenPayment(false);
                                });
                            }}
                        >
                            Connect Stripe
                        </Button>

                        <Button
                            prefixIcon={<BsPaypal />}
                            className={classes.paypal}
                            onClick={() => {
                                setOpenPayment(true);
                                setStartPoll(true);
                                const url = `/auth/paypal`;
                                launchPopup(fullBuildURL(site, url), () => {
                                    setOpenPayment(false);
                                });
                            }}
                        >
                            Connect PayPal
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
