import { Box, CopyClipboard, Input, InputArea, TextButton } from 'wix-style-react';
import { classes } from './copy.st.css';

import * as Icons from 'wix-ui-icons-common';

export const Copy = ({ text, isTextArea }) => {
    const InputComponent = isTextArea ? InputArea : Input;
    return (
        <CopyClipboard value={text} resetTimeout={1500} className={classes.copy}>
            {({ isCopied, copyToClipboard }) => (
                <InputComponent
                    readOnly
                    rows={4}
                    value={text}
                    suffix={
                        <Box verticalAlign="middle" marginRight="SP1">
                            <TextButton
                                onClick={() => copyToClipboard()}
                                size="small"
                                prefixIcon={<Icons.DuplicateSmall />}
                            >
                                {!isCopied ? 'Copy' : 'Copied!'}
                            </TextButton>
                        </Box>
                    }
                />
            )}
        </CopyClipboard>
    );
};
