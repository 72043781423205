import { truncate } from 'lodash';
import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from 'react';
import fileDownload from 'js-file-download';

import { useSelector } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';

import {
    Page,
    Table,
    TableToolbar,
    Box,
    Layout,
    Cell,
    Button,
    Dropdown,
    Loader,
    InfoIcon,
    IconButton,
    Tooltip,
    Image,
    EmptyState,
    Badge,
} from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';

import {
    deleteAttendeeApi,
    fetchAttendeesByEventApiPagination,
    selectAttendeeStats,
    selectAttendeesIsLoading,
    selectAttendeesList,
} from '../../features/attendees-slice';
import { getEventApi, selectEventsIsLoading, selectSingleEvent } from '../../features/events-slice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import type { Attendee } from '../../types';
import { selectSiteData } from '../../features/account-slice';
import { API_INSTANCE, buildURL } from '../../features/utils';
import { AttendeeQRCodeCheckin } from './attendee-qrcode';
import { classes } from './attendee-list.st.css';
import { DeleteConfirmation } from '../../components/delete-confirmation';
import { TicketValid } from './ticket-validility';
import TicketValue from './ticket-value';
import NoAttendees from '../../assets/no-attendees.gif';
import { EventAttendeeStatsWidget } from './event-attendee-stats-widget';
import { AttendeeListFilters } from './attendee-list-filters';
import { AddAttendeeModal } from './add-attendee-modal';
import { TicketConfirmationEmailInformation } from './ticket-confirmation-email-information';

export const ATTENDEE_STATUS_OPTIONS = [
    { id: 'attending', value: 'Attending' },
    { id: 'checkedIn', value: 'Checked In' },
    { id: 'notAttending', value: 'Not Attending' },
    // { id: 'incomplete', value: 'Incomplete' },
    // { id: 'canceled', value: 'Canceled' },
    // { id: 'refunded', value: 'Refunded' },
    // { id: 'partiallyRefunded', value: 'Partially Refunded' },
];

export const DeleteButton = ({ ids }) => {
    const [loading, setLoading] = useState(false);
    const site = useAppSelector(selectSiteData);
    const dispatch = useAppDispatch();

    const deleteAttendees = useCallback(async () => {
        setLoading(true);
        try {
            await API_INSTANCE.put(buildURL(site, `/api/v1/dashboard/attendees`), { ids });
            // dispatch(fetchAttendeesByEventApi(id));
        } catch (e) {
            setLoading(false);
        }
        setLoading(false);
    }, [ids, site, dispatch]);

    return (
        <Button
            skin="light"
            disable={loading}
            prefixIcon={<Icons.Upload />}
            onClick={() => deleteAttendees()}
        >
            {loading ? 'Deleting' : 'Delete'}
        </Button>
    );
};
export const AttendeeStatus = ({ attendee }) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState<string>(attendee.status || 'attending');
    const site = useAppSelector(selectSiteData);
    const dispatch = useAppDispatch();

    const updateAttendee = useCallback(
        async (newStatus) => {
            setLoading(true);
            try {
                setStatus(newStatus);
                await API_INSTANCE.put(
                    buildURL(site, `/api/v1/dashboard/attendees/${attendee.id}`),
                    { status: newStatus }
                );
            } catch (e) {
                setLoading(false);
            }
            setLoading(false);
        },
        [status, site, dispatch]
    );

    return (
        <Dropdown
            border="standard"
            selectedId={status}
            options={ATTENDEE_STATUS_OPTIONS}
            status={loading ? 'loading' : ''}
            onSelect={(option) => updateAttendee(option.id)}
        />
    );
};

function stringToColorHex(str) {
    // Hash the string using a simple hash function
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Convert the hash to a darker shade hexadecimal value
    const hex = ((hash & 0x00ffffff) | 0x003300).toString(16);

    // Pad the hexadecimal value with zeros if needed
    const paddedHex = '000000'.substring(0, 6 - hex.length) + hex;

    // Prepend a '#' symbol to the hexadecimal value
    const colorHex = '#' + paddedHex;

    return colorHex;
}

// Exampl

export const ExportButton = ({ ids = [], event, stats }) => {
    const [loading, setLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const site = useAppSelector(selectSiteData);

    const timestamp = moment().format('YYYY-MM-DD_HHmm');

    const exportAttendees = useCallback(
        async (exportAll) => {
            setLoading(true);
            try {
                const { data } = await API_INSTANCE.post(
                    buildURL(site, `/api/v1/dashboard/attendees/export`),
                    { ids, export_all: exportAll, event_id: event.id }
                );
                fileDownload(data, `${event.title}_attendees_export_${timestamp}.csv`);
            } catch (e) {
                setLoading(false);
            }
            setLoading(false);
        },
        [ids, site]
    );

    return (
        <Box gap={2} direction="horizontal" verticalAlign="middle">
            <Button
                skin="standard"
                disable={loading}
                size="small"
                prefixIcon={<Icons.Download />}
                onClick={() => exportAttendees(selectAll)}
                disabled={!selectAll && ids.length === 0}
            >
                {loading
                    ? 'Exporting'
                    : `Export ${selectAll ? stats?.attendance_count : ids.length} Attendees`}
            </Button>
            <Button
                skin="standard"
                disable={loading}
                size="tiny"
                prefixIcon={<Icons.List />}
                onClick={() => setSelectAll(!selectAll)}
            >
                {selectAll ? 'Unselect' : 'Select'} All
            </Button>
        </Box>
    );
};

export const EventAttendeeList = () => {
    const { id } = useParams<{ id: string }>();
    const site = useAppSelector(selectSiteData);

    const dispatch = useAppDispatch();

    const event = useAppSelector(selectSingleEvent);
    const history = useHistory();

    const attendees = useSelector(selectAttendeesList) || [];
    const eventsIsLoading = useSelector(selectEventsIsLoading);
    const stats = useSelector(selectAttendeeStats);

    const isLoading = useSelector(selectAttendeesIsLoading);

    const columns = [
        {
            title: 'Name',
            align: 'center',
            width: '20%',
            render: (row: Attendee) => (
                <Box direction="vertical" wordBreak="break-all" gap={2} align="center">
                    <Box>{row.email}</Box>
                    <Box>
                        {row.first_name || ''} {row.last_name || ''}
                    </Box>
                    <AttendeeQRCodeCheckin attendee={row} />
                </Box>
            ),
        },
        {
            title: <Box>Price</Box>,
            align: 'center',
            render: (row: Attendee) => <TicketValue attendee={row} />,
        },
        {
            title: 'Ticket Type',
            align: 'center',
            render: (row: Attendee) => (
                <Box gap={1} align="center">
                    {row?.ticket?.name}{' '}
                    {row?.ticket?.name && <TicketValid isValid={row?.ticket_validity?.isValid} />}{' '}
                </Box>
            ),
        },
        {
            title: 'Status',
            align: 'center',
            render: (row: Attendee) => <AttendeeStatus attendee={row} />,
        },
        {
            title: 'Order Date',
            align: 'center',
            render: (row: Attendee) => {
                return moment(row?.created_at).tz(row?.event?.timezone).format('LLL');
            },
        },
        {
            title: (
                <div>
                    Order ID{' '}
                    <InfoIcon content="This Order ID represents a unique identifier for a purchase transaction. Multiple attendees may have the same checkout ID if they were part of the same purchase. The checkout IDs are color-coded to indicate that attendees are part of the same cart group" />
                </div>
            ),
            align: 'center',
            render: (row: Attendee) => {
                if (row.is_waitlist_subscriber) {
                    return <Badge skin="neutralSuccess">In Waitlist</Badge>;
                }
                if (!row?.cart?.friendly_cart_id) {
                    return 'No Ticket - RSVP Only';
                }
                const friendlyCartId = row?.cart?.friendly_cart_id;
                const colorHex = stringToColorHex(friendlyCartId);
                const style = {
                    color: colorHex,
                    cursor: 'pointer',
                    textDecoration: 'underline',
                };

                return (
                    <span
                        style={style}
                        onClick={() => history.push(`/events/orders/manage/${row?.cart?.id}`)}
                    >
                        {friendlyCartId}
                    </span>
                );
            },
        },
        {
            title: (
                <div>
                    Ticket Confirmation
                    <InfoIcon content="Displays whether the attendee received an email confirming their ticket." />
                </div>
            ),
            align: 'center',
            render: (row: Attendee) => {
                return <TicketConfirmationEmailInformation attendee={row} event={event} />;
            },
        },

        {
            align: 'center',
            render: (row) => (
                <Box gap={2}>
                    <Tooltip content="View Attendee">
                        <IconButton
                            size="medium"
                            priority="secondary"
                            onClick={() => {
                                history.push(buildURL(site, `/events/attendees/manage/${row.id}`));
                            }}
                        >
                            <Icons.User />
                        </IconButton>
                    </Tooltip>
                    <DeleteConfirmation successClick={() => dispatch(deleteAttendeeApi(row.id))} />
                </Box>
            ),
        },
    ];

    const fetchMoreData = () =>
        dispatch(
            fetchAttendeesByEventApiPagination({
                eventId: id,
                limit: 10,
                offset: attendees.length,
            })
        );

    useEffect(() => {
        dispatch(getEventApi(id));
        // dispatch(fetchAttendeesByEventApi({ eventId: id }));
    }, [dispatch, id]);

    const eventTitle = truncate(event?.title || '', {
        length: 30,
        omission: '...',
    });

    const ActionsToolbar = ({ selectedCount, getSelectedIds }) => (
        <TableToolbar>
            <TableToolbar.ItemGroup position="start">
                <TableToolbar.Item>
                    <TableToolbar.SelectedCount>{`${selectedCount} Selected`}</TableToolbar.SelectedCount>
                </TableToolbar.Item>
            </TableToolbar.ItemGroup>
            <TableToolbar.ItemGroup position="end">
                <TableToolbar.Item layout="button">
                    <ExportButton ids={getSelectedIds()} event={event} stats={stats} />
                </TableToolbar.Item>
                {/* <TableToolbar.Item layout="button">
              <DeleteButton ids={getSelectedIds()} />
            </TableToolbar.Item> */}
            </TableToolbar.ItemGroup>
        </TableToolbar>
    );

    return (
        <Page maxWidth={'80%'} className={classes.page}>
            <Page.Header
                title={`Attendees of "${eventTitle}"`}
                subtitle="Attendee Roster with Comprehensive Details: Dive into Attendee Profiles, Event Associations, and Automation Engagements"
                showBackButton
                onBackClicked={() => history.push(buildURL(site, `/events`))}
                actionsBar={
                    <Box gap={2}>
                        <Button
                            priority="secondary"
                            size="medium"
                            to={buildURL(site, `/events/manage/${id}`)}
                            as={Link}
                        >
                            Edit Event
                        </Button>
                        {event && <AddAttendeeModal event={event} />}
                    </Box>
                }
            />

            <Page.Content>
                <Layout>
                    {event && (
                        <Cell span={12}>
                            <EventAttendeeStatsWidget event={event} key={id} />
                        </Cell>
                    )}
                    {event && (
                        <Cell span={12}>
                            <AttendeeListFilters event={event} key={id} />
                        </Cell>
                    )}
                    <Cell>
                        {attendees.length > 0 && (
                            <Table
                                data={attendees}
                                columns={columns}
                                showSelection
                                loader={
                                    <Box align="center" padding="24px 0px">
                                        <Loader size="small" />
                                    </Box>
                                }
                                infiniteScroll
                                // hasMore={attendees.length < stats?.attendance_count}
                                // itemsPerPage={20}
                                // loadMore={fetchMoreData}
                            >
                                <Table.ToolbarContainer>
                                    {(selectionContext) => ActionsToolbar({ ...selectionContext })}
                                </Table.ToolbarContainer>

                                <Table.Content />
                            </Table>
                        )}
                        {isLoading && (
                            <Box align="center">
                                <Loader text="Fetching Attendees..." size="small" />
                            </Box>
                        )}

                        {!isLoading && attendees.length === 0 && (
                            <EmptyState
                                theme="page"
                                image={
                                    <Image
                                        width="120px"
                                        height="120px"
                                        src={NoAttendees}
                                        transparent
                                    />
                                }
                                title="No Orders Yet"
                                subtitle="Explore our promotional tools to boost event interest."
                            />
                        )}
                    </Cell>
                </Layout>
            </Page.Content>
        </Page>
    );
};
