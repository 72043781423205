import { DrilldownContainer } from '../../../../components/drilldown';
import AddCalendarButtonDesign from './add_calendar_button_design';
import { BackgroundDesign } from './background_design';
import { ButtonDesign } from './button_design';
import { CalendarDesign } from './calendar-design';
import { CheckoutForm } from './checkout_form';
import { EventDesign } from './event_design';
import { MobileEventDesign } from './mobile_text_design';
import { MultiDaySelection } from './multi-day-selection';
import { TicketForm } from './ticket_form';
import { UserFilterDesign } from './user_filters_design';

export const CustomDesign = () => {
    return (
        <div className="config">
            <DrilldownContainer
                title="Styles and Colors"
                infoText="Override the colors and fonts of individual components"
                sections={[
                    {
                        label: 'Opacity and Color',
                        component: <BackgroundDesign />,
                    },
                    {
                        label: 'Desktop Text Fonts & Colors',
                        component: <EventDesign />,
                    },
                    {
                        label: 'Mobile Text Fonts & Colors',
                        component: <MobileEventDesign />,
                    },
                    {
                        label: 'Button Design',
                        component: <ButtonDesign />,
                    },
                    {
                        label: 'Add to calendar button',
                        component: <AddCalendarButtonDesign />,
                    },
                    {
                        label: 'User Search Filters',
                        component: <UserFilterDesign />,
                    },
                    {
                        label: 'Calendar',
                        component: <CalendarDesign />,
                    },
                    {
                        label: 'Attendee Details Form',
                        component: <CheckoutForm />,
                    },
                    {
                        label: 'Checkout',
                        component: <TicketForm />,
                    },
                    {
                        label: 'Multi Day Selection',
                        component: <MultiDaySelection />,
                    },
                ]}
            />
        </div>
    );
};
