import { useState, FC } from 'react';
import {
    Box,
    Button,
    Card,
    Cell,
    CustomModalLayout,
    Layout,
    Modal,
    SectionHelper,
    Text,
    ToggleSwitch,
} from 'wix-style-react';
import { PlanNotification } from '../../components/plan-notification';
import { ConstantContactAction } from './components/actions/constant-contact-action';
import { HubSpotAction } from './components/actions/hubspot-action';
import { MessageAction } from './components/actions/message-action';
import { SlackMessage } from './components/actions/slack-action';
import { SMSMessage } from './components/actions/sms-action';
import { TwitterMessage } from './components/actions/twitter-action';
import { WebhookAction } from './components/actions/webhooks-action';
import { ZohoAction } from './components/actions/zoho-action';
import { FacebookMessage } from './components/actions/facebook-action';
import { LinkedinMessage } from './components/actions/linkedin-action';
import { uniq } from 'lodash';
import EmailIcon from '../../assets/automation-icons/email.svg';
import SlackIcon from '../../assets/automation-icons/slack.svg';
import HubspotIcon from '../../assets/automation-icons//hubspot.svg';
import LinkedinIcon from '../../assets/automation-icons/linkedin.svg';
import MetaIcon from '../../assets/automation-icons/meta.svg';
import XIcon from '../../assets/automation-icons/x.svg';
import ConstantContactIcon from '../../assets/automation-icons/constant-contact.png';
import ZohoIcon from '../../assets/automation-icons/zoho.png';
import WebhooksIcon from '../../assets/automation-icons/webhooks.svg';
import ConfigureIcon from '../../assets/automation-icons/configure.svg';

export const ActionSelector: FC<any> = ({
    id,
    triggers,
    messageRef,
    setValue,
    control,
    errors,
    watch,
    field,
    campaign,
    tag,
    getValues,
    inlineIfSingle,
}) => {
    const watchTriggerType = watch('trigger_type');
    const watchActionSelections = watch('action_selections', []);
    const watchScheduleType = watch('schedule_type');
    const watchEmailEditorType = watch('email_editor_type', campaign?.email_editor_type);

    const items = [
        {
            tags: ['messaging'],
            name: 'email',
            icon: EmailIcon,
            description:
                'Engage attendees with customizable emails using personalization tokens and an intuitive drag-and-drop editor.',
            initiallyOpen: field.value.includes('email'),
            title: 'Email',
            disabled: watchScheduleType === 'PROMOTION',
            content: (
                <MessageAction
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    watch={watch}
                    watchEmailEditorType={watchEmailEditorType}
                    campaign={campaign}
                    getValues={getValues}
                    messageRef={messageRef}
                />
            ),
        },
        {
            tags: ['social'],
            name: 'slack',
            icon: SlackIcon,
            description:
                'Seamlessly communicate with attendees through Slack channels for real-time engagement and updates.',
            initiallyOpen: field.value.includes('slack'),
            title: 'Slack',
            content: (
                <PlanNotification type="business" text="slack integration">
                    <SlackMessage
                        control={control}
                        campaign={campaign}
                        trigger={triggers.find((t: any) => t.value === watchTriggerType)}
                        id={id}
                    />
                </PlanNotification>
            ),
        },
        {
            tags: ['social'],
            name: 'twitter',
            icon: XIcon,
            description:
                'Amplify event reach by engaging attendees and broadcasting updates directly through X (Twitter).',
            initiallyOpen: field.value.includes('twitter'),
            title: 'X (Twitter)',
            content: (
                <PlanNotification type="business" text="twitter integration">
                    <TwitterMessage
                        control={control}
                        campaign={campaign}
                        trigger={triggers.find((t: any) => t.value === watchTriggerType)}
                        id={id}
                    />
                </PlanNotification>
            ),
        },
        {
            tags: ['social'],
            name: 'facebook',
            icon: MetaIcon,
            initiallyOpen: field.value.includes('facebook'),
            title: 'Facebook and Instagram',
            description:
                'Maximize social presence by sharing engaging content on both Instagram and Facebook, reaching a diverse audience.',
            content: (
                <PlanNotification type="business" text="meta">
                    <FacebookMessage
                        control={control}
                        campaign={campaign}
                        trigger={triggers.find((t) => t.value === watchTriggerType)}
                        id={id}
                        setValue={setValue}
                    />
                </PlanNotification>
            ),
        },
        {
            tags: ['social'],
            name: 'linkedin',
            icon: LinkedinIcon,
            description:
                'Professionalize event promotion by connecting with attendees on LinkedIn, ideal for networking and business-focused events.',
            initiallyOpen: field.value.includes('linkedin'),
            title: 'Linkedin',
            content: (
                <PlanNotification type="business" text="linkedin">
                    <LinkedinMessage
                        control={control}
                        campaign={campaign}
                        trigger={triggers.find((t) => t.value === watchTriggerType)}
                        id={id}
                    />
                </PlanNotification>
            ),
        },
        {
            tags: ['crm'],
            name: 'hubspot',
            title: 'HubSpot',
            description:
                "Efficiently manage your event by automatically adding attendee details as contacts within HubSpot's CRM system.",
            icon: HubspotIcon,
            initiallyOpen: field.value.includes('hubspot'),
            content: (
                <PlanNotification type="business" text="HubSpot integration">
                    <HubSpotAction setValue={setValue} watch={watch} campaign={campaign} />
                </PlanNotification>
            ),
        },
        {
            tags: ['crm'],
            name: 'zoho',
            title: 'Zoho',
            description:
                'Automate your workflow by adding attendee details directly to Zoho for seamless event data management.',
            icon: ZohoIcon,
            initiallyOpen: field.value.includes('zoho'),
            content: (
                <PlanNotification type="business" text="Zoho integration">
                    <ZohoAction setValue={setValue} watch={watch} campaign={campaign} />
                </PlanNotification>
            ),
        },
        {
            tags: ['crm'],
            title: 'Constant Contact',
            icon: ConstantContactIcon,
            description:
                'Enhance your email marketing efforts by automatically syncing attendee details with Constant Contact for targeted outreach.',
            name: 'constantContact',
            initiallyOpen: field.value.includes('constantContact'),
            content: (
                <PlanNotification type="business" text="Constant Contact integration">
                    <ConstantContactAction setValue={setValue} watch={watch} campaign={campaign} />
                </PlanNotification>
            ),
        },
        {
            tags: ['development'],
            title: 'Webhooks',
            name: 'webhook',
            description:
                'Customize data flow by configuring endpoints to receive event-related information, enabling real-time data integration.',
            icon: WebhooksIcon,
            initiallyOpen: field.value.includes('webhook'),
            content: (
                <PlanNotification type="business" text="Webhooks">
                    <WebhookAction control={control} campaign={campaign} />
                </PlanNotification>
            ),
        },
    ].filter((item) => (tag ? item.tags.includes(tag) : true));

    if (inlineIfSingle && items.length === 1) {
        return (
            <Card stretchVertically>
                <Card.Content>
                    <Box direction="vertical">{items[0].content}</Box>
                </Card.Content>
            </Card>
        );
    }
    return (
        <Layout>
            {items.map((item, index) => (
                <Cell span={3} key={index}>
                    <Card showShadow stretchVertically>
                        <Card.Header
                            title={
                                <Box gap={2} verticalAlign="middle">
                                    <img src={item.icon} width={40} />
                                    {item.title}
                                </Box>
                            }
                            suffix={
                                <ToggleSwitch
                                    checked={watchActionSelections.includes(item.name)}
                                    onChange={(e) => {
                                        setValue(
                                            'action_selections',
                                            uniq(watchActionSelections.concat(item.name))
                                        );
                                        if (e.target.checked) {
                                            setValue(
                                                'action_selections',
                                                uniq([...watchActionSelections, item.name])
                                            );
                                        } else {
                                            const newList = watchActionSelections.filter(
                                                (name: string) => name !== item.name
                                            );
                                            setValue('action_selections', newList);
                                        }
                                    }}
                                />
                            }
                        />
                        <Card.Content>
                            <Box direction="vertical" gap={2}>
                                <Text>{item.description}</Text>

                                {field.value.includes(item.name) && (
                                    <ConfigureConnector content={item.content} title={item.title} />
                                )}
                            </Box>
                        </Card.Content>
                    </Card>
                </Cell>
            ))}
        </Layout>
        // <SelectableAccordion
        //     onSelectionChanged={(ids) => {
        //         const newSelection = ids.map((id) => items[id].name).filter((s) => s);
        //         setValue('action_selections', newSelection);
        //     }}
        //     type="toggle"
        //     items={items}
        // />
    );
};

export const ConfigureConnector = ({ content, title }) => {
    const [isModalOpened, setModalOpened] = useState(false);

    const openModal = () => setModalOpened(true);
    const closeModal = () => setModalOpened(false);

    const renderModalContent = () => (
        <CustomModalLayout
            secondaryButtonText="Close"
            onCloseButtonClick={closeModal}
            secondaryButtonOnClick={closeModal}
            title={`Configure ${title}`}
            width="50%"
        >
            <>
                <Box marginBottom={2}>
                    <SectionHelper appearance="success">
                        Please close the setup and click &apos;Save&apos; to apply your changes to
                        the Engagement.
                    </SectionHelper>
                </Box>
                {content}
            </>
        </CustomModalLayout>
    );

    return (
        <>
            <Box align="center" marginTop={3}>
                <Button
                    prefixIcon={<img src={ConfigureIcon} width={35} />}
                    onClick={openModal}
                    priority="secondary"
                >
                    Setup
                </Button>
            </Box>
            <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
                {renderModalContent()}
            </Modal>
        </>
    );
};
