import {
    Box,
    FormField,
    Cell,
    Layout,
    Text,
    Card,
    ToggleSwitch,
    RichTextInputArea,
    Table,
    Divider,
    Input,
    Heading,
    Tooltip,
    IconButton,
    TableToolbar,
    Loader,
    EmptyState,
    Image,
} from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import moment from 'moment';
import { classes } from './waitlist.st.css';
import NoAttendees from '../../assets/no-attendees.gif';

import { Controller } from 'react-hook-form';
import { MesssageKeysTable } from '../campaigns/components/message-keys-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    deleteAttendeeApi,
    fetchEventWaitlistAttendees,
    selectAttendeeWaitlist,
} from '../../features/attendees-slice';
import { useSelector } from 'react-redux';
import { buildURL } from '../../features/utils';
import { DeleteConfirmation } from '../../components/delete-confirmation';
import { useHistory } from 'react-router-dom';
import { WaitlistReleaseModal } from '../../components/waitlist-release-modal';
import {
    getEventTicketsApi,
    selectTicketsList,
    updateEventWaitlist,
} from '../../features/events-slice';
import { CampaignModal } from './event-campaigns';
import { fetchEventCampaignsApi, selectEventCampaignsList } from '../../features/campaign-slice';
import { PlanNotification } from '../../components/plan-notification';
import { selectSiteData } from '../../features/account-slice';
import { ConvertToAttendee } from '../../components/convert-to-attendee';

const TicketStatusMessage = ({ tickets }) => {
    const allSoldOut = tickets.every((ticket) => ticket.status === 'soldout');
    const totalAvailable = tickets.reduce((acc, ticket) => acc + ticket.available_quantity, 0);

    return (
        <Box align="center" direction="vertical" gap={2}>
            {allSoldOut ? (
                <Text skin="error">
                    All tickets are sold out. Please make more tickets available before notifying
                    attendees.
                </Text>
            ) : (
                <Text skin="success">
                    There are <strong>{totalAvailable}</strong> tickets available for purchase.
                </Text>
            )}
        </Box>
    );
};

const WaitlistForm = ({ control, setValue, event, watch }) => {
    return (
        <>
            <FormField
                label="Show Only When No Tickets Available"
                labelPlacement="top"
                infoContent="Displays a waitlist option for tickets that are sold out."
            >
                <Controller
                    control={control}
                    name="waitlistTriggerTicketsSoldOut"
                    render={({ field }) => {
                        return <ToggleSwitch {...field} checked={field.value} />;
                    }}
                />
            </FormField>
            <FormField
                label="Show When Event is at Capacity"
                labelPlacement="top"
                infoContent="Activates the waitlist when the event reaches full capacity."
            >
                <Controller
                    control={control}
                    name="waitlistTriggerEventCapacity"
                    render={({ field }) => {
                        return <ToggleSwitch {...field} checked={field.value} />;
                    }}
                />
            </FormField>

            <Box>
                <Divider />
            </Box>
            <Box marginTop={2} marginBottom={2}>
                <MesssageKeysTable />
            </Box>
            <FormField
                label={
                    <Box gap={2}>
                        <Text>Description</Text>
                    </Box>
                }
                infoContent="Will appear in the event widget and on the event page to inform your users about the waitlist."
            >
                <Controller
                    control={control}
                    name="waitlistDescription"
                    render={({ field }) => (
                        <RichTextInputArea
                            {...field}
                            className={classes.flexGrow}
                            initialValue={event?.waitlistDescription || ''}
                            minHeight="140px"
                            maxHeight="400px"
                            onChange={(htmlText) => setValue('waitlistDescription', htmlText)}
                        />
                    )}
                />
            </FormField>
            <FormField
                label={
                    <Box gap={2}>
                        <Text>Call to Action</Text>
                    </Box>
                }
                infoContent="Text that appears on the call to action button for the waitlist."
            >
                <Controller
                    control={control}
                    name="waitlistCallToAction"
                    render={({ field }) => <Input {...field} />}
                />
            </FormField>
            <FormField
                label={
                    <Box gap={2}>
                        <Text>Confirmation Message</Text>
                    </Box>
                }
                infoContent="When a user adds their name to the waitlist then the message will appear to confirm they are on a waiting list. Use our personalization tokens to provide a personalized message to your users: Note you can configure an engagement email to send to users too. Just go to the Engagements tab"
            >
                <Controller
                    control={control}
                    name="waitlistConfirmationMessage"
                    render={({ field }) => (
                        <RichTextInputArea
                            {...field}
                            className={classes.flexGrow}
                            initialValue={event?.waitlistConfirmationMessage || ''}
                            minHeight="140px"
                            maxHeight="400px"
                            onChange={(htmlText) =>
                                setValue('waitlistConfirmationMessage', htmlText)
                            }
                        />
                    )}
                />
            </FormField>
            <Box marginTop={3} marginBottom={3}>
                <Divider />
            </Box>
            <div className={classes.heading}>Communication</div>
            <WaitlistCommunicationTable
                setValue={setValue}
                event={event}
                watch={watch}
                control={control}
            />
            {/* 
            <FormField
                label={
                    <Box gap={2}>
                        <Text>Auto-Response Message</Text>
                    </Box>
                }
                required
            >
                <Controller
                    control={control}
                    name="waitlistAutoResponseMessage"
                    render={({ field }) => (
                        <RichTextInputArea
                            {...field}
                            className={classes.flexGrow}
                            initialValue={event?.waitlistAutoResponseMessage || ''}
                            minHeight="140px"
                            maxHeight="400px"
                            onChange={(htmlText) =>
                                setValue('waitlistAutoResponseMessage', htmlText)
                            }
                        />
                    )}
                />
            </FormField>
            <FormField
                label={
                    <Box gap={2}>
                        <Text>Ticket Release Message</Text>
                    </Box>
                }
                required
            >
                <Controller
                    control={control}
                    name="waitlistTicketReleaseMessage"
                    render={({ field }) => (
                        <RichTextInputArea
                            {...field}
                            className={classes.flexGrow}
                            initialValue={event?.waitlistTicketReleaseMessage}
                            minHeight="140px"
                            maxHeight="400px"
                            onChange={(htmlText) =>
                                setValue('waitlistTicketReleaseMessage', htmlText)
                            }
                        />
                    )}
                />
            </FormField> */}
        </>
    );
};

const WaitlistCommunicationTable = ({ event, watch, control, setValue }) => {
    const [shown, setShown] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const watchWaitlistEnableCommunication = watch('waitlistEnableCommunication');
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const campaigns = useSelector(selectEventCampaignsList);
    const waitlistCampaigns = useMemo(
        () =>
            campaigns.filter((campaign) =>
                ['WAITLIST_SIGNUP_CONFIRMATION', 'WAITLIST_AVAILABLE_NOTIFICATION'].includes(
                    campaign.trigger_type
                )
            ),
        [campaigns]
    );
    const handleToggle = useCallback(
        async (e) => {
            const { checked } = e.target;
            setValue('waitlistEnableCommunication', checked);
            if (checked) {
                setLoading(true);
                await dispatch(
                    updateEventWaitlist({ event_id: event.id, enable_communication: checked })
                );
                await dispatch(fetchEventCampaignsApi(event.id));
                setLoading(false);
            } else {
                // dont need to fetch campaigns since the table is not present. I only want to update the state of the campaign
                await dispatch(
                    updateEventWaitlist({ event_id: event.id, enable_communication: checked })
                );
            }
        },
        [dispatch, event.id, setValue, watchWaitlistEnableCommunication]
    );

    useEffect(() => {
        const fetch = async () => {
            if (watchWaitlistEnableCommunication) {
                setLoading(true);
                await dispatch(fetchEventCampaignsApi(event.id));
                setLoading(false);
            }
        };
        void fetch();
    }, []);

    const handleWaitlistCommunicationEdit = (campaign) => {
        setShown(true);
        setSelectedCampaign(campaign);
    };
    const columns = [
        {
            width: 'auto',
            render: (row: Campaign) => (
                <Box gap={2}>
                    {' '}
                    {row.trigger_type === 'WAITLIST_SIGNUP_CONFIRMATION' ? (
                        <Icons.Confirm />
                    ) : (
                        <Icons.Promote />
                    )}{' '}
                    <Text>{row.name}</Text>
                </Box>
            ),
        },
        {
            align: 'left',
            render: (row) => (
                <Tooltip content={`Edit ${row.name}`}>
                    <IconButton
                        priority="secondary"
                        onClick={() => handleWaitlistCommunicationEdit(row)}
                    >
                        <Icons.Edit />
                    </IconButton>
                </Tooltip>
            ),
        },
    ];

    return (
        <Box direction="vertical" gap={3}>
            {shown && (
                <CampaignModal
                    event={event}
                    campaign={selectedCampaign}
                    shown={shown}
                    setShown={setShown}
                    hideTriggerSelection
                    hideName
                    hideSchedule
                    onComplete={() => setSelectedCampaign(null)}
                />
            )}
            <FormField
                label="Enable Communication"
                labelPlacement="top"
                infoContent="Enable if you want to send emails or SMS to attendees"
            >
                <Controller
                    control={control}
                    name="waitlistEnableCommunication"
                    render={({ field }) => {
                        return (
                            <ToggleSwitch
                                {...field}
                                checked={field.value}
                                onChange={handleToggle}
                            />
                        );
                    }}
                />
            </FormField>
            {loading && (
                <Box>
                    <Loader size="small" />
                </Box>
            )}
            {!loading && watchWaitlistEnableCommunication && (
                <Table
                    skin="neutral"
                    data={waitlistCampaigns || []}
                    columns={columns}
                    titleBarVisible={false}
                >
                    <Table.Content />
                </Table>
            )}
        </Box>
    );
};
export const Waitlist = ({ event, control, setValue, watch }) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [loadingSubscribers, setLoadingSubscribers] = useState(true);

    const attendees = useSelector(selectAttendeeWaitlist);
    const tickets = useSelector(selectTicketsList);
    useEffect(() => {
        const fetch = async () => {
            setLoadingSubscribers(true);
            await dispatch(fetchEventWaitlistAttendees({ eventId: event.id }));
            await dispatch(getEventTicketsApi(event.id));
            setLoadingSubscribers(false);
        };
        fetch();
    }, [dispatch]);

    const handleDelete = useCallback(
        async (attendee) => {
            setLoadingSubscribers(true);
            await dispatch(deleteAttendeeApi(attendee.id));
            await dispatch(fetchEventWaitlistAttendees({ eventId: event.id }));
            setLoadingSubscribers(false);
        },
        [dispatch, event.id]
    );

    const site = useAppSelector(selectSiteData);

    const watchEnableWaitlist = watch('enableWaitlist');

    const columns = [
        { title: 'Email', render: (row) => row?.email },
        { title: 'Ticket', render: (row) => <Text>{row?.ticket?.name || 'N/A'}</Text> },
        { title: 'Added to Waitlist', render: (row) => moment(row?.created_at).format('LLL') },
        {
            align: 'center',
            render: (row) => (
                <Box gap={2}>
                    <Tooltip content="View Attendee">
                        <IconButton
                            size="medium"
                            priority="secondary"
                            onClick={() => {
                                history.push(buildURL(site, `/events/attendees/manage/${row.id}`));
                            }}
                        >
                            <Icons.User />
                        </IconButton>
                    </Tooltip>
                    <DeleteConfirmation successClick={() => handleDelete(row)} />
                </Box>
            ),
        },
    ];

    const ActionsToolbar = ({ selectedCount, getSelectedIds }) => (
        <TableToolbar>
            <TableToolbar.ItemGroup position="start">
                <TableToolbar.Item>
                    <TableToolbar.Label>{`${selectedCount} selected`}</TableToolbar.Label>
                </TableToolbar.Item>
                <TableToolbar.Item>
                    <Box height="18px">
                        <Divider direction="vertical" />
                    </Box>
                </TableToolbar.Item>
                <TableToolbar.Item layout="button">
                    <WaitlistReleaseModal
                        event={event}
                        releaseCount={selectedCount}
                        selectedIds={getSelectedIds ? getSelectedIds() : 0}
                    />
                    <ConvertToAttendee
                        event={event}
                        releaseCount={selectedCount}
                        selectedIds={getSelectedIds ? getSelectedIds() : 0}
                    />
                </TableToolbar.Item>
            </TableToolbar.ItemGroup>
        </TableToolbar>
    );

    return (
        <Box
            direction="vertical"
            verticalAlign="space-between"
            marginTop="50px"
            marginBottom="50px"
            margin={'0 auto'}
            padding="24px 29px 27px"
            borderRadius="8px"
            backgroundColor="white"
            height="100%"
            width="90%"
            boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
            flexGrow={1}
        >
            <Card.Header
                title="Waitlist"
                subtitle="Let people join a waitlist if tickets sell out or your event reaches capacity"
            />
            <Card.Divider />
            <Box marginTop={4} width={'100%'}>
                <PlanNotification type="business" text="waitlist">
                    <Layout className={classes.fullWidth}>
                        <Cell span={6}>
                            <Box direction="vertical" gap={5}>
                                <FormField label="Enable Waitlist" labelPlacement="top">
                                    <Controller
                                        control={control}
                                        name="enableWaitlist"
                                        render={({ field }) => {
                                            return (
                                                <ToggleSwitch {...field} checked={field.value} />
                                            );
                                        }}
                                    />
                                </FormField>
                                {watchEnableWaitlist && (
                                    <WaitlistForm
                                        control={control}
                                        setValue={setValue}
                                        event={event}
                                        watch={watch}
                                    />
                                )}
                            </Box>
                        </Cell>
                        <Cell span={6}>
                            <Box direction="vertical" gap={3}>
                                <Heading appearance="H3">Waitlist Subscribers</Heading>
                                <Divider />
                                {loadingSubscribers ? (
                                    <Box align="center">
                                        <Loader size="small" />
                                    </Box>
                                ) : (
                                    <>
                                        <Box marginTop={2} marginBottom={3}>
                                            <TicketStatusMessage tickets={tickets} />
                                        </Box>
                                        <Table
                                            skin="neutral"
                                            data={attendees || []}
                                            columns={columns}
                                            titleBarVisible={false}
                                            showSelection
                                        >
                                            <Table.ToolbarContainer>
                                                {(selectionContext) =>
                                                    selectionContext.selectedCount > 0 &&
                                                    ActionsToolbar({ ...selectionContext })
                                                }
                                            </Table.ToolbarContainer>
                                            <Table.Content />
                                        </Table>
                                        {!loadingSubscribers && attendees.length === 0 && (
                                            <Box position="relative">
                                                <EmptyState
                                                    theme="page-no-border"
                                                    image={
                                                        <Image
                                                            width="120px"
                                                            height="120px"
                                                            src={NoAttendees}
                                                            transparent
                                                            showBorder={false}
                                                        />
                                                    }
                                                    subtitle="There are currently no attendees on the waitlist."
                                                />
                                            </Box>
                                        )}
                                    </>
                                )}
                            </Box>
                        </Cell>
                    </Layout>
                </PlanNotification>
            </Box>
        </Box>
    );
};
