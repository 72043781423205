import {
    Box,
    FormField,
    Input,
    RadioGroup,
    InfoIcon,
    ToggleSwitch,
    Dropdown,
    Row,
    Col,
} from 'wix-style-react';

import { Controller } from 'react-hook-form';

import { classes } from '../../create-events.st.css';

import { isEmpty } from 'lodash';
import { EVENT_CATEGORIES, EVENT_SUBCATEGORIES, EVENT_TYPES } from '../../type-and-categories';

export const EventType = ({ watch, control, setValue, errors }) => {
    const watchEventData = watch('include_event_data_url');
    const watchEventSetup = watch('event_setup', 'tickets');
    const watchUrl = watch('url');
    const watchType = watch('type');
    const watchCategory = watch('category');
    const watchSubcategory = watch('subcategory');

    const eventSetupOptions = [
        {
            value: 'tickets',
            title: 'Sell tickets',
            info: 'Offer free or paid tickets through the app with our simple checkout process.',
        },
        {
            value: 'rsvp',
            title: 'RSVP or Simple Registration',
            info: 'Guests can RSVP through a form that allows you to collect information.',
        },
        {
            value: 'url',
            title: 'External URL',
            info: 'Clicking the RSVP/Attend button will take the user to an external page.',
            selectedComponent: (
                <Box gap={2}>
                    <FormField>
                        <Controller
                            control={control}
                            name="url"
                            rules={{
                                validate: (url: string) => {
                                    if (isEmpty(url)) return true;
                                    try {
                                        new URL(url);
                                    } catch (e) {
                                        return false;
                                    }
                                    return true;
                                },
                            }}
                            render={({ field }) => (
                                <Input
                                    size="large"
                                    placeholder="https://www.ticketspot.io"
                                    {...field}
                                    className={classes.urlInput}
                                    onChange={(e) => {
                                        setValue('url', e.target.value);
                                    }}
                                    value={watchUrl}
                                />
                            )}
                        />
                        {errors?.url?.type === 'validate' && (
                            <small className={classes.error}>
                                <b>{watchUrl}</b> is not valid. Make sure the URL starts with{' '}
                                <i>https://</i>. For example - https://ticketspot.io
                            </small>
                        )}
                    </FormField>
                    <FormField
                        label="Pass event data as query parameters"
                        infoContent="When a guest is redirected to the specified site the event data will be sent as query parameters so you can use the information on the target site"
                    >
                        <Controller
                            control={control}
                            name="include_event_data_url"
                            render={({ field }) => (
                                <ToggleSwitch
                                    {...field}
                                    onChange={(e) => {
                                        setValue('include_event_data_url', e.target.checked);
                                    }}
                                    checked={watchEventData}
                                />
                            )}
                        />
                        {errors?.url?.type === 'validate' && (
                            <small className={classes.error}>
                                <b>{watchUrl}</b> is not valid. Make sure the URL starts with{' '}
                                <i>https://</i>. For example - https://ticketspot.io
                            </small>
                        )}
                    </FormField>
                </Box>
            ),
        },
        {
            value: 'nothing',
            title: 'Display only',
            info: 'Show event information only, without the attend or checkout button.',
        },
    ];

    return (
        <Box marginTop={4} direction="vertical">
            <Row>
                <Col span={6}>
                    <FormField label="Type" required>
                        <Controller
                            control={control}
                            name="type"
                            rules={{
                                required: 'Event type is required',
                            }}
                            render={({ field }) => (
                                <Dropdown
                                    {...field}
                                    placeholder="Select an event type..."
                                    popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                                    onSelect={(option) => setValue('type', option.id)}
                                    selectedId={watchType}
                                    options={EVENT_TYPES.map((field) => ({
                                        id: field.id,
                                        value: field.name,
                                    }))}
                                />
                            )}
                        />
                    </FormField>
                    <small className={classes.error}>{errors.type?.message}</small>
                </Col>
                <Col span={6}>
                    <Box direction="vertical" gap={2}>
                        <FormField label="Category" required>
                            <Controller
                                control={control}
                                name="category"
                                rules={{
                                    required: 'Category is required',
                                }}
                                render={({ field }) => (
                                    <Dropdown
                                        {...field}
                                        placeholder="Select a category..."
                                        popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                                        onSelect={(option) => setValue('category', option.id)}
                                        selectedId={watchCategory}
                                        options={EVENT_CATEGORIES.map((field) => ({
                                            id: field.id,
                                            value: field.name,
                                        }))}
                                    />
                                )}
                            />
                        </FormField>
                        <small className={classes.error}>{errors.category?.message}</small>

                        <FormField label="Subcategory" required>
                            <Controller
                                control={control}
                                name="subcategory"
                                render={({ field }) => (
                                    <Dropdown
                                        {...field}
                                        popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                                        placeholder="Select a subcategory..."
                                        onSelect={(option) => setValue('subcategory', option.id)}
                                        selectedId={watchSubcategory}
                                        options={EVENT_SUBCATEGORIES.filter(
                                            (c) => c.parent_category.id === watchCategory
                                        ).map((field) => ({
                                            id: field.id,
                                            value: field.name,
                                        }))}
                                    />
                                )}
                            />
                        </FormField>
                    </Box>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <FormField label="Event Setup" required>
                        <Controller
                            control={control}
                            name="event_setup"
                            rules={{
                                required: 'Event setup needs to be selected',
                            }}
                            render={({ field }) => (
                                <RadioGroup
                                    onChange={(e) => {
                                        setValue('event_setup', e);
                                    }}
                                    value={field.value}
                                >
                                    {eventSetupOptions.map((option, index) => {
                                        return (
                                            <RadioGroup.Radio value={option.value} key={index}>
                                                <Box gap={1}>
                                                    {option.title}
                                                    <InfoIcon size="medium" content={option.info} />
                                                </Box>
                                                {watchEventSetup === option.value &&
                                                    option?.selectedComponent}
                                            </RadioGroup.Radio>
                                        );
                                    })}
                                </RadioGroup>
                            )}
                        />
                    </FormField>
                </Col>
            </Row>
        </Box>
    );
};
