import { SettingsHeader } from '../../../../components/settings-header';
import { selectSettings } from '../../../../features/settings-slice';
import { useAppSelector } from '../../../../hooks';
import { FontColorPicker } from '../font-picker';

export const MobileEventDesign = ({ label }) => {
    const settings = useAppSelector(selectSettings);

    return (
        <div>
            <SettingsHeader title={label} />

            {settings.showHeader && (
                <FontColorPicker
                    title="Main Header"
                    fontUpdateKey="_mobileWidgetHeaderFont"
                    colorUpdateKey="_mobileHeadlineColor"
                    startWithTheme="font_5"
                    startWithColor="color-5"
                    fontMaxSize={30}
                />
            )}

            <FontColorPicker
                title="Event Header"
                fontUpdateKey="_mobileEventTitleFont"
                colorUpdateKey="_mobileEventTitleColor"
                startWithColor="color-5"
                startWithTheme="font_7"
            />

            <FontColorPicker
                title="Event Details"
                startWithTheme="font_8"
                startWithColor="color-5"
                fontUpdateKey="_mobileEventBodyFont"
                colorUpdateKey="_mobileEventBodyColor"
            />

            <FontColorPicker
                title="Dates"
                startWithColor="color-5"
                fontUpdateKey="_mobileEventDateFont"
                colorUpdateKey="_mobileEventDateColor"
                startWithTheme="font_9"
            />

            <FontColorPicker
                title="Venue"
                startWithTheme="font_9"
                startWithColor="color-5"
                fontUpdateKey="_mobileEventVenueFont"
                colorUpdateKey="_mobileEventVenueColor"
            />

            <FontColorPicker
                title="On Sale Event Status"
                startWithTheme="font_6"
                startWithColor="color-5"
                fontUpdateKey="_mobileEventAvailableStatusBodyFont"
                colorUpdateKey="_mobileEventAvailableStatusBodyColor"
                fontMaxSize={25}
            />

            <FontColorPicker
                title="Postponed Event Status"
                startWithTheme="font_9"
                startWithColor="color-5"
                fontUpdateKey="_mobileEventUnavailableStatusBodyFont"
                colorUpdateKey="_mobileEventUnavailableStatusBodyColor"
                fontMaxSize={25}
            />

            <FontColorPicker
                title="Next Event"
                startWithColor="color-5"
                startWithTheme="font_8"
                fontUpdateKey="_mobileNextEventFont"
                colorUpdateKey="_mobileNextEventColor"
                fontMaxSize={25}
            />
        </div>
    );
};
