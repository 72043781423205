import { Box, InfoIcon, Text } from 'wix-style-react';

export const SettingsHeader = ({ title, info }) => {
    return (
        <>
            <Box padding="12px 30px" backgroundColor="#f0f3f5" align="center" marginBottom={2}>
                <Text skin="standard">{title}</Text>{' '}
                {info && (
                    <Box marginLeft={1}>
                        <InfoIcon size="small" content={info} />
                    </Box>
                )}
            </Box>{' '}
        </>
    );
};
export const SubSettingsHeader = ({ title, info }) => {
    return (
        <>
            <Box padding="8px 20px" backgroundColor="#5650be24" align="center" marginBottom={2}>
                <Text skin="standard">{title}</Text>{' '}
                {info && (
                    <Box marginLeft={1}>
                        <InfoIcon size="small" content={info} />
                    </Box>
                )}
            </Box>{' '}
        </>
    );
};
