import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, SectionHelper, Loader, Text } from 'wix-style-react';
import ZoomIcon from '../../../assets/zoom.png';
import {
    fetchConnectedAccountsApi,
    selectConnectedAccounts,
    selectSiteData,
} from '../../../features/account-slice';
import { API_INSTANCE, buildURL } from '../../../features/utils';
import { Copy } from './copy';
import { isEmpty } from 'lodash';

export function ZoomMeetings({ id }) {
    const [saving, setSaving] = useState(false);
    const [meeting, setMeeting] = useState();
    const [usePMI, setUsePMI] = useState(false);
    const [account, setAccount] = useState();
    const site = useSelector(selectSiteData);
    const connectedAccounts = useSelector(selectConnectedAccounts);
    const dispatch = useDispatch();

    useEffect(async () => {
        if (!meeting) {
            const { data } = await API_INSTANCE.get(
                buildURL(site, `/api/v1/dashboard/events/${id}/zoom-meetings`)
            );
            setMeeting(data);
        }
    }, [meeting]);
    useEffect(async () => {
        if (!account) {
            dispatch(fetchConnectedAccountsApi());
            const { data } = await API_INSTANCE.get(buildURL(site, `/api/v1/dashboard/accounts`));
            setAccount(data);
        }
    }, [account, dispatch]);

    const createZoomMeeting = useCallback(
        (type) => async () => {
            setSaving(true);
            try {
                const { data } = await API_INSTANCE.post(
                    buildURL(site, `/api/v1/dashboard/events/${id}/zoom-meetings`),
                    { type, use_pmi: usePMI }
                );
                setMeeting(data);
            } catch (e) {
                setSaving(false);
            }
            setSaving(false);
        },
        [saving, usePMI]
    );
    const deleteZoomMeeting = useCallback(async () => {
        await API_INSTANCE.delete(
            buildURL(site, `/api/v1/dashboard/events/${meeting.id}/zoom-meetings`)
        );
        setMeeting(null);
    });

    if (account && !connectedAccounts.hasOwnProperty('zoom')) {
        return (
            <>
                <Box marginBottom={2}>
                    <SectionHelper appearance="warning">
                        Easily create Zoom links for your events by connecting your Zoom account
                        through our dedicated integrations page.
                    </SectionHelper>
                </Box>
                <Box direction="horizontal">
                    <Button
                        size="medium"
                        prefixIcon={<img src={ZoomIcon} alt="zoom" width="30" />}
                        disabled
                    >
                        Create Meeting
                    </Button>
                    <Box marginLeft={2} />
                    <Button
                        size="medium"
                        prefixIcon={<img src={ZoomIcon} alt="zoom" width="30" />}
                        disabled
                    >
                        Create Webinar
                    </Button>
                </Box>
            </>
        );
    }
    if (!isEmpty(meeting)) {
        return (
            <>
                <SectionHelper appearance="success">
                    <Text size="small">
                        You have connected your Zoom account and generated a{' '}
                        <b>{meeting.type || 'meeting'}</b> join url for your attendees. You can use
                        email automation to send this to your attendees. In your email automation
                        message use personalization token <b>{'{{event_meeting_url}}'} </b>
                    </Text>
                    <br />
                    <br />
                    <Text size="small">
                        You can also view your {meeting.type}s on the{' '}
                        <a
                            target="_blank"
                            href={`https://zoom.us/${meeting.type}#/upcoming`}
                            rel="noreferrer"
                        >
                            Zoom Dashboard
                        </a>
                    </Text>
                </SectionHelper>
                <Box width="100%" marginTop={2} marginBottom={2}>
                    <Copy text={meeting.join_url} /> <Box marginLeft={2} />
                    <Button onClick={deleteZoomMeeting}>Remove</Button>
                </Box>
            </>
        );
    }

    return (
        <Box direction="horizontal" gap={2}>
            {/* <FormField
                                            label="Use personal meeting URL"
                                            labelPlacement='left'
                                        >
            <ToggleSwitch
                                                            checked={usePMI}
                                                            onChange={(e) =>
                                                                setUsePMI(!usePMI)
                                                            }
                                                        />
                                                        </FormField> */}
            <Button
                size="medium"
                prefixIcon={<img src={ZoomIcon} alt="zoom" width="30" />}
                onClick={createZoomMeeting('meeting')}
            >
                {saving ? <Loader size="tiny" /> : 'Create Meeting'}
            </Button>
            <Box marginLeft={2} />
            <Button
                size="medium"
                prefixIcon={<img src={ZoomIcon} alt="zoom" width="30" />}
                onClick={createZoomMeeting('webinar')}
            >
                {saving ? <Loader size="tiny" /> : 'Create Webinar'}
            </Button>
        </Box>
    );
}
