import { useState } from 'react';
import { Text, Box, Cell, FormField, Layout, Palette, Thumbnail } from 'wix-style-react';
import themes from './themes.json';

export const ThemePicker = ({ onChange }) => {
    const [selected, setSelected] = useState(0);

    return (
        <Box direction="vertical">
            <Layout gap="12px">
                <Cell span={12}>
                    <Box paddingTop="24px">
                        <Text>Featured Themes</Text>
                    </Box>
                </Cell>
                {themes.map((theme, i) => {
                    return (
                        <Cell span={12} key={i}>
                            <Thumbnail
                                selected={selected === i}
                                onClick={() => {
                                    setSelected(i);
                                    onChange(themes[i]);
                                }}
                            >
                                <Box padding="12px" direction="vertical">
                                    <FormField label={theme.title}>
                                        <Text size="small">{theme.subtitle}</Text>
                                        <Box marginTop={2} height="24px">
                                            <Palette fill={Object.values(theme.colors)} />
                                        </Box>
                                    </FormField>
                                </Box>
                            </Thumbnail>
                        </Cell>
                    );
                })}
            </Layout>
        </Box>
    );
};
