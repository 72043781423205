import { useCallback, useState } from 'react';
import {
    Box,
    Button,
    Card,
    Cell,
    FormField,
    Heading,
    Image,
    Input,
    Layout,
    MarketingPageLayout,
    MarketingPageLayoutContent,
    Text,
} from 'wix-style-react';
import MainCPA from '../../assets/main-cpa-image.png';
import Logo from '../../assets/logo.png';
import { classes } from './login.st.css';
import { useHistory } from 'react-router-dom';
import { API_INSTANCE } from '../../features/utils';

export const RequestResetPassword = () => {
    return (
        <Card>
            <MarketingPageLayout
                removeImageHorizontalPadding
                removeImageVerticalPadding
                className={classes.color}
                content={
                    <Box height="840px" verticalAlign="middle" direction="vertical">
                        <Box
                            align="center"
                            marginBottom={5}
                            verticalAlign="middle"
                            gap={4}
                            direction="vertical"
                        >
                            <img src={Logo} width={60} />
                            <div className={classes.welcomeHeader}>Forgot Password?</div>

                            <Box gap={2}>
                                <Heading appearance="H5" className={classes.logoHeader}>
                                    No worries, we&apos;ll send you reset instructions
                                </Heading>
                            </Box>
                        </Box>

                        <Box align="center" marginBottom={10} verticalAlign="middle">
                            <RequestResetPasswordArea />
                        </Box>
                        <MarketingPageLayoutContent />
                    </Box>
                }
                image={<Image transparent borderRadius={0} src={MainCPA} />}
            />
        </Card>
    );
};

export const CheckYourEmail = () => {
    const email = getParamFromUrlWithHash('email');
    const [reset, setReset] = useState(false);
    const history = useHistory();

    const onResetPassword = useCallback(async () => {
        try {
            await API_INSTANCE.post(`/api/v1/auth/send-reset-email`, {
                email,
            });
            setReset(true);
        } catch (err) {
            console.error(err);
        }
    }, [email]);
    return (
        <Card>
            <MarketingPageLayout
                removeImageHorizontalPadding
                removeImageVerticalPadding
                className={classes.color}
                content={
                    <Box height="840px" verticalAlign="middle" direction="vertical">
                        <Box
                            align="center"
                            marginBottom={5}
                            verticalAlign="middle"
                            gap={4}
                            direction="vertical"
                        >
                            <img src={Logo} width={60} />
                            <div className={classes.welcomeHeader}>Check your email</div>

                            <Box gap={2}>
                                <Heading appearance="H5" className={classes.logoHeader}>
                                    We sent a password reset link to <b>{email}</b>
                                </Heading>
                            </Box>
                        </Box>

                        <Box marginTop={2} align="center">
                            {reset ? (
                                <div className={classes.reset}>Resent reset password link</div>
                            ) : (
                                <div className={classes.backToSignInContainer}>
                                    Didn&apos;t receive an email?{' '}
                                    <span
                                        className={classes.backToSignIn}
                                        onClick={() => onResetPassword()}
                                    >
                                        Click to resend
                                    </span>
                                </div>
                            )}
                        </Box>
                        <Box marginTop={2}>
                            <div className={classes.backToSignInContainer}>
                                Back to{' '}
                                <span
                                    className={classes.backToSignIn}
                                    onClick={() => history.push('/auth/login')}
                                >
                                    Sign In
                                </span>
                            </div>
                        </Box>
                        <MarketingPageLayoutContent />
                    </Box>
                }
                image={<Image transparent borderRadius={0} src={MainCPA} />}
            />
        </Card>
    );
};
export const ResetPasswordConfirm = () => {
    const history = useHistory();
    return (
        <Card>
            <MarketingPageLayout
                removeImageHorizontalPadding
                removeImageVerticalPadding
                className={classes.color}
                content={
                    <Box height="840px" verticalAlign="middle" direction="vertical">
                        <Box
                            align="center"
                            marginBottom={5}
                            verticalAlign="middle"
                            gap={4}
                            direction="vertical"
                        >
                            <img src={Logo} width={60} />
                            <div className={classes.welcomeHeader}>Password Reset</div>

                            <Box className={classes.text} direction="vertical">
                                <span>Your password has been successfully reset</span>
                                <span>Click below to login</span>
                            </Box>
                        </Box>

                        <Box align="center">
                            <Button
                                size="large"
                                onClick={() => {
                                    history.push('/auth/login');
                                }}
                                className={classes.button}
                            >
                                Sign in
                            </Button>
                        </Box>
                        <MarketingPageLayoutContent />
                    </Box>
                }
                image={<Image transparent borderRadius={0} src={MainCPA} />}
            />
        </Card>
    );
};
export const ResetPassword = () => {
    return (
        <Card>
            <MarketingPageLayout
                removeImageHorizontalPadding
                removeImageVerticalPadding
                className={classes.color}
                content={
                    <Box height="840px" verticalAlign="middle" direction="vertical">
                        <Box
                            align="center"
                            marginBottom={5}
                            verticalAlign="middle"
                            gap={4}
                            direction="vertical"
                        >
                            <img src={Logo} width={60} />
                            <div className={classes.welcomeHeader}>Change Password</div>

                            <Box gap={2}>
                                <Heading appearance="H5" className={classes.logoHeader}>
                                    In order to <b>protect your account</b>, make sure your password
                                    is longer than 8 characters.
                                </Heading>
                            </Box>
                        </Box>

                        <Box align="center" marginBottom={10} verticalAlign="middle">
                            <ResetPasswordArea />
                        </Box>
                        <MarketingPageLayoutContent />
                    </Box>
                }
                image={<Image transparent borderRadius={0} src={MainCPA} />}
            />
        </Card>
    );
};
export const RequestResetPasswordArea = ({ initialEmail = '' }) => {
    const [clicked, setClicked] = useState(false);
    const [email, setEmail] = useState(initialEmail);
    const history = useHistory();

    const onResetPassword = useCallback(async () => {
        try {
            await API_INSTANCE.post(`/api/v1/auth/send-reset-email`, {
                email: email,
            });

            history.push(`/auth/check-email?email=${email}`);
        } catch (err) {
            console.error(err);
        }
    }, [email]);

    return (
        <Box align="center" verticalAlign="middle" className={classes.container}>
            <Layout>
                <Cell span={12}>
                    <Layout>
                        <Cell span={12}>
                            <Box gap={2} direction="vertical">
                                <FormField label="">
                                    <Input
                                        size="large"
                                        placeholder="Enter your email"
                                        value={email}
                                        name="email"
                                        onChange={(e) => setEmail(e.currentTarget.value)}
                                        status={clicked && !email ? 'error' : null}
                                        statusMessage="Enter an email"
                                    />
                                </FormField>
                            </Box>
                        </Cell>
                        <Cell span={12}>
                            <Box width="100%" align="center">
                                <Button
                                    size="large"
                                    onClick={() => {
                                        setClicked(true);
                                        onResetPassword();
                                    }}
                                    className={classes.button}
                                >
                                    Reset Password
                                </Button>
                            </Box>
                            <Box marginTop={2}>
                                <div className={classes.backToSignInContainer}>
                                    Back to{' '}
                                    <span
                                        className={classes.backToSignIn}
                                        onClick={() => history.push('/auth/login')}
                                    >
                                        Sign In
                                    </span>
                                </div>
                            </Box>
                        </Cell>
                    </Layout>
                </Cell>
            </Layout>
        </Box>
    );
};
function getParamFromUrlWithHash(key) {
    const urlSearchParams = new URLSearchParams(`?${window.location.hash.split('?')[1]}`);
    return urlSearchParams.get(key);
}

export const ResetPasswordArea = () => {
    const token = getParamFromUrlWithHash('token');
    const [error, setError] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [newConfirmPassword, setNewConfirmPassword] = useState('');
    const history = useHistory();

    const onResetPassword = useCallback(async () => {
        try {
            setError(null);
            await API_INSTANCE.post(`/api/v1/auth/reset-password`, {
                newPassword,
                token,
            });
            history.push('/auth/reset-password-confirm');
        } catch (err) {
            console.error(err);
            setError(err.response.data);
        }
    }, [newPassword, token]);

    return (
        <Box align="center" verticalAlign="middle" className={classes.container}>
            <Layout>
                <Cell span={12}>
                    <Layout>
                        <Cell span={12}>
                            <Box gap={2} direction="vertical">
                                <FormField label="">
                                    <Input
                                        size="large"
                                        placeholder="New password"
                                        value={newPassword}
                                        name="new-password"
                                        type="password"
                                        onChange={(e) => setNewPassword(e.currentTarget.value)}
                                        status={error ? 'error' : null}
                                    />
                                </FormField>
                                <FormField label="">
                                    <Input
                                        size="large"
                                        placeholder="Re-enter New Password"
                                        value={newConfirmPassword}
                                        name="confirm-password"
                                        type="password"
                                        onChange={(e) =>
                                            setNewConfirmPassword(e.currentTarget.value)
                                        }
                                        status={error ? 'error' : null}
                                    />
                                </FormField>
                            </Box>
                        </Cell>
                        <Cell span={12}>
                            <Box width="100%" align="center" gap={2} direction="vertical">
                                <Button
                                    size="large"
                                    onClick={() => {
                                        if (newPassword !== newConfirmPassword) {
                                            setError('Passwords do not match');
                                            return;
                                        }
                                        if (newPassword.length <= 8) {
                                            setError('Password should be more than 8 characters');
                                            return;
                                        }
                                        onResetPassword();
                                    }}
                                    className={classes.button}
                                >
                                    Change Password
                                </Button>
                                {error && <Box className={classes.error}>{error}</Box>}
                            </Box>
                            <Box marginTop={2}>
                                <div className={classes.backToSignInContainer}>
                                    Back to{' '}
                                    <span
                                        className={classes.backToSignIn}
                                        onClick={() => history.push('/auth/login')}
                                    >
                                        Sign In
                                    </span>
                                </div>
                            </Box>
                        </Cell>
                    </Layout>
                </Cell>
            </Layout>
        </Box>
    );
};
