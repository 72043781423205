import { FormField, Card, ToggleSwitch, Box, SectionHelper } from 'wix-style-react';

import { Controller } from 'react-hook-form';
import { PlanNotification } from '../../../../components/plan-notification';

export const Engagement = ({ control, setValue, watch }) => {
    const watchCreateTicketOrderConfirmation = watch('create_ticket_order_confirmation');
    const watchCreateEventPromotion = watch('create_event_promotion');

    return (
        <>
            <PlanNotification type="business" text="Event Engagement">
                <Box gap={2} direction="vertical">
                    <FormField label="Send a confirmation email to attendees?" infoContent="">
                        <Controller
                            control={control}
                            name="create_ticket_order_confirmation"
                            render={({ field }) => (
                                <ToggleSwitch
                                    {...field}
                                    onChange={(e) => {
                                        setValue(
                                            'create_ticket_order_confirmation',
                                            e.target.checked
                                        );
                                    }}
                                    checked={watchCreateTicketOrderConfirmation}
                                />
                            )}
                        />
                    </FormField>
                    <Box marginTop={2}>
                        <SectionHelper appearance="standard">
                            A.I will craft a highly professional confirmation email for your
                            attendees, which can be further customized according to your preferences
                            once the event has been created.
                        </SectionHelper>
                    </Box>
                </Box>
            </PlanNotification>
        </>
    );
};
