import { Badge, Box, InfoIcon, Table, Text } from 'wix-style-react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import type { Workflow } from '../../../types';
import { useAppDispatch } from '../../../hooks';
import { useEffect } from 'react';
import { fetchEventPromos, selectPromoList } from '../../../features/events-slice';
import { PromoCodeModal } from './promo-code-modal';
import { Copy } from './copy';

const getDiscountValue = (promo) => {
    if (promo.discount_percentage) return `${promo.discount_percentage}%`;
    return promo.discount_value;
};
const getStartDate = (promo, event) => {
    if (promo.start_date_selection === 'now') return `Now`;
    return moment(event.dateStart).tz(event.timezone).format('LLL');
};
export const PromoCodeTable = ({ event }) => {
    const dispatch = useAppDispatch();
    const promos = useSelector(selectPromoList) || [];

    useEffect(() => {
        dispatch(fetchEventPromos(event.id));
    }, [dispatch]);

    const columns = [
        {
            title: 'Code',
            render: (row) => (
                <Box>
                    <Box direction="vertical" gap={2}>
                        <Box>
                            <Copy text={row.name} />
                        </Box>

                        {row.is_shared && (
                            <Box align="right">
                                <Text skin="premium" size="tiny">
                                    Shared{' '}
                                    <InfoIcon
                                        size="small"
                                        content={'This promo code is shared across all events.'}
                                    />
                                </Text>
                            </Box>
                        )}
                    </Box>
                </Box>
            ),
            width: '30%',
        },
        {
            title: 'Discount',
            render: (row: Workflow) => getDiscountValue(row),
        },
        {
            title: 'Starts',
            render: (row: Workflow) => getStartDate(row, event),
        },
        {
            title: 'Uses',
            render: (row: Workflow) =>
                row.ticket_limit_selection === 'limited'
                    ? `${row.uses || 0}/${row.limit_amount}`
                    : row.uses || 0,
        },
        {
            render: (row) => <PromoCodeModal event={event} promo={row} key={row.id} />,
        },
    ];

    return (
        <>
            <Table
                showSelection
                //   rowDetails={row => renderRowDetails(row)}
                data={promos}
                columns={columns}
            >
                <Table.Content />
            </Table>
        </>
    );
};
