import moment from 'moment-timezone';
import { SFC } from 'react';
import { Controller } from 'react-hook-form';
import {
    Box,
    Card,
    Col,
    FormField,
    Row,
    Table,
    TableActionCell,
    Text,
    Tooltip,
    SectionHelper,
    ToggleSwitch,
} from 'wix-style-react';
import { InfoCircle } from 'wix-ui-icons-common';

export const EventSelection: SFC<any> = ({
    events,
    control,
    watch,
    campaign,
    setValue,
    setSelectedIds,
    selectedIds,
    expandOccurrences,
    setExpandOccurrences,
    type = 'automation',
}) => {
    const watchSubscribeAllEvents = watch('subscribe_all_events', campaign?.subscribe_all_events);

    const columns = [
        { title: 'Name', render: (row: any) => row.title },
        {
            title: 'Starts',
            render: (row: any) =>
                moment.tz(row.dateStart, row.timezone).format('Do MMMM YYYY @ HH:mm'),
        },
        { title: 'Status', render: (row: any) => row.status },
        {
            title: '',
            render: (row: any) =>
                row.expandable ? (
                    <TableActionCell
                        primaryAction={{
                            text: !row.expanded ? 'Expand' : 'Collapse',
                            skin: 'inverted',
                            onClick: (row.expanded = !row.expanded),
                        }}
                    />
                ) : (
                    ''
                ),
        },
    ];

    return (
        <Row>
            <Col span={12}>
                <Box
                    direction="vertical"
                    verticalAlign="space-between"
                    padding="24px 29px 27px"
                    marginTop="50px"
                    backgroundColor="white"
                    height="100%"
                    width="100%"
                    boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                    flexGrow={1}
                >
                    <Card>
                        <Card.Header
                            title="Event Selection"
                            subtitle={`Pick events to include in this engagement: use listed events or specify custom events`}
                        />
                        <Card.Divider />
                        <Card.Content>
                            <Box gap={2}>
                                <FormField
                                    label={
                                        <Box>
                                            Use Widget Visible Events
                                            <Tooltip
                                                appendTo="window"
                                                content={`Automatically assign events that are visible on your site to this engagement.`}
                                            >
                                                <InfoCircle />
                                            </Tooltip>
                                        </Box>
                                    }
                                >
                                    <Controller
                                        name="subscribe_all_events"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <ToggleSwitch
                                                    {...field}
                                                    checked={watchSubscribeAllEvents}
                                                    onChange={(e: any) =>
                                                        setValue(
                                                            'subscribe_all_events',
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </FormField>
                                {!watchSubscribeAllEvents && (
                                    <FormField
                                        label={
                                            <Box>
                                                Expand all occurrences
                                                <Tooltip
                                                    appendTo="window"
                                                    content={`Select specific occurrences for this ${type}. Selecting the parent will subscribe all occurrences`}
                                                >
                                                    <InfoCircle />
                                                </Tooltip>
                                            </Box>
                                        }
                                    >
                                        <ToggleSwitch
                                            checked={expandOccurrences}
                                            onChange={(e) =>
                                                setExpandOccurrences(!expandOccurrences)
                                            }
                                        />
                                    </FormField>
                                )}
                            </Box>
                            <Box marginTop={4}>
                                <SectionHelper appearance="success">
                                    {!watchSubscribeAllEvents
                                        ? `Attach this ${type} to specific events. If the event is recurring you can expand and select from the occurrences`
                                        : `Automatically attach this ${type} to all the events in your live widget`}
                                </SectionHelper>
                            </Box>
                            <Box marginTop={2}>
                                <Table
                                    showSelection={!watchSubscribeAllEvents}
                                    data={events}
                                    columns={columns}
                                    onSelectionChanged={(ids) => setSelectedIds(ids)}
                                    selectedIds={selectedIds}
                                >
                                    <Table.Content />
                                    {!events.length && (
                                        <Table.EmptyState
                                            subtitle={<Text>{'No Events Found'}</Text>}
                                        />
                                    )}
                                </Table>
                            </Box>
                        </Card.Content>
                    </Card>
                </Box>
            </Col>
        </Row>
    );
};
