import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import { useSelector } from 'react-redux';
import {
    fetchAttendeeDailyTransactions,
    selectAttendeeDailyTransactions,
    selectAttendeeInsightsLoading,
} from '../../features/attendees-slice';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks';
import { Box, Dropdown, Loader, Text } from 'wix-style-react';

const monthNames = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
];

function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + 'st';
    }
    if (j == 2 && k != 12) {
        return i + 'nd';
    }
    if (j == 3 && k != 13) {
        return i + 'rd';
    }
    return i + 'th';
}

function addNameProperty(inputData) {
    return inputData.map((obj, index) => {
        return {
            name: ordinal_suffix_of(index + 1),
            ...obj,
        };
    });
}

const formatDate = (dateString) => {
    const [month, day, year] = dateString.split('/');
    const dayWithoutLeadingZero = parseInt(day, 10).toString();

    const date = new Date(`${year}-${month}-${day}`);
    const monthString = date.toLocaleString('en-US', { month: 'long' });
    const yearString = date.toLocaleString('en-US', { year: 'numeric' });
    return {
        month: monthString.toLowerCase(),
        year: yearString,
        day: dayWithoutLeadingZero,
        key: dateString,
    };
};

const stringToColorHex = (str) => {
    // Hash the string using a simple hash function
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Convert the hash to a darker shade hexadecimal value
    const hex = ((hash & 0x00ffffff) | 0x003300).toString(16);

    // Pad the hexadecimal value with zeros if needed
    const paddedHex = '000000'.substring(0, 6 - hex.length) + hex;

    // Prepend a '#' symbol to the hexadecimal value
    const colorHex = '#' + paddedHex;

    return colorHex;
};

const getUniqueEvents = (attendeeTransactions) => {
    const uniqueIds = {};
    const deduplicatedAttendeeTransactions = attendeeTransactions.filter((transaction) => {
        const eventId = transaction?.event?.id;
        if (eventId && !uniqueIds[eventId]) {
            uniqueIds[eventId] = true;
            return true; // Include the first occurrence of the ID
        }
        return false; // Skip subsequent occurrences of the same ID
    });
    return deduplicatedAttendeeTransactions;
};
export const ChartsOverview = () => {
    const dispatch = useAppDispatch();
    const [chartData, setChartData] = useState([]);
    const [xAxisData, setXAxisData] = useState([]);
    const [opacity, setOpacity] = useState({});
    const monthlyOptions = [];
    for (let i = 0; i < 12; i++) {
        const monthName = new Date(0, i).toLocaleString('en-US', { month: 'long' });
        const id = monthName.toLowerCase(); // Set id to lowercase month name
        monthlyOptions.push({ id, value: monthName });
    }

    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('en-US', { month: 'long' }).toLowerCase();
    const currentYear = currentDate.getFullYear().toString();
    const yearlyOptions = [];
    for (let year = 2021; year <= currentYear; year++) {
        const id = String(year); // Set id to the year as a string
        yearlyOptions.push({ id, value: String(year) });
    }

    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const attendeeTransactions = useSelector(selectAttendeeDailyTransactions) || [];
    const insightsLoading = useSelector(selectAttendeeInsightsLoading);

    useEffect(() => {
        async function fetchData() {
            await dispatch(fetchAttendeeDailyTransactions());
        }
        void fetchData();
    }, [dispatch]);

    const updateChartData = () => {
        const startDate = new Date(selectedYear, monthNames.indexOf(selectedMonth), 1);
        const endDate = new Date(selectedYear, monthNames.indexOf(selectedMonth) + 1, 0);
        const daysInRange = [];
        for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
            daysInRange.push(date.getDate());
        }

        const groupedData = daysInRange.reduce((acc, day) => {
            acc[day] = {};
            return acc;
        }, {});

        const filteredEventsBySelectedDate = attendeeTransactions.filter((event) => {
            const eventDate = formatDate(event.month_day_year);

            return eventDate.month === selectedMonth && eventDate.year.toString() === selectedYear;
        });

        const uniqueEvents = getUniqueEvents(filteredEventsBySelectedDate);

        const opacityData = uniqueEvents.reduce(
            (res, curr) => ({ ...res, [curr.event.title]: 1 }),
            {}
        );
        setOpacity(opacityData);
        uniqueEvents.forEach(({ event }) => {
            const { title, created_at, start_date } = event;
            const startDate = new Date(created_at);
            if (
                startDate.getFullYear() === +selectedYear &&
                startDate.getMonth() < monthNames.indexOf(selectedMonth)
            ) {
                startDate.setMonth(monthNames.indexOf(selectedMonth));
                startDate.setDate(1); // Set it to the first day of the month
            }

            const eventEndDate = new Date(start_date);
            const isEndWithinMonth =
                eventEndDate.getFullYear() === +selectedYear &&
                eventEndDate.getMonth() === monthNames.indexOf(selectedMonth);

            const endDate = isEndWithinMonth
                ? eventEndDate
                : new Date(selectedYear, monthNames.indexOf(selectedMonth) + 1, 0);
            for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
                const day = date.getDate();

                if (!groupedData[day]) {
                    groupedData[day] = {};
                }

                groupedData[day][title] = 0;
            }
        });

        filteredEventsBySelectedDate.forEach((event) => {
            const eventDate = formatDate(event.month_day_year);
            const {
                event: { title: event_title },
                attendee_count,
            } = event;

            if (!groupedData[eventDate.day]) {
                groupedData[eventDate.day] = {};
            }

            groupedData[eventDate.day][event_title] = attendee_count;
        });

        setXAxisData(daysInRange);
        setChartData(Object.values(groupedData));
    };

    useEffect(() => {
        if (attendeeTransactions.length > 0) {
            updateChartData();
        }
    }, [selectedMonth, selectedYear, attendeeTransactions]);

    const eventNames = chartData.map((item) => {
        const { name, ...events } = item; // Destructure "name" and the rest of the properties
        return Object.keys(events); // Get the keys (event names)
    });

    const uniqueEventNames = [...new Set(Object.values(eventNames).flat())];

    // Calculate the maxim[um attendee count and round it up to the nearest 10 or 100
    const maxAttendeeCount = Math.max(
        ...chartData.map((item) => Math.max(...Object.values(item).slice(1)))
    );
    const roundedMaxAttendeeCount =
        maxAttendeeCount <= 10
            ? 10
            : maxAttendeeCount <= 100
            ? Math.ceil(maxAttendeeCount / 10) * 10
            : Math.ceil(maxAttendeeCount / 100) * 100;

    if (insightsLoading) {
        return (
            <Box align="center" marginTop={10}>
                <Loader statusMessage="Loading" />
            </Box>
        );
    }
    const handleMouseEnter = (o) => {
        const { dataKey } = o;
        setOpacity((prevOpacity) => {
            const updatedOpacity = { ...prevOpacity };

            for (const key in updatedOpacity) {
                if (key === dataKey) {
                    updatedOpacity[key] = 1;
                } else {
                    updatedOpacity[key] = 0.2;
                }
            }

            console.log(updatedOpacity);

            return updatedOpacity;
        });
    };

    const handleMouseLeave = (o) => {
        const { dataKey } = o;
        setOpacity((prevOpacity) => {
            const updatedOpacity = { ...prevOpacity };

            for (const key in updatedOpacity) {
                if (key === dataKey) {
                    updatedOpacity[key] = 0.2;
                } else {
                    updatedOpacity[key] = 1;
                }
            }

            return updatedOpacity;
        });
    };

    return (
        <Box direction="vertical" marginTop={10}>
            <Box direction="horizontal" marginBottom={10}>
                <Box verticalAlign="middle" marginRight={2}>
                    <Text>Showing data for</Text>
                </Box>
                <Dropdown
                    size="small"
                    selectedId={selectedMonth}
                    options={monthlyOptions}
                    onSelect={(option) => setSelectedMonth(option.id)}
                />
                <Dropdown
                    size="small"
                    selectedId={selectedYear}
                    options={yearlyOptions}
                    onSelect={(option) => setSelectedYear(option.id)}
                />
                <Box verticalAlign="middle" marginLeft={2}>
                    <Text>(month to date)</Text>
                </Box>
            </Box>

            <LineChart width={1500} height={600} data={addNameProperty(chartData)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tickFormatter={(value, index) => xAxisData[index]} />
                <YAxis domain={[0, roundedMaxAttendeeCount]} />
                <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
                <Tooltip />
                {uniqueEventNames.map((name: any, index: any) => {
                    return (
                        <Line
                            key={index + 1}
                            type="monotone"
                            dataKey={name}
                            stroke={stringToColorHex(name)}
                            name={name}
                            activeDot={{ r: 8 }}
                            strokeOpacity={opacity[name]}
                        />
                    );
                })}
            </LineChart>
        </Box>
    );
};
