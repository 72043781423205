import { SettingsHeader } from '../../../../components/settings-header';
import { ColorPicker } from '../color-picker';
import { FontColorPicker } from '../font-picker';

export const AddCalendarButtonDesign = ({ label }) => {
    return (
        <div>
            <SettingsHeader title={label} />

            <FontColorPicker
                title="Text"
                startWithSize={14}
                fontUpdateKey="_addCalendarButtonFont"
                startWithTheme="font_9"
                colorUpdateKey="btnAddCalendarForeground"
                startWithColor="color-5"
                fontMaxSize={20}
            />

            <ColorPicker
                title="Background"
                colorUpdateKey="btnAddCalendarBackground"
                startWithOpacity={0}
                startWithColor="color-1"
            />
        </div>
    );
};

export default AddCalendarButtonDesign;
