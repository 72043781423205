import { useState, useEffect, useCallback } from 'react';
import {
    Card,
    RadioGroup,
    Box,
    Text,
    Layout,
    Cell,
    FormField,
    SectionHelper,
    Dropdown,
} from 'wix-style-react';
import { isEmpty } from 'lodash';
import { API_INSTANCE, buildURL } from '../../../../features/utils';
import { selectConnectedAccounts, selectSiteData } from '../../../../features/account-slice';
import { useSelector } from 'react-redux';
import { IntegrationNotConnected } from './integration-not-connected';

export const ZohoAction = ({ setValue, watch, campaign }) => {
    const watchZohoAction = watch('zoho_action', campaign?.zoho_action || 'create-update-contact');
    const watchZohoMappings = watch('zoho_mappings', campaign?.zoho_mappings);

    const connectedAccounts = useSelector(selectConnectedAccounts);
    if (!connectedAccounts.hasOwnProperty('zoho')) {
        return <IntegrationNotConnected actionName="Zoho" />;
    }

    const handleSelection = useCallback(async (id) => {
        setValue('zoho_action', id);
    }, []);
    return (
        <Card>
            <Card.Content>
                <Layout>
                    <Cell span={6}>
                        <RadioGroup
                            selectionArea="always"
                            selectionAreaPadding="18px"
                            spacing="12px"
                            value={watchZohoAction}
                            onChange={handleSelection}
                        >
                            <RadioGroup.Radio value={'create-update-contact'}>
                                <Box direction="vertical">
                                    <Text weight="normal">Create or Update Contact</Text>
                                    <Text size="small">
                                        Create a new contact or update an existing contact based on
                                        email address.
                                    </Text>
                                </Box>
                            </RadioGroup.Radio>
                            <RadioGroup.Radio value={'create-meeting'}>
                                <Box direction="vertical">
                                    <Text weight="normal">Create Meeting</Text>
                                    <Text size="small">
                                        Automatically create Zoho meeting when you create a new
                                        event
                                    </Text>
                                </Box>
                            </RadioGroup.Radio>
                            <RadioGroup.Radio value={'create-update-contact-assign-event'}>
                                <Box direction="vertical">
                                    <Text weight="normal">Assign Contact to Zoho Meeting</Text>
                                    <Text size="small">
                                        Add contact as a Meeting participant. If the contact does
                                        not exist on Zoho, it is created. If the Meeting does not
                                        exist it is automatically created
                                    </Text>
                                </Box>
                            </RadioGroup.Radio>
                        </RadioGroup>
                    </Cell>
                    <Cell span={6}>
                        {['create-update-contact', 'create-update-contact-assign-event'].includes(
                            watchZohoAction
                        ) && (
                            <ContactFields
                                setValue={setValue}
                                watchZohoMappings={watchZohoMappings}
                            />
                        )}
                    </Cell>
                </Layout>
            </Card.Content>
        </Card>
    );
};

export const ContactFields = ({ setValue, watchZohoMappings }) => {
    const [zohoProps, setzohoProps] = useState([]);
    const [contractProps, setContractProps] = useState([]);

    const [mappings, setMappings] = useState({});
    const options = contractProps.map((prop) => ({ id: prop, value: prop }));
    const site = useSelector(selectSiteData);

    useEffect(() => {
        const fetchData = async () => {
            const zohoGetPropsUrl = `/api/v1/zoho/contact/properties`;
            const customerGetPropsUrl = `/api/v1/dashboard/customers/contact/properties`;
            const zohoGetProps = await API_INSTANCE.get(buildURL(site, zohoGetPropsUrl));
            const customerGetProps = await API_INSTANCE.get(buildURL(site, customerGetPropsUrl));
            setzohoProps(zohoGetProps.data);
            setContractProps(customerGetProps.data);
            const defaultMapping = zohoGetProps.data.reduce(
                (result: any, prop: { name: string }) => {
                    const sameProp = customerGetProps.data.find(
                        (customerProp: string) =>
                            customerProp.replace('_', '') === prop.name.toLowerCase()
                    );
                    if (sameProp) {
                        return { ...result, [prop.name]: sameProp };
                    }
                    return result;
                },
                {}
            );
            const map =
                watchZohoMappings && !isEmpty(watchZohoMappings)
                    ? watchZohoMappings
                    : defaultMapping;
            setValue('zoho_mappings', map);
            setMappings(map);
        };
        void fetchData();
    }, []);

    const handleSelection = useCallback(
        async (hsProp, attendeeProp) => {
            const newMapping = { ...mappings, [hsProp]: attendeeProp };
            setMappings(newMapping);
            setValue('zoho_mappings', newMapping);
        },
        [mappings]
    );
    return (
        <>
            <SectionHelper appearance="success">
                Create mapping between your Zoho properties and your Contact properties
            </SectionHelper>
            <Layout>
                <Cell span={12}>
                    <Box marginTop={2} direction="vertical">
                        <Layout>
                            {zohoProps.map((zohoProp) => (
                                <Cell span={6}>
                                    <FormField
                                        label={zohoProp.label}
                                        infoContent={
                                            <Box direction="vertical" gap="6px">
                                                <Text light size="small">
                                                    {zohoProp.description}
                                                </Text>
                                            </Box>
                                        }
                                    >
                                        <Dropdown
                                            border="round"
                                            options={options}
                                            clearButton
                                            selectedId={mappings[zohoProp.name]}
                                            onClear={() =>
                                                handleSelection(zohoProp.name, undefined)
                                            }
                                            onSelect={(option) =>
                                                handleSelection(zohoProp.name, option.id)
                                            }
                                        />
                                    </FormField>
                                </Cell>
                            ))}
                        </Layout>
                    </Box>
                </Cell>
            </Layout>
        </>
    );
};
