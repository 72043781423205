import { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
    EditEvent,
    CopyCreateEvent,
    CreateEvent,
    CreateEventPublic,
} from './modules/events/create-event';
import { CreateEventWithAI } from './modules/events/create-ai-event';
import { ListEvent } from './modules/events/list-events';
import { CampaignManage } from './modules/campaigns/create-campaign';
import { CampaignStats } from './modules/campaigns/campaign-stats';
import { CampaignLog } from './modules/campaigns/campaign-log';
import { AttendeeList } from './modules/attendees/attendee-list';
import { AttendeePublicView } from './modules/attendees/attendee-public-view';
import { EventAttendeeList } from './modules/attendees/event-attendee-list';
import { AttendeeView } from './modules/attendees/attendee-view';
import { OrderView } from './modules/attendees/order-view';
import { AttendeeCheckin } from './modules/attendees/attendee-checkin';
import { useAppDispatch } from './hooks';
import { fetchAccountApi, selectAccount, siteData } from './features/account-slice';
import { AdvancedEmailEditor } from './modules/campaigns/advanced-email-editor';
import { NotificationManage } from './modules/campaigns/create-notification';

import { SiteDashboard } from './modules/sites/list-sites';
import { IntegationsContainer } from './modules/integrations';
import { Login } from './modules/login';
import { Signup } from './modules/signup';
import { EmailVerification } from './modules/email-verification';
import {
    RequestResetPassword,
    ResetPassword,
    CheckYourEmail,
    ResetPasswordConfirm,
} from './modules/reset-password';
import { Pricing } from './modules/sites/pricing';
import { Widgets } from './modules/sites/widgets';
import { TeamInvite } from './modules/sites/team-invite';
import { WixWidgets } from './modules/sites/wix-widgets';
import { WixConnection } from './components/wix-connection';
import { WidgetSettings } from './modules/sites/settings';
import { getFonts } from './features/settings-slice';
import { Navigation } from './modules/events/navigation';
import { PromotionManage } from './modules/campaigns/create-promotion';
import { SiteSessionWrapper } from './modules/sites/site-session-wrapper';
import { EngagementPromotion } from './modules/engagement-promotion';
import { NewsletterOptIn } from './newsletter-opt-in';
import { AttendeeTickets } from './modules/tickets';
import { AttendeeTransactions } from './modules/transactions';
import { fetchSite, fetchWixSite } from './features/site-slice';
import { WixPricing } from './modules/sites/wix-pricing';
// import { LeaveReview } from './event-disovery-review';

export const App = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const account = useSelector(selectAccount);
    const queryParams = new URLSearchParams(window.location.search);
    const isCreateEvent = queryParams.get('is_create_event');
    useEffect(() => {
        const fetchData = async () => {
            try {
                let siteId = queryParams.get('site');
                if (window.brand === 'wix') {
                    const data = await dispatch(fetchWixSite());
                    siteId = data.payload.id;
                } else if (siteId) {
                    await dispatch(fetchSite(siteId));
                }
                const account = await dispatch(fetchAccountApi());

                if (
                    account.payload.must_verify_email &&
                    account.payload.email_verified_at === null
                ) {
                    history.push('/email-verification');
                }
            } catch (e) {
                if (
                    window.brand !== 'wix' &&
                    !history.location.pathname.includes('auth/') &&
                    !history.location.pathname.includes('/new-event') &&
                    !history.location.pathname.includes('/wix/pricing')
                ) {
                    history.push('/auth/login');
                }
            }
            if (window.brand !== 'wix') {
                dispatch(getFonts());
            }
        };
        void fetchData();
    }, [dispatch]);

    useEffect(() => {
        if (
            window.brand === 'wix' &&
            !siteData.compId &&
            !history.location.pathname.includes('/wix/pricing')
        ) {
            history.replace('/wix-widgets');
        }

        if (!isEmpty(account) && !isCreateEvent) {
            window.Intercom('boot', {
                app_id: 'm51ut11q',
                email: account.email,
                shop_id: account.shop_id,
                account_id: account.id,
            });
        }
    }, [account]);

    if (
        process.env.NODE_ENV === 'production' &&
        window.brand === 'wix' &&
        !account.has_app_updated
    ) {
        return <WixConnection />;
    }

    return (
        <div className="App">
            {window.brand === 'wix' && account.newsletter_opt_in === null && (
                <NewsletterOptIn account={account} />
            )}
            {/* {account.display_review_offer === null && <LeaveReview account={account} />} */}
            <Route exact path="/auth/login" component={Login} />
            <Route exact path="/auth/signup" component={Signup} />
            <Route exact path="/auth/request-reset-password" component={RequestResetPassword} />
            <Route exact path="/auth/reset-password" component={ResetPassword} />
            <Route exact path="/auth/check-email" component={CheckYourEmail} />
            <Route exact path="/email-verification" component={EmailVerification} />
            <Route exact path="/auth/reset-password-confirm" component={ResetPasswordConfirm} />
            <Route exact path="/sites" component={SiteDashboard} />
            <Route exact path="/my-tickets" component={AttendeeTickets} />
            <Route exact path="/wix-widgets" component={WixWidgets} />
            <Route exact path="/wix/pricing" component={WixPricing} />
            <Route
                exact
                path={`/new-event`}
                render={(props) => SiteWrapper(props, CreateEventPublic, true)}
            />
            <Route path="/sites">
                <SiteRoute />
            </Route>
            <Route path="/events">
                <WidgetDashboard />
            </Route>
            <Route
                exact
                path={`/campaigns/email-editor/:id`}
                render={(props) => <AdvancedEmailEditor {...props} />}
            />
            <Route exact path="/">
                {window.brand === 'wix' ? <Redirect to="/events" /> : <SiteDashboard />}
            </Route>
        </div>
    );
};

const SiteWrapper = (props: any, Component: any, noAuth: boolean) => {
    const { match } = props;
    return (
        <SiteSessionWrapper siteId={match?.params?.site_id} noAuth={noAuth}>
            <Component {...props} />
        </SiteSessionWrapper>
    );
};

const SiteRoute = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route
                exact
                path={`${path}/:site_id/widgets`}
                render={(props) => SiteWrapper(props, Widgets)}
            />
            <Route
                exact
                path={`${path}/:site_id/team`}
                render={(props) => SiteWrapper(props, TeamInvite)}
            />
        </Switch>
    );
};
const WidgetDashboard = () => {
    const { path } = useRouteMatch();

    return (
        <>
            <header className="App-header">
                <Navigation />
            </header>
            <Switch>
                <Route exact path={`${path}/`} render={(props) => SiteWrapper(props, ListEvent)} />
                <Route
                    exact
                    path={`${path}/integrations`}
                    render={(props) => SiteWrapper(props, IntegationsContainer)}
                />
                <Route
                    exact
                    path={`${path}/attendees/manage`}
                    component={AttendeeList}
                    render={(props) => SiteWrapper(props, AttendeeList)}
                />
                <Route
                    exact
                    path={`${path}/:site_id/billing`}
                    component={Pricing}
                    render={(props) => SiteWrapper(props, Pricing)}
                />
                <Route
                    exact
                    path={`${path}/attendees/manage/:id?`}
                    component={AttendeeView}
                    render={(props) => SiteWrapper(props, AttendeeView)}
                />
                <Route
                    exact
                    path={`${path}/orders/manage/:id`}
                    component={OrderView}
                    render={(props) => SiteWrapper(props, OrderView)}
                />
                <Route
                    exact
                    path={`${path}/attendees/checkin`}
                    render={(props) => SiteWrapper(props, AttendeeCheckin)}
                />
                <Route
                    exact
                    path={`${path}/attendees/view/:id?`}
                    component={AttendeePublicView}
                    render={(props) => SiteWrapper(props, AttendeePublicView)}
                />

                <Route
                    exact
                    path={`${path}/manage/:id`}
                    render={(props) => SiteWrapper(props, EditEvent)}
                />
                <Route
                    exact
                    path={`${path}/manage`}
                    render={(props) => SiteWrapper(props, CreateEvent)}
                />
                <Route
                    exact
                    path={`${path}/ai/manage`}
                    render={(props) => SiteWrapper(props, CreateEventWithAI)}
                />
                <Route
                    exact
                    path={`${path}/copy/:id`}
                    render={(props) => SiteWrapper(props, CopyCreateEvent)}
                />
                <Route
                    exact
                    path={`${path}/manage/:id/attendees`}
                    render={(props) => SiteWrapper(props, EventAttendeeList)}
                />

                <Route
                    exact
                    path={`${path}/engage-promote`}
                    render={(props) => SiteWrapper(props, EngagementPromotion)}
                />
                <Route
                    exact
                    path={`${path}/transactions`}
                    render={(props) => SiteWrapper(props, AttendeeTransactions)}
                />

                <Route
                    exact
                    path={`${path}/campaigns/manage/:id?`}
                    render={(props) => SiteWrapper(props, CampaignManage)}
                />
                <Route
                    exact
                    path={`${path}/promotions/manage/:id?`}
                    render={(props) => SiteWrapper(props, PromotionManage)}
                />
                <Route
                    exact
                    path={`${path}/campaigns/manage/:id/logs`}
                    render={(props) => SiteWrapper(props, CampaignLog)}
                />
                <Route
                    exact
                    path={`${path}/campaigns/manage/:id/stats`}
                    render={(props) => SiteWrapper(props, CampaignStats)}
                />
                <Route
                    exact
                    path={`${path}/campaigns/manage/:campaign_id/notification/:id?`}
                    render={(props) => SiteWrapper(props, NotificationManage)}
                />
                <Route
                    exact
                    path={`${path}/design`}
                    render={(props) => SiteWrapper(props, WidgetSettings)}
                />
            </Switch>
        </>
    );
};
