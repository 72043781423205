import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import screen from 'screen-size';
import { isEmpty } from 'lodash';

import EmailEditor from 'react-email-editor';
import { PlanNotification } from '../../../components/plan-notification';
import { selectAccount } from '../../../features/account-slice';
import { Box, Button, SectionHelper } from 'wix-style-react';
const features = {
    preheaderText: false,
    undoRedo: true,
    userUploads: true,
    imageEditor: true,
    spellChecker: true,
    stockImages: {
        enabled: true,
        safeSearch: true,
        defaultSearchTerm: 'event',
    },
};
const tools = {
    image: {
        enabled: true,
    },
    button: {
        enabled: true,
    },
    divider: {
        enabled: true,
    },
    heading: {
        enabled: true,
    },
    social: {
        enabled: true,
    },
    text: {
        enabled: true,
    },
    video: {
        enabled: true,
    },
    menu: {
        enabled: false,
    },
};
export const CampaignEmailEditor = ({ editorJson, advancedMessagedRef }) => {
    const emailEditorRef = useRef();
    const [loaded, setLoaded] = useState(false);

    const account = useSelector(selectAccount);

    const exportHtml = () => {
        return new Promise((resolve, reject) => {
            emailEditorRef.current.editor.exportHtml((data) => {
                resolve(data);
            });
        });
    };
    useEffect(() => {
        advancedMessagedRef.current = exportHtml;
    }, []);

    const onLoad = () => {
        // editor instance is created
        // you can load your template here;
        // const templateJson = {};
        if (!isEmpty(editorJson) && emailEditorRef.current) {
            emailEditorRef.current.editor.loadDesign(editorJson);
            setLoaded(true);
        }
    };

    const onReady = () => {
        if (!loaded && !isEmpty(editorJson)) {
            emailEditorRef.current.editor.loadDesign(editorJson);
            setLoaded(true);
        }
    };

    if (!account?.id) return <span />;
    const user = {
        id: +account?.id,
    };
    return (
        <PlanNotification type="business" text="advanced email editor">
            <EmailEditor
                options={{ user, features, displayMode: 'email' }}
                projectId={55743}
                tools={tools}
                ref={emailEditorRef}
                onLoad={onLoad}
                onReady={onReady}
            />
        </PlanNotification>
    );
};

export const EditorReceive = ({ campaign }) => {
    const [newEditorChange, setNewEditorChange] = useState(false);

    const openEditor = (url: string) => {
        const width = screen().x * 0.7;
        const height = screen().y * 0.8;
        const top = (window.outerHeight - height) / 2 + window.screenTop + 20;
        const left = (window.outerWidth - width) / 2 + window.screenLeft;

        const params = `scrollbars=no,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=${width},height=${height},left=${left},top=-${top}`;

        window.open(url, 'Ticket Spot Advanced Editor', params);
    };
    const receiveMessage = (event: MessageEvent) => {
        switch (event.data.type) {
            case 'button-save':
                setNewEditorChange(true);
                break;
            default:
        }
    };
    window.addEventListener('message', receiveMessage, false);

    if (!campaign) {
        return (
            <SectionHelper appearance="danger">
                Please save this engagement before accessing the advanced email editor.
            </SectionHelper>
        );
    }

    const baseUrl = window.location.href.split('#')[0]; // Get the URL without the hash
    const newFragment = `/campaigns/email-editor/${campaign.id}`;
    const url = `${baseUrl}#${newFragment}`;

    return (
        <>
            {newEditorChange && (
                <SectionHelper appearance="success">
                    Advanced Text Editor Changes Saved!
                </SectionHelper>
            )}
            <Box align="center">
                <Button skin="standard" onClick={() => openEditor(url)}>
                    Open Editor
                </Button>
            </Box>
        </>
    );
};
