import {
    Row,
    Col,
    Box,
    FormField,
    TimeInput,
    Checkbox,
    Dropdown,
    Cell,
    Layout,
    CheckToggle,
} from 'wix-style-react';

import { Controller } from 'react-hook-form';

import { classes } from '../../create-events.st.css';
import moment from 'moment-timezone';
import { TIMEZONE_OPTIONS } from '../../timezone-utils';
import { EventDatePicker } from '../date-picker';

const options = [
    { id: 'daily', value: 'Daily' },
    { id: 'weekly', value: 'Weekly' },
    { id: 'monthly', value: 'Monthly' },
    { id: 'yearly', value: 'Yearly' },
];

const timezoneOptions = TIMEZONE_OPTIONS;

export const EventTimeDate = ({ watch, control, setValue, errors, getValues }) => {
    const watchRepeatingEvents = watch('is_repeating_event', false);
    const watchAllDayEvent = watch('all_day_Event', false);

    const watchRepeatOn = watch('repeat_on', 'daily');
    const watchTimezone = watch('timezone');

    return (
        <>
            <Row>
                <Col span={12}>
                    <Row>
                        <Col span={7}>
                            <div className={classes.margin}>
                                <FormField label="Start">
                                    <Controller
                                        control={control}
                                        name="date_start"
                                        render={({ field }) => (
                                            <EventDatePicker
                                                initialValue={field.value}
                                                propName={'date_start'}
                                                setValue={setValue}
                                                popoverAppendTo="window"
                                            />
                                        )}
                                    ></Controller>
                                </FormField>
                            </div>
                        </Col>
                        <Col span={5}>
                            <div className={classes.margin}>
                                <FormField label="Start Time (24 hrs)">
                                    <Controller
                                        name="time_start"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <TimeInput
                                                    {...field}
                                                    disableAmPm
                                                    width="auto"
                                                    dashesWhenDisabled
                                                    disabled={watchAllDayEvent}
                                                    onChange={(date) => {
                                                        console.log('d-------atedate', date);
                                                        setValue('time_start', date);
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </FormField>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={7}>
                            <div className={classes.margin}>
                                <FormField label="Until">
                                    <Controller
                                        control={control}
                                        name="date_end"
                                        rules={{
                                            validate: () => {
                                                const date_startVal = moment(
                                                    getValues('date_start')
                                                ).format('L');
                                                const date_endVal = moment(
                                                    getValues('date_end')
                                                ).format('L');
                                                const isValid = moment(date_startVal).isAfter(
                                                    moment(date_endVal)
                                                );
                                                return !isValid;
                                            },
                                        }}
                                        render={({ field }) => (
                                            <EventDatePicker
                                                {...field}
                                                initialValue={field.value}
                                                propName={'date_end'}
                                                setValue={setValue}
                                                popoverAppendTo="window"
                                            />
                                        )}
                                    ></Controller>
                                </FormField>
                            </div>
                        </Col>

                        <Col span={5}>
                            <div className={classes.margin}>
                                <FormField label="End Time (24 hrs)">
                                    <Controller
                                        name="time_end"
                                        control={control}
                                        render={({ field }) => (
                                            <TimeInput
                                                {...field}
                                                disableAmPm
                                                dashesWhenDisabled
                                                width="auto"
                                                disabled={watchAllDayEvent}
                                                onChange={(date) => setValue('time_end', date)}
                                            />
                                        )}
                                    />
                                </FormField>
                            </div>
                        </Col>
                    </Row>

                    {errors.date_end && (
                        <small className={classes.error}>
                            Start date cannot be after the end date
                        </small>
                    )}
                </Col>
            </Row>

            <Row>
                <Col span={6}>
                    <FormField label="Timezone">
                        <Controller
                            control={control}
                            name="timezone"
                            render={({ field }) => (
                                <Dropdown
                                    {...field}
                                    popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                                    border="standard"
                                    placeholder={moment.tz.guess()}
                                    options={timezoneOptions}
                                    selectedId={watchTimezone}
                                    onSelect={(option) => setValue('timezone', '' + option.id)}
                                />
                            )}
                        ></Controller>
                    </FormField>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <Box direction="vertical" gap={4}>
                        <Controller
                            control={control}
                            name="is_repeating_event"
                            render={({ field }) => (
                                <Checkbox
                                    size="medium"
                                    {...field}
                                    onChange={(e) =>
                                        setValue('is_repeating_event', e.target.checked)
                                    }
                                    checked={field.value}
                                >
                                    Will this event repeat on other days?
                                </Checkbox>
                            )}
                        ></Controller>
                    </Box>
                </Col>
                <Col span={5}>
                    {watchRepeatingEvents && (
                        <Controller
                            control={control}
                            name="repeat_on"
                            render={({ field }) => (
                                <Dropdown
                                    {...field}
                                    popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                                    border="standard"
                                    placeholder="Daily"
                                    options={options}
                                    selectedId={watchRepeatOn || 'daily'}
                                    onSelect={(option) => setValue('repeat_on', '' + option.id)}
                                />
                            )}
                        ></Controller>
                    )}

                    {watchRepeatingEvents && watchRepeatOn === 'weekly' && (
                        <>
                            <Box marginTop={2}>On these days</Box>

                            <Box marginTop={2}>
                                <Layout cols={7}>
                                    <Cell span={1}>
                                        <FormField label="Su" labelPlacement="top">
                                            <Controller
                                                control={control}
                                                name="repeat_day_sunday"
                                                render={({ field }) => {
                                                    return (
                                                        <CheckToggle
                                                            {...field}
                                                            checked={field.value}
                                                        />
                                                    );
                                                }}
                                            />
                                        </FormField>
                                    </Cell>
                                    <Cell span={1}>
                                        <FormField label="Mo" labelPlacement="top">
                                            <Controller
                                                control={control}
                                                name="repeat_day_monday"
                                                render={({ field }) => (
                                                    <CheckToggle {...field} checked={field.value} />
                                                )}
                                            />
                                        </FormField>{' '}
                                    </Cell>
                                    <Cell span={1}>
                                        <FormField label="Tu" labelPlacement="top">
                                            <Controller
                                                control={control}
                                                name="repeat_day_tuesday"
                                                render={({ field }) => (
                                                    <CheckToggle {...field} checked={field.value} />
                                                )}
                                            />
                                        </FormField>{' '}
                                    </Cell>
                                    <Cell span={1}>
                                        <FormField label="We" labelPlacement="top">
                                            <Controller
                                                control={control}
                                                name="repeat_day_wednesday"
                                                render={({ field }) => (
                                                    <CheckToggle {...field} checked={field.value} />
                                                )}
                                            />
                                        </FormField>{' '}
                                    </Cell>
                                    <Cell span={1}>
                                        <FormField label="Th" labelPlacement="top">
                                            <Controller
                                                control={control}
                                                name="repeat_day_thursday"
                                                render={({ field }) => (
                                                    <CheckToggle {...field} checked={field.value} />
                                                )}
                                            />
                                        </FormField>{' '}
                                    </Cell>
                                    <Cell span={1}>
                                        <FormField label="Fi" labelPlacement="top">
                                            <Controller
                                                control={control}
                                                name="repeat_day_friday"
                                                render={({ field }) => (
                                                    <CheckToggle {...field} checked={field.value} />
                                                )}
                                            />
                                        </FormField>{' '}
                                    </Cell>
                                    <Cell span={1}>
                                        <FormField label="Sa" labelPlacement="top">
                                            <Controller
                                                control={control}
                                                name="repeat_day_saturday"
                                                render={({ field }) => (
                                                    <CheckToggle {...field} checked={field.value} />
                                                )}
                                            />
                                        </FormField>{' '}
                                    </Cell>
                                </Layout>
                            </Box>
                        </>
                    )}
                </Col>
            </Row>
        </>
    );
};
