import { useEffect, useCallback, useState } from 'react';
import * as Icons from 'wix-ui-icons-common';
import { Button, Checkbox, Loader } from 'wix-style-react';
import { fetchSettingsApi, selectSettings } from '../../features/settings-slice';
import { useAppDispatch, useAppSelector } from '../../hooks';
// import WidgetUpdater from '../modules/widget_updater';
import { classes } from './google-calendars.st.css';
import { API_INSTANCE, buildURL } from '../../features/utils';
import { useSelector } from 'react-redux';
import { selectSiteData } from '../../features/account-slice';

export const GoogleCalendarsComponent = ({ settings, googleCalendars, handleSave }) => {
    const [saved, setSaved] = useState(false);
    const [calendars, setCalendars] = useState(
        googleCalendars.map((calendar: any) => {
            const cal = settings.googleCalendars.find((c) => c.id === calendar.id);
            return cal ? { ...calendar, enabled: cal ? cal.enabled : false } : calendar;
        })
    );

    const handleChange = useCallback(
        async (calendarId: number, newValue: boolean) => {
            const newCalendars = calendars.map((calendar: any) =>
                calendar.id === calendarId ? { ...calendar, enabled: newValue } : calendar
            );
            setCalendars(newCalendars);
        },
        [calendars]
    );

    return (
        <div>
            <div className={classes.calendarDropdown}>
                {calendars.map((calendar, index: number) => {
                    return (
                        <Checkbox
                            key={index}
                            className={classes.checkbox}
                            checked={calendar.enabled}
                            onChange={(e) => handleChange(calendar.id, e.target.checked)}
                        >
                            {calendar.summary}
                        </Checkbox>
                    );
                })}
            </div>
            <div className={classes.buttonContainer}>
                <Button
                    onClick={() => {
                        setSaved(true);
                        setTimeout(() => setSaved(false), 2000);
                        handleSave(calendars);
                    }}
                >
                    Save
                </Button>

                {saved && (
                    <>
                        <Icons.Check />
                    </>
                )}
            </div>
        </div>
    );
};

export const GoogleCalendars = () => {
    const [calendars, setCalendars] = useState(null);
    const [loading, setLoading] = useState(true);

    const dispatch = useAppDispatch();
    const site = useSelector(selectSiteData);

    const settings = useAppSelector(selectSettings);

    useEffect(() => {
        const fetchData = async () => {
            const url = `/api/v1/config/google/calendars`;
            const { data } = await API_INSTANCE.get(buildURL(site, url));
            await dispatch(fetchSettingsApi());
            setCalendars(data);
            setLoading(false);
        };
        fetchData();
    }, [dispatch]);

    const handleSave = useCallback(
        async (updateCalendars) => {
            const url = `/api/v1/config`;
            const googleCalendars = updateCalendars.map((cal) => ({
                id: cal.id,
                summary: cal.id,
                enabled: cal.enabled,
                primary: cal.primary,
            }));
            await API_INSTANCE.put(buildURL(site, url), { googleCalendars });
        },
        [calendars]
    );

    if (loading || !settings || !calendars) {
        return <Loader size="small" />;
    }
    return (
        <>
            <GoogleCalendarsComponent
                googleCalendars={calendars}
                settings={settings}
                handleSave={handleSave}
            />
        </>
    );
};
