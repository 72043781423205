import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, InputArea } from 'wix-style-react';
import { selectSiteData } from '../../features/account-slice';
import { API_INSTANCE, buildURL } from '../../features/utils';
import { classes } from './ticket-spice-widget-html-apply.st.css';

export const TicketSpiceWidgetHtmlApply = ({ integrations }) => {
    const [widgetHtml, setWidgetHtml] = useState(integrations['ticketSpice'].widgetHtml);

    const site = useSelector(selectSiteData);

    const handleSave = useCallback(async () => {
        await API_INSTANCE.put(buildURL(site, `/api/v1/ticket-spice/users`), { widgetHtml });
    }, [widgetHtml]);
    const placeholder =
        '<iframe src="https://s3-us-west-2.amazonaws.com/assets.webconnex.com/widgets/tickets/widget.html?source=https://api.webconnex.com/v1/widgets/2311b0d4e0f0b37919d1cbcda5e2caa8"></iframe>';
    return (
        <div>
            <Box marginTop={2}>
                <p className={classes.text}>
                    Follow the{' '}
                    <a href="https://docs.geteventviewer.com/integrations/ticket-spice#buying-tickets-from-your-wix-site">
                        Tickets Widget instructions
                    </a>{' '}
                    to allow ticket purchasing from your site
                </p>
            </Box>
            <InputArea
                placeholder={placeholder}
                size="large"
                defaultValue={integrations['ticketSpice'].widgetHtml}
                value={widgetHtml}
                onChange={(e) => setWidgetHtml(e.currentTarget.value)}
                minHeight={150}
            />

            <Box marginTop={2} align="center">
                <Button onClick={handleSave}>Save</Button>
            </Box>
        </div>
    );
};
