import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Cell, Layout, FormField, DatePicker, TimeInput } from 'wix-style-react';

export const RescheduleForm = ({ control, setValue, watch }) => {
    // status=  selectedOccurrence?.status,
    const watchTimeStart = watch('dateStart');
    const watchTimeEnd = watch('dateEnd');
    useEffect(() => {
        if (watchTimeStart.getTime() > watchTimeEnd.getTime()) {
            setValue('dateEnd', watchTimeStart);
        }
    }, [watchTimeStart]);
    useEffect(() => {
        if (watchTimeStart.getTime() > watchTimeEnd.getTime()) {
            setValue('dateStart', watchTimeEnd);
        }
    }, [watchTimeEnd]);

    return (
        <Layout>
            <Cell span={6}>
                <FormField label="Start">
                    <Controller
                        control={control}
                        name="dateStart"
                        render={({ field }) => {
                            return (
                                <DatePicker
                                    {...field}
                                    onChange={(date: Date) => setValue('dateStart', date)}
                                    popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                                    width="auto"
                                />
                            );
                        }}
                    />
                </FormField>
            </Cell>
            <Cell span={6}>
                <FormField label="Time">
                    <Controller
                        name="timeStart"
                        control={control}
                        render={({ field }) => {
                            return (
                                <TimeInput
                                    {...field}
                                    width="auto"
                                    defaultValue={field.value}
                                    onChange={(date) => setValue('timeStart', date)}
                                />
                            );
                        }}
                    />
                </FormField>
            </Cell>

            <Cell span={6}>
                <FormField label="End">
                    <Controller
                        control={control}
                        name="dateEnd"
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                onChange={(date: Date) => setValue('dateEnd', date)}
                                popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                                width="auto"
                            />
                        )}
                    ></Controller>
                </FormField>
            </Cell>
            <Cell span={6}>
                <FormField label="Time">
                    <Controller
                        name="timeEnd"
                        control={control}
                        render={({ field }) => (
                            <TimeInput
                                {...field}
                                width="auto"
                                defaultValue={field.value}
                                onChange={(date) => setValue('timeEnd', date)}
                            />
                        )}
                    />
                </FormField>
            </Cell>
        </Layout>
    );
};
