import { useState, useEffect, useCallback } from 'react';
import {
    Card,
    RadioGroup,
    Box,
    Text,
    Layout,
    Cell,
    FormField,
    SectionHelper,
    Dropdown,
    CheckToggle,
    Button,
} from 'wix-style-react';
import IntegrationsIcon from 'wix-ui-icons-common/Integrations';
import { isEmpty, uniq } from 'lodash';
import { API_INSTANCE, buildURL } from '../../../../features/utils';
import { selectConnectedAccounts, selectSiteData } from '../../../../features/account-slice';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IntegrationNotConnected } from './integration-not-connected';

export const HubSpotAction = ({ setValue, watch, campaign }) => {
    const watchHubspotMappings = watch('hubspot_mappings', campaign?.hubspot_mappings);

    const watchHubSpotAction = watch(
        'hubspot_action',
        campaign?.hubspot_action || 'create-update-contact'
    );

    const watchWorkflowSelections = watch(
        'hubspot_workflow_selections',
        campaign?.hubspot_workflow_selections
    );
    const connectedAccounts = useSelector(selectConnectedAccounts);
    if (!connectedAccounts.hasOwnProperty('hubspot')) {
        return <IntegrationNotConnected actionName="HubSpot" />;
    }

    const handleSelection = useCallback(async (id) => {
        setValue('hubspot_action', id);
    }, []);
    return (
        <Card>
            <Card.Content>
                <Layout>
                    <Cell span={6}>
                        <RadioGroup
                            selectionArea="always"
                            selectionAreaPadding="18px"
                            spacing="12px"
                            value={watchHubSpotAction}
                            onChange={handleSelection}
                        >
                            <RadioGroup.Radio value={'contact-to-workflow'}>
                                <Box direction="vertical">
                                    <Text weight="normal">Add Contact to Workflow</Text>
                                    <Text size="small">
                                        Enroll a contact in a specific workflow.
                                    </Text>
                                </Box>
                            </RadioGroup.Radio>
                            <RadioGroup.Radio value={'create-update-contact'}>
                                <Box direction="vertical">
                                    <Text weight="normal">Create or Update Contact</Text>
                                    <Text size="small">
                                        Create a new contact or update an existing contact based on
                                        email address.
                                    </Text>
                                </Box>
                            </RadioGroup.Radio>
                        </RadioGroup>
                    </Cell>
                    <Cell span={6}>
                        {watchHubSpotAction === 'create-update-contact' && (
                            <ContactFields
                                setValue={setValue}
                                watchHubspotMappings={watchHubspotMappings}
                            />
                        )}
                        {watchHubSpotAction === 'contact-to-workflow' && (
                            <WorkflowSelection
                                setValue={setValue}
                                watchWorkflowSelections={watchWorkflowSelections}
                            />
                        )}
                    </Cell>
                </Layout>
            </Card.Content>
        </Card>
    );
};

export const ContactFields = ({ setValue, watchHubspotMappings }) => {
    const [hubspotProps, setHubspotProps] = useState([]);
    const [contractProps, setContractProps] = useState([]);

    const [mappings, setMappings] = useState({});
    const options = contractProps.map((prop) => ({ id: prop, value: prop }));
    const site = useSelector(selectSiteData);

    useEffect(() => {
        const fetchData = async () => {
            const hubSpotGetPropsUrl = `/api/v1/hubspot/contact/properties`;
            const customerGetPropsUrl = `/api/v1/dashboard/customers/contact/properties`;
            const hubSpotGetProps = await API_INSTANCE.get(buildURL(site, hubSpotGetPropsUrl));
            const customerGetProps = await API_INSTANCE.get(buildURL(site, customerGetPropsUrl));
            setHubspotProps(hubSpotGetProps.data);
            setContractProps(customerGetProps.data);
            const defaultMapping = hubSpotGetProps.data.reduce(
                (result: any, prop: { name: string }) => {
                    const sameProp = customerGetProps.data.find(
                        (customerProp: string) =>
                            customerProp.replace('_', '') === prop.name.toLowerCase()
                    );
                    if (sameProp) {
                        return { ...result, [prop.name]: sameProp };
                    }
                    return result;
                },
                {}
            );
            const map =
                watchHubspotMappings && !isEmpty(watchHubspotMappings)
                    ? watchHubspotMappings
                    : defaultMapping;
            setValue('hubspot_mappings', map);
            setMappings(map);
        };
        void fetchData();
    }, []);

    const handleSelection = useCallback(
        async (hsProp, attendeeProp) => {
            const newMapping = { ...mappings, [hsProp]: attendeeProp };
            setMappings(newMapping);
            setValue('hubspot_mappings', newMapping);
        },
        [mappings]
    );
    return (
        <>
            <SectionHelper appearance="success">
                Create mapping between your HubSpot properties and your Contact properties
            </SectionHelper>
            <Layout>
                <Cell span={12}>
                    <Box marginTop={2} direction="vertical">
                        <Layout>
                            {hubspotProps.map((hubspotProp) => (
                                <Cell span={6}>
                                    <FormField
                                        label={hubspotProp.label}
                                        infoContent={
                                            <Box direction="vertical" gap="6px">
                                                <Text light size="small">
                                                    {hubspotProp.description}
                                                </Text>
                                            </Box>
                                        }
                                    >
                                        <Dropdown
                                            border="round"
                                            options={options}
                                            clearButton
                                            selectedId={mappings[hubspotProp.name]}
                                            onClear={() =>
                                                handleSelection(hubspotProp.name, undefined)
                                            }
                                            onSelect={(option) =>
                                                handleSelection(hubspotProp.name, option.id)
                                            }
                                        />
                                    </FormField>
                                </Cell>
                            ))}
                        </Layout>
                    </Box>
                </Cell>
            </Layout>
        </>
    );
};
export const WorkflowSelection = ({ setValue, watchWorkflowSelections }) => {
    const [workflows, setWorkflows] = useState([]);
    const site = useSelector(selectSiteData);

    useEffect(() => {
        const fetchData = async () => {
            const hubSpotGetWorkflowsUrl = `/api/v1/hubspot/workflows`;
            const hubSpotWorkflows = await API_INSTANCE.get(buildURL(site, hubSpotGetWorkflowsUrl));
            setWorkflows(hubSpotWorkflows.data);
        };
        void fetchData();
    }, []);

    const handleSelection = useCallback(
        async (workflow, checked) => {
            if (!checked) {
                setValue(
                    'hubspot_workflow_selections',
                    newSelections.filter((s) => s !== workflow.id)
                );
            } else {
            }
            const newSelections = watchWorkflowSelections.concat(workflow.id);
            setValue('hubspot_workflow_selections', uniq(newSelections));
        },
        [watchWorkflowSelections]
    );
    return (
        <>
            <SectionHelper appearance="success">
                Select the HubSpot workflows to enroll contacts that enter this campaign
            </SectionHelper>
            <Layout>
                <Cell span={12}>
                    <Box marginTop={2} direction="vertical">
                        <Layout>
                            {workflows.map((workflow) => (
                                <Cell span={12}>
                                    <Box marginTop={2} width="100%">
                                        <FormField label={workflow.name} labelPlacement="right">
                                            <CheckToggle
                                                checked={watchWorkflowSelections.includes(
                                                    workflow.id
                                                )}
                                                onChange={(e) =>
                                                    handleSelection(
                                                        workflow,
                                                        e.currentTarget.checked
                                                    )
                                                }
                                            />
                                        </FormField>
                                    </Box>
                                </Cell>
                            ))}
                        </Layout>
                    </Box>
                </Cell>
            </Layout>
        </>
    );
};
