import { useCallback, useState } from 'react';
import { Box, Cell, Input, Layout, Slider } from 'wix-style-react';

export const SliderWithInput = ({ onChange, max, initialValue }) => {
    const [value, setValue] = useState(initialValue);
    const handleChange = useCallback(
        (newData) => {
            setValue(newData);
            onChange(newData);
        },
        [onChange]
    );
    return (
        <Box marginLeft={2}>
            <Layout>
                <Cell span={8}>
                    <Slider
                        onChange={(val) => handleChange(val)}
                        min={1}
                        max={max}
                        value={value}
                        displayMarks={false}
                    />
                </Cell>
                <Cell span={3}>
                    <Box gap="12px">
                        <Input value={value} onChange={(e) => handleChange(e.target.value)} />
                    </Box>
                </Cell>
            </Layout>
        </Box>
    );
};
