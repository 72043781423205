import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import 'primeflex/primeflex.css';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import PLAN_FEATURES from './plan-feature-list.json';

import { Box, Loader, Heading } from 'wix-style-react';
import {
    fetchPlans,
    fetchWixSubscriptionApi,
    selectPlans,
    selectSubscription,
} from '../../features/site-slice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectSiteData } from '../../features/account-slice';
import { useSelector } from 'react-redux';

export const nameMapping = {
    featured: 'professional',
};

const urlSearchParams = new URLSearchParams(window.location.search);
let instance = urlSearchParams.get('instance');

if (!instance) {
    const hashFragment = window.location.hash.split('?')[1] || '';
    const hashParams = new URLSearchParams(hashFragment);
    instance = hashParams.get('instance');
}

const PlanCard = ({
    plan = {},
    isYearly,
    selectedQuota,
    handleQuotaChange,
    volumnOptions,
    selectedEnterprisePlan,
    siteId,
    siteData,
    plans,
    subscription,
}) => {
    return (
        <div className="col-12 lg:col-3" key={plan.name}>
            <div className="p-3 h-full">
                <div
                    className="shadow-2 p-3 h-full flex flex-column surface-card"
                    style={{ borderRadius: '6px' }}
                >
                    <div className="text-900 font-medium text-xl mb-2">{plan.name}</div>
                    <div className="text-600">{plan.description}</div>
                    <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                    <div className="flex align-items-center">
                        <span className="font-bold text-2xl text-900">
                            ${isYearly ? plan.yearly_price : plan.monthly_price}
                        </span>
                        <span className="ml-2 font-medium text-600">per month</span>
                    </div>
                    <div className="flex align-items-center mt-2 ">
                        <span className="font-light font-italic text-600">
                            billed {isYearly ? 'yearly' : 'monthly'}
                        </span>
                    </div>
                    <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                    <ul className="list-none p-0 m-0 flex-grow-1">
                        {plan.name === 'Enterprise' && (
                            <li className="flex align-items-center mb-3">
                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                Up to
                                <Dropdown
                                    value={selectedQuota}
                                    onChange={(e) => handleQuotaChange(e.value)}
                                    options={volumnOptions}
                                    optionLabel="name"
                                    className="ml-1 mr-1"
                                    checkmark={true}
                                    highlightOnSelect={false}
                                />
                                Tickets Per Month
                            </li>
                        )}
                        {plan.benefits.map((benefit, benefitIndex) => (
                            <li className="flex align-items-center mb-3" key={benefitIndex}>
                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                <span className="text-900">{benefit.name}</span>
                            </li>
                        ))}
                    </ul>
                    <hr className="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />

                    <form
                        action={`${process.env.API_SERVICE_URL}/api/v1/dashboard/billing/wix/sessions?instance=${instance}&brand=wix`}
                        method="POST"
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        <input
                            type="hidden"
                            name="priceId"
                            value={
                                plan.name === 'Enterprise'
                                    ? isYearly
                                        ? selectedEnterprisePlan.yearly_price_id
                                        : selectedEnterprisePlan.monthly_price_id
                                    : isYearly
                                    ? plan.yearly_price_id
                                    : plan.monthly_price_id
                            }
                        />
                        <input type="hidden" name="widgetId" value={siteData.widget} />
                        <input type="hidden" name="billingType" value={plan.billing_type} />
                        <input type="hidden" name="siteId" value={siteId} />
                        <input type="hidden" name="isYearly" value={isYearly} />

                        {subscription && (
                            <SubscriptionComponent
                                subscription={subscription}
                                plans={plans}
                                plan={plan.name === 'Enterprise' ? selectedEnterprisePlan : plan}
                            />
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};
const getDisplayedPlans = (plans) => {
    const volumePlans = plans.filter((plan) => plan.volume_based);
    const corePlans = plans.filter((plan) => !plan.volume_based);
    return [...corePlans, volumePlans[0]];
};

const PricingPlans = ({ plans = [], isYearly, subscription, siteId, siteData }) => {
    const volumePlans = plans.filter((plan) => plan.volume_based);
    const volumnOptions = volumePlans.map((plan) => ({
        name: plan.monthly_attendee_quota,
        code: plan.monthly_attendee_quota,
    }));
    const [selectedQuota, setSelectedQuota] = useState(volumnOptions[0]);
    const [selectedEnterprisePlan, setSelectedEnterprisePlan] = useState(volumePlans[0]);

    const handleQuotaChange = (value) => {
        const quota = parseInt(value.code, 10);
        setSelectedQuota(value);
        const updatedPlan = volumePlans.find((plan) => plan.monthly_attendee_quota === quota);
        setSelectedEnterprisePlan(updatedPlan);
    };

    return (
        <>
            {plans
                .filter((plan) => !plan.volume_based)
                .map((plan) => (
                    <PlanCard
                        plan={plan}
                        plans={plans}
                        isYearly={isYearly}
                        selectedQuota={selectedQuota}
                        handleQuotaChange={handleQuotaChange}
                        volumnOptions={volumnOptions}
                        selectedEnterprisePlan={selectedEnterprisePlan}
                        siteId={siteId}
                        siteData={siteData}
                        subscription={subscription}
                    />
                ))}
            <PlanCard
                plan={selectedEnterprisePlan}
                isYearly={isYearly}
                plans={plans}
                selectedQuota={selectedQuota}
                handleQuotaChange={handleQuotaChange}
                volumnOptions={volumnOptions}
                selectedEnterprisePlan={selectedEnterprisePlan}
                siteId={siteId}
                siteData={siteData}
                subscription={subscription}
            />
        </>
    );
};

export const WixPricing = () => {
    const { site_id: siteId } = useParams<{ site_id: string }>();
    const [isLoading, setLoading] = useState(true);
    const [isYearly, setIsYearly] = useState(false);

    const siteData = useSelector(selectSiteData);

    const subscription = useAppSelector(selectSubscription);
    const plans = useAppSelector(selectPlans);

    const dispatch = useAppDispatch();

    useEffect(() => {
        async function fetchData() {
            await dispatch(fetchPlans());
            instance && (await dispatch(fetchWixSubscriptionApi(instance)));
            setLoading(false);
        }
        fetchData();
    }, [dispatch, siteId]); // Or [] if effect doesn't need props or state

    if (isLoading) {
        return (
            <Box align="center">
                <Loader />
            </Box>
        );
    }

    return (
        <Box direction="vertical">
            <Box>
                <div className="surface-ground px-4 py-8 md:px-6 lg:px-8" style={{ width: '100%' }}>
                    <div className="text-900 font-bold text-6xl mb-4 text-center">
                        Ticket Spot Pricing Plans
                    </div>
                    <div className="text-700 text-xl mb-6 text-center line-height-3">
                        Choose the Perfect Plan to Unlock Your Event Success!
                    </div>

                    <div className="flex align-items-center justify-content-center text-900 mb-1">
                        <span className="font-semibold mr-3">Monthly</span>
                        <InputSwitch checked={isYearly} onChange={(e) => setIsYearly(e.value)} />
                        <span className="ml-3">Yearly</span>
                    </div>
                    <div className="flex align-items-center justify-content-center text-500 mb-6">
                        (Save up to 25%)
                    </div>

                    <div className="grid">
                        <PricingPlans
                            plans={plans}
                            isYearly={isYearly}
                            subscription={subscription}
                            siteId={siteId}
                            siteData={siteData}
                        />
                    </div>
                </div>
            </Box>
            <Box align="center" background={'#F9FAFB'}>
                <Heading appearance="H2" style={{ textDecoration: 'underline' }}>
                    Compare Plans
                </Heading>
            </Box>
            <Box>
                <div className="surface-ground px-4 py-8 md:px-6 lg:px-8" style={{ width: '100%' }}>
                    <table
                        className="w-full white-space-nowrap"
                        style={{
                            borderCollapse: 'collapse',
                            borderSpacing: '0',
                            background: 'white',
                        }}
                    >
                        <thead>
                            <tr>
                                <td className="surface-100 p-3">
                                    <div className="text-xl text-900 mb-2">Features</div>
                                </td>
                                {getDisplayedPlans(plans).map((plan) => (
                                    <td className="surface-100 p-3 text-center">
                                        <div className="text-900 text-xl font-medium mb-3">
                                            {plan?.name}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(PLAN_FEATURES).map(([category, features]) => (
                                <>
                                    <div className="text-900 font-bold text-xl mb-5 mt-5 ml-3">
                                        {category}
                                    </div>
                                    {features.map((feature, index) => (
                                        <tr key={index}>
                                            <td className="p-3 text-900 font-medium border-bottom-1 border-200">
                                                {feature.feature}
                                            </td>
                                            {feature.plans.map((plan, planIndex) => (
                                                <td
                                                    key={planIndex}
                                                    className="text-center p-medium border-bottom-1 border-200"
                                                >
                                                    <i
                                                        className={
                                                            plan === 'Y'
                                                                ? 'text-green-500 pi pi-check'
                                                                : plan === 'N'
                                                                ? 'text-500 pi pi-times'
                                                                : 'text-900'
                                                        }
                                                    >
                                                        {plan !== 'Y' && plan !== 'N' ? plan : ''}
                                                    </i>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Box>
        </Box>
    );
};

function SubscriptionComponent({ subscription, plan, plans }) {
    // Find the index of the current subscription plan in the plans array
    const currentPlanIndex = plans.findIndex((p) => p.billing_type === subscription?.billing_type);
    // Find the index of the target plan in the plans array
    const targetPlanIndex = plans.findIndex((p) => p.billing_type === plan.billing_type);

    // If the target plan is less than or equal to the current plan, don't render anything
    if (targetPlanIndex < currentPlanIndex) {
        return null;
    }

    // If the target plan is different and a higher tier than the current plan
    if (plan.billing_type !== subscription?.billing_type && targetPlanIndex > currentPlanIndex) {
        return (
            <Box direction="vertical" gap={2} align="center">
                <Button
                    type="submit"
                    className="p-3 w-full mt-auto"
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    Upgrade
                </Button>
            </Box>
        );
    }

    // Default to rendering the "Current" button for other cases
    return (
        <Button type="submit" text raised disabled>
            Current
        </Button>
    );
}
