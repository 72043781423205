import { FC, useEffect, useState } from 'react';
import { DatePicker } from 'wix-style-react';
import moment from 'moment';

export const EventDatePicker: FC<any> = ({
    isDisabled,
    initialValue,
    propName,
    setValue,
    excludePastDates = false,
    filterDate = () => true,
    popoverAppendTo = 'parent',
    onChange,
}) => {
    const [dateValue, setDateValue] = useState(initialValue);
    const [closed, setClosed] = useState(false);

    useEffect(() => {
        if (closed) setValue(propName, dateValue);
    }, [dateValue, closed]);
    return (
        <DatePicker
            value={initialValue}
            disableKeyboardType={false}
            autoFocus={false}
            disabled={isDisabled}
            width="auto"
            popoverProps={{ appendTo: popoverAppendTo, zIndex: 5000 }}
            excludePastDates={excludePastDates}
            dateFormatV2={(v) => {
                return moment(v).format('ll');
            }}
            filterDate={filterDate}
            onClose={() => {
                setClosed(true);
            }}
            onChange={(date: Date) => {
                setDateValue(date);
                setClosed(false);
                onChange && onChange(date);
            }}
        />
    );
};
