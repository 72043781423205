import { Box, Card, Tabs } from 'wix-style-react';

import { classes } from './style.st.css';
import { fetchAttendeeMonthlyTransactions } from '../../features/attendees-slice';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks';
import { fetchSettingsApi } from '../../features/settings-slice';
import { AttendeeTransactionsBreakdown } from './breakdown';
import { ChartsOverview } from './charts-overview';
import { PlanNotification } from '../../components/plan-notification';
import { fetchPlans } from '../../features/site-slice';

export const AttendeeTransactions = () => {
    const dispatch = useAppDispatch();
    const [activeId, setActiveId] = useState(2);

    useEffect(() => {
        async function fetchData() {
            await dispatch(fetchAttendeeMonthlyTransactions());
            await dispatch(fetchPlans());
            await dispatch(fetchSettingsApi());
        }
        void fetchData();
    }, [dispatch]);

    return (
        <div className={classes.container}>
            <Card className={classes.fullWidth}>
                <Card.Header
                    title="Attendee Insights"
                    subtitle="Analyze and Track Monthly Attendances. View How Much Quota Is Remaining for the Month."
                />
                <Card.Content>
                    <PlanNotification type="business" text="attendee insights">
                        <Tabs
                            activeId={activeId}
                            onClick={(value) => setActiveId(value.id as number)}
                            items={[
                                {
                                    id: 1,
                                    title: (
                                        <Box direction="horizontal" gap={1} align="center">
                                            Overview
                                        </Box>
                                    ),
                                },
                                {
                                    id: 2,
                                    title: (
                                        <Box direction="horizontal" gap={1} align="center">
                                            Transactions
                                        </Box>
                                    ),
                                },
                            ]}
                        />
                        {activeId === 1 && <ChartsOverview />}
                        {activeId === 2 && <AttendeeTransactionsBreakdown />}
                    </PlanNotification>
                </Card.Content>
            </Card>
        </div>
    );
};
