import {
    Box,
    Card,
    Button,
    Dropdown,
    Row,
    Col,
    Search,
    Table,
    TableActionCell,
    TableToolbar,
    MarketingPageLayout,
    MarketingPageLayoutContent,
    Badge,
    Loader,
    EmptyState,
    Image,
    Text,
    InfoIcon,
} from 'wix-style-react';
import moment from 'moment';
import * as Icons from 'wix-ui-icons-common';
import { classes } from './create-campaign.st.css';
import AddSmall from 'wix-ui-icons-common/AddSmall';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import {
    fetchCampaignsApi,
    selectCampaignsList,
    selectCampaignIsLoading,
    selectTriggersList,
    deleteCampaignsApi,
    stopBulkCampaignsApi,
    deleteBulkCampaignsApi,
    startBulkCampaignsApi,
} from '../../features/campaign-slice';
import type { Campaign } from '../../types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useMemo, useEffect } from 'react';
import { PlanNotification } from '../../components/plan-notification';
import { buildURL } from '../../features/utils';
import { selectSiteData } from '../../features/account-slice';
import { DeleteConfirmation } from '../../components/delete-confirmation';
import NoAttendees from '../../assets/no-attendees.gif';

const NoCampaign = () => {
    const history = useHistory();
    const site = useSelector(selectSiteData);

    return (
        <Box verticalAlign="middle" align="center">
            <Card>
                <MarketingPageLayout
                    horizontalSize="medium"
                    verticalSize="medium"
                    content={
                        <Box verticalAlign="middle" align="center" height="260px">
                            <MarketingPageLayoutContent
                                size="medium"
                                title="Auto-Engage Attendees"
                                content="Drive Attendance and Increase Sales with Multi-Channel Messaging: Email, SMS, and More!"
                                actions={
                                    <Button
                                        size="large"
                                        onClick={() =>
                                            history.push(buildURL(site, `/events/campaigns/manage`))
                                        }
                                    >
                                        Get Started
                                    </Button>
                                }
                            />
                        </Box>
                    }
                />
            </Card>
        </Box>
    );
};
const applyFilters = (
    campaign: Campaign,
    searchText: string,
    selectedStatus: string,
    selectedType: string,
    eventScope: string
) => {
    const eventBasedFilters = [
        (campaign: Campaign) =>
            selectedStatus === 'all' || (campaign.enabled && selectedStatus === 'started'),
        (campaign: Campaign) => selectedType === 'all' || campaign.trigger_type === selectedType,
        (campaign: Campaign) => campaign?.name?.toLowerCase()?.includes(searchText.toLowerCase()),
        (campaign: Campaign) => {
            if (eventScope === 'all') {
                return true; // Do not filter based on subscribe_all_events
            } else if (eventScope === 'all-events') {
                return campaign?.subscribe_all_events === true;
            } else if (eventScope === 'specific') {
                return campaign?.subscribe_all_events === false;
            }
            return true;
        },
    ];
    return eventBasedFilters.every((filterFunc) => filterFunc(campaign));
};
export const CampaignList = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [activeSearch, setActiveSearch] = useState('');
    const [selectedTrigger, setTriggerFilter] = useState('all');
    const [selectedStatus, setStatusFilter] = useState('all');
    const [eventScope, setEventScope] = useState('all-events');

    const campaigns = useSelector(selectCampaignsList);
    const isLoading = useSelector(selectCampaignIsLoading);
    const site = useSelector(selectSiteData);

    const triggers = useAppSelector(selectTriggersList) || [];

    const selectedCampaigns = useMemo(() => {
        return campaigns
            .filter((c) => c.schedule_type !== 3)
            .filter((campaign) =>
                applyFilters(campaign, activeSearch, selectedStatus, selectedTrigger, eventScope)
            );
    }, [activeSearch, selectedTrigger, selectedStatus, campaigns, eventScope]);

    useEffect(() => {
        dispatch(fetchCampaignsApi());
    }, [dispatch]);

    const deleteCallback = useCallback(
        (id) => {
            dispatch(deleteCampaignsApi(id));
        },
        [dispatch]
    );

    const _renderSearch = (expandable) => {
        return (
            <Search
                expandable={expandable}
                onChange={(e) => {
                    setActiveSearch(e.target.value);
                }}
            />
        );
    };

    const _renderMainToolbar = () => {
        return (
            <Card>
                <TableToolbar>
                    <TableToolbar.ItemGroup position="start">
                        <TableToolbar.Item>
                            <Box>
                                <TableToolbar.Label>Status</TableToolbar.Label>
                                <Box width="150px" marginLeft={2}>
                                    <Dropdown
                                        closeOnSelect
                                        id="status"
                                        options={[
                                            { id: 'all', value: 'All' },
                                            { id: 'started', value: 'Started' },
                                            { id: 'stopped', value: 'Stopped' },
                                        ]}
                                        selectedId={selectedStatus}
                                        onSelect={(selectedOption) => {
                                            setStatusFilter(selectedOption.id);
                                        }}
                                        roundInput
                                    />
                                </Box>
                            </Box>
                        </TableToolbar.Item>
                        <TableToolbar.Item>
                            <TableToolbar.Label>
                                Trigger
                                <span style={{ width: '150px' }}>
                                    <Dropdown
                                        id="trigger"
                                        closeOnSelect
                                        options={[{ id: 'all', value: 'All' }].concat(
                                            triggers.map(({ value, name }) => ({
                                                id: value,
                                                value: name,
                                            }))
                                        )}
                                        selectedId={selectedTrigger}
                                        roundInput
                                        onSelect={(selectedOption) => {
                                            setTriggerFilter(selectedOption.id);
                                        }}
                                    />
                                </span>
                            </TableToolbar.Label>
                        </TableToolbar.Item>
                        <TableToolbar.Item>
                            <TableToolbar.Label>
                                Event Scope
                                <span style={{ width: '250px' }}>
                                    <Dropdown
                                        id="event-scope"
                                        closeOnSelect
                                        options={[
                                            { id: 'all', value: 'All' },
                                            { id: 'all-events', value: 'All-Event Engagements' },
                                            { id: 'specific', value: 'Event-Specific Engagements' },
                                        ]}
                                        selectedId={eventScope}
                                        onSelect={(selectedOption) => {
                                            setEventScope(selectedOption.id);
                                        }}
                                        roundInput
                                    />
                                </span>
                            </TableToolbar.Label>
                        </TableToolbar.Item>
                    </TableToolbar.ItemGroup>
                    <TableToolbar.ItemGroup position="end">
                        <TableToolbar.Item>{_renderSearch(false)}</TableToolbar.Item>
                    </TableToolbar.ItemGroup>
                </TableToolbar>
            </Card>
        );
    };

    const getNotificationAction = (campaign) => {
        if (campaign.trigger_type !== 'NEW_ORDER') return [];
        let url = `/events/campaigns/manage/${campaign.id}/notification`;
        if (campaign.notification) {
            url = `/events/campaigns/manage/${campaign.id}/notification/${campaign?.notification?.id}`;
        }

        return {
            icon: <Icons.Notification />,
            text: `${campaign.notification ? 'Edit' : 'Create'} Notification`,
            onClick: () => {
                history.push(buildURL(site, url));
            },
        };
    };

    const secondaryActions = (row: Campaign) => {
        return [
            {
                icon: <Icons.Delete />,
                text: 'Delete',
                onClick: () => deleteCallback(row.id),
            },
            {
                icon: <Icons.List />,
                text: 'Logs',
                onClick: () =>
                    history.push(buildURL(site, `/events/campaigns/manage/${row.id}/logs`)),
            },
            {
                icon: <Icons.Statistics />,
                text: 'Analytics',
                onClick: () =>
                    history.push(buildURL(site, `/events/campaigns/manage/${row.id}/stats`)),
            },
        ].concat(getNotificationAction(row));
    };

    const primaryAction = (row) => ({
        icon: <Icons.Edit />,
        text: 'Edit',
        onClick: () => {
            history.push(buildURL(site, `/events/campaigns/manage/${row.id}`));
        },
    });
    const columns = [
        {
            title: 'Name',
            render: (row: Campaign) => (
                <Box direction="vertical">
                    <Text>{row.name}</Text>
                    {row.is_shared && (
                        <Box width={120}>
                            <Badge skin="neutralLight">
                                Shared{' '}
                                <InfoIcon
                                    size="small"
                                    content={'This engagement is applied to all widgets'}
                                />
                            </Badge>
                        </Box>
                    )}
                </Box>
            ),
        },

        {
            title: 'Type',
            width: '20%',
            render: (row: Campaign) => triggers.find((t) => t.value === row.trigger_type)?.name,
        },
        {
            title: 'Status',
            width: '20%',
            render: (row: Campaign) =>
                row.enabled ? (
                    <Badge skin="neutralSuccess" size="small">
                        Started
                    </Badge>
                ) : (
                    <Badge skin="warningLight" size="small">
                        Stopped
                    </Badge>
                ),
        },
        {
            title: 'Created',
            width: '20%',
            render: (row: Campaign) => moment(row.created_at).format('MMMM Do YYYY, h:mm:ss a'),
        },
        {
            width: '10%',
            render: (row: Attendee) => <TableActionCell primaryAction={primaryAction(row)} />,
        },
        {
            width: '5%',
            render: (row: Campaign) => <TableActionCell secondaryActions={secondaryActions(row)} />,
        },
    ];

    if (isLoading) {
        return (
            <Box marginTop={5} align="center" justifyContent="center">
                <Loader size="medium" />
            </Box>
        );
    }
    const ActionsToolbar = ({ selectedCount, getSelectedIds }) => (
        <TableToolbar>
            <TableToolbar.ItemGroup position="start">
                <TableToolbar.Item>
                    <TableToolbar.SelectedCount>{`${selectedCount} Selected`}</TableToolbar.SelectedCount>
                </TableToolbar.Item>
            </TableToolbar.ItemGroup>
            <TableToolbar.ItemGroup position="end">
                <TableToolbar.Item layout="button">
                    <Button
                        skin="light"
                        prefixIcon={<Icons.PlayFilled />}
                        onClick={() => dispatch(startBulkCampaignsApi(getSelectedIds()))}
                    >
                        Start
                    </Button>
                </TableToolbar.Item>
                <TableToolbar.Item layout="button">
                    <Button
                        skin="light"
                        prefixIcon={<Icons.StopFilled />}
                        onClick={() => dispatch(stopBulkCampaignsApi(getSelectedIds()))}
                    >
                        Stop
                    </Button>
                </TableToolbar.Item>
                <TableToolbar.Item layout="button">
                    <DeleteConfirmation
                        buttonText="Delete"
                        placement="top"
                        successClick={() => dispatch(deleteBulkCampaignsApi(getSelectedIds()))}
                    />
                </TableToolbar.Item>
            </TableToolbar.ItemGroup>
        </TableToolbar>
    );
    return (
        <Box direction="vertical" width={'100%'} marginTop={4}>
            {campaigns.length === 0 ? (
                <NoCampaign />
            ) : (
                <Row className={classes.row}>
                    <Col span={12} className={classes.box}>
                        <Box
                            align="center"
                            direction="vertical"
                            verticalAlign="space-between"
                            padding="24px 29px 27px"
                            borderRadius="8px"
                            backgroundColor="white"
                            height="100%"
                            width="100%"
                            boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                            flexGrow={1}
                        >
                            <Card className={classes.fullWidth}>
                                <PlanNotification type="featured" text="attendee engagement">
                                    <Box align="right" marginBottom={2}>
                                        <Button
                                            onClick={() =>
                                                history.push(
                                                    buildURL(site, `/events/campaigns/manage`)
                                                )
                                            }
                                            prefixIcon={<AddSmall />}
                                        >
                                            Add Engagement
                                        </Button>
                                    </Box>
                                    {campaigns?.filter((c) => c.schedule_type !== 3)?.length >
                                        0 && (
                                        <Table
                                            showSelection
                                            data={selectedCampaigns}
                                            columns={columns}
                                        >
                                            <Table.ToolbarContainer>
                                                {(selectionContext: any) =>
                                                    selectionContext.selectedCount === 0
                                                        ? _renderMainToolbar()
                                                        : ActionsToolbar({ ...selectionContext })
                                                }
                                            </Table.ToolbarContainer>

                                            <Table.Content />
                                        </Table>
                                    )}
                                    {selectedCampaigns.length === 0 && (
                                        <EmptyState
                                            theme="page-no-border"
                                            image={
                                                <Image
                                                    width="100px"
                                                    height="100px"
                                                    src={NoAttendees}
                                                    transparent
                                                />
                                            }
                                            title="No Engagements Found"
                                        />
                                    )}
                                </PlanNotification>
                            </Card>
                        </Box>
                    </Col>
                </Row>
            )}
        </Box>
    );
};
