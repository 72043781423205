import {
    Row,
    Col,
    Box,
    FormField,
    TimeInput,
    Dropdown,
    Cell,
    Layout,
    CheckToggle,
    SectionHelper,
} from 'wix-style-react';

import { Controller } from 'react-hook-form';

import { classes } from '../events/create-events.st.css';
import moment from 'moment-timezone';
import { EventDatePicker } from '../events/components/date-picker';
import { TIMEZONE_OPTIONS } from '../events/timezone-utils';
import { useEffect } from 'react';
import { getMomentDate } from '../events/create-event';

export const DateTimeSelector = ({ scheduled, watch, control, setValue, errors, getValues }) => {
    const watchTimezone = watch('scheduled.timezone', scheduled?.timezone || moment.tz.guess());
    const watchStartDate = watch('scheduled.date_start');
    const watchEndDate = watch('scheduled.date_end');

    const watchTimeStart = watch(
        'scheduled.time_start',
        moment(scheduled?.date_start).add(1, 'hour')
    );
    const watchTimeEnd = watch('scheduled.time_end', moment(scheduled?.date_end).add(1, 'week'));

    useEffect(() => {
        if (!scheduled?.timezone) setValue('scheduled.timezone', moment.tz.guess());
        if (!scheduled?.repeat_on) setValue('scheduled.repeat_on', 'daily');
        if (scheduled) {
            setValue(
                'scheduled.time_start',
                getMomentDate(scheduled?.date_start, scheduled?.timezone)
            );
            setValue('scheduled.time_end', getMomentDate(scheduled?.date_end, scheduled?.timezone));

            scheduled?.repeating_days?.map((v) =>
                setValue(`scheduled.repeat_day_${v.day}`, v.value)
            );
        }
    }, [scheduled]);

    return (
        <>
            <Row>
                <Col span={12}>
                    <Row>
                        <Col span={7}>
                            <div className={classes.margin}>
                                <FormField label="When to start schedule">
                                    <Controller
                                        control={control}
                                        name="scheduled.date_start"
                                        render={({ field }) => (
                                            <EventDatePicker
                                                initialValue={field.value}
                                                propName={'scheduled.date_start'}
                                                setValue={setValue}
                                                excludePastDates
                                                filterDate={(a: Date) => {
                                                    const msBetweenDates = Math.abs(
                                                        a.getTime() - new Date().getTime()
                                                    );

                                                    const daysBetweenDates =
                                                        msBetweenDates / (24 * 60 * 60 * 1000);

                                                    return daysBetweenDates < 30;
                                                }}
                                            />
                                        )}
                                    ></Controller>
                                </FormField>
                            </div>
                        </Col>
                        <Col span={5}>
                            <div className={classes.margin}>
                                <FormField label="Time (24 hrs)">
                                    <Controller
                                        name="scheduled.time_start"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <TimeInput
                                                    {...field}
                                                    disableAmPm
                                                    width="auto"
                                                    dashesWhenDisabled
                                                    defaultValue={watchTimeStart}
                                                    onChange={(date) =>
                                                        setValue('scheduled.time_start', date)
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                </FormField>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <FormField label="Timezone">
                        <Controller
                            control={control}
                            name="scheduled.timezone"
                            render={({ field }) => (
                                <Dropdown
                                    {...field}
                                    border="standard"
                                    placeholder={moment.tz.guess()}
                                    options={TIMEZONE_OPTIONS}
                                    selectedId={watchTimezone}
                                    onSelect={(option) =>
                                        setValue('scheduled.timezone', '' + option.id)
                                    }
                                />
                            )}
                        ></Controller>
                    </FormField>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={7}>
                            <div className={classes.margin}>
                                <FormField label="When to stop schedule">
                                    <Controller
                                        control={control}
                                        name="scheduled.date_end"
                                        rules={{
                                            validate: (date) => {
                                                if (moment(date).isBefore()) {
                                                    return false;
                                                }
                                                const dateStartVal = moment(
                                                    getValues('scheduled.date_start')
                                                ).format('L');
                                                const dateEndVal = moment(date).format('L');
                                                const isValid = moment(dateStartVal).isAfter(
                                                    moment(dateEndVal)
                                                );
                                                return !isValid;
                                            },
                                        }}
                                        render={({ field }) => (
                                            <EventDatePicker
                                                {...field}
                                                initialValue={field.value}
                                                propName={'scheduled.date_end'}
                                                setValue={setValue}
                                                excludePastDates
                                            />
                                        )}
                                    ></Controller>
                                </FormField>
                            </div>
                        </Col>

                        <Col span={5}>
                            <div className={classes.margin}>
                                <FormField label="Time (24 hrs)">
                                    <Controller
                                        name="scheduled.time_end"
                                        control={control}
                                        render={({ field }) => (
                                            <TimeInput
                                                {...field}
                                                disableAmPm
                                                dashesWhenDisabled
                                                defaultValue={watchTimeEnd}
                                                width="auto"
                                                onChange={(date) =>
                                                    setValue('scheduled.time_end', date)
                                                }
                                            />
                                        )}
                                    />
                                </FormField>
                            </div>
                        </Col>
                    </Row>

                    {errors?.scheduled?.date_end && (
                        <small className={classes.error}>
                            End date should be after the start date and set to a future date from
                            today
                        </small>
                    )}
                </Col>
            </Row>

            <Row>
                <Col span={5}>
                    <Box height={20} />

                    <SectionHelper appearance="success">
                        Your events will be scheduled to run starting from{' '}
                        {moment(watchStartDate).format('MMM Do YYYY')} and ending on{' '}
                        {moment(watchEndDate).format('MMM Do YYYY')}. To avoid spamming your social
                        media accounts events will be evenly spaced out between your start and end
                        times for the days you selected.
                    </SectionHelper>

                    <>
                        <Box marginTop={2}>On these days</Box>

                        <Box marginTop={2}>
                            <Layout cols={7}>
                                <Cell span={1}>
                                    <FormField label="Su" labelPlacement="top">
                                        <Controller
                                            control={control}
                                            name="scheduled.repeat_day_sunday"
                                            render={({ field }) => {
                                                return (
                                                    <CheckToggle {...field} checked={field.value} />
                                                );
                                            }}
                                        />
                                    </FormField>
                                </Cell>
                                <Cell span={1}>
                                    <FormField label="Mo" labelPlacement="top">
                                        <Controller
                                            control={control}
                                            name="scheduled.repeat_day_monday"
                                            render={({ field }) => (
                                                <CheckToggle {...field} checked={field.value} />
                                            )}
                                        />
                                    </FormField>{' '}
                                </Cell>
                                <Cell span={1}>
                                    <FormField label="Tu" labelPlacement="top">
                                        <Controller
                                            control={control}
                                            name="scheduled.repeat_day_tuesday"
                                            render={({ field }) => (
                                                <CheckToggle {...field} checked={field.value} />
                                            )}
                                        />
                                    </FormField>{' '}
                                </Cell>
                                <Cell span={1}>
                                    <FormField label="We" labelPlacement="top">
                                        <Controller
                                            control={control}
                                            name="scheduled.repeat_day_wednesday"
                                            render={({ field }) => (
                                                <CheckToggle {...field} checked={field.value} />
                                            )}
                                        />
                                    </FormField>{' '}
                                </Cell>
                                <Cell span={1}>
                                    <FormField label="Th" labelPlacement="top">
                                        <Controller
                                            control={control}
                                            name="scheduled.repeat_day_thursday"
                                            render={({ field }) => (
                                                <CheckToggle {...field} checked={field.value} />
                                            )}
                                        />
                                    </FormField>{' '}
                                </Cell>
                                <Cell span={1}>
                                    <FormField label="Fi" labelPlacement="top">
                                        <Controller
                                            control={control}
                                            name="scheduled.repeat_day_friday"
                                            render={({ field }) => (
                                                <CheckToggle {...field} checked={field.value} />
                                            )}
                                        />
                                    </FormField>{' '}
                                </Cell>
                                <Cell span={1}>
                                    <FormField label="Sa" labelPlacement="top">
                                        <Controller
                                            control={control}
                                            name="scheduled.repeat_day_saturday"
                                            render={({ field }) => (
                                                <CheckToggle {...field} checked={field.value} />
                                            )}
                                        />
                                    </FormField>{' '}
                                </Cell>
                            </Layout>
                        </Box>
                    </>
                </Col>
            </Row>
        </>
    );
};
