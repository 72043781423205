import { FONT_FAMILY_DEFAULT, FontManager } from '@samuelmeuli/font-manager';
import themeFonts from '../modules/settings/design/theme-google-fonts.json';
import coreFonts from '../modules/settings/design/core-google-fonts.json';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import type { Attendee, ResponseEvent } from '../types';
import { API_INSTANCE, buildURLWithState } from './utils';

const storedFonts = coreFonts.concat(themeFonts);
const fontManager = new FontManager(
    'AIzaSyAjXtX9mEKrVPliwm4C4PyTCpLLUsyaxoE',
    FONT_FAMILY_DEFAULT,
    { limit: 1000, sort: 'popularity', families: storedFonts.map((f) => f.family) }
);

export type SettingsSliceState = {
    data: {};
    fonts: {};
    planDetails: {};
    components: [];
};

const initialState: SettingsSliceState = {
    data: {},
    fonts: [],
    components: [],
};

export const fetchSettingsApi = createAsyncThunk('config/fetch', async (_, { getState }) => {
    return API_INSTANCE.get<ResponseEvent>(buildURLWithState(getState(), `/api/v1/config`))
        .then((response) => response.data)
        .catch((error) => error);
});
export const fetchPlanDetails = createAsyncThunk('config/plan-details', async (_, { getState }) => {
    return API_INSTANCE.get<ResponseEvent>(
        buildURLWithState(getState(), `/api/v1/dashboard/plan-details`)
    )
        .then((response) => response.data)
        .catch((error) => error);
});
export const fetchSiteComponents = createAsyncThunk('components/fetch', async (_, { getState }) => {
    return API_INSTANCE.get<ResponseEvent>(buildURLWithState(getState(), `/api/v1/components`))
        .then((response) => response.data)
        .catch((error) => error);
});
export const deleteWixWidgetsApi = createAsyncThunk(
    'components/delete',
    async (id: string, { getState }) => {
        return API_INSTANCE.delete<ResponseEvent>(
            buildURLWithState(getState(), `/api/v1/components/${id}`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const updateSettingsApi = createAsyncThunk('config/update', async (data, { getState }) => {
    return API_INSTANCE.put<ResponseEvent>(buildURLWithState(getState(), `/api/v1/config`), data)
        .then((response) => response.data)
        .catch((error) => error);
});
export const publishSettingsApi = createAsyncThunk('config/publish', async (data, { getState }) => {
    return API_INSTANCE.post<ResponseEvent>(buildURLWithState(getState(), `/api/v1/publish`), data)
        .then((response) => response.data)
        .catch((error) => error);
});

export const getFonts = createAsyncThunk('config/available-fonts', async (data, { getState }) => {
    const fonts = await fontManager.init();
    return Array.from(fonts.values());
});

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        config_success: (state, action: PayloadAction<Attendee>) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSettingsApi.fulfilled, (state, action) => {
            state.data = action.payload;
        });
        builder.addCase(fetchPlanDetails.fulfilled, (state, action) => {
            state.planDetails = action.payload;
        });
        builder.addCase(fetchSiteComponents.fulfilled, (state, action) => {
            state.components = action.payload;
        });
        builder.addCase(deleteWixWidgetsApi.fulfilled, (state, action) => {
            state.components = state.components.filter(
                (component) => action?.meta?.arg !== component.id
            );
        });
        builder.addCase(updateSettingsApi.fulfilled, (state, action) => {
            state.data = action.payload;
        });
        builder.addCase(getFonts.fulfilled, (state, action) => {
            state.fonts = action.payload;
        });
        builder.addCase(publishSettingsApi.fulfilled, (state, action) => {
            state.data = { ...state.data, hasUnpublishedChanges: false };
        });
    },
});

export const selectSettings = (state: RootState) => state?.settings.data;
export const selectAvailableFonts = (state: RootState) => state?.settings.fonts;
export const selectComponents = (state: RootState) => state?.settings.components;
export const selectPlanDetails = (state: RootState) => state?.settings.planDetails;

export default settingsSlice;
