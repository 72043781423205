export const TEMPLATE_MESSAGES = [
    {
        id: 1,
        value: 'Easy Access Ticket Link',
        template_tooltip:
            'Opt for this template to send a direct link to the ticket, which attendees can easily add to their Android or Apple wallets for convenient access. Ideal for streamlined and mobile-friendly ticket management.',
        subject: `Your Ticket for {{event_title}} is Ready!`,
        message: `Dear {{attendee_first_name}},<p></p>
<p>We are excited to have you join us at {{event_title}}! Your ticket is now ready and can be accessed easily.</p>
<p></p>
<p>Click here to access your ticket: {{ticket_passes_link}}</p>
<p></p>
<p>Event Date: {{event_start_date}}</p>
<p>Event Time: {{event_start_time}}</p>
<p></p>
<p>If you have any questions or need assistance, please don't hesitate to reach out.</p>
<p></p>
<p>See you soon!</p>
`,
        extra_props: {
            email_action_ticket_passes_pdf: false,
            email_action_ticket_receipt_pdf: false,
        },
    },
    {
        id: 2,
        value: 'QR Code Ticket',
        template_tooltip:
            'Choose this template to send attendees their entry QR code directly in the email. This method ensures attendees have their digital ticket readily accessible for event entry.',
        subject: `Get Set for {{event_title}} - Your Ticket is Ready!`,
        message: `Hello {{attendee_first_name}},<p></p>

<p>Get ready for an unforgettable experience at {{event_title}}! Your entry pass is just a scan away.</p>
<p></p>
<p>Your QR Code: {{attendee_qr_code}}</p>
<p></p>
<p>Event Details:</p>
<p>Date: {{event_start_date}}</p>
<p>Time: {{event_start_time}}</p>
<p></p>
<p>Keep this QR code handy for smooth entry. Need help? We're here for you!</p>
<p></p>
<p>Excited to welcome you</p>
`,
        extra_props: {
            email_action_ticket_passes_pdf: false,
            email_action_ticket_receipt_pdf: false,
        },
    },
    {
        id: 3,
        value: 'Printable Ticket and Receipt',
        template_tooltip:
            'This template is designed for sending a printable PDF of the ticket and receipt. Suitable for attendees who prefer physical tickets.',
        subject: `Your {{event_title}} Tickets and Receipt!`,
        message: `Hi {{attendee_first_name}},<p></p>

<p>Thank you for choosing to be part of {{event_title}}! Attached is your ticket and receipt for your records.</p>
<p></p>
<p>Event Date: {{event_start_date}}</p>
<p>Event Time: {{event_start_time}}</p>
<p></p>
<p>Please print out your attached ticket and bring it with you to the event.</p>
<p></p>
<p>For any queries, feel free to contact us. </p>
<p></p>
<p>Looking forward to seeing you</p>
`,
        extra_props: {
            email_action_ticket_passes_pdf: true,
            email_action_ticket_receipt_pdf: true,
        },
    },
    {
        id: 4,
        value: 'Order Summary & Ticket Link',
        template_tooltip:
            'Use this template to give attendees a detailed breakdown of their ticket purchase along with a link to access their tickets. Great for comprehensive communication.',
        subject: `Summary of Your {{event_title}} Order`,
        message: `Dear {{attendee_first_name}},
<p></p>
We're thrilled to confirm your booking for {{event_title}}! Below is your order summary and a link to your tickets.
<p></p>
<p><b>Your Event Order Summary:</b></p>
{{event_order_summary}}
<p></p>
<p><b>Access Your Tickets Here:</b></p>
<p>{{ticket_passes_link}}</p>
<p></p>
<p>Event Date: {{event_start_date}}</p>
<p>Event Time: {{event_start_time}}</p>
<p></p>
<p>For a seamless experience, keep your ticket link handy on your mobile device. If you have any questions or need further assistance, our team is here to help.</p>
<p></p>
<p>We can't wait to welcome you to an amazing event!</p>
<p></p>
<p>Warmest regards</p>
`,
        extra_props: {
            email_action_ticket_passes_pdf: false,
            email_action_ticket_receipt_pdf: false,
        },
    },
];
