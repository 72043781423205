import {
    AnyAction,
    createAsyncThunk,
    createSlice,
    PayloadAction,
    ThunkAction,
} from '@reduxjs/toolkit';
import { keyBy, merge, values } from 'lodash';

import type { RootState } from '../store';
import type { Campaign, NotificationType } from '../types';
import { API_INSTANCE, buildURLWithState } from './utils';

export type Trigger = {
    name: string;
    value: number | string;
    description: string;
};

export type Options = {
    id: number;
    value: string;
};

export type CampaignSliceState = {
    isLoading: boolean;
    data: Campaign[];
    singleCampaignAnalytics: any[];
    error: Error | null;
    searchText: string;
    singleCampaign: Campaign | {};
    singleNotification: NotificationType | {};
    triggers: Trigger[];
    compaignTimeTypeOptions: Options[];
    compaignTimePrefixOptions: Options[];
    eventData: Campaign[];
};

const initialState: CampaignSliceState = {
    isLoading: false,
    data: [],
    eventData: [],
    error: null,
    searchText: '',
    singleCampaign: {},
    singleNotification: {},
    triggers: [
        {
            name: 'Ticket Purchase Confirmation',
            description: `Send an email, SMS, or social media post to attendees who have purchased a ticket or RSVP'd to your event. This trigger confirms their registration and provides event details.`,
            value: 'NEW_ORDER',
            scope: 'event',
            subject: `Thank you for registering for {{event_title}}!`,
            message: `Hi {{attendee_first_name}}, we're excited to have you join us for {{event_title}} on {{event_start_date}} at {{event_start_time}}. Your registration has been confirmed and we can't wait to see you there! If you have any questions or need assistance, please don't hesitate to contact us. Thanks again for registering!`,
        },
        {
            name: 'Event Start Reminder',
            description:
                'Remind attendees about the upcoming event by sending a message or posting on social media when the event is about to start.',
            value: 'EVENT_STARTED',
            subject: `{{event_title}} starts in one hour!`,
            scope: 'event',
            message: `Hi {{attendee_first_name}}, this is a friendly reminder that {{event_title}} is starting in one hour on {{event_start_date}} at {{event_start_time}}. We hope you're excited to attend and look forward to seeing you soon! If you have any questions or need assistance, please don't hesitate to contact us.`,
        },
        {
            name: 'Event End Appreciation',
            description:
                ' Express gratitude to attendees for participating in your event. Send a thank-you message or post on social media to show your appreciation.',
            value: 'EVENT_ENDED',
            scope: 'event',
            subject: `Thank you for attending {{event_title}}!`,
            message: `Hi {{attendee_first_name}}, we hope you had a great time at {{event_title}}! We appreciate you taking the time to attend and hope you found it valuable. If you have any feedback or suggestions, we'd love to hear them. Thanks again for attending and we hope to see you at future events!`,
        },
        {
            name: 'Event Cancellation Notification',
            description:
                'Notify your registered attendees if the event has been cancelled or postponed.',
            value: 'EVENT_CANCELLED',
            subject: `{{event_title}} has been cancelled`,
            scope: 'event',
            message: `Hi {{attendee_first_name}}, we regret to inform you that {{event_title}} has been cancelled due to unforeseen circumstances. We apologize for any inconvenience this may cause and will provide additional information as it becomes available. If you have any questions or concerns, please don't hesitate to contact us. Thanks for your understanding.`,
        },
        {
            name: 'Event Schedule Change Update',
            description:
                "This trigger will activate when there is a change to the event's date or time, ensuring attendees are informed of the new details and any concerns are addressed.",
            value: 'EVENT_CHANGED',
            subject: `{{event_title}} date and time update`,
            scope: 'event',
            message: `Hi {{attendee_first_name}}, we wanted to let you know that there has been a change to the date or time of {{event_title}}. The new details are {{event_start_date}} at {{event_start_time}}. We apologize for any inconvenience this may cause, and we hope you're still able to attend. If you're unable to make it, please let us know as soon as possible. If you have any questions or concerns, please don't hesitate to reach out. Thanks for your understanding!`,
        },
        {
            name: 'New Event Announcement',
            description:
                "Automatically sync newly added events with your CRM, promote them on social media, or send an email to your customers to announce the event's details.",
            value: 'EVENT_ADDED',
            subject: `Exciting News! A New Event Has Been Added to Our Schedule`,
            message: `Hi {{attendee_first_name}}, we wanted to let you know that we've added a new event to our schedule! {{event_title}} will take place on {{event_start_date}} at {{event_start_time}}. We hope you can make it! If you're unable to attend, keep an eye on our schedule for upcoming events. Thanks for your continued support!`,
        },
        {
            name: 'Important Event Announcement',
            description:
                'Use this trigger to send one-time announcements to all attendees. Communicate important updates or changes related to the event.',
            value: 'ANNOUNCEMENT',
            subject: `Important Announcement Regarding {{event_title}}`,
            message: `Hi everyone, we wanted to let you know that there has been a change in the room assignment for {{event_title}}. The event will now take place in Room 203 instead of Room 205. We apologize for any confusion this may cause, and we hope to see you at the event! If you have any questions or concerns, please don't hesitate to reach out.`,
        },
        {
            name: 'Waitlist Available Notification',
            description: 'Notify waitlist subscribers that tickets are now available for purchase.',
            value: 'WAITLIST_AVAILABLE_NOTIFICATION',
            scope: 'waitlist',
            subject: `Tickets Now Available for {{event_title}}!`,
            message: `Hi {{attendee_first_name}}, great news! Tickets for {{event_title}} are now available for purchase. Don't miss out and secure your spot today. If you have any questions or need assistance, please don't hesitate to contact us. We hope to see you at the event!`,
        },
        {
            name: 'Waitlist Signup Confirmation',
            description:
                'Send a confirmation message to attendees after they are added to the waitlist.',
            value: 'WAITLIST_SIGNUP_CONFIRMATION',
            scope: 'waitlist',
            subject: `You're on the Waitlist for {{event_title}}!`,
            message: `Hi {{attendee_first_name}}, you've been added to the waitlist for {{event_title}}. We will notify you as soon as tickets become available. Thank you for your interest, and we hope to see you at the event! If you have any questions or need assistance, please don't hesitate to contact us.`,
        },
    ],
    compaignTimeTypeOptions: [
        { id: 'MINUTES', value: 'Minutes' },
        { id: 'HOURS', value: 'Hours' },
        { id: 'DAYS', value: 'Days' },
    ],
    compaignTimePrefixOptions: [
        { id: 'BEFORE', value: 'Before' },
        { id: 'AFTER', value: 'After' },
    ],
};

export const fetchCampaignsApi = createAsyncThunk('campaigns/fetch', async (_, { getState }) => {
    return API_INSTANCE.get(buildURLWithState(getState(), `/api/v1/dashboard/campaigns`))
        .then((response) => response.data)
        .catch((error) => error);
});
export const fetchEventCampaignsApi = createAsyncThunk(
    'event-campaigns/fetch',
    async (eventId, { getState }) => {
        return API_INSTANCE.get(
            buildURLWithState(getState(), `/api/v1/dashboard/campaigns/events/${eventId}`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const triggerEngagement = createAsyncThunk(
    'engagement/trigger',
    async (data, { getState }) => {
        return API_INSTANCE.post(
            buildURLWithState(getState(), `/api/v1/dashboard/campaigns/trigger`),
            data
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const fetchCampaignAnalyticsApi = createAsyncThunk(
    'campaigns/analytics',
    async (id, { getState }) => {
        return API_INSTANCE.get(
            buildURLWithState(getState(), `/api/v1/dashboard/campaigns/${id}/analytics`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const startCampaignsApi = createAsyncThunk(
    'campaigns/start',
    async (campaign, { getState }) => {
        return API_INSTANCE.put(
            buildURLWithState(getState(), `/api/v1/dashboard/campaigns/${campaign.id}/start`)
        ).then((response) => response.data);
    }
);
export const startBulkCampaignsApi = createAsyncThunk(
    'campaigns-bulk/start',
    async (ids, { getState }) => {
        return API_INSTANCE.put(
            buildURLWithState(getState(), `/api/v1/dashboard/campaigns/bulk/start`),
            { ids }
        ).then((response) => response.data);
    }
);
export const stopBulkCampaignsApi = createAsyncThunk(
    'campaigns-bulk/stop',
    async (ids, { getState }) => {
        return API_INSTANCE.put(
            buildURLWithState(getState(), `/api/v1/dashboard/campaigns/bulk/stop`),
            { ids }
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const deleteBulkCampaignsApi = createAsyncThunk(
    'campaigns-bulk/delete',
    async (ids, { getState }) => {
        return API_INSTANCE.put(
            buildURLWithState(getState(), `/api/v1/dashboard/campaigns/bulk/delete`),
            { ids }
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const getCampaignAdvancedEditor = createAsyncThunk(
    'campaigns/get-editor',
    async (id, { getState }) => {
        return API_INSTANCE.get(
            buildURLWithState(getState(), `/api/v1/dashboard/campaigns/${id}/editor`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const updateCampaignAdvancedEditor = createAsyncThunk(
    'campaigns/update-editor',
    async (campaign, { getState }) => {
        return API_INSTANCE.put(
            buildURLWithState(getState(), `/api/v1/dashboard/campaigns/${campaign.id}/editor`),
            campaign
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const stopCampaignsApi = createAsyncThunk(
    'campaigns/stop',
    async (campaign, { getState }) => {
        return API_INSTANCE.put(
            buildURLWithState(getState(), `/api/v1/dashboard/campaigns/${campaign.id}/stop`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const deleteCampaignsApi = createAsyncThunk(
    'campaigns/delete',
    async (id: string, { getState }) => {
        return API_INSTANCE.delete(
            buildURLWithState(getState(), `/api/v1/dashboard/campaigns/${id}`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const updateCampaignApi = createAsyncThunk(
    'campaigns/update',
    (campaign: Campaign, { getState }) =>
        API_INSTANCE.put(
            buildURLWithState(getState(), `/api/v1/dashboard/campaigns/${campaign.id}`),
            campaign
        )
            .then((response) => response.data)
            .catch((error) => error)
);

export const getCampaignApi = createAsyncThunk(
    'campaigns/get',
    (campaignId: string, { getState }) =>
        API_INSTANCE.get(buildURLWithState(getState(), `/api/v1/dashboard/campaigns/${campaignId}`))
            .then((response) => response.data)
            .catch((error) => error)
);
export const getNotificationApi = createAsyncThunk(
    'campaigns/notifications-get',
    (notification: any, { getState }) =>
        API_INSTANCE.get(
            buildURLWithState(
                getState(),
                `/api/v1/dashboard/campaigns/${notification.campaign_id}/notifications/${notification.id}`
            )
        )
            .then((response) => response.data)
            .catch((error) => error)
);
export const createCampaignApi = createAsyncThunk(
    'campaigns/create',
    (campaign: Campaign, { getState }) =>
        API_INSTANCE.post(buildURLWithState(getState(), `/api/v1/dashboard/campaigns`), campaign)
            .then((response) => response.data)
            .catch((error) => error)
);
export const createNotificationApi = createAsyncThunk(
    'campaigns/notification-create',
    (notification: NotificationType, { getState }) =>
        API_INSTANCE.post(
            buildURLWithState(
                getState(),
                `/api/v1/dashboard/campaigns/${notification.campaign_id}/notifications`
            ),
            notification
        )
            .then((response) => response.data)
            .catch((error) => error)
);
export const updateNotificationApi = createAsyncThunk(
    'campaigns/notification-update',
    (notification: NotificationType, { getState }) =>
        API_INSTANCE.put(
            buildURLWithState(
                getState(),
                `/api/v1/dashboard/campaigns/${notification.campaign_id}/notifications/${notification.id}`
            ),
            notification
        )
            .then((response) => response.data)
            .catch((error) => error)
);

export const campaignsSlice = createSlice({
    name: 'campaigns',
    initialState,
    reducers: {
        compaign_attempt: (state: CampaignSliceState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload || false;
        },
        campaign_success: (state, action: PayloadAction<Campaign[]>) => {
            state.data = action.payload;
            state.isLoading = false;
            state.singleCampaign = {};
        },
        compaign_error: (state, action: PayloadAction<Error>) => {
            state.error = action.payload;
            state.isLoading = false;
        },

        submit_compaign_attempt: (state) => {
            state.isLoading = true;
        },
        submit_campaign_success: (state, action: PayloadAction<Campaign>) => {
            state.data = state.data.concat(action.payload);
            state.isLoading = false;
        },
        submit_compaign_error: (state, action: PayloadAction<Error>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        update_compaign_attempt: (state) => {
            state.isLoading = true;
        },
        update_campaign_success: (state, action: PayloadAction<Campaign>) => {
            state.data = state.data.map((item) =>
                item.id === action.payload.id ? action.payload : item
            );
            state.isLoading = false;
        },
        update_compaign_error: (state, action: PayloadAction<Error>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        single_campaign_success: (state, action: PayloadAction<Campaign>) => {
            state.singleCampaign = action.payload;
        },
        handle_change_search: (state, action: PayloadAction<string>) => {
            state.searchText = action.payload;
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(getCampaignApi.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getCampaignApi.fulfilled, (state, action) => {
            state.isLoading = false;
            state.singleCampaign = action.payload;
        });
        builder.addCase(getNotificationApi.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getNotificationApi.fulfilled, (state, action) => {
            state.isLoading = false;
            state.singleNotification = action.payload;
        });

        builder.addCase(updateCampaignApi.fulfilled, (state, action) => {
            state.singleCampaign = { ...state.singleCampaign, ...action.payload };
            state.data = state.data.map((item) =>
                item.id === action.payload.id ? action.payload : item
            );
            state.eventData = state.eventData.map((item) =>
                item.id === action.payload.id ? action.payload : item
            );
        });
        builder.addCase(startCampaignsApi.fulfilled, (state, action) => {
            state.singleCampaign = action.payload;
            state.data = state.data.map((item) =>
                item.id === action.payload.id ? action.payload : item
            );
        });
        builder.addCase(getCampaignAdvancedEditor.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getCampaignAdvancedEditor.fulfilled, (state, action) => {
            state.singleCampaign = { ...state.singleCampaign, ...action.payload };
            state.isLoading = false;
        });
        builder.addCase(updateCampaignAdvancedEditor.fulfilled, (state, action) => {
            state.singleCampaign = action.payload;
        });
        builder.addCase(stopCampaignsApi.fulfilled, (state, action) => {
            state.singleCampaign = { ...state.singleCampaign, enabled: action.payload.enabled };
        });

        builder.addCase(createCampaignApi.fulfilled, (state, action) => {
            state.data = state.data.concat(action.payload);
            state.eventData = state.eventData.concat(action.payload);
            state.singleCampaign = action.payload;
        });
        builder.addCase(fetchCampaignAnalyticsApi.fulfilled, (state, action) => {
            state.singleCampaignAnalytics = action.payload;
        });

        builder.addCase(deleteCampaignsApi.fulfilled, (state, action) => {
            state.data = state.data.filter((data) => action.meta.arg !== data.id);
            state.eventData = state.eventData.filter((data) => action.meta.arg !== data.id);
        });

        builder.addCase(fetchCampaignsApi.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchCampaignsApi.fulfilled, (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
        });
        builder.addCase(stopBulkCampaignsApi.fulfilled, (state, action) => {
            const merged = merge(keyBy(state.data, 'id'), keyBy(action.payload, 'id'));
            const vals = values(merged);
            state.data = vals;
        });
        builder.addCase(startBulkCampaignsApi.fulfilled, (state, action) => {
            const merged = merge(keyBy(state.data, 'id'), keyBy(action.payload, 'id'));
            const vals = values(merged);
            state.data = vals;
        });

        builder.addCase(deleteBulkCampaignsApi.fulfilled, (state, action) => {
            state.data = state.data.filter((campaign) => !action.meta.arg.includes(campaign.id));
        });
        builder.addCase(fetchEventCampaignsApi.pending, (state, action) => {
            state.loadingCampaignList = true;
        });
        builder.addCase(fetchEventCampaignsApi.fulfilled, (state, action) => {
            state.eventData = action.payload;
            state.loadingCampaignList = false;
        });
    },
});

export const {
    compaign_attempt,
    campaign_success,
    compaign_error,
    handle_change_search,
    single_campaign_success,
    submit_campaign_success,
    update_campaign_success,
} = campaignsSlice.actions;

export const getSingleCampaign =
    (id: string | number): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch, getState) => {
        const state = getState();
        const campaigns = state.campaigns.data;
        const singleCampaign = campaigns.find((item) => item.id == id);
    };

export const getCampaigns =
    (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch, getState) => {
        const state = getState();
        const campaigns = state.campaigns.data;
        dispatch(campaign_success(campaigns));
    };

export const submitCampaign =
    (data: Campaign): ThunkAction<void, RootState, unknown, AnyAction> =>
    (dispatch) => {
        dispatch(submit_campaign_success(data));
    };

export const updateCampaign =
    (data: Campaign): ThunkAction<void, RootState, unknown, AnyAction> =>
    (dispatch) => {
        dispatch(update_campaign_success(data));
    };

export const selectCampaignsList = (state: RootState) => state?.campaigns?.data;
export const selectEventCampaignsList = (state: RootState) => state?.campaigns?.eventData;
export const selectEventCampaignsListLoading = (state: RootState) =>
    state?.campaigns?.loadingCampaignList;
export const selectTriggersList = (state: RootState) => state?.campaigns?.triggers;
export const selectCampaignPrefixTypeOptions = (state: RootState) =>
    state?.campaigns?.compaignTimePrefixOptions;
export const selectCampaignTimeTypeOptions = (state: RootState) =>
    state?.campaigns?.compaignTimeTypeOptions;
export const selectSingleCampaign = (state: RootState) => state?.campaigns?.singleCampaign;
export const selectSingleNotification = (state: RootState) => state?.campaigns?.singleNotification;
export const selectSearchText = (state: RootState) => state?.campaigns?.searchText;
export const selectCampaignIsLoading = (state: RootState) => state?.campaigns?.isLoading;
export const selectSingleCampaignAnalytics = (state: RootState) =>
    state?.campaigns?.singleCampaignAnalytics;

export default campaignsSlice;
