import getSymbolFromCurrency from 'currency-symbol-map';
import { isEmpty } from 'lodash';
import { Box, InfoIcon, Text } from 'wix-style-react';

const TicketValue = ({ attendee, currency_symbol }) => {
    const refundAmount = attendee?.refund_amount;
    const ticketPrice = attendee?.final_ticket_price || 0;
    const currency = currency_symbol || getSymbolFromCurrency(attendee?.cart?.currency) || '';
    const total = `${currency}${ticketPrice.toFixed(2)}`;

    return (
        <Box align="center">
            {refundAmount ? (
                <Box gap={1} direction="vertical">
                    <>
                        {currency} {(ticketPrice - refundAmount).toFixed(2)}
                        <span style={{ textDecoration: 'line-through' }}>{total}</span>
                    </>
                    <Text skin="error" size="tiny">
                        Refunded{' '}
                        {!isEmpty(attendee.refund_reason) && (
                            <InfoIcon size="small" content={`Reason: ${attendee.refund_reason}`} />
                        )}
                    </Text>
                </Box>
            ) : (
                <> {total}</>
            )}
        </Box>
    );
};

export default TicketValue;
