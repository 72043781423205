import getSymbolFromCurrency from 'currency-symbol-map';
import { Controller } from 'react-hook-form';
import { classes } from './tickets.st.css';
import {
    Cell,
    Input,
    Layout,
    FormField,
    InputArea,
    Box,
    Dropdown,
    InputStatus,
    NumberInput,
    ToggleSwitch,
    InfoIcon,
    FileUpload,
    ImageViewer,
    Text,
    Divider,
    RadioGroup,
} from 'wix-style-react';
import { toBase64 } from '../../../utils';
import { useCallback, useEffect, useState } from 'react';
import { fromURL } from 'image-resize-compress';

export const TICKET_STATUS_OPTIONS = [
    { id: 'onsale', value: 'On Sale', badge: 'success', static: true },
    { id: 'soldout', value: 'Sold Out', badge: 'neutralStandard', static: false },
    { id: 'cancelled', value: 'Cancelled', badge: 'danger', static: true },
    { id: 'postponed', value: 'Postponed', badge: 'warningLight', static: true },
    { id: 'sale-ended', value: 'Sales Ended', badge: 'warningLight', static: false },
    { id: 'sale-not-started', value: 'Sales Not Started', badge: 'warningLight', static: false },
];

const TICKET_TYPES = {
    FREE: 'free',
    PAID: 'paid',
    PAY_WHAT_YOU_WANT: 'pwyw',
};

export const Tickets = ({ ticket, control, errors, setValue, getValues, watch }) => {
    const watchStatus = watch('status', ticket?.status || 'onsale');
    const watchValidityPeriod = watch('validity_period');
    const watchIsFree = watch('is_free');
    const watchSalesEndRelativeUnit = watch('sales_end_relative_unit') || 'hour';
    const watchSalesRelativeTo = watch('sales_end_relative_to') || 'before-start';
    const watchSalesStartRelativeUnit = watch('sales_start_relative_unit') || 'hour';
    const watchPayWhatYouWant = watch('pay_what_you_want');
    const watchEnableBookingFee = watch('enable_booking_fee');
    const watchStartSalesImmediately = watch('start_sales_immediately');
    const watchIsHidden = watch('is_hidden');
    const watchTicketType = watch('ticket_type');

    const watchImageUrls = watch('image_urls') || [];
    const watchImageFiles = watch('images', []) || [];

    const [imageUrls, setImageUrls] = useState(watchImageUrls);
    const [imageFiles, setImageFiles] = useState(watchImageFiles);

    useEffect(() => {
        async function setData() {
            if (ticket && ticket.image_urls && ticket.image_urls.length > 0) {
                const files = await Promise.all(
                    ticket.image_urls.map(async (url) => {
                        const blob = await fromURL(url);
                        const name = url.substr(url.lastIndexOf('_') + 1);
                        return new File([blob], name);
                    })
                );
                setImageFiles(files);
                setImageUrls(ticket.image_urls);
            }
        }
        void setData();
    }, []);
    const salesRelativeUnits = [
        { id: 'day', value: 'Day(s)' },
        { id: 'hour', value: 'Hour(s)' },
        { id: 'minute', value: 'Minute(s)' },
    ];
    const salesRelativeTo = [
        { id: 'before-start', value: 'Before Event Starts' },
        { id: 'before-end', value: 'Before Event Ends' },
    ];
    const validityPeriodOptions = [
        { id: 'unlimited', value: 'Unlimited' },
        { id: 'one-day', value: 'One Day' },
        { id: 'one-week', value: 'One Week' },
        { id: 'one-month', value: 'One Month' },
        { id: 'one-year', value: 'One Year' },
    ];

    const handleRemoveImage = useCallback(
        (index) => {
            const newImages = imageUrls.filter((_, i) => i !== index);
            const newFiles = imageFiles.filter((_, i) => i !== index);
            setValue('image_urls', newImages);
            setValue('images', newFiles);
            setImageUrls(newImages);
            setImageFiles(newFiles);
        },
        [setValue]
    );

    useEffect(() => {
        if (ticket) {
            let ticketType;

            if (ticket.is_free) {
                ticketType = TICKET_TYPES.FREE;
            } else if (ticket.pay_what_you_want) {
                ticketType = TICKET_TYPES.PAY_WHAT_YOU_WANT;
            } else {
                ticketType = TICKET_TYPES.PAID;
            }

            setValue('ticket_type', ticketType);
        } else {
            setValue('ticket_type', TICKET_TYPES.FREE);
        }
    }, [ticket]);

    useEffect(() => {
        setValue('pay_what_you_want', false);
        setValue('is_free', true);

        if (watchTicketType === 'free') {
            setValue('price', 0);
            setValue('is_free', true);
            return;
        } else {
            setValue('is_free', false);
        }

        if (watchTicketType === 'pwyw') {
            setValue('pay_what_you_want', true);

            return;
        }
    }, [setValue, watchTicketType]);
    const currency = getSymbolFromCurrency(getValues('currency'));
    return (
        <Box marginBottom={2} marginTop={3}>
            <Layout>
                <Cell span={12}>
                    <Box
                        justifyContent="space-between"
                        width="100%"
                        className={classes.spaceBetween}
                        verticalAlign="middle"
                    >
                        <div className={classes.heading}>Basic Ticket Information</div>
                        <Box>
                            <FormField
                                label="Hide Ticket"
                                labelPlacement="right"
                                infoContent="Select this option to hide this ticket. It will not be shown to users but remains valid and can be made visible again at any time."
                            >
                                <Controller
                                    name="is_hidden"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <ToggleSwitch
                                                {...field}
                                                onChange={(e) => {
                                                    setValue('is_hidden', e.target.checked);
                                                }}
                                                checked={watchIsHidden}
                                            />
                                        );
                                    }}
                                />
                            </FormField>
                        </Box>
                    </Box>
                </Cell>
                <Cell span={12}>
                    <Layout gap={3}>
                        <Cell span={3}>
                            <FormField
                                label="Images"
                                infoContent="Images will appear next to the ticket. Adding more than one image will rotate images in a carousel display."
                            >
                                <FileUpload
                                    multiple
                                    onChange={async (files) => {
                                        const urls = await Promise.all(
                                            Array.from(files).map((file) => toBase64(file))
                                        );
                                        const newImageUrls = imageUrls
                                            ? imageUrls.concat(urls)
                                            : urls;
                                        const newImageFiles = imageFiles.concat(Array.from(files));
                                        setValue('image_urls', newImageUrls);
                                        setValue('images', newImageFiles);
                                        setImageUrls(newImageUrls);
                                        setImageFiles(newImageFiles);
                                    }}
                                    accept=".jpeg,.gif,.png,.jpg"
                                >
                                    {({ openFileUploadDialog }) => {
                                        return (
                                            <ImageViewer
                                                onAddImage={openFileUploadDialog}
                                                width={100}
                                                height={100}
                                            />
                                        );
                                    }}
                                </FileUpload>
                            </FormField>
                        </Cell>
                        {imageUrls?.map((ticketImage, index) => {
                            return (
                                <Cell span={3} key={index}>
                                    <ImageViewer
                                        width={100}
                                        height={100}
                                        imageUrl={ticketImage}
                                        onRemoveImage={() => handleRemoveImage(index)}
                                    />
                                </Cell>
                            );
                        })}
                    </Layout>
                </Cell>
                <Cell span={6}>
                    <FormField label="Ticket Name" required>
                        <Controller
                            control={control}
                            name="name"
                            rules={{
                                required: 'Ticket name is required!',
                            }}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    status={
                                        errors.name?.message
                                            ? Input.StatusError
                                            : ('' as InputStatus)
                                    }
                                    placeholder="General Admission"
                                />
                            )}
                        />
                    </FormField>
                </Cell>
                <Cell span={6}>
                    <FormField label="Status">
                        <Controller
                            control={control}
                            name="status"
                            render={({ field }) => (
                                <Dropdown
                                    {...field}
                                    border="standard"
                                    options={TICKET_STATUS_OPTIONS.filter((t) => t.static)}
                                    selectedId={watchStatus}
                                    onSelect={(option) => setValue('status', '' + option.id)}
                                />
                            )}
                        ></Controller>
                    </FormField>
                </Cell>

                <Cell span={12}>
                    <FormField label="Description">
                        <Controller
                            control={control}
                            name="description"
                            render={({ field }) => <InputArea rows={4} {...field} />}
                        />
                    </FormField>
                </Cell>
                <Cell span={6}>
                    <FormField
                        label="Check-In Limit"
                        infoContent="Defines the maximum number of entries allowed per ticket. Ideal for events offering multiple admissions over time, like theme parks or museums. Once the limit is reached, further attempts to check in will be flagged, indicating the attendee has used all their entries."
                    >
                        <Controller
                            control={control}
                            name="checkin_scan_limit"
                            render={({ field }) => <NumberInput {...field} placeholder="1" />}
                        />
                    </FormField>
                </Cell>
                <Cell span={6}>
                    <FormField
                        label={
                            <Box>
                                Ticket Validity Period{' '}
                                <InfoIcon content="Choose the desired validity duration for your tickets after purchase to suit your event's requirements." />
                            </Box>
                        }
                    >
                        <Controller
                            control={control}
                            name="validity_period"
                            render={({ field }) => (
                                <Dropdown
                                    {...field}
                                    onSelect={(option) => setValue('validity_period', option.id)}
                                    selectedId={watchValidityPeriod || 'unlimited'}
                                    options={validityPeriodOptions}
                                    popoverProps={{
                                        appendTo: 'window',
                                        zIndex: 9999,
                                    }}
                                />
                            )}
                        />
                    </FormField>
                </Cell>
                <Cell span={12}>
                    <Divider />
                </Cell>
                <Cell span={12}>
                    <div className={classes.heading}>Pricing and Fees</div>
                </Cell>

                <Cell span={6}>
                    <RadioGroup
                        value={watchTicketType}
                        selectionArea="none"
                        onChange={(e) => setValue('ticket_type', e)}
                    >
                        <RadioGroup.Radio value={'free'}>Free</RadioGroup.Radio>
                        <RadioGroup.Radio value={'paid'}>Paid</RadioGroup.Radio>
                        <RadioGroup.Radio value={'pwyw'}>
                            Pay What You Want{' '}
                            <InfoIcon
                                size="small"
                                content={`Let customers define their own price for the event or donation amount.`}
                            />
                        </RadioGroup.Radio>
                    </RadioGroup>
                </Cell>
                <Cell span={6}>
                    {watchPayWhatYouWant && (
                        <Cell span={4}>
                            <FormField label="Minimum Price">
                                <Controller
                                    name="minimum_price"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <NumberInput
                                                {...field}
                                                placeholder="1"
                                                prefix={<Input.Affix>{currency}</Input.Affix>}
                                            />
                                        );
                                    }}
                                />
                            </FormField>
                        </Cell>
                    )}
                    {!watchPayWhatYouWant && (
                        <Cell span={4}>
                            {!watchIsFree && (
                                <FormField label="Price" required={!watchIsFree}>
                                    <Controller
                                        control={control}
                                        name="price"
                                        rules={{
                                            required: 'Price is required!',
                                        }}
                                        render={({ field }) => (
                                            <NumberInput
                                                disabled={watchIsFree}
                                                status={
                                                    errors.price?.message
                                                        ? Input.StatusError
                                                        : ('' as InputStatus)
                                                }
                                                {...field}
                                                placeholder="10.00"
                                                prefix={<Input.Affix>{currency}</Input.Affix>}
                                                // suffix={
                                                //     watchPassTicketFee && (
                                                //         <Input.Affix>
                                                //             +{currency}
                                                //             {(
                                                //                 process.env.TICKET_FEE * priceData.convert
                                                //             ).toFixed(2)}
                                                //         </Input.Affix>
                                                //     )
                                                // }
                                            />
                                        )}
                                    />
                                </FormField>
                            )}
                        </Cell>
                    )}
                </Cell>
                <Cell span={5}>
                    <FormField
                        label="Enable Booking Fee"
                        infoContent="Enable this option to add a custom booking fee to each ticket. Set a fixed amount of your choice, which will be added to the ticket price. This is a great way to cover additional costs or service charges."
                    >
                        <Controller
                            name="enable_booking_fee"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <ToggleSwitch
                                        {...field}
                                        onChange={(e) => {
                                            setValue('enable_booking_fee', e.target.checked);
                                        }}
                                        checked={watchEnableBookingFee}
                                    />
                                );
                            }}
                        />
                    </FormField>
                </Cell>
                <Cell span={7}>
                    {watchEnableBookingFee && (
                        <FormField label="Custom Booking Fee" infoContent="">
                            <Controller
                                name="booking_fee_fixed"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <NumberInput
                                            {...field}
                                            placeholder="0"
                                            prefix={<Input.Affix>{currency}</Input.Affix>}
                                        />
                                    );
                                }}
                            />
                        </FormField>
                    )}
                </Cell>

                <Cell span={12}>
                    <Divider />
                </Cell>
                <Cell span={12}>
                    <div className={classes.heading}>Sales and Capacity Settings</div>
                </Cell>
                <Cell span={4}>
                    <Text>
                        Ticket Sales Start{' '}
                        <InfoIcon content="Set the ticket sales start time relative to the event's commencement. Choose to begin sales several days, hours, or minutes before the event, or opt for immediate sales availability." />
                    </Text>
                </Cell>
                <Cell span={8}>
                    <Box direction="vertical" gap={3}>
                        <Box gap={3}>
                            <Controller
                                control={control}
                                name="sales_start_relative_value"
                                render={({ field }) => (
                                    <NumberInput
                                        {...field}
                                        defaultValue={1}
                                        min={0}
                                        max={365}
                                        disabled={watchStartSalesImmediately}
                                        onSelect={(option) =>
                                            setValue('sales_start_relative_value', option.id)
                                        }
                                        strict
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="sales_start_relative_unit"
                                render={({ field }) => (
                                    <Dropdown
                                        {...field}
                                        disabled={watchStartSalesImmediately}
                                        onSelect={(option) =>
                                            setValue('sales_start_relative_unit', option.id)
                                        }
                                        selectedId={watchSalesStartRelativeUnit}
                                        options={salesRelativeUnits}
                                    />
                                )}
                            />
                        </Box>
                        <Box>
                            <Controller
                                control={control}
                                name="Start Immediately"
                                render={({ field }) => (
                                    <Box gap={1} verticalAlign="middle">
                                        <ToggleSwitch
                                            {...field}
                                            onChange={(e) => {
                                                setValue(
                                                    'start_sales_immediately',
                                                    e.target.checked
                                                );
                                            }}
                                            checked={watchStartSalesImmediately}
                                        />
                                        <Text>Immediately</Text>
                                    </Box>
                                )}
                            />
                        </Box>
                    </Box>
                </Cell>
                <Cell span={12}>
                    <Divider />
                </Cell>
                <Cell span={4}>
                    <Text>
                        Ticket Sales End{' '}
                        <InfoIcon content="Set the ticket sales start time relative to the event' s commencement. Choose to begin sales several days, hours, or minutes before the event, or opt for immediate sales availability." />
                    </Text>
                </Cell>
                <Cell span={8}>
                    <Box direction="vertical" gap={3}>
                        <Box gap={3}>
                            <Controller
                                control={control}
                                name="sales_end_relative_value"
                                render={({ field }) => (
                                    <NumberInput
                                        {...field}
                                        defaultValue={1}
                                        min={0}
                                        max={365}
                                        onSelect={(option) =>
                                            setValue('sales_end_relative_value', option.id)
                                        }
                                        strict
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="sales_end_relative_unit"
                                render={({ field }) => (
                                    <Dropdown
                                        {...field}
                                        onSelect={(option) =>
                                            setValue('sales_end_relative_unit', option.id)
                                        }
                                        selectedId={watchSalesEndRelativeUnit}
                                        options={salesRelativeUnits}
                                    />
                                )}
                            />
                        </Box>
                        <Box>
                            <Controller
                                control={control}
                                name="sales_end_relative_to"
                                render={({ field }) => (
                                    <Dropdown
                                        {...field}
                                        onSelect={(option) =>
                                            setValue('sales_end_relative_to', option.id)
                                        }
                                        selectedId={watchSalesRelativeTo}
                                        options={salesRelativeTo}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                </Cell>
                <Cell span={12}>
                    <Divider />
                </Cell>

                <Cell span={4}>
                    Ticket Capacity{' '}
                    <InfoIcon content="Set a limit on ticket sales to avoid exceeding the available space or intended audience size." />
                </Cell>
                <Cell span={8}>
                    <Box direction="vertical" gap={3}>
                        <FormField
                            label="Ticket Capacity"
                            infoContent="Set a limit on ticket sales to avoid exceeding the available space or intended audience size."
                        >
                            <Controller
                                control={control}
                                name="quantity_total"
                                rules={{
                                    required: 'Ticket Capacity is required!',
                                }}
                                render={({ field }) => (
                                    <NumberInput
                                        status={
                                            errors.quantity_total?.message
                                                ? Input.StatusError
                                                : ('' as InputStatus)
                                        }
                                        {...field}
                                        placeholder="2"
                                        suffix="Tickets"
                                    />
                                )}
                            />
                        </FormField>
                        <FormField label="Min Per Order">
                            <Controller
                                rules={{
                                    required: 'Minimum Order is required!',
                                }}
                                control={control}
                                name="minimum_per_order"
                                render={({ field }) => (
                                    <NumberInput
                                        status={
                                            errors.minimum_per_order?.message
                                                ? Input.StatusError
                                                : ('' as InputStatus)
                                        }
                                        {...field}
                                        suffix="Tickets"
                                    />
                                )}
                            />
                        </FormField>
                        <FormField label="Max Per Order">
                            <Controller
                                rules={{
                                    required: 'Max Order is required!',
                                }}
                                control={control}
                                name="maximum_per_order"
                                render={({ field }) => (
                                    <NumberInput
                                        status={
                                            errors.maximum_per_order?.message
                                                ? Input.StatusError
                                                : ('' as InputStatus)
                                        }
                                        {...field}
                                        suffix="Tickets"
                                    />
                                )}
                            />
                        </FormField>
                    </Box>
                </Cell>
            </Layout>
        </Box>
    );
};
