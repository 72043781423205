import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { classes } from './promotion-start-modal.st.css';
import { Cell, CustomModalLayout, Layout, Modal, Box, Text, Loader, Button } from 'wix-style-react';
import { capitalize } from 'lodash';
import * as Icons from 'wix-ui-icons-common';
import { useSelector } from 'react-redux';
import { selectSiteData } from '../../../features/account-slice';
import { useHistory } from 'react-router-dom';
import { buildURL } from '../../../features/utils';
import { startCampaignsApi } from '../../../features/campaign-slice';
import { useAppDispatch } from '../../../hooks';

const Complete = ({ completePath }) => {
    const site = useSelector(selectSiteData);
    const history = useHistory();
    return (
        <Box direction="vertical" gap={2} align="center">
            <Icons.StatusCompleteFilled className={classes.successIcon} />
            <Text>Autopilot has been setup successfully!</Text>
            <Button
                size="medium"
                onClick={() => {
                    history.push(buildURL(site, completePath));
                }}
            >
                Close
            </Button>
        </Box>
    );
};

const formatWithAnd = (input) => {
    const last = input.pop();
    return input.join(', ') + ' and ' + last;
};

const ValidMessage = ({ promotion }) => {
    const repeatOn = promotion.schedule.repeat_on;
    const repeatingDays = promotion.schedule.repeating_days;
    const timezone = promotion.schedule.timezone;
    const startDate = promotion.schedule.date_start;
    const endDate = promotion.schedule.date_end;
    const dayList = repeatingDays.filter(({ value }) => value).map(({ day }) => capitalize(day));
    const momentTime = moment(startDate).tz(timezone);
    return (
        <Cell span={12}>
            <Box marginBottom={2}></Box>
            <Box marginBottom={2}>
                <Text>
                    Autopilot will start{' '}
                    <b>
                        {momentTime.format('MMM Do LT')} {momentTime.format('z')}
                    </b>{' '}
                    and will stop <b>{moment(endDate).format('MMM Do LT')}</b>.
                </Text>
            </Box>
            <Box marginBottom={1}>
                <Text>
                    It will repeat <b>{capitalize(repeatOn)}</b> on days; {formatWithAnd(dayList)}.{' '}
                </Text>
            </Box>
            <Box marginBottom={1}>
                <Text>Messages will be sent in 1 minute to avoid spamming</Text>
            </Box>
        </Cell>
    );
};
const InvalidMessage = () => {
    return (
        <Cell span={12}>
            <Box marginBottom={2}>
                <Text>The start date is set in the past so this event cannot start</Text>
            </Box>
        </Cell>
    );
};
export const PromotionStartModal = ({ promotion, completePath, isStartAfterCreate, onClose }) => {
    const startDate = promotion.schedule.date_start;
    const [isModalOpened, setModalOpened] = useState(true);
    const [complete, setComplete] = useState(true);
    const [initStart, setInitStart] = useState(isStartAfterCreate);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const timer = setTimeout(() => {
            setComplete(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);
    const closeModal = () => {
        onClose(false);
        setModalOpened(false);
    };

    const copyInProgress = () => {
        const startDateInPast = moment(startDate).isAfter();
        return (
            <Box width="100%" height="100%" align="center">
                <Layout>
                    {startDateInPast ? (
                        <ValidMessage promotion={promotion} />
                    ) : (
                        <InvalidMessage promotion={promotion} />
                    )}
                    <Cell span={12}>
                        <Box marginBottom={3} align="center">
                            {initStart ? (
                                <Box gap={2}>
                                    {startDateInPast && (
                                        <Button
                                            priority="primary"
                                            onClick={() => {
                                                void dispatch(startCampaignsApi(promotion)).then(
                                                    () => {
                                                        setInitStart(false);
                                                    }
                                                );
                                            }}
                                        >
                                            Start
                                        </Button>
                                    )}
                                    <Button
                                        skin="destructive"
                                        priority="secondary"
                                        size="medium"
                                        onClick={() => {
                                            closeModal();
                                        }}
                                    >
                                        Close
                                    </Button>
                                </Box>
                            ) : !complete ? (
                                <Complete completePath={completePath} />
                            ) : (
                                <Loader size="medium" text="Setting up Autopilot..." />
                            )}
                        </Box>
                    </Cell>
                </Layout>
            </Box>
        );
    };

    const renderModalContent = () => (
        <CustomModalLayout title={`Starting "${promotion.name}" autopilot`} width="600px">
            {copyInProgress()}
        </CustomModalLayout>
    );

    return (
        <>
            <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
                {renderModalContent()}
            </Modal>
        </>
    );
};
