import { SettingsHeader } from '../../../components/settings-header';
import { TextInput } from './text-input';

export const CheckoutDateTimeSelection = ({ label }) => {
    return (
        <>
            <SettingsHeader title={label} />

            <TextInput
                placeholder="Available"
                title="Availability Text"
                tooltipText="This text indicates that tickets for the event are currently available for purchase."
                updateKey="dateTimeSelectionAvailabilityText"
            />

            <TextInput
                placeholder="Sold Out"
                title="Unavailable Text"
                tooltipText="This text indicates that all tickets for the event have been sold."
                updateKey="dateTimeSelectionUnAvailabilityText"
            />
            <TextInput
                placeholder="Morning"
                title="Morning Time Slot Label"
                tooltipText="Change the text for the morning time slot label."
                updateKey="timeBasedMorningPeriodText"
            />
            <TextInput
                placeholder="Afternoon"
                title="Afternoon Time Slot Label"
                tooltipText="Change the text for the afternoon time slot label."
                updateKey="timeBasedAfternoonPeriodText"
            />
            <TextInput
                placeholder="Evening"
                title="Evening Time Slot Label"
                tooltipText="Change the text for the evening time slot label."
                updateKey="timeBasedEveningPeriodText"
            />
            <TextInput
                placeholder="Night"
                title="Night Time Slot Label"
                tooltipText="Change the text for the night time slot label."
                updateKey="timeBasedNightPeriodText"
            />
        </>
    );
};
