import { CheckoutDateTimeDisplay } from './checkout-date-time';
import { DrilldownContainer } from '../../../components/drilldown';
import { CheckoutPromoCode } from './checkout-promo-code';
import { CheckoutAttendeeInfo } from './checkout-attendee-info';
import { CheckoutTicketInfo } from './checkout-ticket-info';

export const Checkout = () => {
    return (
        <DrilldownContainer
            title="Checkout Settings"
            infoText="Manage settings related to the checkout process, including date and time formats, promo code options, attendee information, and ticket availability."
            sections={[
                {
                    label: 'Date and time',
                    id: 'checkout_datetime',
                    component: <CheckoutDateTimeDisplay />,
                },
                {
                    label: 'Promo Code',
                    id: 'checkout_promo',
                    component: <CheckoutPromoCode />,
                },
                {
                    label: 'Attendee Information',
                    id: 'checkout_attendee',
                    component: <CheckoutAttendeeInfo />,
                },
                {
                    label: 'Ticket Information',
                    id: 'checkout_ticket',
                    component: <CheckoutTicketInfo />,
                },
            ]}
        />
    );
};
