import { useState } from 'react';
import { Button, PulseAnimation, MessageModalLayout, Modal, Loader, Box } from 'wix-style-react';
import PublishSuccess from '../../assets/site-published.gif';

const PublishButton = ({ hasUnpublishedChanges, onPublish }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const publishOnClick = async () => {
        setIsLoading(true);
        await onPublish();
        setSuccessMessage(
            'You have successfully published the changes and they are now available on your site.'
        );
        setModalOpen(true);
        setIsLoading(false);
    };

    return (
        <div>
            <PulseAnimation active={hasUnpublishedChanges} color="B10" borderRadius="18px">
                <Button onClick={publishOnClick}>
                    <Box gap={2}>{isLoading && <Loader size="tiny" />}Publish</Box>
                </Button>
            </PulseAnimation>

            <Modal isOpen={isModalOpen}>
                <MessageModalLayout
                    illustration={<img src={PublishSuccess} width={100} />}
                    title="Changes Published!"
                    primaryButtonText="Close"
                    primaryButtonOnClick={() => setModalOpen(false)}
                >
                    {successMessage}
                </MessageModalLayout>
            </Modal>
        </div>
    );
};

export default PublishButton;
