import {
    Box,
    Card,
    Cell,
    Checkbox,
    FormField,
    GoogleAddressInput,
    Input,
    InputStatus,
    Layout,
} from 'wix-style-react';

import { Controller } from 'react-hook-form';
import { ZoomMeetings } from './components/zoom-meetings';
import { GoogleMap } from './components/google-map';
import { GoogleMapsClient } from './components/google-maps-client';
import { classes } from './create-events.st.css';

export const EventLocation = ({ watch, setValue, control, errors, event }) => {
    const watchLocation = watch('location');
    const watchAddress = watch('address');
    const watchIsOnline = watch('isOnline', false);
    const watchRepeatingEvents = watch('repeat', false);

    const venueRule = !watchIsOnline
        ? {
              required: 'Please provide a venue name if the event is not online.',
          }
        : {};
    return (
        <Box
            direction="vertical"
            verticalAlign="space-between"
            marginTop="50px"
            marginBottom="50px"
            margin={'0px auto'}
            padding="24px 29px 27px"
            borderRadius="8px"
            backgroundColor="white"
            height="100%"
            width="90%"
            boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
            flexGrow={1}
        >
            <Card.Header
                title="Venue"
                subtitle="Enter the venue information for your event to let guests know where it will be held."
            />
            <Card.Divider />
            <Box direction="horizontal" gap={2}>
                <Box marginBottom={3} marginTop={2} direction="vertical" gap={2} width={'40%'}>
                    <FormField>
                        <Controller
                            control={control}
                            name="isOnline"
                            render={({ field }) => (
                                <Checkbox
                                    size="medium"
                                    {...field}
                                    onChange={(e) => setValue('isOnline', e.target.checked)}
                                    checked={field.value}
                                >
                                    Online Event
                                </Checkbox>
                            )}
                        ></Controller>
                    </FormField>
                    {watchIsOnline && (
                        <div className={classes.margin}>
                            <Layout>
                                <Cell span={8}>
                                    <ZoomMeetings
                                        id={event?.id}
                                        repeatingEvent={watchRepeatingEvents}
                                    />
                                </Cell>
                            </Layout>
                        </div>
                    )}

                    {!watchIsOnline && (
                        <>
                            <FormField label="Location" required={!watchIsOnline}>
                                <GoogleAddressInput
                                    value={watchLocation}
                                    onChange={(e) => setValue('location', e.target.value)}
                                    onSet={(e: any) => {
                                        setValue('address', e.address);
                                        setValue('location', e.address.formatted);
                                    }}
                                    Client={GoogleMapsClient as any}
                                />
                            </FormField>
                            {watchAddress && (
                                <div style={{ height: 250, marginTop: 20 }}>
                                    <GoogleMap
                                        key={watchAddress?.latLng.lat}
                                        latitude={watchAddress?.latLng.lat}
                                        longitude={watchAddress?.latLng.lng}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </Box>
                <Box
                    direction="vertical"
                    verticalAlign="space-between"
                    padding="24px 29px 27px"
                    backgroundColor="white"
                    height="100%"
                    flexGrow={1}
                >
                    <div className={classes.margin}>
                        <FormField label="Venue name" required={!watchIsOnline}>
                            <Controller
                                control={control}
                                name="venueName"
                                rules={venueRule}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        status={
                                            errors.venueName?.message
                                                ? Input.StatusError
                                                : ('' as InputStatus)
                                        }
                                    />
                                )}
                            ></Controller>
                            <small className={classes.error}>{errors.venueName?.message}</small>
                        </FormField>
                    </div>
                    <div className={classes.margin}>
                        <FormField label="Phone">
                            <Controller
                                control={control}
                                name="venuePhone"
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        placeholder="e.g. +1 XXX XXX XXXX"
                                        status={
                                            errors.venuePhone?.message
                                                ? Input.StatusError
                                                : ('' as InputStatus)
                                        }
                                    />
                                )}
                            ></Controller>
                            <small className={classes.error}>{errors.venuePhone?.message}</small>
                        </FormField>
                    </div>
                    <div className={classes.margin}>
                        <FormField label="Email">
                            <Controller
                                control={control}
                                name="venueEmail"
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        status={
                                            errors.venueEmail?.message
                                                ? Input.StatusError
                                                : ('' as InputStatus)
                                        }
                                    />
                                )}
                            ></Controller>
                            <small className={classes.error}>{errors.venueEmail?.message}</small>
                        </FormField>
                    </div>
                    <div className={classes.margin}>
                        <FormField label="Website">
                            <Controller
                                control={control}
                                name="venueWebsite"
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        status={
                                            errors.venueWebsite?.message
                                                ? Input.StatusError
                                                : ('' as InputStatus)
                                        }
                                    />
                                )}
                            ></Controller>
                            <small className={classes.error}>{errors.venueWebsite?.message}</small>
                        </FormField>
                    </div>
                </Box>
            </Box>
        </Box>
    );
};
