import { useRef } from 'react';
import {
    FormField,
    Box,
    Layout,
    Cell,
    Accordion,
    accordionItemBuilder,
    InputArea,
} from 'wix-style-react';
import InfoDocument from 'wix-ui-icons-common/Document';
import { Controller } from 'react-hook-form';
import { MesssageKeysTable } from '../message-keys-table';
import { useSelector } from 'react-redux';
import { selectConnectedAccounts } from '../../../../features/account-slice';
import { IntegrationNotConnected } from './integration-not-connected';
import { LinkedinPageSelection } from '../../../integrations/linkedin-page-selections';

export const LinkedinMessage = ({ control, campaign = {}, trigger, id }) => {
    const messageRef = useRef();
    const { linkedin } = campaign || {};
    if (id === undefined) {
        messageRef.current?.setValue(trigger.message);
    }
    const connectedAccounts = useSelector(selectConnectedAccounts);
    if (!connectedAccounts.hasOwnProperty('linkedin')) {
        return <IntegrationNotConnected actionName="Linkedin" />;
    }
    return (
        <>
            <Box marginTop={2} />

            <LinkedinPageSelection integrations={connectedAccounts} />
            <MesssageKeysTable appendTo="window" />
            <FormField label="Message" required>
                <Controller
                    control={control}
                    name="linkedin.message"
                    render={({ field }) => (
                        <InputArea
                            defaultValue={linkedin?.message}
                            minHeight="120px"
                            maxHeight="300px"
                            maxLength={2200}
                            {...field}
                        />
                    )}
                />
            </FormField>
        </>
    );
};
