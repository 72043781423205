import { useCallback, useState } from 'react';
import * as Icons from 'wix-ui-icons-common';
import {
    Box,
    FormField,
    Heading,
    MarketingPageLayoutContent,
    SectionHelper,
} from 'wix-style-react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/lara-dark-indigo/theme.css';

import Logo from '../../assets/logo.png';
import { classes } from './login.st.css';
import { useHistory } from 'react-router-dom';
import { API_INSTANCE } from '../../features/utils';

export const Login = () => {
    const [query, hash] = window.location.href.split('#')[1].split('?');
    const { error_type = '', email = '' } = Object.fromEntries(new URLSearchParams(hash));
    const history = useHistory();
    return (
        <>
            <Box align="space-between" marginTop={6} width={'80%'} margin={'40px auto'}>
                <Box verticalAlign="middle" gap={3}>
                    <img src={Logo} width={50} /> <Heading appearance="H2">Ticket Spot</Heading>
                </Box>
                <Button
                    className={classes.signUpButton}
                    onClick={() => history.push('signup')}
                    priority="secondary"
                >
                    Join Ticket Spot
                </Button>
            </Box>
            <Box height="840px" verticalAlign="middle" direction="vertical">
                <Box
                    align="center"
                    marginBottom={5}
                    verticalAlign="middle"
                    gap={4}
                    direction="vertical"
                >
                    <div className={classes.welcomeHeader}>Welcome!</div>
                </Box>
                {error_type === 'shopify_requires_login' && (
                    <SectionHelper appearance="success">
                        Thank you for installing Ticket Spot on your Shopify Store. Once you log in,
                        please click the <b>&quot;Connect Shopify&quot;</b> button to finalize the
                        installation.
                    </SectionHelper>
                )}
                {error_type === 'ecwid_requires_login' && (
                    <SectionHelper appearance="success">
                        Thank you for installing Ticket Spot on your Ecwid Store. To complete the
                        setup, please ensure you create a Ticket Spot account using the same email
                        address <b>({email})</b> associated with your Ecwid installation.
                    </SectionHelper>
                )}
                <Box align="center" marginBottom={10} verticalAlign="middle" direction="vertical">
                    <LoginArea initialEmail={email} />
                </Box>
                <MarketingPageLayoutContent />
            </Box>
        </>
    );
};
export const LoginArea = ({ initialEmail = '' }) => {
    const [clicked, setClicked] = useState(false);
    const [email, setEmail] = useState(initialEmail);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');

    const history = useHistory();
    const handleLogin = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);
            setClicked(true);

            if (!email || !password) {
                setLoading(false);
                return;
            }

            const response = await API_INSTANCE.post('/api/v1/auth/login', {
                username: email.trim(),
                password,
            });

            if (response.status === 200) {
                setLoading(false);
                history.push('/');
            }
        } catch (error) {
            // Handle network errors or other exceptions
            setError(error.response.data);
            setLoading(false);
        }
    }, [email, history, password]);
    return (
        <Box
            align="center"
            verticalAlign="middle"
            className={classes.container}
            direction="vertical"
            width={'25%'}
        >
            <Box gap={3} direction="vertical" width={'100%'}>
                <FormField label="Email">
                    <InputText
                        type="text"
                        invalid={clicked && !email}
                        className={`p-inputtext-lg ${classes.input}`}
                        placeholder="name@example.com"
                        value={email}
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FormField>
                <FormField label="Password">
                    <InputText
                        type="password"
                        invalid={clicked && !password}
                        className={`p-inputtext-lg ${classes.input}`}
                        placeholder="password"
                        value={password}
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </FormField>
            </Box>
            <Box direction="vertical" gap={2} align="center" width={'100%'} marginTop={5}>
                <Box width="100%" align="center" direction="vertical" gap={1}>
                    <Button
                        label="Login"
                        size="large"
                        className={classes.button}
                        onClick={() => {
                            void handleLogin();
                            setClicked(true);
                        }}
                        loading={loading}
                    />

                    {error && (
                        <Box gap={1} className={classes.error}>
                            <Icons.StatusAlert /> {error}
                        </Box>
                    )}
                </Box>
                <div
                    className={classes.resetPassword}
                    onClick={() => history.push('/auth/request-reset-password')}
                >
                    Forgot Password?
                </div>
            </Box>
        </Box>
    );
};
