import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { updateSiteSession } from '../../features/site-slice';
import { fetchAccountApi, selectAccount, selectSiteData } from '../../features/account-slice';

export const SiteSessionWrapper = ({ children, siteId, noAuth }) => {
    const { site } = useAppSelector(selectSiteData);
    const [loading, setLoading] = useState(!noAuth);
    const dispatch = useAppDispatch();

    const account = useAppSelector(selectAccount);

    useEffect(() => {
        const updateSession = async () => {
            if (window.brand !== 'wix') {
                await dispatch(updateSiteSession(siteId || site));
                !account && (await dispatch(fetchAccountApi()));
            }
            setLoading(false);
        };
        if (!noAuth) {
            void updateSession();
        }
    }, [dispatch, siteId, noAuth]);

    if (loading) return <></>;

    return <>{children}</>;
};
