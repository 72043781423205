import { useState } from 'react';
import AICreateEventIcon from '../../assets/ai-create-event-icon.png';
import { CustomModalLayout, Modal, Button } from 'wix-style-react';
import CreateEventWithAI from './create-ai-event';
import { classes } from './create-ai-event.st.css';
export const CreateWithAIModal = () => {
    const [isModalOpened, setModalOpened] = useState(false);

    const openModal = () => setModalOpened(true);
    const closeModal = () => setModalOpened(false);

    const renderForm = () => <CreateEventWithAI closeModal={closeModal} />;

    const renderModalContent = () => (
        <CustomModalLayout
            secondaryButtonText="Close"
            onCloseButtonClick={closeModal}
            secondaryButtonOnClick={closeModal}
            title="Event Creation with AI: Create Your Perfect Event in Seconds"
            width="800px"
        >
            {renderForm()}
        </CustomModalLayout>
    );

    return (
        <>
            <Button
                onClick={openModal}
                prefixIcon={<img src={AICreateEventIcon} width={20} />}
                className={classes.button}
            >
                AI Quick Start
            </Button>
            <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
                {renderModalContent()}
            </Modal>
        </>
    );
};
