export const TRIAL_PLAN = 'trial';
export const STARTER_PLAN = 'starter';
export const FREE_PLAN = 'free';
export const GROWTH_PLAN = 'growth';
export const BUSINESS_PLAN = 'busines';
export const ENTERPRISE_PLAN = 'enterprise';

export const growthPlanEligible = (settings) => {
    if (!settings) return false;
    return [TRIAL_PLAN, STARTER_PLAN, GROWTH_PLAN, BUSINESS_PLAN].includes(settings.plan);
};
export const businessPlanEligible = (settings) => {
    if (!settings) return false;
    return [TRIAL_PLAN, STARTER_PLAN, BUSINESS_PLAN, TRIAL_PLAN].includes(settings.plan);
};
