import { SFC, useCallback, useState } from 'react';
import { classes } from './ticket-confirmation-email-information.st.css';
import moment from 'moment-timezone';
import * as Icons from 'wix-ui-icons-common';

import {
    Box,
    Button,
    InfoIcon,
    Loader,
    MessageModalLayout,
    Modal,
    Text,
    TextButton,
    Tooltip,
} from 'wix-style-react';
import TriggerSuccessGif from '../../assets/trigger-success.gif';
import EmailSendFailed from '../../assets/email-send-failed.gif';
import { API_INSTANCE, buildURL } from '../../features/utils';
import { useSelector } from 'react-redux';
import { selectSiteData } from '../../features/account-slice';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../hooks';
import { triggerEngagement } from '../../features/campaign-slice';

export const TicketConfirmationEmailInformation: SFC<any> = ({ attendee, event }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [workflow, setWorkflow] = useState(null);

    const site = useSelector(selectSiteData);

    const handleWorkflow = useCallback(async () => {
        setLoading(true);
        const actionLog = attendee.action_log || [];
        const orderTrigger = actionLog.find((log) => log.trigger_type === 'NEW_ORDER');
        if (orderTrigger?.workflow_id) {
            const url = `/api/v1/dashboard/workflows/${orderTrigger.workflow_id}`;
            const { data } = await API_INSTANCE.get(buildURL(site, url));
            setWorkflow(data);
        }
        setLoading(false);
    }, [attendee.id]);

    return (
        <>
            <Modal isOpen={isModalOpen}>
                <MessageModalLayout
                    illustration={
                        <img
                            src={
                                attendee.email_action_completed
                                    ? TriggerSuccessGif
                                    : EmailSendFailed
                            }
                            width={'100%'}
                        />
                    }
                    title={`Ticket Confirmation: ${attendee.email}`}
                    primaryButtonText="Close"
                    primaryButtonOnClick={() => setModalOpen(false)}
                >
                    {isLoading ? (
                        <Loader size="small" />
                    ) : (
                        <EngagementMessage event={event} workflow={workflow} attendee={attendee} />
                    )}
                </MessageModalLayout>
            </Modal>
            <Box direction="vertical" gap={2} align="center">
                <EmailValid isValid={attendee.email_action_completed} />
                <TextButton
                    onClick={() => {
                        handleWorkflow();
                        setModalOpen(true);
                    }}
                >
                    More Info
                </TextButton>
            </Box>
        </>
    );
};

const EngagementMessage = ({ workflow, event, attendee }) => {
    const history = useHistory();
    const site = useSelector(selectSiteData);

    if (!attendee.email_action_completed) {
        return (
            <Box direction="vertical" gap={2}>
                <Text>
                    A confirmation was not sent. You can send it now by clicking the button below,
                    provided you have configured the email engagement.
                </Text>
                <Box>
                    <SendTicketConfirmationButton
                        attendee={attendee}
                        buttonText="Send Order Confirmation"
                    />
                </Box>
            </Box>
        );
    }

    const formattedDate = moment
        .tz(workflow?.execution_date, event.timezone)
        .format('MMMM Do YYYY');
    const formattedTime = moment.tz(workflow?.execution_date, event.timezone).format('h:mm A');
    return (
        <Box direction="vertical" gap={2}>
            <Box>
                <Text>
                    Ticket email confirmation sent on <strong>{formattedDate}</strong> at{' '}
                    <strong>{formattedTime}</strong>.
                </Text>
            </Box>
            <Box align="center">
                <Button
                    size="small"
                    label="View Email Engagement"
                    onClick={() =>
                        history.push(
                            buildURL(site, `/events/campaigns/manage/${workflow?.campaign?.id}`)
                        )
                    }
                >
                    View Email Engagement
                </Button>
            </Box>
        </Box>
    );
};

export const EmailValid = ({ isValid }) => {
    if (isValid) {
        return (
            <span className={classes.success}>
                <Tooltip content={`Ticket email confirmation sent.`}>
                    <svg viewBox="0 0 16 16" fill="currentColor" width="25" height="25">
                        <path d="M9,16 C5.13400675,16 2,12.8659932 2,9 C2,5.13400675 5.13400675,2 9,2 C12.8659932,2 16,5.13400675 16,9 C16,12.8659932 12.8659932,16 9,16 Z M10.9393398,7 L8.5,9.43933983 L7.06066017,8 C6.76776695,7.70710678 6.29289322,7.70710678 6,8 C5.70710678,8.29289322 5.70710678,8.76776695 6,9.06066017 L8.5,11.5606602 L12,8.06066017 C12.2928932,7.76776695 12.2928932,7.29289322 12,7 C11.7071068,6.70710678 11.232233,6.70710678 10.9393398,7 Z"></path>
                    </svg>
                </Tooltip>
            </span>
        );
    }

    return (
        <span className={classes.error}>
            <Tooltip content="Ticket email confirmation not sent.">
                <svg viewBox="0 0 16 16" fill="currentColor" width="25" height="25">
                    <path d="M9,16 C5.13400675,16 2,12.8659932 2,9 C2,5.13400675 5.13400675,2 9,2 C12.8659932,2 16,5.13400675 16,9 C16,12.8659932 12.8659932,16 9,16 Z M9,5 C8.44771525,5 8,5.44771525 8,6 L8,8 C8,8.55228475 8.44771525,9 9,9 C9.55228475,9 10,8.55228475 10,8 L10,6 C10,5.44771525 9.55228475,5 9,5 Z M9,10 C8.44771525,10 8,10.4477153 8,11 C8,11.5522847 8.44771525,12 9,12 C9.55228475,12 10,11.5522847 10,11 C10,10.4477153 9.55228475,10 9,10 Z"></path>
                </svg>
            </Tooltip>
        </span>
    );
};

const SendTicketConfirmationButton = ({ attendee, buttonText }) => {
    const [executingTrigger, setExecutingTrigger] = useState(false);
    const [runningExecutionTrigger, setRunningExecutionTrigger] = useState(false);
    const dispatch = useAppDispatch();

    const handleTrigger = useCallback(async () => {
        setRunningExecutionTrigger(true);
        const payload: any = {
            trigger_type: 'NEW_ORDER',
            event_id: attendee?.event?.id,
            attendee_id: attendee.id,
        };
        await dispatch(triggerEngagement(payload));
        setExecutingTrigger(true);
        setRunningExecutionTrigger(false);
    }, [attendee, dispatch]);

    if (executingTrigger) {
        return (
            <Box>
                <Text skin="success">Successfully sent to attendee</Text>
            </Box>
        );
    }
    return (
        <Button
            priority="secondary"
            size="small"
            onClick={handleTrigger}
            prefixIcon={<Icons.Send />}
            disabled={runningExecutionTrigger}
        >
            <Box gap={1} verticalAlign="middle">
                {' '}
                {runningExecutionTrigger && <Loader size="tiny" />} {buttonText}{' '}
                <InfoIcon content="Send this email confirmation to your attendee scared me I need to poop you need to poop play come in" />
            </Box>
        </Button>
    );
};
