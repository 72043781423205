import { SettingsHeader } from '../../../../components/settings-header';
import { ColorPicker } from '../color-picker';

export const BackgroundDesign = () => {
    return (
        <div>
            <SettingsHeader title="Opacity and Color" />

            <ColorPicker
                title="Background"
                colorUpdateKey="widgetBGColor"
                startWithColor="color-1"
            />

            <ColorPicker
                title="Event Listings"
                colorUpdateKey="eventBGColor"
                startWithColor="color-1"
            />

            <ColorPicker
                title="Loading Indicator"
                colorUpdateKey="loadingIndicator"
                startWithColor="color-5"
            />
        </div>
    );
};
