import { useEffect, useState } from 'react';
import { Box, Button, Dropdown, FormField, ToggleSwitch } from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import { AttendeeEventRollupTrigger } from './attendee-event-rollup-button';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { fetchEventAttendeesFilterValues } from '../../features/events-slice';
import { MultiSelect } from 'react-multi-select-component';
import { classes } from './attendee-list-filters.st.css';
import {
    searchAttendeesByEventApi,
    searchAttendeesStatsByEventApi,
} from '../../features/attendees-slice';

const ATTENDEE_STATUS_OPTIONS = [
    { id: 'attending', value: 'Attending' },
    { id: 'checkedIn', value: 'Checked-In' },
    { id: 'notAttending', value: 'Not Attending' },
];
export const AttendeeListFilters = ({ event }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEmailLoading, setEmailIsLoading] = useState(false);
    const [ticketFilter, setTicketFilter] = useState();
    const [attendeeFilter, setAttendeeFilter] = useState([]);
    const [attendeeNameFilter, setAttendeeNameFilter] = useState([]);
    const [filterOccurrences, setFilterOccurrences] = useState([]);
    const [ticketFilterOptions, setTicketFilterOptions] = useState();
    const [occurenceEventsOptions, setOccurenceEventsOptions] = useState([]);
    const [attendeeFilterOptions, setAttendeeFilterOptions] = useState([]);
    const [attendeeNameFilterOptions, setAttendeeNameFilterOptions] = useState([]);
    const [filterAttendeeStatus, setFilterStatus] = useState();
    const [filterWaitlistSubscriber, setWaitlistSubscriber] = useState(false);
    const [attendeeOrder, setAttendeeOrder] = useState<string | null>(null);
    const [occurrencesSet, setOccurrencesSet] = useState(false);
    const [attendeeFilterValues, setAttendeeFilterValues] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            setFilterOccurrences([]);
            const { payload } = await dispatch(
                fetchEventAttendeesFilterValues({ eventId: event.id })
            );

            setIsLoading(false);
            setAttendeeFilterValues(payload);
        }
        if (event?.id) {
            fetchData();
        }
    }, [dispatch, event?.id]);
    useEffect(() => {
        async function fetchData() {
            setEmailIsLoading(true);
            const { payload } = await dispatch(
                fetchEventAttendeesFilterValues({
                    eventId: event.id,
                    criteria: {
                        selected_event_ids: filterOccurrences.map((item) => item.value).join(','),
                    },
                })
            );
            setAttendeeFilterValues(payload);
            setEmailIsLoading(false);
        }
        if (event?.id && filterOccurrences.length > 0) {
            fetchData();
        }
    }, [dispatch, event?.id, filterOccurrences]);

    useEffect(() => {
        if (attendeeFilterValues.attendees) {
            setAttendeeFilterOptions(
                attendeeFilterValues.attendees.map((val) => ({
                    value: val.email,
                    label: val.email,
                }))
            );
            setAttendeeNameFilterOptions(
                attendeeFilterValues.attendees.map((val) => ({
                    value: `${val.first_name || ''} ${val.last_name || ''}`,
                    label: `${val.first_name || ''} ${val.last_name || ''}`,
                }))
            );
        }
        if (attendeeFilterValues.tickets) {
            setTicketFilterOptions(
                attendeeFilterValues.tickets.map((val) => ({
                    id: val.ticket_uuid,
                    value: val.name,
                }))
            );
        }
        if (attendeeFilterValues.occurrences && !occurrencesSet) {
            const occurrenceOptions = attendeeFilterValues.occurrences.map((val) => ({
                value: val.id,
                label: `${moment(val.start_date).tz(val.timezone).format('ddd DD MMM HH:mm a')}${
                    val.stats && Object.keys(val.stats).length !== 0
                        ? ` (${val.stats.attendance_count})`
                        : ''
                }`,
            }));

            setOccurenceEventsOptions(occurrenceOptions);
            setFilterOccurrences(occurrenceOptions);

            // Mark as set so it doesn't run again
            setOccurrencesSet(true);
        }
    }, [attendeeFilterValues, occurrencesSet]);

    useEffect(() => {
        const isFilterSet =
            attendeeOrder ||
            filterAttendeeStatus ||
            attendeeFilter.length > 0 ||
            attendeeNameFilter.length > 0 ||
            ticketFilter ||
            filterOccurrences.length > 0;
        const fetchData = async () => {
            const requestBody = {
                attendee_order: attendeeOrder,
                selected_attendee_status: filterAttendeeStatus?.id || '',
                selected_attendee_email: attendeeFilter.map((item) => item.value).join(','),
                selected_name_email: attendeeNameFilter.map((item) => item.value).join(','),
                selected_ticket_uuid: ticketFilter?.id || '',
                selected_event_ids: filterOccurrences.map((item) => item.value).join(','),
                show_waitlist_subscribers: filterWaitlistSubscriber,
                is_filtered: isFilterSet,
            };

            if (occurrencesSet && event?.id) {
                await Promise.all([
                    dispatch(
                        searchAttendeesByEventApi({ eventId: event.id, criteria: requestBody })
                    ),
                    dispatch(
                        searchAttendeesStatsByEventApi({ eventId: event.id, criteria: requestBody })
                    ),
                ]);
            }
        };
        fetchData();
    }, [
        attendeeOrder,
        filterAttendeeStatus,
        attendeeFilter,
        attendeeNameFilter,
        ticketFilter,
        filterOccurrences,
        filterWaitlistSubscriber,
        dispatch,
        event?.id,
    ]);

    const updateFiltersAndCallApi = (setter) => (newValue) => {
        setter(newValue);
    };

    return (
        <Box width={'100%'} gap={2} align="left" verticalAlign="middle">
            <Box gap={2}>
                <Box width={250}>
                    <MultiSelect
                        options={occurenceEventsOptions}
                        onChange={updateFiltersAndCallApi(setFilterOccurrences)}
                        value={filterOccurrences}
                        labelledBy="Select Events"
                        overrideStrings={{
                            selectSomeItems: 'Select Events...',
                            allItemsAreSelected: 'All Events',
                        }}
                        hasSelectAll
                        isLoading={isLoading}
                        className={classes.maxWidth}
                    />
                </Box>
                <Box width={250}>
                    <MultiSelect
                        options={attendeeFilterOptions}
                        closeOnChangedValue
                        onChange={(opts) => {
                            const result = opts.filter(
                                (opt) =>
                                    !attendeeFilter.some((attendee) => attendee.value === opt.value)
                            );
                            updateFiltersAndCallApi(setAttendeeFilter)(result);
                        }}
                        value={attendeeFilter}
                        labelledBy="Select Email"
                        overrideStrings={{ selectSomeItems: 'Select Email...' }}
                        isLoading={isEmailLoading}
                        hasSelectAll={false}
                        className={classes.maxWidth}
                    />
                </Box>
                <Box width={250}>
                    <MultiSelect
                        options={attendeeNameFilterOptions}
                        closeOnChangedValue
                        onChange={(opts) => {
                            const result = opts.filter(
                                (opt) =>
                                    !attendeeNameFilter.some(
                                        (attendee) => attendee.value === opt.value
                                    )
                            );
                            updateFiltersAndCallApi(setAttendeeNameFilter)(result);
                        }}
                        value={attendeeNameFilter}
                        labelledBy="Select Name"
                        overrideStrings={{ selectSomeItems: 'Select Name...' }}
                        isLoading={isEmailLoading}
                        hasSelectAll={false}
                        className={classes.maxWidth}
                    />
                </Box>
                <Box width={250}>
                    <Dropdown
                        clearButton
                        onClear={updateFiltersAndCallApi(setTicketFilter)}
                        status={isLoading ? 'loading' : null}
                        placeholder="Select Ticket"
                        options={ticketFilterOptions}
                        onSelect={updateFiltersAndCallApi(setTicketFilter)}
                        className={classes.maxWidth}
                    />
                </Box>
                <Box width={250}>
                    <Dropdown
                        clearButton
                        onClear={updateFiltersAndCallApi(setFilterStatus)}
                        status={isLoading ? 'loading' : null}
                        placeholder="Select Status"
                        options={ATTENDEE_STATUS_OPTIONS}
                        onSelect={updateFiltersAndCallApi(setFilterStatus)}
                        className={classes.maxWidth}
                    />
                </Box>
                <Box width={250}>
                    <FormField label="Show Waitlist Subscribers" labelPlacement="right">
                        <ToggleSwitch
                            onChange={(e) =>
                                updateFiltersAndCallApi(setWaitlistSubscriber)(e.target.checked)
                            }
                            checked={filterWaitlistSubscriber}
                        />
                    </FormField>
                </Box>
            </Box>

            {/* <Tooltip content="Sort Attendee Names in Ascending Order">
                <IconButton
                    onClick={() => {
                        setAttendeeOrder('asc');
                        handleSearch();
                    }}
                >
                    <Icons.SortAZ />
                </IconButton>
            </Tooltip>

            <Tooltip content="Sort Attendee Names in Descending Order">
                <IconButton
                    onClick={() => {
                        setAttendeeOrder('desc');
                        handleSearch();
                    }}
                >
                    <Icons.SortZA />
                </IconButton>
            </Tooltip> */}
            <Button
                size="small"
                prefixIcon={<Icons.Revert />}
                onClick={() => {
                    setAttendeeOrder(null);
                    setAttendeeFilter([]);
                    setAttendeeNameFilter([]);
                    setTicketFilter('');
                    setFilterStatus('');
                    // handleSearch();
                }}
            >
                Reset
            </Button>
            {process.env.NODE_ENV !== 'production' && <AttendeeEventRollupTrigger event={event} />}
        </Box>
    );
};
