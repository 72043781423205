import { SettingsHeader } from '../../../components/settings-header';
import { TextInput } from './text-input';

export const CheckoutGeneralText = ({ label }) => {
    return (
        <>
            <SettingsHeader title={label} />

            <TextInput
                isMultiLine
                maxLength={300}
                title="Form Pre Text"
                tooltipText="Initial checkout information text shown to users when they open the checkout."
                shouldDebounce
                updateKey="checkoutPreText"
            />
            <TextInput
                title="Next Button Text"
                tooltipText="Set the text for the button moving users to the next checkout step."
                shouldDebounce
                updateKey="checkoutNextButtonText"
            />
            <TextInput
                title="Back Button Text"
                tooltipText="Set the text for the button returning users to the previous checkout step."
                shouldDebounce
                updateKey="checkoutBackButtonText"
            />
            <TextInput title="No Tickets Selected Text" updateKey="checkoutNoTicketsSelectedText" />
        </>
    );
};
