import {
    Box,
    Card,
    Button,
    Dropdown,
    Row,
    Col,
    Search,
    Table,
    TableActionCell,
    TableToolbar,
    MarketingPageLayout,
    MarketingPageLayoutContent,
    Badge,
    Loader,
    Heading,
    Text,
    EmptyState,
    Image,
} from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import { classes } from './create-campaign.st.css';
import AddSmall from 'wix-ui-icons-common/AddSmall';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import {
    fetchCampaignsApi,
    selectCampaignsList,
    selectCampaignIsLoading,
    deleteCampaignsApi,
    stopBulkCampaignsApi,
    deleteBulkCampaignsApi,
    startBulkCampaignsApi,
} from '../../features/campaign-slice';
import type { Campaign } from '../../types';
import { useAppDispatch } from '../../hooks';
import { useMemo, useEffect } from 'react';
import { PlanNotification } from '../../components/plan-notification';
import { buildURL } from '../../features/utils';
import { selectSiteData } from '../../features/account-slice';
import { DeleteConfirmation } from '../../components/delete-confirmation';
import moment from 'moment-timezone';
import NoAttendees from '../../assets/no-attendees.gif';

const NoCampaign = () => {
    const history = useHistory();
    const site = useSelector(selectSiteData);

    return (
        <Box verticalAlign="middle" align="center">
            <Card>
                <MarketingPageLayout
                    horizontalSize="medium"
                    verticalSize="medium"
                    content={
                        <Box verticalAlign="middle" align="center" height="260px">
                            <MarketingPageLayoutContent
                                size="medium"
                                title="Promote to Social Media"
                                content="Our automated post creation and publishing feature lets you effortlessly enhance engagement, while freeing up more time to focus on other aspects of your event planning."
                                actions={
                                    <Button
                                        size="large"
                                        onClick={() =>
                                            history.push(
                                                buildURL(site, `/events/promotions/manage`)
                                            )
                                        }
                                    >
                                        Get Started
                                    </Button>
                                }
                            />
                        </Box>
                    }
                />
            </Card>
        </Box>
    );
};
const applyFilters = (campaign: Campaign, searchText: string, selectedStatus: string) => {
    const eventBasedFilters = [
        (campaign: Campaign) =>
            selectedStatus === 'all' || (campaign.enabled && selectedStatus === 'started'),
        (campaign: Campaign) => campaign?.name?.toLowerCase()?.includes(searchText.toLowerCase()),
    ];
    return eventBasedFilters.every((filterFunc) => filterFunc(campaign));
};
export const PromotionList = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [activeSearch, setActiveSearch] = useState('');
    const [selectedStatus, setStatusFilter] = useState('all');

    const campaigns = useSelector(selectCampaignsList);
    const isLoading = useSelector(selectCampaignIsLoading);
    const site = useSelector(selectSiteData);

    const selectedCampaigns = useMemo(() => {
        return campaigns
            .filter((campaign) => applyFilters(campaign, activeSearch, selectedStatus))
            .filter((c) => c.schedule_type === 3);
    }, [activeSearch, selectedStatus, campaigns]);

    useEffect(() => {
        dispatch(fetchCampaignsApi());
    }, [dispatch]);

    const deleteCallback = useCallback(
        (id) => {
            dispatch(deleteCampaignsApi(id));
        },
        [dispatch]
    );

    const _renderSearch = (expandable) => {
        return (
            <Search
                expandable={expandable}
                onChange={(e) => {
                    setActiveSearch(e.target.value);
                }}
            />
        );
    };

    const _renderMainToolbar = () => {
        return (
            <Card>
                <TableToolbar>
                    <TableToolbar.ItemGroup position="start">
                        <TableToolbar.Item>
                            <Box>
                                <TableToolbar.Label>Status</TableToolbar.Label>
                                <Box width="150px" marginLeft={2}>
                                    <Dropdown
                                        options={[
                                            { id: 'all', value: 'All' },
                                            { id: 'started', value: 'Started' },
                                            { id: 'stopped', value: 'Stopped' },
                                        ]}
                                        selectedId={selectedStatus}
                                        onSelect={(selectedOption) => {
                                            setStatusFilter(selectedOption.id);
                                        }}
                                        roundInput
                                    />
                                </Box>
                            </Box>
                        </TableToolbar.Item>
                    </TableToolbar.ItemGroup>
                    <TableToolbar.ItemGroup position="end">
                        <TableToolbar.Item>{_renderSearch(false)}</TableToolbar.Item>
                    </TableToolbar.ItemGroup>
                </TableToolbar>
            </Card>
        );
    };

    const secondaryActions = (row: Campaign) => {
        return [
            {
                icon: <Icons.Delete />,
                text: 'Delete',
                onClick: () => deleteCallback(row.id),
            },
            {
                icon: <Icons.List />,
                text: 'Logs',
                onClick: () =>
                    history.push(buildURL(site, `/events/campaigns/manage/${row.id}/logs`)),
            },
        ];
    };

    const primaryAction = (row) => ({
        icon: <Icons.Edit />,
        text: 'Edit',
        onClick: () => {
            history.push(buildURL(site, `/events/promotions/manage/${row.id}`));
        },
    });
    const columns = [
        { title: 'Name', render: (row: Campaign) => row.name },

        {
            title: 'Next execution',
            width: '20%',
            render: (row: Campaign) => {
                if (row.scheduled_job) {
                    const { seconds } = row.scheduled_job.scheduleTime;
                    return moment(+seconds * 1000, 'x')
                        .tz(row.schedule.timezone)
                        .format('DD MMM YYYY h:mm a');
                }
                return 'not available';
            },
        },
        {
            title: 'Status',
            width: '20%',
            render: (row: Campaign) =>
                row.enabled ? (
                    <Badge skin="neutralSuccess" size="small">
                        Started
                    </Badge>
                ) : (
                    <Badge skin="warningLight" size="small">
                        Stopped
                    </Badge>
                ),
        },
        {
            width: '10%',
            render: (row: Attendee) => <TableActionCell primaryAction={primaryAction(row)} />,
        },
        {
            width: '5%',
            render: (row: Campaign) => <TableActionCell secondaryActions={secondaryActions(row)} />,
        },
    ];

    if (isLoading) {
        return (
            <Box marginTop={5} align="center" justifyContent="center">
                <Loader size="medium" />
            </Box>
        );
    }
    const ActionsToolbar = ({ selectedCount, getSelectedIds }) => (
        <TableToolbar>
            <TableToolbar.ItemGroup position="start">
                <TableToolbar.Item>
                    <TableToolbar.SelectedCount>{`${selectedCount} Selected`}</TableToolbar.SelectedCount>
                </TableToolbar.Item>
            </TableToolbar.ItemGroup>
            <TableToolbar.ItemGroup position="end">
                <TableToolbar.Item layout="button">
                    <Button
                        skin="light"
                        prefixIcon={<Icons.PlayFilled />}
                        onClick={async () => {
                            try {
                                await dispatch(startBulkCampaignsApi(getSelectedIds()));
                            } catch (e) {
                                console.error(e);
                            }
                        }}
                    >
                        Start
                    </Button>
                </TableToolbar.Item>
                <TableToolbar.Item layout="button">
                    <Button
                        skin="light"
                        prefixIcon={<Icons.StopFilled />}
                        onClick={() => dispatch(stopBulkCampaignsApi(getSelectedIds()))}
                    >
                        Stop
                    </Button>
                </TableToolbar.Item>
                <TableToolbar.Item layout="button">
                    <DeleteConfirmation
                        buttonText="Delete"
                        placement="top"
                        successClick={() => dispatch(deleteBulkCampaignsApi(getSelectedIds()))}
                    />
                </TableToolbar.Item>
            </TableToolbar.ItemGroup>
        </TableToolbar>
    );
    return (
        <Box direction="vertical" width={'100%'} marginTop={4}>
            {campaigns.filter((c) => c.schedule_type === 3).length === 0 ? (
                <NoCampaign />
            ) : (
                <Row className={classes.row}>
                    <Col span={12} className={classes.box}>
                        <Box
                            align="center"
                            direction="vertical"
                            verticalAlign="space-between"
                            padding="24px 29px 27px"
                            borderRadius="8px"
                            backgroundColor="white"
                            height="100%"
                            width="100%"
                            boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                            flexGrow={1}
                        >
                            <Card className={classes.fullWidth}>
                                <Box gap={2} marginBottom={4} align="space-between">
                                    <Box align="left" direction="vertical" width="80%">
                                        <Heading appearance="H2">Social Media Promotion</Heading>
                                        <Text size="small" weight="normal">
                                            Upgrade your social media strategy with our cutting-edge
                                            feature that automatically creates and publishes posts,
                                            saving you valuable time and effort.
                                        </Text>
                                    </Box>
                                    <Box align="right" marginBottom={2}>
                                        <Button
                                            onClick={() =>
                                                history.push(
                                                    buildURL(site, `/events/promotions/manage`)
                                                )
                                            }
                                            prefixIcon={<AddSmall />}
                                        >
                                            Add Promotion
                                        </Button>
                                    </Box>
                                </Box>
                                <PlanNotification type="business" text="promotion">
                                    {campaigns?.length > 0 && (
                                        <Table
                                            showSelection
                                            data={selectedCampaigns}
                                            columns={columns}
                                        >
                                            <Table.ToolbarContainer>
                                                {(selectionContext: any) =>
                                                    selectionContext.selectedCount === 0
                                                        ? _renderMainToolbar()
                                                        : ActionsToolbar({ ...selectionContext })
                                                }
                                            </Table.ToolbarContainer>

                                            <Table.Content />
                                        </Table>
                                    )}
                                    {selectedCampaigns.length === 0 && (
                                        <EmptyState
                                            theme="page-no-border"
                                            image={
                                                <Image
                                                    width="100px"
                                                    height="100px"
                                                    src={NoAttendees}
                                                    transparent
                                                />
                                            }
                                            title="No Promotions Found"
                                        />
                                    )}
                                </PlanNotification>
                            </Card>
                        </Box>
                    </Col>
                </Row>
            )}
        </Box>
    );
};
