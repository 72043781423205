import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import type { CampaignLog, Workflow } from '../types';
import { API_INSTANCE, buildURLWithState } from './utils';

export type CampaignLogsSliceState = {
    isLoading: boolean;
    data: CampaignLog[];
    workflows: Workflow[];
    error: Error | null;
};

const initialState: CampaignLogsSliceState = {
    isLoading: false,
    data: [],
    workflows: [],
    error: null,
};

export const fetchCampaignLogsApi = createAsyncThunk(
    'campaigns/fetch',
    async (id: string, { getState }) => {
        return API_INSTANCE.get(
            buildURLWithState(getState(), `/api/v1/dashboard/campaigns/${id}/logs`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const fetchWorkflowsApi = createAsyncThunk(
    'workflows/fetch',
    async (id: string, { getState }) => {
        return API_INSTANCE.get(
            buildURLWithState(getState(), `/api/v1/dashboard/campaigns/${id}/workflows`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const campaignLogsSlice = createSlice({
    name: 'campaignLogs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCampaignLogsApi.pending, (state, action) => {
            state.data = action.payload;
            state.isLoading = true;
        });
        builder.addCase(fetchCampaignLogsApi.fulfilled, (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
        });
        builder.addCase(fetchWorkflowsApi.fulfilled, (state, action) => {
            state.workflows = action.payload;
            state.isLoading = false;
        });
    },
});

export const selectCampaignLogsList = (state: RootState) => state?.campaignLogs?.data;
export const selectCampaignWorkflowList = (state: RootState) => state?.campaignLogs?.workflows;

export default campaignLogsSlice;
