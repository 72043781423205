import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Badge,
    Box,
    Button,
    Card,
    Divider,
    Heading,
    InfoIcon,
    Loader,
    SectionHelper,
    Text,
} from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import { selectSiteData } from '../../features/account-slice';
import { API_INSTANCE, buildURL } from '../../features/utils';
import { classes } from './create-events.st.css';
import { fetchSite, selectSite } from '../../features/site-slice';
import { useAppDispatch } from '../../hooks';
import { PublishShopifyButton } from './publish-shopify-button';
import moment from 'moment-timezone';
import SaveEventModal from './components/save-event-modal';
import { DeleteConfirmation } from '../../components/delete-confirmation';
import { PublishEcwidButton } from './publish-ecwid-button';
import PublishIcon from '../../assets/publish-celebration.svg';
import { ShopifyDisconnectButton } from './shopify-disconnect-button';
import { ShopifyProductConfigModal } from './shopify-product-config';

export const EditEventHeader = ({ saved, event, isCopy, saving, id, handleSubmit, error }) => {
    const history = useHistory();
    const site = useSelector(selectSiteData);
    const dispatch = useAppDispatch();

    const [messageType, setMessageType] = useState(null);

    const deleteEvent = useCallback(async () => {
        await API_INSTANCE.delete(buildURL(site, `/api/v1/dashboard/events/${id}`));
        window.history.back();
    }, []);
    const cancelEvent = useCallback(async () => {
        await API_INSTANCE.put(buildURL(site, `/api/v1/dashboard/events/${id}/`));
        window.history.back();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            if (site?.site) {
                await dispatch(fetchSite(site.site));
            }
        };
        void fetchData();
    }, [dispatch, site.site]);
    const currentSite = useSelector(selectSite);

    const cancelText = `Cancel ${event?.isSeriesParent ? ' all ' : ''}${
        event?.isSeriesParent ? 'Events' : 'Event'
    }`;
    return (
        <Box width="100%" height="100%" marginBottom={4} marginTop={4} direction="vertical">
            <Box direction="horizontal" width="100%">
                <Box align="left">
                    <Button
                        className={classes.cancel}
                        onClick={() => history.push(buildURL(site, `/events/`))}
                    >
                        Exit
                    </Button>
                </Box>
                <Box direction="vertical" align="right" width="100%">
                    <Box gap="2" marginLeft={4}>
                        {!isCopy && (
                            <DeleteConfirmation
                                successClick={deleteEvent}
                                buttonText={`Delete`}
                                tooltipContent="Cancels an event, removes it from the list, and sets all attendees' status to 'Not Attending'."
                            />
                        )}
                        {!isCopy &&
                            event?.type === 'eventviewer' &&
                            event?.status !== 'cancelled' &&
                            !event?.requiresApproval && (
                                <DeleteConfirmation
                                    successClick={handleSubmit('cancelled')}
                                    buttonText={cancelText}
                                    skin="standard"
                                    priority="secondary"
                                    tooltipContent="Cancel the event and set all attendees' status to 'Not Attending'."
                                />
                            )}

                        {event && event.status === 'cancelled' && (
                            <Button
                                prefixIcon={<Icons.Undo />}
                                className={classes.cancel}
                                onClick={handleSubmit('draft')}
                                skin="dark"
                                priority="secondary"
                            >
                                Restore as draft
                            </Button>
                        )}

                        {(!event || isCopy) && (
                            <Box gap={1}>
                                <Button
                                    onClick={() => {
                                        setMessageType('publishedSuccess');
                                        handleSubmit('draft');
                                    }}
                                    disabled={saving || (event && event.type !== 'eventviewer')}
                                >
                                    Save as draft
                                </Button>
                            </Box>
                        )}
                        {(isCopy ||
                            !event ||
                            event?.status === 'draft' ||
                            event?.requiresApproval) && (
                            <Box gap={1}>
                                <Button
                                    suffixIcon={
                                        <img
                                            src={PublishIcon}
                                            style={{ width: 20, height: 'auto' }}
                                        />
                                    }
                                    onClick={() => {
                                        setMessageType('publishedSuccess');
                                        handleSubmit('live')();
                                    }}
                                    className={classes.publishButton}
                                    disabled={saving || (event && event.type !== 'eventviewer')}
                                >
                                    {event?.requiresApproval ? 'Approve and Publish' : 'Publish'}
                                </Button>
                            </Box>
                        )}
                        {event &&
                            (event.status === 'live' ||
                                event.status === 'draft' ||
                                event.status === 'ended') && (
                                <Box gap={2}>
                                    <Button
                                        suffixIcon={<Icons.Check />}
                                        onClick={() => {
                                            handleSubmit(event.status)();
                                            setMessageType('savedSuccess');
                                        }}
                                        disabled={event && event.type !== 'eventviewer'}
                                    >
                                        Save
                                    </Button>

                                    {/* <Button
                                    onClick={handleSubmit(event.status)}
                                    disabled={event && event.type !== 'eventviewer'}
                                >
                                    Save as ...
                                </Button> */}
                                </Box>
                            )}
                        {event && currentSite?.type === 'shopify' && (
                            <>
                                <Divider direction="vertical" />
                                <Box direction="vertical" gap={1}>
                                    <PublishShopifyButton
                                        handleSubmit={(status, type, extra) => {
                                            handleSubmit(status, type, extra)();
                                            setMessageType('shopifyPublishSuccess');
                                        }}
                                        event={event}
                                    />
                                </Box>
                            </>
                        )}
                        {event && currentSite?.type === 'ecwid' && (
                            <>
                                <Divider direction="vertical" />
                                <Box direction="vertical" gap={1}>
                                    <PublishEcwidButton
                                        handleSubmit={(status, type, extra) => {
                                            handleSubmit(status, type, extra)();
                                            setMessageType('ecwidPublishSuccess');
                                        }}
                                        event={event}
                                    />
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>

            <Box align="right" marginTop={2}>
                {saving && <Loader size="tiny" />}
                {error && <SaveEventModal message={error} messageType="error" />}
                {!error && saved && messageType && <SaveEventModal messageType={messageType} />}
            </Box>
            <Box width="100%" align="center" direction="vertical">
                <Card.Header title={<EventCardHeaderTitle event={event} id={id} />} />
                {event && (
                    <Box>
                        <Badge skin="neutralSuccess" size="medium">
                            Status: <b>{event.status}</b>
                        </Badge>
                    </Box>
                )}
                {event?.shopify && (
                    <Box width={'40%'} align="center" marginTop={3}>
                        <SectionHelper appearance="premium">
                            <Box gap={3} align="center">
                                <Box verticalAlign="center">
                                    <Icons.Link />{' '}
                                </Box>
                                This event is currently linked to your Shopify product with Product
                                ID: {event?.shopify.id}.{' '}
                                <Box width="300" gap={2}>
                                    <Button
                                        skin="inverted"
                                        size="small"
                                        as="a"
                                        href={event?.shopify.url}
                                        target="_blank"
                                    >
                                        View Product
                                    </Button>
                                    <ShopifyDisconnectButton event={event} />
                                    {/* <ShopifyProductConfigModal site={site} event={event} /> */}
                                </Box>
                            </Box>
                        </SectionHelper>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

const EventCardHeaderTitle = ({ id, event }) => {
    const getTitle = () => {
        if (!id) {
            return 'Add Event';
        }

        let title = `Update Event - ${event?.title}`;
        if (event?.seriesParent) {
            const formattedDate = moment(event?.start_date, event?.timezone).format('MMM Do YYYY');
            title += ` (${formattedDate})`;
        }

        return title;
    };

    return (
        <Box gap={2} direction="vertical">
            <Heading appearance="H3">{getTitle()}</Heading>
            <Text>
                {(event?.isMultiDay || event?.repeat) && !event?.isSeriesParent && (
                    <SectionHelper appearance="warning">
                        <Box direction="vertical" gap={2}>
                            <Text>
                                Editing a specific instance of your event, such as a day in a
                                multi-day series or a recurring occurrence.
                            </Text>
                        </Box>
                        <a href="" className={classes.parentEventLink}>
                            Go Back to Main Event
                        </a>
                    </SectionHelper>
                )}
            </Text>
        </Box>
    );
};
