export const TIMEZONE_OPTIONS = [
    {
        id: 'Pacific/Midway',
        value: '(GMT-11:00) Midway Island',
    },
    {
        id: 'Pacific/Samoa',
        value: '(GMT-11:00) Samoa',
    },
    {
        id: 'Pacific/Honolulu',
        value: '(GMT-10:00) Hawaii',
    },
    {
        id: 'America/Anchorage',
        value: '(GMT-08:00) Alaska',
    },
    {
        id: 'America/Los_Angeles',
        value: '(GMT-07:00) Pacific Time (US/Canada)',
    },
    {
        id: 'America/Tijuana',
        value: '(GMT-07:00) Tijuana, Baja California',
    },
    {
        id: 'America/Phoenix',
        value: '(GMT-07:00) Arizona',
    },
    {
        id: 'America/Denver',
        value: '(GMT-06:00) Mountain Time (US/Canada)',
    },
    {
        id: 'America/Chihuahua',
        value: '(GMT-06:00) Chihuahua',
    },
    {
        id: 'America/Mazatlan',
        value: '(GMT-06:00) Mazatlan',
    },
    {
        id: 'America/Regina',
        value: '(GMT-06:00) Saskatchewan',
    },
    {
        id: 'America/Tegucigalpa',
        value: '(GMT-06:00) Central America',
    },
    {
        id: 'America/Chicago',
        value: '(GMT-05:00) Central Time (US/Canada)',
    },
    {
        id: 'America/Toronto',
        value: '(GMT-05:00) Eastern Time (Canada)',
    },
    {
        id: 'America/Mexico_City',
        value: '(GMT-05:00) Mexico City',
    },
    {
        id: 'America/Monterrey',
        value: '(GMT-05:00) Monterrey',
    },
    {
        id: 'America/Bogota',
        value: '(GMT-05:00) Bogota',
    },
    {
        id: 'America/Lima',
        value: '(GMT-05:00) Lima',
    },
    {
        id: 'America/Rio_Branco',
        value: '(GMT-05:00) Rio Branco',
    },
    {
        id: 'America/New_York',
        value: '(GMT-04:00) Eastern Time (US/Canada)',
    },
    {
        id: 'America/Indiana/Indianapolis',
        value: '(GMT-04:00) Indiana (East)',
    },
    {
        id: 'America/Caracas',
        value: '(GMT-04:00) Caracas',
    },
    {
        id: 'America/Manaus',
        value: '(GMT-04:00) Manaus',
    },
    {
        id: 'America/La_Paz',
        value: '(GMT-04:00) La Paz',
    },
    {
        id: 'America/Halifax',
        value: '(GMT-03:00) Atlantic Time (Canada)',
    },
    {
        id: 'America/Santiago',
        value: '(GMT-03:00) Santiago',
    },
    {
        id: 'America/Argentina/Buenos_Aires',
        value: '(GMT-03:00) Georgetown',
    },
    {
        id: 'America/Sao_Paulo',
        value: '(GMT-03:00) Brasilia',
    },
    {
        id: 'America/Montevideo',
        value: '(GMT-03:00) Montevideo',
    },
    {
        id: 'America/St_Johns',
        value: '(GMT-02:30) Newfoundland',
    },
    {
        id: 'America/Godthab',
        value: '(GMT-02:00) Greenland',
    },
    {
        id: 'Atlantic/South_Georgia',
        value: '(GMT-02:00) Mid-Atlantic',
    },
    {
        id: 'Atlantic/Cape_Verde',
        value: '(GMT-01:00) Cape Verde Is.',
    },
    {
        id: 'Atlantic/Azores',
        value: '(GMT+00:00) Azores',
    },
    {
        id: 'Africa/Monrovia',
        value: '(GMT+00:00) Monrovia',
    },
    {
        id: 'Atlantic/Reykjavik',
        value: '(GMT+00:00) Reykjavik',
    },
    {
        id: 'Europe/Dublin',
        value: '(GMT+01:00) Dublin',
    },
    {
        id: 'Europe/Lisbon',
        value: '(GMT+01:00) Lisbon',
    },
    {
        id: 'Europe/London',
        value: '(GMT+01:00) London',
    },
    {
        id: 'Africa/Casablanca',
        value: '(GMT+01:00) Casablanca',
    },
    {
        id: 'Africa/Algiers',
        value: '(GMT+01:00) West Central Africa',
    },
    {
        id: 'Europe/Belgrade',
        value: '(GMT+02:00) Belgrade',
    },
    {
        id: 'Europe/Bratislava',
        value: '(GMT+02:00) Bratislava',
    },
    {
        id: 'Europe/Budapest',
        value: '(GMT+02:00) Budapest',
    },
    {
        id: 'Europe/Ljubljana',
        value: '(GMT+02:00) Ljubljana',
    },
    {
        id: 'Europe/Prague',
        value: '(GMT+02:00) Prague',
    },
    {
        id: 'Europe/Sarajevo',
        value: '(GMT+02:00) Sarajevo',
    },
    {
        id: 'Europe/Skopje',
        value: '(GMT+02:00) Skopje',
    },
    {
        id: 'Europe/Warsaw',
        value: '(GMT+02:00) Warsaw',
    },
    {
        id: 'Europe/Zagreb',
        value: '(GMT+02:00) Zagreb',
    },
    {
        id: 'Europe/Brussels',
        value: '(GMT+02:00) Brussels',
    },
    {
        id: 'Europe/Copenhagen',
        value: '(GMT+02:00) Copenhagen',
    },
    {
        id: 'Europe/Madrid',
        value: '(GMT+02:00) Madrid',
    },
    {
        id: 'Europe/Paris',
        value: '(GMT+02:00) Paris',
    },
    {
        id: 'Europe/Amsterdam',
        value: '(GMT+02:00) Amsterdam',
    },
    {
        id: 'Europe/Berlin',
        value: '(GMT+02:00) Berlin',
    },
    {
        id: 'Europe/Rome',
        value: '(GMT+02:00) Rome',
    },
    {
        id: 'Europe/Stockholm',
        value: '(GMT+02:00) Stockholm',
    },
    {
        id: 'Europe/Vienna',
        value: '(GMT+02:00) Vienna',
    },
    {
        id: 'Africa/Cairo',
        value: '(GMT+02:00) Cairo',
    },
    {
        id: 'Africa/Windhoek',
        value: '(GMT+02:00) Windhoek',
    },
    {
        id: 'Africa/Harare',
        value: '(GMT+02:00) Harare',
    },
    {
        id: 'Europe/Minsk',
        value: '(GMT+03:00) Minsk',
    },
    {
        id: 'Europe/Helsinki',
        value: '(GMT+03:00) Helsinki',
    },
    {
        id: 'Europe/Riga',
        value: '(GMT+03:00) Riga',
    },
    {
        id: 'Europe/Sofia',
        value: '(GMT+03:00) Sofia',
    },
    {
        id: 'Europe/Tallinn',
        value: '(GMT+03:00) Tallinn',
    },
    {
        id: 'Europe/Vilnius',
        value: '(GMT+03:00) Vilnius',
    },
    {
        id: 'Europe/Athens',
        value: '(GMT+03:00) Athens',
    },
    {
        id: 'Europe/Bucharest',
        value: '(GMT+03:00) Bucharest',
    },
    {
        id: 'Europe/Istanbul',
        value: '(GMT+03:00) Istanbul',
    },
    {
        id: 'Asia/Jerusalem',
        value: '(GMT+03:00) Jerusalem',
    },
    {
        id: 'Asia/Amman',
        value: '(GMT+03:00) Amman',
    },
    {
        id: 'Asia/Beirut',
        value: '(GMT+03:00) Beirut',
    },
    {
        id: 'Asia/Kuwait',
        value: '(GMT+03:00) Kuwait',
    },
    {
        id: 'Asia/Riyadh',
        value: '(GMT+03:00) Riyadh',
    },
    {
        id: 'Asia/Baghdad',
        value: '(GMT+03:00) Baghdad',
    },
    {
        id: 'Africa/Nairobi',
        value: '(GMT+03:00) Nairobi',
    },
    {
        id: 'Europe/Moscow',
        value: '(GMT+03:00) Moscow',
    },
    {
        id: 'Europe/Volgograd',
        value: '(GMT+03:00) Volgograd',
    },
    {
        id: 'Asia/Tbilisi',
        value: '(GMT+04:00) Tbilisi',
    },
    {
        id: 'Asia/Muscat',
        value: '(GMT+04:00) Muscat',
    },
    {
        id: 'Asia/Baku',
        value: '(GMT+04:00) Baku',
    },
    {
        id: 'Asia/Yerevan',
        value: '(GMT+04:00) Yerevan',
    },
    {
        id: 'Asia/Tehran',
        value: '(GMT+04:30) Tehran',
    },
    {
        id: 'Asia/Yekaterinburg',
        value: '(GMT+05:00) Ekaterinburg',
    },
    {
        id: 'Asia/Karachi',
        value: '(GMT+05:00) Karachi',
    },
    {
        id: 'Asia/Tashkent',
        value: '(GMT+05:00) Tashkent',
    },
    {
        id: 'Asia/Kolkata',
        value: '(GMT+05:30) Calcutta',
    },
    {
        id: 'Asia/Colombo',
        value: '(GMT+05:30) Sri Jayawardenepura',
    },
    {
        id: 'Asia/Katmandu',
        value: '(GMT+05:45) Kathmandu',
    },
    {
        id: 'Asia/Dhaka',
        value: '(GMT+06:00) Dhaka',
    },
    {
        id: 'Asia/Almaty',
        value: '(GMT+06:00) Almaty',
    },
    {
        id: 'Asia/Urumqi',
        value: '(GMT+06:00) Urumqi',
    },
    {
        id: 'Asia/Rangoon',
        value: '(GMT+06:30) Yangon (Rangoon)',
    },
    {
        id: 'Asia/Novosibirsk',
        value: '(GMT+07:00) Novosibirsk',
    },
    {
        id: 'Asia/Krasnoyarsk',
        value: '(GMT+07:00) Krasnoyarsk',
    },
    {
        id: 'Asia/Bangkok',
        value: '(GMT+07:00) Bangkok',
    },
    {
        id: 'Asia/Jakarta',
        value: '(GMT+07:00) Jakarta',
    },
    {
        id: 'Asia/Brunei',
        value: '(GMT+08:00) Beijing',
    },
    {
        id: 'Asia/Chongqing',
        value: '(GMT+08:00) Chongqing',
    },
    {
        id: 'Asia/Hong_Kong',
        value: '(GMT+08:00) Hong Kong',
    },
    {
        id: 'Asia/Irkutsk',
        value: '(GMT+08:00) Irkutsk',
    },
    {
        id: 'Asia/Ulaanbaatar',
        value: '(GMT+08:00) Ulaan Bataar',
    },
    {
        id: 'Asia/Kuala_Lumpur',
        value: '(GMT+08:00) Kuala Lumpur',
    },
    {
        id: 'Asia/Singapore',
        value: '(GMT+08:00) Singapore',
    },
    {
        id: 'Asia/Taipei',
        value: '(GMT+08:00) Taipei',
    },
    {
        id: 'Australia/Perth',
        value: '(GMT+08:00) Perth',
    },
    {
        id: 'Asia/Seoul',
        value: '(GMT+09:00) Seoul',
    },
    {
        id: 'Asia/Tokyo',
        value: '(GMT+09:00) Tokyo',
    },
    {
        id: 'Asia/Yakutsk',
        value: '(GMT+09:00) Yakutsk',
    },
    {
        id: 'Australia/Darwin',
        value: '(GMT+09:30) Darwin',
    },
    {
        id: 'Australia/Adelaide',
        value: '(GMT+09:30) Adelaide',
    },
    {
        id: 'Australia/Canberra',
        value: '(GMT+10:00) Canberra',
    },
    {
        id: 'Australia/Melbourne',
        value: '(GMT+10:00) Melbourne',
    },
    {
        id: 'Australia/Sydney',
        value: '(GMT+10:00) Sydney',
    },
    {
        id: 'Australia/Brisbane',
        value: '(GMT+10:00) Brisbane',
    },
    {
        id: 'Australia/Hobart',
        value: '(GMT+10:00) Hobart',
    },
    {
        id: 'Asia/Vladivostok',
        value: '(GMT+10:00) Vladivostok',
    },
    {
        id: 'Pacific/Guam',
        value: '(GMT+10:00) Guam',
    },
    {
        id: 'Pacific/Port_Moresby',
        value: '(GMT+10:00) Port Moresby',
    },
    {
        id: 'Asia/Magadan',
        value: '(GMT+11:00) Magadan',
    },
    {
        id: 'Kwajalein',
        value: '(GMT+12:00) International Date Line West',
    },
    {
        id: 'Pacific/Fiji',
        value: '(GMT+12:00) Fiji',
    },
    {
        id: 'Asia/Kamchatka',
        value: '(GMT+12:00) Kamchatka',
    },
    {
        id: 'Pacific/Auckland',
        value: '(GMT+12:00) Auckland',
    },
    {
        id: 'Pacific/Tongatapu',
        value: '(GMT+13:00) Nukualofa',
    },
];
