import { EventAmount } from './event-display-amount';
import { EventStatus } from './event-status';
import { RecurringEvents } from './recurring-events';
import { EventInformation } from './information';
import { Promotion } from './promotion';
import { TimeDate } from './time-date';
import { Visibility } from './visibility';
import { DrilldownContainer } from '../../../components/drilldown';

export const Display = () => {
    return (
        <DrilldownContainer
            title="Display Settings"
            infoText="Customize the appearance and behavior of your event listings, including event details, listing preferences, visitor engagement features, date and time formats, and visibility options."
            sections={[
                {
                    label: 'Event Details',
                    id: 'details',
                    component: <EventInformation />,
                },
                {
                    label: 'Event Count Preferences',
                    id: 'event_count',
                    component: <EventAmount />,
                },
                {
                    label: 'Visitor Engagement',
                    id: 'engagement',
                    component: <Promotion />,
                },
                {
                    label: 'Time and Date',
                    id: 'date',
                    component: <TimeDate />,
                },
                {
                    label: 'Components Visibility',
                    id: 'visibility',
                    component: <Visibility />,
                },
                {
                    label: 'Event Types and Status',
                    id: 'event_types',
                    component: <EventStatus />,
                },
                {
                    label: 'Event Occurrences',
                    id: 'occurrences',
                    component: <RecurringEvents />,
                },
            ]}
        />
    );
};
