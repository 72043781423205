import { useState } from 'react';
import moment from 'moment';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, Button, CustomModalLayout, Loader, Modal } from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import {
    fetchEventOccurrencesApi,
    updateDateWithTimesEventOccurrenceApi,
} from '../../../features/events-slice';
import { useAppDispatch } from '../../../hooks';
import type { Event } from '../../../types';
import { convertToDate, convertToUtc, getMomentDate } from '../create-event';
import { DateWithTimesForm } from './date-with-times-form';

export const EditAddTimeBasedDateEventModel = ({ timeEntryParent, rootEvent }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isModalOpened, setModalOpened] = useState(false);
    const [timeEntries, setTimeEntries] = useState([
        {
            start_time: moment().set({ hour: 8, minute: 0 }),
            end_time: moment().set({ hour: 9, minute: 0 }),
        },
    ]);
    const closeModal = () => {
        reset();
        setModalOpened(false);
    };

    const { handleSubmit, control, setValue, reset, watch } = useForm<Event>({
        defaultValues: {
            timeStart: getMomentDate(timeEntryParent?.dateStart, timeEntryParent?.timezone),
            dateStart: convertToDate(timeEntryParent?.dateStart, timeEntryParent?.timezone),
            timeEnd: getMomentDate(timeEntryParent?.dateEnd, timeEntryParent?.timezone),
            dateEnd: convertToDate(timeEntryParent?.dateEnd, timeEntryParent?.timezone),
            status: timeEntryParent?.status,
        },
    });
    const dispatch = useAppDispatch();

    const onSubmit: SubmitHandler<Event> = async (values) => {
        const start_date = convertToUtc(values.dateStart, timeEntryParent.timezone);
        const end_date = convertToUtc(values.dateEnd, timeEntryParent.timezone);
        setIsSaving(true);
        await dispatch(
            updateDateWithTimesEventOccurrenceApi({
                id: timeEntryParent.id,
                start_date,
                end_date,
                time_entries: timeEntries,
            })
        );
        await dispatch(fetchEventOccurrencesApi(rootEvent));
        setIsSaving(false);
        closeModal();
        reset();
    };

    const renderModalContent = () => (
        <CustomModalLayout
            primaryButtonText={isSaving ? <Loader size="tiny" /> : 'Create'}
            secondaryButtonText="Cancel"
            onCloseButtonClick={closeModal}
            primaryButtonOnClick={handleSubmit(onSubmit)}
            secondaryButtonOnClick={closeModal}
            title="Add More Times"
            width="600px"
            height="80%"
        >
            {
                <DateWithTimesForm
                    key={timeEntryParent?.id}
                    timeEntries={timeEntries}
                    setTimeEntries={setTimeEntries}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                />
            }
        </CustomModalLayout>
    );

    return (
        <Box align="right" marginBottom={3}>
            <Button
                prefixIcon={<Icons.AddSmall />}
                size="small"
                onClick={() => setModalOpened(true)}
            >
                Add Times
            </Button>
            <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
                {renderModalContent()}
            </Modal>
        </Box>
    );
};
