import { ToggleSwitch } from 'wix-style-react';
import { SettingsHeader } from '../../../components/settings-header';
import { WidgetSettingsUpdater } from '../../../components/widget-settings-updater';

export const CheckoutAttendeeInfo = ({ label }) => {
    return (
        <>
            <SettingsHeader title={label} />

            <hr className="divider-long" />

            <WidgetSettingsUpdater
                updateKey="showAttendeeInputsPerTicket"
                tooltipText="Enable this to collect information from each attendee. When enabled, for each ticket quantity selected, a corresponding attendee input field will be displayed for individual attendee details. When disabled, only the buyer's information will be collected."
                title="Per-Ticket Attendee Data"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater updateKey="showConfirmEmail" title="Show Confirm Email">
                <ToggleSwitch />
            </WidgetSettingsUpdater>
        </>
    );
};
