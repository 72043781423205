import { useCallback, useState } from 'react';
import {
    Box,
    Button,
    Cell,
    CustomModalLayout,
    FormField,
    Input,
    Layout,
    Loader,
    Modal,
    Text,
} from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import { API_INSTANCE, buildURL } from '../../../features/utils';
import { useSelector } from 'react-redux';
import { selectAccount, selectSiteData } from '../../../features/account-slice';
import SuccessTestEmail from '../../../assets/success-test-email.gif';

function isValidEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export const TestEmailButton = ({ campaign, watch }) => {
    const [shown, setShown] = useState(false);
    const account = useSelector(selectAccount);
    const [toEmail, setToEmail] = useState<any>(account.email);
    const [firstName, setFirstName] = useState<any>(account.first_name);
    const [lastName, setLastName] = useState<any>(account.last_name);
    const [error, setError] = useState(null);

    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const site = useSelector(selectSiteData);

    const message = watch('message');

    if (campaign.subscribe_all_events) return <span />;
    const handleSend = useCallback(async () => {
        setLoading(true);
        setError(null);
        if (!isValidEmail(toEmail)) {
            setLoading(false);

            setError(`Invalid Email`);
            return;
        }
        const url = `/api/v1/dashboard/campaigns/send-test`;
        try {
            await API_INSTANCE.post(buildURL(site, url), {
                campaign: { ...campaign, message },
                toEmail,
                firstName,
                lastName,
            });
            setTimeout(() => {
                setLoading(false);
                setEmailSent(true);
            }, 300);
        } catch (e) {
            setError(e.response.data);
            setLoading(false);
        }
    }, [campaign, site, toEmail, firstName, lastName]);
    return (
        <>
            <Button
                size="small"
                onClick={() => setShown(true)}
                prefixIcon={<Icons.EmailSendSmall />}
            >
                Send Test Email
            </Button>
            <Modal
                isOpen={shown}
                onRequestClose={() => setShown(false)}
                shouldCloseOnOverlayClick
                screen="desktop"
            >
                <CustomModalLayout
                    primaryButtonText="Close"
                    primaryButtonOnClick={() => {
                        setLoading(false);
                        setEmailSent(false);
                        setShown(false);
                    }}
                    title="Send Test Email"
                    width={'40%'}
                    content={
                        <Box direction="vertical" gap={4}>
                            {!emailSent ? (
                                <Box>
                                    <Layout>
                                        <Cell span={12}>
                                            <FormField label="Enter an email address" required>
                                                <Input
                                                    onChange={(e) => {
                                                        setToEmail(e.currentTarget.value);
                                                    }}
                                                    value={toEmail}
                                                />
                                            </FormField>
                                        </Cell>

                                        <Cell span={6}>
                                            <FormField label="Enter First Name" required>
                                                <Input
                                                    onChange={(e) => {
                                                        setFirstName(e.currentTarget.value);
                                                    }}
                                                    value={firstName}
                                                />
                                            </FormField>
                                        </Cell>
                                        <Cell span={6}>
                                            <FormField label="Enter Last Name" required>
                                                <Input
                                                    onChange={(e) => {
                                                        setLastName(e.currentTarget.value);
                                                    }}
                                                    value={lastName}
                                                />
                                            </FormField>
                                        </Cell>
                                    </Layout>
                                </Box>
                            ) : (
                                <EmailSentMessage email={toEmail} />
                            )}

                            {!emailSent && (
                                <Box>
                                    <Button
                                        prefixIcon={
                                            loading ? (
                                                <Loader size="tiny" />
                                            ) : (
                                                <Icons.EmailSendSmall />
                                            )
                                        }
                                        skin="light"
                                        onClick={handleSend}
                                    >
                                        Send Test
                                    </Button>
                                </Box>
                            )}
                            {error && (
                                <Text size="small" skin="error">
                                    {error}
                                </Text>
                            )}
                        </Box>
                    }
                />
            </Modal>
        </>
    );
};

const EmailSentMessage = ({ email }) => {
    return (
        <Box align="center" gap={2} direction="vertical">
            <Box>
                <img src={SuccessTestEmail} width={150} />
            </Box>
            <Text weight="bold">Test email sent successfully to: {email}</Text>
        </Box>
    );
};
