import { SettingsHeader } from '../../../../components/settings-header';
import { ColorPicker } from '../color-picker';
import { FontColorPicker } from '../font-picker';

export const UserFilterDesign = ({ label }) => {
    return (
        <div>
            <SettingsHeader title={label} />

            <FontColorPicker
                title="Filter Text"
                startWithSize={14}
                fontUpdateKey="_userSelectFont"
                startWithTheme="font_9"
                colorUpdateKey="_userSelectColor"
                startWithColor="color-5"
                fontMaxSize={20}
            />

            <ColorPicker
                title="Filter background"
                colorUpdateKey="_userSelectBackgroundColor"
                startWithColor="color-1"
            />

            <FontColorPicker
                title="Search/Filter button text"
                startWithSize={14}
                startWithTheme="font_9"
                fontUpdateKey="btnUserSearchButtonFont"
                colorUpdateKey="btnUserSearchButtonTextColor"
                startWithColor="color-5"
                fontMaxSize={20}
            />

            <ColorPicker
                title="Search/Filter button background"
                colorUpdateKey="btnUserSearchButtonBackground"
                startWithColor="color-6"
            />

            <ColorPicker title="Icon" colorUpdateKey="userSearchIcon" startWithColor="color-5" />
        </div>
    );
};
