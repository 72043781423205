import { useState, useEffect } from 'react';
import { Text, Loader, Box, Modal, CustomModalLayout } from 'wix-style-react';

export const CancelModal = ({ onSubmit }) => {
    const [loading, setLoading] = useState(true);
    const [cancelled, setCancelled] = useState(false);
    const [shown, setShown] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
            setCancelled(true);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Box>
            <Modal isOpen={shown} onRequestClose={() => setShown(false)} screen="desktop">
                <CustomModalLayout
                    primaryButtonText="Close"
                    primaryButtonOnClick={() => {
                        onSubmit();
                        setShown(false);
                    }}
                    onCloseButtonClick={() => setShown(false)}
                    title="Cancelling Subscription"
                    width={'40%'}
                    content={
                        <Box align="center" direction="vertical" gap={2}>
                            {loading && (
                                <Box>
                                    <Loader size="small" />
                                </Box>
                            )}
                            {loading && <Text>Cancelling your subscription, please wait...</Text>}
                            {cancelled && (
                                <Text>
                                    Your subscription has been cancelled. You have been downgraded
                                    to the free plan.
                                </Text>
                            )}
                        </Box>
                    }
                />
            </Modal>
        </Box>
    );
};
