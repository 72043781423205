import Intercom from 'react-intercom';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { App } from './app';
import * as Sentry from '@sentry/react';
import './reset.st.css';
import { store } from './store';

const queryParams = new URLSearchParams(window.location.search);
const isCreateEvent = queryParams.get('is_create_event');

Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <App />
            </Router>
            {!isCreateEvent && <Intercom appID="m51ut11q" />}
        </Provider>
    </React.StrictMode>,
    document.body.appendChild(document.createElement('div'))
);
