import { useState } from 'react';
import { Box, Heading, IconButton, Button, Popover, Tooltip } from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import { classes } from './delete-confirmation.st.css';

export const DeleteConfirmation = ({
    buttonText,
    confirmText = 'Are you sure?',
    successClick,
    placement = 'right',
    tooltipContent = 'content',
    skin = 'destructive',
    priority = 'primary',
}) => {
    const [shown, setShown] = useState(false);
    return (
        <Popover showArrow shown={shown} appendTo="window" placement={placement}>
            <Popover.Element>
                <Tooltip content={tooltipContent}>
                    <div>
                        {!buttonText && (
                            <IconButton onClick={() => setShown(true)} className={classes.delete}>
                                <Icons.Delete />
                            </IconButton>
                        )}
                        {buttonText && (
                            <Button
                                suffixIcon={<Icons.Delete />}
                                skin={skin}
                                onClick={() => setShown(true)}
                                priority={priority}
                            >
                                <div>{buttonText}</div>
                            </Button>
                        )}
                    </div>
                </Tooltip>
            </Popover.Element>
            <Popover.Content>
                <Box align="center" width={'100%'}>
                    <Heading appearance="H5">{confirmText}</Heading>
                </Box>
                <Box direction="horizontal" gap={2} margin={3} paddingBottom={2}>
                    <Button
                        size="small"
                        onClick={() => {
                            setShown(false);
                            successClick();
                        }}
                    >
                        Yes
                    </Button>
                    <Button priority="secondary" size="small" onClick={() => setShown(false)}>
                        No
                    </Button>
                </Box>
            </Popover.Content>
        </Popover>
    );
};
