import { useMemo, useRef } from 'react';
import {
    Box,
    Button,
    Card,
    Row,
    Col,
    Cell,
    FormField,
    Input,
    Layout,
    InputStatus,
    Loader,
    Notification,
    SectionHelper,
    Page,
} from 'wix-style-react';
import { classes } from './create-campaign.st.css';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useAppSelector } from '../../hooks';
import { useSelector } from 'react-redux';

import {
    selectTriggersList,
    getCampaignApi,
    selectSingleCampaign,
    createCampaignApi,
    updateCampaignApi,
    stopCampaignsApi,
    selectCampaignIsLoading,
} from '../../features/campaign-slice';
import {
    fetchConnectedAccountsApi,
    selectAccount,
    selectSiteData,
} from '../../features/account-slice';
import type { Campaign } from '../../types';
import { useAppDispatch } from './../../hooks';
import {
    eventResponseTransform,
    fetchEventsApi,
    selectEventsList,
} from '../../features/events-slice';
import { uniq } from 'lodash';
import { EventSelection } from './event-selection';
import { ActionSelector } from './action-selector';
import { DateTimeSelector } from './date-time-selector';
import { addTimeToDate, convertToUtc } from '../events/create-event';
import { convertToDate } from '../events/create-event';
import moment from 'moment-timezone';
import { buildURL } from '../../features/utils';
import { PromotionStartModal } from '../events/components/promotion-start-modal';

export const PromotionManage = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectCampaignIsLoading);
    const campaign = id ? useAppSelector(selectSingleCampaign) : null;
    const account = useAppSelector(selectAccount);
    const events = useSelector(selectEventsList);

    useEffect(() => {
        if (id) dispatch(getCampaignApi(id));
    }, [id]);
    if (isLoading) {
        return (
            <Box width="100%" height="100px" align="center" verticalAlign="middle">
                <Loader size="large" />
            </Box>
        );
    }
    return (
        <PromotionInput account={account} events={events} campaign={campaign} isUpdating={!!id} />
    );
};

const PromotionInput = ({ campaign }) => {
    const [expandOccurrences, setExpandOccurrences] = useState(false);

    const [selectedIds, setSelectedIds] = useState<Array<string>>(
        campaign?.event_subscription_criteria
            ? campaign?.event_subscription_criteria.map((e) => e.event_id)
            : []
    );
    const [saved, setSaved] = useState(false);
    const [saving, setSaving] = useState(false);
    const [showStartModal, setShowStartModal] = useState(false);
    const [createdPromotion, setCreatedPromotion] = useState(null);

    const { id } = useParams<{ id: string }>();
    const messageRef = useRef();
    const dispatch = useAppDispatch();

    const eventsBase = useAppSelector(selectEventsList);

    const events = useMemo(() => {
        if (!expandOccurrences) return eventsBase;

        return eventsBase.reduce((res, curr) => {
            const occurrences = curr?.occurrences?.map((e, index) => ({
                ...eventResponseTransform(e),
                title: (
                    <Box marginLeft={4}>
                        {index + 1}. {e?.title}
                    </Box>
                ),
            }));
            return res.concat(curr).concat(occurrences || []);
        }, []);
    }, [eventsBase, expandOccurrences]);

    const { handleSubmit, watch, control, setValue, getValues, formState } = useForm<Campaign>({
        defaultValues: {
            ...campaign,
            scheduled: {
                timezone: campaign?.schedule?.timezone || moment.tz.guess(),
                repeat_on: campaign?.schedule?.repeat_on,
                repeating_days: campaign?.schedule?.repeating_days,
                time_start: moment().set({ hour: 9, minute: 0 }),
                time_end: moment().set({ hour: 17, minute: 0 }),
                date_start: campaign?.schedule?.date_start
                    ? convertToDate(campaign?.schedule?.date_start, campaign?.schedule?.timezone)
                    : moment().toDate(),
                date_end: campaign?.schedule?.date_end
                    ? convertToDate(campaign?.schedule?.date_end, campaign?.schedule?.timezone)
                    : moment().add(7, 'days').toDate(),
            },
            schedule_time_unit: campaign?.schedule_time_unit || 'MINUTES',
            schedule_time_amount: campaign?.schedule_time_amount || 0,
            schedule_time_direction: campaign?.schedule_time_direction || 'BEFORE',
            schedule_type: campaign?.schedule_type || 3,
            trigger_type: campaign?.watchTriggerType || 'NEW_ORDER',
            subscribe_all_events: campaign?.subscribe_all_events,
            attendee_selection_type: campaign?.attendee_selection_type || 'automatic',
            email_editor_type: campaign?.email_editor_type || 'basic',
            action_selections: campaign?.action_selections || [],
            zoho_mappings: campaign?.zoho_mappings || {},
            hubspot_mappings: campaign?.hubspot_mappings || {},
            hubspot_workflow_selections: campaign?.hubspot_workflow_selections || [],
            hubspot_action: campaign?.hubspot_action || 'create-update-contact',
            zoho_action: campaign?.zoho_action || 'create-update-contact',
            sms: {
                message: campaign?.sms?.message,
            },
            twitter: {
                message: campaign?.sms?.message,
            },
            slack: {
                message: campaign?.slack?.message,
            },
            facebook: {
                message: campaign?.facebook?.message,
                pages: campaign?.facebook?.pages,
                enable_instagram: campaign?.facebook?.enable_instagram,
            },
            linkedin: {
                message: campaign?.linkedin?.message,
            },
            constant_contact_action: campaign?.constant_contact_action || 'create-update-contact',
            constant_contact_mappings: campaign?.constant_contact_mappings || {},
            constant_contact_list_ids: campaign?.constant_contact_list_ids || [],
        },
    });

    const watchTriggerType = watch('trigger_type');
    const watchScheduleType = watch('schedule_type');

    const watchSubscribeAllEvents = watch('subscribe_all_events', campaign?.subscribe_all_events);

    const { errors } = formState;
    const triggers = useAppSelector(selectTriggersList);

    useEffect(() => {
        dispatch(fetchConnectedAccountsApi());
        dispatch(fetchEventsApi(watchSubscribeAllEvents ? 'widget-active' : 'parents-only'));
    }, [dispatch, watchSubscribeAllEvents]);

    useEffect(() => {
        if (id === undefined) {
            const trigger = triggers.find((t) => t.value === watchTriggerType);
            setValue('subject', trigger.subject);
            setValue('message', trigger.message);
            messageRef.current?.setValue(trigger.message);
        }
    }, [id, setValue, triggers, watchTriggerType]);
    const actionSelectionType = {
        0: 'email',
        1: 'sms',
        2: 'slack',
        3: 'twitter',
        4: 'facebook',
        5: 'hubspot',
        6: 'zoho',
        7: 'constantContact',
        8: 'webhook',
    };

    const createScheduled = (scheduleType: number, scheduledData: any) => {
        if (scheduleType !== 3) return null;
        const repeatDays = Object.keys(scheduledData)
            .filter((v) => v.startsWith('repeat_day'))
            .map((v) => ({ day: v.replace('repeat_day_', ''), value: !!scheduledData[v] }));

        addTimeToDate(scheduledData.date_start, scheduledData.time_start);
        addTimeToDate(scheduledData.date_end, scheduledData.time_end);

        return {
            timezone: scheduledData.timezone,
            repeat_on: 'weekly',
            repeating_days: repeatDays,
            date_start: convertToUtc(scheduledData.date_start, scheduledData.timezone),
            date_end: convertToUtc(scheduledData.date_end, scheduledData.timezone),
        };
    };
    const onSubmit: SubmitHandler<Campaign> = async (values) => {
        let submitFunc = createCampaignApi;
        if (id) submitFunc = updateCampaignApi;
        setSaved(false);
        setSaving(true);

        const schedule = createScheduled(values.schedule_type, values.scheduled);

        const promotion = {
            id,
            name: values.name,
            schedule,
            message: values.message || '',
            subject: values.subject,
            from_email: values.from_email,
            schedule_type: values.schedule_type,
            schedule_time_amount: values.schedule_time_amount,
            schedule_time_unit: values.schedule_time_unit,
            subscribe_all_events: values.subscribe_all_events,
            schedule_time_direction: values.schedule_time_direction,
            trigger_type: 'PROMOTION',
            event_subscription_criteria: selectedIds.map((id) => ({ event_id: id })),
            attendee_selection_type: values.attendee_selection_type,
            email_editor_type: values.email_editor_type,
            sender_name: values.sender_name,
            action_selections: uniq(values.action_selections),
            hubspot_mappings: values.hubspot_mappings,
            hubspot_workflow_selections: values.hubspot_workflow_selections,
            hubspot_action: values.hubspot_action,
            zoho_action: values.zoho_action,
            zoho_mappings: values.zoho_mappings,
            sms: values.sms,
            twitter: values.twitter,
            facebook: values.facebook,
            linkedin: values.linkedin,
            slack: values.slack,
            webhook: values.webhook,
            constant_contact_action: values.constant_contact_action,
            constant_contact_mappings: values.constant_contact_mappings,
            constant_contact_list_ids: values.constant_contact_list_ids,
        };
        const { payload } = await dispatch(submitFunc(promotion));
        setSaved(true);
        setSaving(false);
        setCreatedPromotion({ ...promotion, id: payload.id });
        setShowStartModal(id === undefined);
    };
    const history = useHistory();

    const site = useSelector(selectSiteData);

    return (
        <>
            <Box direction="vertical" width="100%" height="100%">
                {createdPromotion && showStartModal && (
                    <PromotionStartModal
                        promotion={createdPromotion}
                        completePath="/events/engage-promote"
                        isStartAfterCreate={showStartModal}
                        onClose={() => {
                            setShowStartModal(false);
                        }}
                    />
                )}

                {id && campaign && campaign.enabled && (
                    <Notification theme="standard" show={id && campaign && campaign.enabled}>
                        <Notification.TextLabel>Autopilot task running...</Notification.TextLabel>
                        <Notification.CloseButton />
                    </Notification>
                )}
                <Notification
                    theme="success"
                    show={saved}
                    autoHideTimeout={5000}
                    onClose={() => setSaved(false)}
                >
                    <Notification.TextLabel>
                        Successfully saved Autopilot task
                    </Notification.TextLabel>
                    <Notification.CloseButton />
                </Notification>
                <Page height="100vh">
                    <Page.Header
                        title={id ? 'Update Promotion' : 'Create Promotion'}
                        subtitle="Automatically promote your events and help boost your ticket sales"
                        actionsBar={
                            <>
                                <Box>
                                    <Button
                                        className={classes.cancel}
                                        onClick={() =>
                                            history.push(buildURL(site, `/events/engage-promote`))
                                        }
                                    >
                                        Exit
                                    </Button>
                                    <Button
                                        onClick={handleSubmit(onSubmit)}
                                        className={classes.cancel}
                                    >
                                        {saving ? (
                                            <Loader size="tiny" />
                                        ) : (
                                            `${id ? 'Save' : 'Create'}`
                                        )}
                                    </Button>

                                    {id && (
                                        <Button
                                            onClick={() => {
                                                if (campaign?.enabled) {
                                                    dispatch(stopCampaignsApi(campaign));
                                                } else {
                                                    setShowStartModal(true);
                                                    setCreatedPromotion(
                                                        createdPromotion || getValues()
                                                    );
                                                }
                                            }}
                                            skin="premium"
                                        >
                                            {campaign?.enabled ? 'Stop' : 'Start'}
                                        </Button>
                                    )}
                                </Box>
                                {id && (
                                    <Box marginTop={3} marginRight={2}>
                                        <SectionHelper appearance="preview">
                                            Restart the Autopilot after saving to apply the new
                                            changes
                                        </SectionHelper>
                                    </Box>
                                )}
                            </>
                        }
                    />
                    <Page.Content>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col span={12} className={classes.box}>
                                    <Box
                                        direction="vertical"
                                        verticalAlign="space-between"
                                        padding="24px 29px 27px"
                                        borderRadius="8px"
                                        backgroundColor="white"
                                        height="100%"
                                        width="100%"
                                        boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                                        flexGrow={1}
                                    >
                                        <Card>
                                            <Card.Content>
                                                <Layout cols={12}>
                                                    <Cell span={10}>
                                                        <FormField label="Name" required>
                                                            <Controller
                                                                control={control}
                                                                name="name"
                                                                rules={{
                                                                    required: 'Name is required!',
                                                                    max: 140,
                                                                }}
                                                                render={({ field }) => (
                                                                    <Input
                                                                        status={
                                                                            errors.name?.message
                                                                                ? Input.StatusError
                                                                                : ('' as InputStatus)
                                                                        }
                                                                        {...field}
                                                                    />
                                                                )}
                                                            ></Controller>
                                                            <small className={classes.error}>
                                                                {errors.name?.message}
                                                            </small>
                                                        </FormField>
                                                    </Cell>
                                                </Layout>
                                            </Card.Content>
                                        </Card>
                                    </Box>
                                </Col>
                            </Row>

                            {
                                <EventSelection
                                    events={events}
                                    control={control}
                                    watch={watch}
                                    campaign={campaign}
                                    setValue={setValue}
                                    setSelectedIds={setSelectedIds}
                                    selectedIds={selectedIds}
                                    expandOccurrences={expandOccurrences}
                                    setExpandOccurrences={setExpandOccurrences}
                                    type="autopilot"
                                />
                            }
                            <Row>
                                <Col span={12}>
                                    <Box
                                        direction="vertical"
                                        verticalAlign="space-between"
                                        padding="24px 29px 27px"
                                        borderRadius="8px"
                                        backgroundColor="white"
                                        marginTop="50px"
                                        height="100%"
                                        width="100%"
                                        boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                                        flexGrow={1}
                                    >
                                        <Card>
                                            <Card.Header
                                                title="Timing"
                                                subtitle="Schedule a time and frequency to promote your events"
                                            />
                                            <Card.Divider />
                                            <Card.Content>
                                                {watchScheduleType === 3 && (
                                                    <DateTimeSelector
                                                        scheduled={campaign?.schedule}
                                                        watch={watch}
                                                        control={control}
                                                        setValue={setValue}
                                                        errors={errors}
                                                        getValues={getValues}
                                                    />
                                                )}
                                            </Card.Content>
                                        </Card>
                                    </Box>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Box
                                        direction="vertical"
                                        verticalAlign="space-between"
                                        padding="24px 29px 27px"
                                        borderRadius="8px"
                                        backgroundColor="white"
                                        height="100%"
                                        width="100%"
                                        marginTop="50px"
                                        boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                                        flexGrow={1}
                                    >
                                        <Card>
                                            <Card.Header
                                                title="Action"
                                                subtitle="Where do you want to promote your events?"
                                            />
                                            <Card.Divider />
                                            <Card.Content>
                                                <Layout cols="1">
                                                    <Controller
                                                        control={control}
                                                        name="action_selections"
                                                        render={({ field }) => (
                                                            <ActionSelector
                                                                id={id}
                                                                actionSelectionType={
                                                                    actionSelectionType
                                                                }
                                                                triggers={triggers}
                                                                messageRef={messageRef}
                                                                setValue={setValue}
                                                                control={control}
                                                                errors={errors}
                                                                watch={watch}
                                                                field={field}
                                                                campaign={campaign}
                                                                tag="social"
                                                            />
                                                        )}
                                                    />
                                                </Layout>
                                            </Card.Content>
                                        </Card>
                                    </Box>
                                </Col>
                            </Row>
                        </form>
                    </Page.Content>
                </Page>
            </Box>
        </>
    );
};
