import { FC, useMemo, useEffect, useCallback, useRef } from 'react';
import {
    Row,
    Col,
    Button,
    Text,
    Box,
    Image,
    Badge,
    Loader,
    Search,
    TextButton,
    Card,
    MarketingPageLayout,
    MarketingPageLayoutContent,
    TableListItem,
    DropdownBase,
    InfoIcon,
    EmptyState,
    PopoverMenu,
    IconButton,
    Tooltip,
    Cell,
    Layout,
    Heading,
} from 'wix-style-react';
import FilterIcon from '../../assets/filter.svg';
import { Checkbox } from 'primereact/checkbox';

import VirtualScroll from 'virtual-scroll-component';

import * as Icons from 'wix-ui-icons-common';

import { Link, useHistory } from 'react-router-dom';
import { classes } from './list-events.st.css';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { debounce, identity } from 'lodash';

import {
    fetchEventsApi,
    selectEventsIsLoading,
    selectEventsList,
} from '../../features/events-slice';
import type { Event } from '../../types';
import { useAppDispatch } from '../../hooks';
import moment from 'moment-timezone';
import { selectSiteData } from '../../features/account-slice';
import { buildURL } from '../../features/utils';
import { CopyModal } from './components/copy-modal';
import { AttendeeQuotaAmount } from '../../components/plan-information';
import NoAttendees from '../../assets/no-attendees.gif';
import { CreateWithAIModal } from './create-with-ai-modal';
import { AttendanceStats } from './components/event-attendee-stats';
import { EngagementStats } from './components/event-attendee-engagement-stats';
import { SiteModal } from '../../components/site-edit-modal';
import { selectCurrentWidget, selectSite } from '../../features/site-slice';
import { PlatformGuideModal } from './platform-guide-modal';
import { Copy } from './components/copy';
import { Divider } from 'primereact/divider';
import { OverlayPanel } from 'primereact/overlaypanel';

type EventItemProps = {
    event: Event;
};

type EventImageProps = {
    event: Event;
};

const EventImage: FC<EventImageProps> = ({ event, minWidth = 350 }) => (
    <Box align="center" verticalAlign="middle" minWidth={minWidth} backgroundColor="B40">
        <Box padding={2} backgroundColor="B50" borderRadius="50%">
            <Image
                src={event.image_url}
                borderRadius="15px 0px 0px 15px"
                width="100%"
                style={{ maxHeight: 200, maxWidth: 350 }}
            />
        </Box>
    </Box>
);

const getTime = (time, timezone) => {
    if (timezone) {
        return moment(time).tz(timezone);
    }
    return moment(time);
};

const getBadgeText = (event) => {
    if (event.repeat) {
        return 'Recurring Event';
    } else if (event.isMultiDay) {
        return 'Multiple Event Dates';
    } else if (event.isTimeBased) {
        return 'Event With Time Slots';
    }
};

const EventContent: FC<EventImageProps> = ({ more, site, event, disableFooter }) => {
    const {
        id,
        title,
        location,
        dateStart,
        dateEnd,
        timezone = '',
        status,
        allDayEvent,
        repeat,
        isMultiDay,
        isPrivate,
        isTimeBased,
        dateTBD,
        isOnline,
    } = event;
    return (
        <Box
            direction="vertical"
            verticalAlign="space-between"
            padding="24px 29px 27px"
            borderRadius="8px"
            backgroundColor="white"
            boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
            flexGrow={1}
        >
            <Box align="space-between">
                <Box verticalAlign="middle" align="space-between" width={'100%'}>
                    <Box gap={2}>
                        <Text className={classes.eventTitle}>{title}</Text>
                        <Badge
                            size="medium"
                            skin={status === 'ended' ? 'neutralDanger' : 'neutralSuccess'}
                            className={classes.marginRight}
                        >
                            {status}
                        </Badge>
                        {(repeat || isMultiDay || isTimeBased) && (
                            <Badge size="medium" skin={'standard'} className={classes.marginRight}>
                                {getBadgeText(event)}
                            </Badge>
                        )}
                        {isPrivate && (
                            <Badge
                                size="medium"
                                skin={'neutralDanger'}
                                className={classes.marginRight}
                            >
                                Private Event
                            </Badge>
                        )}
                        {isOnline && (
                            <Badge
                                size="medium"
                                skin={'warningLight'}
                                className={classes.marginRight}
                            >
                                Virtual Event
                            </Badge>
                        )}
                    </Box>
                    <Box align="right">
                        <AttendanceStats event={event} key={event.id} />
                    </Box>
                </Box>
            </Box>
            {!dateTBD ? (
                <Text size="tiny">
                    {!allDayEvent
                        ? `${getTime(dateStart, timezone).format('Do of MMMM')} ${getTime(
                              dateStart,
                              timezone
                          ).format('HH:mm A')} - ${getTime(dateEnd, timezone).format('HH:mm A')}`
                        : `${getTime(dateStart, timezone).format('Do of MMMM')} - ${getTime(
                              dateEnd,
                              timezone
                          ).format('Do of MMMM')}`}
                </Text>
            ) : (
                <Box width={200}>
                    <Badge skin={'neutralSuccess'}>Date Not Decided Yet!</Badge>
                </Box>
            )}
            {!isOnline && <Text size="tiny">{location}</Text>}
            {!disableFooter && (
                <Box marginTop={2} justifyContent="space-between" className={classes.spaceBetween}>
                    <Box display="flex" verticalAlign="bottom" alignItems="flex-end">
                        <EngagementStats event={event} key={event.id} />
                    </Box>
                    <Box gap={1} display="flex" verticalAlign="bottom" alignItems="flex-end">
                        <Button
                            priority="primary"
                            className={classes.addEventButton}
                            size="medium"
                            to={buildURL(site, `/events/manage/${id}`)}
                            as={Link}
                            prefixIcon={<Icons.Edit />}
                        >
                            Edit
                        </Button>
                        <CopyModal event={event} />
                        <Divider layout="vertical" className={classes.divider} />
                        <Box marginRight={1}></Box>
                        <EventPage event={event} />
                        {/* <Button
                            priority="secondary"
                            prefixIcon={<Icons.Desktop />}
                            as="a"
                            href={`https://event-discovery.ticketspot.io/events/${id}`}
                            target="_blank"
                        >
                            <Box verticalAlign="middle" gap={1}>
                                Event Page
                                <InfoIcon content="Click here to visit the dedicated page for this event" />
                            </Box>
                        </Button> */}

                        {more && <Box marginLeft={2}>{more}</Box>}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

const EventPage = ({ event }) => {
    const [showSiteConfig, setShowSiteConfig] = useState(false);
    const siteInformation = useSelector(selectSite);
    return (
        <>
            <SiteModal
                site={siteInformation}
                handleSiteUpdate={() => {}}
                isModalOpened={showSiteConfig}
                setModalOpened={setShowSiteConfig}
                includeDesign={true}
            />

            <PopoverMenu
                triggerElement={
                    <Button prefixIcon={<Icons.Desktop />} priority="secondary">
                        <Box verticalAlign="middle" gap={1}>
                            Event Page
                            <InfoIcon content="Click here to visit the dedicated page for this event" />
                        </Box>
                    </Button>
                }
            >
                {/* <PopoverMenu.MenuItem
                    text="Design"
                    prefixIcon={<Icons.LayoutGallery />}
                    onClick={() => history.push(`/events/design?selector=eventpage`)}
                /> */}
                <PopoverMenu.MenuItem
                    text="Settings"
                    prefixIcon={<Icons.Settings />}
                    onClick={() => setShowSiteConfig(true)}
                />

                <PopoverMenu.Divider />
                <PopoverMenu.MenuItem
                    text="View Event Page"
                    prefixIcon={<Icons.Link />}
                    onClick={() => {
                        window.open(event.eventPageUrl, '_blank');
                    }}
                />
                <PopoverMenu.MenuItem
                    text="View Landing Page"
                    prefixIcon={<Icons.Link />}
                    onClick={() => {
                        window.open(event.eventLandingPageUrl, '_blank');
                    }}
                />
            </PopoverMenu>
        </>
    );
};

export const EventItem: FC<EventItemProps> = ({ event, more, site, disableFooter, minWidth }) => (
    <>
        <EventImage event={event} minWidth={minWidth} />
        <EventContent event={event} more={more} site={site} disableFooter={disableFooter} />
    </>
);

const NoEvent = ({ site }) => {
    const history = useHistory();

    return (
        <Box verticalAlign="middle" align="center">
            <Card>
                <MarketingPageLayout
                    horizontalSize="medium"
                    verticalSize="medium"
                    content={
                        <Box verticalAlign="middle" align="center">
                            <MarketingPageLayoutContent
                                size="medium"
                                title="Create Your Perfect Event"
                                content="Start by creating your ideal event, where you can customize every detail from theme to location. Set up engaging activities, create ticket options, and promote your event to a wider audience."
                                actions={
                                    <Box gap={2}>
                                        <Button
                                            suffixIcon={<Icons.GetStarted />}
                                            size="medium"
                                            onClick={() =>
                                                history.push(buildURL(site, `/events/manage`))
                                            }
                                        >
                                            Start Creating Your Event
                                        </Button>
                                        <CreateWithAIModal />
                                    </Box>
                                }
                            />
                        </Box>
                    }
                />
            </Card>
        </Box>
    );
};
export const applyFilters = (event: Event, searchText: string) => {
    const eventBasedFilters = [
        (event: Event) => event?.title?.toLowerCase()?.includes(searchText.toLowerCase()),
    ];
    return eventBasedFilters.every((filterFunc) => filterFunc(event));
};

const SiteEdit = () => {
    const [showSiteConfig, setShowSiteConfig] = useState(false);
    const siteInformation = useSelector(selectSite);
    const currentWidget = useSelector(selectCurrentWidget);

    return (
        <Box marginTop={3} direction="vertical">
            {showSiteConfig && (
                <SiteModal
                    site={siteInformation}
                    handleSiteUpdate={() => {}}
                    isModalOpened={showSiteConfig}
                    setModalOpened={setShowSiteConfig}
                />
            )}
            <Box marginBottom={1}>
                <Text>Your Site Landing Page</Text>
            </Box>
            <Box gap={1} width={'80%'} verticalAlign="middle">
                <Copy text={currentWidget?.landing_page_url} />
                <Tooltip content="Site Settings">
                    <IconButton
                        onClick={() => setShowSiteConfig(true)}
                        className={classes.iconButton}
                    >
                        <Icons.Settings />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    );
};
export const ListEvent = () => {
    const dispatch = useAppDispatch();

    const [searchText, setSearchText] = useState('');

    const eventStatusRef = useRef();
    const statusFilterByOptions = [
        { key: 'all', name: 'All' },
        { key: 'upcoming', name: 'Upcoming' },
        { key: 'noDateSet', name: 'No date set' },
        { key: 'ended', name: 'Ended' },
        { key: 'draft', name: 'Draft' },
        { key: 'cancelled', name: 'Cancelled' },
        { key: 'requiresApproval', name: 'Requires approval' },
    ];
    const history = useHistory();
    const events = useSelector(selectEventsList);
    const siteEntity = useSelector(selectSite);
    const isLoading = useSelector(selectEventsIsLoading);
    const [selectedStatuses, setSelectedStatuses] = useState([statusFilterByOptions[0]]);

    const onEventStatusChange = (e) => {
        let _selectedCategories = [...selectedStatuses];

        if (e.checked) {
            if (e.value.key === 'all') {
                _selectedCategories = [e.value];
            } else {
                _selectedCategories = _selectedCategories.filter(
                    (category) => category.key !== 'all'
                );
                _selectedCategories.push(e.value);
            }
        } else {
            _selectedCategories = _selectedCategories.filter(
                (category) => category.key !== e.value.key
            );
        }

        if (_selectedCategories.length === 0) {
            _selectedCategories = [statusFilterByOptions.find((option) => option.key === 'all')];
        }

        setSelectedStatuses(_selectedCategories);
    };
    const sortByOptions = [
        { id: 'eventStarted', value: 'Event started' },
        { id: 'newest', value: 'Date added (newest)' },
        { id: 'oldest', value: 'Date added (oldest)' },
    ];

    const selectedEvents = useMemo(() => {
        const selectedKeys = selectedStatuses.map((status) => status.key);

        if (selectedKeys.includes('all')) {
            return events.filter((event) => applyFilters(event, searchText));
        }

        return events
            .filter((event) => {
                const matchesSearch = applyFilters(event, searchText);
                const matchesStatus = selectedKeys.some((key) => {
                    switch (key) {
                        case 'upcoming':
                            return !event.dateTBD && event.status === 'live';
                        case 'noDateSet':
                            return event.dateTBD;
                        case 'ended':
                            return !event.dateTBD && event.status === 'ended';
                        case 'draft':
                            return !event.dateTBD && event.status === 'draft';
                        case 'cancelled':
                            return !event.dateTBD && event.status === 'cancelled';
                        case 'requiresApproval':
                            return event.requiresApproval;
                        default:
                            return true;
                    }
                });
                return matchesSearch && matchesStatus;
            })
            .sort((a, b) => {
                if (a.dateTBD && !b.dateTBD) {
                    return -1;
                } else if (!a.dateTBD && b.dateTBD) {
                    return 1;
                }

                const isALive = a.status === 'live';
                const isBLive = b.status === 'live';

                if (isALive && !isBLive) {
                    return -1;
                } else if (!isALive && isBLive) {
                    return 1;
                }

                return new Date(a.startDate) - new Date(b.startDate);
            });
    }, [events, selectedStatuses, searchText]);

    useEffect(() => {
        dispatch(fetchEventsApi());
    }, [dispatch]);

    const searchHandler = (event) => {
        setSearchText(event.target.value);
    };
    const debouncedChangeHandler = useCallback(debounce(searchHandler, 500), []);
    const site = useSelector(selectSiteData);

    const getEventOption = (event) => [
        {
            value: (
                <Box
                    minHeight={200}
                    maxHeight={200}
                    height={200}
                    marginBottom={3}
                    className={classes.row}
                >
                    <>
                        <EventItem key={event.id} event={event} site={site} />
                    </>
                </Box>
            ),
        },
    ];

    return (
        <>
            <div className={classes.listEvents}>
                <Card>
                    <Card.Header />
                    <Card.Content>
                        <Layout>
                            <Row>
                                <Cell span={6}>
                                    <Box direction="vertical" gap={2}>
                                        <Heading appearance="H1" className={classes.siteHeader}>
                                            {siteEntity?.site_display_name}
                                        </Heading>
                                        <SiteEdit />
                                    </Box>
                                </Cell>
                                <Cell span={6}>
                                    <Box direction="vertical" marginBottom={4} align="right">
                                        <Box marginBottom={5}>
                                            <AttendeeQuotaAmount />
                                        </Box>

                                        <Box gap={1} align="right" className={classes.divider}>
                                            <Button
                                                className={classes.addEventButton}
                                                skin="premium-light"
                                                onClick={() => history.push(`/events/manage`)}
                                                prefixIcon={<Icons.AddSmall />}
                                            >
                                                Add Event
                                            </Button>
                                            <CreateWithAIModal />
                                            <PlatformGuideModal
                                                siteId={site.site}
                                                widgetId={site.widget}
                                            />
                                        </Box>
                                    </Box>
                                </Cell>
                            </Row>
                        </Layout>
                        <Row className={`${classes.row} ${classes.marginTop}`}>
                            <Col span={7}>
                                <TextButton
                                    onClick={(e) => eventStatusRef.current.toggle(e)}
                                    suffixIcon={<img src={FilterIcon} />}
                                >
                                    Filter by
                                </TextButton>
                                <OverlayPanel ref={eventStatusRef}>
                                    {statusFilterByOptions.map((option) => {
                                        return (
                                            <div
                                                key={option.key}
                                                className="flex align-items-center mt-3"
                                            >
                                                <Checkbox
                                                    inputId={option.key}
                                                    name="status"
                                                    value={option}
                                                    onChange={onEventStatusChange}
                                                    checked={selectedStatuses.some(
                                                        (item) => item.key === option.key
                                                    )}
                                                />
                                                <label htmlFor={option.key} className="ml-2">
                                                    {option.name}
                                                </label>
                                            </div>
                                        );
                                    })}
                                </OverlayPanel>
                            </Col>
                            <Col span={5}>
                                <Box
                                    align="right"
                                    verticalAlign="middle"
                                    direction="horizontal"
                                    gap={4}
                                >
                                    <Box>
                                        <Search
                                            placeholder="Search"
                                            roundInput
                                            size="large"
                                            value={searchText}
                                            clearButton
                                            onChange={debouncedChangeHandler}
                                            onClear={() => setSearchText('')}
                                            minWidthPixels={450}
                                        />
                                    </Box>
                                    <Box>
                                        <DropdownBase options={sortByOptions}>
                                            {({ toggle }) => (
                                                <TextButton
                                                    onClick={toggle}
                                                    suffixIcon={<Icons.SortAscending />}
                                                >
                                                    Sort by
                                                </TextButton>
                                            )}
                                        </DropdownBase>
                                    </Box>
                                </Box>
                            </Col>
                        </Row>

                        {!isLoading && events.length === 0 && <NoEvent site={site} />}
                        {events.length > 0 && selectedEvents.length === 0 && (
                            <EmptyState
                                theme="page-no-border"
                                image={
                                    <Image
                                        width="120px"
                                        height="120px"
                                        src={NoAttendees}
                                        transparent
                                    />
                                }
                                subtitle="No events match your filter. Try different settings or check back later."
                            />
                        )}
                        {/* <Box marginLeft={5} marginBottom={5}>
                            <Checkbox checked={selectAll} onChange={handleSelectAllChange}>
                                Select All
                            </Checkbox>
                        </Box> */}
                        <VirtualScroll
                            rows={selectedEvents.map((event) => (
                                <TableListItem
                                    key={event.id}
                                    // checkbox
                                    // checked={!!checkedEvents[event.id]}
                                    // onCheckboxChange={() => handleCheckboxChange(event.id)}
                                    options={getEventOption(event)}
                                    verticalPadding="small"
                                    className={classes.tableList}
                                />
                            ))}
                            onLastRow={identity}
                            rowHeight={220}
                        />
                    </Card.Content>
                </Card>
                {isLoading && (
                    <Box width="100%" height="100px" align="center" verticalAlign="middle">
                        <Loader size="medium" />
                    </Box>
                )}
            </div>
        </>
    );
};
