import {
    Cell,
    Proportion,
    Thumbnail,
    Text,
    Box,
    Layout,
    Accordion,
    accordionItemBuilder,
    Image,
    InfoIcon,
} from 'wix-style-react';
import { SettingsHeader } from '../../../components/settings-header';
import { WidgetSettingsUpdater } from '../../../components/widget-settings-updater';
import { selectSettings } from '../../../features/settings-slice';
import { useAppSelector } from '../../../hooks';
import ListIcon from '../../../assets/layouts/list.svg';
import List2Icon from '../../../assets/layouts/list2.svg';
import MapIcon from '../../../assets/layouts/map.svg';
import CheckoutIcon from '../../../assets/layouts/checkout-layout.svg';
import MobileMinimalIcon from '../../../assets/layouts/mobile-minimal.svg';
import MobileSideBySideIcon from '../../../assets/layouts/mobile-side-by-side.svg';
import MobileStackIcon from '../../../assets/layouts/mobile-stack.svg';
import SideBySideIcon from '../../../assets/layouts/sideBySide.svg';
import ThreeColumnsIcon from '../../../assets/layouts/threeColumns.svg';
import TwoColumnsIcon from '../../../assets/layouts/twoColumns.svg';
import VerticalIcon from '../../../assets/layouts/vertical.svg';
import CalendarIcon from '../../../assets/layouts/calendar.svg';
import { CalendarOptions } from './calendar_options';
import { getFragmentParams } from '../utils';

import DesktopList1 from '../../../assets/layouts/new-layout-icons/desktop/list1.svg';
import DesktopList2 from '../../../assets/layouts/new-layout-icons/desktop/list2.svg';
import DesktopList3 from '../../../assets/layouts/new-layout-icons/desktop/list3.svg';
import DesktopVertical from '../../../assets/layouts/new-layout-icons/desktop/vertical.svg';
import Desktop2Columns from '../../../assets/layouts/new-layout-icons/desktop/twoColumns.svg';
import Desktop3Columns from '../../../assets/layouts/new-layout-icons/desktop/threeColumns.svg';
import DesktopCheckoutOnly from '../../../assets/layouts/new-layout-icons/desktop/checkoutOnly.svg';
import DesktopMap from '../../../assets/layouts/new-layout-icons/desktop/map.svg';
import DesktopCalendar from '../../../assets/layouts/new-layout-icons/desktop/calendar.svg';
import MobileStack1 from '../../../assets/layouts/new-layout-icons/mobile/stack1.svg';
import MobileStack2 from '../../../assets/layouts/new-layout-icons/mobile/stack2.svg';
import MobileList1 from '../../../assets/layouts/new-layout-icons/mobile/list1.svg';
import MobileList2 from '../../../assets/layouts/new-layout-icons/mobile/list2.svg';
import TimeBasedCalendar from '../../../assets/layouts/new-layout-icons/desktop/timeBasedCalendar.svg';
import TimeBasedMinimal from '../../../assets/layouts/new-layout-icons/desktop/timeBasedMinimal.svg';

export const EventLayout = () => {
    const { subfolder } = getFragmentParams(window.location.hash);

    const settings = useAppSelector(selectSettings);

    const tooltip = !settings.isPremium
        ? 'To use this layout, upgrade your Ticket Spot app.'
        : null;

    const thumbnailsOptions = [
        {
            title: 'List',
            value: 'list',
            src: ListIcon,
        },
        {
            title: 'List 2',
            value: 'listCompress',
            label: 'List 2',
            src: List2Icon,
        },
        {
            title: 'Vertical',
            value: 'vertical',
            src: VerticalIcon,
        },
        {
            title: 'Two Columns',
            value: 'twoColumns',
            src: TwoColumnsIcon,
        },
        {
            title: 'Three Columns',
            value: 'threeColumns',
            label: 'Three Columns',
            src: ThreeColumnsIcon,
        },
        {
            title: 'Map',
            value: 'map',
            label: 'Map',
            src: MapIcon,
            tooltip,
        },
        {
            title: 'Checkout Only',
            value: 'checkout',
            src: CheckoutIcon,
            tooltip,
        },
        {
            title: 'Side by Side',
            value: 'sideBySide',
            src: SideBySideIcon,
            tooltip,
        },
        {
            title: 'Calendar',
            value: 'calendar',
            src: CalendarIcon,
        },
    ];

    const mobileThumbnailsOptions = [
        {
            title: 'Stack',
            value: 'stack',
            src: MobileStackIcon,
        },
        {
            title: 'Side by Side',
            value: 'sideBySide',
            src: MobileSideBySideIcon,
        },
        {
            title: 'Minimal',
            value: 'minimal',
            src: MobileMinimalIcon,
        },
    ];

    const newDesktopLayoutOptions = [
        {
            title: 'List 1',
            value: 'desktop-list1',
            src: DesktopList1,
        },
        {
            title: 'List 2',
            value: 'desktop-list2',
            src: DesktopList2,
        },
        {
            title: 'List 3',
            value: 'desktop-list3',
            label: 'List 3',
            src: DesktopList3,
        },
        {
            title: 'Vertical',
            value: 'desktop-vertical',
            label: 'Vertical',
            src: DesktopVertical,
        },
        {
            title: '2 Columns',
            value: 'desktop-two-columns',
            label: '2 Columns',
            src: Desktop2Columns,
        },
        {
            title: '3 Columns',
            value: 'desktop-three-columns',
            label: '3 Columns',
            src: Desktop3Columns,
        },
        {
            title: 'Checkout Only',
            value: 'desktop-checkout',
            label: 'Checkout Only',
            src: DesktopCheckoutOnly,
        },
        {
            title: 'Map',
            value: 'desktop-map',
            label: 'Map',
            src: DesktopMap,
        },
        {
            title: 'Calendar',
            value: 'desktop-calendar',
            label: 'Calendar',
            src: DesktopCalendar,
        },
    ];

    const newMobileLayoutOptions = [
        {
            title: 'Stack 1',
            value: 'mobile-stack1',
            label: 'Stack 1',
            src: MobileStack1,
        },
        {
            title: 'Stack 2',
            value: 'mobile-stack2',
            label: 'Stack 2',
            src: MobileStack2,
        },
        {
            title: 'List 1',
            value: 'mobile-list1',
            label: 'List 1',
            src: MobileList1,
        },
        {
            title: 'List 2',
            value: 'mobile-list2',
            label: 'List 2',
            src: MobileList2,
        },
        {
            title: 'Checkout Only',
            value: 'mobile-checkout',
            label: 'Checkout Only',
            src: DesktopCheckoutOnly,
        },
    ];
    const timeBasedLayouts = [
        {
            title: 'Full Calendar',
            value: 'desktop-full-calendar',
            label: 'Full Calendar',
            src: TimeBasedCalendar,
        },
        {
            title: 'Date and Time Picker',
            value: 'desktop-minimal-date-picker',
            label: 'Date and Time Picker',
            src: TimeBasedMinimal,
        },
    ];
    return (
        <Box direction="vertical" width="100%">
            <SettingsHeader
                title="Layout"
                info="Choose from a variety of layouts for both desktop and mobile to make your event listings look amazing."
            />

            <Accordion
                hideShadow
                items={[
                    accordionItemBuilder({
                        title: 'Desktop Layout (New)',
                        initiallyOpen: false,
                        children: (
                            <Box gap={3} direction="vertical">
                                <Text size="small" skin="primary" light>
                                    Select a layout for your events optimized for desktop viewing.
                                </Text>
                                <WidgetSettingsUpdater updateKey="layoutDesign" isPremium title="">
                                    <ThumbnailItem options={newDesktopLayoutOptions} />
                                </WidgetSettingsUpdater>
                            </Box>
                        ),
                    }),
                    accordionItemBuilder({
                        title: 'Mobile Layout (New)',
                        initiallyOpen: false,
                        children: (
                            <Box gap={3} direction="vertical">
                                <Text size="small" skin="primary" light>
                                    Select a layout for your events optimized for mobile viewing.
                                </Text>
                                <WidgetSettingsUpdater
                                    updateKey="mobileLayoutDesign"
                                    isPremium
                                    title=""
                                >
                                    <ThumbnailItem options={newMobileLayoutOptions} />
                                </WidgetSettingsUpdater>
                            </Box>
                        ),
                    }),
                    accordionItemBuilder({
                        title: (
                            <Box>
                                Date and Time Selector Layout{' '}
                                <InfoIcon
                                    content={`Use this section to customize how dates and times are displayed for both time-based and recurring events.`}
                                />
                            </Box>
                        ),

                        initiallyOpen: false,
                        children: (
                            <Box gap={3} direction="vertical">
                                <Text size="small" skin="primary" light>
                                    Select from layouts that allow users to choose a date and time.
                                </Text>

                                <WidgetSettingsUpdater
                                    updateKey="timeBasedEventLayout"
                                    isPremium
                                    title=""
                                >
                                    <ThumbnailItem options={timeBasedLayouts} />
                                </WidgetSettingsUpdater>
                            </Box>
                        ),
                    }),
                    accordionItemBuilder({
                        title: 'Desktop (Classic)',
                        initiallyOpen: false,
                        children: (
                            <Box gap={3} direction="vertical">
                                <Text size="small" skin="primary" light>
                                    Choose a classic layout optimized for desktop viewing.
                                </Text>

                                <WidgetSettingsUpdater updateKey="layoutDesign" title="">
                                    <ThumbnailItem options={thumbnailsOptions} />
                                </WidgetSettingsUpdater>
                            </Box>
                        ),
                    }),
                    accordionItemBuilder({
                        title: 'Mobile (Classic)',
                        children: (
                            <Box gap={3} direction="vertical">
                                <Text size="small" skin="primary" light>
                                    Choose a classic layout optimized for mobile viewing.
                                </Text>
                                <WidgetSettingsUpdater
                                    updateKey="mobileLayoutDesign"
                                    isPremium
                                    title=""
                                >
                                    <ThumbnailItem options={mobileThumbnailsOptions} />
                                </WidgetSettingsUpdater>
                            </Box>
                        ),
                    }),
                ]}
            />

            {/* <WidgetUpdater settings={settings} updateKey="fitTitleTextToParent">
        <UI.toggleSwitch
          label="Fit title size to parent "
          defaultValue={settings.get('fitTitleTextToParent')}
        />
      </WidgetUpdater> */}
            {settings.layoutDesign === 'calendar' && (
                <>
                    <SettingsHeader title="Calendar Options" />
                    <CalendarOptions />
                </>
            )}
        </Box>
    );
};

const ThumbnailItem = ({ value, onChange, options }) => {
    const renderCell = ({ id, title, src }) => (
        <Cell span={5}>
            <Thumbnail
                size="small"
                title={<Text>{title}</Text>}
                selected={value === id}
                onClick={() => onChange(id)}
            >
                <Proportion>
                    <Box height="100%" width="100%" align="center" verticalAlign="middle">
                        <Image src={src} height="80px" width="80px" transparent />
                    </Box>
                </Proportion>
            </Thumbnail>
        </Cell>
    );

    return (
        <Layout>
            {options.map(({ title, src, value }) => {
                return renderCell({
                    id: value,
                    title,
                    src,
                });
            })}
        </Layout>
    );
};
