import moment from 'moment';
import { Dropdown, RadioGroup, ToggleSwitch } from 'wix-style-react';
import { SettingsHeader } from '../../../components/settings-header';
import { WidgetSettingsUpdater } from '../../../components/widget-settings-updater';

const DATE_FORMAT_OPTIONS = [
    { id: 'ddd Do MMMM', value: moment().format('ddd Do MMMM') },
    { id: 'ddd Do MMM', value: moment().format('ddd Do MMM') },
    { id: 'ddd Do MMMM YYYY', value: moment().format('ddd Do MMMM YYYY') },
    { id: 'dddd MMMM Do YYYY', value: moment().format('dddd MMMM Do YYYY') },
    { id: 'dddd Do MMMM YYYY', value: moment().format('dddd Do MMMM YYYY') },
];
const TIME_FORMAT_OPTIONS = [
    { value: 'h:mm A', label: moment().format('h:mm A') },
    { value: 'HH:mm', label: moment().format('HH:mm') },
    { value: 'HH:mm A', label: moment().format('HH:mm A') },
];

export const CheckoutDateTimeDisplay = ({ label }) => {
    return (
        <>
            <SettingsHeader title={label} />

            <hr className="divider-long" />

            <WidgetSettingsUpdater
                updateKey="checkoutDateFormat"
                title="Date Display"
                tooltipText="Choose the format in which you'd like the date to appear on the checkout page."
            >
                <Dropdown options={DATE_FORMAT_OPTIONS} />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="checkoutTimeFormat"
                tooltipText="Choose the time format for display on the checkout page."
                title="Time Display"
            >
                <RadioGroup>
                    {TIME_FORMAT_OPTIONS.map((val, index) => (
                        <RadioGroup.Radio key={index} value={val.value}>
                            {val.label}
                        </RadioGroup.Radio>
                    ))}
                </RadioGroup>
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater updateKey="checkoutShowStartDate" title="Show Start Date">
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater updateKey="checkoutShowStartTime" title="Show Start Time">
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater updateKey="checkoutShowEndDate" title="Show End Date">
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater updateKey="checkoutShowEndTime" title="Show End Time">
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater updateKey="checkoutIncludeTimezone" title="Include Timezone">
                <ToggleSwitch />
            </WidgetSettingsUpdater>
        </>
    );
};
