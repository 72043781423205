import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { classes } from './attendee-view.st.css';

import {
    Page,
    Box,
    Card,
    Layout,
    Cell,
    Button,
    Table,
    Loader,
    Notification,
    EmptyState,
} from 'wix-style-react';
import { useForm, SubmitHandler } from 'react-hook-form';

import {
    updateAttendeeApi,
    selectAttendee,
    selectAttendeesIsLoading,
    fetchAttendeeApi,
} from '../../features/attendees-slice';
import { useAppDispatch } from '../../hooks';
import type { Event } from '../../types';
import { FieldEditor } from '../../components/field-editor';
import { PlanNotification } from '../../components/plan-notification';
import { CustomFields } from '../../components/custom-fields';
import { isEmpty } from 'lodash';
import { AttendeeNotesCard } from './attendee-notes';

export const AttendeeView = () => {
    const { id } = useParams<{ id: string }>();

    const dispatch = useAppDispatch();

    const attendee = useSelector(selectAttendee);
    const isLoading = useSelector(selectAttendeesIsLoading);

    useEffect(() => {
        dispatch(fetchAttendeeApi(id));
    }, [dispatch]);

    if (isLoading || !attendee) {
        return (
            <Box width="100%" height="100px" align="center" verticalAlign="middle">
                <Loader size="medium" />
            </Box>
        );
    }
    return <AttendeeFields attendee={attendee} />;
};

export const AttendeeFields = ({ attendee }) => {
    const [saved, setSaved] = useState(false);
    const [customFields, setCustomFields] = useState(attendee?.additional_properties || []);
    const [answers, setAnswers] = useState(attendee?.answers || []);
    const [fields, setFields] = useState(attendee.customer);
    const [modifiedFields, setModifiedFields] = useState([]);

    const dispatch = useAppDispatch();

    const onSubmit: SubmitHandler<Event> = async (values) => {
        const additionalProperties = Object.keys(values)
            .filter((a) => customFields.find((f) => f.id === a))
            .filter((a) => customFields.find((f) => f.id === a))
            .map((a) => ({
                id: a,
                name: customFields.find((f) => f.id === a).name,
                name: customFields.find((f) => f.id === a).name,
                value: values[a],
            }));
        dispatch(
            updateAttendeeApi({
                id: attendee.id,
                answers,
                customer: fields,
                modified_fields: modifiedFields,
                additional_properties: additionalProperties,
                events: undefined,
            })
        );
        setSaved(true);
    };
    const activeCustomFields = useMemo(() => {
        return customFields.filter((field) => !isEmpty(field) && !field.is_deleted);
    }, [customFields]);

    const { handleSubmit, watch, control, setValue, formState } = useForm<Event>({
        defaultValues: customFields.reduce((res, val) => ({ ...res, [val.name]: val.value }), {}),
    });
    const handleFieldChange = (name) => (value) => {
        setFields({ ...fields, [name]: value });
        setModifiedFields(Array.from(new Set(modifiedFields.concat(name))));
    };

    const handleAnswerChange = (index, newAnswer) => {
        if (index >= 0 && index < answers.length) {
            const updatedAnswers = [
                ...answers.slice(0, index),
                { ...answers[index], answer: newAnswer },
                ...answers.slice(index + 1),
            ];
            setAnswers(updatedAnswers);
        }
    };

    const questionsColums = [
        { ticket_name: 'Question', render: (row) => row.question },
        {
            ticket_name: 'Answer',
            render: (row, index) => (
                <FieldEditor
                    value={row.answer}
                    setField={(newValue) => {
                        handleAnswerChange(index, newValue);
                    }}
                />
            ),
        },
    ];
    return (
        <>
            <Notification
                theme="success"
                show={saved}
                autoHideTimeout={5000}
                onClose={() => setSaved(false)}
            >
                <Notification.TextLabel>
                    Successfully saved attendee {attendee?.email} information
                </Notification.TextLabel>
                <Notification.CloseButton />
            </Notification>
            <Page>
                <Page.Header
                    showBackButton
                    onBackClicked={() => window.history.back()}
                    actionsBar={<Button onClick={handleSubmit(onSubmit)}>Save</Button>}
                    title={`Edit Attendee Information`}
                />

                <Page.Content>
                    <Layout>
                        <Cell span={8}>
                            <Card className={classes.card}>
                                <Card.Header
                                    title="Contact Information"
                                    subtitle={`Managing and Editing Collected Information from Registration`}
                                    // suffix={<Button size="small">Edit Order</Button>}
                                />
                                <Card.Content>
                                    <Layout gap="18px">
                                        <Cell span={6}>
                                            <FieldEditor
                                                fieldDisplay="Email"
                                                value={fields['email']}
                                                setField={handleFieldChange('email')}
                                            />
                                        </Cell>
                                        <Cell span={6}>
                                            <FieldEditor
                                                fieldDisplay="First name"
                                                value={fields['first_name']}
                                                setField={handleFieldChange('first_name')}
                                            />
                                        </Cell>

                                        <Cell span={6}>
                                            <Box direction="vertical">
                                                <FieldEditor
                                                    fieldDisplay="Last name"
                                                    value={fields['last_name']}
                                                    setField={handleFieldChange('last_name')}
                                                />
                                            </Box>
                                        </Cell>

                                        <Cell span={6}>
                                            <Box direction="vertical">
                                                <FieldEditor
                                                    fieldDisplay="Phone"
                                                    value={fields['phone']}
                                                    setField={handleFieldChange('phone')}
                                                />
                                            </Box>
                                        </Cell>
                                        <Cell span={6}>
                                            <Box direction="vertical">
                                                <FieldEditor
                                                    fieldDisplay="Company"
                                                    value={fields['company']}
                                                    setField={handleFieldChange('company')}
                                                />
                                            </Box>
                                        </Cell>
                                        <Cell span={6}>
                                            <Box direction="vertical">
                                                <FieldEditor
                                                    fieldDisplay="Job title"
                                                    value={fields['job_title']}
                                                    setField={handleFieldChange('job_title')}
                                                />
                                            </Box>
                                        </Cell>
                                        <Cell span={6}>
                                            <Box direction="vertical">
                                                <FieldEditor
                                                    fieldDisplay="Website"
                                                    value={fields['website']}
                                                    setField={handleFieldChange('website')}
                                                />
                                            </Box>
                                        </Cell>
                                    </Layout>
                                </Card.Content>
                            </Card>
                        </Cell>
                        <Cell span={4}>
                            <AttendeeNotesCard attendee={attendee} />
                        </Cell>
                    </Layout>
                    <Box height="42px" />
                    <Card.Divider />
                    <Card>
                        <Card.Header title="Custom Questions and Responses" subtitle="" />

                        <Card.Divider />
                        <Box height={10} />
                        <Card.Content>
                            {!isEmpty(attendee?.answers) ? (
                                <Table skin="neutral" data={answers} columns={questionsColums}>
                                    <Table.Content titleBarVisible={false} />
                                </Table>
                            ) : (
                                <EmptyState
                                    theme={'section'}
                                    title="No Additional Questions Asked or Answered"
                                />
                            )}
                        </Card.Content>
                    </Card>
                    <Box height="42px" />
                    <Card.Divider />
                    <Card>
                        <Card.Content>
                            <Layout>
                                <Cell>
                                    <PlanNotification type="business" text="attendee custom fields">
                                        <CustomFields
                                            reservedNames={Object.keys(attendee)}
                                            customFields={activeCustomFields}
                                            setCustomFields={setCustomFields}
                                            setParentValue={setValue}
                                            parentControl={control}
                                            type="attendee"
                                        />
                                    </PlanNotification>
                                </Cell>
                            </Layout>
                        </Card.Content>
                    </Card>
                </Page.Content>
            </Page>
        </>
    );
};
