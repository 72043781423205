import { useRef } from 'react';
import {
    Box,
    Button,
    Card,
    Row,
    Col,
    Cell,
    ToggleSwitch,
    FormField,
    Input,
    Layout,
    RichTextInputArea,
    InputStatus,
    Loader,
    Notification,
} from 'wix-style-react';
import { classes } from './create-campaign.st.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useAppSelector } from '../../hooks';

import {
    getNotificationApi,
    selectSingleNotification,
    createNotificationApi,
    updateNotificationApi,
    selectCampaignIsLoading,
} from '../../features/campaign-slice';
import type { NotificationType } from '../../types';
import { useAppDispatch } from '../../hooks';
import { MesssageKeysTable } from './components/message-keys-table';

export const NotificationManage = () => {
    const { id, campaign_id } = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectCampaignIsLoading);
    const notification = useAppSelector(selectSingleNotification);

    useEffect(() => {
        if (id) dispatch(getNotificationApi({ campaign_id, id }));
    }, [id]);
    if (isLoading)
        return (
            <Box width="100%" height="100px" align="center" verticalAlign="middle">
                <Loader size="large" />
            </Box>
        );

    return <NotificationInput notification={notification} campaign_id={campaign_id} />;
};

const NotificationInput = ({ notification, campaign_id }) => {
    const [saved, setSaved] = useState(false);
    const [saving, setSaving] = useState(false);

    const { id } = useParams<{ id: string }>();
    const messageRef = useRef();
    const dispatch = useAppDispatch();

    const { handleSubmit, watch, control, setValue, formState } = useForm<NotificationType>({
        defaultValues: {
            name: notification?.name,
            message: notification?.message,
            subject: notification?.subject,
            to_email: notification?.from_email,
            enabled: notification?.enabled,
        },
    });

    const { errors } = formState;
    const watchEnabled = watch('enabled', notification?.enabled || false);

    useEffect(() => {
        if (id === undefined) {
            const message =
                "{{attendee_first_name}} {{attendee_last_name}} has successfully registered for the event titled '{{event_title}}' scheduled on {{event_start_date}} at {{event_start_time}}.";
            setValue(
                'subject',
                '{{attendee_email}} just registered for {{event_title}} on {{event_start_date}} at {{event_start_time}}'
            );
            setValue('message', message);
            messageRef.current?.setValue(message);
        }
    }, [id]);

    const onSubmit: SubmitHandler<NotificationType> = async (values) => {
        let submitFunc = createNotificationApi;
        if (id) submitFunc = updateNotificationApi;
        setSaved(false);
        setSaving(true);
        dispatch(
            submitFunc({
                id,
                campaign_id,
                name: values.name,
                message: values.message || '',
                subject: values.subject,
                to_email: values.to_email,
                enabled: values.enabled,
            })
        );
        setSaved(true);
        setSaving(false);
    };

    useEffect(() => {
        if (notification) {
            if (notification?.message) messageRef.current?.setValue(notification?.message);
            Object.keys(notification).forEach((ev: string) => setValue(ev, notification[ev]));
        }
    }, [notification]);

    return (
        <>
            <div className={classes.header}>
                <Notification
                    theme="success"
                    show={saved}
                    autoHideTimeout={5000}
                    onClose={() => setSaved(false)}
                >
                    <Notification.TextLabel>Updated Notification</Notification.TextLabel>
                    <Notification.CloseButton />
                </Notification>
                <Row>
                    <Col span={2}></Col>
                    <Col span={6}>
                        <Card.Header title={id ? 'Update Notification' : 'Create Notification'} />
                    </Col>
                    <Col span={4} className={classes.btns}>
                        <Button className={classes.cancel} onClick={() => window.history.back()}>
                            Exit
                        </Button>
                        <Button onClick={handleSubmit(onSubmit)} className={classes.cancel}>
                            {saving ? <Loader size="tiny" /> : `${id ? 'Save' : 'Create'}`}
                        </Button>
                    </Col>
                </Row>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col span={1}></Col>
                    <Col span={10} className={classes.box}>
                        <Box
                            direction="vertical"
                            verticalAlign="space-between"
                            padding="24px 29px 27px"
                            marginTop="50px"
                            borderRadius="8px"
                            backgroundColor="white"
                            height="100%"
                            width="100%"
                            boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                            flexGrow={1}
                        >
                            <Card>
                                <Card.Header
                                    title="Notification"
                                    subtitle="Get instant updates whenever an attendee RSVPs or purchases a ticket, ensuring you're always in the loop."
                                />
                                <Card.Content>
                                    <Layout cols={12}>
                                        <Cell span={2}>
                                            <FormField label="Active">
                                                <Controller
                                                    name="enabled"
                                                    control={control}
                                                    render={({ field }) => {
                                                        return (
                                                            <ToggleSwitch
                                                                {...field}
                                                                onChange={(e) => {
                                                                    setValue(
                                                                        'enabled',
                                                                        e.target.checked
                                                                    );
                                                                }}
                                                                checked={watchEnabled}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </FormField>
                                        </Cell>
                                    </Layout>
                                </Card.Content>
                            </Card>
                        </Box>
                    </Col>
                </Row>

                <Row>
                    <Col span={1}></Col>
                    <Col span={10}>
                        <Box
                            direction="vertical"
                            verticalAlign="space-between"
                            padding="24px 29px 27px"
                            borderRadius="8px"
                            backgroundColor="white"
                            height="100%"
                            width="100%"
                            marginTop="50px"
                            boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                            flexGrow={1}
                        >
                            <Card>
                                <Card.Header
                                    title="Message"
                                    subtitle="Craft a personalized message to send!"
                                />
                                <Card.Divider />
                                <Card.Content>
                                    <FormField
                                        label="Recipient Email"
                                        required
                                        infoContent="Enter the email address where the notification will be sent."
                                    >
                                        <Controller
                                            control={control}
                                            name="to_email"
                                            rules={{
                                                required: 'Recipient Email is required!',
                                                max: 140,
                                            }}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    status={
                                                        errors.to_email?.message
                                                            ? Input.StatusError
                                                            : ('' as InputStatus)
                                                    }
                                                />
                                            )}
                                        />
                                        <small className={classes.error}>
                                            {errors.from_email?.message}
                                        </small>
                                    </FormField>
                                    <Box marginTop={2} />
                                    <FormField label="Subject" required>
                                        <Controller
                                            control={control}
                                            name="subject"
                                            rules={{
                                                required: 'Subject is required!',
                                                max: 140,
                                            }}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    status={
                                                        errors.subject?.message
                                                            ? Input.StatusError
                                                            : ('' as InputStatus)
                                                    }
                                                />
                                            )}
                                        />
                                        <small className={classes.error}>
                                            {errors.subject?.message}
                                        </small>
                                    </FormField>

                                    <Box marginTop={2} />
                                    <MesssageKeysTable />

                                    <FormField
                                        label="Message Content"
                                        required
                                        infoContent="Craft a personalized message using tokens like {{attendee_first_name}}, {{event_title}}, and more to dynamically insert attendee and event details."
                                    >
                                        <Controller
                                            control={control}
                                            name="message"
                                            rules={{
                                                required: 'Message is required!',
                                                max: 140,
                                            }}
                                            render={({ field }) => (
                                                <RichTextInputArea
                                                    {...field}
                                                    initialValue={notification?.message}
                                                    minHeight="140px"
                                                    maxHeight="400px"
                                                    onChange={(htmlText: string) =>
                                                        setValue('message', htmlText)
                                                    }
                                                    status={
                                                        errors.message?.message
                                                            ? Input.StatusError
                                                            : ('' as InputStatus)
                                                    }
                                                    ref={messageRef}
                                                />
                                            )}
                                        />
                                        <small className={classes.error}>
                                            {errors.message?.message}
                                        </small>
                                    </FormField>
                                </Card.Content>
                            </Card>
                        </Box>
                    </Col>
                </Row>
            </form>
        </>
    );
};
