import { Card, Table } from 'wix-style-react';
import { useSelector } from 'react-redux';
import {
    fetchWorkflowsApi,
    selectCampaignWorkflowList,
} from '../../../features/campaign-log-slice';
import type { Workflow } from '../../../types';
import { useAppDispatch } from '../../../hooks';
import { useEffect } from 'react';
import moment from 'moment-timezone';

export const WorkflowTable = ({ campaignId }) => {
    const dispatch = useAppDispatch();
    const workflows = useSelector(selectCampaignWorkflowList);

    useEffect(() => {
        dispatch(fetchWorkflowsApi(campaignId));
    }, [dispatch]);

    const eventColumn = [
        {
            title: 'Event title',
            render: (row: Workflow) => row?.event?.title || 'N/A',
        },
    ];
    const columns = [
        {
            title: 'Scheduled Execution Date',
            render: (row: Workflow) => {
                const timezone = row?.event?.timezone;
                if (timezone) {
                    return moment(row?.execution_date).tz(timezone)?.format('ddd DD MMM h:mm a z');
                }

                return moment(row?.execution_date).format('ddd DD MMM h:mm a z');
            },
        },
        {
            title: 'Event',
            render: (row: Workflow) => {
                const { event } = row;
                return event?.title;
            },
        },
        {
            title: 'Status',
            render: (row: Workflow) => row?.status,
        },
    ];

    return (
        <>
            <Card>
                <Card.Header
                    title="Workflow Execution"
                    subtitle="Track Timestamps and Delivery Status for Effective Engagement"
                />
                <Card.Content>
                    <Table
                        showSelection
                        //   rowDetails={row => renderRowDetails(row)}
                        data={workflows}
                        columns={columns}
                    >
                        <Table.Content />
                    </Table>{' '}
                </Card.Content>
            </Card>
        </>
    );
};
