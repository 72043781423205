import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import { API_INSTANCE, buildURLWithState } from './utils';

export type SubscriptionSliceState = {
    subscription: any;
    plans: any;
    currentWidget: any;
    widgets: any;
    team: any;
    site: any;
};

const initialState: SubscriptionSliceState = {
    subscription: null,
    plans: [],
    widgets: [],
    team: [],
    site: null,
};

export const fetchWixSite = createAsyncThunk('site/fetch-wix-site', async (_, { getState }) => {
    return API_INSTANCE.get<any>(buildURLWithState(getState(), `/api/v1/dashboard/wix`))
        .then((response) => response.data)
        .catch((error) => error);
});
export const fetchSubscriptionApi = createAsyncThunk(
    'site/fetch-subscription',
    async (siteId, { getState }) => {
        return API_INSTANCE.get<any>(
            buildURLWithState(getState(), `/api/v1/dashboard/sites/${siteId}/subscription`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const fetchWixSubscriptionApi = createAsyncThunk(
    'site/fetch-wix-subscription',
    async (instance, { getState }) => {
        return API_INSTANCE.get<any>(
            buildURLWithState(
                getState(),
                `/api/v1/dashboard/sites/channels/wix/subscription`,
                `&brand=wix&instance=${instance}`
            )
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const updateSiteSession = createAsyncThunk(
    'site/update-session',
    async (siteId, { getState }) => {
        return API_INSTANCE.put<any>(
            buildURLWithState(getState(), `/api/v1/dashboard/sites/${siteId}/session`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const fetchTeamMembers = createAsyncThunk(
    'site/fetch-team',
    async (siteId, { getState }) => {
        return API_INSTANCE.get<any>(
            buildURLWithState(getState(), `/api/v1/dashboard/sites/${siteId}/teams`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const updateTeamMember = createAsyncThunk(
    'site/update-team-member',
    async ({ siteId, data }, { getState }) => {
        return API_INSTANCE.put<any>(
            buildURLWithState(getState(), `/api/v1/dashboard/sites/${siteId}/teams/${data.id}`),
            data
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const deleteTeamMember = createAsyncThunk(
    'site/delete-team-member',
    async ({ siteId, id }, { getState }) => {
        return API_INSTANCE.delete<any>(
            buildURLWithState(getState(), `/api/v1/dashboard/sites/${siteId}/teams/${id}`)
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const createTeamMember = createAsyncThunk(
    'site/create-team-member',
    async ({ siteId, data }, { getState }) => {
        return API_INSTANCE.post<any>(
            buildURLWithState(getState(), `/api/v1/dashboard/sites/${siteId}/teams`),
            data
        )
            .then((response) => response.data)
            .catch((error) => error);
    }
);

export const fetchSite = createAsyncThunk('sites/fetch-by-id', async (siteId, { getState }) => {
    return API_INSTANCE.get<any>(`/api/v1/dashboard/sites/${siteId}`)
        .then((response) => response.data)
        .catch((error) => error);
});

export const verifySiteDomain = createAsyncThunk(
    'sites/verify-domain',
    async (data, { getState }) => {
        return API_INSTANCE.post<any>(`/api/v1/dashboard/sites/${data.siteId}/verify-domain`, {
            custom_domain: data.customDomain,
        })
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const fetchWidgetsApi = createAsyncThunk('widgets/fetch', async (siteId, { getState }) => {
    return API_INSTANCE.get<any>(`/api/v1/dashboard/sites/${siteId}/widgets`)
        .then((response) => response.data)
        .catch((error) => error);
});
export const fetchWidgetApi = createAsyncThunk(
    'widget/fetch',
    async ({ siteId, widgetId }, { getState }) => {
        return API_INSTANCE.get<any>(`/api/v1/dashboard/sites/${siteId}/widgets/${widgetId}`)
            .then((response) => response.data)
            .catch((error) => error);
    }
);
export const deleteWidgetsApi = createAsyncThunk('widgets/delete', async (widget, { getState }) => {
    return API_INSTANCE.delete<any>(
        buildURLWithState(
            getState(),
            `/api/v1/dashboard/sites/${widget.site_id}/widgets/${widget.id}`
        )
    )
        .then((response) => response.data)
        .catch((error) => error);
});
export const createWidgetsApi = createAsyncThunk('widgets/create', async (widget, { getState }) => {
    return API_INSTANCE.post<any>(
        buildURLWithState(getState(), `/api/v1/dashboard/sites/${widget.site_id}/widgets`),
        widget
    )
        .then((response) => response.data)
        .catch((error) => error);
});
export const updateWidgetsApi = createAsyncThunk('widgets/update', async (widget, { getState }) => {
    return API_INSTANCE.put<any>(
        buildURLWithState(
            getState(),
            `/api/v1/dashboard/sites/${widget.site_id}/widgets/${widget.id}`
        ),
        widget
    )
        .then((response) => response.data)
        .catch((error) => error);
});
export const fetchBilling = createAsyncThunk('site/fetch-billing', async (_, { getState }) => {
    return API_INSTANCE.get<any>(buildURLWithState(getState(), `/api/v1/dashboard/billing`))
        .then((response) => response.data)
        .catch((error) => error);
});
export const fetchPlans = createAsyncThunk('site/fetch-plans', async (_, { getState }) => {
    return API_INSTANCE.get<any>(
        buildURLWithState(getState(), `/api/v1/dashboard/billing/public/plans`)
    )
        .then((response) => response.data)
        .catch((error) => error);
});

export const sitesSlice = createSlice({
    name: 'sites',
    initialState,
    reducers: {
        config_success: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSubscriptionApi.fulfilled, (state, action) => {
            state.subscription = action.payload;
        });
        builder.addCase(fetchWixSubscriptionApi.fulfilled, (state, action) => {
            state.subscription = action.payload;
        });
        builder.addCase(fetchTeamMembers.fulfilled, (state, action) => {
            state.team = action.payload;
        });
        builder.addCase(fetchBilling.fulfilled, (state, action) => {
            state.plans = action.payload;
        });
        builder.addCase(fetchPlans.fulfilled, (state, action) => {
            state.plans = action.payload;
        });
        builder.addCase(fetchWidgetsApi.fulfilled, (state, action) => {
            state.widgets = action.payload;
        });
        builder.addCase(fetchWidgetApi.fulfilled, (state, action) => {
            state.currentWidget = action.payload;
        });
        builder.addCase(deleteWidgetsApi.fulfilled, (state, action) => {
            state.widgets = state.widgets.filter((w) => w.id !== action?.meta?.arg?.id);
        });
        builder.addCase(deleteTeamMember.fulfilled, (state, action) => {
            state.team = state.team.filter((w) => w.id !== action?.meta?.arg?.id);
        });
        builder.addCase(createWidgetsApi.fulfilled, (state, action) => {
            state.widgets = [{ ...action.payload, is_new: true }].concat(state.widgets);
        });
        builder.addCase(createTeamMember.fulfilled, (state, action) => {
            state.team = state.team.concat(action.payload);
        });
        builder.addCase(updateTeamMember.fulfilled, (state, action) => {
            state.team = state.team.map((team) =>
                action.meta.arg.data.id === team.id ? { ...team, ...action.payload } : team
            );
        });
        builder.addCase(fetchSite.fulfilled, (state, action) => {
            state.site = action.payload;
        });
        builder.addCase(verifySiteDomain.fulfilled, (state, action) => {
            if (action.payload.id) {
                state.site = action.payload;
            } else {
                throw new Error('Could not verify domain');
            }
        });
        builder.addCase(fetchWixSite.fulfilled, (state, action) => {
            state.site = action.payload;
        });
        builder.addCase(updateWidgetsApi.fulfilled, (state, action) => {
            state.widgets = state.widgets.map((w) => {
                const updatedWidget = action?.meta?.arg;
                if (w.id === updatedWidget.id) {
                    return { ...w, ...updatedWidget };
                }
                return w;
            });
        });
    },
});

export const selectSubscription = (state: RootState) => state?.sites.subscription;
export const selectPlans = (state: RootState) => state?.sites.plans;
export const selectWidgets = (state: RootState) => state?.sites.widgets;
export const selectCurrentWidget = (state: RootState) => state?.sites.currentWidget;
export const selectSiteTeam = (state: RootState) => state?.sites.team;
export const selectSite = (state: RootState) => state?.sites.site;

export default sitesSlice;
