import { Fragment, useEffect } from 'react';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import { classes } from './date-with-times-form.st.css';
import * as Icons from 'wix-ui-icons-common';
import {
    Cell,
    Layout,
    FormField,
    DatePicker,
    TextButton,
    TimeInput,
    IconButton,
    Box,
} from 'wix-style-react';

export const DateWithTimesForm = ({ control, setValue, watch, timeEntries, setTimeEntries }) => {
    const watchTimeStart = watch('dateStart');
    const watchTimeEnd = watch('dateEnd');
    useEffect(() => {
        if (watchTimeStart.getTime() > watchTimeEnd.getTime()) {
            setValue('dateEnd', watchTimeStart);
        }
    }, [watchTimeStart]);
    useEffect(() => {
        if (watchTimeStart.getTime() > watchTimeEnd.getTime()) {
            setValue('dateStart', watchTimeEnd);
        }
    }, [watchTimeEnd]);

    const addTimeEntry = () => {
        if (timeEntries.length === 0) {
            setTimeEntries([
                {
                    start_time: moment().set({ hour: 8, minute: 0 }),
                    end_time: moment().set({ hour: 9, minute: 0 }),
                },
            ]);
            return;
        }
        const lastEndTime = timeEntries[timeEntries.length - 1].end_time;
        setTimeEntries([
            ...timeEntries,
            {
                start_time: lastEndTime,
                end_time: moment(lastEndTime).add(1, 'hour'),
            },
        ]);
    };

    const removeTimeEntry = (index) => {
        setTimeEntries(timeEntries.filter((_, idx) => idx !== index));
    };

    return (
        <Layout>
            <Cell span={6}>
                <FormField label="Start Date">
                    <Controller
                        control={control}
                        name="dateStart"
                        render={({ field }) => {
                            return (
                                <DatePicker
                                    {...field}
                                    onChange={(date: Date) => setValue('dateStart', date)}
                                    popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                                    width="auto"
                                />
                            );
                        }}
                    />
                </FormField>
            </Cell>
            <Cell span={6}>
                <FormField label="End Date">
                    <Controller
                        control={control}
                        name="dateEnd"
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                onChange={(date: Date) => setValue('dateEnd', date)}
                                popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                                width="auto"
                            />
                        )}
                    ></Controller>
                </FormField>
            </Cell>

            {timeEntries.map((entry, index) => (
                <Fragment key={index}>
                    <Cell span={5}>
                        <FormField label="Start Time">
                            <Controller
                                name={`timeEntries[${index}].start_time`}
                                control={control}
                                render={({ field }) => (
                                    <TimeInput
                                        {...field}
                                        width="auto"
                                        defaultValue={entry.start_time}
                                        onChange={(time) => {
                                            const newEntries = [...timeEntries];
                                            newEntries[index].start_time = time;
                                            setTimeEntries(newEntries);
                                        }}
                                    />
                                )}
                            />
                        </FormField>
                    </Cell>
                    <Cell span={5}>
                        <FormField label="End Time">
                            <Controller
                                name={`timeEntries[${index}].end_time`}
                                control={control}
                                render={({ field }) => (
                                    <TimeInput
                                        {...field}
                                        width="auto"
                                        defaultValue={entry.end_time}
                                        onChange={(time) => {
                                            const newEntries = [...timeEntries];
                                            newEntries[index].end_time = time;
                                            setTimeEntries(newEntries);
                                        }}
                                    />
                                )}
                            />
                        </FormField>
                    </Cell>
                    <Cell span={2}>
                        <Box verticalAlign="middle">
                            <IconButton
                                onClick={() => removeTimeEntry(index)}
                                className={classes.delete}
                            >
                                <Icons.Delete />
                            </IconButton>
                        </Box>
                    </Cell>
                </Fragment>
            ))}
            <Cell span={12}>
                <TextButton size="small" prefixIcon={<Icons.Add />} onClick={addTimeEntry}>
                    Add More
                </TextButton>
            </Cell>
        </Layout>
    );
};
