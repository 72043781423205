import { Loader, Box, Card, Stepper, Button, Text, Divider } from 'wix-style-react';
import { fromURL } from 'image-resize-compress';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAppSelector } from '../../hooks';
import {
    createEventApi,
    getEventApi,
    updateEventApi,
    selectEventsIsLoading,
    selectSingleEvent,
    eventResponseTransform,
    createPreapprovedEventAPI,
    updatePreapprovedEventApi,
} from '../../features/events-slice';

import { useAppDispatch } from '../../hooks';
import type { Event } from '../../types';
import moment from 'moment-timezone';
import { getCurrency } from './currency-utils';
import { getPresignedURL } from '../../utils';
import { useSelector } from 'react-redux';
import { appendSiteData, selectSiteData } from '../../features/account-slice';
import { EditEventHeader } from './edit-event-header';
import { BasicInfo } from './basic-info';
import { EventLocation } from './event-location';
import { CustomFieldTags } from './custom-fields-tags';
import { CreateEmbeddedTickets } from './create-tickets';
import { EventCampaignList } from './event-campaigns';
import { isEmpty } from 'lodash';
import { AdditionalQuestions } from './components/additional-questions';
import { RSVPConfirmationMessage } from './components/rsvp-confirmation-message';
import { classes } from './create-events.st.css';
import { PromotionalImages } from './components/promotional-images';
import SaveEventModal from './components/save-event-modal';
import { EventDateTimeSelection } from './event-date-time-selection';
import { Waitlist } from './waitlist';
import { API_INSTANCE, buildURL } from '../../features/utils';
import { PublishShopifyButton } from './publish-shopify-button';
import { selectSite } from '../../features/site-slice';

export const statusOptions = [
    { id: 'live', value: 'Live' },
    { id: 'draft', value: 'Draft' },
];

export const EditEvent = () => {
    const { id } = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectEventsIsLoading);
    const event = id ? useAppSelector(selectSingleEvent) : null;

    useEffect(() => {
        if (id) dispatch(getEventApi(id));
    }, [dispatch, id]);

    if (isLoading)
        return (
            <Box width="100%" height="100px" align="center" verticalAlign="middle">
                <Loader size="medium" />
            </Box>
        );
    return <CreateEvent savedEvent={event} isUpdating={!!id} />;
};

export const CopyCreateEvent = () => {
    const { id } = useParams<{ source_id: string }>();
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectEventsIsLoading);
    const event = useAppSelector(selectSingleEvent);

    useEffect(() => {
        dispatch(getEventApi(id));
    }, [id]);
    if (isLoading)
        return (
            <Box width="100%" height="100px" align="center" verticalAlign="middle">
                <Loader size="medium" />
            </Box>
        );
    return (
        <CreateEvent
            savedEvent={{ ...event, title: `${event?.title} Copy`, status: null, id: null }}
            isCopy
        />
    );
};

export const addTimeToDate = (date, momentTime) => {
    date.setHours(momentTime?.hours());
    date.setMinutes(momentTime?.minutes());
    date.setSeconds(momentTime?.seconds());
};

export const DEFAULT_PERSONALIZED_MESSAGE =
    '<p><u><strong>Summary</strong></u></p>\n<p></p>\n<p>Thanks for registering, {{attendee_first_name}}</p>\n<p></p>\n<p>Event: {{event_title}}</p>\n<p>Date: {{event_start_date}}</p>\n<p>Time: {{event_start_time}}</p>';

export const convertToUtc = (localDate, localTimeZone) => {
    const localMoment = moment(localDate).format('YYYY-MM-DDTHH:mm:ss');
    return moment.tz(localMoment, localTimeZone).utc().format();
};
export const convertToDate = (dateStart, timezone) => {
    if (timezone) {
        const withoutZone = moment(dateStart).tz(timezone);
        return new Date(withoutZone.format('YYYY-MM-DDTHH:mm:ss'));
    }
    return new Date(moment(dateStart).format('YYYY-MM-DDTHH:mm:ss'));
};
export const getMomentDate = (date, timezone) => {
    if (timezone) {
        return moment(date).tz(timezone);
    }
    return moment(date);
};
export const CreateEventPublic = () => {
    return <CreateEvent isPublicCreation />;
};
export const CreateEvent = ({ savedEvent, isCopy, isPublicCreation }) => {
    const [event, setEvent] = useState<any>(savedEvent);
    const [image, setImageUrl] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const [saved, setSaved] = useState(false);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [questions, setQuestions] = useState([]);

    const descriptionRef = useRef(null);
    const { id } = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    const site = useSelector(selectSiteData);

    useEffect(() => {
        async function fetch() {
            const url = `/api/v1/dashboard/create-event-auth`;
            const { data } = await API_INSTANCE.post(buildURL(site, url));
            if (data.request_event_token) {
                dispatch(appendSiteData({ request_event_token: data.request_event_token }));
            }
        }
        if (isPublicCreation) {
            void fetch();
        }
    }, [dispatch, isPublicCreation]);

    const { handleSubmit, watch, control, setValue, getValues, formState, trigger } =
        useForm<Event>({
            defaultValues: {
                ...event,
                timeStart: event?.dateStart
                    ? getMomentDate(event?.dateStart, event?.timezone)
                    : moment().add(2, 'hour').startOf('hour'),
                timeEnd: event?.dateEnd
                    ? getMomentDate(event?.dateEnd, event?.timezone)
                    : moment().add(3, 'hour').startOf('hour'),
                dateStart: event?.dateStart
                    ? convertToDate(event.dateStart, event.timezone)
                    : new Date(),
                dateEnd: event?.dateEnd ? convertToDate(event.dateEnd, event.timezone) : new Date(),
                repeatingUntil: event?.repeatingUntil
                    ? new Date(event?.repeatingUntil)
                    : new Date(),
                secondary_images: [],
                promotional_images: [],
                description_thumbnail_images: [],
                confirmationMessage: event?.confirmationMessage || DEFAULT_PERSONALIZED_MESSAGE,
                timezone: event?.timezone || moment.tz.guess(),
                currency: event?.currency || getCurrency(),
                eventSetup: event?.eventSetup || 'tickets',
                unlimitedCapacity:
                    event?.unlimitedCapacity === undefined || event?.unlimitedCapacity === null
                        ? true
                        : event?.unlimitedCapacity,
            },
        });

    const { errors } = formState;

    const watchEventSetup = watch('eventSetup');
    useEffect(() => {
        async function fetchData() {
            if (event && event.image_url && isCopy) {
                const blob = await fromURL(event.image_url);
                const name = event.image_url.substr(event.image_url.lastIndexOf('_') + 1);
                const file = new File([blob], name, { type: image.type });
                setValue('image', file);
            }
            if (event && event.secondaryImageUrls && event.secondaryImageUrls.length > 0) {
                const files = await Promise.all(
                    event.secondaryImageUrls.map(async (url) => {
                        const blob = await fromURL(url);
                        const name = url.substr(url.lastIndexOf('_') + 1);
                        return new File([blob], name);
                    })
                );
                setValue('secondary_images', files);
                setValue('secondary_image_urls', event.secondaryImageUrls);
            }
            if (event && event.promotionalImageUrls && event.promotionalImageUrls.length > 0) {
                const files = await Promise.all(
                    event.promotionalImageUrls.map(async (url) => {
                        const blob = await fromURL(url);
                        const name = url.substr(url.lastIndexOf('_') + 1);
                        return new File([blob], name);
                    })
                );
                setValue('promotional_images', files);
                setValue('promotional_image_urls', event.promotionalImageUrls);
            }
            if (
                event &&
                event.descriptionThumbnailUrls &&
                event.descriptionThumbnailUrls.length > 0
            ) {
                const files = await Promise.all(
                    event.descriptionThumbnailUrls.map(async (url) => {
                        const blob = await fromURL(url);
                        const name = url.substr(url.lastIndexOf('_') + 1);
                        return new File([blob], name);
                    })
                );
                setValue('description_thumbnail_images', files);
                setValue('description_thumbnail_urls', event.descriptionThumbnailUrls);
            }
        }
        fetchData();
    }, [event]);

    const onSubmit: SubmitHandler<Event> =
        (status, destination = 'self', extra = {}) =>
        async (values) => {
            setError(null);
            let submitFunc = isPublicCreation ? createPreapprovedEventAPI : createEventApi;
            const id = event?.id;
            if (id && !isCopy) {
                submitFunc = isPublicCreation ? updatePreapprovedEventApi : updateEventApi;
            }

            const repeatDays = Object.keys(values)
                .filter((v) => v.startsWith('repeat_day'))
                .map((v) => ({ day: v.replace('repeat_day_', ''), value: !!values[v] }));

            let endDate = values.dateEnd;
            // if (!values.repeat) {
            //     endDate = new Date(values.dateStart.valueOf());
            // }
            addTimeToDate(values.dateStart, values.timeStart);
            addTimeToDate(
                endDate,
                values.allDayEvent ? values.timeStart.add(1, 'hour') : values.timeEnd
            );

            setError(false);

            setSaved(false);
            setSaving(true);
            let imageUrl = values.image_url;
            let secondaryImageUrls = values.secondary_image_urls;
            let promotionalImageUrls = values.promotional_image_urls;
            const file = values.image;
            const secondaryImages = values.secondary_images;
            const promotionalImages = values.promotional_images;
            if (file) {
                imageUrl = await getPresignedURL(site, file);
            }
            if (secondaryImages) {
                secondaryImageUrls = await Promise.all(
                    secondaryImages.map((file: File) => getPresignedURL(site, file))
                );
            }
            if (promotionalImages) {
                promotionalImageUrls = await Promise.all(
                    promotionalImages.map((file: File) => getPresignedURL(site, file))
                );
            }
            let descriptionThumbnailUrls = values.description_thumbnail_urls;
            const descriptionThumbnailImages = values.description_thumbnail_images;

            if (descriptionThumbnailImages) {
                descriptionThumbnailUrls = await Promise.all(
                    descriptionThumbnailImages.map((file: File) => getPresignedURL(site, file))
                );
            }
            const venue = {
                multi_line_address_display: values?.address?.formatted || values.location,
                longitude: values?.address?.latLng?.lng,
                address: {
                    region: values?.address?.state,
                    country: values?.address?.country,
                    countryCode: values?.address?.countryCode,
                    postal_code: values?.address?.postalCode,
                    address_1: values?.address?.street,
                    city: values?.address?.city,
                },
                latitude: values?.address?.latLng?.lat,
            };
            const gridId = venue?.latitude
                ? `${venue.latitude.toFixed(2)}:${venue.longitude.toFixed(2)}`
                : undefined;

            const location = venue?.latitude
                ? {
                      latitude: values?.address?.latLng?.lat,
                      longitude: values?.address?.latLng?.lng,
                  }
                : undefined;
            const additionalProperties = Object.keys(values)
                .filter((a) => customFields.find((f) => f.id === a))
                .map((a) => ({
                    id: a,
                    name: customFields.find((f) => f.id === a).name,
                    value: values[a],
                    is_deleted: customFields.find((f) => f.id === a).is_deleted,
                }));

            const eventSetup = values.eventSetup;

            const eventSaved = await dispatch(
                submitFunc({
                    id,
                    is_public_creation: isPublicCreation,
                    title: values.title || '',
                    description: values.description || '',
                    confirmation_message: values.confirmationMessage || '',
                    start_date: values.isMultiDay
                        ? undefined
                        : convertToUtc(values.dateStart, values.timezone),
                    end_date: values.isMultiDay
                        ? undefined
                        : convertToUtc(endDate, values.timezone),
                    timezone: values.timezone,
                    tags: tags.map((tag) => ({ id: tag.id, name: tag.children })),
                    repeating_until: values.repeatingUntil,
                    venue,
                    is_repeating_event: values.repeat,
                    repeat_on: values.repeatOn,
                    is_online: values.isOnline,
                    venue_email: values.venueEmail,
                    venue_name: values.venueName,
                    venue_phone: values.venuePhone,
                    venue_website: values.venueWebsite,
                    event_setup: values.eventSetup,
                    url: eventSetup === 'url' ? values.url : null,
                    status,
                    currency: values.currency,
                    image_url: imageUrl,
                    secondary_image_urls: secondaryImageUrls,
                    promotional_image_urls: promotionalImageUrls,
                    description_thumbnail_urls: descriptionThumbnailUrls,
                    all_day_event: values.allDayEvent,
                    date_tbd: values.dateTBD,
                    use_checkout_form: ['tickets', 'rsvp'].includes(eventSetup),
                    repeating_days: repeatDays,
                    unique_recurring_zoom_link: values.occurrenceUniqueZoomLink,
                    questions: questions,
                    type: 'eventviewer',
                    additional_properties: additionalProperties,
                    include_event_data_url: values.includeEventDataUrl,
                    multi_session_event: values.multiSessionEvent,
                    event_capacity: values.eventCapacity,
                    unlimited_capacity: values.unlimitedCapacity,
                    event_type: values.eventType,
                    category: values.category,
                    subcategory: values.subcategory,
                    sales_tax: values.salesTax || 0,
                    external_url_link_to_hosted_page: values.externalUrlLinkToHostedPage,
                    override_parent_tickets: values.overrideParentTickets,
                    is_multi_day: values.isMultiDay,
                    is_time_based: values.isTimeBased,
                    paypal_enable_credit_card_fields: values.paypalEnableCreditCardFields,
                    enable_waitlist: values.enableWaitlist,
                    privacy_hide_event: values.privacyHideEvent,
                    privacy_access_code_enabled: values.privacyAccessCodeEnabled,
                    privacy_access_code: values.privacyAccessCode,
                    waitlist_auto_response_message: values.waitlistAutoResponseMessage,
                    waitlist_ticket_release_message: values.waitlistTicketReleaseMessage,
                    waitlist_confirmation_message: values.waitlistConfirmationMessage,
                    waitlist_enable_communication: values.waitlistEnableCommunication,
                    waitlist_trigger_event_capacity: values.waitlistTriggerEventCapacity,
                    waitlist_trigger_tickets_sold_out: values.waitlistTriggerTicketsSoldOut,
                    waitlist_call_to_action: values.waitlistCallToAction,
                    waitlist_description: values.waitlistDescription,
                    grid_id: gridId,
                    location,
                    destination,
                    ...extra,
                })
            );
            if (eventSaved.payload.error) {
                setSaved(true);
                setSaving(false);
                setError(eventSaved.payload.error);
                return;
            }
            setSaved(true);
            setSaving(false);
            setEvent(eventResponseTransform(eventSaved.payload));
        };
    useEffect(() => {
        if (!event?.timezone) setValue('timezone', moment.tz.guess());
        if (!event?.repeatOn) setValue('repeatOn', 'daily');
        if (event) {
            setValue('useCheckoutForm', event?.useCheckoutForm);
            if (event.image_url) setImageUrl(event.image_url);
            setValue('repeatOn', event?.repeatOn);
            setValue('isSeriesParent', event?.isSeriesParent);

            setQuestions(event?.questions || []);
            event?.repeatingDays?.map((v) => setValue(`repeat_day_${v.day}`, v.value));
            if (event.description) descriptionRef.current?.setValue(event.description);
        }
    }, [event]);

    const [tags, setTags] = useState(
        event?.tags?.map((tag) => ({ id: tag.id, children: tag.name, theme: 'success' })) || []
    );
    const [customFields, setCustomFields] = useState(event?.additionalProperties || []);

    const baseSteps = [
        {
            text: 'Event Details',
            component: (
                <BasicInfo
                    descriptionRef={descriptionRef}
                    isPublicCreation={isPublicCreation}
                    event={event}
                    watch={watch}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    getValues={getValues}
                />
            ),
        },
        {
            text: 'Dates & Times',
            component: (
                <EventDateTimeSelection
                    event={event}
                    watch={watch}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    getValues={getValues}
                />
            ),
        },
        {
            text: 'Venue & Location',
            component: (
                <EventLocation
                    event={event}
                    watch={watch}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                />
            ),
        },
        {
            text: 'Tickets',
            excludes: ['nothing', 'url', 'rsvp'],
            component: (
                <CreateEmbeddedTickets
                    setValue={setValue}
                    getValues={getValues}
                    control={control}
                    event={event}
                    watch={watch}
                    setQuestions={setQuestions}
                    isPublicCreation={isPublicCreation}
                />
            ),
        },
        {
            text: 'Email Communication',
            excludes: ['nothing'],
            component: (
                <EventCampaignList
                    event={event}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                />
            ),
        },
        {
            text: 'Waitlist',
            excludes: ['nothing', 'url'],
            component: (
                <Waitlist
                    setValue={setValue}
                    getValues={getValues}
                    control={control}
                    event={event}
                    watch={watch}
                />
            ),
        },
        {
            text: 'Additional Info',
            excludes: ['nothing', 'url'],
            component: (
                <AdditionalQuestions
                    event={event}
                    questions={questions}
                    setQuestions={setQuestions}
                />
            ),
        },
        {
            text: 'Checkout Summary',
            excludes: ['nothing', 'url'],
            component: (
                <RSVPConfirmationMessage event={event} control={control} setValue={setValue} />
            ),
        },
        {
            text: 'Promotional Media',
            excludes: ['nothing'],
            component: <PromotionalImages event={event} setValue={setValue} watch={watch} />,
        },
        {
            text: 'Tags & Custom Fields',
            component: (
                <CustomFieldTags
                    tags={tags}
                    setTags={setTags}
                    customFields={customFields}
                    setCustomFields={setCustomFields}
                    event={event}
                    control={control}
                    setValue={setValue}
                />
            ),
        },
    ];

    const filteredSteps = isPublicCreation
        ? baseSteps.filter((step) =>
              ['Event Details', 'Venue & Location', 'Dates & Times', 'Tickets'].includes(step.text)
          )
        : baseSteps;

    const activeSteps = filteredSteps.filter(
        (step) => !(step.excludes || []).includes(watchEventSetup)
    );
    const steps = activeSteps.map((step, index) => {
        let type = '';
        if (activeStep > index) {
            type = 'completed';
        } else if (!id && activeStep < index) {
            type = 'disabled';
        }
        return {
            text: step.text,
            type,
        };
    });

    return (
        <>
            <Box
                marginLeft={isPublicCreation ? 2 : 20}
                marginRight={isPublicCreation ? 2 : 20}
                direction="vertical"
            >
                {id && (
                    <EditEventHeader
                        error={error}
                        saved={saved}
                        setSaved={setSaved}
                        event={event}
                        isCopy={isCopy}
                        saving={saving}
                        id={id}
                        handleSubmit={(type: string, dest: string, extra: any) =>
                            handleSubmit(onSubmit(type, dest, extra))
                        }
                    />
                )}
                <Box className={classes.wrap} marginTop={5}>
                    <Stepper
                        fit="compact"
                        activeStep={activeStep}
                        steps={steps}
                        onClick={(activeStep) => setActiveStep(activeStep)}
                    />
                </Box>
                <Box marginTop={5} />

                <Card.Divider />
                <Card.Content>
                    {activeSteps[activeStep].component}
                    {!id && (
                        <Box align="center" marginTop={4} gap={3}>
                            {activeStep < steps.length - 1 ? (
                                <Box gap={2} direction="vertical">
                                    <Button
                                        size="large"
                                        prefixIcon={
                                            saving ? (
                                                <Box marginRight={2}>
                                                    <Loader size="tiny" color="white" />
                                                </Box>
                                            ) : null
                                        }
                                        disabled={saving}
                                        onClick={async () => {
                                            const noErrors = await trigger();
                                            await handleSubmit(
                                                onSubmit(id ? event.status : 'draft')
                                            )();

                                            if (noErrors) {
                                                setActiveStep(activeStep + 1);
                                            }
                                        }}
                                    >
                                        Next: {activeSteps[activeStep + 1].text}
                                    </Button>

                                    <Box marginTop={2} marginBottom={2}>
                                        <Divider />
                                    </Box>

                                    {!isPublicCreation && activeStep > 0 && (
                                        <PublishButtons
                                            handleSubmit={(type: string) =>
                                                handleSubmit(onSubmit(type))
                                            }
                                            saving={saving}
                                            saved={saved}
                                            error={error}
                                            isPublicCreation={isPublicCreation}
                                            event={event}
                                            currentSite={site}
                                        />
                                    )}
                                    {isPublicCreation && activeStep > activeSteps.length - 1 && (
                                        <PublishButtons
                                            handleSubmit={(type: string) =>
                                                handleSubmit(onSubmit(type))
                                            }
                                            saving={saving}
                                            saved={saved}
                                            error={error}
                                            isPublicCreation={isPublicCreation}
                                            event={event}
                                            currentSite={site}
                                        />
                                    )}
                                </Box>
                            ) : (
                                <PublishButtons
                                    handleSubmit={(type: string) => handleSubmit(onSubmit(type))}
                                    saving={saving}
                                    saved={saved}
                                    isPublicCreation={isPublicCreation}
                                    event={event}
                                    currentSite={site}
                                />
                            )}
                            {!isEmpty(errors) && (
                                <Box align="center" verticalAlign="middle">
                                    {' '}
                                    <Text skin="error">Fields marked with (*) are required.</Text>
                                </Box>
                            )}
                        </Box>
                    )}
                </Card.Content>
            </Box>
        </>
    );
};

const PublishButtons = ({ handleSubmit, saving, saved, error, isPublicCreation, event }) => {
    const [messageType, setMessageType] = useState(null);
    const site = useSelector(selectSite);

    return (
        <Box gap="2" marginLeft={4}>
            {!isPublicCreation && (
                <Button
                    skin="light"
                    onClick={async () => {
                        await handleSubmit('draft')();
                        setMessageType('saveAsDraftSuccess');
                    }}
                >
                    Save as Draft
                </Button>
            )}

            {event && site?.type !== 'shopify' && (
                <Button
                    onClick={async () => {
                        await handleSubmit('live')();
                        setMessageType(
                            isPublicCreation ? 'userSubmittedEventSuccess' : 'publishedSuccess'
                        );
                    }}
                    disabled={saving}
                >
                    {isPublicCreation ? ' Submit' : 'Publish'}
                </Button>
            )}

            {event && site?.type === 'shopify' && (
                <>
                    <Box direction="vertical" gap={1}>
                        <PublishShopifyButton
                            handleSubmit={(status, type, extra) => {
                                handleSubmit(status, 'type', extra)();
                                setMessageType('shopifyPublishSuccess');
                            }}
                            event={event}
                        />
                    </Box>
                </>
            )}

            {saving && <Loader size="tiny" />}
            {!error && saved && messageType && (
                <SaveEventModal isPublicCreation={isPublicCreation} messageType={messageType} />
            )}
            {error && saved && messageType && <SaveEventModal messageType={'error'} />}
        </Box>
    );
};
export default CreateEvent;
