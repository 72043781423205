import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Icons from 'wix-ui-icons-common';

import TotalAttendanceIcon from '../../../assets/attendance/people.svg';
import CheckedInAttendanceIcon from '../../../assets/attendance/checked-in.svg';
import NotCheckedInAttendanceIcon from '../../../assets/attendance/not-checked-in.svg';

import { Box, Button, Loader, Tooltip } from 'wix-style-react';
import { API_INSTANCE, buildURL } from '../../../features/utils';
import { useHistory } from 'react-router-dom';
import { selectSiteData } from '../../../features/account-slice';

export const AttendanceStats = ({ event }) => {
    const [stats, setStats] = useState();
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const site = useSelector(selectSiteData);

    useEffect(() => {
        const fetchStatsByEvent = async () => {
            setLoading(true);
            const url = `/api/v1/dashboard/attendee-event-rollup/events/${event.id}`;
            const { data } = await API_INSTANCE.get(buildURL(site, url));
            setStats(data);
            setLoading(false);
        };
        void fetchStatsByEvent();
    }, [event?.id, site]);

    return (
        <Box gap={2} align="center" verticalAlign="middle">
            <Box gap={1}>
                <img src={TotalAttendanceIcon} width={20} />{' '}
                {loading ? <Loader size="tiny" /> : stats?.attendance_count || 0}
            </Box>
            <Box gap={1}>
                <img src={CheckedInAttendanceIcon} width={20} />{' '}
                {loading ? <Loader size="tiny" /> : stats?.checked_in_status_count || 0}
            </Box>
            <Box gap={1}>
                <img src={NotCheckedInAttendanceIcon} width={20} />{' '}
                {loading ? <Loader size="tiny" /> : stats?.not_attending_status_count || 0}
            </Box>
            <Tooltip content="View Attendees and Ticket Sales">
                <Button
                    suffixIcon={<Icons.ChevronRightLarge />}
                    size="small"
                    priority="secondary"
                    onClick={() =>
                        history.push(buildURL(site, `/events/manage/${event.id}/attendees`))
                    }
                >
                    Attendees
                </Button>
            </Tooltip>
        </Box>
    );
};
