import { FormField, RichTextInputArea, Card, Box } from 'wix-style-react';

import { Controller } from 'react-hook-form';
import { MesssageKeysTable } from '../../campaigns/components/message-keys-table';
import { DEFAULT_PERSONALIZED_MESSAGE } from '../create-event';
import { PlanNotification } from '../../../components/plan-notification';

export const RSVPConfirmationMessage = ({ event, control, setValue }) => {
    return (
        <Box width="90%" boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717" margin="0 auto">
            <Card>
                <Card.Header
                    title="Checkout summary text"
                    subtitle="Personalize a summary for you guests that will be shown to them on the checkout screen after they purchase a ticket or RSVP"
                />
                <Card.Divider />
                <Card.Content>
                    <PlanNotification type="featured" text="personalization message">
                        <MesssageKeysTable appendTo="window" />
                        <FormField label="Message" required>
                            <Controller
                                control={control}
                                name="confirmationMessage"
                                render={({ field }) => (
                                    <RichTextInputArea
                                        {...field}
                                        initialValue={
                                            event?.confirmationMessage ||
                                            DEFAULT_PERSONALIZED_MESSAGE
                                        }
                                        minHeight="140px"
                                        maxHeight="400px"
                                        onChange={(htmlText: string) =>
                                            setValue('confirmationMessage', htmlText)
                                        }
                                    />
                                )}
                            ></Controller>
                        </FormField>
                    </PlanNotification>
                </Card.Content>
            </Card>
        </Box>
    );
};
