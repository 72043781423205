import { SettingsHeader } from '../../../../components/settings-header';
import { ColorPicker } from '../color-picker';
import { FontColorPicker } from '../font-picker';

export const CalendarDesign = ({ label }) => {
    return (
        <div>
            <SettingsHeader title={label} />

            <FontColorPicker
                title="Title"
                fontUpdateKey="_calendarTitleFont"
                colorUpdateKey="_calendarTitleColor"
                startWithColor="color-5"
                startWithTheme="font_9"
                startWithSize={14}
            />

            <ColorPicker
                title="Event"
                colorUpdateKey="_calendarEventColor"
                startWithColor="color-5"
            />

            <FontColorPicker
                title="Date Header"
                fontUpdateKey="_calendarDateHeaderFont"
                colorUpdateKey="_calendarDateHeaderColor"
                startWithColor="color-5"
                startWithTheme="font_8"
                startWithStyle={{ bold: true }}
                startWithSize={16}
            />

            <ColorPicker
                title="Navigation Foreground Color"
                colorUpdateKey="_calendarNavForegroundColor"
                startWithColor="color-1"
                startWithOpacity={1}
            />

            <ColorPicker
                title="Navigation Background Color"
                colorUpdateKey="_calendarNavBackgroundColor"
                startWithColor="color-1"
                startWithOpacity={1}
            />

            <ColorPicker
                title="Day Names Header"
                colorUpdateKey="_dayNamesHeaderColor"
                startWithColor="color-1"
                startWithOpacity={1}
            />

            <ColorPicker
                title="Selection Color"
                colorUpdateKey="_calendarTodayBackgroundColor"
                startWithColor="color-4"
            />
        </div>
    );
};
