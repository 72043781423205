import { SettingsHeader } from '../../../../components/settings-header';
import { ColorPicker } from '../color-picker';
import { FontColorPicker } from '../font-picker';

export const MultiDaySelection = ({ label }) => {
    return (
        <div>
            <SettingsHeader title={label} />

            <ColorPicker
                title="Date Picker Calendar Icon"
                colorUpdateKey="_multiDayDatePickerCalendarIconColor"
                startWithColor="color-5"
            />
            <ColorPicker
                title="Date Picker Border Color"
                colorUpdateKey="_multiDayDatePickerBorderColor"
                startWithColor="color-6"
            />
            <ColorPicker
                title="Date Picker Header Background Color"
                colorUpdateKey="_multiDayDatePickerHeaderBackgroundColor"
                startWithColor="color-6"
            />

            <FontColorPicker
                title="Date Picker Current Month Header"
                startWithSize={14}
                fontUpdateKey="_multiDayDatePickerCurrentMonthHeaderFont"
                startWithTheme="font_7"
                colorUpdateKey="_multiDayDatePickerCurrentMonthHeaderColor"
                startWithColor="color-5"
                fontMaxSize={20}
            />
            <FontColorPicker
                title="Date Picker Day Name"
                startWithSize={14}
                fontUpdateKey="_multiDayDatePickerCurrentDayNameFont"
                startWithTheme="font_9"
                colorUpdateKey="_multiDayDatePickerCurrentDayNameColor"
                startWithColor="color-5"
                fontMaxSize={20}
            />
            <ColorPicker
                title="Date Picker Selected Date Highlight Color"
                colorUpdateKey="_multiDayDatePickerSelectedDateBackgroundColor"
                startWithColor="color-6"
            />
            <ColorPicker
                title="Date Picker Selected Date Color"
                colorUpdateKey="_multiDayDatePickerSelectedDateColor"
                startWithColor="color-6"
            />

            <ColorPicker
                title="Date Picker Available Date Highlight Color"
                colorUpdateKey="_multiDayDatePickerAvailableDateBackgroundColor"
                startWithColor="color-6"
            />
            <ColorPicker
                title="Date Picker Available Date Color"
                colorUpdateKey="_multiDayDatePickerAvailablDateColor"
                startWithColor="color-2"
            />
        </div>
    );
};
