import { configureStore, ThunkAction, AnyAction, combineReducers } from '@reduxjs/toolkit';
import eventsSlice, { EventSliceState } from './features/events-slice';
import settingsSlice, { SettingsSliceState } from './features/settings-slice';

import campaignsSlice, { CampaignSliceState } from './features/campaign-slice';
import attendeesSlice, { AttendeeSliceState } from './features/attendees-slice';
import campaignLogsSlice, { CampaignLogsSliceState } from './features/campaign-log-slice';
import accountSlice, { AccountSliceState } from './features/account-slice';
import siteSlice, { SubscriptionSliceState } from './features/site-slice';

const rootReducer = combineReducers({
    [eventsSlice.name]: eventsSlice.reducer,
    [settingsSlice.name]: settingsSlice.reducer,
    [attendeesSlice.name]: attendeesSlice.reducer,
    [campaignsSlice.name]: campaignsSlice.reducer,
    [campaignLogsSlice.name]: campaignLogsSlice.reducer,
    [accountSlice.name]: accountSlice.reducer,
    [siteSlice.name]: siteSlice.reducer,
});

export const store = configureStore({
    reducer: rootReducer,
    //devTools: process.env.NODE_ENV !== 'production',
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = {
    events: EventSliceState;
    campaigns: CampaignSliceState;
    campaignLogs: CampaignLogsSliceState;
    attendees: AttendeeSliceState;
    settings: SettingsSliceState;
    account: AccountSliceState;
    site: SubscriptionSliceState;
};
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, any, unknown, AnyAction>;
