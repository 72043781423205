import { FormField, Box, InputArea } from 'wix-style-react';

import { Controller } from 'react-hook-form';
import { MesssageKeysTable } from '../message-keys-table';
import { useRef } from 'react';
import { IntegrationNotConnected } from './integration-not-connected';
import { selectConnectedAccounts } from '../../../../features/account-slice';
import { useSelector } from 'react-redux';

export const TwitterMessage = ({ control, campaign = {}, trigger, id }) => {
    const messageRef = useRef();
    const { twitter } = campaign || {};
    if (id === undefined) {
        messageRef.current?.setValue(trigger.message);
    }
    const connectedAccounts = useSelector(selectConnectedAccounts);
    if (!connectedAccounts.hasOwnProperty('twitter')) {
        return <IntegrationNotConnected actionName="Twitter" />;
    }

    return (
        <>
            <Box marginTop={2} />
            <MesssageKeysTable />,
            <FormField label="Message" required>
                <Controller
                    control={control}
                    name="twitter.message"
                    render={({ field }) => (
                        <InputArea
                            defaultValue={twitter?.message}
                            minHeight="120px"
                            maxHeight="300px"
                            {...field}
                        />
                    )}
                />
            </FormField>
        </>
    );
};
