import { useState, useEffect, useCallback } from 'react';
import * as Icons from 'wix-ui-icons-common';
import {
    Box,
    Cell,
    Layout,
    VerticalTabs,
    Loader,
    Heading,
    Page,
    Thumbnail,
    Divider,
    InfoIcon,
    EmptyState,
    Image,
    SectionHelper,
    Text,
} from 'wix-style-react';
import {
    fetchSettingsApi,
    publishSettingsApi,
    selectSettings,
} from '../../features/settings-slice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Display } from '../settings/display';
import { Checkout } from '../settings/checkout';
import { Texts } from '../settings/text';
import { EventLayout } from '../settings/layout';
import { Filters } from '../settings/filters';
import { UserSearchFilters } from '../settings/search-filters';
import { Design } from '../settings/design';
import { Eventbrite } from '../settings/eventbrite';
import { useSelector } from 'react-redux';
import { selectSite } from '../../features/site-slice';
import WixIcon from '../../assets/wix.png';
import PublishButton from './publish-button';
import DesignToolImage from '../../assets/design-tool.svg';
import { classes } from './settings.st.css';

export const WidgetSettings = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const siteId = queryParams.get('site');
    const widgetId = queryParams.get('widget');

    return (
        <Layout>
            <Cell>
                <SidebarMenu site_id={siteId} widget_id={widgetId} />
            </Cell>
        </Layout>
    );
};

const getFragmentParams = (hash) => {
    const hashParts = hash.split('?');
    if (hashParts.length < 2) {
        return {};
    }
    const params = new URLSearchParams(hashParts[1]);
    return {
        selector: params.get('selector'),
    };
};

const SidebarMenu = ({ site_id, widget_id }) => {
    const [height, setHeight] = useState(0);
    const [widgetIsLoading, setWidgetIsLoading] = useState(true);
    const [loadingSettings, setLoadingSettings] = useState(true);
    const settings = useAppSelector(selectSettings);

    const [selected, setSelected] = useState('preview');

    const { selector } = getFragmentParams(location.hash);
    const [activeTab, setActiveTab] = useState(selector || 'display');
    const dispatch = useAppDispatch();

    const site = useSelector(selectSite);

    function onMsg(msg) {
        const { data, origin } = msg;

        if (!process.env.WIDGET_CLIENT_URL.startsWith(origin)) {
            return;
        }

        if (data.type === 'ticket-spot-set-height') {
            setHeight(data.value);
            setWidgetIsLoading(false);
        }
    }

    useEffect(() => {
        window.addEventListener('message', onMsg, false);
    }, []);
    useEffect(() => {
        async function fetchData() {
            setLoadingSettings(true);
            await dispatch(fetchSettingsApi());
            setLoadingSettings(false);
        }
        void fetchData();
    }, [dispatch]);

    const languageCode = (navigator.language || 'en').split('-')[0];

    const publishOnClick = useCallback(async () => {
        await dispatch(publishSettingsApi());
    }, [dispatch]);

    const tabs = [
        {
            name: 'Display',
            id: 'display',
            component: <Display />,
        },
        {
            name: 'Checkout',
            id: 'checkout',
            component: <Checkout />,
        },
        {
            name: 'Text',
            id: 'text',
            component: <Texts />,
        },
        {
            name: 'Layout',
            id: 'layout',
            component: <EventLayout />,
        },
        {
            name: 'Filters',
            id: 'filters',
            component: <Filters />,
        },
        {
            name: 'User Search Filters',
            id: 'user-filter',
            component: <UserSearchFilters />,
        },
        {
            name: 'Eventbrite',
            id: 'eventbrite',
            component: <Eventbrite />,
        },
        site?.type !== 'wix' && {
            name: 'Design',
            id: 'design',
            component: <Design />,
        },
    ].filter(Boolean);

    const previewFrameSrc =
        window.brand === 'wix'
            ? `${process.env.WIDGET_CLIENT_URL}${window.location.search}&brand=wix&deviceType=${
                  selected === 'mobile' ? 'mobile' : 'desktop'
              }`
            : `${process.env.WIDGET_CLIENT_URL}?site=${site_id}&widget=${widget_id}&viewMode=${
                  selected === 'preview' ? 'editor' : 'site'
              }&deviceType=${selected === 'mobile' ? 'mobile' : 'desktop'}&lang=${languageCode}`;
    return (
        <Box direction="vertical" paddingLeft="SP10" marginTop={4}>
            <Box marginBottom={5} align="space-between">
                <Box direction="vertical" marginTop={2} marginLeft={2}>
                    <Heading appearance="H3">Design Widget</Heading>
                    <Text>
                        Design and configure how your event list looks on your site. Use the preview
                        view and once happy with the changes publish to your live site
                    </Text>
                </Box>
                <Box marginRight={3} gap={3} height={'35px'}>
                    <Box>
                        <PublishButton
                            onPublish={publishOnClick}
                            hasUnpublishedChanges={settings.hasUnpublishedChanges}
                        />
                        {settings.hasUnpublishedChanges && (
                            <InfoIcon
                                size="small"
                                content="There are changes not published to the live site"
                            />
                        )}
                    </Box>
                </Box>
            </Box>

            <Page.Content>
                <Box direction="horizontal" gap={2}>
                    <Box width={350}>
                        <VerticalTabs
                            size="medium"
                            activeTabId={activeTab}
                            onChange={(id) => {
                                setActiveTab(id);
                            }}
                        >
                            {tabs.map((tab, index) => (
                                <VerticalTabs.TabItem
                                    key={index}
                                    id={tab.id}
                                    suffixIcon={<Icons.ChevronRight />}
                                >
                                    {tab.name}
                                </VerticalTabs.TabItem>
                            ))}
                        </VerticalTabs>
                    </Box>
                    {loadingSettings ? (
                        <Loader size="tiny" statusMessage="Loading Settings" />
                    ) : (
                        <Box width="500" overflow="auto" className={classes.settingContainer}>
                            {activeTab && tabs.find((a) => a.id === activeTab)?.component}
                        </Box>
                    )}
                    <Box width="100%" align="center" overflow="hidden" direction="vertical">
                        <Box width={700} marginBottom={3} gap={2} marginTop={2}>
                            <Thumbnail
                                title={
                                    <Box gap={2} align="center">
                                        <Icons.Preview />
                                        Preview
                                        <InfoIcon
                                            size="small"
                                            content="Safely explore and customize all configurations and features before publishing them live"
                                        />
                                    </Box>
                                }
                                selected={selected === 'preview'}
                                width={180}
                                onClick={() => setSelected('preview')}
                            />
                            <Thumbnail
                                title={
                                    <Box gap={2} align="center">
                                        <Icons.Mobile />
                                        Mobile
                                        <InfoIcon
                                            size="small"
                                            content="Mobile Live: See the actual live changes on mobile devices (availability of certain features may depend on your plan)"
                                        />
                                    </Box>
                                }
                                selected={selected === 'mobile'}
                                width={180}
                                onClick={() => setSelected('mobile')}
                            />
                            <Thumbnail
                                title={
                                    <Box gap={2} align="center">
                                        <Icons.Desktop />
                                        Live
                                        <InfoIcon
                                            size="small"
                                            content="Desktop Live: See the actual live changes on desktop devices (availability of certain features may depend on your plan)"
                                        />
                                    </Box>
                                }
                                selected={selected === 'live'}
                                width={180}
                                onClick={() => setSelected('live')}
                            />
                        </Box>

                        <Box width="100%">
                            <Divider />
                        </Box>
                        <Box width={'80%'} align="center" marginTop={3} marginBottom={2}>
                            {site?.type === 'wix' && (
                                <SectionHelper appearance="success" fullWidth>
                                    <Box
                                        gap={4}
                                        alignItems="center"
                                        justifyItems="center"
                                        className={classes.alignItems}
                                    >
                                        <img src={DesignToolImage} width={80} />
                                        <Text size="small">
                                            <b>
                                                Please note that the preview of the Widget shown
                                                below contains no pre-applied styling.
                                            </b>
                                            To customize the design and style of the Widget in the
                                            app, please use the Wix Editor. Once the app is
                                            installed on Wix, right-click on the App, select
                                            &apos;Settings,&apos; and then choose the
                                            &apos;Design&apos; tab to make your adjustments.
                                        </Text>
                                    </Box>
                                </SectionHelper>
                            )}
                        </Box>
                        {widgetIsLoading && (
                            <Box align="center" marginTop={5}>
                                <Loader size="medium" />
                            </Box>
                        )}
                        <div
                            id={widget_id as string}
                            style={{ overflow: 'none', height, width: '100%', textAlign: 'center' }}
                        >
                            <iframe
                                src={previewFrameSrc}
                                frameBorder="0"
                                scrolling="no"
                                width={selected === 'mobile' ? '30%' : '80%'}
                                height={'100%'}
                            />
                        </div>
                        {/* ) : (
                            <UseWixEditor />
                        )} */}
                    </Box>
                </Box>
            </Page.Content>
        </Box>
    );
};

const UseWixEditor = () => {
    return (
        <EmptyState
            theme="page"
            image={<Image width="120px" height="120px" src={WixIcon} transparent />}
            title="Go to the Wix Editor to View Your Changes and Publish Your Site"
        ></EmptyState>
    );
};
