import ctzc from 'country-tz-currency';
import moment from 'moment-timezone';
import ct from 'countries-and-timezones';

export const CURRENCIES = [
    {
        id: 'USD',
        value: '$ United States Dollar',
        convert: 1,
    },
    {
        id: 'GBP',
        value: '£ Great British Pound',
        convert: 0.66,
    },
    {
        id: 'EUR',
        value: '€ Euro',
        convert: 0.81,
    },
    {
        id: 'AUD',
        value: '$ Australian Dollar',
        convert: 1.31,
    },
    {
        id: 'CAD',
        value: '$ Canadian Dollar',
        convert: 1.31,
    },
];

export const getCurrency = () => {
    const timezone = ct.getCountryForTimezone(moment.tz.guess());
    if (timezone) {
        const currency = ctzc.getCurrencyByCountryCode(timezone.id);
        return currency?.code || 'USD';
    }
};

export const getAllCurrencies = () => {
    return [
        { id: 'USD', value: '$' },
        { id: 'EUR', value: '€' },
        { id: 'JPY', value: '¥' },
        { id: 'GBP', value: '£' },
        { id: 'AUD', value: 'AU$' },
        { id: 'CAD', value: 'CA$' },
        { id: 'HKD', value: 'HK$' },
        { id: 'NZD', value: 'NZ$' },
        { id: 'SGD', value: 'S$' },
        { id: 'CHF', value: 'CHF' },
        { id: 'SEK', value: 'Skr' },
        { id: 'NOK', value: 'Nkr' },
        { id: 'DKK', value: 'Dkr' },
        { id: 'PLN', value: 'zł' },
        { id: 'MXN', value: 'MX$' },
        { id: 'BRL', value: 'R$' },
        { id: 'MYR', value: 'RM' },
        { id: 'IDR', value: 'Rp' },
        { id: 'TWD', value: 'NT$' },
        { id: 'THB', value: '฿' },
        { id: 'PHP', value: '₱' },
        { id: 'TRY', value: '₺' },
        { id: 'AED', value: 'AED' },
        { id: 'INR', value: '₹' },
        { id: 'ZAR', value: 'R' },
        { id: 'SAR', value: 'SR' },
        { id: 'QAR', value: 'QR' },
        { id: 'KRW', value: '₩' },
        { id: 'AFN', value: 'Af' },
        { id: 'ALL', value: 'ALL' },
        { id: 'AMD', value: 'AMD' },
        { id: 'ARS', value: 'AR$' },
        { id: 'BAM', value: 'KM' },
        { id: 'BDT', value: 'Tk' },
        { id: 'BGN', value: 'BGN' },
        { id: 'BHD', value: 'BD' },
        { id: 'BIF', value: 'FBu' },
        { id: 'BND', value: 'BN$' },
        { id: 'BOB', value: 'Bs' },
        { id: 'CDF', value: 'CDF' },
        { id: 'CRC', value: '₡' },
        { id: 'CVE', value: 'CV$' },
        { id: 'CZK', value: 'Kč' },
        { id: 'DJF', value: 'Fdj' },
        { id: 'DOP', value: 'RD$' },
        { id: 'DZD', value: 'DA' },
        { id: 'EEK', value: 'Ekr' },
        { id: 'EGP', value: 'EGP' },
        { id: 'ERN', value: 'Nfk' },
        { id: 'ETB', value: 'Br' },
        { id: 'GEL', value: 'GEL' },
        { id: 'GHS', value: 'GH₵' },
        { id: 'GNF', value: 'FG' },
        { id: 'GTQ', value: 'GTQ' },
        { id: 'HNL', value: 'HNL' },
        { id: 'HRK', value: 'kn' },
        { id: 'HUF', value: 'Ft' },
        { id: 'ILS', value: '₪' },
        { id: 'IQD', value: 'IQD' },
        { id: 'IRR', value: 'IRR' },
        { id: 'ISK', value: 'Ikr' },
        { id: 'JMD', value: 'J$' },
        { id: 'JOD', value: 'JD' },
        { id: 'KES', value: 'Ksh' },
        { id: 'KHR', value: 'KHR' },
        { id: 'KMF', value: 'CF' },
        { id: 'KWD', value: 'KD' },
        { id: 'KZT', value: 'KZT' },
        { id: 'LBP', value: 'LB£' },
        { id: 'LKR', value: 'SLRs' },
        { id: 'LTL', value: 'Lt' },
        { id: 'LVL', value: 'Ls' },
        { id: 'LYD', value: 'LD' },
        { id: 'MAD', value: 'MAD' },
        { id: 'MDL', value: 'MDL' },
        { id: 'MGA', value: 'MGA' },
        { id: 'MKD', value: 'MKD' },
        { id: 'MMK', value: 'MMK' },
        { id: 'MOP', value: 'MOP$' },
        { id: 'MUR', value: 'MURs' },
        { id: 'NAD', value: 'N$' },
        { id: 'NGN', value: '₦' },
        { id: 'NIO', value: 'C$' },
        { id: 'NPR', value: 'NPRs' },
        { id: 'OMR', value: 'OMR' },
        { id: 'PAB', value: 'B/.' },
        { id: 'PEN', value: 'S/.' },
        { id: 'PKR', value: 'PKRs' },
        { id: 'PYG', value: '₲' },
        { id: 'RON', value: 'RON' },
        { id: 'RSD', value: 'din.' },
        { id: 'RUB', value: '₽' },
        { id: 'RWF', value: 'RWF' },
        { id: 'SDG', value: 'SDG' },
        { id: 'TND', value: 'DT' },
        { id: 'TOP', value: 'T$' },
        { id: 'TTD', value: 'TT$' },
        { id: 'UAH', value: '₴' },
        { id: 'UGX', value: 'USh' },
        { id: 'UYU', value: '$U' },
        { id: 'UZS', value: 'UZS' },
        { id: 'VEF', value: 'Bs.F.' },
        { id: 'VND', value: '₫' },
        { id: 'XAF', value: 'FCFA' },
        { id: 'XOF', value: 'CFA' },
        { id: 'YER', value: 'YR' },
        { id: 'ZMK', value: 'ZK' },
    ];
};
