import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Page,
    Table,
    TableToolbar,
    Search,
    Box,
    TableActionCell,
    Cell,
    Layout,
    Pagination,
} from 'wix-style-react';
import { selectSiteData } from '../../features/account-slice';
import {
    fetchCustomersApi,
    selectAttendeesIsLoading,
    selectAttendeesList,
} from '../../features/attendees-slice';
import { buildURL } from '../../features/utils';
import { useAppDispatch, useAppSelector, useDebounce } from '../../hooks';
import type { Attendee } from '../../types';

export const AttendeeList = () => {
    const [activeSearch, setActiveSearch] = useState('');
    const [selectedPage, setSelectedPage] = useState(1);
    const site = useAppSelector(selectSiteData);

    const history = useHistory();
    const _handleChange = ({ page, event }) => {
        event.preventDefault();
        setSelectedPage(page);
    };
    const primaryAction = (row: Attendee) => ({
        text: 'Edit',
        onClick: () => {
            history.push(buildURL(site, `/events/attendees/manage/${row.id}`));
        },
    });
    const columns = [
        { title: 'Email', width: '30%', render: (row: Attendee) => row.email },
        { title: 'First name', width: '20%', render: (row: Attendee) => row.first_name },
        { title: 'Last name', width: '20%', render: (row: Attendee) => row.last_name },
        { title: 'Company', width: '20%', render: (row: Attendee) => row.company },
        {
            width: '10%',
            render: (row: Attendee) => <TableActionCell primaryAction={primaryAction(row)} />,
        },
    ];

    const dispatch = useAppDispatch();

    const attendees = useSelector(selectAttendeesList) || [];
    const isLoading = useSelector(selectAttendeesIsLoading);

    const debouncedSearchTerm = useDebounce(attendees, 500);

    const selectedAttendees = useMemo(() => {
        return debouncedSearchTerm.filter(
            (attendee: Attendee) =>
                attendee?.email?.includes(activeSearch) ||
                attendee?.first_name?.includes(activeSearch) ||
                attendee?.last_name?.includes(activeSearch)
        );
    }, [debouncedSearchTerm, activeSearch]);
    useEffect(() => {
        dispatch(fetchCustomersApi(selectedPage - 1));
    }, [dispatch, selectedPage]);

    return (
        <Page>
            <Page.Header
                title="Customers"
                subtitle="View all your customers and manage their information"
                showBackButton
                onBackClicked={() => history.push(buildURL(site, `/events`))}
            />
            <Page.Content>
                <Layout>
                    <Cell>
                        <Table
                            data={selectedAttendees}
                            columns={columns}
                            showSelection
                            loader={isLoading}
                        >
                            <Table.ToolbarContainer>
                                {() => (
                                    <TableToolbar>
                                        <TableToolbar.ItemGroup position="end">
                                            <TableToolbar.Item>
                                                <Box width="500">
                                                    <Search
                                                        size="large"
                                                        value={activeSearch}
                                                        onChange={(event) =>
                                                            setActiveSearch(event.target.value)
                                                        }
                                                    />
                                                </Box>
                                            </TableToolbar.Item>
                                        </TableToolbar.ItemGroup>
                                    </TableToolbar>
                                )}
                            </Table.ToolbarContainer>
                            <Table.Content />
                        </Table>
                    </Cell>

                    <Cell>
                        <Pagination currentPage={selectedPage} onChange={_handleChange} />
                    </Cell>
                </Layout>
            </Page.Content>
        </Page>
    );
};
