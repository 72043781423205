import { NumberInput, ToggleSwitch } from 'wix-style-react';
import { SettingsHeader } from '../../../components/settings-header';
import { WidgetSettingsUpdater } from '../../../components/widget-settings-updater';
import { selectSettings } from '../../../features/settings-slice';
import { useAppSelector } from '../../../hooks';

export const RecurringEvents = ({ label }) => {
    const settings = useAppSelector(selectSettings);

    return (
        <>
            <SettingsHeader title={label} />

            {!settings.multiDayDateSelector && (
                <WidgetSettingsUpdater
                    updateKey="expandRecurringEvents"
                    title="Display All Events"
                    shouldFetchEvents
                    resizeWidget
                    tooltipText="Toggle this switch to choose between displaying only the next upcoming occurrence of each event, or all scheduled occurrences."
                >
                    <ToggleSwitch />
                </WidgetSettingsUpdater>
            )}
            {!settings.expandRecurringEvents && (
                <WidgetSettingsUpdater
                    updateKey="multiDayDateSelector"
                    title="Enable Date Grouping"
                    shouldFetchEvents
                    resizeWidget
                    tooltipText="Enable this option to group recurring events under a date selector. This allows attendees to easily select and view events based on their preferred dates. You can choose different display layouts from the Layouts section to customize how dates are presented to users."
                >
                    <ToggleSwitch />
                </WidgetSettingsUpdater>
            )}
            {!settings.expandRecurringEvents && !settings.multiDayDateSelector && (
                <WidgetSettingsUpdater
                    updateKey="eventsPerSeries"
                    title="Events per Series"
                    shouldFetchEvents
                    resizeWidget
                    tooltipText="Set the number of events to display per recurring series"
                >
                    <NumberInput />
                </WidgetSettingsUpdater>
            )}
        </>
    );
};
