import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Checkbox, Dropdown, Heading, InfoIcon, Popover } from 'wix-style-react';
import { classes } from './create-events.st.css';
import { API_INSTANCE, buildURL } from '../../features/utils';
import { useSelector } from 'react-redux';
import { selectSiteData } from '../../features/account-slice';
import PublishIcon from '../../assets/publish-celebration.svg';

export const PublishShopifyButton = ({ handleSubmit, event }) => {
    const [shown, setShown] = useState<boolean>();
    const [collections, setCollections] = useState<any>([]);
    const [savedCollections, setSavedCollections] = useState<any>([]);
    const [locations, setLocations] = useState<any>([]);
    const [savedLocation, setSavedLocation] = useState<any>(null);

    const site = useSelector(selectSiteData);

    useEffect(() => {
        const fetchCurrentCollectionsData = async () => {
            const url = `/shopify/collections/events/${event?.id}`;
            const { data } = await API_INSTANCE.get(buildURL(site, url));
            setCollections(data);
            setSavedCollections(data.filter(({ enabled }) => enabled));
        };
        void fetchCurrentCollectionsData();
    }, [event?.id, site]);

    useEffect(() => {
        const fetchCurrentLocationsData = async () => {
            const url = `/shopify/locations/events/${event?.id}`;
            const { data } = await API_INSTANCE.get(buildURL(site, url));
            setLocations(data);
            setSavedLocation(data?.find(({ enabled }) => enabled)?.id);
        };
        void fetchCurrentLocationsData();
    }, [event?.id, site]);

    const handleChange = useCallback(
        async (collection: any, newValue: boolean) => {
            if (newValue) {
                setSavedCollections(savedCollections.concat(collection));
            } else {
                setSavedCollections(savedCollections.filter(({ id }) => id !== collection.id));
            }
        },
        [savedCollections]
    );

    return (
        <Popover showArrow shown={shown} appendTo="window" placement={'bottom'}>
            <Popover.Element>
                <Button
                    onClick={() => setShown(true)}
                    disabled={event && event.type !== 'eventviewer'}
                >
                    Publish to Shopify{' '}
                    <Box marginLeft={1} inline>
                        {' '}
                        <InfoIcon
                            size="small"
                            className={classes.info}
                            content="Publish Event as a Shopify Product"
                        />
                    </Box>
                </Button>
            </Popover.Element>
            <Popover.Content>
                <Box padding={4} direction="vertical">
                    <Box align="left" width={'100%'} direction="vertical">
                        <Heading appearance="H5">
                            Select Collections{' '}
                            <InfoIcon
                                size="small"
                                content="If you've set up manual collections in Shopify, the product will automatically be added to the relevant collection."
                            />
                        </Heading>
                        <Box direction="vertical" marginTop={2} marginBottom={2} align="left">
                            {collections.map((collection, index: number) => {
                                return (
                                    <Checkbox
                                        key={index}
                                        checked={
                                            !!savedCollections.find(({ id }) => {
                                                return collection.id === id;
                                            })
                                        }
                                        onChange={(e) => handleChange(collection, e.target.checked)}
                                    >
                                        {collection.title}
                                    </Checkbox>
                                );
                            })}
                        </Box>
                    </Box>
                    <Box direction="vertical" marginTop={2} marginBottom={2}>
                        <Heading appearance="H5">
                            Select Location{' '}
                            <InfoIcon
                                size="small"
                                content="Choose a location to automatically mark products as fulfilled. Orders without a selected location will remain unfulfilled."
                            />
                        </Heading>
                        <Dropdown
                            border="standard"
                            clearButton
                            selectedId={savedLocation}
                            options={locations.map((location) => ({
                                id: location.id,
                                value: location.name,
                            }))}
                            onSelect={(option) => setSavedLocation(option.id)}
                            onClear={() => setSavedLocation(null)}
                        />
                    </Box>
                    <Button
                        prefixIcon={<img src={PublishIcon} width={30} />}
                        className={classes.publishButton}
                        onClick={async () => {
                            setShown(false);
                            await handleSubmit(event.status, 'shopify', {
                                collections: savedCollections,
                                location_id: savedLocation,
                            });
                        }}
                    >
                        Publish
                    </Button>
                </Box>
            </Popover.Content>
        </Popover>
    );
};
