import { useState } from 'react';
import { Box, Dropdown, FormField, InfoIcon, listItemSectionBuilder } from 'wix-style-react';
import { Copy } from '../../events/components/copy';
import { classes } from './message-keys-table.st.css';

export const MesssageKeysTable = ({ appendTo }) => {
    const records = [
        // Event Details
        listItemSectionBuilder({
            id: 0,
            type: 'title',
            title: 'Event Details',
        }),
        { value: 'Event Title', id: '{{event_title}}' },
        { value: 'Event Summary', id: '{{event_summary}}' },
        { value: 'Event Start Date', id: '{{event_start_date}}' },
        { value: 'Event Start Time', id: '{{event_start_time}}' },
        { value: 'Event End Date', id: '{{event_end_date}}' },
        { value: 'Event End Time', id: '{{event_end_time}}' },
        { value: 'Event Timezone', id: '{{event_timezone}}' },
        { value: 'Venue name', id: '{{event_venue_name}}' },
        { value: 'Hosted Event Page URL', id: '{{event_page_url}}' },

        listItemSectionBuilder({
            id: 1,
            type: 'title',
            title: 'Ticket Details',
        }),
        // Ticket Details
        { value: 'Ticket Name', id: '{{ticket_name}}' },
        { value: 'Ticket Price', id: '{{ticket_price}}' },
        { value: 'Ticket Sales Tax', id: '{{sales_tax}}' },
        { value: 'Total Ticket Price', id: '{{attendee_ticket_price}}' },
        { value: 'Ticket Passes Link', id: '{{ticket_passes_link}}' },
        { value: 'Currency Symbol', id: '{{currency_symbol}}' },
        { value: 'Order ID', id: '{{order_id}}' },
        { value: 'Order Quantity', id: '{{order_quantity}}' },
        { value: 'Order Total Price', id: '{{order_total_price}}' },
        { value: 'Order Summary', id: '{{event_order_summary}}' },

        // Attendee Information
        listItemSectionBuilder({
            id: 2,
            type: 'title',
            title: 'Attendee Information',
        }),
        { value: 'Attendee First Name', id: '{{attendee_first_name}}' },
        { value: 'Attendee Last Name', id: '{{attendee_last_name}}' },
        { value: 'Attendee Email', id: '{{attendee_email}}' },
        { value: 'Attendee Company', id: '{{attendee_company}}' },
        { value: 'Attendee Phone', id: '{{attendee_phone}}' },
        { value: 'Attendee Qrcode', id: '{{attendee_qr_code}}' },
        { value: 'Attendee Order History', id: '{{attendee_order_history}}' },
        { value: 'Attendee Notes', id: '{{attendee_notes}}' },

        listItemSectionBuilder({
            id: 3,
            type: 'title',
            title: 'Zoom Event Links',
        }),
        { value: 'Zoom Meeting Join Url', id: '{{event_meeting_url}}' },
        { value: 'Zoom Next Occurrence Meeting Url', id: '{{next_occurrence_meeting_url}}' },

        // Miscellaneous Information
        listItemSectionBuilder({
            id: 4,
            type: 'title',
            title: 'Next Occurrence Details',
        }),
        { value: 'Next Occurrence Start Date', id: '{{next_occurrence_start_date}}' },
        { value: 'Next Occurrence Start Time', id: '{{next_occurrence_start_time}}' },
        { value: 'Next Occurrence End Date', id: '{{next_occurrence_end_date}}' },
        { value: 'Next Occurrence End Time', id: '{{next_occurrence_end_time}}' },
    ];

    const [item, setItem] = useState(records[1].id);

    return (
        <Box direction="vertical" gap={2} marginBottom={2} marginTop={2}>
            <div className={classes.heading}>
                Customize your message using personalization tokens{' '}
                <InfoIcon content="Elevate your communication using tokens like {{attendee_first_name}}, {{attendee_email}}, {{event_title}}, and more. Each token acts as a placeholder that automatically resolves to its corresponding value, guaranteeing personalized, engaging, and relevant messages for your recipients." />
            </div>
            <Box direction="horizontal" gap={2}>
                <Box width={'70%'} className={classes.fullWidth}>
                    <FormField label="Property" stretchContent={false}>
                        <Dropdown
                            size="large"
                            options={records}
                            onSelect={(option) => setItem(option.id)}
                            selectedId={item}
                            popoverProps={{
                                appendTo,
                                zIndex: 9999,
                            }}
                        />
                    </FormField>
                </Box>

                <Box width={'30%'}>
                    <FormField label="Token">
                        <Copy text={item} />
                    </FormField>
                </Box>
            </Box>
        </Box>
    );
};
