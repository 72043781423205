import { SettingsHeader } from '../../../../components/settings-header';
import { ColorPicker } from '../color-picker';
import { FontColorPicker } from '../font-picker';

export const ButtonDesign = ({ label }) => {
    return (
        <div>
            <SettingsHeader title={label} />

            <FontColorPicker
                title="Text"
                startWithSize={14}
                fontUpdateKey="_buttonFont"
                colorUpdateKey="btnForeground"
                startWithTheme={'font_6'}
                startWithColor="color-5"
            />
            <hr className="divider-long" />

            <ColorPicker
                title="Text on hover"
                colorUpdateKey="btnHoverForeground"
                startWithColor="color-5"
            />
            <hr className="divider-long" />
            <ColorPicker
                title="Background"
                colorUpdateKey="btnBackground"
                startWithColor="color-6"
                startWithOpacity={1}
            />
            <hr className="divider-long" />
            <ColorPicker
                title="Background on hover"
                colorUpdateKey="btnHoverBackground"
                startWithColor="color-6"
                startWithOpacity={1}
            />
            <hr className="divider-long" />

            <FontColorPicker
                title="Show more text"
                startWithSize={12}
                fontUpdateKey="_showMoreBtnFont"
                colorUpdateKey="_showMoreBtnForeground"
                startWithTheme="font_7"
                startWithColor="color-5"
            />
            <hr className="divider-long" />

            <ColorPicker
                title="Show more/less text background"
                colorUpdateKey="_showMoreBtnBackground"
                startWithColor="color-6"
                startWithOpacity={1}
            />

            <hr className="divider-long" />

            <FontColorPicker
                title="Load more text"
                startWithSize={14}
                fontUpdateKey="_buttonLoadMoreFont"
                colorUpdateKey="btnLoadMoreForeground"
                startWithTheme={'font_6'}
                startWithColor="color-5"
            />
            <hr className="divider-long" />

            <ColorPicker
                title="Load more text on hover"
                colorUpdateKey="btnLoadMoreHoverForeground"
                startWithColor="color-5"
            />
            <hr className="divider-long" />
            <ColorPicker
                title="Load more background"
                colorUpdateKey="btnLoadMoreBackground"
                startWithColor="color-6"
                startWithOpacity={1}
            />
            <hr className="divider-long" />
            <ColorPicker
                title="Load more background hover"
                colorUpdateKey="btnLoadMoreHoverBackground"
                startWithColor="color-6"
                startWithOpacity={1}
            />
        </div>
    );
};
