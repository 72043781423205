import { capitalize, isEmpty, uniq } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as Icons from 'wix-ui-icons-common';
import { classes } from './wix-widgets.st.css';
import EmptyWidgets from '../../assets/empty-widgets.svg';
import PageSelectorImage from '../../assets/widget/page-selection.svg';

import {
    Divider,
    Badge,
    Box,
    Breadcrumbs,
    Button,
    Card,
    Cell,
    Checkbox,
    CustomModalLayout,
    Dropdown,
    FormField,
    Heading,
    IconButton,
    Input,
    Layout,
    Modal,
    Page,
    Text,
    ToggleSwitch,
    Table,
    SectionHelper,
    RadioGroup,
    EmptyState,
    Image,
    Loader,
    Radio,
} from 'wix-style-react';
import AddSmall from 'wix-ui-icons-common/AddSmall';

import { API_INSTANCE } from '../../features/utils';
import { useAppDispatch } from '../../hooks';
import { Copy } from '../events/components/copy';
import {
    createWidgetsApi,
    deleteWidgetsApi,
    fetchSite,
    fetchWidgetsApi,
    selectSite,
    selectWidgets,
    updateWidgetsApi,
} from '../../features/site-slice';
import { useSelector } from 'react-redux';
import { DeleteConfirmation } from '../../components/delete-confirmation';
import EventManagementIcon from '../../assets/event-management.svg';

const ShopifyVintageOnlineStoreInstallation = ({ site_id, widget }) => {
    const [themes, setThemes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState(widget?.metadata?.theme);

    const dispatch = useAppDispatch();
    useEffect(() => {
        const fetchData = async () => {
            const themeApi = `/shopify/themes?site=${site_id}`;
            const { data } = await API_INSTANCE.get(themeApi);
            setThemes(data);
            setLoading(false);
        };
        void fetchData();
    }, [site_id]);
    const handleSelect = useCallback(
        async (id) => {
            dispatch(updateWidgetsApi({ site_id, ...widget, metadata: { theme: id } }));
            setValue(id);
        },
        [site_id, widget]
    );
    return (
        <FormField label="Select Theme">
            <Dropdown
                selectedId={value}
                size="medium"
                options={themes.map(({ id, name }) => ({ id, value: name }))}
                status={loading ? 'loading' : null}
                onSelect={(option) => handleSelect(option.id)}
            />
        </FormField>
    );
};
const PageSelector = ({ site_id, pages = [], widget }) => {
    const [pagesState, setPagesState] = useState<boolean>(widget.pages || []);
    const site = widget.site;
    const dispatch = useAppDispatch();
    const handleCheck = useCallback(
        async (item, checked) => {
            const uniqPages = uniq(pagesState.map((p) => p).concat(item.id));
            const newPages = checked ? uniqPages : uniqPages.filter((p) => item.id !== p);
            setPagesState(newPages);
            dispatch(
                updateWidgetsApi({ id: widget.id, site_id, pages: newPages.filter((p) => p) })
            );
        },
        [pagesState]
    );

    const containsShopifyTheme = site?.type === 'shopify' && widget?.metadata?.theme;
    return (
        <Box direction="vertical" marginTop={3}>
            {site?.type === 'shopify' && (
                <Box marginBottom={2} marginTop={2}>
                    <ShopifyVintageOnlineStoreInstallation site_id={site_id} widget={widget} />
                </Box>
            )}
            {(containsShopifyTheme || site?.type !== 'shopify') && (
                <Layout>
                    <Cell>
                        <FormField>
                            <Box direction="vertical" paddingLeft={7}>
                                {pages.map((page, i) => {
                                    return (
                                        <Checkbox
                                            key={i}
                                            id={page.id}
                                            checked={pagesState.includes(page.id)}
                                            onChange={(e) => {
                                                handleCheck(page, e.target.checked);
                                            }}
                                        >
                                            {page.label}
                                        </Checkbox>
                                    );
                                })}
                            </Box>
                        </FormField>
                    </Cell>
                </Layout>
            )}
        </Box>
    );
};
const WidgetEnable = ({ widget, site_id }) => {
    const [enabled, setEnabled] = useState<boolean>(widget.enabled);
    const dispatch = useAppDispatch();
    const handleClick = useCallback(async (checked) => {
        dispatch(updateWidgetsApi({ id: widget.id, site_id, enabled: checked }));
        setEnabled(checked);
    }, []);

    return (
        <Box marginBottom={3}>
            <FormField
                infoContent="Activate your widget and make it visible for your visitors"
                infoTooltipProps={{ placement: 'right' }}
            >
                <ToggleSwitch
                    checked={enabled}
                    onChange={(e) => {
                        handleClick(e.target.checked);
                    }}
                />
            </FormField>
        </Box>
    );
};

const WidgetInShopifyPageInstructions = ({ widget, site_id, themes, store }) => {
    const columns = [
        { title: 'Theme', render: (row) => row.name },
        {
            title: '',
            render: (row) => (
                <a
                    target="_blank"
                    href={`https://${store.store_domain}/admin/themes/${row.id}/editor`}
                >
                    <Icons.ExternalLink />
                </a>
            ),
        },
    ];

    return (
        <Box direction="vertical" width="100%">
            <Box width={250} align="center" marginTop={3}>
                <Button
                    className={classes.learnButton}
                    as={'a'}
                    href={`https://youtu.be/O8m2xwucNQ4`}
                    target="_blank"
                    prefixIcon={<Icons.HelpCircle />}
                >
                    Watch Video Instruction
                </Button>
            </Box>
            <Box marginTop={2} direction="vertical">
                <Text>Copy the Widget ID below and paste it into the Code Block settings.</Text>
                <Box marginTop={2} />
                <Copy text={widget.id} isTextArea={false} />
            </Box>
            <Box marginTop={5} direction="vertical">
                <Box marginBottom={3}>
                    <Text>Choose a Theme to Install the Event Widget</Text>
                </Box>

                <Table skin="standard" data={themes} columns={columns}>
                    <Table.Content />
                </Table>
            </Box>
        </Box>
    );
};

const InstructionsCard = ({ title, subtitle, badgeTitle }) => {
    const [hovered, setHovered] = useState(false);
    return (
        <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <Card showShadow={hovered}>
                <Layout gap={0}>
                    <Cell span={12}>
                        <Box height="18px" marginTop={2} marginLeft={-1}>
                            {badgeTitle && (
                                <Badge size="small" skin="success">
                                    {badgeTitle}
                                </Badge>
                            )}
                        </Box>
                    </Cell>
                    <Cell>
                        <Box padding="12px 24px 0 24px">
                            <Heading appearance="H4">{title}</Heading>
                        </Box>
                    </Cell>
                    <Cell>
                        <Box padding="24px">
                            <Text size="small" secondary>
                                {subtitle}
                            </Text>
                        </Box>
                    </Cell>
                </Layout>
            </Card>
        </div>
    );
};

const EcwidInstructions = ({ siteId, pages, widget }) => {
    const [checkedId, setCheckedId] = useState(2);

    return (
        <Box direction="vertical">
            <Box direction="vertical" align="center" gap={3}>
                <Heading appearance="H3">Getting Started on Ecwid</Heading>
                <Heading appearance="H4">
                    To make the most of Ticket Spot, you have two convenient installation options:
                </Heading>
            </Box>
            <Layout cols={1} gap="5px">
                <Card>
                    <Box padding="18px 24px">
                        <Radio
                            checked={checkedId === 2}
                            onChange={() => setCheckedId(2)}
                            label={
                                <Box direction="vertical">
                                    <Heading appearance="H4">Ecwid Product Integration</Heading>
                                    <Text size="medium" secondary>
                                        Create your event in TicketSpot and publish it directly to
                                        Ecwid as a Product. Set up automated engagement tools in
                                        TicketSpot to send emails, SMS messages, or social media
                                        posts for each new ticket order processed through Ecwid.
                                    </Text>
                                </Box>
                            }
                        />
                    </Box>
                </Card>
                <Card>
                    <Box padding="18px 24px">
                        <Radio
                            checked={checkedId === 1}
                            onChange={() => setCheckedId(1)}
                            label={
                                <Box direction="vertical">
                                    <Heading appearance="H4">Widget Integration</Heading>
                                    <Text size="small" secondary>
                                        Drop in the Ticket Spot Widget to any page on your Ecwid
                                        Store. Your customers can purchase tickets through the
                                        TicketSpot checkout. Fully Customize it to match your store
                                        design.
                                    </Text>
                                </Box>
                            }
                        />
                    </Box>
                </Card>
            </Layout>
            <Box>
                {checkedId === 1 && (
                    <Box direction="vertical">
                        <Heading appearance="H4">
                            <Box verticalAlign="middle" gap={2}>
                                <img src={PageSelectorImage} width={30} /> Choose Pages for Widget
                                Installation
                            </Box>
                        </Heading>
                        <PageSelector site_id={siteId} pages={pages} widget={widget} />
                        <Box direction="vertical" gap={2} marginTop={3}>
                            <Text>Position</Text>
                            <PositionSelector widget={widget} site_id={siteId} />
                        </Box>
                    </Box>
                )}

                {checkedId === 2 && (
                    <Box direction="vertical" gap={2}>
                        <Box width={250} align="center" marginTop={3}>
                            <Button
                                className={classes.learnButton}
                                as={'a'}
                                href={`https://learn.ticketspot.io/videos/BKbn16HLIa0`}
                                target="_blank"
                                prefixIcon={<Icons.HelpCircle />}
                            >
                                Watch Instruction Video
                            </Button>
                        </Box>
                        <Box direction="horizontal" gap={4} marginTop={3}>
                            <InstructionsCard
                                title={'Create Your Events'}
                                subtitle={
                                    'Begin by crafting your events from the Ticket Spot Dashboard. Add all the necessary details, such as event dates, ticket types, and pricing.'
                                }
                                badgeTitle={'First'}
                            />
                            <InstructionsCard
                                title={'Publish to Ecwid'}
                                subtitle={`With your events configured in Ticket Spot, the next step is as simple as clicking "Publish to Ecwid." This action seamlessly transfers your event listings to your Ecwid store.`}
                                badgeTitle={'Second'}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

const ShopifyInstructions = ({ store, themes, site_id, widget }) => {
    const [checkedId, setCheckedId] = useState(2);

    return (
        <Box direction="vertical">
            <Box direction="vertical" align="center" marginTop={4} marginBottom={3}>
                <Heading appearance="H3">Getting Started on Shopify</Heading>
                <Heading appearance="H5">
                    To make the most of Ticket Spot, you have two convenient installation options:
                </Heading>
            </Box>
            <Divider />
            <Layout cols={1} gap="5px">
                <Card>
                    <Box padding="18px 24px">
                        <Radio
                            checked={checkedId === 2}
                            onChange={() => setCheckedId(2)}
                            label={
                                <Box direction="vertical">
                                    <Heading appearance="H4">Shopify Product Integration</Heading>
                                    <Text size="medium" secondary>
                                        Create your event in TicketSpot and publish it directly to
                                        Shopify as a Product. Set up automated engagement tools in
                                        TicketSpot to send emails, SMS messages, or social media
                                        posts for each new ticket order processed through Shopify.
                                    </Text>
                                </Box>
                            }
                        />
                    </Box>
                </Card>
                <Card>
                    <Box padding="18px 24px">
                        <Radio
                            checked={checkedId === 1}
                            onChange={() => setCheckedId(1)}
                            label={
                                <Box direction="vertical">
                                    <Heading appearance="H4">Widget Integration</Heading>
                                    <Text size="medium" secondary>
                                        Drop in the Ticket Spot Widget to any page on your Shopify
                                        Store. Your customers can purchase tickets through the
                                        TicketSpot checkout. Fully Customize it to match your store
                                        design.
                                    </Text>
                                </Box>
                            }
                        />
                    </Box>
                </Card>
            </Layout>
            <Divider />
            <Box>
                {checkedId === 1 && (
                    <Box direction="vertical">
                        <WidgetInShopifyPageInstructions
                            widget={widget}
                            site_id={site_id}
                            themes={themes}
                            store={store}
                        />
                    </Box>
                )}

                {checkedId === 2 && (
                    <Box direction="vertical" gap={4} marginTop={3}>
                        <Box direction="vertical" gap={2}>
                            <Box width={250} align="center" marginTop={3}>
                                <Button
                                    className={classes.learnButton}
                                    as={'a'}
                                    href={`https://learn.ticketspot.io/videos/JfbkAyc9k9g`}
                                    target="_blank"
                                    prefixIcon={<Icons.HelpCircle />}
                                >
                                    Watch Instruction Video
                                </Button>
                            </Box>
                            <Box direction="horizontal" gap={4} marginTop={3}>
                                <InstructionsCard
                                    title={'Create Your Events'}
                                    subtitle={
                                        'Begin by crafting your events from the Ticket Spot Dashboard. Add all the necessary details, such as event dates, ticket types, and pricing.'
                                    }
                                    badgeTitle={'First'}
                                />
                                <InstructionsCard
                                    title={'Publish to Shopify'}
                                    subtitle={`With your events configured in Ticket Spot, the next step is as simple as clicking "Publish to Shopify." This action seamlessly transfers your event listings to your Shopify store.`}
                                    badgeTitle={'Second'}
                                />
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

const PositionSelector = ({ widget, site_id }) => {
    const dispatch = useAppDispatch();
    const positions = ['top', 'bottom'];
    const [position, setPosition] = useState(widget.position || 'bottom');
    const handlePositionChange = useCallback(async (newPosition) => {
        setPosition(newPosition);
        dispatch(updateWidgetsApi({ id: widget.id, site_id, position: newPosition }));
    }, []);

    return (
        <RadioGroup
            spacing="6px"
            onChange={(e) => {
                handlePositionChange(e);
            }}
            value={position}
        >
            {positions.map((pos, key) => {
                return (
                    <RadioGroup.Radio value={pos} key={key}>
                        <Box direction="vertical">
                            <Text size="small">{capitalize(pos)}</Text>
                        </Box>
                    </RadioGroup.Radio>
                );
            })}
        </RadioGroup>
    );
};
const InstallWithAutomation = ({ site_id, pages, widget }) => {
    const [themes, setThemes] = useState([]);
    const [store, setStore] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const themeApi = `/shopify/themes?site=${site_id}`;
            const themeResult = await API_INSTANCE.get(themeApi);
            const storeApi = `/shopify?site=${site_id}`;
            const storeResult = await API_INSTANCE.get(storeApi);
            setThemes(themeResult.data);
            setStore(storeResult.data);
        };
        if (widget.site.type === 'shopify') {
            void fetchData();
        }
    }, [site_id]);

    return (
        <Box marginBottom={10} direction="vertical">
            <Box height={20} />
            <Layout>
                {widget.site.type === 'shopify' ? (
                    <Cell span={12}>
                        <ShopifyInstructions
                            store={store}
                            themes={themes}
                            site_id={site_id}
                            widget={widget}
                        />
                    </Cell>
                ) : (
                    <Cell span={12}>
                        <EcwidInstructions siteId={site_id} pages={pages} widget={widget} />
                    </Cell>
                )}
            </Layout>
        </Box>
    );
};

export const SiteInstall = ({ widget }) => {
    if (widget?.site?.type === 'wordpress') {
        const shortCodeSnippet = `[ticket_spot widget_id='${widget.id}']`;
        return (
            <Box marginBottom={10} direction="vertical">
                <SectionHelper appearance="standard">
                    To add the shortcode to your WordPress posts and pages, simply copy and paste
                    the provided shortcode below. Alternatively, you can utilize the &quot;Shortcode
                    Widget&quot; by navigating to the &quot;Appearance » Widgets&quot; screen in
                    your dashboard.
                </SectionHelper>
                <Box marginTop={2} />
                <Copy text={shortCodeSnippet} isTextArea={false} />
            </Box>
        );
    }
    if (widget?.site?.type === 'wix') {
        return (
            <Box marginBottom={10} direction="vertical">
                <Box marginBottom={3} marginTop={4} align="center">
                    <Heading appearance="H3">Getting Started on Wix</Heading>
                </Box>
                <Layout>
                    <Cell>
                        <Divider />
                    </Cell>
                    <Cell>
                        <Text>
                            1. Install the Ticket Spot app from the{' '}
                            <a
                                href="https://www.wix.com/app-market/event-viewer"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Wix App Market
                            </a>
                            . This will add a widget to your site.
                        </Text>
                        <Box marginTop={2} />
                        <Text>
                            2. Use the widget to add events, manage attendees, and check in
                            attendees.
                        </Text>
                        <Box marginTop={2} />
                        <Text>
                            For a detailed guide, watch this{' '}
                            <a
                                href="https://learn.ticketspot.io/videos/V35yhc_vVnI"
                                target="_blank"
                                rel="noreferrer"
                            >
                                installation and usage video
                            </a>
                            .
                        </Text>
                    </Cell>
                </Layout>
            </Box>
        );
    }
    const codeSnippet = `<ticket-spot id='${widget.id}' />`;
    const headSnippet = `<script type="text/javascript" \n              src="https://api.ticketspot.io/script">\n</script>`;

    return (
        <Box marginBottom={10} direction="vertical">
            <Box marginBottom={3} marginTop={4}>
                <Heading appearance="H3">
                    Add Ticket Spot to your site with just a few lines of code.
                </Heading>
            </Box>
            <Layout>
                <Cell>
                    <Text>
                        1. Include the Ticket Spot library by adding it to the HTML HEAD tag of your
                        website.
                    </Text>
                    <Box marginTop={2} />
                    <Copy text={headSnippet} isTextArea />
                </Cell>
                <Cell>
                    <Text>
                        2. Place the following code wherever you wish your widget to appear on your
                        page.
                    </Text>
                    <Box marginTop={2} />
                    <Copy text={codeSnippet} />
                </Cell>
            </Layout>
        </Box>
    );
};

export const InstallationModel = ({ onClose, widget, isModalOpened, site_id }) => {
    const codeSnippet = `<ticket-spot id='${widget.id}' />`;
    const [pages, setPages] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const availablePages = `/api/v1/dashboard/sites/${site_id}/available-pages`;
            const { data } = await API_INSTANCE.get(availablePages);
            setPages(data);
        };
        void fetchData();
    }, [site_id]);

    return (
        <Modal isOpen={isModalOpened} onRequestClose={onClose}>
            <CustomModalLayout onCloseButtonClick={() => onClose()} width="40%">
                {pages.length > 0 ? (
                    <InstallWithAutomation
                        site_id={site_id}
                        pages={pages}
                        widget={widget}
                        codeSnippet={codeSnippet}
                    />
                ) : (
                    <SiteInstall widget={widget} />
                )}
            </CustomModalLayout>
        </Modal>
    );
};
const AddWidget = ({ site, addWidget }) => {
    const [isModalOpened, setModalOpened] = useState(false);
    const [name, setName] = useState('');

    const openModal = () => setModalOpened(true);
    const closeModal = () => setModalOpened(false);

    const handleClick = useCallback(async () => {
        await addWidget({ name });
        closeModal();
    }, [name]);

    return (
        <>
            {site?.roles?.some((r) => ['OWNER', 'ADMIN'].includes(r)) && (
                <Button onClick={openModal} prefixIcon={<AddSmall />}>
                    Add Widget
                </Button>
            )}

            <Modal isOpen={isModalOpened} onRequestClose={closeModal}>
                <CustomModalLayout
                    primaryButtonText="Save"
                    primaryButtonOnClick={handleClick}
                    primaryButtonProps={{
                        disabled: isEmpty(name),
                    }}
                    onCloseButtonClick={() => closeModal()}
                    title="Create a new widget"
                    width={300}
                >
                    <SectionHelper appearance="success">
                        Ticket Spot widgets are customizable components that display events on
                        websites or online stores, and you can have multiple widgets per site.
                    </SectionHelper>
                    <Box marginTop={2} />
                    <FormField label="Widget name">
                        <Input
                            placeholder="My Event Widget"
                            onChange={(e) => setName(e.target.value)}
                        />
                    </FormField>
                </CustomModalLayout>
            </Modal>
        </>
    );
};

export const Widgets = () => {
    const { site_id } = useParams<{ site_id: string }>();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    const widgets = useSelector(selectWidgets) || [];

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchWidgetsApi(site_id));
            setLoading(false);
        };
        void fetchData();
    }, [site_id]);

    const site = useSelector(selectSite);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchSite(site_id));
        };
        void fetchData();
    }, [dispatch, site_id]);

    const handleDelete = useCallback(async (id: string) => {
        await dispatch(deleteWidgetsApi({ site_id, id }));
    }, []);
    const addWidget = useCallback(async (newWidget: string) => {
        await dispatch(createWidgetsApi({ site_id, ...newWidget }));
    }, []);

    return (
        <Box direction="vertical" padding="SP10">
            <Box>
                <Breadcrumbs
                    activeId="2"
                    items={[
                        { id: '1', value: 'Sites' },
                        { id: '2', value: `"${site?.site_display_name}" Widgets` },
                    ]}
                    onClick={({ id }) => {
                        if (id === '1') {
                            history.push(`/sites`);
                        }
                    }}
                />
            </Box>
            <Box direction="horizontal" marginBottom={10} align="space-between">
                <Box direction="vertical">
                    <Heading appearance="H1">Customizable Event Widgets</Heading>
                    <Heading appearance="H4">
                        Create and Manage Widgets with Unique Events and Design, Engage Attendees
                        with Automation, and Promote Your Events Effortlessly
                    </Heading>
                </Box>
                {widgets.length !== 0 && (
                    <Box>
                        <AddWidget site={site} addWidget={addWidget} />
                    </Box>
                )}
            </Box>

            <Page.Content>
                {loading && <Loader size="small" />}
                {!loading && widgets.length === 0 ? (
                    <EmptyState
                        theme="page"
                        image={
                            <Image width="120px" height="120px" src={EmptyWidgets} transparent />
                        }
                        title="Add your first widget"
                        subtitle="Create and display captivating events on your website using our widget."
                    >
                        {<AddWidget site={site} addWidget={addWidget} />}
                    </EmptyState>
                ) : (
                    <Layout>
                        {widgets.map((widget, i) => {
                            return (
                                <Cell span={3} key={i}>
                                    <Widget
                                        widget={widget}
                                        site_id={site_id}
                                        site={site}
                                        onDelete={() => handleDelete(widget.id)}
                                        key={widget.id}
                                    />
                                </Cell>
                            );
                        })}
                    </Layout>
                )}
            </Page.Content>
        </Box>
    );
};
export const Widget = ({ site, widget, site_id, onDelete }) => {
    const [openModel, setOpenModel] = useState(widget.is_new);

    return (
        <>
            <InstallationModel
                widget={widget}
                site_id={site_id}
                isModalOpened={openModel}
                onClose={() => setOpenModel(false)}
            />

            <Card showShadow={true}>
                <Box height="24px" marginTop={2} gap={2}>
                    <Badge skin={widget.enabled ? 'success' : 'danger'}>
                        {widget.enabled ? 'on' : 'off'}
                    </Badge>
                    <WidgetEnable site_id={site_id} widget={widget} />
                </Box>
                <Card.Header
                    title={widget.name}
                    suffix={
                        site?.roles?.some((r) => ['OWNER', 'ADMIN'].includes(r)) && (
                            <DeleteConfirmation successClick={onDelete}>
                                <IconButton skin="light" size="large">
                                    <Icons.Delete />
                                </IconButton>
                            </DeleteConfirmation>
                        )
                    }
                />
                <Box
                    marginTop={2}
                    marginBottom={2}
                    align="center"
                    paddingBottom={2}
                    direction="vertical"
                >
                    <Button
                        size="medium"
                        className={classes.learnButton}
                        priority="secondary"
                        onClick={() => setOpenModel(true)}
                        suffixIcon={<Icons.DownloadImport />}
                    >
                        Installation Guide
                    </Button>

                    {site?.roles?.some((r) => ['EVENT_MANAGER', 'ADMIN', 'OWNER'].includes(r)) && (
                        <Box gap={1} marginTop={2} align="center">
                            <Button
                                prefixIcon={<img src={EventManagementIcon} width={35} />}
                                priority="secondary"
                                onClick={() => {
                                    const baseUrl = window.location.origin;
                                    const queryString = `?site=${encodeURIComponent(
                                        site_id
                                    )}&widget=${encodeURIComponent(widget.id)}`;
                                    const newPath = '#/events';

                                    window.location.href = `${baseUrl}${queryString}${newPath}`;
                                }}
                            >
                                Event Management
                            </Button>
                        </Box>
                    )}
                </Box>
            </Card>
        </>
    );
};
