import {
    Row,
    Col,
    Box,
    FileUpload,
    FormField,
    Input,
    RichTextInputArea,
    InputStatus,
    ImageViewer,
    Cell,
    Layout,
    Text,
    RadioGroup,
    InfoIcon,
    ToggleSwitch,
    Heading,
    Checkbox,
    NumberInput,
} from 'wix-style-react';

import { Controller } from 'react-hook-form';

import { classes } from './create-events.st.css';
import { toBase64 } from '../../utils';
import { isEmpty } from 'lodash';
import { UnsplashPhotoPicker } from '../../components/unslash-photo-picker';
import { GiphyPhotoPicker } from '../../components/giphy-photo-picker';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../features/account-slice';
import { EventCategory } from './components/category';
import { AIGenerationButton } from '../../components/generate-ai-text';
import { PlanNotification } from '../../components/plan-notification';

export const BasicInfo = ({
    descriptionRef,
    event,
    watch,
    control,
    setValue,
    errors,
    getValues,
    isPublicCreation,
}) => {
    const watchUrl = watch('url', '');
    const watchImages = watch('image_url', event?.image_url);
    const watchExternalUrlLinkToHostedPage = watch(
        'externalUrlLinkToHostedPage',
        event?.externalUrlLinkToHostedPage
    );
    const watchSecondaryImageUrls = watch(
        'secondary_image_urls',
        event?.secondary_image_urls || []
    );
    const watchDescriptionThumbnailUrls = watch(
        'description_thumbnail_urls',
        event?.description_thumbnail_urls || []
    );
    const watchDescriptionThumbnailImages = watch('description_thumbnail_images', []);
    const watchSecondaryImages = watch('secondary_images', []);
    const watchEventSetup = watch('eventSetup', event?.eventSetup);
    const watchPrivacyAccessCodeEnabled = watch(
        'privacyAccessCodeEnabled',
        event?.privacyAccesCodeEnabled
    );

    const watchUnlimitedCapacity = watch('unlimitedCapacity', event?.unlimitedCapacity);

    const account = useSelector(selectAccount);

    const eventSetupOptions = [
        {
            value: 'tickets',
            title: 'Sell tickets',
            info: 'Offer free or paid tickets through the app with our simple checkout process.',
        },
        {
            value: 'rsvp',
            title: 'RSVP or Simple Registration',
            info: 'Guests can RSVP through a form that allows you to collect information.',
        },
        {
            value: 'url',
            title: 'External URL',
            info: 'Clicking the RSVP/Attend button will direct users to either a custom external page or our hosted event page.',
            selectedComponent: (
                <>
                    <Box gap={2}>
                        <FormField
                            labelPlacement="right"
                            labelSize="small"
                            label="Link to hosted event page"
                            infoContent="Redirects users to a dedicated event page for additional details and ticketing options when they click the register button."
                        >
                            <Controller
                                control={control}
                                name="externalUrlLinkToHostedPage"
                                render={({ field }) => (
                                    <ToggleSwitch
                                        {...field}
                                        size="small"
                                        onChange={(e) => {
                                            setValue(
                                                'externalUrlLinkToHostedPage',
                                                e.target.checked
                                            );
                                        }}
                                        checked={watchExternalUrlLinkToHostedPage}
                                    />
                                )}
                            />
                        </FormField>
                        {!watchExternalUrlLinkToHostedPage && (
                            <FormField>
                                <Controller
                                    control={control}
                                    name="url"
                                    rules={{
                                        validate: (url: string) => {
                                            if (isEmpty(url)) return true;
                                            try {
                                                new URL(url);
                                            } catch (e) {
                                                return false;
                                            }
                                            return true;
                                        },
                                    }}
                                    render={({ field }) => (
                                        <Input
                                            size="large"
                                            placeholder="https://www.ticketspot.io"
                                            {...field}
                                            className={classes.urlInput}
                                            onChange={(e) => {
                                                setValue('url', e.target.value);
                                            }}
                                            value={watchUrl}
                                        />
                                    )}
                                />
                                {errors?.url?.type === 'validate' && (
                                    <small className={classes.error}>
                                        <b>{watchUrl}</b> is not valid. Make sure the URL starts
                                        with <i>https://</i>. For example - https://ticketspot.io
                                    </small>
                                )}
                            </FormField>
                        )}

                        {/* <FormField
                        label="Pass event data as query parameters"
                        infoContent="When a guest is redirected to the specified site the event data will be sent as query parameters so you can use the information on the target site"
                    >
                        <Controller
                            control={control}
                            name="includeEventDataUrl"
                            render={({ field }) => (
                                <ToggleSwitch
                                    {...field}
                                    onChange={(e) => {
                                        setValue('includeEventDataUrl', e.target.checked);
                                    }}
                                    checked={watchExternalUrlLinkToHostedPage}
                                />
                            )}
                        />
                        {errors?.url?.type === 'validate' && (
                            <small className={classes.error}>
                                <b>{watchUrl}</b> is not valid. Make sure the URL starts with{' '}
                                <i>https://</i>. For example - https://ticketspot.io
                            </small>
                        )}
                    </FormField> */}
                    </Box>
                </>
            ),
        },
        {
            value: 'nothing',
            title: 'Display only',
            info: 'Show event information only, without the attend or checkout button.',
        },
    ];

    return (
        <>
            <div className={classes.margin}>
                <Row>
                    <Col span={5}>
                        <Box
                            boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                            padding={4}
                            width={'100%'}
                        >
                            <EventCategory
                                control={control}
                                setValue={setValue}
                                watch={watch}
                                errors={errors}
                            />
                        </Box>

                        <Box
                            marginTop={4}
                            boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                            padding={4}
                        >
                            <FormField
                                label={<Heading appearance="H4">Event Setup</Heading>}
                                required
                            >
                                <Controller
                                    control={control}
                                    name="eventSetup"
                                    rules={{
                                        required: 'Event setup needs to be selected',
                                    }}
                                    render={({ field }) => (
                                        <RadioGroup
                                            onChange={(e) => {
                                                setValue('eventSetup', e);
                                            }}
                                            value={field.value}
                                        >
                                            {eventSetupOptions.map((option, index) => {
                                                return (
                                                    <RadioGroup.Radio
                                                        value={option.value}
                                                        key={index}
                                                    >
                                                        <Box gap={1}>
                                                            {option.title}
                                                            <InfoIcon
                                                                size="medium"
                                                                content={option.info}
                                                            />
                                                        </Box>
                                                        {watchEventSetup === option.value &&
                                                            option?.selectedComponent}
                                                    </RadioGroup.Radio>
                                                );
                                            })}
                                        </RadioGroup>
                                    )}
                                />
                                <small className={classes.error}>
                                    {errors.eventSetup?.message}
                                </small>
                            </FormField>
                        </Box>

                        {!isPublicCreation && (
                            <Box
                                marginTop={4}
                                direction="vertical"
                                gap={2}
                                boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                                padding={4}
                            >
                                <Heading appearance="H4">Privacy</Heading>
                                <Box direction="vertical" gap={2}>
                                    <PlanNotification type={'featured'} text="Private Event">
                                        <FormField label="">
                                            <Controller
                                                control={control}
                                                name="privacyHideEvent"
                                                render={({ field }) => (
                                                    <Checkbox
                                                        size="medium"
                                                        {...field}
                                                        onChange={(e) =>
                                                            setValue(
                                                                'privacyHideEvent',
                                                                e.target.checked
                                                            )
                                                        }
                                                        checked={field.value}
                                                    >
                                                        Hide Event from Event Listing{' '}
                                                        <InfoIcon content="This will not show this event in the event listing. Useful if you want to temporarily hide the event from the public." />
                                                    </Checkbox>
                                                )}
                                            ></Controller>
                                        </FormField>
                                    </PlanNotification>
                                    {/* <FormField label="">
                        <Controller
                            control={control}
                            name="privacyAccessCodeEnabled"
                            render={({ field }) => (
                                <Checkbox
                                    size="medium"
                                    {...field}
                                    onChange={(e) =>
                                        setValue('privacyAccessCodeEnabled', e.target.checked)
                                    }
                                    checked={field.value}
                                >
                                    Require Access Code to View and Purchase Tickets{' '}
                                    <InfoIcon content="Share the access code with visitors to grant them access to the event and enable ticket purchases. Available when using the hosted event page." />
                                </Checkbox>
                            )}
                        ></Controller>
                    </FormField> */}
                                    {watchPrivacyAccessCodeEnabled && (
                                        <FormField label="Access Code">
                                            <Controller
                                                control={control}
                                                name="privacyAccessCode"
                                                render={({ field }) => <Input {...field} />}
                                            ></Controller>
                                        </FormField>
                                    )}
                                </Box>
                            </Box>
                        )}

                        <Box
                            direction="vertical"
                            gap={2}
                            marginTop={4}
                            boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                            padding={4}
                        >
                            <Heading appearance="H4">
                                Event Capacity{' '}
                                <InfoIcon content="This is the maximum number of tickets that can be sold at your event, including all ticket types."></InfoIcon>
                            </Heading>
                            <PlanNotification type="featured" text="event capacity">
                                <Box gap={2}>
                                    <FormField label="Unlimited attendee capacity" infoContent="">
                                        <Controller
                                            control={control}
                                            name="unlimitedCapacity"
                                            render={({ field }) => (
                                                <ToggleSwitch
                                                    {...field}
                                                    onChange={(e) => {
                                                        setValue(
                                                            'unlimitedCapacity',
                                                            e.target.checked
                                                        );
                                                    }}
                                                    checked={watchUnlimitedCapacity}
                                                />
                                            )}
                                        />
                                    </FormField>
                                    <FormField label={<Box gap={2}>Total capacity </Box>}>
                                        <Controller
                                            control={control}
                                            name="eventCapacity"
                                            render={({ field }) => (
                                                <NumberInput
                                                    {...field}
                                                    disabled={watchUnlimitedCapacity}
                                                    placeholder="100"
                                                    // suffix={
                                                    //     watchPassTicketFee && (
                                                    //         <Input.Affix>
                                                    //             +{currency}
                                                    //             {(
                                                    //                 process.env.TICKET_FEE * priceData.convert
                                                    //             ).toFixed(2)}
                                                    //         </Input.Affix>
                                                    //     )
                                                    // }
                                                />
                                            )}
                                        />
                                    </FormField>
                                </Box>
                            </PlanNotification>
                        </Box>
                    </Col>

                    <Col span={7}>
                        <Box
                            boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                            padding={4}
                            direction="vertical"
                            gap={4}
                        >
                            <Box width={'100%'} className={classes.stretch}>
                                <FormField
                                    label={<Heading appearance="H4">Title</Heading>}
                                    required
                                >
                                    <Controller
                                        control={control}
                                        name="title"
                                        rules={{
                                            required: 'Event title is required!',
                                            max: 140,
                                        }}
                                        render={({ field }) => (
                                            <Box verticalAlign="middle" flexGrow={1} gap={2}>
                                                <Input
                                                    className={classes.flexGrow}
                                                    status={
                                                        errors.title?.message
                                                            ? Input.StatusError
                                                            : ('' as InputStatus)
                                                    }
                                                    {...field}
                                                />
                                            </Box>
                                        )}
                                    ></Controller>
                                    <small className={classes.error}>{errors.title?.message}</small>
                                </FormField>
                            </Box>

                            <Box>
                                <FormField
                                    label="Primary Image"
                                    infoContent="It is recommended the width to length ratio should be 3:2"
                                >
                                    <Controller
                                        control={control}
                                        name="image"
                                        render={({ field }) => (
                                            <FileUpload
                                                {...field}
                                                onChange={async (files) => {
                                                    const url = await toBase64(files[0]);
                                                    setValue('image_url', url);
                                                    setValue('image', files[0]);
                                                }}
                                                accept=".jpeg,.gif,.png,.jpg"
                                            >
                                                {({ openFileUploadDialog }) => {
                                                    return (
                                                        <ImageViewer
                                                            imageUrl={watchImages}
                                                            showUpdateButton={false}
                                                            onAddImage={openFileUploadDialog}
                                                            onRemoveImage={() => {
                                                                setValue('image_url', null);
                                                                setValue('image', null);
                                                            }}
                                                            width="100%"
                                                            height="220px"
                                                        />
                                                    );
                                                }}
                                            </FileUpload>
                                        )}
                                    ></Controller>
                                </FormField>
                            </Box>

                            <Box gap={2} align="left" direction="vertical">
                                <Text>
                                    Find the perfect image or GIF for your event by browsing
                                    Unsplash or Giphy.
                                </Text>

                                <Box gap={1}>
                                    <UnsplashPhotoPicker
                                        buttonText="Unsplash"
                                        event={event}
                                        account={account}
                                        onChange={async (file: any) => {
                                            setValue('image_url', file);
                                            setValue('image', file);
                                        }}
                                    />
                                    <GiphyPhotoPicker
                                        buttonText="Giphy"
                                        event={event}
                                        onChange={async (gif: any) => {
                                            setValue('image_url', gif);
                                            setValue('image', gif);
                                        }}
                                    />
                                </Box>
                            </Box>

                            <Box direction="vertical" marginBottom={6}>
                                <FormField>
                                    <FileUpload
                                        multiple
                                        onChange={async (files) => {
                                            const urls = await Promise.all(
                                                Array.from(files).map((file) => toBase64(file))
                                            );
                                            setValue(
                                                'secondary_image_urls',
                                                watchSecondaryImageUrls
                                                    ? watchSecondaryImageUrls.concat(urls)
                                                    : urls
                                            );
                                            setValue(
                                                'secondary_images',
                                                watchSecondaryImages.concat(Array.from(files))
                                            );
                                        }}
                                        accept=".jpeg,.gif,.png,.jpg"
                                    >
                                        {({ openFileUploadDialog }) => {
                                            return (
                                                <ImageViewer onAddImage={openFileUploadDialog} />
                                            );
                                        }}
                                    </FileUpload>
                                    <Box marginBottom={4} gap={1} marginTop={2} align="left">
                                        <UnsplashPhotoPicker
                                            event={event}
                                            account={account}
                                            onChange={async (base64Url) => {
                                                setValue(
                                                    'secondary_images',
                                                    watchSecondaryImages.concat(base64Url)
                                                );
                                                setValue(
                                                    'secondary_image_urls',
                                                    watchSecondaryImageUrls
                                                        ? watchSecondaryImageUrls.concat(base64Url)
                                                        : [base64Url]
                                                );
                                            }}
                                        />
                                        <GiphyPhotoPicker
                                            event={event}
                                            onChange={async (gif) => {
                                                setValue(
                                                    'secondary_images',
                                                    watchSecondaryImages.concat(gif)
                                                );
                                                setValue(
                                                    'secondary_image_urls',
                                                    watchSecondaryImageUrls
                                                        ? watchSecondaryImageUrls.concat(gif)
                                                        : [gif]
                                                );
                                            }}
                                        />
                                    </Box>
                                </FormField>
                                <Box gap="2" marginBottom={2}>
                                    {watchSecondaryImageUrls?.map((secondaryImage, index) => {
                                        return (
                                            <Cell span={3}>
                                                <ImageViewer
                                                    imageUrl={secondaryImage}
                                                    onRemoveImage={() => {
                                                        const newImages =
                                                            watchSecondaryImageUrls.filter(
                                                                (_, i) => i !== index
                                                            );
                                                        const newFiles =
                                                            watchSecondaryImages.filter(
                                                                (_, i) => i !== index
                                                            );
                                                        setValue('secondary_image_urls', newImages);
                                                        setValue('secondary_images', newFiles);
                                                    }}
                                                />
                                            </Cell>
                                        );
                                    })}
                                </Box>
                                <FormField
                                    label={
                                        <Box gap={2}>
                                            <Heading appearance="H4">Description</Heading>
                                            {/* <AIGenerationButton
                                                name="description"
                                                isPublicCreation={isPublicCreation}
                                                getValues={getValues}
                                                onSubmit={(result) => {
                                                    descriptionRef.current?.setValue(result);
                                                    setValue('description', result);
                                                }}
                                            /> */}
                                        </Box>
                                    }
                                    required
                                >
                                    <Controller
                                        control={control}
                                        name="description"
                                        render={({ field }) => (
                                            <RichTextInputArea
                                                {...field}
                                                className={classes.flexGrow}
                                                initialValue={event?.description}
                                                minHeight="140px"
                                                maxHeight="400px"
                                                onChange={(htmlText: string) =>
                                                    setValue('description', htmlText)
                                                }
                                                ref={descriptionRef}
                                            />
                                        )}
                                    />
                                </FormField>
                            </Box>

                            <Box marginTop={2} marginBottom={2}>
                                <FormField
                                    label={<Heading appearance="H4">Description Image</Heading>}
                                >
                                    <Text>
                                        Add thumbnail images under the description of your event.
                                        Great for displaying flyers or extra information about an
                                        event
                                    </Text>
                                </FormField>
                            </Box>
                            <Box>
                                <Layout>
                                    <Cell span={3}>
                                        <FormField>
                                            <FileUpload
                                                multiple
                                                onChange={async (files) => {
                                                    const urls = await Promise.all(
                                                        Array.from(files).map((file) =>
                                                            toBase64(file)
                                                        )
                                                    );
                                                    setValue(
                                                        'description_thumbnail_urls',
                                                        watchDescriptionThumbnailUrls
                                                            ? watchDescriptionThumbnailUrls.concat(
                                                                  urls
                                                              )
                                                            : urls
                                                    );
                                                    setValue(
                                                        'description_thumbnail_images',
                                                        watchDescriptionThumbnailImages.concat(
                                                            Array.from(files)
                                                        )
                                                    );
                                                }}
                                                accept=".jpeg,.gif,.png,.jpg"
                                            >
                                                {({ openFileUploadDialog }) => {
                                                    return (
                                                        <ImageViewer
                                                            onAddImage={openFileUploadDialog}
                                                        />
                                                    );
                                                }}
                                            </FileUpload>
                                        </FormField>
                                    </Cell>
                                    {watchDescriptionThumbnailUrls?.map((image, index) => {
                                        return (
                                            <Cell span={3} key={index}>
                                                <ImageViewer
                                                    imageUrl={image}
                                                    onRemoveImage={() => {
                                                        const newImages =
                                                            watchDescriptionThumbnailUrls.filter(
                                                                (_, i) => i !== index
                                                            );
                                                        const newFiles =
                                                            watchDescriptionThumbnailImages.filter(
                                                                (_, i) => i !== index
                                                            );
                                                        setValue(
                                                            'description_thumbnail_urls',
                                                            newImages
                                                        );
                                                        setValue(
                                                            'description_thumbnail_images',
                                                            newFiles
                                                        );
                                                    }}
                                                />
                                            </Cell>
                                        );
                                    })}
                                </Layout>
                            </Box>
                            <Box
                                marginBottom={4}
                                gap={1}
                                marginTop={2}
                                align="left"
                                direction="vertical"
                                width={'100%'}
                            >
                                <Text>
                                    Find the perfect image or GIF for your event by browsing
                                    Unsplash or Giphy.
                                </Text>
                                <Box gap={1}>
                                    <UnsplashPhotoPicker
                                        event={event}
                                        account={account}
                                        onChange={async (url: string) => {
                                            setValue(
                                                'description_thumbnail_images',
                                                watchDescriptionThumbnailImages.concat(url)
                                            );
                                            setValue(
                                                'description_thumbnail_urls',
                                                watchDescriptionThumbnailUrls
                                                    ? watchDescriptionThumbnailUrls.concat(url)
                                                    : [url]
                                            );
                                        }}
                                    />
                                    <GiphyPhotoPicker
                                        event={event}
                                        onChange={async (gif) => {
                                            setValue(
                                                'description_thumbnail_images',
                                                watchDescriptionThumbnailImages.concat(gif)
                                            );
                                            setValue(
                                                'description_thumbnail_urls',
                                                watchDescriptionThumbnailUrls
                                                    ? watchDescriptionThumbnailUrls.concat(gif)
                                                    : [gif]
                                            );
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Col>
                </Row>
            </div>
        </>
    );
};
