import moment from 'moment';
import { Dropdown, RadioGroup, ToggleSwitch } from 'wix-style-react';
import { SettingsHeader } from '../../../components/settings-header';
import { WidgetSettingsUpdater } from '../../../components/widget-settings-updater';
import { selectSettings } from '../../../features/settings-slice';
import { useAppSelector } from '../../../hooks';

const DATE_FORMAT_OPTIONS = [
    { id: 'MMM D', value: moment().format('MMM D') },
    { id: 'Do MMM', value: moment().format('Do MMM') },
    { id: 'Do MMMM', value: moment().format('Do MMMM') },
    { id: 'ddd MMM Do', value: moment().format('ddd MMM Do') },
    { id: 'Do MMMM YYYY', value: moment().format('Do MMMM YYYY') },
    { id: 'ddd Do MMMM', value: moment().format('ddd Do MMMM') },
    { id: 'Do MMMM YYYY', value: moment().format('Do MMMM YYYY') },
    { id: 'ddd Do MMMM YYYY', value: moment().format('ddd Do MMMM YYYY') },
    { id: 'dddd MMMM Do YYYY', value: moment().format('dddd MMMM Do YYYY') },
    { id: 'dddd Do MMMM YYYY', value: moment().format('dddd Do MMMM YYYY') },
];
const TIME_FORMAT_OPTIONS = [
    { value: 'h:mm A', label: moment().format('h:mm A') },
    { value: 'HH:mm', label: moment().format('HH:mm') },
    { value: 'HH:mm A', label: moment().format('HH:mm A') },
];

export const TimeDate = ({ label }) => {
    const settings = useAppSelector(selectSettings);

    return (
        <>
            <SettingsHeader title={label} />

            <hr className="divider-long" />

            <WidgetSettingsUpdater
                title="Date Display Format"
                updateKey="dateFormat"
                tooltipText="Choose the format in which you'd like the event date to appear."
            >
                <Dropdown options={DATE_FORMAT_OPTIONS} />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater updateKey="includeTimezone" isPremium title="Include Timezone">
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                title="Auto-Adjust Timezone"
                updateKey="automaticTimezone"
                tooltipText="Automatically adjust the event date and time based on the visitor's timezone."
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater updateKey="showTime" isPremium title="Show Start Time">
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            {settings.showTime && (
                <WidgetSettingsUpdater title="Show End Time" updateKey="showEndTime" isPremium>
                    <ToggleSwitch />
                </WidgetSettingsUpdater>
            )}

            {settings.showTime && (
                <WidgetSettingsUpdater
                    title="Time Display Format"
                    updateKey="timeFormat"
                    tooltipText="Choose the format in which you'd like the event time to appear on your event."
                >
                    <RadioGroup value={settings.timeFormat}>
                        {TIME_FORMAT_OPTIONS.map((val, index) => (
                            <RadioGroup.Radio key={index} value={val.value}>
                                {val.label}
                            </RadioGroup.Radio>
                        ))}
                    </RadioGroup>
                </WidgetSettingsUpdater>
            )}

            <WidgetSettingsUpdater updateKey="showStartDate" title="Show Start Date">
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater updateKey="showEndDate" title="Show End Date">
                <ToggleSwitch />
            </WidgetSettingsUpdater>
        </>
    );
};
