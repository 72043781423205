import { FormField, Box, Input, SectionHelper } from 'wix-style-react';

import { Controller } from 'react-hook-form';

export const WebhookAction = ({ control, campaign = {} }) => {
    const { webhook } = campaign || {};

    return (
        <>
            <Box marginTop={2} />

            <Box marginBottom={2}>
                <SectionHelper appearance="warning">
                    Webhooks allow you to build or set up integrations which subscribe to certain
                    events (guest register, ticket purchase, event started) on Ticket Spot. When one
                    of those events is triggered, we'll send a HTTP POST payload to the webhook's
                    configured URL. Check out our{' '}
                    <a href="https://docs.ticketspot.io/integrations/webhooks">documentation</a> for
                    instructions on setting up and validating the payload
                </SectionHelper>
            </Box>
            <FormField
                label="Payload URL"
                required
                infoContent={'Ticket Spot triggers an HTTP POST request to the defined destination'}
            >
                <Controller
                    control={control}
                    name="webhook.destination_url"
                    render={({ field }) => <Input defaultValue={webhook?.secret} {...field} />}
                />
            </FormField>
            <Box marginTop={2} />

            <FormField
                label="Secret token"
                required
                infoContent="A random string used to validate payloads"
            >
                <Controller
                    control={control}
                    name="webhook.secret"
                    render={({ field }) => (
                        <Input min={10} defaultValue={webhook?.secret} {...field} />
                    )}
                />
            </FormField>
        </>
    );
};
