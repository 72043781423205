import { FC, useContext, useState } from 'react';
import { Grid, SearchBar, SearchContext, SearchContextManager } from '@giphy/react-components';
import { Box, Button, CustomModalLayout, Modal, SectionHelper } from 'wix-style-react';
import PoweredByGiphy from '../assets/Poweredby_640px-White_HorizText.png';

export const GiphyPhotoPicker: FC<any> = ({ event, onChange, buttonText }) => {
    const [isModalOpened, setModalOpened] = useState(false);

    const openModal = () => setModalOpened(true);
    const closeModal = () => setModalOpened(false);

    const renderForm = () => (
        <Box direction="vertical" width={'100%'}>
            <SearchContextManager apiKey={process.env.GIPHY_API_KEY} initialTerm={event?.title}>
                <Components />
            </SearchContextManager>
        </Box>
    );

    const renderModalContent = () => (
        <CustomModalLayout
            primaryButtonText="Close"
            primaryButtonOnClick={closeModal}
            title="Browse Giphy"
            width="600px"
            height="80%"
        >
            <>
                <SectionHelper appearance="standard">
                    Selecting a GIF will automatically add it to the photo collection
                </SectionHelper>
                {renderForm()}
            </>
        </CustomModalLayout>
    );
    const Components = () => {
        const context = useContext(SearchContext);

        const { fetchGifs, searchKey } = context;
        return (
            <>
                <Box height={10} />
                <SearchBar />
                <Box height={30} marginTop={5}>
                    <img src={PoweredByGiphy} height="25" />
                </Box>

                <Grid
                    width={500}
                    columns={2}
                    key={searchKey}
                    fetchGifs={fetchGifs}
                    onGifClick={(gif, e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        onChange(`https://media.giphy.com/media/${gif.id}/giphy.gif`);
                    }}
                />
                <Box>
                    <img src={PoweredByGiphy} alt="Powered by Giphy" />
                </Box>
            </>
        );
    };

    return (
        <>
            <Button size="small" onClick={openModal} priority="secondary">
                {buttonText || 'Giphy'}
            </Button>
            <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
                {renderModalContent()}
            </Modal>
        </>
    );
};
