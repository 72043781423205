import {
    Card,
    Button,
    Table,
    TableActionCell,
    Badge,
    Loader,
    Box,
    Layout,
    Cell,
    FormField,
    RadioGroup,
    Text,
    Dropdown,
    NumberInput,
    Modal,
    CustomModalLayout,
    ToggleSwitch,
    EmptyState,
    InputStatus,
    Input,
    InfoIcon,
    Heading,
} from 'wix-style-react';
import moment from 'moment-timezone';
import AddSmallIcon from 'wix-ui-icons-common/AddSmall';
import { classes } from '../campaigns/create-campaign.st.css';

import * as Icons from 'wix-ui-icons-common';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCallback, useRef, useState } from 'react';
import {
    fetchEventCampaignsApi,
    selectEventCampaignsList,
    selectCampaignIsLoading,
    selectTriggersList,
    deleteCampaignsApi,
    selectCampaignTimeTypeOptions,
    selectCampaignPrefixTypeOptions,
    createCampaignApi,
    updateCampaignApi,
    startCampaignsApi,
    stopCampaignsApi,
    selectEventCampaignsListLoading,
} from '../../features/campaign-slice';

import type { Campaign } from '../../types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useEffect } from 'react';
import { PlanNotification } from '../../components/plan-notification';
import { buildURL } from '../../features/utils';
import { fetchConnectedAccountsApi, selectSiteData } from '../../features/account-slice';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { uniq } from 'lodash';
import { ActionSelector } from '../campaigns/action-selector';
import { NotificationModal } from '../campaigns/create-notification-modal';

export const ShareCommunicationAcrossEvents = ({ campaign }) => {
    const dispatch = useAppDispatch();
    const [enabled, setEnabled] = useState(campaign.subscribe_all_events);
    const [loading, setLoading] = useState(false);
    const handleChange = useCallback(
        async (newEnable) => {
            setEnabled(newEnable);
            setLoading(true);
            dispatch(updateCampaignApi({ ...campaign, subscribe_all_events: newEnable }));
        },
        [dispatch, campaign]
    );
    const statusBadge = enabled ? (
        <Badge skin="neutralStandard" size="small" className={classes.badge}>
            Shared Across All Events
        </Badge>
    ) : (
        <Badge skin="neutralStandard" size="small" className={classes.badge}>
            Used Only for This Event
        </Badge>
    );
    return (
        <Box>
            <FormField labelPlacement="right">
                <Box direction="vertical" gap={2}>
                    <ToggleSwitch
                        status={loading ? 'loading' : ''}
                        checked={enabled}
                        onChange={(e) => handleChange(e.target.checked)}
                    />
                    {statusBadge}
                </Box>
            </FormField>
        </Box>
    );
};
export const EnabledSwitch = ({ campaign }) => {
    const dispatch = useAppDispatch();
    const [enabled, setEnabled] = useState(campaign.enabled);
    const [loading, setLoading] = useState(false);
    const handleChange = useCallback(
        async (newEnable) => {
            setEnabled(newEnable);
            setLoading(true);
            newEnable
                ? await dispatch(startCampaignsApi(campaign))
                : await dispatch(stopCampaignsApi(campaign));
            setLoading(false);
        },
        [enabled, dispatch]
    );
    const statusBadge = enabled ? (
        <Badge skin="neutralSuccess" size="small">
            Started
        </Badge>
    ) : (
        <Badge skin="warningLight" size="small">
            Stopped
        </Badge>
    );
    return (
        <Box>
            <FormField labelPlacement="right">
                <Box direction="vertical" gap={2}>
                    <ToggleSwitch
                        status={loading ? 'loading' : ''}
                        checked={enabled}
                        onChange={(e) => handleChange(e.target.checked)}
                    />
                    {statusBadge}
                </Box>
            </FormField>
        </Box>
    );
};

export const EventCampaignList = ({ event, scheduleType }) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [shown, setShown] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);

    const campaigns = useSelector(selectEventCampaignsList);
    const campaignListLoading = useSelector(selectEventCampaignsListLoading);
    const site = useSelector(selectSiteData);

    const triggers = useAppSelector(selectTriggersList) || [];
    const [trigger, setTrigger] = useState(triggers[0]);

    const filteredCampaigns = campaigns.filter(
        (campaign) =>
            !['WAITLIST_AVAILABLE_NOTIFICATION', 'WAITLIST_SIGNUP_CONFIRMATION'].includes(
                campaign.trigger_type
            )
    );

    useEffect(() => {
        dispatch(fetchEventCampaignsApi(event.id));
    }, [dispatch]);

    const deleteCallback = useCallback(
        (id) => {
            dispatch(deleteCampaignsApi(id));
        },
        [dispatch]
    );

    const secondaryActions = (row: Campaign) => {
        return [
            {
                icon: <Icons.Delete />,
                text: 'Delete',
                onClick: () => deleteCallback(row.id),
            },
            {
                icon: <Icons.List />,
                text: 'Logs',
                onClick: () =>
                    history.push(buildURL(site, `/events/campaigns/manage/${row.id}/logs`)),
            },
            {
                icon: <Icons.Statistics />,
                text: 'Analytics',
                onClick: () =>
                    history.push(buildURL(site, `/events/campaigns/manage/${row.id}/stats`)),
            },
        ];
    };

    const primaryAction = (row) => ({
        text: 'Edit',
        onClick: () => {
            setShown(true);
            setSelectedCampaign(row);
        },
    });

    function getScheduleDescription(campaign, timezone) {
        if (campaign.pending_workflow && campaign.pending_workflow.execution_date) {
            return moment(campaign.pending_workflow.execution_date).tz(timezone).format('LLL');
        } else if (campaign.trigger_type === 'NEW_ORDER') {
            return 'On Each Order';
        } else if (campaign.trigger_type === 'EVENT_CHANGED') {
            return 'On Date/Time Change';
        } else {
            return 'Not Scheduled';
        }
    }

    const columns = [
        {
            title: 'Trigger',
            width: '20%',
            render: (row: Campaign) => (
                <Box direction="vertical" gap={1}>
                    <Text>{triggers.find((t) => t.value === row.trigger_type)?.name}</Text>
                    {row.is_shared && row.subscribe_all_events && (
                        <Box width={120}>
                            <Badge skin="warning">
                                Shared{' '}
                                <InfoIcon
                                    className={classes.white}
                                    size="small"
                                    content={
                                        'This engagement applies universally to all events and widgets.'
                                    }
                                />
                            </Badge>
                        </Box>
                    )}
                    {row.is_shared && !row.subscribe_all_events && (
                        <Box width={120}>
                            <Badge skin="warningLight">
                                Shared{' '}
                                <InfoIcon
                                    size="small"
                                    content={'This engagement applies universally to all widgets.'}
                                />
                            </Badge>
                        </Box>
                    )}
                </Box>
            ),
        },
        // {
        //     title: 'Name',
        //     width: '20%',
        //     render: (row: Campaign) => <Text>{row.name}</Text>,
        // },
        {
            title: (
                <Box gap={2}>
                    Scheduled Send Time{' '}
                    <InfoIcon content="Scheduling will depend on the trigger type. For example, a ticket confirmation will be sent with each order, whereas a reminder engagement will be sent a time before the event, depending on how you have configured it." />
                </Box>
            ),
            width: '20%',
            render: (campaign: Campaign) => getScheduleDescription(campaign, event.timezone),
        },
        {
            title: 'Status',
            width: '20%',
            render: (row: Campaign) => <EnabledSwitch campaign={row} />,
        },
        {
            title: (
                <Box gap={2}>
                    Share Across Events
                    <InfoIcon
                        size="small"
                        content={`You can share this message across all your events and use our personalization tokens to ensure it dynamically adjusts to your specific event or attendees. This is especially useful to avoid repeatedly creating the same email across events.`}
                    />
                </Box>
            ),
            width: '20%',
            render: (row: Campaign) => <ShareCommunicationAcrossEvents campaign={row} />,
        },
        {
            title: (
                <Box gap={2}>
                    Notification
                    <InfoIcon size="small" content={`Receive an email when an order is created `} />
                </Box>
            ),
            width: '20%',
            render: (row: Campaign) => (
                <NotificationModal campaign_id={row.id} id={row?.notification?.id} />
            ),
        },
        {
            render: (row: Campaign) => (
                <TableActionCell
                    primaryAction={primaryAction(row)}
                    secondaryActions={secondaryActions(row)}
                />
            ),
        },
    ];

    return (
        <Box
            width="90%"
            padding="24px 29px 27px"
            boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
            margin="0 auto"
            direction="vertical"
        >
            <Card.Header
                title="Email Communication"
                subtitle="Setup mail communications to message your customers when a ticket has been purchased, send reminders, or thank your attendees after the event ends."
                suffix={
                    <Button
                        prefixIcon={<AddSmallIcon />}
                        onClick={() => {
                            setSelectedCampaign(null);
                            setShown(true);
                        }}
                    >
                        Create
                    </Button>
                }
            ></Card.Header>
            <Box>
                <Layout>
                    <Cell span={12}>
                        <Box marginBottom={4}>
                            {shown && (
                                <CampaignModal
                                    event={event}
                                    campaign={selectedCampaign}
                                    shown={shown}
                                    setShown={setShown}
                                    trigger={trigger}
                                    onComplete={() => setSelectedCampaign(null)}
                                    scheduleType={scheduleType}
                                />
                            )}
                            <Card className={classes.fullWidth}>
                                <Card.Content>
                                    {campaignListLoading && (
                                        <Box align="center">
                                            <Loader
                                                size="medium"
                                                text="Fetching Email Communications"
                                            />
                                        </Box>
                                    )}
                                    {!campaignListLoading && filteredCampaigns.length > 0 && (
                                        <PlanNotification type="featured" text="automation">
                                            <Table data={filteredCampaigns} columns={columns}>
                                                <Table.Content />
                                            </Table>
                                        </PlanNotification>
                                    )}
                                    {!campaignListLoading && filteredCampaigns.length === 0 && (
                                        <EmptyState
                                            theme={'section'}
                                            title="Boost Engagement Now!"
                                            subtitle="Set Up Your First Event Automation to Connect with Attendees."
                                        />
                                    )}
                                </Card.Content>
                            </Card>
                        </Box>
                    </Cell>
                </Layout>
            </Box>
        </Box>
    );
};

const CampaignManage = ({
    campaign,
    trigger,
    watch,
    control,
    setValue,
    formState,
    getValues,
    hideName,
    hideSchedule,
}) => {
    const isLoading = useAppSelector(selectCampaignIsLoading);

    if (isLoading)
        return (
            <Box width="100%" height="100px" align="center" verticalAlign="middle">
                <Loader size="small" />
            </Box>
        );
    return (
        <CampaignInput
            campaign={campaign}
            trigger={trigger}
            watch={watch}
            control={control}
            setValue={setValue}
            formState={formState}
            getValues={getValues}
            hideName={hideName}
            hideSchedule={hideSchedule}
        />
    );
};

const CampaignInput = ({
    campaign,
    trigger,
    watch,
    control,
    setValue,
    formState,
    getValues,
    hideName,
    hideSchedule,
}) => {
    const id = campaign?.id;

    const messageRef = useRef();
    const dispatch = useAppDispatch();

    const watchScheduleSuffix = watch('schedule_time_unit', campaign?.schedule_time_unit);
    const watchTriggerType = watch('trigger_type');
    const watchScheduleType = watch('schedule_type');

    const watchScheduleExecutionType = watch(
        'schedule_time_direction',
        campaign?.schedule_time_direction
    );
    const watchSubscribeAllEvents = watch('subscribe_all_events', campaign?.subscribe_all_events);

    const { errors } = formState;
    const triggers = useAppSelector(selectTriggersList);

    useEffect(() => {
        dispatch(fetchConnectedAccountsApi());
    }, [dispatch, watchSubscribeAllEvents]);

    useEffect(() => {
        if (id === undefined) {
            setValue('subject', trigger.subject);
            setValue('message', trigger.message);
            messageRef.current?.setValue(trigger.message);
        }
    }, [id, setValue, triggers, watchTriggerType]);

    useEffect(() => {
        if (campaign) {
            if (campaign?.message) messageRef.current?.setValue(campaign?.message);
            Object.keys(campaign).forEach((ev: string) => setValue(ev, campaign[ev]));
        }
    }, [campaign, setValue]);

    const campaignTimeTypeOptions = useAppSelector(selectCampaignTimeTypeOptions);
    const prefixTriggerOptions = useAppSelector(selectCampaignPrefixTypeOptions);

    return (
        <>
            {/* <Notification
                theme="success"
                show={saved}
                autoHideTimeout={5000}
                onClose={() => setSaved(false)}
            >
                <Notification.TextLabel>Successfully Saved Engagement</Notification.TextLabel>
                <Notification.CloseButton />
            </Notification> */}

            <Box direction="vertical" verticalAlign="space-between" height="100%" width="100%">
                <Box direction="vertical" marginLeft={5} marginRight={5}>
                    <Box marginBottom={3}>
                        <div className={classes.heading}>General Information</div>
                    </Box>

                    {!hideName && (
                        <FormField
                            label={<Heading appearance="H4">Name</Heading>}
                            required
                            stretchContent={false}
                        >
                            <Controller
                                control={control}
                                name="name"
                                rules={{
                                    required: 'Name is required!',
                                    max: 140,
                                }}
                                render={({ field }) => (
                                    <Input
                                        status={
                                            errors.name?.message
                                                ? Input.StatusError
                                                : ('' as InputStatus)
                                        }
                                        {...field}
                                    />
                                )}
                            ></Controller>
                            <small className={classes.error}>{errors.name?.message}</small>
                        </FormField>
                    )}
                </Box>

                <Controller
                    control={control}
                    name="action_selections"
                    render={({ field }) => (
                        <ActionSelector
                            id={id}
                            tag={'messaging'}
                            triggers={triggers}
                            messageRef={messageRef}
                            setValue={setValue}
                            control={control}
                            errors={errors}
                            watch={watch}
                            field={field}
                            campaign={campaign}
                            inlineIfSingle
                            getValues={getValues}
                        />
                    )}
                />
            </Box>
            {!hideSchedule && (
                <Box>
                    <Card>
                        <Card.Header
                            title="Set Timing"
                            subtitle="Schedule a time to execute the email"
                        />
                        <Card.Divider />
                        <Card.Content>
                            <Box marginBottom={2} marginRight={20}>
                                <RadioGroup
                                    value={watchScheduleType}
                                    selectionArea="always"
                                    display="horizontal"
                                    spacing="12px"
                                    selectionAreaSkin="outlined"
                                    selectionAreaPadding="12px 18px"
                                    onChange={(e) => setValue('schedule_type', e)}
                                    className={classes.space}
                                >
                                    <RadioGroup.Radio value={1} className={classes.space}>
                                        Immediately
                                    </RadioGroup.Radio>
                                    <RadioGroup.Radio value={2}>Custom</RadioGroup.Radio>
                                </RadioGroup>
                            </Box>
                            {watchScheduleType === 2 && (
                                <Layout>
                                    <Cell span={2}>
                                        <Controller
                                            control={control}
                                            name="schedule_time_amount"
                                            render={({ field }) => (
                                                <NumberInput
                                                    {...field}
                                                    min={0}
                                                    max={365}
                                                    onSelect={(option) =>
                                                        setValue('schedule_time_amount', option.id)
                                                    }
                                                    strict
                                                    placeholder="1"
                                                />
                                            )}
                                        />
                                    </Cell>
                                    <Cell span={3}>
                                        <Controller
                                            control={control}
                                            name="schedule_time_unit"
                                            render={({ field }) => (
                                                <Dropdown
                                                    {...field}
                                                    onSelect={(option) =>
                                                        setValue('schedule_time_unit', option.id)
                                                    }
                                                    placeholder="Days"
                                                    selectedId={watchScheduleSuffix}
                                                    options={campaignTimeTypeOptions}
                                                />
                                            )}
                                        />
                                    </Cell>
                                    <Cell span={3}>
                                        <Controller
                                            control={control}
                                            name="schedule_time_direction"
                                            render={({ field }) => (
                                                <Dropdown
                                                    {...field}
                                                    onSelect={(option) =>
                                                        setValue(
                                                            'schedule_time_direction',
                                                            option.id
                                                        )
                                                    }
                                                    selectedId={watchScheduleExecutionType}
                                                    initialSelectedId={field.value}
                                                    options={prefixTriggerOptions}
                                                />
                                            )}
                                        />
                                    </Cell>
                                </Layout>
                            )}
                        </Card.Content>
                    </Card>
                </Box>
            )}
        </>
    );
};

export const CampaignModal = ({
    event,
    campaign,
    setShown,
    shown,
    onComplete,
    hideTriggerSelection = false,
    hideName = false,
    hideSchedule = false,
}) => {
    const [loading, setLoading] = useState(false);
    const triggers = useAppSelector(selectTriggersList) || [];
    const [trigger, setTrigger] = useState(
        campaign ? triggers.find((t) => t.value === campaign.trigger_type) : triggers[0]
    );

    const id = campaign?.id;

    const dispatch = useAppDispatch();

    const { handleSubmit, watch, control, setValue, formState, getValues, reset } =
        useForm<Campaign>({
            defaultValues: {
                ...campaign,
                schedule_time_unit: campaign?.schedule_time_unit || 'MINUTES',
                schedule_time_amount: campaign?.schedule_time_amount || 0,
                schedule_time_direction: campaign?.schedule_time_direction || 'BEFORE',
                schedule_type: campaign?.schedule_type || 1,
                trigger_type: campaign?.watchTriggerType || 'NEW_ORDER',
                subscribe_all_events:
                    campaign?.subscribe_all_events === undefined
                        ? false
                        : campaign?.subscribe_all_events,
                attendee_selection_type: campaign?.attendee_selection_type || 'automatic',
                email_editor_type: campaign?.email_editor_type || 'basic',
                action_selections: campaign?.action_selections || ['email'],
                zoho_mappings: campaign?.zoho_mappings || {},
                hubspot_mappings: campaign?.hubspot_mappings || {},
                hubspot_workflow_selections: campaign?.hubspot_workflow_selections || [],
                hubspot_action: campaign?.hubspot_action || 'create-update-contact',
                zoho_action: campaign?.zoho_action || 'create-update-contact',
                sms: {
                    message: campaign?.sms?.message,
                },
                twitter: {
                    message: campaign?.sms?.message,
                },
                facebook: {
                    message: campaign?.facebook?.message,
                    pages: campaign?.facebook?.pages,
                    enable_instagram: campaign?.facebook?.enable_instagram,
                },
                linkedin: {
                    message: campaign?.linkedin?.message,
                },
                slack: {
                    message: campaign?.slack?.message,
                },
                constant_contact_action:
                    campaign?.constant_contact_action || 'create-update-contact',
                constant_contact_mappings: campaign?.constant_contact_mappings || {},
                constant_contact_list_ids: campaign?.constant_contact_list_ids || [],
            },
        });

    const onSubmit: SubmitHandler<Campaign> = async (values) => {
        let submitFunc = createCampaignApi;
        if (id) submitFunc = updateCampaignApi;
        setLoading(true);
        await dispatch(
            submitFunc({
                id,
                name: values.name,
                message: values.message || '',
                subject: values.subject,
                from_email: values.from_email,
                schedule_type: values.schedule_type,
                schedule_time_amount: values.schedule_time_amount,
                schedule_time_unit: values.schedule_time_unit,
                subscribe_all_events: values.subscribe_all_events,
                schedule_time_direction: values.schedule_time_direction,
                enabled: values.enabled || false,
                trigger_type: trigger.value,
                event_subscription_criteria: [{ event_id: event.id }],
                attendee_selection_type: values.attendee_selection_type,
                email_editor_type: values.email_editor_type,
                sender_name: values.sender_name,
                action_selections: uniq(values.action_selections),
                hubspot_mappings: values.hubspot_mappings,
                hubspot_workflow_selections: values.hubspot_workflow_selections,
                hubspot_action: values.hubspot_action,
                zoho_action: values.zoho_action,
                zoho_mappings: values.zoho_mappings,
                sms: values.sms,
                twitter: values.twitter,
                slack: values.slack,
                constant_contact_action: values.constant_contact_action,
                constant_contact_mappings: values.constant_contact_mappings,
                constant_contact_list_ids: values.constant_contact_list_ids,
                email_action_ticket_receipt_pdf: values.email_action_ticket_receipt_pdf,
                email_action_ticket_passes_pdf: values.email_action_ticket_passes_pdf,
            })
        );
        setLoading(false);
        setShown(false);
        onComplete();
        reset();
    };

    return (
        <>
            <Modal
                isOpen={shown}
                onRequestClose={() => setShown(false)}
                shouldCloseOnOverlayClick
                screen="desktop"
            >
                <CustomModalLayout
                    width="60%"
                    title={
                        <CustomModalLayout.Title>
                            {campaign?.id ? 'Update' : 'Create'} Email Communication
                        </CustomModalLayout.Title>
                    }
                    removeContentPadding
                    primaryButtonText={
                        <Box gap={1}>
                            {loading && <Loader size="tiny" />}
                            {id ? 'Update' : 'Create'}
                        </Box>
                    }
                    primaryButtonOnClick={() => {
                        handleSubmit(onSubmit)();
                    }}
                    secondaryButtonText="Cancel"
                    secondaryButtonOnClick={() => setShown(false)}
                    onCloseButtonClick={() => setShown(false)}
                >
                    <Box direction="vertical" align="left">
                        {!hideTriggerSelection && (
                            <Box margin={3} direction="vertical" gap={2} width={'60%'}>
                                <Text>Select Trigger</Text>
                                <RadioGroup
                                    selectionArea="always"
                                    selectionAreaPadding="18px"
                                    spacing="12px"
                                    onChange={(e) => {
                                        setTrigger(triggers.find((t) => t.value === e));
                                    }}
                                    value={trigger.value}
                                >
                                    {triggers
                                        .filter((trigger) => trigger.scope === 'event')
                                        .map((trigger, index) => {
                                            return (
                                                <RadioGroup.Radio key={index} value={trigger.value}>
                                                    <Box direction="vertical">
                                                        <Text weight="normal">{trigger.name}</Text>
                                                        <Text size="small">
                                                            {trigger.description}
                                                        </Text>
                                                    </Box>
                                                </RadioGroup.Radio>
                                            );
                                        })}
                                </RadioGroup>
                            </Box>
                        )}
                        <CampaignManage
                            event={event}
                            campaign={campaign}
                            trigger={trigger}
                            watch={watch}
                            control={control}
                            setValue={setValue}
                            formState={formState}
                            getValues={getValues}
                            hideName={hideName}
                            hideSchedule={hideSchedule}
                        />
                    </Box>
                </CustomModalLayout>
            </Modal>
        </>
    );
};
