import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as Icons from 'wix-ui-icons-common';
import SaveSuccessCheck from '../../assets/save-success-check.gif';

import {
    Cell,
    CustomModalLayout,
    Layout,
    Modal,
    Box,
    FormField,
    SectionHelper,
    Loader,
    Button,
    Checkbox,
    Input,
    Text,
} from 'wix-style-react';
import { cancelAndRefundAttendee, fetchOrderApi } from '../../features/attendees-slice';
import { useAppDispatch } from '../../hooks';

export const CancelAndRefundModal = ({ cartId, orderId }) => {
    const [isModalOpened, setModalOpened] = useState(false);
    const [saving, setSaving] = useState(false);
    const [completed, setCompleted] = useState(false);

    const dispatch = useAppDispatch();

    const openModal = () => setModalOpened(true);
    const closeModal = () => setModalOpened(false);

    const { handleSubmit, control, watch } = useForm();

    const watchFullRefund = watch('full_refund');

    const onSubmit: SubmitHandler<any> = async (values) => {
        setSaving(true);
        setCompleted(false);
        await dispatch(
            cancelAndRefundAttendee({
                cart_id: cartId,
                ...values,
            })
        );
        await dispatch(fetchOrderApi(orderId));

        setSaving(false);
        setCompleted(true);
    };

    const renderForm = () => (
        <Box marginBottom={2}>
            <Layout>
                <Cell span={12}>
                    <Box marginBottom={2}>
                        <SectionHelper appearance="warning">
                            You can cancel the order and refund the full amount. If a partial refund
                            is required, go to the payment account (Stripe, PayPal, Square) and
                            perform the refund there.
                        </SectionHelper>
                    </Box>
                </Cell>
                <Cell span={12}>
                    <FormField label="" required>
                        <Controller
                            control={control}
                            name="full_refund"
                            render={({ field }) => (
                                <Checkbox {...field} checked={field.value}>
                                    Full Refund
                                </Checkbox>
                            )}
                        />
                    </FormField>
                </Cell>
                <Cell span={12}>
                    <FormField label="Refund Reason" required>
                        <Controller
                            control={control}
                            name="refund_reason"
                            render={({ field }) => <Input {...field} />}
                        />
                    </FormField>
                </Cell>
            </Layout>
        </Box>
    );

    const renderModalContent = () => (
        <CustomModalLayout
            primaryButtonText={
                saving ? (
                    <Box gap={1}>
                        <Loader size="tiny" /> Processing
                    </Box>
                ) : (
                    'Submit'
                )
            }
            secondaryButtonText="Close"
            onCloseButtonClick={() => {
                setSaving(false);
                setCompleted(false);
                closeModal();
            }}
            primaryButtonOnClick={handleSubmit(onSubmit)}
            secondaryButtonOnClick={closeModal}
            title={`Cancel and Refund`}
            width="600px"
        >
            {completed ? <SuccessText fullRefund={watchFullRefund} /> : renderForm()}
        </CustomModalLayout>
    );

    return (
        <>
            <Button
                onClick={openModal}
                prefixIcon={<Icons.CancelOrder />}
                size="small"
                priority="secondary"
            >
                Cancel and Refund
            </Button>
            <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
                {renderModalContent()}
            </Modal>
        </>
    );
};

const SuccessText = ({ fullRefund }) => {
    return (
        <Box direction="vertical" gap={2} align="center">
            <img src={SaveSuccessCheck} width={100} />
            {fullRefund ? (
                <Text>
                    This order was canceled, and all attendees have been marked as not attending. A
                    full refund has been processed.
                </Text>
            ) : (
                <Text>
                    This order was canceled, and all attendees have been marked as not attending.
                </Text>
            )}
        </Box>
    );
};
