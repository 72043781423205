import { isEmpty, toInteger } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
    Cell,
    CustomModalLayout,
    Layout,
    Modal,
    Button,
    Box,
    FormField,
    Input,
    InputStatus,
    Dropdown,
    Text,
    RadioGroup,
    TimeInput,
    MultiSelect,
    ToggleSwitch,
    Divider,
    NumberInput,
} from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import {
    createEventPromos,
    deleteEventPromoApi,
    selectTicketsList,
    updateEventPromos,
} from '../../../features/events-slice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { EventDatePicker } from './date-picker';
import { DeleteConfirmation } from '../../../components/delete-confirmation';

export const TICKET_LIMIT_OPTIONS = [
    { id: 'unlimited', value: 'Unlimited' },
    { id: 'limited', value: 'Limited to' },
];

function combineDateAndTime(date, time) {
    let hours, minutes;

    if (time === null) {
        // If time is null, use the current time
        const currentDateTime = new Date();
        hours = currentDateTime.getHours();
        minutes = currentDateTime.getMinutes();
    } else {
        // If time is not null, parse it into a moment.js object
        const parsedTime = moment(time);

        // Extract hours and minutes from the moment.js object
        hours = parsedTime.hours();
        minutes = parsedTime.minutes();
    }

    // Combine date and time information to create the final Date object
    const finalDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);

    return finalDate;
}
export const PromoCodeModal = ({ event, promo }) => {
    const [isModalOpened, setModalOpened] = useState(false);
    const [connectedTickets, setConnectedTickets] = useState<any>([]);

    const dispatch = useAppDispatch();
    const tickets = useAppSelector(selectTicketsList);

    const openModal = () => setModalOpened(true);
    const closeModal = () => setModalOpened(false);

    const { handleSubmit, control, formState, setValue, watch } = useForm({
        defaultValues: {
            ticket_limit_selection: 'unlimited',
            start_date_selection: 'now',
            end_date_selection: 'sales_end',
            ...promo,

            date_start: promo?.date_start
                ? moment(promo?.date_start).toDate()
                : moment(event?.startDate).toDate(),
            date_end: promo?.date_end
                ? moment(promo?.date_end).toDate()
                : moment(event?.endDate).toDate(),
        },
    });
    useEffect(() => {
        const fetchData = async () => {
            if (promo) {
                setConnectedTickets(promo?.connected_tickets || []);
            }
        };
        void fetchData();
    }, [promo]);

    const handleOnSelect = (tag) => {
        setConnectedTickets([...connectedTickets, tag.id]);
    };

    const handleOnRemoveTag = (tagId) => {
        const newIds = connectedTickets.filter((currTag) => currTag !== tagId);
        setConnectedTickets(newIds);
    };

    const onSubmit: SubmitHandler<any> = async (values) => {
        const submitFunc = promo ? updateEventPromos : createEventPromos;
        const discount_value = toInteger(values.discount_value);
        const discount_percentage = toInteger(values.discount_percentage);
        const limit_amount = toInteger(values.limit_amount);
        await dispatch(
            submitFunc({
                ...values,
                connected_tickets: connectedTickets,
                event_id: event.id,
                discount_value,
                discount_percentage,
                limit_amount,
                date_start: combineDateAndTime(values.date_start, values.time_start),
                date_end: combineDateAndTime(values.date_end, values.time_end),
            })
        );
        // dispatch(getEventTicketsApi(event.id));

        closeModal();
    };

    const { errors } = formState;

    const watchLimitOption = watch('ticket_limit_selection');
    const watchPromoStartsSelection = watch('start_date_selection');
    const watchPromoEndsSelection = watch('end_date_selection');
    const watchDiscountDollar = watch('discount_value');
    const watchDiscountPercentage = watch('discount_percentage');
    const watchIsShared = watch('is_shared');
    const watchEnableVolumeDiscounts = watch('enable_volume_discounts');

    // setValue('timeStart', getMomentDate(event?.dateStart, event?.timezone));
    // setValue('timeEnd', getMomentDate(event?.dateEnd, event?.timezone));
    const renderForm = () => (
        <Layout>
            <Cell span={7}>
                <FormField label="Code name" required>
                    <Controller
                        control={control}
                        name="name"
                        rules={{
                            required: 'Code name is required!',
                        }}
                        render={({ field }) => (
                            <Input
                                {...field}
                                status={
                                    errors.name?.message ? Input.StatusError : ('' as InputStatus)
                                }
                            />
                        )}
                    />
                </FormField>
            </Cell>
            <Cell span={5}>
                <FormField
                    label="Multi-Event Code"
                    infoContent="When enabled, this promo code will be applicable across all current and future events you create. Disabling this option will restrict the promo code's use to this specific event only."
                >
                    <Controller
                        name="is_shared"
                        control={control}
                        render={({ field }) => {
                            return (
                                <ToggleSwitch
                                    {...field}
                                    onChange={(e) => {
                                        setValue('is_shared', e.target.checked);
                                    }}
                                    checked={watchIsShared}
                                />
                            );
                        }}
                    />
                </FormField>
            </Cell>
            <Cell span={6}>
                <FormField
                    label="Ticket limit"
                    infoContent="Total number of tickets that can be purchased with this code"
                >
                    <Controller
                        control={control}
                        name="ticket_limit_selection"
                        render={({ field }) => (
                            <Dropdown
                                {...field}
                                border="standard"
                                options={TICKET_LIMIT_OPTIONS}
                                selectedId={watchLimitOption}
                                onSelect={(option) =>
                                    setValue('ticket_limit_selection', '' + option.id)
                                }
                            />
                        )}
                    ></Controller>
                </FormField>
            </Cell>
            {watchLimitOption === 'limited' && (
                <>
                    <Cell span={6}>
                        <FormField label="Amount" required>
                            <Controller
                                name="limit_amount"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <Input
                                            {...field}
                                            suffix={<Input.Affix>Tickets</Input.Affix>}
                                            status={
                                                errors.limit_amount?.message
                                                    ? Input.StatusError
                                                    : ('' as InputStatus)
                                            }
                                        />
                                    );
                                }}
                            />
                        </FormField>
                    </Cell>
                </>
            )}
            <Cell span={12}>
                <Text>Discount amount</Text>
                <Box gap={2}>
                    <Controller
                        name="discount_value"
                        control={control}
                        render={({ field }) => {
                            return (
                                <Input
                                    {...field}
                                    value={watchDiscountDollar}
                                    disabled={!isEmpty(watchDiscountPercentage)}
                                    prefix={<Input.Affix>$</Input.Affix>}
                                />
                            );
                        }}
                    />
                    <Box align="center" verticalAlign="middle">
                        or
                    </Box>
                    <Controller
                        name="discount_percentage"
                        control={control}
                        render={({ field }) => {
                            return (
                                <Input
                                    {...field}
                                    value={watchDiscountPercentage}
                                    disabled={!isEmpty(watchDiscountDollar)}
                                    suffix={<Input.Affix>%</Input.Affix>}
                                />
                            );
                        }}
                    />
                </Box>
            </Cell>
            <Cell span={12}>
                <FormField label="Promo code starts">
                    <Controller
                        control={control}
                        name="start_date_selection"
                        rules={{
                            required: 'Trigger type is required!',
                        }}
                        render={({ field }) => (
                            <RadioGroup
                                value={watchPromoStartsSelection}
                                onChange={(e) => {
                                    setValue('start_date_selection', e);
                                }}
                            >
                                <RadioGroup.Radio value={'now'}>
                                    <Text>Now</Text>
                                </RadioGroup.Radio>
                                <RadioGroup.Radio value={'scheduled'}>
                                    <Text>Scheduled time</Text>
                                </RadioGroup.Radio>
                            </RadioGroup>
                        )}
                    />
                </FormField>
                {watchPromoStartsSelection === 'scheduled' && (
                    <Box gap={2} marginTop={2}>
                        <Controller
                            name="date_start"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <EventDatePicker
                                        initialValue={field.value}
                                        propName={'date_start'}
                                        setValue={setValue}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="time_end"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <TimeInput
                                        {...field}
                                        disableAmPm
                                        width="auto"
                                        onChange={(date) => setValue('time_start', date)}
                                    />
                                );
                            }}
                        />
                    </Box>
                )}
            </Cell>
            <Cell span={12}>
                <FormField label="Promo code ends">
                    <Controller
                        control={control}
                        name="end_date_selection"
                        render={({ field }) => (
                            <RadioGroup
                                value={watchPromoEndsSelection}
                                onChange={(e) => {
                                    setValue('end_date_selection', e);
                                }}
                            >
                                <RadioGroup.Radio value={'sales_end'}>
                                    <Text>When ticket sales end</Text>
                                </RadioGroup.Radio>
                                <RadioGroup.Radio value={'scheduled'}>
                                    <Text>Scheduled time</Text>
                                </RadioGroup.Radio>
                            </RadioGroup>
                        )}
                    />
                </FormField>
                {watchPromoEndsSelection === 'scheduled' && (
                    <Box gap={2} marginTop={2}>
                        <Controller
                            name="date_end"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <EventDatePicker
                                        initialValue={field.value}
                                        propName={'date_end'}
                                        setValue={setValue}
                                    />
                                );
                            }}
                        />
                        <Controller
                            name="time_end"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <TimeInput
                                        {...field}
                                        disableAmPm
                                        width="auto"
                                        onChange={(date) => setValue('time_end', date)}
                                    />
                                );
                            }}
                        />
                    </Box>
                )}
            </Cell>

            <Cell span={12}>
                <Divider />
            </Cell>
            <Cell span={12}>
                <FormField
                    label="Enable Volume Discounts"
                    infoContent="Activate to offer discounts for bulk ticket purchases."
                >
                    <Controller
                        name="enable_volume_discounts"
                        control={control}
                        render={({ field }) => {
                            return (
                                <ToggleSwitch
                                    {...field}
                                    onChange={(e) => {
                                        setValue('enable_volume_discounts', e.target.checked);
                                    }}
                                    checked={watchEnableVolumeDiscounts}
                                />
                            );
                        }}
                    />
                </FormField>
            </Cell>
            {watchEnableVolumeDiscounts && (
                <Cell span={12}>
                    <FormField
                        label="Minimum Quantity"
                        infoContent="Specify the minimum number of tickets a customer must purchase to qualify for the volume discount. Adjust this to target specific bulk purchase incentives."
                    >
                        <Controller
                            name="volume_minimum_quantity"
                            control={control}
                            render={({ field }) => {
                                return <NumberInput {...field} />;
                            }}
                        />
                    </FormField>
                </Cell>
            )}
            <Cell span={12}>
                <Divider />
            </Cell>
            <Cell span={12}>
                <FormField
                    label="Apply to Tickets"
                    required
                    infoContent="Apply Promo Code to Select Tickets."
                >
                    <Controller
                        control={control}
                        name="tickets"
                        render={() => (
                            <MultiSelect
                                options={tickets.map((val) => ({
                                    id: val.ticket_uuid,
                                    value: val.name,
                                }))}
                                tags={connectedTickets
                                    .map((selectedOption: any) => {
                                        const ticket = tickets.find(
                                            (t) => t.ticket_uuid === selectedOption
                                        );
                                        if (ticket) {
                                            return { label: ticket.name, id: selectedOption };
                                        }
                                    })
                                    .filter((v) => v)}
                                onSelect={handleOnSelect}
                                onRemoveTag={handleOnRemoveTag}
                                popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                            />
                        )}
                    ></Controller>
                </FormField>
            </Cell>
        </Layout>
    );

    const renderModalContent = () => (
        <CustomModalLayout
            primaryButtonText={promo ? 'Update' : 'Add'}
            secondaryButtonText="Cancel"
            onCloseButtonClick={closeModal}
            primaryButtonOnClick={handleSubmit(onSubmit)}
            secondaryButtonOnClick={closeModal}
            title={`${promo ? 'Update' : 'Add'} promo code`}
            width="600px"
        >
            {renderForm()}
        </CustomModalLayout>
    );

    return (
        <>
            <Box>
                {isEmpty(promo) ? (
                    <Box marginTop={2} marginBottom={2}>
                        <Button size="medium" prefixIcon={<Icons.Promote />} onClick={openModal}>
                            Add Promo Code
                        </Button>
                    </Box>
                ) : (
                    <Box gap={2} direction="horizontal">
                        <Button size="medium" onClick={openModal}>
                            Edit
                        </Button>
                        <DeleteConfirmation
                            buttonText=""
                            placement="top"
                            successClick={() => dispatch(deleteEventPromoApi(promo))}
                        />
                    </Box>
                )}
            </Box>
            <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
                {renderModalContent()}
            </Modal>
        </>
    );
};
