import { Tooltip } from 'wix-style-react';
import { classes } from './ticket-validility.st.css';

export const TicketValid = ({ isValid }) => {
    if (isValid) {
        return (
            <span className={classes.success}>
                <Tooltip content="Valid Ticket: Attendees with this ticket type will have access to the event based on the specified validity duration.">
                    <svg viewBox="0 0 32 32" fill="currentColor" width="32" height="32">
                        <path d="M9,16 C5.13400675,16 2,12.8659932 2,9 C2,5.13400675 5.13400675,2 9,2 C12.8659932,2 16,5.13400675 16,9 C16,12.8659932 12.8659932,16 9,16 Z M10.9393398,7 L8.5,9.43933983 L7.06066017,8 C6.76776695,7.70710678 6.29289322,7.70710678 6,8 C5.70710678,8.29289322 5.70710678,8.76776695 6,9.06066017 L8.5,11.5606602 L12,8.06066017 C12.2928932,7.76776695 12.2928932,7.29289322 12,7 C11.7071068,6.70710678 11.232233,6.70710678 10.9393398,7 Z"></path>
                    </svg>
                </Tooltip>
            </span>
        );
    }

    return (
        <span className={classes.error}>
            <Tooltip content="Expired Ticket: This ticket is marked as expired based on the ticket validity date configuration you have set.">
                <svg viewBox="0 0 32 32" fill="currentColor" width="32" height="32">
                    <path d="M9,16 C5.13400675,16 2,12.8659932 2,9 C2,5.13400675 5.13400675,2 9,2 C12.8659932,2 16,5.13400675 16,9 C16,12.8659932 12.8659932,16 9,16 Z M9,5 C8.44771525,5 8,5.44771525 8,6 L8,8 C8,8.55228475 8.44771525,9 9,9 C9.55228475,9 10,8.55228475 10,8 L10,6 C10,5.44771525 9.55228475,5 9,5 Z M9,10 C8.44771525,10 8,10.4477153 8,11 C8,11.5522847 8.44771525,12 9,12 C9.55228475,12 10,11.5522847 10,11 C10,10.4477153 9.55228475,10 9,10 Z"></path>
                </svg>
            </Tooltip>
        </span>
    );
};
