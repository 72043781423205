import { useState } from 'react';
import { Box, Button, InfoIcon, Loader, Text } from 'wix-style-react';
import { verifySiteDomain } from '../features/site-slice';
import { classes } from './customDomain.st.css';
import { useAppDispatch } from '../hooks';

const VerifyDomainButton = ({ customDomain, site }) => {
    const [isVerifying, setIsVerifying] = useState(false);
    const [isComplete, setIsCompleted] = useState(false);

    const dispatch = useAppDispatch();

    const errorMessage =
        'Your domain has not been verified yet. Please allow a few minutes for DNS changes to propagate and try again.';
    const verifyDomainDNS = async () => {
        setIsVerifying(true);
        setIsCompleted(false);

        await dispatch(verifySiteDomain({ siteId: site.id, customDomain }));

        setIsCompleted(true);

        setIsVerifying(false);
    };

    return (
        <Box direction="vertical">
            <Box width={200}>
                <Button onClick={verifyDomainDNS} disabled={isVerifying}>
                    <Box gap={1}>
                        {isVerifying && <Loader size="tiny" />} Verify Domain{' '}
                        <InfoIcon
                            className={classes.white}
                            content="Verify this domain to confirm you have correctly configured the DNS settings. This ensures your domain is properly linked to the event and landing pages"
                        />
                    </Box>
                </Button>
            </Box>
            <Box margin={1}>
                {isComplete && site.custom_domain_verified_at === null && (
                    <Text skin="error">{errorMessage}</Text>
                )}
            </Box>
        </Box>
    );
};

export default VerifyDomainButton;
