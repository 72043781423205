import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import moment from 'moment-timezone';
import {
    Box,
    Button,
    Cell,
    CustomModalLayout,
    Divider,
    Dropdown,
    FormField,
    Heading,
    Input,
    Layout,
    Loader,
    Modal,
    NumberInput,
    Text,
    ToggleSwitch,
} from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import { classes } from './add-attendee-modal.st.css';

import { createAttendeeApi } from '../../features/attendees-slice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    fetchEventLeafOccurrencesApi,
    getEventTicketsWithoutMetricsApi,
    selectEventOccurrences,
    selectTicketsList,
} from '../../features/events-slice';
import { useSelector } from 'react-redux';
import { API_INSTANCE, buildURL } from '../../features/utils';
import { selectSiteData } from '../../features/account-slice';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

export const AddAttendeeModal = ({ event }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isCompleted, setCompleted] = useState(false);
    const [goToLink, setGoToLink] = useState('');
    const [eventId, setEventId] = useState(event.id);
    const [isModalOpened, setModalOpened] = useState(false);
    const closeModal = () => {
        reset();
        setModalOpened(false);
    };

    const dispatch = useAppDispatch();
    const history = useHistory();
    const tickets = useAppSelector(selectTicketsList) || [];

    const occurrences = useSelector(selectEventOccurrences);

    const [campaigns, setCampaigns] = useState([]);

    const hasNewOrderStarted = campaigns?.some(
        (campaign) => campaign.trigger_type === 'NEW_ORDER' && campaign.enabled
    );
    const site = useSelector(selectSiteData);

    useEffect(() => {
        const fetchCampaigns = async () => {
            const url = `/api/v1/dashboard/campaigns/events/${event.id}`;
            const { data } = await API_INSTANCE.get(buildURL(site, url));
            setCampaigns(data);
        };
        void fetchCampaigns();
    }, [event.id, site]);

    useEffect(() => {
        if (event?.id) {
            dispatch(getEventTicketsWithoutMetricsApi(event.id));
        }
        if (event?.isSeriesParent) {
            dispatch(fetchEventLeafOccurrencesApi(event));
        }
    }, [event?.id]);
    useEffect(() => {
        const occurrences = event?.occurrences?.filter((event) =>
            moment(event.start_date).isSameOrAfter(moment())
        );

        occurrences.length > 0 ? setEventId(occurrences[0].id) : setEventId(event.id);
    }, [event?.occurrences]);

    const { handleSubmit, control, setValue, reset, watch, formState } = useForm<any>({
        defaultValues: {
            ticket_quantity: 1,
        },
    });
    const { errors } = formState;

    const watchOrderConfirmation = watch('initiate_order_confirmation');
    const watchSelectedTicket = watch('selected_ticket_id');

    const onSubmit: SubmitHandler<Event> = async (values) => {
        setIsSaving(true);

        const response = await dispatch(
            createAttendeeApi({
                ...values,
                selected_ticket_id: watchSelectedTicket,
                event_id: eventId,
            })
        );
        let link = '';
        if (response.payload.cart_id) {
            link = `/events/orders/manage/${response.payload.cart_id}`;
        } else {
            link = `/events/attendees/manage/${response.payload.attendees[0].id}`;
        }
        setGoToLink(link);
        setIsSaving(false);
        setCompleted(true);
        // closeModal();
        reset();
    };

    const renderModalContent = () => (
        <CustomModalLayout
            primaryButtonText={
                <Box gap={2}>
                    {isSaving && <Loader size="tiny" />} <Text color={'white'}>Add</Text>
                </Box>
            }
            secondaryButtonText="Cancel"
            onCloseButtonClick={closeModal}
            primaryButtonOnClick={handleSubmit(onSubmit)}
            secondaryButtonOnClick={closeModal}
            title="Add Attendee"
            width="600px"
        >
            {isCompleted ? (
                <Box direction="vertical" gap={2} align="center" marginTop={3}>
                    <Heading appearance="H3">Successfully Created Attendee</Heading>
                    <Box>
                        <Button onClick={() => history.push(buildURL(site, goToLink))}>
                            View Attendee
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Layout gap="18px">
                    <Cell span={12}>
                        <div className={classes.heading}>Basic Information</div>
                    </Cell>
                    {event.isSeriesParent && (
                        <Cell span={12}>
                            <FormField
                                label="Event"
                                infoContent="Select the event occurence you want this attendee to be created in"
                            >
                                <Dropdown
                                    onSelect={(option) => setEventId(option.id)}
                                    selectedId={eventId}
                                    options={occurrences
                                        ?.filter((event) =>
                                            moment(event.start_date).isSameOrAfter(moment())
                                        )
                                        .map((event) => ({
                                            id: event.id,
                                            value: `${moment(event.start_date)
                                                .tz(event.timezone)
                                                .format('ddd DD MMM HH:mm a')}`,
                                        }))}
                                    popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                                />
                            </FormField>
                        </Cell>
                    )}
                    <Cell span={6}>
                        <FormField label="First Name" required>
                            <Controller
                                control={control}
                                name="first_name"
                                rules={{
                                    required: 'First Name is required',
                                }}
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormField>
                        <small className={classes.error}>{errors.first_name?.message}</small>
                    </Cell>
                    <Cell span={6}>
                        <FormField label="Last Name" required>
                            <Controller
                                control={control}
                                name="last_name"
                                rules={{
                                    required: 'Last Name is required',
                                }}
                                render={({ field }) => <Input {...field} />}
                            />
                        </FormField>
                        <small className={classes.error}>{errors.last_name?.message}</small>
                    </Cell>

                    <Cell span={12}>
                        <Box direction="vertical">
                            <FormField label="Email" required>
                                <Controller
                                    control={control}
                                    name="email"
                                    rules={{
                                        required: 'Email is required',
                                        validate: (value = '') => {
                                            return value.trim() !== '';
                                        },
                                    }}
                                    render={({ field }) => <Input {...field} />}
                                />
                            </FormField>
                            <small className={classes.error}>{errors.email?.message}</small>
                        </Box>
                    </Cell>
                    <Cell span={12}>
                        <Divider />
                    </Cell>
                    <Cell span={12}>
                        <div className={classes.heading}>Ticket Selection</div>
                    </Cell>

                    <Cell span={6}>
                        <FormField label="Tickets">
                            <Controller
                                control={control}
                                name="selected_ticket_id"
                                render={({ field }) => (
                                    <Dropdown
                                        {...field}
                                        onSelect={(option) =>
                                            setValue('selected_ticket_id', +option.id)
                                        }
                                        selectedId={watchSelectedTicket}
                                        options={tickets.map((ticket) => ({
                                            id: +ticket.id,
                                            value: ticket.name,
                                        }))}
                                        clearButton
                                        onClear={() => {
                                            setValue('selected_ticket_id', null);
                                        }}
                                        popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                                    />
                                )}
                            />
                        </FormField>
                    </Cell>
                    <Cell span={6}>
                        <FormField label="Ticket Quantity">
                            <Controller
                                control={control}
                                name="ticket_quantity"
                                render={({ field }) => (
                                    <NumberInput
                                        {...field}
                                        min={1}
                                        disabled={!watchSelectedTicket}
                                        max={365}
                                        onSelect={(option) =>
                                            setValue('ticket_quantity', option.id)
                                        }
                                        strict
                                        placeholder="1"
                                    />
                                )}
                            />
                        </FormField>
                    </Cell>
                    <Cell span={12}>
                        <Divider />
                    </Cell>
                    {hasNewOrderStarted && (
                        <>
                            <Cell span={12}>
                                <div className={classes.heading}>Engagement</div>
                            </Cell>
                            <Cell span={12}>
                                <FormField
                                    label="Send Email"
                                    infoContent="A ticket confirmation email will be sent to this attendee."
                                >
                                    <Controller
                                        name="initiate_order_confirmation"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <ToggleSwitch
                                                    {...field}
                                                    onChange={(e) => {
                                                        setValue(
                                                            'initiate_order_confirmation',
                                                            e.target.checked
                                                        );
                                                    }}
                                                    checked={watchOrderConfirmation}
                                                />
                                            );
                                        }}
                                    />
                                </FormField>
                            </Cell>
                        </>
                    )}
                </Layout>
            )}
        </CustomModalLayout>
    );

    return (
        <Box align="right" marginBottom={3}>
            <Button
                prefixIcon={<Icons.AddSmall />}
                size="medium"
                onClick={() => setModalOpened(true)}
            >
                Add Attendee
            </Button>
            <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
                {renderModalContent()}
            </Modal>
        </Box>
    );
};
