import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Dropdown, Heading, Loader } from 'wix-style-react';
import { selectSiteData } from '../../features/account-slice';
import { API_INSTANCE, fullBuildURL } from '../../features/utils';
import type { ResponseEvent } from '../../types';
import { classes } from './organization-selections.st.css';

function OrganizationSelectionComponent({ site, organizations = [] }) {
    const [selectedOrg, setSelectedOrg] = useState(organizations?.find((o) => o.enabled)?.id);

    const handleOrgSelect = useCallback(
        async (value) => {
            setSelectedOrg(value);
            await API_INSTANCE.put<ResponseEvent>(
                fullBuildURL(site, `/api/v1/dashboard/linkedin/pages`),
                {
                    organizationId: value,
                }
            );
        },
        [selectedOrg, site]
    );
    return (
        <Box direction="vertical" gap={2} marginBottom={2}>
            <Heading appearance="H5">Select the page you would like to connect</Heading>
            <Dropdown
                onSelect={(option) => handleOrgSelect(option.id)}
                selectedId={selectedOrg}
                options={organizations.map((org) => ({ id: org.id, value: org.name }))}
            />
        </Box>
    );
}

// export const updateEventbriteUserssApi = createAsyncThunk('eventbrite/update', async (data, { getState }) => {
//     return API_INSTANCE.put<ResponseEvent>(buildURLWithState(getState(), `/api/v1/users`), data)
//         .then((response) => response.data)
//         .catch((error) => error);
// });

export const LinkedinPageSelection = ({ integrations }) => {
    const site = useSelector(selectSiteData);

    const [organizations, setOrganizations] = useState();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        async function fetch() {
            setLoading(true);
            const { data } = await API_INSTANCE.get<ResponseEvent>(
                fullBuildURL(site, `/api/v1/dashboard/linkedin/pages`)
            );
            setOrganizations(data);
            setLoading(false);
        }
        void fetch();
    }, [site]);

    if (!integrations['linkedin']) {
        return <span />;
    }
    if (loading) {
        return <Loader size="tiny" />;
    }
    return (
        <Box marginTop={1}>
            {organizations && (
                <OrganizationSelectionComponent
                    site={site}
                    selectedOrganizationId={integrations['linkedin'].page_organization_id}
                    organizations={organizations}
                />
            )}
        </Box>
    );
};
