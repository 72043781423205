import { cloneElement, useCallback, useState } from 'react';
import { Box, Button } from 'wix-style-react';
import { SettingsHeader } from '../settings-header';
import { classes } from './classes.st.css';
import { getFragmentParams } from '../../modules/settings/utils';

export const Drilldown = ({ label, component, onClick, active }) => {
    if (active === true) {
        return (
            <Box width="100%" direction="vertical">
                {cloneElement(component, {
                    label,
                })}
            </Box>
        );
    }
    return (
        <Box width="100%" align="space-between" className={classes.section}>
            <div className={classes.text}>{label}</div>
            <div className={classes.arrow} onClick={onClick} />
        </Box>
    );
};

export const DrilldownContainer = ({ sections = [], title, infoText = '' }) => {
    const { subfolder } = getFragmentParams(window.location.hash);
    const index = sections.findIndex((section) => section?.id && section?.id === subfolder);
    const [active, setActive] = useState(index === -1 ? null : index);

    const handleClick = useCallback((tabIndex) => {
        setActive(tabIndex);
    }, []);

    if (active !== null) {
        return (
            <Box width="100%" direction="vertical">
                <Box>
                    <Button
                        size="small"
                        onClick={() => setActive(null)}
                        style={{ marginBottom: 20 }}
                    >
                        Back
                    </Button>
                </Box>
                <Box height={10} />
                <Drilldown
                    label={sections[active].label}
                    component={sections[active].component}
                    active
                    onClick={handleClick}
                />
            </Box>
        );
    }
    return (
        <Box width="100%" direction="vertical">
            {title && <SettingsHeader title={title} info={infoText} />}
            {sections
                .filter((s) => s)
                .map((section, index) => (
                    <Drilldown
                        key={index}
                        label={section.label}
                        component={section.component}
                        onClick={() => handleClick(index)}
                        active={false}
                    />
                ))}
        </Box>
    );
};
