import { SettingsHeader } from '../../../../components/settings-header';
import { ColorPicker } from '../color-picker';
import { FontColorPicker } from '../font-picker';

export const CheckoutForm = ({ label }) => {
    return (
        <div>
            <SettingsHeader title={label} />

            <FontColorPicker
                title="Pre-form text"
                startWithSize={14}
                fontUpdateKey="_checkoutPreFormTextFont"
                startWithTheme="font_8"
                colorUpdateKey="_checkoutPreFormTextColor"
                startWithColor="color-5"
                fontMaxSize={40}
            />

            <FontColorPicker
                title="Form labels"
                startWithSize={14}
                fontUpdateKey="_checkoutFormFieldsFont"
                startWithTheme="font_9"
                colorUpdateKey="_checkoutFormFieldsColor"
                startWithColor="color-5"
                fontMaxSize={30}
            />

            <FontColorPicker
                title="Check/Radio values"
                startWithSize={14}
                fontUpdateKey="_checkoutFormCheckFont"
                startWithTheme="font_9"
                colorUpdateKey="_checkoutFormCheckColor"
                startWithColor="color-5"
                fontMaxSize={20}
            />

            <ColorPicker
                title="Form border color"
                colorUpdateKey="_checkoutBorderColor"
                startWithColor="color-5"
                startWithOpacity={0.125}
            />
            <ColorPicker
                title="Background color"
                colorUpdateKey="_checkoutCardBackgroundColor"
                startWithColor="color-1"
                startWithOpacity={1}
            />
        </div>
    );
};
