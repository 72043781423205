import { InputArea, Input, Tooltip } from 'wix-style-react';
import Premium from 'wix-ui-icons-common/Premium';
import { WidgetSettingsUpdater } from '../../../components/widget-settings-updater';

const TRANSLATION_TEXT_DEFAULTS = {
    attendBtnText: 'Register',
    addToCalendarText: 'Add to Calendar',
    nextEventText: 'Next Event',
    onSaleText: 'On Sale',
    postponedText: 'Postponed',
    soldOutText: 'Sold Out',
};

export const TextInput = (props) => {
    const {
        placeholder = '',
        maxLength = 30,
        title = '',
        updateKey,
        tooltipText,
        shouldFetchEvents = false,
        isMultiLine = false,
        noDivider = false,
    } = props;

    return (
        <WidgetSettingsUpdater
            updateKey={updateKey}
            tooltipText={tooltipText}
            shouldFetchEvents={shouldFetchEvents}
            title={title}
            noDivider={noDivider}
        >
            {isMultiLine ? (
                <InputArea placeholder={placeholder} rows={4} />
            ) : (
                <Input
                    placeholder={placeholder}
                    max={maxLength}
                    suffix={
                        tooltipText && (
                            <Tooltip appendTo="window" content={tooltipText}>
                                <Premium />
                            </Tooltip>
                        )
                    }
                />
            )}
        </WidgetSettingsUpdater>
    );
};
