import { useCallback, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/lara-dark-indigo/theme.css';

import {
    Box,
    Card,
    Cell,
    Checkbox,
    FormField,
    Heading,
    Layout,
    MarketingPageLayoutContent,
    SectionHelper,
} from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import Logo from '../../assets/logo.png';
import { classes } from './signup.st.css';
import { useHistory } from 'react-router-dom';
import { API_INSTANCE } from '../../features/utils';

export const Signup = () => {
    const [query, hash] = window.location.href.split('#')[1].split('?');
    const {
        error_type = '',
        email = '',
        first_name = '',
        last_name = '',
    } = Object.fromEntries(new URLSearchParams(hash));
    const history = useHistory();

    return (
        <>
            <Box align="space-between" marginTop={6} width={'80%'} margin={'40px auto'}>
                <Box verticalAlign="middle" gap={3}>
                    <img src={Logo} width={50} /> <Heading appearance="H2">Ticket Spot</Heading>
                </Box>
                <Button
                    className={classes.signUpButton}
                    severity="info"
                    onClick={() => history.push('/auth/login')}
                >
                    Back to Sign Up
                </Button>
            </Box>
            <Box height="840px" verticalAlign="middle" direction="vertical">
                <Box
                    align="center"
                    marginBottom={5}
                    verticalAlign="middle"
                    gap={2}
                    direction="vertical"
                >
                    <Heading appearance="H1">Create Account</Heading>
                    <Heading appearance="H4">7-day free trial. No credit card needed.</Heading>
                </Box>
                {error_type === 'shopify_requires_login' && (
                    <SectionHelper appearance="success">
                        Thank you for installing Ticket Spot on your Shopify Store. Once you log in,
                        please click the <b>&quot;Connect Shopify&quot;</b> button to finalize the
                        installation.
                    </SectionHelper>
                )}
                {error_type === 'ecwid_requires_login' && (
                    <SectionHelper appearance="success">
                        Thank you for installing Ticket Spot on your Ecwid Store. To complete the
                        setup, please ensure you create a Ticket Spot account using the same email
                        address <b>({email})</b> associated with your Ecwid installation.
                    </SectionHelper>
                )}
                <Box align="center" marginBottom={10} verticalAlign="middle">
                    <LoginArea
                        initialEmail={email}
                        initialFirstName={first_name}
                        initialLastName={last_name}
                    />
                </Box>
                <MarketingPageLayoutContent />
            </Box>
        </>
    );
};
export const LoginArea = ({ initialEmail = '', initialFirstName = '', initialLastName = '' }) => {
    const [clicked, setClicked] = useState(false);
    const [email, setEmail] = useState(initialEmail);
    const [firstName, setFirstName] = useState(initialFirstName);
    const [lastName, setLastName] = useState(initialLastName);
    const [password, setPassword] = useState('');
    const [termsCheck, setTermsCheck] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const history = useHistory();
    const handleSignup = useCallback(async () => {
        try {
            setError(null);
            setClicked(true);

            if (!email || !password || !firstName || !lastName) {
                return;
            }
            if (termsCheck === false) {
                setError('Please Accept The Terms.');
                return;
            }
            setLoading(true);

            const response = await API_INSTANCE.post('/api/v1/auth/create-account', {
                email,
                password,
                first_name: firstName,
                last_name: lastName,
            });

            if (response.status === 200) {
                setTimeout(() => {
                    setLoading(false);
                    history.push('/email-verification');
                }, 1500);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            setError(error.response.data);
        }
    }, [email, firstName, history, lastName, password, termsCheck]);
    return (
        <Box align="center" verticalAlign="middle" className={classes.container}>
            <Layout>
                <Cell span={12}>
                    <Layout>
                        <Cell span={6}>
                            <FormField label="First Name">
                                <InputText
                                    size="large"
                                    value={firstName}
                                    className={`p-inputtext-lg ${classes.input}`}
                                    name="first_name"
                                    onChange={(e) => setFirstName(e.target.value)}
                                    invalid={clicked}
                                />
                            </FormField>
                        </Cell>
                        <Cell span={6}>
                            <FormField label="Last Name">
                                <InputText
                                    size="large"
                                    value={lastName}
                                    className={`p-inputtext-lg ${classes.input}`}
                                    name="last_name"
                                    onChange={(e) => setLastName(e.target.value)}
                                    invalid={clicked && !lastName}
                                />
                            </FormField>
                        </Cell>
                        <Cell span={12}>
                            <FormField label="Email">
                                <InputText
                                    size="large"
                                    value={email}
                                    className={`p-inputtext-lg ${classes.input}`}
                                    name="email"
                                    onChange={(e) => setEmail(e.currentTarget.value)}
                                    invalid={clicked && !email}
                                />
                            </FormField>
                        </Cell>
                        <Cell span={12}>
                            <FormField label="Password">
                                <InputText
                                    size="large"
                                    value={password}
                                    className={`p-inputtext-lg ${classes.input}`}
                                    name="password"
                                    onChange={(e) => setPassword(e.currentTarget.value)}
                                    invalid={clicked && !password}
                                    type="password"
                                />
                            </FormField>
                        </Cell>

                        <Cell span={12}>
                            <Box gap={2} marginTop={1}>
                                <Checkbox
                                    hasError={clicked && !termsCheck}
                                    checked={termsCheck}
                                    onChange={(e) => setTermsCheck(e.currentTarget.checked)}
                                >
                                    I agree to the{' '}
                                    <a
                                        href="https://ticketspot.io/terms"
                                        target="_target"
                                        className={classes.link}
                                    >
                                        Terms of Use
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        href="https://ticketspot.io/privacy"
                                        target="_target"
                                        className={classes.link}
                                    >
                                        Privacy Policy
                                    </a>
                                </Checkbox>
                            </Box>
                        </Cell>

                        <Cell span={12}>
                            <Box width="100%" align="center" direction="vertical" gap={1}>
                                <Button
                                    disabled={loading}
                                    size="large"
                                    onClick={() => {
                                        void handleSignup();
                                        setClicked(true);
                                    }}
                                    className={classes.button}
                                    loading={loading}
                                >
                                    Create Account
                                </Button>
                                {error && (
                                    <Box gap={1} className={classes.error}>
                                        <Icons.StatusAlert /> {error}
                                    </Box>
                                )}
                            </Box>
                        </Cell>
                    </Layout>
                </Cell>
            </Layout>
        </Box>
    );
};
