import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
    Cell,
    CustomModalLayout,
    Layout,
    Modal,
    Box,
    FormField,
    Dropdown,
    Text,
    IconButton,
    Tooltip,
    SectionHelper,
    Loader,
} from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import { getEventTicketsWithoutMetricsApi, selectTicketsList } from '../../features/events-slice';
import { updateAttendeeTicketApi } from '../../features/attendees-slice';
import { useAppDispatch, useAppSelector } from '../../hooks';

export const AttendeeTicketEditModal = ({ attendee }) => {
    const [isModalOpened, setModalOpened] = useState(false);
    const [saving, setSaving] = useState(false);

    const event = attendee.event;
    const dispatch = useAppDispatch();

    const openModal = () => setModalOpened(true);
    const closeModal = () => setModalOpened(false);

    const tickets = useAppSelector(selectTicketsList) || [];

    useEffect(() => {
        dispatch(getEventTicketsWithoutMetricsApi(event.id));
    }, [event.id]);

    const { handleSubmit, control, setValue, watch } = useForm({
        defaultValues: {
            selected_ticket: +attendee?.ticket?.id,
        },
    });
    const watchSelectedTicket = watch('selected_ticket');

    const onSubmit: SubmitHandler<any> = async (values) => {
        setSaving(true);
        await dispatch(
            updateAttendeeTicketApi({
                id: attendee.id,
                ticket_id: values.selected_ticket,
            })
        );
        setSaving(false);
        closeModal();
    };

    const renderForm = () => (
        <Layout>
            <Cell span={12}>
                <Box marginBottom={2}>
                    <SectionHelper appearance="warning">
                        Please be aware that this action will only modify the type of your ticket
                        and will not impact the total cost of your order.
                    </SectionHelper>
                </Box>
                <FormField label="Tickets" required>
                    <Controller
                        control={control}
                        name="selected_ticket"
                        rules={{
                            required: 'Property is required',
                        }}
                        render={({ field }) => (
                            <Dropdown
                                {...field}
                                onSelect={(option) => setValue('selected_ticket', +option.id)}
                                selectedId={watchSelectedTicket}
                                options={tickets.map((ticket) => ({
                                    id: +ticket.id,
                                    value: ticket.name,
                                }))}
                                popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                            />
                        )}
                    />
                </FormField>
            </Cell>
        </Layout>
    );

    const renderModalContent = () => (
        <CustomModalLayout
            primaryButtonText={
                saving ? (
                    <Box gap={1}>
                        <Loader size="tiny" /> Updating
                    </Box>
                ) : (
                    'Update'
                )
            }
            secondaryButtonText="Cancel"
            onCloseButtonClick={closeModal}
            primaryButtonOnClick={handleSubmit(onSubmit)}
            secondaryButtonOnClick={closeModal}
            title={`Change Attendee Ticket`}
            width="600px"
        >
            {renderForm()}
        </CustomModalLayout>
    );

    return (
        <>
            {attendee.ticket && (
                <Box gap={2} direction="horizontal">
                    <Text>{attendee.ticket.name}</Text>
                    <Tooltip content="Change Ticket">
                        <IconButton
                            size="tiny"
                            skin="standard"
                            priority="secondary"
                            onClick={openModal}
                        >
                            <Icons.Transfer />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
            <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
                {renderModalContent()}
            </Modal>
        </>
    );
};
