import {
    Page,
    Box,
    Table,
    Card,
    MarketingPageLayout,
    MarketingPageLayoutContent,
    Heading,
    Text,
    Image,
    Layout,
    Cell,
} from 'wix-style-react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    fetchCampaignAnalyticsApi,
    selectSingleCampaignAnalytics,
} from '../../features/campaign-slice';
import type { CampaignStat } from '../../types';
import { useAppDispatch } from '../../hooks';
import { useEffect } from 'react';
import DeliveredIcon from '../../assets/delivered-icon.svg';
import ClicksIcon from '../../assets/clicks-icon.svg';
import OpenedIcon from '../../assets/opened-icon.svg';

const StatCard = ({ type, value = 0, processed = 0, src }) => {
    const percent = (value / processed) * 100;
    return (
        <Box verticalAlign="middle" padding="40px" backgroundColor="D80">
            <Image width="30px" height="30px" src={src} transparent />
            <Box marginLeft="SP10" direction="vertical">
                <Text size="medium" secondary>
                    {value}/{processed} {type}
                </Text>
                <Heading appearance="H3">{+percent.toFixed(0) || 0}%</Heading>
            </Box>
        </Box>
    );
};
const NoStats = () => {
    return (
        <Box verticalAlign="middle" align="center">
            <Card>
                <MarketingPageLayout
                    horizontalSize="medium"
                    verticalSize="medium"
                    content={
                        <Box verticalAlign="middle" align="center" height="360px">
                            <MarketingPageLayoutContent
                                size="medium"
                                title="Nothing here yet"
                                content="Your campaign has no emails delivered yet"
                            />
                        </Box>
                    }
                />
            </Card>
        </Box>
    );
};

export const CampaignStats = () => {
    const { id } = useParams<{ id: string }>();

    const dispatch = useAppDispatch();
    const selectedCampaigns = useSelector(selectSingleCampaignAnalytics) || [];

    useEffect(() => {
        dispatch(fetchCampaignAnalyticsApi(id));
    }, [dispatch]);

    const columns = [
        {
            title: 'Event',
            render: (row: CampaignStat) => row?.event?.title,
        },
        {
            title: 'Processed',
            width: '15%',
            render: (row: CampaignStat) => row?.processed,
        },
        {
            title: 'Delivered',
            width: '15%',
            render: (row: CampaignStat) => row?.delivered,
        },
        {
            title: 'Failed',
            width: '15%',
            render: (row: CampaignStat) => row?.failed,
        },
        {
            title: 'Unique opens',
            width: '15%',
            render: (row: CampaignStat) => row?.unique_opens,
        },
        {
            title: 'Unique clicks',
            width: '10%',
            render: (row: CampaignStat) => row?.unique_clicks,
        },
    ];

    const totalProcessed = selectedCampaigns.reduce((a, b) => a + b.processed, 0);
    const totalDelivered = selectedCampaigns.reduce((a, b) => a + b.delivered, 0);
    const totalUniqueOpens = selectedCampaigns.reduce((a, b) => a + b.unique_opens, 0);
    const totalUniqueClicks = selectedCampaigns.reduce((a, b) => a + b.unique_clicks, 0);
    return (
        <>
            <Page>
                <Page.Header
                    title="Analytics"
                    showBackButton
                    onBackClicked={() => window.history.back()}
                />
                <Page.Content>
                    <Layout>
                        <Cell span={4}>
                            <StatCard
                                type="delivered"
                                value={totalDelivered}
                                processed={totalProcessed}
                                src={DeliveredIcon}
                            />
                        </Cell>
                        <Cell span={4}>
                            <StatCard
                                type="opens"
                                value={totalUniqueOpens}
                                processed={totalProcessed}
                                src={OpenedIcon}
                            />
                        </Cell>
                        <Cell span={4}>
                            <StatCard
                                type="clicks"
                                value={totalUniqueClicks}
                                processed={totalProcessed}
                                src={ClicksIcon}
                            />
                        </Cell>
                    </Layout>
                    <div style={{ background: '#fff' }}></div>
                    <Box height={20} />
                    {selectedCampaigns && selectedCampaigns.length === 0 ? (
                        <NoStats />
                    ) : (
                        <Card>
                            <Card.Header
                                title="Event level analytics"
                                subtitle="Analytics provides details on the performance of your messages"
                            />
                            <Box height={30} />
                            <Table data={selectedCampaigns} columns={columns}>
                                <Table.Content />
                            </Table>
                        </Card>
                    )}
                </Page.Content>
            </Page>
        </>
    );
};
