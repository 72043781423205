import { useState, useEffect, useCallback } from 'react';
import {
    Card,
    RadioGroup,
    Box,
    Text,
    Layout,
    Cell,
    FormField,
    SectionHelper,
    Dropdown,
    Button,
    MultiSelect,
} from 'wix-style-react';
import IntegrationsIcon from 'wix-ui-icons-common/Integrations';
import { isEmpty } from 'lodash';
import { API_INSTANCE, buildURL } from '../../../../features/utils';
import { selectConnectedAccounts, selectSiteData } from '../../../../features/account-slice';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IntegrationNotConnected } from './integration-not-connected';

export const ConstantContactAction = ({ setValue, watch, campaign }) => {
    const watchConstantContactAction = watch(
        'constant_contact_action',
        campaign?.constant_contact_action || 'create-update-contact'
    );
    const watchConstantContactMappings = watch(
        'constant_contact_mappings',
        campaign?.constant_contact_mappings
    );
    const watchConstantContactListIds = watch(
        'constant_contact_list_ids',
        campaign?.constant_contact_list_ids || []
    );

    const connectedAccounts = useSelector(selectConnectedAccounts);
    if (!connectedAccounts.hasOwnProperty('constantContact')) {
        return <IntegrationNotConnected actionName="Constant Contact" />;
    }

    const handleSelection = useCallback(async (id) => {
        setValue('constant_contact_action', id);
    }, []);
    return (
        <Card>
            <Card.Content>
                <Layout>
                    <Cell span={6}>
                        <RadioGroup
                            selectionArea="always"
                            selectionAreaPadding="18px"
                            spacing="12px"
                            value={watchConstantContactAction}
                            onChange={handleSelection}
                        >
                            <RadioGroup.Radio value={'create-update-contact'}>
                                <Box direction="vertical">
                                    <Text weight="normal">Create or Update Contact</Text>
                                    <Text size="small">
                                        Create a new contact or update an existing contact based on
                                        email address.
                                    </Text>
                                </Box>
                            </RadioGroup.Radio>
                        </RadioGroup>
                    </Cell>
                    <Cell span={6}>
                        {watchConstantContactAction === 'create-update-contact' && (
                            <ContactFields
                                setValue={setValue}
                                watchConstantContactListIds={watchConstantContactListIds}
                                watchConstantContactMappings={watchConstantContactMappings}
                            />
                        )}
                    </Cell>
                </Layout>
            </Card.Content>
        </Card>
    );
};

export const MultiListSelector = ({ availableLists, selectedListsIds, setValue }) => {
    const options = availableLists.map(({ list_id, name }) => ({ id: list_id, value: name }));

    const tags = availableLists
        .filter(({ list_id }) => selectedListsIds.includes(list_id))
        .map(({ list_id, name }) => ({ id: list_id, label: name }));

    const handleOnSelect = (tag) =>
        setValue('constant_contact_list_ids', [...selectedListsIds, tag.id]);

    const handleOnRemoveTag = (tagId) =>
        setValue(
            'constant_contact_list_ids',
            selectedListsIds.filter((currTag) => currTag !== tagId)
        );

    return (
        <FormField label="Select Contact lists">
            {' '}
            <MultiSelect
                options={options}
                tags={tags}
                onSelect={handleOnSelect}
                onRemoveTag={handleOnRemoveTag}
            />
        </FormField>
    );
};

export const ContactFields = ({
    setValue,
    watchConstantContactMappings,
    watchConstantContactListIds,
}) => {
    const [ccProps, setCCProps] = useState([]);
    const [contactLists, setContactList] = useState([]);
    const [contractProps, setContractProps] = useState([]);

    const [mappings, setMappings] = useState({});
    const options = contractProps.map((prop) => ({ id: prop, value: prop }));
    const site = useSelector(selectSiteData);

    useEffect(() => {
        const fetchData = async () => {
            const constantContactGetPropsUrl = `/api/v1/constant-contact/contact/properties`;
            const customerGetPropsUrl = `/api/v1/dashboard/customers/contact/properties`;
            const constantContactGetProps = await API_INSTANCE.get(
                buildURL(site, constantContactGetPropsUrl)
            );
            const customerGetProps = await API_INSTANCE.get(buildURL(site, customerGetPropsUrl));
            setCCProps(constantContactGetProps.data);
            setContractProps(customerGetProps.data);
            const defaultMapping = constantContactGetProps.data.reduce(
                (result: any, prop: { name: string }) => {
                    const sameProp = customerGetProps.data.find(
                        (customerProp: string) =>
                            customerProp.replace('_', '') === prop.name.toLowerCase()
                    );
                    if (sameProp) {
                        return { ...result, [prop.name]: sameProp };
                    }
                    return result;
                },
                {}
            );
            const map =
                watchConstantContactMappings && !isEmpty(watchConstantContactMappings)
                    ? watchConstantContactMappings
                    : defaultMapping;
            setValue('constant_contact_mappings', map);
            setMappings(map);
        };
        void fetchData();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            const ccLists = `/api/v1/constant-contact/contact/lists`;
            const { data } = await API_INSTANCE.get(buildURL(site, ccLists));
            setContactList(data);
        };
        void fetchData();
    }, []);

    const handleSelection = useCallback(
        async (ccField, attendeeProp) => {
            const newMapping = {
                ...mappings,
                [ccField.name]: { value: attendeeProp, custom_field_id: ccField.custom_field_id },
            };
            setMappings(newMapping);
            setValue('constant_contact_mappings', newMapping);
        },
        [mappings]
    );
    return (
        <>
            <SectionHelper appearance="success">
                Select the list(s) that you want to sync your contact to
            </SectionHelper>
            <Layout>
                <Cell span={12}>
                    <Box marginTop={4} marginBottom={4} width="100%">
                        <MultiListSelector
                            availableLists={contactLists}
                            selectedListsIds={watchConstantContactListIds}
                            setValue={setValue}
                        />
                    </Box>
                </Cell>
            </Layout>
            <SectionHelper appearance="success">
                Create mapping between your Constant Contact properties and your Contact properties
            </SectionHelper>
            <Layout>
                <Cell span={12}>
                    <Box marginTop={2} direction="vertical">
                        <Layout>
                            {ccProps.map((ccProp) => (
                                <Cell span={6}>
                                    <FormField
                                        label={ccProp.label}
                                        infoContent={
                                            <Box direction="vertical" gap="6px">
                                                <Text light size="small">
                                                    {ccProp.description}
                                                </Text>
                                            </Box>
                                        }
                                    >
                                        <Dropdown
                                            border="round"
                                            options={options}
                                            clearButton
                                            selectedId={mappings[ccProp.name]?.value}
                                            onClear={() => handleSelection(ccProp, undefined)}
                                            onSelect={(option) =>
                                                handleSelection(ccProp, option.id)
                                            }
                                        />
                                    </FormField>
                                </Cell>
                            ))}
                        </Layout>
                    </Box>
                </Cell>
            </Layout>
        </>
    );
};
