import { SettingsHeader, SubSettingsHeader } from '../../../../components/settings-header';
import { ColorPicker } from '../color-picker';
import { FontColorPicker } from '../font-picker';

export const TicketForm = ({ label }) => {
    return (
        <div>
            <SettingsHeader title={label} />

            <SubSettingsHeader title="Ticket Customization" />
            <FontColorPicker
                title="Checkout Section Title"
                startWithSize={14}
                fontUpdateKey="_checkoutSectionFont"
                startWithTheme="font_7"
                colorUpdateKey="_checkoutSectionColor"
                startWithColor="color-5"
                fontMaxSize={20}
            />
            <FontColorPicker
                title="Ticket Name"
                startWithSize={14}
                fontUpdateKey="_checkoutTicketNameFont"
                startWithTheme="font_8"
                colorUpdateKey="_checkoutTicketNameColor"
                startWithColor="color-5"
            />
            <FontColorPicker
                title="Ticket Description"
                startWithSize={16}
                fontUpdateKey="_checkoutTicketDescriptionFont"
                startWithTheme="font_9"
                colorUpdateKey="_checkoutTicketDescriptionColor"
                startWithColor="color-5"
            />
            <FontColorPicker
                title="Ticket Availability"
                startWithSize={16}
                fontUpdateKey="_ticketsAvailabilityFont"
                startWithTheme="font_9"
                colorUpdateKey="_ticketsAvailabilityTextColor"
                startWithColor="color-5"
            />
            <FontColorPicker
                title="Pricing"
                startWithSize={16}
                fontUpdateKey="_checkoutTicketPricingFont"
                startWithTheme="font_8"
                colorUpdateKey="_checkoutTicketPricingColor"
                startWithColor="color-5"
            />
            <ColorPicker
                title="Quantity Selector"
                colorUpdateKey="_checkoutQuantitySelector"
                startWithColor="color-5"
            />

            <ColorPicker
                title="Ticket Background"
                colorUpdateKey="_checkoutTicketBackgroundColor"
                startWithColor="color-6"
            />
            <FontColorPicker
                title="Ticket Summary Header"
                startWithSize={16}
                fontUpdateKey="_checkoutCartSummaryHeaderFont"
                startWithTheme="font_6"
                colorUpdateKey="_checkoutCartSummaryHeaderColor"
                startWithColor="color-5"
            />

            <SubSettingsHeader title="Cart Customization" />
            <ColorPicker
                title="Cart Item Background Color"
                colorUpdateKey="_ticketInformationBackgroundColor"
                startWithColor="color-6"
            />
            <ColorPicker
                title="Cart Item Text Color"
                colorUpdateKey="_ticketInformationTextColor"
                startWithColor="color-5"
            />
            <ColorPicker
                title="Ticket Badge Color"
                colorUpdateKey="_checkoutTicketItemBadge"
                startWithColor="color-5"
            />

            <SubSettingsHeader title="Checkout Customization" />
            <ColorPicker
                title="Checkout Border Color"
                colorUpdateKey="_checkoutBorderColor"
                startWithColor="color-5"
                startWithOpacity={0.125}
            />
            <ColorPicker
                title="Checkout Form Dropdown Color"
                colorUpdateKey="_checkoutDropdownFormTextColor"
                startWithColor="color-5"
            />
            <ColorPicker
                title="Checkout Form Dropdown Background Color"
                colorUpdateKey="_checkoutDropdownFormBackgroundColor"
                startWithColor="color-6"
            />
            <FontColorPicker
                title="Checkout Next Button"
                startWithSize={18}
                fontUpdateKey="_checkoutNextButtonFont"
                startWithTheme="font_8"
                colorUpdateKey="_checkoutNextButtonTextColor"
                startWithColor="color-5"
                fontMaxSize={24}
            />
            <ColorPicker
                title="Checkout Next Button Background"
                colorUpdateKey="_checkoutNextButtonBackgroundColor"
                startWithColor="color-6"
            />
            <FontColorPicker
                title="Checkout Back Button"
                startWithSize={18}
                fontUpdateKey="_checkoutBackButtonFont"
                startWithTheme="font_8"
                colorUpdateKey="_checkoutBackButtonColor"
                startWithColor="color-5"
                fontMaxSize={24}
                startWithOpacity={0.5}
            />
            <ColorPicker
                title="Checkout Back Button Background"
                colorUpdateKey="_checkoutBackButtonBackgroundColor"
                startWithColor="color-6"
            />
            <ColorPicker
                title="Step Background Color"
                colorUpdateKey="_checkoutStepBackgroundColor"
                startWithColor="color-5"
            />
            <ColorPicker
                title="Step Section Color"
                colorUpdateKey="_checkoutStepLabelColor"
                startWithColor="color-1"
            />
            <FontColorPicker
                title="Step Text Color"
                fontUpdateKey="_checkoutStepTextFont"
                startWithTheme="font_8"
                colorUpdateKey="_checkoutStepTextColor"
                startWithColor="color-5"
            />

            <FontColorPicker
                title="Confirmation Text"
                startWithSize={14}
                fontUpdateKey="_checkoutConfirmationFont"
                startWithTheme="font_8"
                colorUpdateKey="_checkoutConfirmationColor"
                startWithColor="color-5"
                fontMaxSize={20}
            />

            <SubSettingsHeader title="Promo Code" />
            <FontColorPicker
                title="Promo Code Text"
                fontUpdateKey="_promoCodeTextFont"
                startWithTheme="font_7"
                colorUpdateKey="_promoCodeTextColor"
                startWithColor="color-5"
            />
            <FontColorPicker
                title="Promo Code Button Text"
                fontUpdateKey="_promoCodeButtonFont"
                startWithTheme="font_8"
                colorUpdateKey="_promoCodeButtonTextColor"
                startWithColor="color-5"
            />
            <ColorPicker
                title="Promo Code Button Background"
                colorUpdateKey="_promoCodeButtonBackgroundColor"
                startWithColor="color-5"
            />
        </div>
    );
};
