import {
    Row,
    Col,
    Box,
    Layout,
    Card,
    NumberInput,
    InfoIcon,
    FormField,
    ToggleSwitch,
    Heading,
    Dropdown,
    Loader,
    Input,
    Text,
    SectionHelper,
} from 'wix-style-react';

import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks';
import {
    getEventTicketsApi,
    selectTicketsIsLoading,
    selectTicketsList,
} from '../../features/events-slice';
import { useAppDispatch } from '../../hooks';
import { classes } from './create-events.st.css';
import { TicketTable } from './components/ticket-table';
import { ConnectPaymentAccount } from './components/connect-payment';
import { PlanNotification } from '../../components/plan-notification';
import { useSelector } from 'react-redux';
import { selectAccount, selectSiteData } from '../../features/account-slice';
import { Controller } from 'react-hook-form';
import { getAllCurrencies } from './currency-utils';
import AppStoreBadge from '../../assets/app-store-badge.svg';
import PlayStoreBadge from '../../assets/play-store-badge.svg';
import { PromoCodeModal } from './components/promo-code-modal';
import { PromoCodeTable } from './components/promo-code-table';
import { selectSite } from '../../features/site-slice';

export const CreateEmbeddedTickets: FC<any> = ({
    setValue,
    getValues,
    control,
    watch,
    event,
    setQuestions,
    isPublicCreation,
}) => {
    const id = event?.id;
    const [paymentDetails, setPaymentDetails] = useState(null);

    const watchCurrency = watch('currency');
    const siteData = useSelector(selectSiteData);
    const isLoading = useSelector(selectTicketsIsLoading);
    const account = useSelector(selectAccount);
    const site = useSelector(selectSite);

    const currencyOptions = getAllCurrencies();

    const dispatch = useAppDispatch();
    const tickets = id ? useAppSelector(selectTicketsList) : [];

    useEffect(() => {
        if (id) {
            dispatch(getEventTicketsApi(id));
        }
    }, [id, siteData.site]);

    return (
        <Box gap={2} direction="vertical">
            <Card>
                <Card.Header
                    title="Sell Tickets"
                    subtitle="Easily Set Up and Sell Tickets Directly to Your Visitors"
                />
                <Card.Divider />
                <Card.Content>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <Layout>
                            <Row>
                                {!isPublicCreation && (
                                    <Col span={3}>
                                        <Box direction="vertical" gap={2}>
                                            <ConnectPaymentAccount
                                                paymentDetails={paymentDetails}
                                                setPaymentDetails={setPaymentDetails}
                                                setValue={setValue}
                                                control={control}
                                            />
                                            <Box gap={2} direction="vertical">
                                                <Heading appearance="H4">
                                                    Attendee Check-In Made Easy with Ticket Spot!
                                                </Heading>
                                                <Text>
                                                    To help us with attendee check-in, we recommend
                                                    downloading the Ticket Spot app and logging in
                                                    using your email: <b>{account?.email}</b>.
                                                    It&apos;s the easiest way to get everyone into
                                                    the event smoothly.
                                                </Text>
                                                <Box gap={3} direction="vertical" align="center">
                                                    <a href="https://apps.apple.com/us/app/ticket-spot-check-in/id6449327292">
                                                        <img src={AppStoreBadge} width={'80%'} />
                                                    </a>{' '}
                                                    <a href="https://play.google.com/store/apps/details?id=io.ticketspot.app">
                                                        <img src={PlayStoreBadge} width={'80%'} />
                                                    </a>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Col>
                                )}
                                <Col span={isPublicCreation ? 12 : 9}>
                                    <Box
                                        direction="vertical"
                                        marginTop="20px"
                                        marginBottom="20px"
                                        padding="20px 29px 20px"
                                        borderRadius="8px"
                                        backgroundColor="white"
                                        width="100%"
                                        boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                                        flexGrow={1}
                                    >
                                        {
                                            <Box marginBottom={3} direction="vertical">
                                                <Heading appearance="H3">Sales Tax</Heading>
                                                <Heading appearance="H5">
                                                    Add Sales Tax to your tickets and it will be
                                                    applied at checkout automatically
                                                </Heading>
                                            </Box>
                                        }
                                        {site?.type !== 'shopify' ? (
                                            <PlanNotification type="business" text="sales tax">
                                                <Box gap={2} marginTop={2}>
                                                    <FormField label="Sales Tax">
                                                        <Controller
                                                            control={control}
                                                            name="salesTax"
                                                            render={({ field }) => (
                                                                <NumberInput
                                                                    {...field}
                                                                    placeholder="0"
                                                                    suffix={
                                                                        <Input.Affix>%</Input.Affix>
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    </FormField>
                                                </Box>
                                            </PlanNotification>
                                        ) : (
                                            <SectionHelper appearance="danger">
                                                Sales Tax settings are managed through Shopify
                                            </SectionHelper>
                                        )}
                                    </Box>

                                    <Box
                                        direction="vertical"
                                        marginTop="20px"
                                        marginBottom="20px"
                                        padding="10px 29px 0px"
                                        borderRadius="8px"
                                        backgroundColor="white"
                                        width="100%"
                                        boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                                        flexGrow={1}
                                    >
                                        <Box marginBottom={3} direction="vertical" marginTop={2}>
                                            <Heading appearance="H3">Tickets</Heading>
                                            <Heading appearance="H5">
                                                Customize Your Tickets: Create Free, Paid, or
                                                Donation Options with Personalized Images and
                                                Adjustable Limits.
                                            </Heading>
                                        </Box>
                                        <Box width={200} marginBottom={3} direction="vertical">
                                            <FormField label={<Box gap={2}>Currency </Box>}>
                                                <Controller
                                                    control={control}
                                                    name="currency"
                                                    render={({ field }) => (
                                                        <Dropdown
                                                            {...field}
                                                            onSelect={(option) =>
                                                                setValue('currency', option.id)
                                                            }
                                                            selectedId={watchCurrency}
                                                            options={currencyOptions}
                                                        />
                                                    )}
                                                />
                                            </FormField>
                                            {event?.seriesId && !event?.isSeriesParent && (
                                                <Box marginTop={4}>
                                                    <FormField
                                                        label="Override Parent Tickets"
                                                        labelPlacement="top"
                                                    >
                                                        <Controller
                                                            control={control}
                                                            name="overrideParentTickets"
                                                            render={({ field }) => {
                                                                return (
                                                                    <ToggleSwitch
                                                                        {...field}
                                                                        checked={field.value}
                                                                    />
                                                                );
                                                            }}
                                                        />
                                                    </FormField>
                                                </Box>
                                            )}
                                        </Box>
                                        <TicketTable
                                            tickets={tickets}
                                            event={event}
                                            site={siteData}
                                            paymentDetails={paymentDetails}
                                            setQuestions={setQuestions}
                                            getParentValues={getValues}
                                            parentWatch={watch}
                                            isPublicCreation={isPublicCreation}
                                        />
                                        {/* <DesignLinkButton
                                            selectorKey="display;checkout_options;checkoutDateFormat"
                                            buttonLabel="Design Ticket Interface"
                                            tooltipContent="Go to the design page to customize ticket checkout options and form labels for a personalized user experience."
                                        /> */}
                                    </Box>
                                    {!isPublicCreation && (
                                        <Box
                                            direction="vertical"
                                            marginTop="20px"
                                            marginBottom="20px"
                                            padding="10px 29px 0px"
                                            borderRadius="8px"
                                            backgroundColor="white"
                                            width="100%"
                                            boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
                                            flexGrow={1}
                                        >
                                            <Box
                                                marginBottom={3}
                                                direction="vertical"
                                                marginTop={2}
                                            >
                                                <Heading appearance="H3">Promo Codes</Heading>
                                                <Heading appearance="H5">
                                                    Create Promo Codes with Fixed or Percentage
                                                    Discounts for Your Entire Ticket Range or Select
                                                    Types, Complete with Custom Usage Limits.
                                                </Heading>
                                            </Box>

                                            <PlanNotification type="business" text="promo codes">
                                                <PromoCodeModal event={event} />
                                                <PromoCodeTable event={event} />
                                            </PlanNotification>
                                        </Box>
                                    )}
                                </Col>
                            </Row>
                        </Layout>
                    )}
                </Card.Content>
            </Card>
        </Box>
    );
};
