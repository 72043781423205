import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import TicketNewOrderIcon from '../../../assets/campaign-icons/new-order.svg';
import EventStartIcon from '../../../assets/campaign-icons/event-started.svg';
import EventEndedIcon from '../../../assets/campaign-icons/event-ended.svg';

import TicketNewOrderGreyIcon from '../../../assets/campaign-icons/new-order-grey.svg';
import EventStartGreyIcon from '../../../assets/campaign-icons/event-started-grey.svg';
import EventEndedGreyIcon from '../../../assets/campaign-icons/event-ended-grey.svg';

import { Box, Tooltip } from 'wix-style-react';
import { API_INSTANCE, buildURL } from '../../../features/utils';
import { selectSiteData } from '../../../features/account-slice';

export const EngagementStats = ({ event }) => {
    const [campaigns, setCampaigns] = useState([]);

    const hasNewOrderStarted = campaigns?.some(
        (campaign) => campaign.trigger_type === 'NEW_ORDER' && campaign.enabled
    );
    const hasEventStartedEnabled = campaigns?.some(
        (campaign) => campaign.trigger_type === 'EVENT_STARTED' && campaign.enabled
    );
    const hasEventEndedEnabled = campaigns?.some(
        (campaign) => campaign.trigger_type === 'EVENT_ENDED' && campaign.enabled
    );
    const site = useSelector(selectSiteData);

    useEffect(() => {
        const fetchCampaigns = async () => {
            const url = `/api/v1/dashboard/campaigns/events/${event.id}`;
            const { data } = await API_INSTANCE.get(buildURL(site, url));
            setCampaigns(data);
        };
        void fetchCampaigns();
    }, [event.id, site]);
    return (
        <Box gap={2} verticalAlign="middle">
            <Box gap={2} align="center" verticalAlign="middle">
                <Box>
                    <Tooltip
                        content={`${
                            hasNewOrderStarted
                                ? 'Order Confirmation Automation Enabled. Attendees will automatically receive an order confirmation upon ticket purchase.'
                                : 'Order Confirmation Automation Not Enabled. Attendees will not receive an automatic order confirmation. Set up now to enhance attendee experience.'
                        }`}
                    >
                        {hasNewOrderStarted ? (
                            <img src={TicketNewOrderIcon} height={28} />
                        ) : (
                            <img src={TicketNewOrderGreyIcon} height={28} />
                        )}
                    </Tooltip>
                </Box>
                <Box>
                    <Tooltip
                        content={`${
                            hasEventStartedEnabled
                                ? 'Event Start Notification Enabled. Attendees will be notified when the event is about to begin.'
                                : 'Event Start Notification Not Enabled. Attendees will not receive a reminder that the event is starting soon. Activate now to keep attendees informed.'
                        }`}
                    >
                        {hasEventStartedEnabled ? (
                            <img src={EventStartIcon} height={28} />
                        ) : (
                            <img src={EventStartGreyIcon} height={28} />
                        )}
                    </Tooltip>
                </Box>
                <Box>
                    <Tooltip
                        content={`${
                            hasEventEndedEnabled
                                ? 'Event Ended Notification Enabled. Attendees will automatically receive a message when the event concludes.'
                                : 'Event Ended Notification Not Enabled. Attendees will not receive a post-event message. Set up now to complete the event experience.'
                        }`}
                    >
                        {hasEventEndedEnabled ? (
                            <img src={EventEndedIcon} height={28} />
                        ) : (
                            <img src={EventEndedGreyIcon} height={28} />
                        )}
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    );
};
