import { SFC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MessageModalLayout, Modal } from 'wix-style-react';
import { selectSiteData } from '../../../features/account-slice';
import { buildURL } from '../../../features/utils';
import SaveSuccess from '../../../assets/save-success.gif';
import SaveError from '../../../assets/save-error.gif';

export const SUCCESS_MESSAGES = {
    savedSuccess: {
        title: 'Changes Saved!',
        message: 'Your event changes have been successfully saved.',
    },
    publishedSuccess: {
        title: 'Event Published!',
        message: 'Congratulations! Your event is now published and available on your event widget.',
    },
    userSubmittedEventSuccess: {
        title: 'Event Successfully Submitted!',
        message: 'Your event has been submitted for review.',
    },
    saveAsDraftSuccess: {
        title: 'Draft Saved!',
        message:
            "Your event has been saved as a draft. It won't appear on your event list unless you update the settings to show draft events.",
    },
    shopifyPublishSuccess: {
        title: 'Published!',
        message: 'Your changes have been successfully published to your Shopify store.',
    },
    ecwidPublishSuccess: {
        title: 'Published!',
        message: 'Your changes have been successfully published to your Ecwid store.',
    },
    error: {
        title: 'Oops, Something Went Wrong!',
        message:
            'We apologize, but it seems that something went wrong. Please correct the errors and try again.',
    },
};
const SaveEventModal: SFC<any> = ({ messageType, message, isPublicCreation }) => {
    const [isModalOpen, setModalOpen] = useState(true);
    const history = useHistory();
    const site = useSelector(selectSiteData);

    return (
        <Modal isOpen={isModalOpen}>
            <MessageModalLayout
                illustration={
                    <img src={messageType === 'error' ? SaveError : SaveSuccess} width={'100%'} />
                }
                title={SUCCESS_MESSAGES[messageType]?.title}
                primaryButtonText={isPublicCreation ? 'Close' : 'My Events'}
                secondaryButtonText={isPublicCreation ? undefined : 'Continue Editing'}
                primaryButtonOnClick={
                    isPublicCreation
                        ? () => {
                              const message = {
                                  type: 'TICKETSPOT_CLOSE_EVENT_CREATION',
                                  data: true,
                              };
                              parent.postMessage(message, '*');
                          }
                        : () => {
                              history.push(buildURL(site, `/events/`));
                              setModalOpen(false);
                          }
                }
                secondaryButtonOnClick={isPublicCreation ? undefined : () => setModalOpen(false)}
            >
                {message || SUCCESS_MESSAGES[messageType]?.message}
            </MessageModalLayout>
        </Modal>
    );
};

export default SaveEventModal;
