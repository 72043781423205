import { Component, FC, useEffect, useState } from 'react';
import UnsplashReact from 'unsplash-react';
import { Button, CustomModalLayout, Modal, SectionHelper } from 'wix-style-react';

class InsertIntoApplicationUploader extends Component {
    componentWillReceiveProps(nextProps) {
        const prevPhoto = this.props.unsplashPhoto;
        const nextPhoto = nextProps.unsplashPhoto;
        if ((prevPhoto && prevPhoto.id) === (nextPhoto && nextPhoto.id)) return;

        nextProps
            .downloadPhoto(nextPhoto)
            .then((r: any) => r.url)
            .then(this.props.onFinishedUploading);
    }

    render() {
        return null;
    }
}

export const UnsplashPhotoPicker: FC<any> = ({ event, account, onChange, buttonText }) => {
    const [isModalOpened, setModalOpened] = useState(false);
    const [photoUrl, handleFinishedUploading] = useState<any>(null);

    const openModal = () => setModalOpened(true);
    const closeModal = () => setModalOpened(false);

    useEffect(() => {
        const fetchData = async () => {
            if (photoUrl) onChange(photoUrl);
        };

        fetchData();
    }, [photoUrl]);
    const renderForm = () => (
        <>
            <UnsplashReact
                accessKey={process.env.UNSPLASH_ACCESS_KEY}
                Uploader={InsertIntoApplicationUploader}
                applicationName={`account_${account?.id}`}
                defaultSearch={event?.title}
                onFinishedUploading={handleFinishedUploading}
                preferredSize={{ width: 600, height: 400 }}
            />
            <img src={photoUrl} />
        </>
    );

    const renderModalContent = () => (
        <CustomModalLayout
            primaryButtonText="Close"
            primaryButtonOnClick={closeModal}
            title="Browse Unsplash"
            width="600px"
        >
            <>
                <SectionHelper appearance="standard">
                    Selecting an image will automatically add it to the photo collection
                </SectionHelper>
                {renderForm()}
            </>
        </CustomModalLayout>
    );

    return (
        <>
            <Button size="small" onClick={openModal} priority="secondary">
                {buttonText || 'Unslash'}
            </Button>
            <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
                {renderModalContent()}
            </Modal>
        </>
    );
};
