import moment from 'moment-timezone';
import { useState, useMemo, useEffect } from 'react';
import * as Icons from 'wix-ui-icons-common';
import { classes } from './time-based-occurrences-table.st.css';
import { Table, TableActionCell, Pagination, Box, Text, Button, Loader } from 'wix-style-react';
import { Badge } from 'primereact/badge';

import CancelOrder from 'wix-ui-icons-common/CancelOrder';
import {
    deleteDateWithTimesEventOccurrenceApi,
    deleteEventApi,
    eventResponseTransform,
    fetchEventOccurrencesApi,
    selectSingleEvent,
    updateEventOccurrenceApi,
} from '../../../features/events-slice';
import { useAppDispatch, useAppSelector } from '../../../hooks';

import type { Event } from '../../../types';
import { capitalize, isEmpty } from 'lodash';
import { EditAddTimeBasedDateEventModel } from './edit-times-modal';

const MAX_PER_PAGE = 5;

export const TimeBasedOccurrences = () => {
    const parentEvent = useAppSelector(selectSingleEvent);
    const [occurrences, setOccurrences] = useState(parentEvent?.occurrences || []);
    const [isTimedEntries, setTimeEntries] = useState(false);

    useEffect(() => {
        setOccurrences(parentEvent?.occurrences);
    }, [parentEvent]);

    const resetBackToParent = () => {
        setOccurrences(parentEvent?.occurrences || []);
        setTimeEntries(false);
    };
    const setTimeEntriesOccurrences = (occurrences) => {
        setOccurrences(occurrences);
        setTimeEntries(true);
    };
    return (
        <OccurrencesTable
            parentEvent={parentEvent}
            occurrences={occurrences}
            setOccurrences={setTimeEntriesOccurrences}
            isTimedEntries={isTimedEntries}
            resetBackToParent={resetBackToParent}
        />
    );
};

const OccurrencesTable = ({
    parentEvent,
    occurrences = [],
    setOccurrences,
    resetBackToParent,
    isTimedEntries,
}) => {
    const [selectedPage, setSelectedPage] = useState(1);
    const [loadingOccurrence, setLoadingOccurrence] = useState(null);

    const events = useMemo(
        () => occurrences.slice((selectedPage - 1) * MAX_PER_PAGE, selectedPage * MAX_PER_PAGE),
        [occurrences, selectedPage]
    );
    const columns = [
        {
            title: `${isTimedEntries ? 'Times' : 'Dates'}`,
            width: '50%',
            render: (row) => {
                let timeFormat = null;
                if (row.id === loadingOccurrence) {
                    return <Loader size="tiny" />;
                }
                if (isTimedEntries) {
                    const startDate = moment(row.start_date).tz(row.timezone).format('HH:mm A');
                    const endDate = moment(row.end_date).tz(row.timezone).format('HH:mm A');
                    timeFormat = `${startDate} - ${endDate}`;
                } else {
                    timeFormat = moment(row.start_date).tz(row.timezone).format('dddd DD MMMM');
                }
                return (
                    <Box gap={2} verticalAlign="middle" className={classes.justify}>
                        <Box gap={2}>
                            <Text>{timeFormat}</Text>

                            <Badge
                                value={`${row?.timed_entries?.length || 0} Times`}
                                severity="warning"
                            ></Badge>
                        </Box>
                        <Box alignItems="center">
                            {!isTimedEntries && (
                                <EditAddTimeBasedDateEventModel
                                    timeEntryParent={eventResponseTransform(row)}
                                    rootEvent={parentEvent}
                                />
                            )}
                        </Box>
                    </Box>
                );
            },
        },
        {
            title: 'Status',
            width: '20%',
            render: (row) => {
                return capitalize(row.status);
            },
        },
        {
            title: 'Tickets Sold',
            width: '10%',
            render: (row) => {
                const attendanceCount = row?.stats?.attendance_count || 0;
                return row.unlimited_capacity
                    ? attendanceCount
                    : `${attendanceCount}/${row.event_capacity}`;
            },
        },
        {
            width: '20%',
            render: (rowData) => {
                return (
                    <TableActionCell
                        primaryAction={primaryViewAction(rowData)}
                        secondaryActions={secondaryActions(rowData)}
                    />
                );
            },
        },
    ];

    const dispatch = useAppDispatch();

    const secondaryActions = (occurrence) =>
        [
            occurrence.status !== 'cancelled'
                ? {
                      icon: <CancelOrder />,
                      text: 'Cancel',
                      onClick: async () => {
                          await dispatch(
                              updateEventOccurrenceApi({
                                  status: 'cancelled',
                                  id: occurrence.id,
                                  series_id: occurrence.series_id,
                                  start_date: occurrence.start_date,
                                  end_date: occurrence.end_date,
                                  status: occurrence.status,
                              })
                          );
                          await dispatch(fetchEventOccurrencesApi(parentEvent));
                      },
                  }
                : {},
            {
                icon: <Icons.Delete />,
                text: `${isTimedEntries ? 'Delete Time' : 'Delete'}`,
                onClick: async () => {
                    setLoadingOccurrence(occurrence.id);
                    if (isTimedEntries) {
                        await dispatch(deleteEventApi(occurrence.id));
                    } else {
                        await dispatch(deleteDateWithTimesEventOccurrenceApi(occurrence));
                    }
                    await dispatch(fetchEventOccurrencesApi(parentEvent));
                    setLoadingOccurrence(null);
                    resetBackToParent();
                },
            },
        ].filter((c) => !isEmpty(c));

    const _handleChange = ({ page, event }) => {
        event.preventDefault();
        setSelectedPage(page);
    };
    const primaryViewAction = (occurrence: Event) => ({
        text: 'View Times',
        skin: 'standard',
        onClick: () => setOccurrences(occurrence.timed_entries),
    });

    const renderEmptyState = () => (
        <Table.EmptyState title="" subtitle={<>No Events Generated!</>}></Table.EmptyState>
    );

    return (
        <Box direction="vertical">
            {isTimedEntries && (
                <Box marginBottom={2}>
                    <Button
                        onClick={resetBackToParent}
                        prefixIcon={<Icons.ArrowLeft />}
                        size="medium"
                    >
                        Back
                    </Button>
                </Box>
            )}

            <Table data={events} columns={columns}>
                <Table.Content />
                {events.length === 0 && renderEmptyState()}
                {events.length > 0 && (
                    <Pagination
                        currentPage={selectedPage}
                        totalPages={Math.ceil(occurrences.length / MAX_PER_PAGE)}
                        onChange={_handleChange}
                    />
                )}
            </Table>
        </Box>
    );
};
