import IntegrationsIcon from 'wix-ui-icons-common/Integrations';
import { capitalize } from 'lodash';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Box, Button, SectionHelper, Text } from 'wix-style-react';
import { buildURL } from '../../../../features/utils';
import { selectSiteData } from '../../../../features/account-slice';

export const IntegrationNotConnected: FC<any> = ({ actionName }) => {
    const history = useHistory();
    const site = useSelector(selectSiteData);

    return (
        <Box width={400} align="center">
            <SectionHelper appearance="danger">
                <Box align="center">
                    <Text weight="bold" size="medium">
                        Your {capitalize(actionName)} account is not connected!
                        <br />
                        Save your automation and then go to integrations and connect your{' '}
                        {capitalize(actionName)} account
                    </Text>
                </Box>
                <Box align="center" marginTop={2}>
                    <Button
                        onClick={() => history.push(buildURL(site, `/events/integrations`))}
                        prefixIcon={<IntegrationsIcon />}
                    >
                        Integrations
                    </Button>
                </Box>
            </SectionHelper>
        </Box>
    );
};
