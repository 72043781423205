export const getFragmentParams = (hash) => {
    const hashParts = hash.split('?');
    if (hashParts.length < 2) {
        return {};
    }
    const params = new URLSearchParams(hashParts[1]);
    const selector = params.get('selector');

    const selectorParts = selector ? selector.split(';') : [];

    let result = {};
    if (selectorParts.length > 0) {
        result.tab = selectorParts[0];
        if (selectorParts.length === 3) {
            result.subfolder = selectorParts[1];
            result.item = selectorParts[2];
        } else if (selectorParts.length === 2) {
            result.item = selectorParts[1];
        }
    }

    return result;
};
