import { SFC, useState } from 'react';
import { MessageModalLayout, Modal } from 'wix-style-react';
import TriggerSuccessGif from '../../assets/trigger-success.gif';

const TriggerSuccess: SFC<any> = () => {
    const [isModalOpen, setModalOpen] = useState(true);

    return (
        <Modal isOpen={isModalOpen}>
            <MessageModalLayout
                illustration={<img src={TriggerSuccessGif} width={'100%'} />}
                title={'Success!'}
                primaryButtonText="Close"
                primaryButtonOnClick={() => setModalOpen(false)}
            >
                The Ticket Confirmations have been successfully dispatched to all attendees in the
                order.
            </MessageModalLayout>
        </Modal>
    );
};

export default TriggerSuccess;
