import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Cell,
    CustomModalLayout,
    Layout,
    Modal,
    Checkbox,
    Button,
    Box,
    Text,
    Loader,
} from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import { selectSiteData } from '../../../features/account-slice';
import { copyEventApi } from '../../../features/events-slice';
import { buildURL } from '../../../features/utils';
import { useAppDispatch } from '../../../hooks';

const checkboxes = [
    {
        id: 'eventProperties',
        label: 'Event properties',
        checked: true,
        disabled: true,
    },
    {
        id: 'tickets',
        label: 'Tickets',
        checked: true,
    },
    {
        id: 'automation',
        label: 'Automation',
        checked: true,
    },
];

export const CopyModal = ({ event }) => {
    const [loading, setLoading] = useState(false);
    const [isModalOpened, setModalOpened] = useState(false);
    const [checkboxSelections, setCheckboxesList] = useState(checkboxes);

    const dispatch = useAppDispatch();
    const history = useHistory();
    const site = useSelector(selectSiteData);

    const openModal = () => setModalOpened(true);
    const closeModal = () => setModalOpened(false);

    const copyInProgress = () => {
        return (
            <Box width="100%" height="100%" align="center">
                <Loader size="medium" text="Copying..." />
            </Box>
        );
    };
    const renderForm = () => (
        <Layout>
            <Cell span={12}>
                <Box marginBottom={2}>
                    <Text weight="bold">What do you want to copy?</Text>
                </Box>
                <Box direction="vertical" gap="12px">
                    {checkboxSelections.map((item) => (
                        <Checkbox
                            id={item.id}
                            key={item.id}
                            checked={item.checked}
                            disabled={item.disabled}
                            onChange={(e) =>
                                setCheckboxesList(
                                    checkboxSelections.map((c) =>
                                        item.id === c.id ? { ...c, checked: e.target.checked } : c
                                    )
                                )
                            }
                        >
                            {item.label}
                        </Checkbox>
                    ))}
                </Box>
            </Cell>
        </Layout>
    );

    const handleCopy = useCallback(async () => {
        setLoading(true);
        const id = event.id;
        const { payload } = await dispatch(
            copyEventApi({
                checkboxSelections: checkboxSelections.filter((c) => c.checked).map((c) => c.id),
                id,
            })
        );
        const path = buildURL(site, `/events/manage/${payload.id}`);
        history.push(path);
    });
    const renderModalContent = () => (
        <CustomModalLayout
            primaryButtonText="Copy"
            primaryButtonProps={{ disabled: loading }}
            secondaryButtonText="Cancel"
            onCloseButtonClick={closeModal}
            primaryButtonOnClick={handleCopy}
            secondaryButtonOnClick={closeModal}
            title={`Copy "${event.title}"`}
            width="600px"
        >
            {loading ? copyInProgress() : renderForm()}
        </CustomModalLayout>
    );

    return (
        <>
            <Button
                size="medium"
                prefixIcon={<Icons.Duplicate />}
                onClick={openModal}
                priority="secondary"
            >
                Copy
            </Button>
            {isModalOpened && (
                <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
                    {renderModalContent()}
                </Modal>
            )}
        </>
    );
};
