import React, { useState } from 'react';
import { Box, Checkbox, CustomModalLayout, Modal } from 'wix-style-react';
import { useAppDispatch } from './hooks';
import { updateAccountApi } from './features/account-slice';

const Description: React.VFC<any> = ({ setChecked, checked }) => {
    return (
        <Box gap={2} direction="vertical" display="inline">
            Get the latest event updates, news, and exclusive offers delivered straight to your
            inbox. By confirming below, you agree to receive emails from Ticket Spot. You can
            unsubscribe at any time. Please see our{' '}
            <a href="https://ticketspot.io/privacy">Privacy Policy</a> for more information.
            <Box gap={2} direction="vertical" marginTop={2}>
                <Checkbox
                    size="medium"
                    onChange={(e) => setChecked(e.target.checked)}
                    checked={checked}
                    key="newsletter-checkbox"
                >
                    Yes, I would like to receive emails from Ticket Spot.
                </Checkbox>
            </Box>
        </Box>
    );
};
export const NewsletterOptIn: React.VFC = ({ account }) => {
    const [shown, setShown] = useState(true);
    const [checked, setChecked] = useState(false);
    const dispatch = useAppDispatch();

    const handleOnSubmit = () => {
        dispatch(updateAccountApi({ ...account, newsletter_opt_in: checked }));
        setShown(false);
    };

    return (
        <Modal
            isOpen={shown}
            onRequestClose={() => setShown(false)}
            shouldCloseOnOverlayClick
            screen="desktop"
        >
            <CustomModalLayout
                primaryButtonText="Update"
                primaryButtonOnClick={() => handleOnSubmit()}
                onCloseButtonClick={() => setShown(false)}
                title="Stay Connected with Ticket Spot"
                width={'40%'}
                content={<Description checked={checked} setChecked={setChecked} />}
            />
        </Modal>
    );
};
