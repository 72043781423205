import { ToggleSwitch } from 'wix-style-react';
import { SettingsHeader } from '../../../components/settings-header';
import { WidgetSettingsUpdater } from '../../../components/widget-settings-updater';
import { selectSettings } from '../../../features/settings-slice';
import { useAppSelector } from '../../../hooks';

export const Visibility = ({ label }) => {
    const settings = useAppSelector(selectSettings);

    return (
        <>
            <SettingsHeader title={label} />

            <hr className="divider-long" />

            <WidgetSettingsUpdater
                updateKey="showHeader"
                title="Display Widget Header"
                tooltipText="Choose whether to include a header at the top of the widget."
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                title="Display Attend Button"
                updateKey="removeAttendButton"
                invertResult
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
            <WidgetSettingsUpdater title="Show Event Title" updateKey="showEventTitle">
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater updateKey="showEventLogo" isPremium title="Show Event Logo">
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater updateKey="showZoomHostInfo" title="Show Zoom Host Details">
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="showEventStatus"
                isPremium
                tooltipText="Enable the display of the event status, indicating whether it's 'on sale' or 'postponed'."
                title="Show Event Status"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="showOnlineText"
                isPremium
                tooltipText="Choose whether to display a label indicating that the event is online when it is indeed an online event."
                title="Display Online Event Label"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="keepEventsUntilEndTime"
                tooltipText="Display your event until its end time."
                title="Show Until End"
                shouldFetchEvents
                resizeWidget
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="showVenue"
                title="Display Venue Name"
                tooltipText={'Show only the name of the venue'}
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            {settings.showVenue && (
                <WidgetSettingsUpdater
                    updateKey="showCity"
                    isPremium
                    tooltipText="Add the city to the venue name"
                    title="Display City"
                >
                    <ToggleSwitch />
                </WidgetSettingsUpdater>
            )}

            <WidgetSettingsUpdater
                updateKey="showFullAddress"
                title="Display Full Address"
                tooltipText="Replace the venue name with its complete address"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            <WidgetSettingsUpdater
                updateKey="buttonRounded"
                isPremium
                noDivider
                title="Button Corners"
                tooltipText="Choose whether to display the attend button with rounded or square corners."
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
        </>
    );
};
