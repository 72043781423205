import {
    Row,
    Col,
    Box,
    FormField,
    TimeInput,
    Checkbox,
    Dropdown,
    Cell,
    Layout,
    CheckToggle,
    Text,
    InfoIcon,
    SectionHelper,
    Divider,
    Card,
    Radio,
} from 'wix-style-react';

import { Controller } from 'react-hook-form';

import { classes } from './create-events.st.css';
import moment from 'moment-timezone';

import { Occurrences } from './components/occurrences-table';

import { EventDatePicker } from './components/date-picker';
import { useEffect } from 'react';
import OneTimeIcon from '../../assets/dates-time/one-time-event.svg';
import TimeBasedIcon from '../../assets/dates-time/time-based.svg';
import RecurringIcon from '../../assets/dates-time/recurring.svg';
import MultiDayIcon from '../../assets/dates-time/multi-day.svg';
import DateTBDIcon from '../../assets/dates-time/date-tbd.svg';
import { AddMultiDayEventModel } from './components/add-multi-day-event-modal';
import { getMomentDate } from './create-event';
import { AddTimeBasedDateEventModel } from './components/add-times-modal';
import { TimeBasedOccurrences } from './components/time-based-occurrences-table';

const options = [
    { id: 'daily', value: 'Daily' },
    { id: 'weekly', value: 'Weekly' },
    { id: 'monthly', value: 'Monthly' },
    { id: 'yearly', value: 'Yearly' },
];

export const EVENT_FORMATS = {
    RECURRING: 'recurring',
    ONE_TIME: 'oneTime',
    MULTI_DAY: 'multiDay',
    DATE_TBD: 'noDateInMind',
    SINGLE_DATE_MULTIPLE_TIME: 'singleDateMultipleTimes',
    TIME_BASED: 'timeBased',
};

const FormatRadio = ({ watchEventFormat, isDisabled, setValue, formatType, formatLabel, icon }) => {
    return (
        <Box
            verticalAlign="middle"
            boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
            padding={3}
        >
            <Radio
                checked={watchEventFormat === formatType}
                disabled={isDisabled}
                onChange={() => setValue('eventFormat', formatType)}
                label={
                    <Box direction="horizontal" gap={2} align="center" verticalAlign="middle">
                        <Text weight="normal">{formatLabel}</Text>
                        {icon && <img src={icon} width={30} />}
                    </Box>
                }
            />
        </Box>
    );
};

export const EventDateTimeSelection = ({ event, watch, control, setValue, errors, getValues }) => {
    const watchRepeatingEvents = watch('repeat', false);
    const watchAllDayEvent = watch('allDayEvent', false);
    const watchEventFormat = watch('eventFormat', EVENT_FORMATS.ONE_TIME);
    const watchDateTBD = watch('dateTBD', false);
    const watchTimeStart = getMomentDate(event?.dateStart, event?.timezone);
    const watchTimeEnd = getMomentDate(event?.dateEnd, event?.timezone);
    const watchRepeatOn = watch('repeatOn', event?.repeatOn || 'daily');

    const isDisabled =
        (event && event.repeat && event.isSeriesParent && event.hasAttendees) || watchDateTBD;
    const isDisabledWithoutDateTBD =
        event && event.repeat && event.isSeriesParent && event.hasAttendees;

    useEffect(() => {
        setValue('isMultiDay', false);
        setValue('repeat', false);
        setValue('dateTBD', false);
        setValue('isTimeBased', false);

        if (watchEventFormat === EVENT_FORMATS.DATE_TBD) {
            setValue('dateTBD', true);
            return;
        }

        if (watchEventFormat === EVENT_FORMATS.RECURRING) {
            setValue('repeat', true);
            return;
        }
        if (watchEventFormat === EVENT_FORMATS.MULTI_DAY) {
            setValue('isMultiDay', true);
            setValue('repeat', false);
            return;
        }
        if (watchEventFormat === EVENT_FORMATS.TIME_BASED) {
            setValue('isTimeBased', true);
            return;
        }
    }, [setValue, watchEventFormat]);
    useEffect(() => {
        if (event) {
            let format;

            if (event.repeat) {
                format = EVENT_FORMATS.RECURRING;
            } else if (event.isMultiDay) {
                format = EVENT_FORMATS.MULTI_DAY;
            } else if (event.dateTBD) {
                format = EVENT_FORMATS.DATE_TBD;
            } else if (event.isMultiTime) {
                format = EVENT_FORMATS.SINGLE_DATE_MULTIPLE_TIME;
            } else if (event.isTimeBased) {
                format = EVENT_FORMATS.TIME_BASED;
            } else {
                format = EVENT_FORMATS.ONE_TIME;
            }

            setValue('eventFormat', format);
        } else {
            setValue('eventFormat', EVENT_FORMATS.ONE_TIME);
        }
    }, [event]);

    return (
        <Box
            direction="vertical"
            verticalAlign="space-between"
            marginTop="50px"
            margin={'0 auto'}
            marginBottom="50px"
            padding="24px 29px 27px"
            borderRadius="8px"
            backgroundColor="white"
            height="100%"
            width="90%"
            boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
            flexGrow={1}
        >
            <Card.Header
                title="Dates and Times"
                subtitle="Choose the right format for your event: One-Time, Recurring, Multi-Day, Time-Based or Undecided Date. Each option is tailored to fit the specific structure and scheduling needs of your event."
            />
            <Box gap={2}>
                {/* <DesignLinkButton
                    selectorKey="display;date;dateFormat"
                    buttonLabel="Set Date/Time Presentation"
                    tooltipContent={
                        'Modify how dates and times are formatted and presented on the event widget.'
                    }
                /> */}
                {/* <DesignLinkButton
                    selectorKey="display;occurrences;expandRecurringEvents"
                    buttonLabel="Configure Occurrences Display"
                    tooltipContent={
                        'Visit the design page to choose how multiple event occurrences are organized and displayed.'
                    }
                /> */}
            </Box>
            <Card.Divider />
            <>
                <div className={classes.margin}>
                    <Box direction="vertical">
                        {isDisabledWithoutDateTBD && (
                            <Box marginTop={3} marginBottom={3}>
                                <SectionHelper appearance="warning">
                                    Changes to dates or times are not permissible once there are
                                    confirmed attendees.
                                </SectionHelper>
                            </Box>
                        )}
                        <Box direction="horizontal" gap={4} marginBottom={4} marginTop={3}>
                            <FormatRadio
                                watchEventFormat={watchEventFormat}
                                isDisabled={!!(!event?.isSeriesParent && event?.seriesId)}
                                setValue={setValue}
                                formatType={EVENT_FORMATS.ONE_TIME}
                                formatLabel="One-Time Event"
                                icon={OneTimeIcon}
                            />
                            <FormatRadio
                                watchEventFormat={watchEventFormat}
                                isDisabled={!!(!event?.isSeriesParent && event?.seriesId)}
                                setValue={setValue}
                                formatType={EVENT_FORMATS.RECURRING}
                                icon={RecurringIcon}
                                formatLabel={
                                    <>
                                        Recurring{' '}
                                        <InfoIcon content="Select this if your event happens repeatedly on specific dates. Ideal for weekly classes, monthly meetings, or annual conferences. This option lets you set a schedule for regular occurrences of your event." />
                                    </>
                                }
                            />
                            <FormatRadio
                                watchEventFormat={watchEventFormat}
                                isDisabled={!!(!event?.isSeriesParent && event?.seriesId)}
                                setValue={setValue}
                                formatType={EVENT_FORMATS.DATE_TBD}
                                formatLabel="No Date In Mind"
                                icon={DateTBDIcon}
                            />
                            <FormatRadio
                                watchEventFormat={watchEventFormat}
                                isDisabled={!!(!event?.isSeriesParent && event?.seriesId)}
                                setValue={setValue}
                                formatType={EVENT_FORMATS.MULTI_DAY}
                                icon={MultiDayIcon}
                                formatLabel={
                                    <>
                                        Specific Dates{' '}
                                        <InfoIcon
                                            content={`Use this for events spanning multiple days, such as festivals, conferences, or sports tournaments. This option lets you create and detail each day's events separately below.`}
                                        />
                                    </>
                                }
                            />
                            <FormatRadio
                                watchEventFormat={watchEventFormat}
                                setValue={setValue}
                                formatType={EVENT_FORMATS.TIME_BASED}
                                icon={TimeBasedIcon}
                                formatLabel={
                                    <>
                                        Time-Based{' '}
                                        <InfoIcon
                                            content={`Use this for events requiring registration for particular time slots, such as classes, fitness sessions, or guided tours. This option allows attendees to select and book their preferred times, ensuring a structured schedule and avoiding overbooking. It's ideal for events where capacity is limited and specific timing is crucial for participation.`}
                                        />
                                    </>
                                }
                            />
                        </Box>
                        {/* {watchRepeatingEvents && (
                            <FormField
                                label="Multi-Session Event"
                                labelPlacement="right"
                                stretchContent={false}
                                infoContent="Enable this option for events that span multiple sessions, allowing attendees access to all sessions within the event series."
                            >
                                <Controller
                                    control={control}
                                    name="multiSessionEvent"
                                    render={({ field }) => (
                                        <ToggleSwitch
                                            checked={field.value}
                                            onChange={(e) =>
                                                setValue('multiSessionEvent', e.target.checked)
                                            }
                                        />
                                    )}
                                ></Controller>
                            </FormField>
                        )} */}
                    </Box>
                </div>

                {['oneTime', 'recurring'].includes(watchEventFormat) && (
                    <>
                        <Box marginLeft={5} marginRight={5}>
                            <Divider direction="vertical" />
                        </Box>
                        <Row>
                            <Col span={12}>
                                <Box marginBottom={2}>
                                    <FormField>
                                        <Controller
                                            control={control}
                                            name="allDayEvent"
                                            render={({ field }) => (
                                                <Checkbox
                                                    disabled={isDisabled}
                                                    size="medium"
                                                    {...field}
                                                    onChange={(e) =>
                                                        setValue('allDayEvent', e.target.checked)
                                                    }
                                                    checked={field.value}
                                                >
                                                    All Day Event
                                                </Checkbox>
                                            )}
                                        ></Controller>
                                    </FormField>
                                </Box>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={7}>
                                        <div className={classes.margin}>
                                            <FormField label="Start">
                                                <Controller
                                                    control={control}
                                                    name="dateStart"
                                                    render={({ field }) => (
                                                        <EventDatePicker
                                                            initialValue={field.value}
                                                            propName={'dateStart'}
                                                            isDisabled={isDisabled}
                                                            setValue={setValue}
                                                            onChange={(date) => {
                                                                if (date > getValues('dateEnd')) {
                                                                    setValue('dateEnd', date);
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                ></Controller>
                                            </FormField>
                                        </div>
                                    </Col>
                                    <Col span={5}>
                                        <div className={classes.margin}>
                                            <FormField label="Start Time (24 hrs)">
                                                <Controller
                                                    name="timeStart"
                                                    control={control}
                                                    render={({ field }) => {
                                                        return (
                                                            <TimeInput
                                                                {...field}
                                                                disableAmPm
                                                                width="auto"
                                                                dashesWhenDisabled
                                                                defaultValue={watchTimeStart}
                                                                disabled={
                                                                    isDisabled || watchAllDayEvent
                                                                }
                                                                onChange={(date) =>
                                                                    setValue('timeStart', date)
                                                                }
                                                            />
                                                        );
                                                    }}
                                                />
                                            </FormField>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Col span={7}>
                                        <div className={classes.margin}>
                                            <FormField label="Until">
                                                <Controller
                                                    control={control}
                                                    name="dateEnd"
                                                    rules={{
                                                        validate: () => {
                                                            const dateStartVal = moment(
                                                                getValues('dateStart')
                                                            ).format('L');
                                                            const dateEndVal = moment(
                                                                getValues('dateEnd')
                                                            ).format('L');
                                                            const isValid = moment(
                                                                dateStartVal
                                                            ).isAfter(moment(dateEndVal));
                                                            return !isValid;
                                                        },
                                                    }}
                                                    render={({ field }) => (
                                                        <EventDatePicker
                                                            {...field}
                                                            isDisabled={isDisabled}
                                                            initialValue={field.value}
                                                            propName={'dateEnd'}
                                                            setValue={setValue}
                                                            onChange={(date) => {
                                                                if (date < getValues('dateStart')) {
                                                                    setValue('dateStart', date);
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                ></Controller>
                                            </FormField>
                                        </div>
                                    </Col>

                                    <Col span={5}>
                                        <div className={classes.margin}>
                                            <FormField label="End Time (24 hrs)">
                                                <Controller
                                                    name="timeEnd"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TimeInput
                                                            {...field}
                                                            disableAmPm
                                                            dashesWhenDisabled
                                                            defaultValue={watchTimeEnd}
                                                            width="auto"
                                                            disabled={
                                                                isDisabled || watchAllDayEvent
                                                            }
                                                            onChange={(date) =>
                                                                setValue('timeEnd', date)
                                                            }
                                                        />
                                                    )}
                                                />
                                            </FormField>
                                        </div>
                                    </Col>
                                </Row>

                                {errors.dateEnd && (
                                    <small className={classes.error}>
                                        Start date cannot be after the end date
                                    </small>
                                )}
                            </Col>
                            {['recurring'].includes(watchEventFormat) && (
                                <Col span={12}>
                                    <Box marginTop={3} />
                                    {watchRepeatingEvents && (
                                        <Controller
                                            control={control}
                                            name="repeatOn"
                                            render={({ field }) => (
                                                <Dropdown
                                                    {...field}
                                                    border="standard"
                                                    placeholder="Daily"
                                                    options={options}
                                                    selectedId={watchRepeatOn || 'daily'}
                                                    disabled={isDisabled}
                                                    onSelect={(option) =>
                                                        setValue('repeatOn', '' + option.id)
                                                    }
                                                />
                                            )}
                                        ></Controller>
                                    )}

                                    {watchRepeatingEvents && watchRepeatOn === 'weekly' && (
                                        <>
                                            <Box marginTop={2}>On these days</Box>

                                            <Box marginTop={2}>
                                                <Layout cols={7}>
                                                    <Cell span={1}>
                                                        <FormField label="Su" labelPlacement="top">
                                                            <Controller
                                                                control={control}
                                                                name="repeat_day_sunday"
                                                                render={({ field }) => {
                                                                    return (
                                                                        <CheckToggle
                                                                            {...field}
                                                                            disabled={isDisabled}
                                                                            checked={field.value}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </FormField>
                                                    </Cell>
                                                    <Cell span={1}>
                                                        <FormField label="Mo" labelPlacement="top">
                                                            <Controller
                                                                control={control}
                                                                name="repeat_day_monday"
                                                                render={({ field }) => (
                                                                    <CheckToggle
                                                                        {...field}
                                                                        disabled={isDisabled}
                                                                        checked={field.value}
                                                                    />
                                                                )}
                                                            />
                                                        </FormField>
                                                    </Cell>
                                                    <Cell span={1}>
                                                        <FormField label="Tu" labelPlacement="top">
                                                            <Controller
                                                                control={control}
                                                                name="repeat_day_tuesday"
                                                                render={({ field }) => (
                                                                    <CheckToggle
                                                                        {...field}
                                                                        disabled={isDisabled}
                                                                        checked={field.value}
                                                                    />
                                                                )}
                                                            />
                                                        </FormField>{' '}
                                                    </Cell>
                                                    <Cell span={1}>
                                                        <FormField label="We" labelPlacement="top">
                                                            <Controller
                                                                control={control}
                                                                name="repeat_day_wednesday"
                                                                render={({ field }) => (
                                                                    <CheckToggle
                                                                        {...field}
                                                                        disabled={isDisabled}
                                                                        checked={field.value}
                                                                    />
                                                                )}
                                                            />
                                                        </FormField>{' '}
                                                    </Cell>
                                                    <Cell span={1}>
                                                        <FormField label="Th" labelPlacement="top">
                                                            <Controller
                                                                control={control}
                                                                name="repeat_day_thursday"
                                                                render={({ field }) => (
                                                                    <CheckToggle
                                                                        {...field}
                                                                        disabled={isDisabled}
                                                                        checked={field.value}
                                                                    />
                                                                )}
                                                            />
                                                        </FormField>{' '}
                                                    </Cell>
                                                    <Cell span={1}>
                                                        <FormField label="Fi" labelPlacement="top">
                                                            <Controller
                                                                control={control}
                                                                name="repeat_day_friday"
                                                                render={({ field }) => (
                                                                    <CheckToggle
                                                                        {...field}
                                                                        disabled={isDisabled}
                                                                        checked={field.value}
                                                                    />
                                                                )}
                                                            />
                                                        </FormField>{' '}
                                                    </Cell>
                                                    <Cell span={1}>
                                                        <FormField label="Sa" labelPlacement="top">
                                                            <Controller
                                                                control={control}
                                                                name="repeat_day_saturday"
                                                                render={({ field }) => (
                                                                    <CheckToggle
                                                                        {...field}
                                                                        disabled={isDisabled}
                                                                        checked={field.value}
                                                                    />
                                                                )}
                                                            />
                                                        </FormField>{' '}
                                                    </Cell>
                                                </Layout>
                                            </Box>
                                        </>
                                    )}
                                </Col>
                            )}
                        </Row>
                    </>
                )}

                {watchRepeatingEvents && (
                    <Row>
                        <Col span={12}>
                            <Occurrences id={event?.id} />
                        </Col>
                    </Row>
                )}
                {watchEventFormat === EVENT_FORMATS.MULTI_DAY && (
                    <Row>
                        <Col span={12}>
                            <AddMultiDayEventModel parentEvent={event} />
                            <Occurrences id={event?.id} />
                        </Col>
                    </Row>
                )}
                {watchEventFormat === EVENT_FORMATS.TIME_BASED && (
                    <Row>
                        <Col span={12}>
                            <AddTimeBasedDateEventModel parentEvent={event} />
                            <TimeBasedOccurrences id={event?.id} />
                        </Col>
                    </Row>
                )}
            </>
        </Box>
    );
};
