import { map } from 'lodash';
import { useCallback, useState } from 'react';
import { Box, FormField, InputArea, ToggleSwitch } from 'wix-style-react';
import { selectSettings } from '../../../features/settings-slice';
import { useAppSelector } from '../../../hooks';
import { classes } from './filter.st.css';

const placeholderMapping = {
    name: 'party, weekend',
    summary: 'free training, gym membership',
    tags: 'free event, promotion',
    long_description: 'free training, gym membership',
    venue_name: 'Walmart, Club Fun',
    location: '50 Oakland Ave, 123 miller street',
    city: 'San Diego, Del Mar',
    region: 'CA, MA, NV',
    short_locale: 'en, fr, it, es',
    online_event: false,
    in_person_event: true,
};

const titleMapping = {
    online_event: 'Online Event',
    in_person_event: 'In-Person Event',
    name: 'Name',
    summary: 'Summary',
    tags: 'Tags',
    long_description: 'Description',
    venue_name: 'Venue Name',
    location: 'Address',
    city: 'City',
    region: 'Region',
    short_locale: 'Language',
};

const typeMapping = {
    name: 'string',
    summary: 'string',
    tags: 'string',
    long_description: 'string',
    venue_name: 'string',
    location: 'string',
    city: 'string',
    region: 'string',
    short_locale: 'string',
    online_event: 'boolean',
    in_person_event: 'boolean',
};

const properties = Object.keys(titleMapping);

export const EventFilter = ({ onChange }) => {
    const settings = useAppSelector(selectSettings);

    const [filterQueries, setFilterQueries] = useState<any>(settings.filterQueries || []);

    const handleChange = useCallback(
        (name, ev, type) => {
            const newValue = ev.target.value;
            const filterQueries = settings.filterQueries || [];
            const index = filterQueries.findIndex((filter) => filter && filter.name === name);
            let newFilterQueries = filterQueries;
            if (index === -1) {
                newFilterQueries = filterQueries.concat([{ query: newValue, name }]);
            } else {
                newFilterQueries = filterQueries.map((val, i) =>
                    i === index ? { ...val, [type]: newValue } : val
                );
            }
            setFilterQueries(newFilterQueries);
            onChange(newFilterQueries);
        },
        [filterQueries]
    );
    const handleSliderChange = (name, ev, type) => {
        const newValue = ev.checked;

        const filterQueries = settings.filterQueries;
        const index = filterQueries.findIndex((filter) => filter && filter.name === name);
        const result =
            index === -1
                ? { filterQueries: filterQueries.concat({ query: newValue, name }) }
                : {
                      filterQueries: filterQueries.map((val, i) =>
                          i === index ? { ...val, [type]: newValue } : val
                      ),
                  };
        onChange(result.filterQueries);
    };

    return (
        <>
            {map(properties, (prop) => {
                const values = filterQueries.find((filter) => filter && filter.name === prop) || {};
                if (typeMapping[prop] === 'string') {
                    return (
                        <Box marginBottom={2} marginTop={3}>
                            <FormField label={titleMapping[prop]} className={classes.selection}>
                                <InputArea
                                    placeholder={placeholderMapping[prop]}
                                    value={values.query}
                                    onChange={(val) => handleChange(prop, val, 'query')}
                                    onBlur={() => onChange(filterQueries)}
                                />
                            </FormField>
                        </Box>
                    );
                }
                if (typeMapping[prop] === 'boolean') {
                    return (
                        <Box marginBottom={2} marginTop={3}>
                            <FormField label={titleMapping[prop]}>
                                <ToggleSwitch
                                    onChange={(ev) => handleSliderChange(prop, ev.target, 'query')}
                                    checked={values.query || false}
                                />
                            </FormField>
                        </Box>
                    );
                }
                return <span />;
            })}
        </>
    );
};
