import getSymbolFromCurrency from 'currency-symbol-map';
import { useSelector } from 'react-redux';

import { Loader, StatisticsWidget } from 'wix-style-react';

import { selectAttendeeStats, selectAttendeesStatsIsLoading } from '../../features/attendees-slice';

export const EventAttendeeStatsWidget = ({ event }) => {
    const stats = useSelector(selectAttendeeStats);
    const isLoading = useSelector(selectAttendeesStatsIsLoading);

    const currencySymbol = getSymbolFromCurrency(event?.currency);
    if (!stats) return <span />;
    const formattedAmount = (value) =>
        new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2, // Ensure two decimal places
        }).format(value || 0);
    return (
        <StatisticsWidget
            items={[
                {
                    value: isLoading ? <Loader size="tiny" /> : <>{stats.attendance_count || 0}</>,
                    description: 'Attendees',
                },
                {
                    value: isLoading ? <Loader size="tiny" /> : <>{stats.order_count || 0}</>,
                    description: 'Orders',
                },
                {
                    value: isLoading ? (
                        <Loader size="tiny" />
                    ) : (
                        <>{formattedAmount(stats?.total_sold_amount)}</>
                    ),
                    description: `Sold Amount (${currencySymbol})`,
                },
                {
                    value: isLoading ? (
                        <Loader size="tiny" />
                    ) : (
                        <>{formattedAmount(stats?.total_refund_amount)}</>
                    ),
                    description: `Refund Amount (${currencySymbol})`,
                },
                {
                    value: isLoading ? (
                        <Loader size="tiny" />
                    ) : (
                        <>{formattedAmount(stats?.total_tax_amount)}</>
                    ),
                    description: `Tax Amount (${currencySymbol})`,
                },
            ]}
        />
    );
};
