import React, { useState, useCallback, useEffect } from 'react';
import cn from 'classnames';

import InfoCircle from 'wix-ui-icons-common/InfoCircle';
import { FaFacebook, FaHubspot, FaLinkedin, FaSlack, FaTwitter } from 'react-icons/fa';
import { chunk, isEmpty } from 'lodash';
import { SiEventbrite, SiWix, SiZoho } from 'react-icons/si';
import { HiCheckCircle } from 'react-icons/hi';
import { BsGear } from 'react-icons/bs';
import { classes } from './integrations.st.css';
import Eventbrite from '../../assets/integrations/small-eventbrite-logo.png';
import GoogleSmall from '../../assets/integrations/small-google-logo.png';
import TicketTailor from '../../assets/integrations/small-ticket-tailor-logo.png';
import OvationTix from '../../assets/integrations/small-audience-view-logo.png';
import TicketSpice from '../../assets/integrations/small-ticket-spice-logo.png';
import RegFox from '../../assets/integrations/small-regfox-logo.png';
import Zoom from '../../assets/integrations/small-zoom-logo.png';
import Twitch from '../../assets/integrations/small-twitch-logo.png';
import ZoomLogo from '../../assets/integrations/zoom-camera.png';
import TwitchButtonIcon from '../../assets/integrations/twitch-button-icon.png';
import HubSpotButtonIcon from '../../assets/integrations/small-hubspot-logo.png';
import SlackButtonIcon from '../../assets/integrations/small-slack-logo.png';
import FacebookButtonIcon from '../../assets/integrations/facebook-logo.png';
import LinkedinButtonIcon from '../../assets/integrations/linkedin-logo.png';
import WixLogo from '../../assets/integrations/small-wix-logo.png';
import UniverseLogo from '../../assets/integrations/universe-logo.png';

import GoogleImage from '../../assets/integrations/btn_google_signin_dark.png';

import { GoogleCalendars } from './google-calendars';
import { TicketSpiceWidgetHtmlApply } from './ticket-spice-widget-html-apply';
import {
    Button,
    Card,
    Layout,
    Cell,
    Input,
    Box,
    Page,
    Loader,
    ToggleSwitch,
    FormField,
    Heading,
    Divider,
} from 'wix-style-react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { API_INSTANCE, buildURL, fullBuildURL } from '../../features/utils';
import { useSelector } from 'react-redux';
import { selectSiteData } from '../../features/account-slice';
import {
    fetchSettingsApi,
    publishSettingsApi,
    selectSettings,
    updateSettingsApi,
} from '../../features/settings-slice';
import { OrganizationSelection } from './organization-selections';
import { LinkedinPageSelection } from './linkedin-page-selections';

function IntegrationHeader({ title, subtitle }) {
    return (
        <Box marginTop={2} direction="vertical" gap={1}>
            <Heading appearance="H3">{title}</Heading>
            <Heading appearance="H5">{subtitle}</Heading>
            <Divider skin="success" />
        </Box>
    );
}
function Integations(props) {
    const { integrations, fetchAccount, disconnect } = props;

    const chunkIntegrations = chunk(props.integrationsData, 3);

    return (
        <div className={classes.integrationContainer}>
            {chunkIntegrations.map((chunkIntegration, index) => (
                <Layout className={classes.container} key={index}>
                    {chunkIntegration.map((integration, i) => (
                        <Cell span={4} key={i}>
                            <IntegrationCard
                                integration={integration}
                                integrations={integrations}
                                fetchAccount={fetchAccount}
                                disconnect={disconnect}
                                {...props}
                            />
                        </Cell>
                    ))}
                </Layout>
            ))}
        </div>
    );
}

const EnableListEvents = ({ settings }) => {
    const [checked, setChecked] = useState<boolean>(!settings.showCustomEventsOnly);
    const dispatch = useAppDispatch();
    const handleSwitch = useCallback(
        async (value) => {
            setChecked(value);
            await dispatch(updateSettingsApi({ showCustomEventsOnly: !value }));
        },
        [dispatch]
    );

    return (
        <FormField
            label="List Zoom Events?"
            labelPlacement="right"
            infoContent="Use Zoom to list events or generate a meeting/webinar link in your event?"
        >
            <ToggleSwitch checked={checked} onChange={(e) => handleSwitch(e.target.checked)} />
        </FormField>
    );
};
const IntegrationCard = ({ integration, integrations = {}, disconnect, fetchAccount }) => {
    const [openConfigure, setOpenConfigure] = useState(false);
    const [extraSelected, setExtraSelected] = useState(false);
    const settings = useAppSelector(selectSettings);
    return (
        <Card showShadow className={classes.cardHeader}>
            {integrations[integration.type] && integration.listEvents && !isEmpty(settings) && (
                <Card.Header suffix={<EnableListEvents settings={settings} />}></Card.Header>
            )}
            <Card.Content>
                <div>
                    {!openConfigure && (
                        <>
                            <h5 className={classes.header}>
                                <div>
                                    <img
                                        src={integration.image}
                                        alt={integration.name}
                                        className={classes.logo}
                                    />
                                </div>
                                <div className={classes.title}>{integration.name}</div>
                            </h5>
                        </>
                    )}

                    <>
                        {integrations[integration.type] && (
                            <div className={classes.badge}>
                                {openConfigure ? (
                                    <Box marginLeft={2} marginTop={2}>
                                        <Button
                                            size="tiny"
                                            onClick={() => {
                                                setOpenConfigure(false);
                                                setExtraSelected(false);
                                            }}
                                        >
                                            Back
                                        </Button>
                                    </Box>
                                ) : (
                                    <HiCheckCircle style={{ fill: '#569e9d' }} />
                                )}
                            </div>
                        )}
                    </>
                </div>
                {integration.configure &&
                    openConfigure &&
                    React.cloneElement(extraSelected ? integration.extra : integration.configure)}

                <div className={classes.cardConnection}>
                    {integration.information &&
                        !openConfigure &&
                        React.cloneElement(integration.information)}

                    {integration.extra && !openConfigure && integrations[integration.type] && (
                        <div style={{ textAlign: 'center' }}>
                            <UI.button
                                label={integration.extraText}
                                className="btn-md"
                                onClick={() =>
                                    this.setState({
                                        openConfigure: true,
                                        extraSelected: true,
                                    })
                                }
                            />
                        </div>
                    )}
                    {!openConfigure && (
                        <div style={{ marginBottom: 20 }}>
                            <div className={classes.action}>
                                {!integrations[integration.type] &&
                                    React.cloneElement(integration.component, {
                                        launchPopup: launchPopup,
                                        fetchAccount: fetchAccount,
                                        ...integration,
                                    })}
                                {integrations[integration.type] && (
                                    <Disconnect
                                        disconnect={disconnect}
                                        fetchAccount={fetchAccount}
                                        userIdProperty={integration.userIdProperty}
                                    />
                                )}
                                {integration.configure && integrations[integration.type] && (
                                    <BsGear
                                        className={classes.configure}
                                        onClick={() => setOpenConfigure(true)}
                                    />
                                )}
                            </div>
                            {integrations[integration.type] && (
                                <span>
                                    <div className={classes.connectedAs}>Connected as</div>
                                    <div className={classes.email}>
                                        {integrations[integration.type]['emailAddress']}
                                    </div>
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </Card.Content>
        </Card>
    );
};

export const IntegationsContainer = () => {
    const dispatch = useAppDispatch();

    const [integrations, setIntegrations] = useState({});
    // const [showModal, setShowModal] = useState(false);
    const site = useSelector(selectSiteData);

    const disconnect = useCallback(async (key, value) => {
        await API_INSTANCE.put(buildURL(site, `/api/v1/config/disconnect`), {
            [key]: value,
        });
    }, []);

    const handleConnectIntegrations = useCallback(
        async (initial) => {
            const url = `/api/v1/connected-accounts`;
            const { data } = await API_INSTANCE.get(buildURL(site, url));
            setIntegrations(data);
            // !initial && setShowModal(true);
        },
        [site]
    );
    useEffect(() => {
        let isMounted = true; // Flag to check if the component is still mounted

        const startPolling = async () => {
            if (!isMounted) return; // Prevent polling if the component is unmounted

            await handleConnectIntegrations(); // Call handleConnectIntegrations initially to update the account status

            setTimeout(startPolling, 2000); // Wait for 2 seconds before polling again
        };

        startPolling();

        return () => {
            isMounted = false; // Set isMounted to false when the component unmounts
        };
    }, [handleConnectIntegrations]);

    useEffect(async () => {
        await dispatch(fetchSettingsApi());
    }, [dispatch]);
    useEffect(() => {
        handleConnectIntegrations(true);
    }, [handleConnectIntegrations]);

    const publishOnClick = useCallback(async () => {
        await dispatch(publishSettingsApi());
    }, [dispatch]);

    const conferenceIntegrations = [
        {
            name: 'Google',
            image: GoogleSmall,
            type: 'google',
            userIdProperty: 'googleId',
            component: <ConnectGoogleButton />,
            configure: <GoogleCalendars />,
        },
        {
            name: 'Zoom',
            image: Zoom,
            type: 'zoom',
            listEvents: true,
            information: (
                <div className={classes.information} style={{ paddingBottom: 10 }}>
                    <InfoCircle style={{ marginLeft: 10 }} />
                    <a
                        href="https://docs.geteventviewer.com/integrations/zoom"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Documentation
                    </a>{' '}
                </div>
            ),
            userIdProperty: 'zoomUserId',
            component: <ConnectZoomButton />,
        },
    ];
    const liveSreamIntegration = [
        {
            name: 'Twitch',
            image: Twitch,
            type: 'twitch',
            userIdProperty: 'twitchUserId',
            component: <ConnectTwitchButton />,
        },
    ];
    const crmIntegration = [
        {
            name: 'HubSpot',
            image: HubSpotButtonIcon,
            type: 'hubspot',
            userIdProperty: 'hubSpotUserId',
            component: <ConnectHubSpotButton />,
        },
        {
            name: 'Zoho',
            image: HubSpotButtonIcon,
            type: 'zoho',
            userIdProperty: 'zohoUserId',
            component: <ConnectZoHoButton />,
        },
        {
            name: 'Constant Contact',
            image: HubSpotButtonIcon,
            type: 'constantContact',
            userIdProperty: 'constantContactUserId',
            component: <ConnectConstantContact />,
        },
    ];
    const promotionIntegration = [
        {
            name: 'Slack',
            image: SlackButtonIcon,
            type: 'slack',
            userIdProperty: 'slackUserId',
            component: <ConnectSlackButton />,
        },
        {
            name: 'Twitter',
            image: SlackButtonIcon,
            type: 'twitter',
            userIdProperty: 'twitterUserId',
            component: <ConnectTwitterButton />,
        },
        {
            name: 'Facebook',
            image: FacebookButtonIcon,
            type: 'facebook',
            userIdProperty: 'facebookUserId',
            component: <ConnectFacebookButton />,
        },
        {
            name: 'Linkedin',
            image: LinkedinButtonIcon,
            type: 'linkedin',
            userIdProperty: 'linkedinUserId',
            component: <ConnectLinkedinButton />,
            configure: <LinkedinPageSelection integrations={integrations} />,
        },
    ];
    const eventIntegrationsData: any[] = [
        {
            name: 'Eventbrite',
            image: Eventbrite,
            type: 'eventbrite',
            userIdProperty: 'userId',
            component: <ConnectEventbriteButton />,
            configure: <OrganizationSelection integrations={integrations} />,
        },
        {
            name: 'Wix Events',
            image: WixLogo,
            type: 'wixEvents',
            userIdProperty: 'wixEventsUserId',
            component: <ConnectWixEventsButton />,
        },
        {
            name: 'Ticket Tailor',
            type: 'ticketTailor',
            image: TicketTailor,
            userIdProperty: 'ticketTailorUserId',
            information: (
                <div className={classes.information}>
                    <InfoCircle style={{ marginLeft: 10 }} />
                    <a
                        href="https://docs.geteventviewer.com/integrations/ticket-tailor"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Generate an API key
                    </a>{' '}
                    and add it below.
                </div>
            ),
            component: (
                <InputButton
                    integrationType="ticket-tailor"
                    fetchAccount={handleConnectIntegrations}
                />
            ),
        },
        {
            name: 'Ovation Tix',
            type: 'ovationTix',
            image: OvationTix,
            userIdProperty: 'ovationTixUserId',
            information: (
                <div className={classes.information}>
                    <InfoCircle style={{ marginLeft: 10 }} />
                    <span className={classes.informationText}>Enter your Client ID below</span>
                </div>
            ),
            component: (
                <InputButton
                    integrationType="ovation-tix"
                    fetchAccount={handleConnectIntegrations}
                />
            ),
        },
        {
            name: 'Reg Fox',
            type: 'regFox',
            image: RegFox,
            userIdProperty: 'regFoxUserId',
            information: (
                <div className={classes.information}>
                    <InfoCircle style={{ marginLeft: 10 }} />
                    <a
                        href="https://docs.geteventviewer.com/integrations/reg-fox"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Generate an API key
                    </a>{' '}
                    and add it below.
                </div>
            ),
            component: (
                <InputButton integrationType="reg-fox" fetchAccount={handleConnectIntegrations} />
            ),
        },
        {
            name: 'Ticket Spice',
            type: 'ticketSpice',
            image: TicketSpice,
            userIdProperty: 'ticketSpiceUserId',
            configure: <TicketSpiceWidgetHtmlApply integrations={integrations} />,
            information: (
                <div className={classes.information}>
                    <InfoCircle style={{ marginLeft: 10 }} />
                    <a
                        href="https://docs.geteventviewer.com/integrations/ticket-spice"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Generate an API key
                    </a>{' '}
                    and add it below.
                </div>
            ),
            component: (
                <InputButton
                    integrationType="ticket-spice"
                    fetchAccount={handleConnectIntegrations}
                />
            ),
        },
        {
            name: 'Event Universe',
            type: 'eventUniverse',
            image: UniverseLogo,
            userIdProperty: 'universeUserId',
            component: <ConnectEventUniverse />,
        },
    ];

    return (
        <Page height="100vh">
            <Page.Header
                title="Integrations"
                subtitle="Connect your favorite apps to easily display events, broadcast live stream and sync with your CRM"
                actionsBar={
                    window.brand !== 'wix' && <Button onClick={publishOnClick}>Publish</Button>
                }
            />
            <Page.Content>
                {/* {showModal && <IntegrationSuccess onClose={() => setShowModal(false)} />} */}
                <Layout>
                    <Cell>
                        <IntegrationHeader
                            title="Events and Tickets"
                            subtitle="Connect your event application to display your upcoming events"
                        />
                    </Cell>
                    <Cell>
                        <Integations
                            fetchAccount={handleConnectIntegrations}
                            disconnect={disconnect}
                            integrations={integrations}
                            integrationsData={eventIntegrationsData}
                        />
                    </Cell>
                </Layout>
                <Layout>
                    <Cell>
                        <IntegrationHeader
                            title="Promotions"
                            subtitle="Promote your event and send out notifications"
                        />

                        <Integations
                            fetchAccount={handleConnectIntegrations}
                            disconnect={disconnect}
                            integrations={integrations}
                            integrationsData={promotionIntegration}
                        />
                    </Cell>
                </Layout>

                <Layout>
                    <Cell>
                        <IntegrationHeader
                            title="Conferencing"
                            subtitle="Manage all your virtual events in one calendar"
                        />

                        <Integations
                            fetchAccount={handleConnectIntegrations}
                            disconnect={disconnect}
                            integrations={integrations}
                            integrationsData={conferenceIntegrations}
                        />
                    </Cell>
                </Layout>
                <Layout>
                    <Cell>
                        <IntegrationHeader
                            title="Live Stream"
                            subtitle="Broadcast your live stream to all your visitors"
                        />

                        <Integations
                            fetchAccount={handleConnectIntegrations}
                            disconnect={disconnect}
                            integrationsData={liveSreamIntegration}
                            integrations={integrations}
                        />
                    </Cell>
                </Layout>
                <Layout>
                    <Cell>
                        <IntegrationHeader
                            title="CRM"
                            subtitle="Sync contacts and enroll to specific worklows on your CRM"
                        />

                        <Integations
                            fetchAccount={handleConnectIntegrations}
                            disconnect={disconnect}
                            integrationsData={crmIntegration}
                            integrations={integrations}
                        />
                    </Cell>
                </Layout>
            </Page.Content>
        </Page>
    );
};

function Disconnect({ disconnect, userIdProperty, fetchAccount }) {
    const [loading, setLoading] = useState(false);
    const logout = useCallback(async () => {
        setLoading(true);
        await disconnect(userIdProperty, null);
        await fetchAccount(true);
        setLoading(false);
    }, [disconnect, fetchAccount, userIdProperty]);
    return (
        <span onClick={logout} className={classes.disconnect}>
            {loading && <Loader size="tiny" />} Disconnect
        </span>
    );
}

function ConnectGoogleButton(props) {
    const site = useSelector(selectSiteData);

    const { launchPopup, fetchAccount } = props;
    return (
        <div>
            <img
                src={GoogleImage}
                alt="Connect Google"
                className={classes.imageButton}
                onClick={() => {
                    launchPopup(fullBuildURL(site, `/auth/google`), () => fetchAccount());
                }}
            />
        </div>
    );
}
function MissingIntegration() {
    return (
        <div>
            <p className={classes.missingIntegration}>
                Contact us <a href="mailto:chris@ticketspot.io">chris@ticketspot.io</a> and we can
                add it
            </p>
        </div>
    );
}

function ConnectEventbriteButton(props) {
    const { launchPopup, fetchAccount } = props;
    const site = useSelector(selectSiteData);

    return (
        <div>
            <Button
                className={classes.primaryButton}
                onClick={() => {
                    launchPopup(fullBuildURL(site, `/auth/eventbrite`), () => {
                        fetchAccount();
                    });
                }}
            >
                {' '}
                <SiEventbrite style={{ marginRight: 10 }} />
                <span className={classes.text}>Connect Eventbrite</span>
            </Button>
        </div>
    );
}
function ConnectWixEventsButton(props) {
    const { launchPopup, fetchAccount } = props;
    const site = useSelector(selectSiteData);

    return (
        <div>
            <Button
                className={classes.primaryButton}
                onClick={() => {
                    launchPopup(fullBuildURL(site, `/auth/wix-events`), () => fetchAccount());
                }}
            >
                {' '}
                <SiWix style={{ marginRight: 10 }} />
                <span className={classes.text}>Connect Wix Events</span>
            </Button>
        </div>
    );
}

function ConnectFacebookButton(props) {
    const { launchPopup, fetchAccount } = props;
    const site = useSelector(selectSiteData);

    return (
        <div>
            <Button
                className={cn(classes.primaryButton, classes.facebook)}
                onClick={() => {
                    launchPopup(fullBuildURL(site, `/auth/facebook`), () => fetchAccount());
                }}
            >
                {' '}
                <span className={cn(classes.text, classes.textCenter)}>
                    <FaFacebook className={classes.hubspotImage} />
                    Log in With Facebook
                </span>
            </Button>
        </div>
    );
}
function ConnectLinkedinButton(props) {
    const { launchPopup, fetchAccount } = props;
    const site = useSelector(selectSiteData);

    return (
        <div>
            <Button
                className={cn(classes.primaryButton, classes.linkedin)}
                onClick={() => {
                    launchPopup(fullBuildURL(site, `/auth/linkedin`), () => fetchAccount());
                }}
            >
                <span className={cn(classes.text, classes.textCenter)}>
                    <FaLinkedin className={classes.linkedinImage} />
                    Connect Linkedin
                </span>
            </Button>
        </div>
    );
}
function ConnectZoomButton(props) {
    const { launchPopup, fetchAccount } = props;
    const site = useSelector(selectSiteData);

    return (
        <div>
            <Button
                className={classes.primaryButton}
                onClick={() => {
                    launchPopup(fullBuildURL(site, `/auth/zoom`), () => fetchAccount());
                }}
            >
                {' '}
                <span className={classes.text}>
                    <img
                        src={ZoomLogo}
                        alt="zoom"
                        width="20px"
                        style={{ marginRight: 10, width: 20 }}
                    />
                    Connect Zoom
                </span>
            </Button>
        </div>
    );
}

function ConnectTwitchButton(props) {
    const { launchPopup, fetchAccount } = props;
    const site = useSelector(selectSiteData);

    return (
        <div>
            <Button
                className={classes.primaryButton}
                onClick={() => {
                    launchPopup(fullBuildURL(site, `/auth/twitch`), () => fetchAccount());
                }}
            >
                {' '}
                <span className={cn(classes.text, classes.textCenter)}>
                    <img
                        src={TwitchButtonIcon}
                        alt="twitch"
                        width="20px"
                        style={{ marginRight: 10, width: 20 }}
                    />
                    Connect Twitch
                </span>
            </Button>
        </div>
    );
}
function ConnectHubSpotButton(props) {
    const { launchPopup, fetchAccount } = props;
    const site = useSelector(selectSiteData);

    return (
        <div>
            <Button
                className={classes.primaryButton}
                onClick={() => {
                    launchPopup(fullBuildURL(site, `/auth/hubspot`), () => fetchAccount());
                }}
            >
                {' '}
                <span className={cn(classes.text, classes.textCenter)}>
                    <FaHubspot className={classes.hubspotImage} />
                    Connect HubSpot
                </span>
            </Button>
        </div>
    );
}
function ConnectSlackButton(props) {
    const { launchPopup, fetchAccount } = props;
    const site = useSelector(selectSiteData);

    return (
        <div>
            <Button
                className={classes.primaryButton}
                onClick={() => {
                    launchPopup(fullBuildURL(site, `/auth/slack`), () => fetchAccount());
                }}
            >
                {' '}
                <span className={cn(classes.text, classes.textCenter)}>
                    <FaSlack className={classes.hubspotImage} />
                    Connect Slack
                </span>
            </Button>
        </div>
    );
}
function ConnectTwitterButton(props) {
    const { launchPopup, fetchAccount } = props;
    const site = useSelector(selectSiteData);

    return (
        <div>
            <Button
                className={classes.primaryButton}
                onClick={() => {
                    launchPopup(fullBuildURL(site, `/auth/twitter`), () => fetchAccount());
                }}
            >
                {' '}
                <span className={cn(classes.text, classes.textCenter)}>
                    <FaTwitter className={classes.hubspotImage} />
                    Connect Twitter
                </span>
            </Button>
        </div>
    );
}
function ConnectZoHoButton(props) {
    const { launchPopup, fetchAccount } = props;
    const site = useSelector(selectSiteData);

    return (
        <div>
            <Button
                className={classes.primaryButton}
                onClick={() => {
                    launchPopup(fullBuildURL(site, `/auth/zoho`), () => fetchAccount());
                }}
            >
                {' '}
                <span className={cn(classes.text, classes.textCenter)}>
                    <SiZoho className={classes.hubspotImage} />
                    Connect ZoHo
                </span>
            </Button>
        </div>
    );
}

function ConnectConstantContact(props) {
    const { launchPopup, fetchAccount } = props;
    const site = useSelector(selectSiteData);

    return (
        <div>
            <Button
                className={classes.primaryButton}
                onClick={() => {
                    launchPopup(fullBuildURL(site, `/auth/constant-contact`), () => fetchAccount());
                }}
            >
                {' '}
                <span className={cn(classes.text, classes.textCenter)}>
                    <FaHubspot className={classes.hubspotImage} />
                    Connect Constant Contact
                </span>
            </Button>
        </div>
    );
}

function ConnectEventUniverse(props) {
    const { launchPopup, fetchAccount } = props;
    const site = useSelector(selectSiteData);

    return (
        <div>
            <Button
                className={classes.primaryButton}
                onClick={() => {
                    launchPopup(fullBuildURL(site, `/auth/event-universe`), () => fetchAccount());
                }}
            >
                {' '}
                <span className={cn(classes.text, classes.textCenter)}>Connect Event Universe</span>
            </Button>
        </div>
    );
}

export const InputButton = ({ fetchAccount, integrationType }) => {
    const [loading, setLoading] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [error, setError] = useState(false);
    const [value, setValue] = useState('');
    const extra = error
        ? { status: 'error', statusMessage: 'Invalid key', tooltipPlacement: 'top-end' }
        : {};
    const site = useSelector(selectSiteData);

    const handleSave = useCallback(async () => {
        try {
            setLoading(true);
            await API_INSTANCE.post(buildURL(site, `/api/v1/${integrationType}/users`), {
                api_key: value,
            });
            await fetchAccount();
            setLoading(false);
        } catch (e) {
            setError(true);
        }
    }, [value]);

    return (
        <div style={{ marginTop: 40 }}>
            <Input
                onInputClicked={() => setClicked(true)}
                onChange={(e) => setValue(e.currentTarget.value)}
                value={value}
                {...extra}
            />

            {clicked && (
                <Box marginTop={2} align="center">
                    <Button disabled={loading} onClick={() => handleSave()}>
                        {loading && <Loader size="tiny" />}Save
                    </Button>
                </Box>
            )}
        </div>
    );
};

const launchPopup = (url) => {
    const width = window.screen.width * 0.75;
    const height = window.screen.height * 0.75;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    const params = `width=${width}, height=${height}, top=${top}, left=${left}, fullscreen=yes, scrollbars=1`;

    const newWin = window.open(url, 'Ticket Spot', params);
    if (newWin) {
        newWin.focus();
    }
    return false;
};
