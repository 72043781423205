import { useSelector } from 'react-redux';
import {
    FormField,
    Card,
    Layout,
    Cell,
    FileUpload,
    ImageViewer,
    Box,
    Button,
} from 'wix-style-react';
import { GiphyPhotoPicker } from '../../../components/giphy-photo-picker';

import { PlanNotification } from '../../../components/plan-notification';
import { UnsplashPhotoPicker } from '../../../components/unslash-photo-picker';
import { selectAccount, selectSiteData } from '../../../features/account-slice';
import { buildURL } from '../../../features/utils';
import { useHistory } from 'react-router-dom';
import { toBase64 } from '../../../utils';

export const PromotionalImages = ({ event, setValue, watch }) => {
    const watchPromotionalImagesUrls =
        watch('promotional_image_urls', event?.promotional_image_urls || []) || [];
    const watchPromotionalImages = watch('promotional_images', []);
    const account = useSelector(selectAccount);
    const history = useHistory();
    const site = useSelector(selectSiteData);

    return (
        <Box
            boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
            margin={'0 auto'}
            width={'90%'}
        >
            <Card>
                <Card.Header
                    title="Promotional images"
                    subtitle={
                        <>
                            Upload Images or GIFs to Use in the
                            <Box inline marginTop={1} marginLeft={1}>
                                <Button
                                    priority="secondary"
                                    size="small"
                                    onClick={() =>
                                        history.push(buildURL(site, `/events/engage-promote`))
                                    }
                                >
                                    Promotion Scheduling Tool
                                </Button>
                            </Box>
                        </>
                    }
                />
                <Card.Divider />
                <Card.Content>
                    <PlanNotification type="business" text="promotion">
                        <Layout>
                            <Cell span={3}>
                                <FormField>
                                    <FileUpload
                                        multiple
                                        onChange={async (files) => {
                                            const urls = await Promise.all(
                                                Array.from(files).map((file) => toBase64(file))
                                            );
                                            setValue(
                                                'promotional_image_urls',
                                                watchPromotionalImagesUrls
                                                    ? watchPromotionalImagesUrls.concat(urls)
                                                    : urls
                                            );
                                            setValue(
                                                'promotional_images',
                                                watchPromotionalImages.concat(Array.from(files))
                                            );
                                        }}
                                        accept=".jpeg,.gif,.png,.jpg"
                                    >
                                        {({ openFileUploadDialog }) => {
                                            return (
                                                <ImageViewer onAddImage={openFileUploadDialog} />
                                            );
                                        }}
                                    </FileUpload>
                                    <Box marginBottom={4} gap={2} marginTop={2} align="left">
                                        <UnsplashPhotoPicker
                                            event={event}
                                            account={account}
                                            onChange={async (base64Url: string) => {
                                                setValue(
                                                    'promotional_images',
                                                    watchPromotionalImages.concat(base64Url)
                                                );
                                                setValue(
                                                    'promotional_image_urls',
                                                    watchPromotionalImagesUrls
                                                        ? watchPromotionalImagesUrls.concat(
                                                              base64Url
                                                          )
                                                        : [base64Url]
                                                );
                                            }}
                                        />
                                        <GiphyPhotoPicker
                                            event={event}
                                            onChange={async (gif: string) => {
                                                setValue(
                                                    'promotional_images',
                                                    watchPromotionalImages.concat(gif)
                                                );
                                                setValue(
                                                    'promotional_image_urls',
                                                    watchPromotionalImagesUrls
                                                        ? watchPromotionalImagesUrls.concat(gif)
                                                        : [gif]
                                                );
                                            }}
                                        />
                                    </Box>
                                </FormField>
                            </Cell>
                            {watchPromotionalImagesUrls?.map((promotionalImage, index) => {
                                return (
                                    <Cell span={3}>
                                        <ImageViewer
                                            imageUrl={promotionalImage}
                                            showUpdateButton={false}
                                            onRemoveImage={() => {
                                                const newImages = watchPromotionalImagesUrls.filter(
                                                    (_, i) => i !== index
                                                );
                                                const newFiles = watchPromotionalImages.filter(
                                                    (_, i) => i !== index
                                                );
                                                setValue('promotional_image_urls', newImages);
                                                setValue('promotional_images', newFiles);
                                            }}
                                        />
                                    </Cell>
                                );
                            })}
                        </Layout>
                    </PlanNotification>
                </Card.Content>
            </Card>
        </Box>
    );
};
