import { Text, Modal, MessageModalLayout } from 'wix-style-react';
import screen from 'screen-size';
import { useAppDispatch } from '../hooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchAccountApi, selectAccount } from '../features/account-slice';
import { useEffect } from 'react';

const launchPopup = (url, cb) => {
    let params = `width=${screen().x * 0.5}`;
    params += `, height=${screen().y * 0.8}`;
    params += ', top=0, left=0';
    params += ', fullscreen=yes';
    params += ', scrollbars=1';

    const newWin = window.open(url, 'Connect Wix', params);
    if (window.focus) {
        newWin.focus();
    }

    const winTimer = window.setInterval(() => {
        if (newWin.closed !== false) {
            window.clearInterval(winTimer);
            cb();
        }
    }, 200);
    return false;
};

export const WixConnection = () => {
    const dispatch = useAppDispatch();
    const [openModal, setOpenModal] = useState(false);
    const account = useSelector(selectAccount);

    useEffect(() => {
        return () => {
            setOpenModal(!account.has_app_updated);
        };
    }, [account]);

    return (
        <Modal isOpen={openModal}>
            <MessageModalLayout
                primaryButtonText="Update"
                primaryButtonOnClick={() => {
                    const url = `${process.env.API_SERVICE_URL}/auth/wix?in_app=true`;
                    launchPopup(url, () => {
                        dispatch(fetchAccountApi());
                    });
                }}
                title="Update Required"
            >
                <Text>
                    <p>Please update to get the newest version of the app</p>
                </Text>
            </MessageModalLayout>
        </Modal>
    );
};
