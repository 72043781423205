import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Checkbox, Heading, InfoIcon, Popover } from 'wix-style-react';
import { classes } from './create-events.st.css';
import { API_INSTANCE, buildURL } from '../../features/utils';
import { useSelector } from 'react-redux';
import { selectSiteData } from '../../features/account-slice';

export const PublishEcwidButton = ({ handleSubmit, event }) => {
    const [shown, setShown] = useState<boolean>();
    const [collections, setCollections] = useState<any>([]);
    const [savedCategories, setCategories] = useState<any>([]);

    const site = useSelector(selectSiteData);

    useEffect(() => {
        const fetchCurrentCollectionsData = async () => {
            const url = `/ecwid/categories/events/${event?.id}`;
            const { data } = await API_INSTANCE.get(buildURL(site, url));
            setCollections(data);
            setCategories(data.filter(({ enabled }) => enabled));
        };
        void fetchCurrentCollectionsData();
    }, [event?.id, site]);

    const handleChange = useCallback(
        async (collection: any, newValue: boolean) => {
            if (newValue) {
                setCategories(savedCategories.concat(collection));
            } else {
                setCategories(savedCategories.filter(({ id }) => id !== collection.id));
            }
        },
        [savedCategories]
    );

    return (
        <Popover showArrow shown={shown} appendTo="window" placement={'bottom'}>
            <Popover.Element>
                <Button
                    onClick={() => setShown(true)}
                    disabled={event && event.type !== 'eventviewer'}
                >
                    Publish to Ecwid{' '}
                    <Box marginLeft={1} inline>
                        {' '}
                        <InfoIcon
                            size="small"
                            className={classes.info}
                            content="Publish event tickets to your Ecwid store. Only events with tickets will be created."
                        />
                    </Box>
                </Button>
            </Popover.Element>
            <Popover.Content>
                <Box padding={4} direction="vertical">
                    <Box align="center" width={'100%'} direction="vertical">
                        {<Heading appearance="H5">Select Categories</Heading>}
                        <Box direction="vertical" marginTop={2} marginBottom={2}>
                            {collections.length === 0 && (
                                <Checkbox checked disabled>
                                    Front Page
                                </Checkbox>
                            )}
                            {collections.map((collection, index: number) => {
                                return (
                                    <Checkbox
                                        key={index}
                                        checked={
                                            !!savedCategories.find(({ id }) => {
                                                return collection.id === id;
                                            })
                                        }
                                        onChange={(e) => handleChange(collection, e.target.checked)}
                                    >
                                        {collection.title}
                                    </Checkbox>
                                );
                            })}
                        </Box>
                    </Box>
                    <Button
                        onClick={async () => {
                            setShown(false);
                            await handleSubmit(event.status, 'ecwid', {
                                categories: savedCategories,
                            })();
                        }}
                    >
                        Publish
                    </Button>
                </Box>
            </Popover.Content>
        </Popover>
    );
};
