import { useParams } from 'react-router-dom';
import { Box, Card } from 'wix-style-react';

export const AttendeeCheckin = () => {
    const { id } = useParams<{ id: string }>();

    return (
        <Box>
            {' '}
            <Card>
                <Card.Header title="Use settings from an existing campaign?" />

                <Card.Divider />
                <Card.Content>
                    <FormField
                        label="Select campaign"
                        infoContent="Copy values from an existing campaign. Only campaigns of the selected trigger will be displayed in the dropdown"
                    >
                        <Dropdown
                            border="standard"
                            placeholder="Choose campaign ..."
                            options={options}
                            clearButton
                            onClear={() => {
                                onCampaignApply(null);
                                setSelectedOption(null);
                            }}
                            selectedId={selectedOption}
                            onSelect={({ id }) => {
                                onCampaignApply(campaigns.find((c) => c.id === id));
                                setSelectedOption(id);
                            }}
                        />
                    </FormField>
                </Card.Content>
            </Card>
        </Box>
    );
};
