import axios from 'axios';
import { fromBlob } from 'image-resize-compress';
import { API_INSTANCE, buildURL } from './features/utils';

export const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const isValidUrl = (urlString: string) => {
    try {
        return Boolean(new URL(urlString));
    } catch (e) {
        return false;
    }
};

export const getPresignedURL = async (
    site,
    file: File,
    presignedUrl = `/api/v1/dashboard/events/presigned-url`
): Promise<string> => {
    if (isValidUrl(file)) return file;
    if (isValidUrl(file?.name)) return file.name;

    const { data } = await API_INSTANCE.post(buildURL(site, presignedUrl), {
        name: file.name,
    });
    const originalImageTooLarge = file.size / 1000 > 2000;

    const quality = 90;
    const width = 1000;
    const height = 'auto';
    const format = 'webp';
    const compressedFile = await fromBlob(file, quality, width, height, format);

    await axios.put(
        data.presigned_url,
        file.name.endsWith('.gif') || !originalImageTooLarge ? file : compressedFile,
        {
            headers: {
                'Content-Type': file.type,
            },
        }
    );
    return data.image_url;
};
