import { Accordion, accordionItemBuilder, Box, Button, InfoIcon } from 'wix-style-react';
import RevertIcon from 'wix-ui-icons-common/Revert';
import { SettingsHeader } from '../../../components/settings-header';
import { DesignReset, ThemeDesignUpdater } from '../../../components/widget-design-updater';
import { CustomDesign } from './overrides';
import { ThemePicker } from './theme-picker';
import { classes } from './design.st.css';

export const Design = () => {
    return (
        <Box direction="vertical" width="100%">
            <SettingsHeader title="Design" />
            <Box align="right" marginTop={2}>
                <DesignReset>
                    <Button
                        size="small"
                        prefixIcon={<RevertIcon />}
                        suffixIcon={
                            <InfoIcon
                                size="small"
                                content="Reset styles back to the default theme"
                                className={classes.info}
                            />
                        }
                    >
                        Reset
                    </Button>
                </DesignReset>
            </Box>
            <Accordion
                skin="light"
                hideShadow
                items={[
                    accordionItemBuilder({
                        title: 'Choose Theme',
                        children: (
                            <ThemeDesignUpdater title="">
                                <ThemePicker />
                            </ThemeDesignUpdater>
                        ),
                    }),
                    accordionItemBuilder({
                        title: 'Customize Specific components',
                        children: (
                            <div>
                                <CustomDesign />
                            </div>
                        ),
                    }),
                ]}
            />
        </Box>
    );
};
