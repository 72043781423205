import {
    Box,
    Table,
    TableToolbar,
    Search,
    Text,
    Dropdown,
    StatisticsWidget,
    Loader,
} from 'wix-style-react';

import { useSelector } from 'react-redux';
import moment from 'moment';
import {
    selectAttendeeInsightsLoading,
    selectAttendeeMonthlyTransactions,
} from '../../features/attendees-slice';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectPlans } from '../../features/site-slice';
import { fetchPlanDetails, selectPlanDetails, selectSettings } from '../../features/settings-slice';

const monthNameToMM = {
    january: '01',
    february: '02',
    march: '03',
    april: '04',
    may: '05',
    june: '06',
    july: '07',
    august: '08',
    september: '09',
    october: '10',
    november: '11',
    december: '12',
};

export const AttendeeTransactionsBreakdown = () => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    const planDetails = useAppSelector(selectPlanDetails);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            await dispatch(fetchPlanDetails());
            setLoading(false);
        }
        void fetchData();
    }, [dispatch]);

    const monthlyOptions = [];
    for (let i = 0; i < 12; i++) {
        const monthName = new Date(0, i).toLocaleString('en-US', { month: 'long' });
        const id = monthName.toLowerCase(); // Set id to lowercase month name
        monthlyOptions.push({ id, value: monthName });
    }

    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('en-US', { month: 'long' }).toLowerCase();
    const currentYear = currentDate.getFullYear().toString();
    const yearlyOptions = [];
    for (let year = 2021; year <= currentYear; year++) {
        const id = String(year); // Set id to the year as a string
        yearlyOptions.push({ id, value: String(year) });
    }
    const attendeeTransactions = useSelector(selectAttendeeMonthlyTransactions) || [];
    const insightsLoading = useSelector(selectAttendeeInsightsLoading);

    const settings = useAppSelector(selectSettings);

    const plans = useSelector(selectPlans) || [];
    const [activeSearch, setActiveSearch] = useState('');
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedYear, setSelectedYear] = useState(currentYear);

    const selectedTransactions = useMemo(() => {
        return attendeeTransactions
            .filter((trans: any) => trans?.event?.title?.includes(activeSearch))
            .filter((trans: any) => {
                if (trans?.month_year) {
                    const [eventMonth, eventYear] = trans.month_year.split('/');

                    const selectedMonthLowercase = selectedMonth.toLowerCase();

                    const selectedMonthMM = monthNameToMM[selectedMonthLowercase];
                    return eventMonth === selectedMonthMM && eventYear === selectedYear;
                }
                return false;
            });
    }, [attendeeTransactions, activeSearch, selectedMonth, selectedYear]);

    const plan = plans?.find((plan) => plan.billing_type === settings.plan || plan.trial);
    const totalTransactions = selectedTransactions?.reduce(
        (curr, res) => curr + res.attendee_count,
        0
    );
    // Showing data for month / year
    // search bar
    // Total Transactions
    // transactions left for the month (enterprise will show unlimited)
    //

    const columns = [
        {
            title: 'Date',
            render: (row: any) => moment(row.month_year, 'MM/YYYY').format('MMMM YYYY'),
        },
        { title: 'Event Name', render: (row: any) => row.event.title },
        { title: 'Transaction Count', render: (row: any) => row.attendee_count },
    ];

    const quota = planDetails?.monthly_quota;

    const remainingQuota = quota - +totalTransactions;

    // Define color thresholds as hex values
    const greenThresholdColor = '#09591a';
    const yellowThresholdColor = '#5d7d11';
    const redThresholdColor = '#aa1337';
    const greenThreshold = 0.3; // When remaining quota is above 30%
    const yellowThreshold = 0.1; // When remaining quota is above 10%

    let quotaColor = ''; // Initialize the color variable

    // Determine the color based on the remaining quota
    if (remainingQuota / quota > greenThreshold) {
        quotaColor = greenThresholdColor; // Green
    } else if (remainingQuota / quota > yellowThreshold) {
        quotaColor = yellowThresholdColor; // Yellow
    } else {
        quotaColor = redThresholdColor; // Red
    }

    if (insightsLoading) {
        return (
            <Box align="center" marginTop={10}>
                <Loader statusMessage="Loading" />
            </Box>
        );
    }
    return (
        <Table data={selectedTransactions} columns={columns} showSelection>
            <TableToolbar>
                <TableToolbar.ItemGroup position="start">
                    <TableToolbar.Item>
                        <Box verticalAlign="middle" marginRight={2}>
                            <Text>Showing data for</Text>
                        </Box>
                        <Dropdown
                            size="small"
                            selectedId={selectedMonth}
                            options={monthlyOptions}
                            onSelect={(option) => setSelectedMonth(option.id)}
                        />
                    </TableToolbar.Item>
                    <TableToolbar.Item>
                        <Dropdown
                            size="small"
                            selectedId={selectedYear}
                            options={yearlyOptions}
                            onSelect={(option) => setSelectedYear(option.id)}
                        />
                        <Box verticalAlign="middle" marginLeft={2}>
                            <Text>(month to date)</Text>
                        </Box>
                    </TableToolbar.Item>
                </TableToolbar.ItemGroup>

                <TableToolbar.ItemGroup position="end">
                    <TableToolbar.Item>
                        <Search
                            size="large"
                            value={activeSearch}
                            placeholder="Search for events"
                            onChange={(event) => setActiveSearch(event.target.value)}
                        />{' '}
                    </TableToolbar.Item>
                </TableToolbar.ItemGroup>
            </TableToolbar>
            <Table.SubToolbar>
                <StatisticsWidget
                    items={[
                        {
                            value: totalTransactions === 0 ? '0' : totalTransactions,
                            description: 'Total Transactions',
                        },
                        {
                            value: quota,
                            description: 'Quota',
                        },
                        {
                            value: <div style={{ color: quotaColor }}>{remainingQuota}</div>,
                            description: 'Remaining Quota',
                        },
                    ]}
                />
            </Table.SubToolbar>

            <Table.Content />
        </Table>
    );
};
