import { useState } from 'react';
import { capitalize, omit } from 'lodash';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
    Layout,
    Cell,
    TableActionCell,
    FormField,
    Input,
    CustomModalLayout,
    Box,
    Button,
    Modal,
    Dropdown,
    Table,
    ToggleSwitch,
} from 'wix-style-react';
import { classes } from './custom-fields.st.css';
import * as Icons from 'wix-ui-icons-common';

export const AttendeeSelection = ({
    selections = [],
    setSelections,
    matchAllSelections,
    setMatchAllSelections,
}) => {
    const [isModalOpened, setModalOpened] = useState(false);
    const [selectedField, setSelectedField] = useState();

    const { handleSubmit, control, setValue, formState, watch, reset, clearErrors } = useForm<any>({
        defaultValues: selections.reduce((res, curr) => ({ ...res, [curr.id]: curr.value }), {}),
    });

    const { errors } = formState;

    const onSubmit: SubmitHandler<any> = async (values) => {
        const newSelections = selectedField
            ? selections.map((f, i) => (i === selectedField.index ? omit(values, ['index']) : f))
            : selections.concat(values);
        setSelections(newSelections);
        reset(null);
        closeModal();
    };

    const openModal = () => setModalOpened(true);
    const closeModal = () => {
        setSelectedField(null);
        reset(null);
        setModalOpened(false);
    };
    const updateField = (field, index) => {
        reset(field);
        setSelectedField({ ...field, index });
        openModal();
    };

    const deleteSelectionField = (index) => {
        setSelections(selections.filter((_, i) => index !== i));
    };

    const secondaryActions = (row, index) => [
        {
            icon: <Icons.Edit />,
            text: 'Edit',
            onClick: () => updateField(row, index),
        },
        {
            icon: <Icons.Delete />,
            text: 'Delete',
            onClick: () => deleteSelectionField(index),
        },
    ];

    const columns = [
        { title: 'Property', width: '50%', render: (row: any) => row.property },
        { title: 'Value', width: '50%', render: (row: any) => row.value },
        {
            render: (row, index) => (
                <TableActionCell
                    secondaryActions={secondaryActions(row, index)}
                    popoverMenuProps={{ appendTo: 'window', zIndex: 5000 }}
                />
            ),
        },
    ];

    const watchProperty = watch('property', selectedField ? selectedField.property : 'email');
    const fields = [
        'email',
        'first_name',
        'last_name',
        'phone',
        'company',
        'prefix',
        'suffix',
        'job_title',
        'website',
        'gender',
        'tags',
        'ticket_name',
    ];
    const selectionOptions = fields.map((field) => ({
        id: field,
        value: capitalize(field.replaceAll('_', ' ')),
    }));

    const renderForm = () => (
        <Layout>
            <Cell span={12}>
                <FormField label="Property" required>
                    <Controller
                        control={control}
                        name="property"
                        rules={{
                            required: 'Property is required',
                        }}
                        render={({ field }) => (
                            <Dropdown
                                {...field}
                                onSelect={(option) => setValue('property', option.id)}
                                selectedId={watchProperty}
                                options={selectionOptions}
                                popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                            />
                        )}
                    />

                    <small className={classes.error}>{errors.property?.message}</small>
                </FormField>
            </Cell>
            <Cell span={12}>
                <FormField label="Value" required>
                    <Controller
                        control={control}
                        name="value"
                        rules={{
                            required: 'A value is required',
                        }}
                        render={({ field }) => <Input {...field} />}
                    />
                    <small className={classes.error}>{errors.value?.message}</small>
                </FormField>
            </Cell>
        </Layout>
    );

    const renderModalContent = () => (
        <CustomModalLayout
            primaryButtonText={`${selectedField ? 'Update' : 'Create'}  `}
            secondaryButtonText="Cancel"
            onCloseButtonClick={closeModal}
            primaryButtonOnClick={handleSubmit(onSubmit)}
            secondaryButtonOnClick={closeModal}
            title={`${selectedField ? 'Update' : 'Create'} Condition`}
            width="600px"
            height="400px"
        >
            {renderForm()}
        </CustomModalLayout>
    );

    return (
        <Box direction="vertical">
            <Layout>
                <Cell span={3}>
                    <Button size="small" prefixIcon={<Icons.AddSmall />} onClick={openModal}>
                        Add Match Field
                    </Button>
                    <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
                        {renderModalContent()}
                    </Modal>
                </Cell>
                <Cell span={3}>
                    <FormField
                        label="Match All"
                        stretchContent={false}
                        labelPlacement="right"
                        infoContent="Enable to match all criteria. Turn off to match any of the criteria."
                    >
                        <ToggleSwitch
                            onChange={() => setMatchAllSelections(!matchAllSelections)}
                            checked={matchAllSelections}
                        />
                    </FormField>
                </Cell>

                <Cell span={12}>
                    <Table
                        data={selections}
                        columns={columns}
                        virtualized
                        width="100%"
                        virtualizedLineHeight={60}
                        virtualizedTableHeight={400}
                    >
                        <Table.Content titleBarVisible={false} />
                    </Table>
                </Cell>
            </Layout>
        </Box>
    );
};
