import { useEffect, useRef, useState } from 'react';

import { fetchPlanDetails, selectPlanDetails } from '../features/settings-slice';
import { useAppSelector, useAppDispatch } from '../hooks';
import { Box, Heading, Text } from 'wix-style-react';
import { Line } from 'rc-progress';
import { classes } from './plan-notification.st.css';
import { selectSiteData } from '../features/account-slice';
import { useSelector } from 'react-redux';
import AttendeeTransactionWarning from '../assets/attendee-transaction-warning.gif';
import { capitalize } from 'lodash';
import { nameMapping } from './plan-notification';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { buildURL } from '../features/utils';

function getColorForPercentage(percentage) {
    if (percentage >= 95) {
        return '#ad0707';
    } else if (percentage > 80) {
        return '#7c3968';
    } else if (percentage >= 60) {
        return '#8a8a4e';
    } else {
        return '#5651BE';
    }
}

export const AttendeeQuotaAmount = () => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    const planDetails = useAppSelector(selectPlanDetails);
    const siteData = useSelector(selectSiteData);
    const op = useRef(null);
    const history = useHistory();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            await dispatch(fetchPlanDetails());
            setLoading(false);
        }
        void fetchData();
    }, [dispatch]);

    const transactionCount = planDetails?.monthly_attendee_transaction as string;
    const emailsSentCount = planDetails?.monthly_emails_sent as string;
    const quota = planDetails?.monthly_quota as string;
    const emailQuota = planDetails?.monthly_email_sent_quota as string;
    const quotaPercentage = (+transactionCount / +quota) * 100;
    const emaiQuotaPercentage = (+emailsSentCount / +emailQuota) * 100;

    const upgradeLink = `/events/${siteData.site}/billing`;

    const label = planDetails?.in_free_trial
        ? 'Free Trial'
        : `${capitalize(nameMapping[planDetails?.plan_name] || planDetails?.plan_name)} Plan`;
    return (
        <>
            <Box direction="vertical">
                <Box align="right">
                    <Button
                        type="button"
                        label={label}
                        onClick={(e) => op.current.toggle(e)}
                        loading={loading}
                        rounded
                        severity="success"
                    />
                </Box>
                <Text>
                    You&apos;ve used <b>{transactionCount}</b> attendee transactions this month
                </Text>
            </Box>
            {!loading && (
                <OverlayPanel ref={op}>
                    <Box direction="vertical">
                        <Box
                            gap={2}
                            marginTop={3}
                            marginBottom={3}
                            verticalAlign="middle"
                            direction="vertical"
                        >
                            <Box verticalAlign="middle" gap={1}>
                                {quotaPercentage > 90 && (
                                    <Box>
                                        <img src={AttendeeTransactionWarning} width={100} />
                                    </Box>
                                )}
                                <Box width="300" height={10}>
                                    <Line
                                        percent={quotaPercentage}
                                        strokeWidth={1}
                                        trailColor="#eeeeee"
                                        strokeColor={getColorForPercentage(quotaPercentage)}
                                    />
                                </Box>
                                <Box>
                                    <Heading appearance="H5">
                                        <b>{transactionCount}</b> out of <b>{quota}</b> Attendees
                                        Used
                                    </Heading>
                                </Box>
                            </Box>
                            {planDetails.plan_name !== 'free' && emailQuota !== -1 && (
                                <Box verticalAlign="middle" gap={1}>
                                    {emaiQuotaPercentage > 90 && (
                                        <Box>
                                            <img src={AttendeeTransactionWarning} width={100} />
                                        </Box>
                                    )}
                                    <Box width="300" height={10}>
                                        <Line
                                            percent={emaiQuotaPercentage}
                                            strokeWidth={1}
                                            trailColor="#eeeeee"
                                            strokeColor={getColorForPercentage(emaiQuotaPercentage)}
                                        />
                                    </Box>
                                    <Box>
                                        <Heading appearance="H5">
                                            <b>{emailsSentCount}</b> out of <b>{emailQuota}</b>{' '}
                                            Emails Sent
                                        </Heading>
                                    </Box>
                                </Box>
                            )}
                        </Box>

                        <Box verticalAlign="middle" gap={2}>
                            <Text>Quota Running Low?</Text>
                            <div
                                onClick={() => history.push(buildURL(siteData, upgradeLink))}
                                className={classes.upgradeButton}
                            >
                                Upgrade Now
                            </div>
                        </Box>
                    </Box>
                </OverlayPanel>
            )}
        </>
    );
};
