import { Text, ToggleSwitch } from 'wix-style-react';
import { SettingsHeader } from '../../../components/settings-header';
import { WidgetSettingsUpdater } from '../../../components/widget-settings-updater';
import { selectSettings } from '../../../features/settings-slice';
import { useAppSelector } from '../../../hooks';
import { TextInput } from './text-input';

export const EventText = ({ label }) => {
    const settings = useAppSelector(selectSettings);
    return (
        <>
            <SettingsHeader title={label} />

            <WidgetSettingsUpdater
                updateKey="autoTranslateEvent"
                tooltipText="Event components will be automatically translated based on the user's current language."
                title="Automatic Translation"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>
            <WidgetSettingsUpdater
                updateKey="autoTranslateEventSearchFilters"
                tooltipText="Automatically translates search filters, including time periods like months and weeks."
                title="Automatic Search Filter Translation"
            >
                <ToggleSwitch />
            </WidgetSettingsUpdater>

            {settings.autoTranslateEvent && (
                <Text>
                    Note: The event text is translated from a predefined default shown below into
                    the language of the event. The values cannot be changed.
                </Text>
            )}
            <hr className="divider-long" />

            <TextInput
                placeholder="Register"
                title="Attend Button"
                updateKey="attendBtnText"
                maxLength={25}
            />
            <TextInput
                placeholder="View"
                title="Past Event Button Text"
                updateKey="pastAttendBtnText"
                maxLength={25}
            />
            <TextInput placeholder="Next Event" title="Next Event Text" updateKey="nextEventText" />

            <TextInput
                placeholder="Add to Calendar"
                title="Add to Calendar"
                updateKey="addToCalendarText"
                maxLength={30}
                tooltipText="Text for the 'Add to Calendar' button."
            />

            <TextInput
                title="On Sale Text"
                updateKey="onSaleText"
                tooltipText="Text displayed when tickets are available for purchase."
            />

            <TextInput
                title="Not on Sale Text"
                updateKey="notYetOnSale"
                tooltipText="Text displayed when tickets are not available for purchase."
            />

            <TextInput
                title="Sales Ended Text"
                updateKey="salesEndedText"
                tooltipText="Text displayed when the sale period for tickets has ended."
            />
            <TextInput
                title="Sales Not Started Text"
                updateKey="salesNotStartedText"
                tooltipText="Text displayed when the sale period for tickets has not started."
            />

            <TextInput
                title="Postponed Text"
                updateKey="postponedText"
                tooltipText="Text displayed when ticket sales have been delayed or rescheduled."
            />

            <TextInput
                title="Sold Out Text"
                updateKey="soldOutText"
                tooltipText="Text displayed when all available tickets have been purchased."
            />

            <TextInput
                title="Canceled Text"
                updateKey="cancelledText"
                tooltipText="Text displayed when the event or ticket sales have been canceled."
            />

            <TextInput
                title="Show Details Text"
                updateKey="showMoreText"
                tooltipText="Button label to reveal additional information."
            />

            <TextInput
                title="Close Details Text"
                updateKey="showLessText"
                tooltipText="Button label to hide the additional information."
            />

            <TextInput
                title="Online Event Text"
                updateKey="onlineEventText"
                tooltipText="Text displayed when the event is taking place online."
            />
        </>
    );
};
