import { useState } from 'react';
import { Box, Text, Input, IconButton, InputArea } from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import Edit from 'wix-ui-icons-common/Edit';

export const FieldEditor = ({ fieldDisplay, value, setField, isMultiLine }) => {
    const [openEdit, setOpenEdit] = useState(false);
    const [inputText, setInputText] = useState(value);

    return (
        <Box direction="vertical">
            {fieldDisplay && (
                <Text size="small" secondary light>
                    {fieldDisplay}
                </Text>
            )}
            <Box direction="horizontal" gap={1} verticalAlign="middle" height="100%" marginTop={2}>
                {openEdit ? (
                    <Box gap={2}>
                        {isMultiLine ? (
                            <InputArea
                                placeholder="Any additional notes or information about this customer..."
                                rows={4}
                                resizable
                                value={inputText}
                                onChange={(e) => setInputText(e.target.value)}
                            />
                        ) : (
                            <Input
                                value={inputText}
                                clearButton
                                onClear={() => setInputText('')}
                                onChange={(e) => setInputText(e.target.value)}
                            />
                        )}

                        <IconButton
                            skin="standard"
                            priority="primary"
                            onClick={() => {
                                setField(inputText);
                                setOpenEdit(false);
                            }}
                        >
                            <Icons.Check />
                        </IconButton>
                        <IconButton
                            skin="standard"
                            priority="secondary"
                            onClick={() => setOpenEdit(false)}
                        >
                            <Icons.X />
                        </IconButton>
                    </Box>
                ) : (
                    <>
                        <Text size="small">{value}</Text>
                        <Edit onClick={() => setOpenEdit(true)} />
                    </>
                )}
            </Box>
        </Box>
    );
};
