import { useCallback, useState } from 'react';
import {
    Box,
    Button,
    Card,
    Cell,
    FormField,
    Image,
    Input,
    Layout,
    Loader,
    MarketingPageLayout,
    MarketingPageLayoutContent,
    Text,
} from 'wix-style-react';
import MainCPA from '../../assets/main-cpa-image.png';
import Logo from '../../assets/logo.png';
import { classes } from './email-verification.st.css';
import { useHistory } from 'react-router-dom';
import { API_INSTANCE } from '../../features/utils';
import { EmailSendSmall } from 'wix-ui-icons-common';

export const EmailVerification = () => {
    const [resend, setResend] = useState(false);

    const onResendEmail = useCallback(async () => {
        try {
            await API_INSTANCE.post(`/api/v1/auth/send-verification-email`);
            setResend(true);
        } catch (err) {
            console.error(err);
        }
    }, []);
    return (
        <Card>
            <MarketingPageLayout
                removeImageHorizontalPadding
                removeImageVerticalPadding
                className={classes.color}
                verticalSize="medium"
                content={
                    <Box height="840px" verticalAlign="middle" direction="vertical">
                        <Box
                            align="center"
                            marginBottom={5}
                            verticalAlign="middle"
                            gap={4}
                            direction="vertical"
                        >
                            <img src={Logo} width={60} />
                            <div className={classes.welcomeHeader}>Email Verification</div>
                        </Box>
                        <Box
                            align="center"
                            marginBottom={10}
                            verticalAlign="middle"
                            direction="vertical"
                            gap={2}
                        >
                            <Text className={classes.center}>
                                To activate your account, please click the link in the verification
                                email that we sent to your inbox. Once you click the link, your
                                email address will be verified, and your account will be activated.
                            </Text>
                            <Box marginTop={2} align="left">
                                {resend ? (
                                    <div className={classes.resend}>
                                        Verification email resent. Please check your inbox and spam
                                        folder.
                                    </div>
                                ) : (
                                    <Button
                                        className={classes.button}
                                        prefixIcon={<EmailSendSmall />}
                                        onClick={onResendEmail}
                                    >
                                        Resend Email
                                    </Button>
                                )}
                            </Box>
                        </Box>
                        <MarketingPageLayoutContent />
                    </Box>
                }
                image={<Image transparent borderRadius={0} src={MainCPA} />}
            />
        </Card>
    );
};
export const LoginArea = () => {
    const [clicked, setClicked] = useState(false);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const history = useHistory();
    const handleSignup = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);
            setClicked(true);

            if (!email || !password) {
                return;
            }

            const response = await API_INSTANCE.post('/api/v1/auth/signup', {
                email,
                password,
                firstName,
                lastName,
            });

            if (response.status === 200) {
                setLoading(false);
                history.push('/');
            }
        } catch (error) {
            // Handle network errors or other exceptions
            setError(error.response.data);
            setLoading(false);
        }
    }, [email, history, password]);
    return (
        <Box align="center" verticalAlign="middle" className={classes.container}>
            <Layout>
                <Cell span={12}>
                    <Layout>
                        <Cell span={12}>
                            <FormField label="">
                                <Input
                                    size="large"
                                    placeholder="First name"
                                    value={firstName}
                                    name="email"
                                    onChange={(e) => setFirstName(e.currentTarget.value)}
                                    status={clicked && !firstName ? 'error' : null}
                                    statusMessage="Enter your first name"
                                />
                            </FormField>
                        </Cell>
                        <Cell span={12}>
                            <FormField label="">
                                <Input
                                    size="large"
                                    placeholder="Last name"
                                    value={lastName}
                                    name="password"
                                    onChange={(e) => setLastName(e.currentTarget.value)}
                                    status={clicked && !lastName ? 'error' : null}
                                    statusMessage="Enter your last name"
                                    type="password"
                                />
                            </FormField>
                        </Cell>
                        <Cell span={12}>
                            <FormField label="">
                                <Input
                                    size="large"
                                    placeholder="Email"
                                    value={email}
                                    name="email"
                                    onChange={(e) => setEmail(e.currentTarget.value)}
                                    status={clicked && !email ? 'error' : null}
                                    statusMessage="Enter your email"
                                />
                            </FormField>
                        </Cell>

                        <Cell span={12}>
                            <Box gap={2} direction="horizontal">
                                <FormField label="">
                                    <Input
                                        size="large"
                                        placeholder="Password"
                                        value={password}
                                        name="email"
                                        onChange={(e) => setPassword(e.currentTarget.value)}
                                        status={clicked && !password ? 'error' : null}
                                        statusMessage="Enter your password"
                                    />
                                </FormField>
                                <FormField label="">
                                    <Input
                                        size="large"
                                        placeholder="Confirm password"
                                        value={passwordConfirm}
                                        name="password"
                                        onChange={(e) => setPasswordConfirm(e.currentTarget.value)}
                                        status={clicked && !passwordConfirm ? 'error' : null}
                                        statusMessage="Confirm your password"
                                        type="password"
                                    />
                                </FormField>
                            </Box>
                        </Cell>

                        <Cell span={12}>
                            <Box width="100%" align="center" direction="vertical" gap={1}>
                                <Button
                                    disabled={loading}
                                    size="large"
                                    onClick={() => {
                                        void handleSignup();
                                        setClicked(true);
                                    }}
                                    className={classes.button}
                                >
                                    {loading && (
                                        <Box marginRight={2}>
                                            <Loader size="tiny" />
                                        </Box>
                                    )}{' '}
                                    Create Account
                                </Button>
                                {error && <Box className={classes.error}>{error}</Box>}
                                <Box marginTop={2} align="left">
                                    <div className={classes.backToSignInContainer}>
                                        Back to{' '}
                                        <span
                                            className={classes.backToSignIn}
                                            onClick={() => history.push('/auth/login')}
                                        >
                                            Sign In
                                        </span>
                                    </div>
                                </Box>
                            </Box>
                        </Cell>
                    </Layout>
                </Cell>
            </Layout>
        </Box>
    );
};
