import { ColorInput } from 'wix-style-react';
import { ComponentColorUpdater } from '../../../components/widget-design-updater';

export const ColorPicker = ({
    colorUpdateKey,
    title,
    includeDivider,
    startWithColor,
    defaultValue,
    tooltipContent,
}) => {
    return (
        <ComponentColorUpdater
            updateKey={colorUpdateKey}
            title={title}
            startWithColor={startWithColor}
            defaultValue={defaultValue}
            tooltipContent={tooltipContent}
            includeDivider={includeDivider}
        >
            <ValueWrapperColorPicker defaultValue={defaultValue} />
        </ComponentColorUpdater>
    );
};
const ValueWrapperColorPicker = ({ onChange, onConfirm, value, defaultValue }) => {
    return (
        <ColorInput
            popoverAppendTo="viewport"
            onConfirm={onConfirm}
            value={value || defaultValue}
            onChange={(val) => onChange(val, true)}
            popoverProps={{ appendTo: 'window', zIndex: 5000 }}
        />
    );
};
