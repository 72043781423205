import { SFC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
    Layout,
    Cell,
    Card,
    FormField,
    Input,
    Checkbox,
    Dropdown,
    CustomModalLayout,
    Box,
    Button,
    Modal,
    TableActionCell,
    Table,
    InputArea,
    MultiSelect,
    EmptyState,
    TextButton,
    Image,
    ToggleSwitch,
} from 'wix-style-react';
import * as Icons from 'wix-ui-icons-common';
import { arrayMoveImmutable } from 'array-move';
import { API_INSTANCE, buildURL } from '../../../features/utils';
import { useSelector } from 'react-redux';
import { selectSiteData } from '../../../features/account-slice';
import { getEventTicketsApi, selectTicketsList } from '../../../features/events-slice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { isEmpty } from 'lodash';
import QuestionImage from '../../../assets/event-question-answer.gif';

const options = [
    { id: 'input', value: 'Text Field (Single Line)' },
    { id: 'radio', value: 'Choice (Radio Buttons)' },
    { id: 'dropdown', value: 'Choice (Dropdown Menu)' },
    { id: 'checkbox', value: 'Multiple Selections (Checkboxes)' },
    { id: 'terms', value: 'Agree to Terms (Checkbox)' },
    { id: 'shopify_variant_option', value: 'Shopify Product Variant' },
    { id: 'datepicker', value: 'Date Picker' },
];

const capitalizeEachWord = (text: string) => {
    return text
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
};
export const AdditionalQuestions: SFC<any> = ({ questions = [], setQuestions, event }) => {
    const [isModalOpened, setModalOpened] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState<any>();
    const [connectedTickets, setConnectedTickets] = useState<any>([]);
    const [contactProps, setContactProps] = useState<any>([]);
    const site = useSelector(selectSiteData);
    const tickets = useAppSelector(selectTicketsList);

    const dispatch = useAppDispatch();
    useEffect(() => {
        const fetchData = async () => {
            void dispatch(getEventTicketsApi(event.id));
            const customerGetPropsUrl = `/api/v1/dashboard/customers/contact/properties`;
            const { data } = await API_INSTANCE.get(buildURL(site, customerGetPropsUrl));
            const valuesToRemove = ['email', 'first_name', 'last_name'];
            const dataWithCoreFieldsRemove = data.filter((item) => !valuesToRemove.includes(item));

            setContactProps(dataWithCoreFieldsRemove);
        };
        void fetchData();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            if (selectedQuestion) {
                setConnectedTickets(selectedQuestion?.connected_ticket_ids || []);
            }
        };
        void fetchData();
    }, [selectedQuestion]);

    const { handleSubmit, control, setValue, watch, reset } = useForm<any>({
        defaultValues: {
            ...selectedQuestion,
            type: selectedQuestion?.type || 'input',
        },
    });

    const onSubmit: SubmitHandler<any> = async (values) => {
        const newQuestions = selectedQuestion
            ? questions.map((q, i) =>
                  i === selectedQuestion.index
                      ? { ...values, connected_ticket_ids: connectedTickets }
                      : q
              )
            : questions.concat(values);
        setQuestions(newQuestions);
        reset(null);
        closeModal();
    };

    const deleteQuestion = (index: number) => {
        const result = [...questions.slice(0, index), ...questions.slice(index + 1)];
        setQuestions(result);
    };
    const openModal = () => setModalOpened(true);
    const closeModal = () => {
        setSelectedQuestion(null);
        reset(null);
        setModalOpened(false);
    };

    const setQuestion = (question: any, index: number) => {
        reset(question);
        setSelectedQuestion({ ...question, index });
        openModal();
    };

    const watchType = watch('type', selectedQuestion?.type || 'input');
    const watchMappingValue = watch('mapping', selectedQuestion?.mapping);
    const mappingOptions = contactProps.map((prop: string) => ({
        id: prop,
        value: capitalizeEachWord(prop.replaceAll('_', ' ')),
    }));

    const handleOnSelect = (tag) => {
        setConnectedTickets([...connectedTickets, tag.id]);
    };

    const handleOnRemoveTag = (tagId) => {
        const newIds = connectedTickets.filter((currTag) => currTag !== tagId);
        setConnectedTickets(newIds);
    };

    const renderForm = () => (
        <Layout>
            <Cell span={12}>
                <FormField
                    label="Response Type Selection"
                    infoContent="Choose the format you’d like attendees to use when answering this question. Selecting the correct type ensures you receive the information in the most organized way."
                >
                    <Controller
                        control={control}
                        name="type"
                        render={({ field }) => (
                            <Dropdown
                                {...field}
                                border="standard"
                                options={options}
                                selectedId={watchType}
                                onSelect={(option) => setValue('type', '' + option.id)}
                            />
                        )}
                    />
                </FormField>
            </Cell>
            <Cell span={12}>
                <FormField
                    label="Exclude from Waitlist"
                    required
                    infoContent="Do not include this question in the waitlist form"
                >
                    <Controller
                        control={control}
                        name="excludeInWaitlist"
                        render={({ field }) => <ToggleSwitch {...field} checked={field.value} />}
                    />
                </FormField>
            </Cell>
            <Cell span={12}>
                <FormField
                    label={
                        watchType === 'shopify_variant_option'
                            ? 'Option Name'
                            : 'Custom Question Text'
                    }
                    required
                    infoContent="What would you like to know from your attendees? (e.g., 'What is your T-shirt size?')"
                >
                    <Controller
                        control={control}
                        rules={{
                            required: 'Question name is required!',
                        }}
                        name="question"
                        render={({ field }) => <Input {...field} />}
                    />
                </FormField>
            </Cell>
            {!['input', 'datepicker'].includes(watchType) && (
                <Cell span={12}>
                    <FormField
                        label={watchType === 'terms' ? 'Terms Document URL' : 'Answer Options'}
                        required
                        infoContent={
                            watchType === 'terms'
                                ? 'Provide a link to your event’s terms, conditions, or any other important information you want attendees to review.'
                                : 'List the possible answers for your question. Attendees will choose from these options.'
                        }
                    >
                        <Controller
                            control={control}
                            name="options"
                            render={({ field }) =>
                                watchType === 'terms' ? (
                                    <Input {...field} />
                                ) : (
                                    <InputArea rows={5} {...field} />
                                )
                            }
                        />
                    </FormField>
                </Cell>
            )}
            {!['datepicker'].includes(watchType) && watchType !== 'shopify_variant_option' && (
                <Cell span={12}>
                    <FormField
                        label="Custom Data Collection"
                        infoContent="Customize attendee data by mapping additional details to customer profile fields. Leverage these fields to deliver personalized and engaging emails tailored to each attendee."
                    >
                        <Controller
                            control={control}
                            name="mapping"
                            render={() => (
                                <Dropdown
                                    popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                                    size="medium"
                                    clearButton
                                    onClear={() => setValue('mapping', '')}
                                    onSelect={(option) => setValue('mapping', '' + option.id)}
                                    selectedId={watchMappingValue}
                                    placeholder="Select a field ..."
                                    options={mappingOptions}
                                />
                            )}
                        ></Controller>
                    </FormField>
                </Cell>
            )}
            {watchType !== 'shopify_variant_option' && (
                <Cell span={4}>
                    <FormField infoContent="Check this if you require an answer to this question for registration completion. Leave unchecked for optional questions.">
                        <Controller
                            control={control}
                            name="required"
                            render={({ field }) => (
                                <Checkbox
                                    size="medium"
                                    {...field}
                                    onChange={(e) => setValue('required', e.target.checked)}
                                    checked={field.value}
                                >
                                    Required
                                </Checkbox>
                            )}
                        />
                    </FormField>
                </Cell>
            )}
            {watchType !== 'shopify_variant_option' && (
                <Cell span={12}>
                    <FormField
                        label="Ticket-Specific Question"
                        required
                        infoContent="Specify the tickets that should trigger the display of this question during checkout. Only users who choose these tickets will be prompted to answer."
                    >
                        <Controller
                            control={control}
                            name="tickets"
                            render={() => (
                                <MultiSelect
                                    options={tickets.map((val) => ({
                                        id: val.ticket_uuid,
                                        value: val.name,
                                    }))}
                                    tags={connectedTickets
                                        .map((selectedOption: any) => {
                                            const ticket = tickets.find(
                                                (t) => t.ticket_uuid === selectedOption
                                            );
                                            if (ticket) {
                                                return { label: ticket.name, id: selectedOption };
                                            }
                                        })
                                        .filter((v) => v)}
                                    onSelect={handleOnSelect}
                                    onRemoveTag={handleOnRemoveTag}
                                    popoverProps={{ appendTo: 'window', zIndex: 5000 }}
                                />
                            )}
                        ></Controller>
                    </FormField>
                </Cell>
            )}
        </Layout>
    );

    const renderModalContent = () => (
        <CustomModalLayout
            primaryButtonText={`${selectedQuestion ? 'Update' : 'Create'} question`}
            secondaryButtonText="Cancel"
            onCloseButtonClick={closeModal}
            primaryButtonOnClick={handleSubmit(onSubmit)}
            secondaryButtonOnClick={closeModal}
            title="Add Custom Question"
            width="600px"
        >
            <Box marginBottom={3}>{renderForm()}</Box>
        </CustomModalLayout>
    );

    return (
        <Box
            direction="vertical"
            width="90%"
            boxShadow="0px -2px 8px #16233717, 0px 4px 4px #16233717"
            margin={'0 auto'}
        >
            <Card>
                <Card.Header
                    title="Personalize Your Event with Custom RSVP Questions"
                    subtitle="Gather the Information You Need for a Successful Event"
                    suffix={
                        <Button size="small" prefixIcon={<Icons.AddSmall />} onClick={openModal}>
                            Add Custom Question
                        </Button>
                    }
                />
            </Card>
            {isEmpty(questions) && (
                <EmptyState
                    theme="page"
                    image={<Image width="120px" height="120px" src={QuestionImage} transparent />}
                    title="Add your first question"
                    subtitle="Capture Attendee Information using different question types"
                >
                    {
                        <TextButton prefixIcon={<Icons.Add />} onClick={openModal}>
                            Add Question
                        </TextButton>
                    }
                </EmptyState>
            )}
            <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
                {renderModalContent()}
            </Modal>
            <QuestionTable
                questions={questions}
                setQuestion={setQuestion}
                deleteQuestion={deleteQuestion}
                setQuestions={setQuestions}
            />
        </Box>
    );
};

const QuestionTable: SFC<any> = ({ questions, setQuestion, deleteQuestion, setQuestions }) => {
    const primaryAction = (question: any, index: number) => ({
        text: 'Edit',
        onClick: () => setQuestion(question, index),
    });

    const secondaryActions = (index: number) => [
        {
            icon: <Icons.Delete />,
            text: 'Delete',
            onClick: () => deleteQuestion(index),
        },
        {
            icon: <Icons.ArrowUp />,
            text: 'Move up',
            onClick: () => setQuestions(arrayMoveImmutable(questions, index, index - 1)),
        },
        {
            icon: <Icons.ArrowDown />,
            text: 'Move down',
            onClick: () => setQuestions(arrayMoveImmutable(questions, index, index + 1)),
        },
    ];

    const columns = [
        { title: 'Question', render: (row: any) => row.question, important: true },
        { title: 'Type', render: (row: any) => options.find((o) => o.id === row.type).value },
        { title: 'Required', render: (row: any) => (row.required ? <Icons.Check /> : null) },
        {
            render: (val: any, index: any) => (
                <TableActionCell
                    primaryAction={primaryAction(val, index)}
                    secondaryActions={secondaryActions(index)}
                />
            ),
        },
    ];

    return (
        <Table data={questions} columns={columns}>
            <Table.Content />
        </Table>
    );
};
