import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
    Table,
    Box,
    Button,
    TextButton,
    CustomModalLayout,
    Modal,
    TableActionCell,
    Badge,
    SectionHelper,
    Loader,
    Text,
    Tooltip,
} from 'wix-style-react';
import { classes } from './tickets.st.css';

import { arrayMoveImmutable } from 'array-move';
import * as Icons from 'wix-ui-icons-common';
import { Tickets, TICKET_STATUS_OPTIONS } from './tickets';

import type { Ticket } from '../../../types';
import {
    createEventTicketsApi,
    deleteEventTicketsApi,
    getEventTicketsApi,
    updateEventTicketsApi,
    updateEventTicketsOrderApi,
} from '../../../features/events-slice';
import { useAppDispatch } from '../../../hooks';
import { useSelector } from 'react-redux';
import { fetchSubscriptionApi, selectSite } from '../../../features/site-slice';
import { getPresignedURL } from '../../../utils';

export const TicketTable = ({
    tickets = [],
    event,
    paymentDetails,
    site,
    setQuestions,
    getParentValues,
    parentWatch,
    isPublicCreation,
}) => {
    const [isModalOpened, setModalOpened] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState();
    const [saving, setSaving] = useState(false);

    const currentSite = useSelector(selectSite);
    const watchOverrideParentTickets = parentWatch('overrideParentTickets');

    const openModal = () => {
        setModalOpened(true);
    };
    const newTicketOpen = () => {
        setModalOpened(true);
        setSelectedTicket(null);
    };
    const closeModal = () => {
        setSelectedTicket(null);
        reset();
        setModalOpened(false);
    };
    const dispatch = useAppDispatch();

    const primaryAction = (ticket: Ticket) => ({
        text: 'Edit',
        onClick: () => {
            reset(ticket);
            setSelectedTicket(ticket);
            openModal();
        },
    });

    const secondaryActions = (ticket: Ticket, index: number) => [
        {
            text: 'Duplicate',
            icon: <Icons.Duplicate />,
            onClick: () =>
                dispatch(
                    createEventTicketsApi({
                        ...ticket,
                        name: `${ticket.name} copy`,
                        order: tickets.length,
                    })
                ),
        },
        {
            icon: <Icons.Delete />,
            text: 'Delete',
            onClick: () => dispatch(deleteEventTicketsApi(ticket.id)),
        },
        {
            icon: <Icons.ArrowUp />,
            text: 'Move up',
            disabled: index === 0,
            onClick: async () => {
                const reorderedTickets = arrayMoveImmutable(sortedTickets, index, index - 1).map(
                    (t, index) => ({ id: t.id, order: index })
                );
                await dispatch(updateEventTicketsOrderApi(reorderedTickets));
            },
        },
        {
            icon: <Icons.ArrowDown />,
            text: 'Move down',
            disabled: index === tickets.length - 1,
            onClick: async () => {
                const reorderedTickets = arrayMoveImmutable(sortedTickets, index, index + 1).map(
                    (t, index) => ({ id: t.id, order: index })
                );

                await dispatch(updateEventTicketsOrderApi(reorderedTickets));
            },
        },
    ];

    const columns = [
        {
            title: 'Name',
            render: (row) => (
                <Box>
                    {row.is_hidden ? (
                        <Text skin="disabled">
                            {row.name}{' '}
                            <Tooltip
                                appendTo="window"
                                content={'This ticket is hidden and will not be visible to buyers.'}
                            >
                                <Icons.InfoCircle />
                            </Tooltip>
                        </Text>
                    ) : (
                        <>{row.name}</>
                    )}
                </Box>
            ),
            width: '30%',
        },
        {
            title: 'Price',
            render: (row) => (
                <Box>
                    {row.price} {row.enable_booking_fee && <>(+{row.booking_fee_fixed} Fee)</>}
                </Box>
            ),
            width: '10%',
        },
        {
            title: 'Quantity',
            render: (row) => row.quantity_total,
            width: '10%',
        },
        {
            title: 'Quantity Sold',
            render: (row) => row?.quantity_sold || 0,
            width: '10%',
        },
        {
            title: 'Available',
            render: (row) => row?.available_quantity || 0,
            width: '10%',
        },
        event.isMultiDay || event.isTimeBased || event.repeat
            ? false
            : {
                  title: 'Status',
                  render: (row) => {
                      const status = TICKET_STATUS_OPTIONS.find((opt) => opt.id === row.status);
                      return (
                          <Badge size="medium" skin={status?.badge}>
                              {status?.value}
                          </Badge>
                      );
                  },
                  width: '20%',
              },
        {
            render: (row: any, index: number) => (
                <TableActionCell
                    primaryAction={primaryAction(row)}
                    secondaryActions={secondaryActions(row, index)}
                />
            ),
            width: '20%',
        },
    ];

    const renderEmptyState = () => (
        <Table.EmptyState
            title="No Ticket Created"
            subtitle="Once you create your tickets, you'll see them here."
        >
            <TextButton onClick={openModal} prefixIcon={<Icons.Add />}>
                Create New Ticket
            </TextButton>
        </Table.EmptyState>
    );

    const { handleSubmit, control, formState, setValue, watch, reset } = useForm<Ticket>({
        defaultValues: {
            ...selectedTicket,
            quantity_total: selectedTicket?.quantity_total || 50,
            status: selectedTicket?.status || 'onsale',
            minimum_per_order: selectedTicket?.minimum_per_order || 1,
            maximum_per_order: selectedTicket?.maximum_per_order || 20,
            is_free: selectedTicket?.is_free === undefined ? true : selectedTicket?.is_free,
            price: selectedTicket?.price,
            sales_end_relative_value: selectedTicket?.sales_end_relative_value || 1,
            sales_end_relative_to: selectedTicket?.sales_end_relative_to || 'before-start',
            sales_end_relative_unit: selectedTicket?.sales_end_relative_unit || 'hour',
            pay_what_you_want: selectedTicket?.pay_what_you_want,
            enable_booking_fee: selectedTicket?.enable_booking_fee,
            is_hidden: selectedTicket?.is_hidden,
            booking_fee_fixed: selectedTicket?.booking_fee_fixed,
            checkin_scan_limit: selectedTicket?.checkin_scan_limit || 1,
            minimum_price: selectedTicket?.minimum_price,
            start_sales_immediately:
                selectedTicket?.start_sales_immediately === undefined
                    ? true
                    : selectedTicket?.start_sales_immediately,
        },
    });

    const { errors } = formState;

    const onSubmit: SubmitHandler<Ticket> = async (values) => {
        setSaving(true);
        const submitFunc = selectedTicket ? updateEventTicketsApi : createEventTicketsApi;
        let ticketImageUrl = values.image_urls;
        if (values.images) {
            ticketImageUrl = await Promise.all(
                values.images.map((file: File) => getPresignedURL(site, file))
            );
        }
        const tickets = await dispatch(
            submitFunc({
                ...values,
                price: values.price ?? 0,
                image_urls: ticketImageUrl,
                event_id: event.id,
                id: selectedTicket?.id,
            })
        );
        dispatch(getEventTicketsApi(event.id));
        setQuestions(tickets.payload.newQuestions);
        closeModal();
        reset();
        setSaving(false);
    };

    const renderModalContent = () => (
        <CustomModalLayout
            primaryButtonText={
                <Box gap={2}>
                    {saving && <Loader color="white" size="tiny" />}
                    {selectedTicket ? 'Update' : 'Create'}
                </Box>
            }
            secondaryButtonText="Cancel"
            onCloseButtonClick={closeModal}
            primaryButtonOnClick={handleSubmit(onSubmit)}
            secondaryButtonOnClick={closeModal}
            title={<>{selectedTicket ? 'Update' : 'Create'} Ticket</>}
            className={classes.modalWidth}
        >
            {
                <Tickets
                    ticket={selectedTicket}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    watch={watch}
                    getValues={getParentValues}
                />
            }
        </CustomModalLayout>
    );

    const sortedTickets = useMemo(() => {
        return [...tickets].sort((a, b) => (a.order || 0) - (b.order || 0));
    }, [tickets]);

    const isChildEvent = event?.seriesId && !event?.isSeriesParent;
    const activateTickets = !isChildEvent || (watchOverrideParentTickets && isChildEvent);
    if (!activateTickets) return <span />;
    return (
        <>
            <TicketsSection
                isModalOpened={isModalOpened}
                closeModal={closeModal}
                renderModalContent={renderModalContent}
                tickets={tickets}
                newTicketOpen={newTicketOpen}
                paymentDetails={paymentDetails}
                sortedTickets={sortedTickets}
                columns={columns.filter(Boolean)}
                renderEmptyState={renderEmptyState}
                isPublicCreation={isPublicCreation}
                siteId={currentSite?.id}
            />
        </>
    );
};

const TicketsSection = ({
    isModalOpened,
    closeModal,
    renderModalContent,
    tickets,
    newTicketOpen,
    sortedTickets,
    columns,
    renderEmptyState,
    siteId,
}) => {
    const dispatch = useAppDispatch();

    useEffect(async () => {
        await dispatch(fetchSubscriptionApi(siteId));
    }, [dispatch, siteId]);

    return (
        <Box direction="vertical" height="100%" marginTop={2}>
            <Modal isOpen={isModalOpened} onRequestClose={closeModal} screen="desktop">
                {renderModalContent()}
            </Modal>
            {tickets.length > 0 && (
                <Box paddingBottom="SP1" align="right">
                    <Button onClick={newTicketOpen} prefixIcon={<Icons.Add />}>
                        Create Ticket
                    </Button>
                </Box>
            )}
            <Table data={sortedTickets} columns={columns}>
                {tickets.length === 0 ? renderEmptyState() : <Table.Content />}
            </Table>
        </Box>
    );
};
